// Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box, Text, Button } from 'grommet'
import PropTypes from 'prop-types'
import { FormSubtract, FormClose } from 'grommet-icons'

import { ChatActions, useChatContext } from './context/chat-context'
import { startDrag } from './Draggable'
import ChatMenu from './ChatMenu'

const ChatHeader = ({ chatContainerRef = undefined, closeChatBot }) => {
  const { dispatchChatContext, feedbackFormData, setLastActiveAsFeedback } =
    useChatContext()
  const { showFeedbackForm } = feedbackFormData

  const handleDrag = (e) => {
    e.preventDefault()
    startDrag(e, chatContainerRef)
  }

  const updateFeedbackForm = (show) => {
    dispatchChatContext({
      type: ChatActions.UPDATE_FEEDBACK_FORM,
      payload: { ...feedbackFormData, showFeedbackForm: show }
    })
  }

  const onCloseChatBot = () => {
    updateFeedbackForm(!showFeedbackForm)
    setLastActiveAsFeedback(showFeedbackForm)

    closeChatBot({ type: ChatActions.OPEN_CHAT, payload: { open: false } })
  }

  const onClearChatBot = async () => {
    sessionStorage.removeItem('chatInputMessage')
    closeChatBot({
      type: ChatActions.CLEAR_STATE,
      payload: { open: false }
    })
  }

  const onOpenFeedback = () => {
    updateFeedbackForm(!showFeedbackForm)
    setLastActiveAsFeedback(!showFeedbackForm)

    if (showFeedbackForm) {
      onClearChatBot()
    }
  }

  return (
    <Box
      data-testid="chatbot-header-box"
      background="background-front"
      direction="row"
      align="center"
      pad="xsmall"
      justify="between"
      border={{ color: 'lightGray', size: 'xsmall', side: 'bottom' }}
      onMouseDown={handleDrag}
      style={{ cursor: 'move', height: 'large' }}
    >
      <ChatMenu />
      <Box direction="row" gap="small" align="center" justify="between">
        <Text
          style={{ fontWeight: '600', fontSize: '16px' }}
          id="chatbot-header-title"
          data-testid="chatbot-header-title"
        >
          Virtual Assistant
        </Text>
      </Box>

      <Box direction="row" align="center">
        <Button
          aria-label="minimize the virtual assistant"
          pad="9px"
          icon={
            <FormSubtract
              data-testid="chat-header-minimize"
              id="chat-header-minimize"
              size="medium"
              onClick={onCloseChatBot}
              style={{ cursor: 'pointer' }}
            />
          }
        />
        <Button
          aria-label="close the virtual assistant"
          pad="9px"
          icon={
            <FormClose
              data-testid="chat-header-close"
              id="chat-header-close"
              size="medium"
              onClick={onOpenFeedback}
              style={{ cursor: 'pointer' }}
            />
          }
        />
      </Box>
    </Box>
  )
}

ChatHeader.propTypes = {
  chatContainerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  closeChatBot: PropTypes.func.isRequired
}
export default ChatHeader
