// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Anchor, Box, Button, Grid, ResponsiveContext, Video } from 'grommet'
import { LinkNext } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation, Trans } from 'react-i18next'

import { Layout } from '../../commoncomponents/layout/Layout'
import { Typography } from '../../components'
import { useCCSContext, CCSActions } from '../../context/ccs-context'

import GuidedTourVideo from './videos/guided_workspace_animation.mp4'

const GuidedWorkspaceWelcome = () => {
  const size = useContext(ResponsiveContext)
  const { t } = useTranslation(['common', 'dashboard', 'manage', 'authn'])
  const LDFlags = useFlags()
  const glcpIAMV2WorkspaceFlag = LDFlags['glcp-iam-v2-workspace']
  const { dispatchCCSContext } = useCCSContext()
  const navigate = useNavigate()

  const handleCreateAccount = (e) => {
    e.preventDefault()
    dispatchCCSContext({
      type: CCSActions.SET_AMPLITUDE_ANALYTICS,
      data: {
        key: 'isV2SignupCheckboxVisible',
        value: glcpIAMV2WorkspaceFlag
      }
    })
    navigate('/post-onboarding/set-up-account')
  }

  const grid = {
    pad: {
      xsmall: {
        horizontal: 'medium'
      },
      small: {
        horizontal: 'large'
      },
      medium: {
        top: 'large',
        horizontal: 'medium',
        bottom: 'xlarge'
      },
      large: {
        top: 'xlarge',
        left: 'xlarge',
        right: 'xlarge',
        bottom: 'xlarge'
      },
      xlarge: {
        top: 'xlarge',
        left: 'xlarge',
        right: 'xlarge',
        bottom: 'xlarge'
      }
    },
    columns: {
      xsmall: ['auto'],
      small: ['auto'],
      medium: ['auto', 'auto'],
      large: ['auto', 'auto'],
      xlarge: ['auto', 'auto']
    },
    gap: {
      xsmall: { row: 'large' },
      small: { row: 'large' },
      medium: { column: 'medium' },
      large: { column: 'large' },
      xlarge: { column: 'large' }
    }
  }

  return (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      <Box data-testid="welcome-page" pad={grid.pad[size]} align="center">
        <Grid
          columns={grid.columns[size] || grid.columns.large} // Define column widths
          gap={grid.gap[size]} // Gaps between rows and columns
        >
          <Box
            pad={{ right: 'large' }}
            justify="center"
            gap="large"
            style={{ maxWidth: '696px' }}
          >
            <Typography
              type="heading"
              level="1"
              testId="guided-workspace-header"
            >
              {t('dashboard:guided_workspace.greeting_title')}
            </Typography>
            <Box alignSelf="stretch" gap="medium">
              <Box>
                <Typography type="heading" level="2" testId="lets-create">
                  {t('dashboard:guided_workspace.greeting_subtitle')}
                </Typography>
              </Box>
              <Box>
                <Typography type="text" testId="guided-workspace-desc">
                  <Trans>
                    {t('dashboard:guided_workspace.greeting_text1')}
                    <Anchor
                      label={t(
                        'dashboard:guided_workspace.greeting_learn_more'
                      )}
                      target="_blank"
                      href="https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us&page=GUID-C335D790-5166-406F-B0B8-C93AB46A2C76.html"
                      style={{ textDecoration: 'none' }}
                    />
                    {t('dashboard:guided_workspace.greeting_text2')}
                  </Trans>
                </Typography>
              </Box>
              <Button
                data-testid="guided-create-workspace-btn"
                label={t('dashboard:guided_workspace.create_workspace_btn')}
                primary
                icon={<LinkNext />}
                reverse
                alignSelf="start"
                onClick={handleCreateAccount}
              />
            </Box>
          </Box>
          <Box
            round="medium"
            border
            overflow="hidden"
            style={{ maxWidth: '696px', minWidth: 'medium' }}
            alignSelf="center"
          >
            <Video autoPlay loop mute controls={false} fit="cover">
              <source src={GuidedTourVideo} type="video/mp4" />
            </Video>
          </Box>
        </Grid>
      </Box>
    </Layout>
  )
}

export default GuidedWorkspaceWelcome
