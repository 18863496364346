// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { post, get, patch } from '../../../utils/api-utils'

export const SESSION_EXPIRED = {
  errorCode: '404',
  message: 'GLCP Session Not Found, it might be expired'
}

export const CONSTANT_PARAMETERS = {
  path: '/workspaceId',
  op: 'append',
  maxRetry: 2
}

const createSession = async (accessToken, userEmail, workspaceId) => {
  try {
    const response = await post(
      `/support-chat/v1alpha1/sessions`,
      { userEmail, workspaceId },
      accessToken
    )
    return response?.data
  } catch (e) {
    return null
  }
}

const sendUtterance = async (accessToken, sessionId, text) => {
  try {
    const response = await post(
      `/support-chat/v1alpha1/sessions/${sessionId}/utterances`,
      { text },
      accessToken
    )
    return response?.data
  } catch (error) {
    if (
      error?.response?.data?.errorCode === SESSION_EXPIRED.errorCode &&
      error?.response?.data?.message === SESSION_EXPIRED.message
    ) {
      return SESSION_EXPIRED?.message
    }
    return null
  }
}

let attempt = 0
const getResponses = async (accessToken, sessionId, limit, offset) => {
  try {
    const response = await get(
      `/support-chat/v1alpha1/sessions/${sessionId}/responses`,
      { limit, offset },
      accessToken
    )
    if (response?.data?.items?.length === 0 && attempt < 5) {
      attempt += 1
      return getResponses(accessToken, sessionId, limit, offset)
    }
    return response?.data
  } catch (e) {
    return null
  }
}

const updateWorkspace = async (
  accessToken,
  sessionId,
  workspaceId,
  attemptForWorkspaceUpdate
) => {
  const { path, op, maxRetry } = CONSTANT_PARAMETERS
  try {
    const response = await patch(
      `/support-chat/v1alpha1/sessions/${sessionId}`,
      { path, op, workspaceId },
      accessToken
    )
    if (response?.status !== 200 && attemptForWorkspaceUpdate < maxRetry) {
      attemptForWorkspaceUpdate += 1
      console.warn('Update Workspace Failed. Retrying...')
      return updateWorkspace(
        accessToken,
        sessionId,
        workspaceId,
        attemptForWorkspaceUpdate
      )
    }
    return response?.data
  } catch (e) {
    return null
  }
}
const chatService = {
  createSession,
  sendUtterance,
  getResponses,
  updateWorkspace
}

export default chatService
