// Copyright 2025 Hewlett Packard Enterprise Development LP
export const FILE_EXTENSION_CONSTANTS = [
  'log',
  'syslog',
  'eventlog',
  'dmesg',
  'accesslog',
  'errorlog',
  'authlog',
  'clog',
  'slog',
  'fslog',
  'dblog',
  'replog',
  'ltt',
  'pcap',
  'netlog',
  'vpnlog',
  'log',
  'etl',
  'trc',
  'debuglog',
  'auditlog',
  'installlog',
  'updatelog',
  'evtx',
  'evt',
  'ahs',
  'msglog',
  'errlog',
  'statlog',
  'csv',
  'zip',
  'xz',
  'tar',
  'tgz',
  'cab',
  '7z',
  'gz',
  'gzip',
  'rar',
  'tbz2',
  'z1',
  'z2',
  'z3',
  'z4',
  'z5',
  'z6',
  'z7',
  'z8',
  'z9',
  'out',
  'bz2',
  'md5',
  'xls',
  'xlsx',
  'doc',
  'docx',
  'pdf',
  'ppt',
  'pptx',
  'txt',
  'png',
  'jpg',
  'jpeg',
  'jpe',
  'jfif',
  'bmp',
  'gif',
  'tif',
  'mp4',
  'mpeg',
  'mpg',
  'ilo',
  'mov',
  'wmv',
  'avi',
  'html',
  'htm',
  'xml',
  'service',
  'target',
  'blg',
  'dmp',
  'mdmp',
  'sdmp',
  'iml',
  'ini',
  'cfg',
  'conf',
  'nfo',
  'ltd',
  'hpasm',
  'messages',
  'secure',
  'iso',
  's7z',
  'ace',
  'info',
  'bin',
  'msg',
  'eml'
]
