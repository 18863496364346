import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Box, Grid } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  CCSForm,
  Dropdown,
  ToggleButton,
  Typography
} from '../../../../../components'
import { getErrorMessage, patch } from '../../../../../utils/api-utils'

const EditModal = ({ data, onSuccess, setModalOpen }) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')
  const formattedState = { ...data }
  formattedState.beta = formattedState.beta === 'Yes'
  formattedState.cbuild = formattedState.cbuild === 'Yes'
  formattedState.is_compliant = formattedState.is_compliant === 'Yes'
  const [state, dispatch] = useReducer((currentState, action) => {
    setErrorMessage('')
    switch (action.type) {
      case 'UPDATE_FIELD':
        return { ...currentState, [action.field]: action.value }
      default:
        return currentState
    }
  }, formattedState)
  const detailKeys = {
    file_name: t('firmware.file_name'),
    file_size: t('firmware.file_size'),
    build: t('firmware.build'),
    version: t('firmware.version'),
    oem: t('firmware.oem'),
    checksum: t('firmware.checksum'),
    is_compliant: {
      keyLabel: t('firmware.compliant'),
      type: 'toggle'
    },
    cbuild: {
      keyLabel: t('firmware.c_build'),
      type: 'toggle'
    },
    platform: t('firmware.platform'),
    beta: {
      keyLabel: t('firmware.beta'),
      type: 'toggle'
    },
    dependent: {
      keyLabel: t('firmware.dependent'),
      type: 'dropdown',
      options: [t('firmware.yes'), t('firmware.no')]
    }
  }

  const handleDone = () => {
    const formatted = { ...state }
    formatted.dependent = formatted.dependent === t('firmware.yes')
    patch(
      `/firmware-registry/ui/v1/firmware/${formatted.id}`,
      {
        is_compliant: formatted.is_compliant,
        dependent: formatted.dependent,
        cbuild: formatted.cbuild,
        beta: formatted.beta
      },
      oidcUser.access_token
    ).then(
      () => {
        onSuccess()
      },
      (err) => {
        const errorMsg = getErrorMessage(err, t)
        setErrorMessage(errorMsg)
      }
    )
  }

  const renderEditField = (key) => {
    switch (detailKeys[key].type) {
      case 'toggle':
        return (
          <ToggleButton
            onChange={(event) => {
              dispatch({
                value: event.target.checked,
                field: key,
                type: 'UPDATE_FIELD'
              })
            }}
            checked={state[key]}
            testId={`${key}-toggle-btn`}
          />
        )
      default:
        // dropdown
        return (
          <Dropdown
            name={`${key}_dropdown`}
            options={detailKeys[key].options}
            value={state[key]}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: key,
                type: 'UPDATE_FIELD'
              })
            }}
            testId={`${key}-dropdown`}
          />
        )
    }
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography level="1" type="heading" testId="edit-firmware-title">
              {`${t('edit')} ${state.file_name}`}
            </Typography>
          }
        />
      }
      width="large"
      testId="edit-firmware-modal"
      content={
        <Box margin={{ top: 'medium' }}>
          <CCSForm errorMessage={errorMessage} testId="edit-firmware-form">
            <Grid
              columns={['1/2', '1/2']}
              gap="small"
              margin={{ bottom: 'medium' }}
            >
              {Object.keys(detailKeys).map((key) => {
                return (
                  <Box key={key} direction="row" gap="small">
                    <Box width="xsmall" justify="center">
                      <Typography
                        type="text"
                        size="medium"
                        testId={`firmware-key-${key}`}
                      >
                        {typeof detailKeys[key] === 'object'
                          ? detailKeys[key].keyLabel
                          : detailKeys[key]}
                      </Typography>
                    </Box>
                    <Box width="small">
                      {typeof detailKeys[key] === 'object' ? (
                        renderEditField(key)
                      ) : (
                        <Typography
                          type="text"
                          size="medium"
                          testId="firmware-value"
                        >
                          {state[key]}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </CCSForm>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => setModalOpen(false)
                },
                {
                  id: 1,
                  label: t('done'),
                  primary: true,
                  testId: 'done-btn',
                  onClick: handleDone
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => setModalOpen(false)}
    />
  )
}

EditModal.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired
}

export default EditModal
