// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const IPAccessRules = lazy(() =>
  import('../../../commoncomponents/ip-access-rules/IPAccessRules')
)

const IPAccessRulesRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<IPAccessRules />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default IPAccessRulesRouter
