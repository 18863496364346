// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, FormField, TextInput } from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Dropdown } from '../../../../components'
import { getAllMac } from '../../../../utils/manage-account-utils'

const BranchToControllerFormField = ({
  state,
  dispatch,
  customerId = undefined,
  inCCSManager = undefined
}) => {
  const { t } = useTranslation(['device'])
  const { oidcUser } = useReactOidc()
  const [listOfPrimaryConductor, setListOfPrimaryConductor] = useState([])
  const [listOfPrimaryConductorResponse, setListOfPrimaryConductorResponse] =
    useState([])

  const [listOfBackupConductor, setListOfBackupConductor] = useState([])
  const [listOfBackupConductorResponse, setListOfBackupConductorResponse] =
    useState([])

  useEffect(() => {
    const param = {
      action: 'QUERY',
      mode: 'MC',
      ...(inCCSManager && { platform_customer_id: customerId })
    }
    getAllMac({
      token: oidcUser.access_token,
      param,
      inCCSManager
    }).then((data) => {
      setListOfPrimaryConductor(data)
      setListOfPrimaryConductorResponse(data)
      setListOfBackupConductor(data)
      setListOfBackupConductorResponse(data)
    })
  }, [customerId, inCCSManager, oidcUser.access_token])

  return (
    <Box
      margin={{ bottom: 'xxsmall' }}
      direction="column"
      justify="between"
      gap="small"
    >
      <FormField
        data-testid="primary-controller-form-field"
        label={t('primary_conductor')}
        name="controller"
        width="medium"
      >
        <Dropdown
          name="controller"
          placeholder={t('select_primary')}
          options={listOfPrimaryConductor}
          value={state.controller}
          onChangeDropdown={(val) => {
            dispatch({
              value: val,
              field: 'controller',
              type: 'CHANGE_FIELD'
            })
          }}
          testId="primary-controller-input"
          onSearch={(searchText) => {
            setListOfPrimaryConductor(
              listOfPrimaryConductorResponse.filter(
                (value) => value === searchText
              )
            )
          }}
          onClose={() => {
            setListOfPrimaryConductor(listOfPrimaryConductorResponse)
          }}
        />
      </FormField>

      <FormField
        data-testid="primary-ctrl-ip-form-field"
        label={t('primary_ctrl_ip')}
        name="value"
        width="medium"
      >
        <TextInput
          data-testid="primary-ctrl-ip-input"
          name="value"
          value={state.value}
          onChange={(event) =>
            dispatch({
              value: event.target.value,
              field: 'value',
              type: 'CHANGE_FIELD'
            })
          }
        />
      </FormField>

      <FormField
        data-testid="backup-controller-form-field"
        label={t('backup_conductor')}
        name="backup_controller"
        width="medium"
      >
        <Dropdown
          name="backup_controller"
          placeholder={t('select_from_list')}
          options={listOfBackupConductor}
          value={state.backup_controller}
          onChangeDropdown={(val) => {
            dispatch({
              value: val,
              field: 'backup_controller',
              type: 'CHANGE_FIELD'
            })
          }}
          testId="backup-controller-input"
          onSearch={(searchText) => {
            setListOfBackupConductor(
              listOfBackupConductorResponse.filter(
                (value) => value === searchText
              )
            )
          }}
          onClose={() => {
            setListOfBackupConductor(listOfBackupConductorResponse)
          }}
        />
      </FormField>

      <FormField
        data-testid="backup-ctrl-ip-form-field"
        label={t('backup_controller_ip')}
        name="backup_controller_ip"
        width="medium"
      >
        <TextInput
          data-testid="backup-ctrl-ip-input"
          name="backup_controller_ip"
          value={state.backup_controller_ip}
          onChange={(event) =>
            dispatch({
              value: event.target.value,
              field: 'backup_controller_ip',
              type: 'CHANGE_FIELD'
            })
          }
        />
      </FormField>

      <FormField
        data-testid="branch-config-group-form-field"
        label={t('branch_config_group')}
        name="config_group"
        width="medium"
      >
        <TextInput
          data-testid="branch-config-group-input"
          name="config_group"
          value={state.config_group}
          onChange={(event) =>
            dispatch({
              value: event.target.value,
              field: 'config_group',
              type: 'CHANGE_FIELD'
            })
          }
        />
      </FormField>
    </Box>
  )
}

BranchToControllerFormField.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

export { BranchToControllerFormField }
