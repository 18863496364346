// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Grid, ResponsiveContext } from 'grommet'
import { useNavigate } from 'react-router-dom'
import {
  Video,
  UserAdd,
  AppsRounded,
  Cycle,
  ServerCluster,
  Close
} from 'grommet-icons'

import {
  ModalDialog,
  ModalHeader,
  Typography,
  Button
} from '../../../components'

const columns = {
  xsmall: ['auto'],
  small: ['auto'],
  medium: ['auto'],
  large: ['auto'],
  xlarge: ['auto', 'auto', 'auto']
}
const NextStepsModal = ({ setIsGuidedNextStepsModalOpen }) => {
  const { t } = useTranslation(['dashboard'])
  const size = useContext(ResponsiveContext)
  const navigate = useNavigate()
  const closeModal = () => {
    sessionStorage.removeItem('glcpGuidedWorkspace')
    setIsGuidedNextStepsModalOpen(false)
  }

  const cards = [
    {
      icon: <Video color="brand" size="xlarge" />,
      id: 1,
      title: t('dashboard:guided_workspace.video_tutorial_title'),
      text: t('dashboard:guided_workspace.video_tutorial_text'),
      route: '/home/get-started'
    },
    {
      icon: <UserAdd color="brand" size="xlarge" />,
      id: 2,
      title: t('dashboard:guided_workspace.invite_users_title'),
      text: t('dashboard:guided_workspace.invite_users_text'),
      route: '/manage-account/identity/users'
    },
    {
      icon: <AppsRounded color="brand" size="xlarge" />,
      id: 3,
      title: t('dashboard:guided_workspace.provision_services_title'),
      text: t('dashboard:guided_workspace.provision_services_text'),
      route: '/services/service-catalog'
    },
    {
      icon: <Cycle color="brand" size="xlarge" />,
      id: 4,
      title: t('dashboard:guided_workspace.add_service_subscription_title'),
      text: t('dashboard:guided_workspace.add_service_subscription_text'),
      route: '/services/service-subscriptions'
    },
    {
      icon: <Cycle color="brand" size="xlarge" />,
      id: 5,
      title: t('dashboard:guided_workspace.add_device_subscription_title'),
      text: t('dashboard:guided_workspace.add_device_subscription_text'),
      route: '/devices/subscriptions-list'
    },
    {
      icon: <ServerCluster color="brand" size="xlarge" />,
      id: 6,
      title: t('dashboard:guided_workspace.add_devices_title'),
      text: t('dashboard:guided_workspace.add_devices_text'),
      route: '/devices/inventory-list'
    }
  ]

  return (
    <ModalDialog
      testId="next-steps-modal"
      width="large"
      onClose={() => closeModal()}
      header={
        <ModalHeader
          title={
            <Box pad={{ bottom: 'small' }}>
              <Box
                direction="row"
                justify="between"
                data-testid="next-steps-modal-title"
              >
                <Typography type="heading" level="2">
                  {t('dashboard:guided_workspace.next_steps_modal_title')}
                </Typography>
                <Button
                  icon={<Close size="small" />}
                  onClick={() => closeModal()}
                />
              </Box>
              <Box data-testid="next-steps-modal-subtitle">
                <Typography type="text">
                  {t('dashboard:guided_workspace.next_steps_modal_subtitle')}
                </Typography>
              </Box>
            </Box>
          }
        />
      }
      content={
        <Box>
          <Grid columns={columns[size]} gap="medium">
            {cards?.map((item) => (
              <Box
                border
                round="xsmall"
                pad="small"
                onClick={() => {
                  if (item?.route === '/home/get-started')
                    sessionStorage.removeItem('glcpGuidedWorkspace')
                  navigate(item.route)
                }}
              >
                {item?.icon}
                <Box
                  pad={{ vertical: 'xsmall' }}
                  data-testid={`next-steps-card-title-${item?.id}`}
                >
                  <Typography type="heading" level="3">
                    {item?.title}
                  </Typography>
                </Box>
                <Typography type="text">{item?.text}</Typography>
              </Box>
            ))}
          </Grid>
        </Box>
      }
      footer={
        <Box
          width="medium"
          alignSelf="center"
          data-testid="explore-on-your-own-btn"
        >
          <Button
            label={t('dashboard:guided_workspace.explore_btn')}
            onClick={() => closeModal()}
          />
        </Box>
      }
    />
  )
}

NextStepsModal.propTypes = {
  setIsGuidedNextStepsModalOpen: PropTypes.bool.isRequired
}
export default NextStepsModal
