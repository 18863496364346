// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import lodashGet from 'lodash/get'

import VisibilityActions from '../context/visibility-context/actions'

import { get } from './api-utils'

export const isGuidedWorkspace = () => {
  return sessionStorage.getItem('glcpGuidedWorkspace')
}

export const getCustomerAccount = () => {
  return JSON.parse(sessionStorage?.getItem('account'))
}

export const setCustomerAccount = (customerAccount) => {
  sessionStorage?.setItem('account', JSON.stringify(customerAccount))
}

export const isV2 = () => {
  const v2 = getCustomerAccount()?.organization_id
  if (v2) return true
  return false
}

export const getNumCustomerAccounts = () => {
  return JSON.parse(sessionStorage.getItem('numOfCustomerAccounts'))
}

export const getMSPCustomerAccount = () => {
  return JSON.parse(sessionStorage.getItem('swapMspAccount'))
}

export const getCustomerAccountType = () => {
  const custAccountLoaded = getCustomerAccount()
  const custAccntType = custAccountLoaded?.account_type || 'NONE'

  // 'NONE' will be returned when this function is called before account selection
  return custAccntType
}

export const isMSP = () => {
  return getCustomerAccountType() && getCustomerAccountType() === 'MSP'
}

export const isCoP = () => {
  return lodashGet(window, '$settings.isCoP')
}

export const isGLOP = () => {
  return lodashGet(window, '$settings.isGLOP')
}

export const hasLP = () => {
  return isGLOP() && sessionStorage.getItem('redirectToLP')
}

export const isTenant = () => {
  return getCustomerAccountType() && getCustomerAccountType() === 'TENANT'
}

export const isStandalone = () => {
  return getCustomerAccountType() && getCustomerAccountType() === 'STANDALONE'
}

export const isOrgWorkSpace = () =>
  getCustomerAccountType() && getCustomerAccountType() === 'BASIC_ORGANIZATION'

export const isMSPTenantCoP = () => isMSP() || isTenant() || isCoP()

export const isOkta = () => {
  return lodashGet(window, '$settings.isOkta')
}

export const updateTACFlags = async (
  oidcUser,
  dispatchVisibilityContext,
  custAccount
) => {
  if (!isCoP() && !isGLOP()) {
    // As ccs-manager is not applicable for COP and SilverCreek, we skip it for both
    const url = `/support-assistant/v1alpha1/manage-access`
    return get(
      url,
      { platform_customer_id: custAccount?.platform_customer_id }, // used only in mock server, in real server the API ignores the query params
      oidcUser.access_token
    ).then(
      (response) => {
        const isTAC = response?.data?.showCCSManagerFeature
        if (isTAC) {
          dispatchVisibilityContext({
            type: VisibilityActions.SET_HIDE_WHEN,
            data: {
              key: 'account',
              value: 'TAC'
            }
          })
        }
        return isTAC
      },
      (error) => {
        console.error(error)
        return null
      }
    )
  }
  return Promise.resolve()
}

export const checkIfSupportEngineer = async (oidcUser) => {
  if (!isCoP() && !isGLOP()) {
    // As ccs-manager is not applicable for COP and SilverCreek, we skip it for both
    const url = `/support-access-manager/v1alpha1/manage-workspace-access`
    return get(url, {}, oidcUser.access_token)
  }
  return Promise.resolve()
}

export const isQACluster = () => {
  return (
    window.location.host === 'mira.ccs.arubathena.com' ||
    window.location.host === 'mira.glcp.hpedev.net'
  )
}
export const isStagingCluster = () => {
  return (
    window.location.host === 'pavo.common.cloud.hpe.com' ||
    window.location.host === 'pavo.greenlake.hpe.com'
  )
}
export const isProductCluster = () => {
  return (
    window.location.host === 'common.cloud.hpe.com' ||
    window.location.host === 'console.greenlake.hpe.com'
  )
}
export const isIntegrationCluster = () => {
  return window.location.host === 'triton.ccs.arubathena.com'
}
export const isInventoryOwnedWorkspace = () => {
  // if loaded account is not tenant, show inventory
  if (!isTenant()) return true
  // loaded account is tenant, show inventory specific UI only if operational_mode === CUSTOMER_OWNED_INVENTORY
  const custAccountLoaded = getCustomerAccount()
  return custAccountLoaded?.operational_mode === 'CUSTOMER_OWNED_INVENTORY'
}

export const getOrganizationId = () => {
  const custAccountLoaded = getCustomerAccount()
  return custAccountLoaded?.organization_id || ''
}

export const isOrg = () => {
  // if organization Id exists and value is not "", return true
  const orgId = getOrganizationId()
  return orgId && orgId !== ''
}

export const isAssociateWorkspace = (id) => {
  const custAccountLoaded = getCustomerAccount()
  return id !== custAccountLoaded?.platform_customer_id
}
