// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { StatusCritical } from 'grommet-icons'

import {
  Wizard,
  ModalDialog,
  Notification,
  Typography
} from '../../../../../../components'
import { getErrorResponse, post } from '../../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../../utils/feature-flag-utils'

import NewDetails, { validateNewDetailsPost } from './steps/WhatsNewDetails'
import ReviewPost, { validateSchedulePost } from './steps/ReviewPost'
import { getCreatePostRequestBody } from './utils'
import { SubmitForReviewModal } from './SubmitForReviewModel'

const CreatePostWizard = ({
  closeWizard,
  setAddPostMessage,
  refreshDataTable,
  isSuperUser
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()

  const [errorResponse, setErrorResponse] = useState(null)
  const [schedulePost, setSchedulePost] = useState(false)
  const [disableFinishOnSubmit, setDisableFinishOnSubmit] = useState(true)
  const [showReviewModel, setshowReviewModel] = useState(false)
  const [formData, setFormdata] = useState({})
  const [selectedReviewers, setSelectedReviewers] = useState([])
  const custAccountLoaded = getCustomerAccount()
  const [isDisabled, setIsDisable] = useState(false)
  const approvalEnabled = LDFlags['glcp-whatsnew-approval']

  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''

  const handleFinish = (formValues) => {
    const postRequestBody = getCreatePostRequestBody(formValues, false)
    post(`/whatsnew/v1/manage/blogs`, postRequestBody, oidcUser.access_token, {
      'x-request-id': uuidv4(),
      'X-Account-ID': platformCustomerId
    }).then(
      (response) => {
        if (response.status === 200) {
          closeWizard()
          setAddPostMessage(
            <Box direction="column">
              {formValues?.scheduleDate ? (
                <>
                  <Typography
                    type="text"
                    size="medium"
                    weight="bold"
                    testId="success-alert-title"
                  >
                    {t('whats_new.create_post_form.post_scheduled_success')}
                  </Typography>
                  <Typography
                    type="text"
                    size="medium"
                    testId="success-alert-desc"
                  >
                    {t('whats_new.create_post_form.scheduled_success_message', {
                      publishedDate: `${postRequestBody?.publishDate?.date} ${postRequestBody?.publishDate?.time}`
                    })}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    type="text"
                    size="medium"
                    weight="bold"
                    testId="success-alert-title"
                  >
                    {t('whats_new.create_post_form.post_published_success')}
                  </Typography>
                  <Typography
                    type="text"
                    size="medium"
                    testId="success-alert-desc"
                  >
                    {t('whats_new.create_post_form.post_success_message')}
                  </Typography>
                </>
              )}
            </Box>
          )
          refreshDataTable()
        }
      },
      (error) => {
        setErrorResponse(getErrorResponse(error, t))
        setDisableFinishOnSubmit(false)
      }
    )
  }

  const handleReviewFinish = (formValues, selectedReviewer) => {
    setDisableFinishOnSubmit(true)
    setIsDisable(true)
    const postRequestBody = getCreatePostRequestBody(
      formValues,
      true,
      selectedReviewer
    )
    post(`/whatsnew/v1/review/blogs`, postRequestBody, oidcUser.access_token, {
      'x-request-id': uuidv4(),
      'X-Account-ID': platformCustomerId
    }).then(
      (response) => {
        if (response.status === 200) {
          setshowReviewModel(false)
          closeWizard()
          setAddPostMessage(
            <Box direction="column">
              <Typography
                type="text"
                size="medium"
                weight="bold"
                testId="success-alert-title"
              >
                {t('whats_new.approval.post_approve_success')}
              </Typography>
              <Typography type="text" size="medium" testId="success-alert-desc">
                {t('whats_new.create_post_form.post_success_message')}
              </Typography>
            </Box>
          )
          refreshDataTable()
        }
      },
      (error) => {
        setErrorResponse(getErrorResponse(error, t))
        setDisableFinishOnSubmit(false)
        setIsDisable(false)
        setshowReviewModel(false)
      }
    )
  }

  const handleExit = () => {
    // Todo: once wizard allows to access formvalues on exit.
    // if (formValues?.media) {
    //   get(
    //     `/whatsnew/v1/blog/medialink?op=delete&path=${formValues?.media}`,
    //     {},
    //     oidcUser.access_token,
    //     false,
    //     {},
    //     {
    //       'X-Account-ID': platformCustomerId
    //     }
    //   ).then((response) => {
    //     if (response?.status === 204) {
    //       closeWizard()
    //     }
    //   })
    // }
    closeWizard()
  }

  const getFinalButton = () => {
    if (approvalEnabled) {
      return t('whats_new.approval.submit_for_approval')
    }
    return schedulePost
      ? t('whats_new.create_post_form.schedule_publish_btn')
      : t('whats_new.create_post_form.publish_post_button')
  }

  const getOtherActionsButtons = () => {
    if (approvalEnabled) {
      return [
        ...(schedulePost
          ? [
              {
                showInStep: 2,
                label: t('whats_new.create_post_form.cancel_schedule_btn'),
                testId: 'schedule-cancel-btn',
                handleOnClick: () => {
                  setSchedulePost(false)
                }
              },
              ...(isSuperUser
                ? [
                    {
                      showInStep: 2,
                      label: t(
                        'whats_new.create_post_form.schedule_publish_btn'
                      ),
                      testId: 'schedule-publish-btn',
                      handleOnClick: (formValues) => {
                        handleFinish(formValues)
                      },
                      primary: true
                    }
                  ]
                : [])
            ]
          : [
              {
                showInStep: 2,
                label: t('whats_new.create_post_form.schedule_publish_btn'),
                testId: 'schedule-publish-btn',
                secondary: true,
                handleOnClick: () => {
                  setSchedulePost(true)
                }
              },
              ...(isSuperUser
                ? [
                    {
                      showInStep: 2,
                      label: t(
                        'whats_new.create_post_form.publish_post_button'
                      ),
                      testId: 'publish-post-btn',
                      handleOnClick: (formValues) => {
                        handleFinish(formValues)
                      },
                      primary: true
                    }
                  ]
                : [])
            ])
      ]
    }
    return [
      ...(schedulePost
        ? [
            {
              showInStep: 2,
              label: t('whats_new.create_post_form.cancel_schedule_btn'),
              testId: 'schedule-cancel-btn',
              handleOnClick: () => {
                setSchedulePost(false)
              }
            }
          ]
        : [
            {
              showInStep: 2,
              label: t('whats_new.create_post_form.schedule_publish_btn'),
              testId: 'schedule-publish-btn',
              secondary: true,
              handleOnClick: () => {
                setSchedulePost(true)
              }
            }
          ])
    ]
  }

  return (
    <ModalDialog
      testId="create-post-wizard-modal"
      position="center"
      width="100%"
      height="100%"
      onClose={() => {}}
      content={
        <>
          <Wizard
            testId="create-post-form"
            title={t('whats_new.create_post_form.form_title')}
            actionOnExit={handleExit}
            actionOnSubmit={(formValues) => {
              if (approvalEnabled) {
                setFormdata(formValues)
                setshowReviewModel(true)
              } else {
                handleFinish(formValues)
              }
            }}
            formDefaultValues={{
              title: '',
              shortDescription: '',
              referenceLinks: [],
              summaryFlag: false
            }}
            buttonLabels={{
              // eslint-disable-next-line no-nested-ternary
              finish: getFinalButton(),
              otherActions: getOtherActionsButtons()
            }}
            disableFinishOnSubmit={disableFinishOnSubmit}
            cancelStrings={{
              continueLabel: t('whats_new.create_post_form.stay_btn'),
              exitLabel: t('whats_new.create_post_form.leave_btn'),
              heading: t('whats_new.create_post_form.exit_without_finishing'),
              text: t('whats_new.create_post_form.exit_without_finishing_desc')
            }}
            steps={[
              {
                childComponents: <NewDetails />,
                title: t('whats_new.create_post_form.Whats_new_step_title'),
                description: '',
                validateForm: (formValues) =>
                  validateNewDetailsPost(formValues, t, LDFlags)
              },
              {
                childComponents: <ReviewPost schedulePost={schedulePost} />,
                title: t('whats_new.create_post_form.review_publish_title'),
                description: t(
                  'whats_new.create_post_form.review_publish_subtitle'
                ),
                validateForm: (formValues) => {
                  return validateSchedulePost(formValues, schedulePost, t)
                }
              }
            ]}
          />

          {errorResponse && (
            <Notification
              backgroundColor="status-critical"
              icon={<StatusCritical size="medium" />}
              onClose={() => setErrorResponse(null)}
              testId="critical-notification"
              text={errorResponse?.message}
            />
          )}
          {showReviewModel && (
            <SubmitForReviewModal
              formValues={formData}
              selectedReviewers={selectedReviewers}
              setSelectedReviewers={setSelectedReviewers}
              handleFinish={handleReviewFinish}
              isDisabled={isDisabled}
              closeReviewModel={() => {
                setshowReviewModel(false)
                setDisableFinishOnSubmit(false)
              }}
            />
          )}
        </>
      }
    />
  )
}

CreatePostWizard.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  setAddPostMessage: PropTypes.func.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  isSuperUser: PropTypes.bool.isRequired
}

export default CreatePostWizard
