// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import omitBy from 'lodash/omitBy'
import { StatusCritical } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'

import {
  DataTable,
  FilterButton,
  Notification,
  ActionButton
} from '../../../../../../components'
import {
  CCSManagerActions,
  useCCSManagerContext
} from '../../../../../../context/ccs-manager-context'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  DeleteUserModal,
  ReinviteUserModal
} from '../../../../common-components/user-actions'
import {
  tableDetails,
  tableFilters,
  searchPlaceholders,
  searchValueKeys
} from '../../customer-details'
import { getTableData } from '../../customer-apis'

const UsersDataTable = ({
  customerId: custId,
  customerData: custData = undefined,
  refreshCount,
  TACusers: TACUsers = false
}) => {
  const type = 'users'
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  const { dispatchCCSManagerContext } = useCCSManagerContext()

  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [filterOptions, setFilterOptions] = useState({})
  const [showReinviteModal, setShowReinviteModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)

  const [customerId, setCustomerId] = useState(custId)
  const [, setCustomerData] = useState(custData)

  useEffect(() => {
    setCustomerId(custId)
    setCustomerData(custData)
  }, [custData, custId])

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  // commenting this since the bulk action button for "Assign roles" is commented
  // const [selectedUsers, setSelectedUsers] = useState([])

  const formatColumn = useCallback(
    (col) => {
      switch (col) {
        case 'user_status':
          return { render: (data) => t(data.user_status) }
        default:
          return false
      }
    },
    [t]
  )

  const defaultActionsColumn = [
    {
      property: 'actions',
      header: '',
      primary: true,
      render: (datum) => (
        <ActionButton
          showOneActionAsDropDown
          actions={[
            ...(custData?.account_type !== 'BASIC_ORGANIZATION'
              ? [
                  {
                    label: t('user_role_assignments.role_assignments'),
                    onClick: () => {
                      navigate(`/manage-ccs/customers/users/${datum.email}`, {
                        state: { TACusers: TACUsers }
                      })
                    },
                    visibility: {
                      rbac: {
                        resource: '/ccs/authorization',
                        permission: 'ccs.authorization.view'
                      }
                    }
                  }
                ]
              : []),
            ...(datum.user_status === 'UNVERIFIED' &&
            custData?.account_type !== 'BASIC_ORGANIZATION'
              ? [
                  {
                    label: t('reinvite'),
                    onClick: () => {
                      setSelectedRowData(datum)
                      setShowReinviteModal(true)
                    },
                    visibility: {
                      rbac: {
                        resource: '/ccs/authorization',
                        permission: 'ccs.authorization.edit'
                      }
                    }
                  }
                ]
              : []),
            ...(custData?.account_type !== 'BASIC_ORGANIZATION'
              ? [
                  {
                    label: t('delete_label'),
                    onClick: () => {
                      setSelectedRowData(datum)
                      setShowDeleteModal(true)
                    },
                    visibility: {
                      rbac: {
                        resource: '/ccs/accounts/user',
                        permission: 'ccs.accounts.user.delete'
                      }
                    }
                  }
                ]
              : [])
          ]}
          testId="users-table-action-btn"
          customRenderer={(actionBtn, visibility, idx) => {
            return (
              <VisibilityWrapper
                key={idx} // eslint-disable-line react/no-array-index-key
                rbac={visibility?.rbac}
              >
                {actionBtn}
              </VisibilityWrapper>
            )
          }}
        />
      )
    }
  ]

  const getUsersData = useCallback(() => {
    const request = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      platform_customer_id: customerId,
      ...((('user_status' in filterOptions &&
        filterOptions.user_status === 'UNVERIFIED') ||
        !('user_status' in filterOptions)) && {
        include_unverified: true
      }),
      ...filterOptions
    }

    if (searchVal.trimStart().length > 0)
      request[searchValueKeys[type]] = searchVal.trimStart()

    getTableData(
      type,
      oidcUser.access_token,
      request,
      setTotalItems,
      setErrorMessage,
      t
    ).then((data) => {
      setTableData(data)
    })
  }, [customerId, oidcUser.access_token, searchVal, filterOptions, page, t])

  useEffect(() => {
    const details = tableDetails[type]
    const updatedCols = Object.keys(details).map((col) => {
      return {
        property: col,
        header: t(`customer_details.${details[col].name}`),
        ...formatColumn(col)
      }
    })
    setColumns([...updatedCols, ...defaultActionsColumn])
    getUsersData()
  }, [formatColumn, getUsersData, t, refreshCount]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (datum) => {
    dispatchCCSManagerContext({
      type: CCSManagerActions.SET_SELECTED_USER,
      data: datum
    })
    if (TACUsers) {
      navigate(`/manage-ccs/users/${datum.email}`, {
        state: { TACUsers: true }
      })
    } else {
      navigate(`/manage-ccs/users/${datum.email}`, {
        state: { fromCustomerDetailPage: true }
      })
    }
  }

  return (
    <Box margin={{ bottom: 'small' }}>
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        filterButton={
          <FilterButton
            filterAttributes={tableFilters(t)[type]}
            onFilterValuesChange={(filters) => {
              const formattedValues = Object.fromEntries(
                Object.entries(filters).map(([key, val]) => [
                  key,
                  val.map((v) => v.trimStart()).join()
                ])
              )
              setFilterOptions(omitBy(formattedValues, (v) => v.length === 0))
              setPage(1)
            }}
            testId="users-filter-btn"
          />
        }
        grid={{
          columns,
          data: tableData,
          onClickRow: ({ datum }) => handleRowClick(datum)
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setSearchVal(value)
          },
          placeholder: searchPlaceholders(t)[type]
        }}
        summary={{ entityName: t(`customer_details.${type}`) }}
        testId="generic-data-table"
      />
      {showReinviteModal && (
        <ReinviteUserModal
          email={selectedRowData.email}
          customerId={customerId}
          customerUserName={oidcUser?.profile?.email}
          setShowModal={setShowReinviteModal}
          onSuccess={getUsersData}
        />
      )}
      {showDeleteModal && (
        <DeleteUserModal
          email={selectedRowData.email}
          customerId={customerId}
          setShowModal={setShowDeleteModal}
          refreshUsers={getUsersData}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

UsersDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  customerData: PropTypes.object,
  TACusers: PropTypes.bool,
  refreshCount: PropTypes.number.isRequired
}

export { UsersDataTable }
