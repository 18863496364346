// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { UPSActions } from '../context/ups-context'

import { updateCoreData, get } from './api-utils'
import { isGLOP, isCoP } from './feature-flag-utils'

const url = `/user-prefs/v1alpha1/preferences`
const bulkApiUrl = `/user-prefs/v1alpha1/preferences/all`

export const isUPSSupported = () => !(isGLOP() || isCoP())
export const isPathnameExcluded = (pathname) => {
  const hasPathname =
    pathname.includes('/post-onboarding/choose-account') ||
    pathname.includes('/end-session') ||
    pathname.includes('/login') ||
    pathname.includes('/sign-out') ||
    pathname.includes('/not-found') ||
    pathname.includes('/authentication/callback') ||
    pathname === '/'
  return !hasPathname
}
export const getDataBasedOnSetting = (category = [], name) => {
  const foundSetting = category?.find((setting) => setting.name === name)
  return foundSetting
}
const categoryMap = {
  commonpreferences: 'commonpreferences',
  quicklinks: 'quicklinks',
  homewidgets: 'homewidgets'
}

// Update UPS context
const updateDataToUPSContext = async (data, dispatchUPSContext) => {
  dispatchUPSContext({
    type: UPSActions.SET_UPS_DATA,
    data: {
      upsData: data
    }
  })
}
// Update data loaded flag to UPS context
const updateDataLoadedFlagToUPSContext = async (
  dataFlag,
  dispatchUPSContext
) => {
  dispatchUPSContext({
    type: UPSActions.SET_UPS_RESOLVED_FLAG,
    data: dataFlag
  })
}
// Reset UPS data in context
const resetUPSDataContext = async (dispatchUPSContext) => {
  dispatchUPSContext({
    type: UPSActions.RESET_UPS_DATA
  })
}
// Update user details to UPS context
const updateUserDetailsToUPSContext = async (data, dispatchUPSContext) => {
  dispatchUPSContext({
    type: UPSActions.SET_UPS_USER_DETAILS,
    data
  })
}
const updateUPSCallErrored = async (data, dispatchUPSContext) => {
  dispatchUPSContext({
    type: UPSActions.SET_UPS_SERVICE_ERROR,
    data
  })
}
// fetching UPS data from UPS service
const fetchUPSData = async (category, accessToken, dispatchUPSContext) => {
  try {
    const response = await get(
      url,
      {
        category
      },
      accessToken
    )
    if (response?.status === 200) {
      // dispatch UPSLoaded flag to context
      updateUserDetailsToUPSContext(
        { userName: response?.data?.userName },
        dispatchUPSContext
      )
      updateUPSCallErrored(false, dispatchUPSContext)

      if (categoryMap[category]) {
        return { [categoryMap[category]]: response?.data?.data }
      }
      if (category === categoryMap.commonpreferences) {
        updateCoreData({
          name: 'preferences',
          token: accessToken,
          data: response?.data
        })
      }
    }
  } catch (e) {
    updateUserDetailsToUPSContext({ userName: '' }, dispatchUPSContext)
    updateUPSCallErrored(true, dispatchUPSContext)
    console.error('UPS data load:', e)
  }
  return null
}
const fetchUPSDataFromBulk = async (accessToken, dispatchUPSContext) => {
  try {
    const response = await get(bulkApiUrl, {}, accessToken)
    if (response?.status === 200) {
      // dispatch UPSLoaded flag to context
      updateUserDetailsToUPSContext(
        { userName: response?.data?.userName },
        dispatchUPSContext
      )
      updateUPSCallErrored(false, dispatchUPSContext)
      return response?.data?.data
    }
  } catch (e) {
    updateUserDetailsToUPSContext({ userName: '' }, dispatchUPSContext)
    updateUPSCallErrored(true, dispatchUPSContext)
    console.error('UPS data load:', e)
  }
  return null
}

// not removing this check for easy fallback if required
const useBulkApi = true

// Get data from UPS
const getDataFromUPSAndUpdateContext = async (
  accessToken,
  dispatchUPSContext,
  LDFlags
) => {
  if (useBulkApi) {
    const upsData = await fetchUPSDataFromBulk(accessToken, dispatchUPSContext)
    Object.values(categoryMap).forEach((value) => {
      const categoryData = upsData && { [value]: upsData[value] }
      if (categoryData) {
        updateDataToUPSContext(categoryData, dispatchUPSContext)
      }
    })
  } else {
    const commonPreferencesData = await fetchUPSData(
      'commonpreferences',
      accessToken,
      dispatchUPSContext
    )

    const quickLinksData =
      LDFlags && LDFlags['glcp-customize-quick-links']
        ? await fetchUPSData('quicklinks', accessToken, dispatchUPSContext)
        : undefined

    const homeWidgetsData =
      LDFlags && LDFlags['glcp-customize-widgets']
        ? await fetchUPSData('homewidgets', accessToken, dispatchUPSContext)
        : undefined

    if (commonPreferencesData) {
      // dispatch upsData data to ups context
      updateDataToUPSContext(commonPreferencesData, dispatchUPSContext)
    }
    if (quickLinksData) {
      // dispatch upsData data to ups context
      updateDataToUPSContext(quickLinksData, dispatchUPSContext)
    }
    if (homeWidgetsData) {
      // dispatch upsData data to ups context
      updateDataToUPSContext(homeWidgetsData, dispatchUPSContext)
    }
  }
}

export const updateUPSData = async (
  oidcUser,
  dispatchUPSContext,
  LDFlags = undefined,
  isPolling = false
) => {
  if (isUPSSupported()) {
    const accessToken = oidcUser.access_token
    try {
      // reset UPS context to default context in case of switch or choose account
      if (!isPolling) await resetUPSDataContext(dispatchUPSContext)
      // make apicallinprogress true
      await updateDataLoadedFlagToUPSContext(false, dispatchUPSContext)
      // get data from UPS and update ups context
      await getDataFromUPSAndUpdateContext(
        accessToken,
        dispatchUPSContext,
        LDFlags
      )
    } catch (e) {
      console.error('UPS data load error in UPS:', e)
    } finally {
      // dispatch UPSLoaded flag to context
      updateDataLoadedFlagToUPSContext(true, dispatchUPSContext)
    }
  }
  return false
}
