// (C) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import {
  Box,
  Markdown,
  Paragraph,
  Heading,
  List,
  Anchor,
  Button,
  Footer,
  Tag
} from 'grommet'
import React from 'react'
import { Search as SearchIcon, FormNext } from 'grommet-icons'
import PropTypes from 'prop-types'

import { getQueryForList } from '../utils'
import { Typography } from '../../../../components'

export const MarkdownComponentQnA = ({ data }) => {
  return (
    <Markdown
      data-testid="markdown-component-QnA"
      components={{
        box: {
          component: Box,
          props: { pad: { left: 'medium' }, alignContent: 'start' }
        },
        h1: {
          component: Heading,
          props: { level: '1', margin: { vertical: 'xsmall' } }
        },
        h2: {
          component: Heading,
          props: { level: '2', margin: { vertical: 'xsmall' } }
        },
        h3: {
          component: Heading,
          props: { level: '3', margin: { vertical: 'xsmall' } }
        },
        h4: {
          component: Heading,
          props: { level: '4', margin: { vertical: 'xsmall' } }
        },
        p: {
          component: Paragraph,
          props: {
            fill: 'true'
          }
        }
      }}
    >
      {data}
    </Markdown>
  )
}
MarkdownComponentQnA.propTypes = {
  data: PropTypes.string.isRequired
}

const ListComponent = ({ content, handleListClick }) => {
  return (
    <List
      data={content}
      onClickItem={(event) => {
        handleListClick(event.item)
      }}
      eventType="item-select"
      pad="none"
      data-testid="autocomplete-list-QnA"
    >
      {(datum, index) => (
        <Box
          gap="small"
          pad={{ horizontal: 'xsmall', vertical: 'small' }}
          direction="row"
          align="center"
          alignContent="center"
          key={index}
          data-testid={`autocomplete-list-${index}-QnA`}
        >
          <SearchIcon id="search-icon" size="small" />
          <Typography type="text" testId={`autocomplete-label-${index}-QnA`}>
            {datum.value}
          </Typography>
        </Box>
      )}
    </List>
  )
}

ListComponent.propTypes = {
  content: PropTypes.any.isRequired,
  handleListClick: PropTypes.func.isRequired
}
export { ListComponent }

export const AdditionalResultsQnA = ({
  data,
  i18nTranslation,
  handleActionClick
}) => (
  <Box
    pad={{ bottom: 'medium' }}
    border={{
      side: 'bottom',
      color: 'border-weak',
      size: 'small'
    }}
    height="auto"
  >
    <Typography
      type="text"
      testId="additional-result-title-QnA"
      color="text-weak"
      size="xsmall"
      margin={{ vertical: 'small' }}
    >
      {i18nTranslation('global_search.documentation')}
    </Typography>
    <Box gap="medium">
      {data &&
        data.map((item) => (
          <Box gap="xsmall" key={item.title}>
            <Typography
              type="heading"
              level="3"
              testId="additional-results-title-QnA"
            >
              <Anchor
                data-testid="additional-results-action-QnA"
                href={item.link}
                target="_blank"
                label={item.title}
                onClick={() => handleActionClick(item.title, 'Documentation')}
              />
            </Typography>
            <Typography
              type="paragraph"
              maxLines={2}
              testId="additional-results-description-QnA"
              fill
            >
              <Markdown
                components={{
                  p: {
                    component: Paragraph,
                    props: {
                      fill: 'true',
                      margin: 'none'
                    }
                  }
                }}
              >
                {item.matchingLine}
              </Markdown>
            </Typography>
          </Box>
        ))}
    </Box>
  </Box>
)

AdditionalResultsQnA.propTypes = {
  data: PropTypes.array.isRequired,
  i18nTranslation: PropTypes.any.isRequired,
  handleActionClick: PropTypes.func.isRequired
}

export const SimilarMatchesQnA = ({
  data,
  handleListClick,
  i18nTranslation
}) => (
  <Box
    pad={{ bottom: 'small' }}
    border={{
      side: 'bottom',
      color: 'border-weak',
      size: 'small'
    }}
    height="auto"
  >
    <Typography
      type="text"
      testId="similar-search-title-QnA"
      color="text-weak"
      size="xsmall"
      margin={{ vertical: 'small' }}
    >
      {i18nTranslation('global_search.similar_searches')}
    </Typography>
    <ListComponent
      data-testid="similar-search-button-QnA"
      content={getQueryForList(data)}
      handleListClick={handleListClick}
    />
  </Box>
)

SimilarMatchesQnA.propTypes = {
  data: PropTypes.array.isRequired,
  handleListClick: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired
}

export const ActionResultsQnA = ({
  data,
  navigate,
  setOpenDropdown,
  i18nTranslation,
  handleActionClick
}) => (
  <Box pad={{ bottom: 'medium', top: 'small' }} height="auto">
    <Typography
      type="text"
      testId="next-steps-title-QnA"
      color="text-weak"
      size="xsmall"
      margin={{ vertical: 'small' }}
    >
      {i18nTranslation('global_search.related_links')}
    </Typography>
    {data && (
      <Box gap="small">
        <Typography type="heading" level="3" testId="action-title-QnA">
          <Anchor
            onClick={() => {
              navigate(data.actionUrl)
              setOpenDropdown(false)
              handleActionClick(data.actionTitle, 'Related Links')
            }}
            label={data.actionTitle}
            data-testid="next-steps-action-QnA"
          />
        </Typography>
        <Typography
          type="paragraph"
          maxLines={2}
          fill
          testId="action-description-QnA"
        >
          {data.actionDescription}
        </Typography>
      </Box>
    )}
  </Box>
)

ActionResultsQnA.propTypes = {
  data: PropTypes.object.isRequired,
  navigate: PropTypes.any.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired,
  handleActionClick: PropTypes.func.isRequired
}

export const FooterComponentBeta = ({ i18nTranslation }) => (
  <Box margin={{ vertical: 'small' }} pad={{ top: 'medium' }} height="auto">
    <Footer
      align="center"
      justify="end"
      gap="medium"
      background="#CBFAEB"
      round="medium"
    >
      <Box direction="column" pad="small" gap="xsmall">
        <Box direction="row">
          <Tag
            value={i18nTranslation('global_search.beta_tag')}
            testId="permission-modal-cancel-btn-QnA"
            background="green"
            border={false}
            pad={{ vertical: 'none' }}
          />
        </Box>
        <Box gap="xsmall">
          <Typography type="text" size="small">
            {i18nTranslation('global_search.beta_tag_description')}
            <Anchor
              label={i18nTranslation('global_search.beta_tag_link')}
              href="https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf"
              target="_blank"
              margin={{ left: 'xsmall' }}
            />
          </Typography>
        </Box>
      </Box>
    </Footer>
  </Box>
)

FooterComponentBeta.propTypes = {
  i18nTranslation: PropTypes.any.isRequired
}

const QnAComponent = ({
  content,
  handleListClick,
  handleActionClick,
  setOpenDropdown,
  i18nTranslation,
  navigate
}) => {
  return (
    <Box height="auto">
      {content?.searchFailed ? (
        <Box direction="column">
          <Box height="auto">
            <MarkdownComponentQnA data={content?.searchFailed} />
          </Box>
        </Box>
      ) : (
        <Box direction="column" gap="small" height="auto" flex={false} wrap>
          {content?.matchedResult?.summary && content?.actualMatch && (
            <Box
              direction="column"
              pad={{ bottom: 'medium' }}
              height="auto"
              border={{
                side: 'bottom',
                color: 'border-weak',
                size: 'small'
              }}
            >
              <Box align="center" direction="row-responsive" gap="small">
                <Typography
                  type="heading"
                  level="3"
                  testId="summary-title-QnA"
                  weight="bold"
                >
                  {i18nTranslation('global_search.summary')}
                </Typography>
              </Box>
              <Box height="auto">
                <MarkdownComponentQnA data={content?.matchedResult?.summary} />
                {content?.matchedResult?.results && (
                  <Button
                    data-testid="read-button-QnA"
                    pad={{ vertical: 'none', horizontal: 'small' }}
                    href={content?.matchedResult?.results[0].link}
                    target="_blank"
                    label={
                      <Typography
                        type="text"
                        size="medium"
                        weight="bold"
                        emphasis
                      >
                        {i18nTranslation('global_search.read_more')}
                      </Typography>
                    }
                    default
                    icon={<FormNext size="large" />}
                    alignSelf="start"
                    reverse
                    align="baseline"
                    onClick={() => handleActionClick()}
                  />
                )}
              </Box>
            </Box>
          )}

          {content?.matchedResult?.action && (
            <ActionResultsQnA
              data={content?.matchedResult?.action}
              navigate={navigate}
              setOpenDropdown={setOpenDropdown}
              i18nTranslation={i18nTranslation}
              handleActionClick={handleActionClick}
            />
          )}
          {content?.results && (
            <AdditionalResultsQnA
              data={content?.results}
              similarMatch={false}
              i18nTranslation={i18nTranslation}
              handleActionClick={handleActionClick}
            />
          )}
          {content?.similarMatch && (
            <SimilarMatchesQnA
              data={content?.similarMatches}
              handleListClick={handleListClick}
              i18nTranslation={i18nTranslation}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
QnAComponent.propTypes = {
  content: PropTypes.any.isRequired,
  handleListClick: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired,
  navigate: PropTypes.any.isRequired
}

export { QnAComponent }
