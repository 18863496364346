// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
export const subjectTypesEnum = {
  USER: 'USER',
  USER_GROUP: 'USER_GROUP'
}

export const glcpServiceID = '00000000-0000-0000-0000-000000000000'

export const attributeDefinitionsEnum = Object.freeze({
  GROUP_SCHEMA: 'urn:ietf:params:scim:schemas:core:2.0:Group',
  PATCH_OP_SCHEMA: 'urn:ietf:params:scim:api:messages:2.0:PatchOp'
})

export const permissionCustomRoleUseEnum = Object.freeze({
  ALLOW: 'ALLOW',
  DENY: 'DENY'
})

export const assignmentSubjectEnum = Object.freeze({
  user: 'User',
  'user-group': 'Group',
  'Api Client': 'API client'
})

export const HPE_MY_ACCOUNT = 'HPE MyAccount'

export const USER_SCHEMAS = Object.freeze({
  EXTENSIONS: 'urn:ietf:params:scim:schemas:extensions:hpe-greenlake:2.0:User'
})

export const roleAssignmentEnum = {
  DIRECT: 'Direct',
  SSO: 'SSO'
}

export const providerNameEnum = {
  IDENTITY: 'identity'
}

export const userStatus = {
  VERIFIED: 'VERIFIED'
}

export const MSP_ALL_CUSTOMER_WORKSPACES_GRN_SUFFIX =
  'regions/default/providers/msp/tenant-groups/00000000-0000-0000-0000-000000000000'

export const MSP_WORKSPACE_SCOPES = Object.freeze({
  NONE: 'NONE',
  ENTIRE: 'ENTIRE',
  SPECIFIC_SCOPE_GROUPS: 'SCOPE_GROUPS'
})

export const TENANT_WORKSPACE_SCOPES = Object.freeze({
  NONE: 'NONE',
  ALL: 'ALL',
  SPECIFIC_WORKSPACE_GROUPS: 'WKSPC_GROUPS'
})

export const MSP_SCOPE_ASSIGNMENT_TYPE = Object.freeze({
  MSP_WORKSPACE: 'MSP_WORKSPACE',
  CUSTOMER_ALL_CUSTOMERS: 'CUSTOMER_ALL_CUSTOMERS',
  MSP_SCOPE_GROUP: 'MSP_SCOPE_GROUP',
  CUSTOMER_WORKSPACE_GROUP: 'CUSTOMER_WORKSPACE_GROUP'
})

export const WKSPC_GROUPS_LIMIT = 10
export const SCOPE_GROUPS_LIMIT = 50
