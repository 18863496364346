// Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Layer, Box, FormField, TextInput, RadioButtonGroup } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Typography, CCSForm, Button, Dropdown } from '../../../../components'
import { post, put } from '../../../../utils/api-utils'
import { getApiErrorMessage } from '../../../../utils/error-handling-utils'

const AddBooksModal = ({
  title,
  subtitle,
  setBookModalOpen,
  onSubmitSuccess,
  selectedRow = undefined,
  mode
}) => {
  const formatPageRadioData = (num) => {
    if (num < 201) return 'small'
    if (num > 200 && num < 401) return 'medium'
    if (num > 400 && num <= 800) return 'large'
    return num
  }
  const initialState =
    mode === 'add'
      ? {
          book_id: 0,
          book_year: '',
          book_title: '',
          total_pages: '',
          rating: '3'
        }
      : selectedRow

  const [state, dispatch] = useReducer((currentState, action) => {
    switch (action.type) {
      case 'CHANGE_FIELD':
        return { ...currentState, [action.field]: action.value }
      default:
        return currentState
    }
  }, initialState)

  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['tutorial'])
  const [errorMessage, setErrorMessage] = useState('')
  const ratingList = [1, 2, 3, 4, 5]
  const pagesRadioData = [
    {
      label: t('book_page_radio_s_val'),
      value: 'small'
    },
    {
      label: t('book_page_radio_m_val'),
      value: 'medium'
    },
    {
      label: t('book_page_radio_l_val'),
      value: 'large'
    }
  ]

  const bookUrl = '/book-catalog/ui/v1/book'
  const addBookPromise = (requestBody) =>
    post(bookUrl, requestBody, oidcUser.access_token)
  const editBookPromise = (requestBody, book_id) =>
    put(`${bookUrl}?book_id=${book_id}`, requestBody, oidcUser.access_token)

  const submitBookData = () => {
    const requestBody = {
      book_id: state.book_id,
      book_year: state.book_year,
      book_title: state.book_title,
      total_pages: state.total_pages,
      rating: state.rating
    }
    const bookApiPromise =
      mode === 'add'
        ? addBookPromise(requestBody)
        : editBookPromise(requestBody, state.book_id)

    bookApiPromise.then(
      (response) => {
        if (response.status === 200) {
          setErrorMessage('')
          setBookModalOpen(false)
          onSubmitSuccess()
        }
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }
  const validateYear = (val) => {
    const regex = /^\d{4}$/
    return regex.test(val)
  }
  const validateName = (val) => {
    const min = 3
    const max = 50
    const regex = new RegExp(`^[A-Za-z0-9_ !@#$&().'’:]{${min},${max}}$`)
    return regex.test(val)
  }
  return (
    <>
      <Layer
        position="center"
        onClickOutside={() => setBookModalOpen(false)}
        onEsc={() => setBookModalOpen(false)}
        data-testid="book-modal-layer"
      >
        <Box width="medium" pad="medium" gap="small">
          <Box gap="xsmall">
            <Typography type="heading" testId="add-licenses-title" level="2">
              {title}
            </Typography>
            <Typography
              type="paragraph"
              size="small"
              testId="add-licenses-subtitle"
            >
              {subtitle}
            </Typography>
          </Box>
          <CCSForm
            value={state}
            errorMessage={errorMessage}
            testId="add-book-form"
            validate="blur"
            onSubmit={submitBookData}
            buttons={
              <Box direction="row" justify="end" gap="medium">
                <Button
                  default
                  label={t('cancel')}
                  onClick={() => {
                    setBookModalOpen(false)
                  }}
                  testId="book-add-cancel-btn"
                />
                <Button
                  primary
                  type="submit"
                  label={t('submit')}
                  testId="book-add-submit-btn"
                />
              </Box>
            }
          >
            <>
              <FormField
                name="book_year"
                label={t('book_year')}
                required
                validate={(value) => {
                  if (!validateYear(value)) return t('year_validation_text')
                  return true
                }}
              >
                <TextInput
                  margin={{ top: 'medium' }}
                  data-testid="book-year-input-field"
                  color="text-strong"
                  placeholder={t('enter_book_year')}
                  width="100%"
                  value={state.book_year}
                  onChange={(e) =>
                    dispatch({
                      value: e.target.value,
                      field: 'book_year',
                      type: 'CHANGE_FIELD'
                    })
                  }
                />
              </FormField>
              <FormField
                name="book_title"
                label={t('book_title')}
                required
                validate={(value) => {
                  if (!validateName(value)) return t('invalid_name')
                  return true
                }}
              >
                <TextInput
                  margin={{ top: 'medium' }}
                  data-testid="book-make-input-field"
                  color="text-strong"
                  placeholder={t('enter_book_title')}
                  width="100%"
                  value={state.book_title}
                  onChange={(e) =>
                    dispatch({
                      value: e.target.value,
                      field: 'book_title',
                      type: 'CHANGE_FIELD'
                    })
                  }
                />
              </FormField>
              <FormField name="total_pages" label={t('total_pages')}>
                <RadioButtonGroup
                  name="pages"
                  data-testId="total_pages_radio_btn"
                  options={pagesRadioData}
                  value={formatPageRadioData(state.total_pages)}
                  onChange={(e) =>
                    dispatch({
                      value: e.target.value,
                      field: 'total_pages',
                      type: 'CHANGE_FIELD'
                    })
                  }
                />
              </FormField>
              <FormField name="rating" label={t('book_rating')}>
                {ratingList && (
                  <Dropdown
                    name="rating"
                    options={ratingList}
                    noBorder
                    multiple={false}
                    placeholder={t('enter_book_rating')}
                    data-testId="book-rating-dropdown"
                    value={state.rating}
                    onChangeDropdown={(values) => {
                      dispatch({
                        value: values,
                        field: 'rating',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                )}
              </FormField>
            </>
          </CCSForm>
        </Box>
      </Layer>
    </>
  )
}

AddBooksModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  setBookModalOpen: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  selectedRow: PropTypes.object,
  mode: PropTypes.string.isRequired
}

export { AddBooksModal }
