// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { React, useState } from 'react'
import { Box, PageHeader, Anchor, Page, PageContent } from 'grommet'
import { Previous } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { useCCSManagerContext } from '../../../../context/ccs-manager-context'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { InviteUserModal } from '../../common-components/user-actions'
import { Button, Typography } from '../../../../components'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import { UsersDataTable } from '../customers/components/data-tables/UsersDataTable'

const TACUsersPage = () => {
  const TACUsers = true
  const [refreshCount, setRefreshCount] = useState(0)
  const { oidcUser } = useReactOidc()
  const custAccountLoaded = getCustomerAccount()
  const [inviteUserModal, setInviteUserModal] = useState(false)
  const navigate = useNavigate()
  const MoveUsers = true
  const { userCCSRoles } = useCCSManagerContext()

  const { t } = useTranslation(['manage'])
  const handleBackBtnClick = () => {
    navigate('/manage-ccs/identity')
  }
  const refreshDataTable = () => {
    setRefreshCount(refreshCount + 1)
  }
  return (
    <Box gap="medium" pad="medium" width="xxlarge">
      <Box direction="column">
        {MoveUsers && (
          <Page>
            <PageHeader
              pad={{ horizontal: 'xlarge', bottom: 'medium', top: 'small' }}
              margin={{ bottom: 'medium' }}
              title={
                <Typography level="1" type="heading" testId="sa-title">
                  {t('users_tab')}
                </Typography>
              }
              parent={
                <Anchor
                  label={t('authz:identity_access')}
                  icon={<Previous />}
                  onClick={handleBackBtnClick}
                  data-testid="identy-access-btn"
                />
              }
              actions={
                <Box>
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/authorization',
                      permission: 'ccs.authorization.edit'
                    }}
                    hideFor={
                      !(
                        userCCSRoles.includes('ccs.tac-admin') ||
                        userCCSRoles.includes('ccs.sa.sa-admin')
                      )
                    }
                  >
                    <Button
                      label={t('invite_user')}
                      testId="invite-user"
                      primary
                      onClick={() => {
                        setInviteUserModal(true)
                      }}
                    />
                  </VisibilityWrapper>
                </Box>
              }
            />
            <PageContent
              pad={{ horizontal: 'xlarge' }}
              margin={{ left: 'large' }}
            >
              <UsersDataTable
                customerId={custAccountLoaded?.platform_customer_id}
                customerData={custAccountLoaded}
                refreshCount={refreshCount}
                TACusers={TACUsers}
              />
            </PageContent>
            {inviteUserModal && (
              <InviteUserModal
                onSetOpen={setInviteUserModal}
                customerId={custAccountLoaded?.platform_customer_id}
                customerUserName={oidcUser?.profile?.email}
                onSuccess={refreshDataTable}
                customerAccountType={custAccountLoaded?.account_type}
              />
            )}
          </Page>
        )}
      </Box>
    </Box>
  )
}

export default TACUsersPage
