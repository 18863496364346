// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useReducer, useEffect } from 'react'

import ChatActions from './ChatActions'
import {
  saveChatToLocalStorage,
  loadChatFromLocalStorage,
  registerUpdateChatState
} from './ChatSync'

const initialState = {
  ...loadChatFromLocalStorage(),
  selectedButtons: {},
  feedbackFormData: {
    showFeedbackForm: false,
    rating: '',
    feedback: '',
    consent: false,
    isSubmitted: false,
    isErrorNotification: false,
    isErrorScreen: false,
    ratingError: false,
    feedbackError: false
  }
}
const CHATBOT_ERROR_MESSAGE = `The virtual assistant is currently unavailable. Please try again in a few minutes. If you need immediate assistance, create a case.`

const addMessageLoader = (chat) => {
  const lastMessage = chat?.[chat.length - 1]
  if (
    lastMessage?.from === 'Virtual Assistant' &&
    lastMessage?.messages?.[lastMessage.messages.length - 1]?.type ===
      'message_loader'
  ) {
    return chat
  }
  chat.push({
    from: 'Virtual Assistant',
    messages: [{ type: 'message_loader' }]
  })
  return chat
}

const removeProgressIndicator = (chat) => {
  if (chat?.length > 0) {
    const lastMessage = chat[chat.length - 1]
    if (
      lastMessage.from === 'Virtual Assistant' &&
      lastMessage.messages[lastMessage.messages.length - 1].type ===
        'message_loader'
    ) {
      if (lastMessage.messages.length > 1) {
        lastMessage.messages.pop()
      } else {
        chat.pop()
      }
    }
  }
  return chat
}

const removeLoaderAndAddErrorMessage = (chat) => {
  if (chat?.length > 0) {
    const lastMessage = chat[chat.length - 1]
    if (
      lastMessage.from === 'Virtual Assistant' &&
      lastMessage.messages[lastMessage.messages.length - 1].type ===
        'message_loader'
    ) {
      lastMessage.messages.pop()
    }
  }
  chat.push({
    from: 'Virtual Assistant',
    messages: [
      {
        type: 'text',
        value: CHATBOT_ERROR_MESSAGE
      }
    ]
  })

  return chat
}

const removeFeedbackRequest = (chat) => {
  if (chat?.length > 0) {
    const lastMessage = chat[chat.length - 1]
    if (
      lastMessage?.from === 'Virtual Assistant' &&
      lastMessage?.messages[0]?.text === '&%$call_feedback'
    ) {
      lastMessage.messages = lastMessage.messages.slice(1)
    }
    return chat
  }
  return null
}

const sendChatMessage = (state, action) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localDateTime = new Date()
  const options = {
    timeZone: userTimeZone,
    hour12: true,
    hour: 'numeric',
    minute: 'numeric'
  }
  const timestamp = localDateTime.toLocaleString('en-US', options)
  if (
    state?.chat?.length === 1 &&
    state.chat[0]?.messages?.[0]?.value === CHATBOT_ERROR_MESSAGE
  ) {
    return {
      ...state,
      chat: [
        {
          ...action.payload,
          id: `chat-message-${state.chat.length}`
        }
      ]
    }
  }
  return {
    ...state,
    chat: state?.chat?.concat({
      ...action.payload,
      id: `chat-message-${state.chat.length}`,
      timestamp
    })
  }
}

const setOpenState = (action) => {
  return action.payload?.open ?? true
}

const ChatReducer = (state, action) => {
  switch (action.type) {
    case ChatActions.UPDATE_CHAT:
      return sendChatMessage(state, action)
    case ChatActions.UPDATE_SELECTED_BUTTONS: {
      if (action.payload) {
        const updatedButtons = {
          ...state.selectedButtons,
          [action.payload.messageId]: action.payload.buttonValue
        }
        saveChatToLocalStorage({ ...state, selectedButtons: updatedButtons })
        return {
          ...state,
          selectedButtons: updatedButtons
        }
      }
      return {
        ...state,
        selectedButtons: []
      }
    }
    case ChatActions.ADD_MESSAGE_LOADER:
      return {
        ...state,
        chat: addMessageLoader(state.chat)
      }
    case ChatActions.REMOVE_LAST_MESSAGE:
      return {
        ...state,
        chat: removeProgressIndicator(state.chat)
      }
    case ChatActions.REMOVE_FEEDBACK_REQUEST:
      return {
        ...state,
        chat: removeFeedbackRequest(state.chat)
      }
    case ChatActions.OPEN_CHAT:
      return {
        ...state,
        open: !state.open
      }
    case ChatActions.ERROR_CONNECTING_CHAT:
      return {
        ...state,
        chat: removeLoaderAndAddErrorMessage(state.chat)
      }
    case ChatActions.UPDATE_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload
      }
    case ChatActions.UPDATE_CURRENT_POLL:
      return {
        ...state,
        currentPoll: state?.currentPoll + 1
      }
    case ChatActions.RESET_CURRENT_POLL:
      return {
        ...state,
        currentPoll: 0
      }
    case ChatActions.SET_STATE:
      return {
        ...action.payload
      }
    case ChatActions.CLEAR_STATE:
      return {
        chat: [],
        open: setOpenState(action),
        progressIndicator: false,
        currentPoll: 0,
        sessionId: null,
        selectedButtons: {}
      }
    case ChatActions.CLEAR_CONVERSATION:
      return {
        ...state,
        chat: [],
        currentPoll: 0,
        sessionId: null,
        selectedButtons: {},
        newConversation: true
      }
    case ChatActions.SYNC_USER_INPUT_TO_BUTTONS: {
      const { text } = action.payload
      const updatedSelectedButtons = { ...state.selectedButtons }
      state.chat.forEach((chatMessage) => {
        if (!chatMessage.messages?.length) {
          return
        }

        const buttonMessages = chatMessage.messages.filter(
          (message) => message.type === 'button' && message.value
        )

        const matchedButton = buttonMessages.find(
          (buttonMessage) =>
            buttonMessage.value?.toLowerCase() === text?.toLowerCase() &&
            updatedSelectedButtons[chatMessage.id] === undefined
        )

        if (matchedButton) {
          updatedSelectedButtons[chatMessage.id] = matchedButton.value
        } else if (!updatedSelectedButtons[chatMessage.id]) {
          updatedSelectedButtons[chatMessage.id] = null
        }
        if (matchedButton?.value === 'Phone') {
          localStorage.setItem('chatPhoneButtonSelected', true)
        }
      })

      saveChatToLocalStorage({
        ...state,
        selectedButtons: updatedSelectedButtons
      })

      return {
        ...state,
        selectedButtons: updatedSelectedButtons
      }
    }
    case ChatActions.UPDATE_FEEDBACK_FORM: {
      const {
        showFeedbackForm = false,
        rating,
        feedback,
        consent,
        isSubmitted = false,
        isErrorNotification = false,
        isErrorScreen = false,
        ratingError = false,
        feedbackError = false
      } = action.payload
      const updatedState = {
        ...state,
        feedbackFormData: {
          showFeedbackForm,
          rating,
          feedback,
          consent,
          isSubmitted,
          isErrorNotification,
          isErrorScreen,
          ratingError,
          feedbackError
        }
      }

      saveChatToLocalStorage(updatedState)
      return updatedState
    }

    default:
      saveChatToLocalStorage(state)
      return state
  }
}

const useChatReducer = () => {
  const initialContext =
    JSON.parse(localStorage.getItem('chatState')) || initialState
  const [ChatState, dispatchChatContext] = useReducer(
    ChatReducer,
    initialContext
  )

  useEffect(() => {
    registerUpdateChatState((newChatState) => {
      dispatchChatContext({
        type: ChatActions.SET_STATE,
        payload: newChatState
      })
    })
  }, [])

  useEffect(() => {
    const savedChatState = loadChatFromLocalStorage()
    if (savedChatState?.chat?.length > 0) {
      dispatchChatContext({
        type: ChatActions.SET_STATE,
        payload: savedChatState
      })
    }
  }, [])

  useEffect(() => {
    if (ChatState?.chat?.length > 0) {
      saveChatToLocalStorage(ChatState)
    }
  }, [ChatState])

  return { ChatState, dispatchChatContext }
}

export default useChatReducer
