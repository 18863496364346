// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import {
  Box,
  NameValueList,
  NameValuePair,
  Page,
  PageContent,
  PageHeader
} from 'grommet'
import dayjs from 'dayjs'
import { FormDown, Previous } from 'grommet-icons'

import { Layout } from '../../../../commoncomponents/layout/Layout'
import {
  ActionButton,
  Anchor,
  Loader,
  ObjectList,
  Typography
} from '../../../../components'
import NoViewPermission from '../../commoncomponents/NoViewPermission'
import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { renameKeys } from '../../../../utils/common-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { getAuthorizationText, idp } from '../constants'
import { useVisibilityContext } from '../../../../context/visibility-context'
import Markdown from '../../../../commoncomponents/Markdown'
import useOrganizationAssociation from '../../commoncomponents/useOrganizationAssociation'

import { DeleteSsoProfileModal } from './DeleteSsoProfileModal'

const ViewSsoProfileContent = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'iam'])
  const location = useLocation()
  // Allow HPE theme styling to spread onto Typography instances
  // for names in a name value pair
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)

  const [loading, setLoading] = useState(false)
  const [ssoProfileName, setSsoProfileName] = useState('')
  const [samlConfig, setSamlConfig] = useState({})
  const [samlAttributeMapping, setSamlAttributeMapping] = useState({})
  const [ssoIdp, setSsoIdp] = useState({})
  const [sessionTimeout, setSessionTimeout] = useState({})
  const [recoveryAccount, setRecoveryAccount] = useState({})
  const [apiErrorNotification, setApiErrorNotification] = useState(null)
  const [certificate, setCertificate] = useState('')
  const [showCertificate, setShowCertificate] = useState(false)

  const [ssoRoutingRule, setSsoRoutingRule] = useState(null)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  // Permissions
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const v2SsoProfilesReadPerm =
    v2RbacPolicies?.includes('identity.sso-profiles.read') &&
    v2RbacPolicies?.includes('identity.sso-routing-rules.read')
  const v2SsoProfilesUpdatePerm =
    v2RbacPolicies?.includes('identity.sso-profiles.update') &&
    v2RbacPolicies?.includes('identity.sso-routing-rules.update')
  const v2SsoProfilesDeletePerm =
    v2RbacPolicies?.includes('identity.sso-profiles.delete') &&
    v2RbacPolicies?.includes('identity.sso-routing-rules.delete')
  const v2AssociateSsoProfilesReadPerm =
    v2RbacRootWkspcPolicies?.includes('identity.sso-profiles.read') &&
    v2RbacRootWkspcPolicies?.includes('identity.sso-routing-rules.read')
  const v2AssociatedSsoProfilesUpdatePerm =
    v2RbacRootWkspcPolicies?.includes('identity.sso-profiles.update') &&
    v2RbacRootWkspcPolicies?.includes('identity.sso-routing-rules.update')
  const v2AssociatedSsoProfilesDeletePerm =
    v2RbacRootWkspcPolicies?.includes('identity.sso-profiles.delete') &&
    v2RbacRootWkspcPolicies?.includes('identity.sso-routing-rules.delete')
  const { isAssociated, isAssociateLoader } =
    useOrganizationAssociation(setNotificationInfo)

  const providerConfiguration = {
    [t('iam:sso_profiles.view_edit_create.greenlake_entity_id')]:
      idp.entityIdUrl,
    [t('iam:sso_profiles.view_edit_create.acs_url')]: idp.acsUrl,
    [t('iam:sso_profiles.view_edit_create.default_relay_state')]:
      idp.defaultRelayStateUrl
  }

  const tempI18NKeys = {
    samlConfig: {
      domains: t('iam:sso_profiles.view_edit_create.domain'),
      ssoMode: t('iam:sso_profiles.view_edit_create.authorization'),
      created_at: t('iam:sso_profiles.view_edit_create.created_at'),
      updated_at: t('iam:sso_profiles.view_edit_create.updated_at')
    },
    attrMapping: {
      email: t('iam:sso_profiles.view_edit_create.email_address'),
      firstName: t('iam:sso_profiles.view_edit_create.first_name'),
      lastName: t('iam:sso_profiles.view_edit_create.last_name'),
      ccsAttribute: t('iam:sso_profiles.view_edit_create.ccs_attribute')
    },
    samlIdpConfig: {
      entityId: t('iam:sso_profiles.view_edit_create.idp_entity_id'),
      loginUrl: t('iam:sso_profiles.view_edit_create.login_url'),
      logoutUrl: t('iam:sso_profiles.view_edit_create.logout_url')
    },
    recoveryUser: {
      username: t('iam:sso_profiles.view_edit_create.recovery_username'),
      recoveryEmail: t('iam:sso_profiles.view_edit_create.recovery_email')
    },
    sessionTimeout: {
      sessionTimeout: t('iam:sso_profiles.view_edit_create.session_timeout')
    }
  }

  const getValueFromArrayObj = (key, arr = []) => {
    return arr.find((obj) => obj.name === key)?.expression
  }

  const initialize = () => {
    // Initialize fields so we display "--" in the screen as value instead of to ignore it
    const attributeMappingConfig = renameKeys(tempI18NKeys.attrMapping, {
      email: '',
      // country: '',
      firstName: '',
      lastName: '',
      ccsAttribute: ''
    })
    setSamlAttributeMapping(attributeMappingConfig)

    const updatedSamlConfig = renameKeys(tempI18NKeys.samlConfig, {
      domains: '',
      ssoMode: '',
      created_at: null,
      updated_at: null
    })
    setSamlConfig(updatedSamlConfig)

    const timeoutConfig = renameKeys(tempI18NKeys.sessionTimeout, {
      sessionTimeout: null
    })
    setSessionTimeout(timeoutConfig)

    const recoveryUserConfig = renameKeys(tempI18NKeys.recoveryUser, {
      username: '',
      recoveryEmail: ''
    })
    setRecoveryAccount(recoveryUserConfig)
  }

  const fetchSsoProfile = useCallback(() => {
    initialize()
    setLoading(true)

    get(`/identity/v1alpha1/sso-profiles/${id}`, {}).then(
      (ssoResponse) => {
        setSsoProfileName(ssoResponse?.data.name)
        const idpConfig = renameKeys(
          tempI18NKeys.samlIdpConfig,
          ssoResponse?.data?.samlIdpConfig
        )
        setCertificate(ssoResponse?.data?.samlIdpConfig?.certificate)
        setShowCertificate(false)
        setSsoIdp(idpConfig)

        if (ssoResponse?.data?.recoveryUser) {
          const recoveryConfig = renameKeys(
            tempI18NKeys.recoveryUser,
            ssoResponse?.data?.recoveryUser
          )
          setRecoveryAccount(recoveryConfig)
        }

        if (ssoResponse?.data?.attributeMapping?.length) {
          const attributeMapping = ssoResponse?.data?.attributeMapping
          const attributeMappingConfig = renameKeys(tempI18NKeys.attrMapping, {
            email: getValueFromArrayObj('Email', attributeMapping),
            firstName: getValueFromArrayObj('FirstName', attributeMapping),
            lastName: getValueFromArrayObj('LastName', attributeMapping),
            ccsAttribute: getValueFromArrayObj(
              'HPECCSAttribute',
              attributeMapping
            )
          })
          setSamlAttributeMapping(attributeMappingConfig)

          const timeoutConfig = renameKeys(tempI18NKeys.sessionTimeout, {
            sessionTimeout: getValueFromArrayObj(
              'IdleSessionTimeout',
              attributeMapping
            )
          })
          setSessionTimeout(timeoutConfig)
        }
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setApiErrorNotification(
          displayApiError(error, t, setApiErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const fetchSsoRouting = useCallback(() => {
    setLoading(true)
    get(`/internal-identity/v1alpha1/sso-routing-rules`, {
      'sso-profile-id': id
    }).then(
      (res) => {
        const updatedSamlConfig = renameKeys(tempI18NKeys.samlConfig, {
          ...res?.data?.items[0],
          domains: res?.data?.items[0]?.conditions?.domains?.join(','),
          ssoMode: getAuthorizationText(
            res?.data?.items[0]?.actions?.idp?.ssoMode
          ),
          created_at: dayjs(res?.data?.items[0]?.updatedAt).format(
            'MM/DD/YYYY'
          ),
          updated_at: dayjs(res?.data?.items[0]?.createdAt).format('MM/DD/YYYY')
        })
        setSamlConfig(updatedSamlConfig)
        setSsoRoutingRule(res?.data?.items[0])
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setApiErrorNotification(
          displayApiError(error, t, setApiErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const { notification, notificationError } = location?.state || {}

    const message = notification ? <Markdown>{notification}</Markdown> : null
    const errorMessage = notificationError ? (
      <Markdown>{notificationError}</Markdown>
    ) : null

    if (message) {
      setNotificationInfo(message, 'info')
    } else if (errorMessage) {
      setNotificationInfo(errorMessage, 'error')
    }

    location.state = {
      ...location.state,
      notification: null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.notification, location?.state?.notificationError])

  useEffect(() => {
    fetchSsoRouting()
  }, [fetchSsoRouting])

  useEffect(() => {
    fetchSsoProfile()
  }, [fetchSsoProfile])

  const handleBackBtnClick = () => {
    navigate(`/manage-account/organization/sso-profiles`)
  }

  const ssoProfileDeleteSuccess = () => {
    navigate(`/manage-account/organization/sso-profiles`, {
      state: {
        notification: t('iam:domains.sso_delete_notification', {
          ssoProfileName
        })
      }
    })
  }

  const handleEdit = () => {
    navigate(`/manage-account/organization/sso-profiles/edit-sso-profile/${id}`)
  }

  const tableActions = () => {
    const actions = []
    const edit = {
      label: t('common:edit'),
      testId: 'edit-sso-profile-btn',
      onClick: handleEdit,
      visibility: {
        rbac: {
          permission: 'identity.sso-profiles.update'
        }
      }
    }
    const del = {
      label: t('common:delete'),
      onClick: () => {
        setShowDeleteDialog(true)
      },
      visibility: {
        rbac: {
          permission: 'identity.sso-profiles.delete'
        }
      },
      testId: 'remove-sso-profile-btn'
    }

    if (
      (!isAssociated && v2SsoProfilesUpdatePerm && v2SsoProfilesReadPerm) ||
      (isAssociated &&
        v2AssociatedSsoProfilesUpdatePerm &&
        v2AssociateSsoProfilesReadPerm)
    )
      actions?.push(edit)
    if (
      (!isAssociated && v2SsoProfilesDeletePerm && v2SsoProfilesReadPerm) ||
      (isAssociated &&
        v2AssociatedSsoProfilesDeletePerm &&
        v2AssociateSsoProfilesReadPerm)
    )
      actions?.push(del)
    return actions
  }

  const ssoProfileTitle = () => {
    return v2SsoProfilesReadPerm ? ssoProfileName : ''
  }

  return (
    <Box
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <Page>
        <PageContent>
          {loading ||
          isAssociateLoader ||
          (!isAssociated && v2SsoProfilesReadPerm === undefined) ||
          (isAssociated && v2AssociateSsoProfilesReadPerm === undefined) ? (
            <Box direction="row" align="center" justify="center" pad="medium">
              <Loader testId="saml-view-loader" />
            </Box>
          ) : (
            <>
              <PageHeader
                responsive
                parent={
                  <Anchor
                    icon={<Previous />}
                    href="#"
                    label={t('iam:sso_profiles.title')}
                    onClick={(event) => {
                      event.preventDefault()
                      handleBackBtnClick()
                    }}
                    data-testid="back-btn"
                  />
                }
                title={
                  <Typography
                    level="1"
                    type="heading"
                    testId="sso-profile-details-title"
                  >
                    {ssoProfileTitle()}
                  </Typography>
                }
                actions={
                  <Box direction="row" align="center" gap="medium">
                    {tableActions()?.length > 0 && (
                      <ActionButton
                        label={t('common:actions')}
                        reverse
                        actions={tableActions()}
                        icon={<FormDown />}
                        testId="sso-profile-details-action-btn"
                        showOneActionAsDropDown
                      />
                    )}
                  </Box>
                }
                data-testid="heading-page-title"
              />

              {(!isAssociated && v2SsoProfilesReadPerm === true) ||
              (isAssociated && v2AssociateSsoProfilesReadPerm === true) ? (
                <Box gap="small" width="large">
                  {samlConfig && (
                    <ObjectList
                      data={samlConfig}
                      showRuler={false}
                      title={t('iam:sso_profiles.view_edit_create.general')}
                      testId="samlConfig-data-list"
                    />
                  )}
                  {samlAttributeMapping && (
                    <ObjectList
                      data={samlAttributeMapping}
                      showRuler={false}
                      title={t(
                        'iam:sso_profiles.view_edit_create.saml_attribute_mappings'
                      )}
                      testId="samlAttributeMapping-data-list"
                    />
                  )}
                  {providerConfiguration && (
                    <ObjectList
                      data={providerConfiguration}
                      showRuler={false}
                      testId="providerConfiguration-data-list"
                      title={t(
                        'iam:sso_profiles.view_edit_create.identity_provider_configuration'
                      )}
                    />
                  )}
                  {ssoIdp && (
                    <>
                      <ObjectList
                        data={ssoIdp}
                        showRuler={false}
                        testId="ssoIdp-data-list"
                        title={t(
                          'iam:sso_profiles.view_edit_create.greenlake_configuration'
                        )}
                      />
                      <Box direction="row" pad={{ vertical: 'small' }}>
                        <NameValueList
                          valueProps={{ width: ['auto', 'medium'] }}
                          data-testid="folder-details"
                        >
                          <NameValuePair
                            key="certificate"
                            name={
                              <Typography
                                type="text"
                                testId="certificate-label"
                                {...name}
                              >
                                {t(
                                  'iam:sso_profiles.view_edit_create.certificate'
                                )}
                              </Typography>
                            }
                          >
                            {showCertificate ? (
                              <Box direction="column" gap="xxsmall">
                                <Anchor
                                  data-testid="devices-anchor-btn"
                                  label={t(
                                    'iam:sso_profiles.view_edit_create.hide'
                                  )}
                                  onClick={() => {
                                    setShowCertificate(!showCertificate)
                                  }}
                                />
                                <Typography
                                  size="medium"
                                  color="begin-cert"
                                  testId="details-value"
                                  type="text"
                                >
                                  {`-----${t(
                                    'iam:sso_profiles.view_edit_create.begin_cert'
                                  )}-----`}
                                </Typography>
                                <Typography
                                  size="medium"
                                  color="text-strong"
                                  testId="details-value"
                                  wordBreak="break-all"
                                  type="text"
                                >
                                  {certificate ?? '--'}
                                </Typography>
                                <Typography
                                  size="medium"
                                  color="text-strong"
                                  testId="end-cert"
                                  type="text"
                                >
                                  {`-----${t(
                                    'iam:sso_profiles.view_edit_create.end_certificate'
                                  )}-----`}
                                </Typography>
                              </Box>
                            ) : (
                              <Anchor
                                data-testid="devices-anchor-btn"
                                label={t(
                                  'iam:sso_profiles.view_edit_create.show'
                                )}
                                onClick={() => {
                                  setShowCertificate(!showCertificate)
                                }}
                              />
                            )}
                          </NameValuePair>
                        </NameValueList>
                      </Box>
                    </>
                  )}
                  {recoveryAccount && (
                    <ObjectList
                      data={recoveryAccount}
                      showRuler={false}
                      testId="recoveryAccount-data-list"
                      title={t(
                        'iam:sso_profiles.view_edit_create.recovery_user'
                      )}
                    />
                  )}
                  {sessionTimeout && (
                    <ObjectList
                      data={sessionTimeout}
                      showRuler={false}
                      testId="sessionTimeout-data-list"
                      title={t(
                        'iam:sso_profiles.view_edit_create.session_timeout'
                      )}
                    />
                  )}
                </Box>
              ) : (
                <NoViewPermission />
              )}
            </>
          )}
          {apiErrorNotification}
          {showDeleteDialog && (
            <DeleteSsoProfileModal
              id={id}
              ssoRoutingRule={ssoRoutingRule}
              setShowModal={() => setShowDeleteDialog(false)}
              onSuccess={ssoProfileDeleteSuccess}
              recoveryAccount={
                recoveryAccount[tempI18NKeys.recoveryUser.username]
              }
            />
          )}
        </PageContent>
      </Page>
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const ViewSsoProfile = () => {
  return (
    <Layout>
      <ViewSsoProfileContent />
    </Layout>
  )
}
export default ViewSsoProfile
