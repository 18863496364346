// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Trans, useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEqual from 'lodash/isEqual'

import {
  Typography,
  ButtonGroup,
  ModalDialog,
  CCSForm,
  Loader,
  Anchor
} from '../../../../components'
import { get, put } from '../../../../utils/api-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { RRPInput } from '../../assign-roles-modal/RRPInput'
import { AbandonProgressModal } from '../../assign-roles-modal/AbandonProgressModal'
import {
  getEditRRPSuccessText,
  allScopesRRPName
} from '../../../../utils/manage-account-identity-utils'
import { isMSP } from '../../../../utils/feature-flag-utils'

const EditRRPModal = ({
  setShowEditRRPModal,
  username,
  selectedRoleAssignment,
  allRoleAssignments,
  reloadTable,
  customerDetails = {},
  inCCSManager = false,
  setShowNotification = () => {}
}) => {
  const { oidcUser } = useReactOidc()
  const { t: commonT } = useTranslation(['common'])
  const { t: authzT } = useTranslation(['authz'])
  const LDFlags = useFlags()
  const rrpWorkForMSP = LDFlags['glcp-msp-rrp'] && isMSP()

  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [editModal, setEditModal] = useState(true)
  const [showAbandonProgressModal, setShowAbandonProgressModal] =
    useState(false)
  const [selectedRoleDetails, setSelectedRoleDetails] = useState({
    application_id: selectedRoleAssignment.application_id,
    ...(rrpWorkForMSP && { access_rules: selectedRoleAssignment.access_rules }),
    limit_resource_access:
      !selectedRoleAssignment?.resource_restriction_policies
        ?.map((data) => data?.name)
        ?.includes(allScopesRRPName),
    resource_restriction_policies:
      selectedRoleAssignment?.resource_restriction_policies?.map((data) => ({
        id: data?.resource_restriction_policy_id,
        name: data?.name,
        description: data?.description || ''
      })) || [],
    role_slug: selectedRoleAssignment.slug
  })
  const [allScopesRRPId, setAllScopesRRPId] = useState('')
  const successMessage = getEditRRPSuccessText(
    selectedRoleAssignment.application_name,
    authzT
  )

  const assignRoleAPICall = () => {
    setLoading(true)
    const filteredRoleAssignments = allRoleAssignments
      .map((data) => ({
        role: {
          slug: data?.slug,
          application_id: data?.application_id
        },
        ...(data.access_rules &&
          rrpWorkForMSP && {
            access_rules: data.access_rules
          }),
        ...(data.resource_restriction_policies && {
          resource_restriction_policies: data.resource_restriction_policies
            .length
            ? data.resource_restriction_policies.map(
                (rrp) => rrp?.resource_restriction_policy_id
              )
            : []
        })
      }))
      .filter(
        (data) =>
          !(
            data.role.application_id === selectedRoleDetails.application_id &&
            data.role.slug === selectedRoleDetails.role_slug
          )
      )
    const newRoleAssignment = {
      role: {
        slug: selectedRoleDetails.role_slug,
        application_id: selectedRoleDetails.application_id
      },
      resource_restriction_policies:
        selectedRoleDetails.resource_restriction_policies?.map(
          (rrp) => rrp?.id
        ) || [],
      ...(rrpWorkForMSP && { access_rules: selectedRoleDetails.access_rules })
    }
    const requestBodyRoles = {
      overwrite: [...filteredRoleAssignments, newRoleAssignment]
    }
    const requestBody = inCCSManager
      ? {
          roles: requestBodyRoles,
          username,
          platform_customer_id: customerDetails?.id
        }
      : requestBodyRoles
    const url = inCCSManager
      ? `/support-assistant/v1alpha1/user-roles`
      : `/authorization/ui/v2/customers/users/${username}/roles`

    put(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setLoading(false)
          setShowErrorNotification(null)
          setShowNotification(
            displayNotification(successMessage, 'info', setShowNotification)
          )
          setShowEditRRPModal(false)
          reloadTable()
        }
      },
      (error) => {
        setLoading(false)
        setShowErrorNotification(
          displayApiError(error, commonT, setShowErrorNotification)
        )
      }
    )
  }

  useEffect(() => {
    // get all scopes RRP id
    setAllScopesRRPId('')
    const url = inCCSManager
      ? `/support-assistant/v1alpha1/resource-restrictions`
      : `/authorization/ui/v1/resource_restrictions`

    get(
      url,
      {
        application_id: selectedRoleDetails.application_id,
        limit: 1,
        offset: 0,
        name: allScopesRRPName,
        exact_match: true,
        ...(inCCSManager && {
          platform_cid: customerDetails?.id,
          match_name: true
        })
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          const rrpData = response?.data?.policies
          if (rrpData[0]) {
            setAllScopesRRPId(rrpData[0].resource_restriction_policy_id)
            if (!selectedRoleDetails.limit_resource_access) {
              setSelectedRoleDetails((prev) => ({
                ...prev,
                resource_restriction_policies: [
                  {
                    id: rrpData[0].resource_restriction_policy_id,
                    name: allScopesRRPName
                  }
                ]
              }))
            }
          } else if (!selectedRoleDetails.limit_resource_access) {
            setSelectedRoleDetails((prev) => ({
              ...prev,
              resource_restriction_policies: []
            }))
          }
        }
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, authzT, setShowErrorNotification)
        )
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, selectedRoleDetails.application_id, authzT])

  useEffect(() => {
    setFormErrorMessage('')
  }, [
    selectedRoleDetails.limit_resource_access,
    selectedRoleDetails.resource_restriction_policies
  ])

  return (
    <>
      {showErrorNotification}
      {editModal && (
        <ModalDialog
          width="medium"
          testId="edit-rrp-modal-dialog"
          content={
            <>
              <Box margin={{ bottom: 'medium' }} gap="small">
                <Typography type="heading" level={2} testId="edit-rrp-title">
                  {authzT('rrp.edit_resource_access')}
                </Typography>
                <Typography type="text" size="large" testId="edit-rrp-message1">
                  <Trans i18nKey="rrp.edit_rrp_desc" t={authzT}>
                    <Anchor
                      label={authzT('rrp.res_restriction_policy_plural')}
                      weight="bold"
                      href="https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us"
                      target="_blank"
                      testId="rrp-documentation-anchor"
                    />
                  </Trans>
                </Typography>
              </Box>
              <CCSForm
                value={selectedRoleDetails}
                errorMessage={formErrorMessage}
                testId="edit-rrp-form"
                validate="submit"
                onSubmit={() => {
                  if (
                    selectedRoleDetails.limit_resource_access &&
                    !selectedRoleDetails.resource_restriction_policies?.length
                  ) {
                    setFormErrorMessage(authzT('rrp.no_rrp_selected_error_msg'))
                  } else if (
                    // checking if the role assignment already exists
                    isEqual(
                      selectedRoleAssignment?.resource_restriction_policies
                        ?.map((rrp) => rrp?.resource_restriction_policy_id)
                        ?.sort(),
                      selectedRoleDetails?.resource_restriction_policies
                        ?.map((rrp) => rrp?.id)
                        ?.sort()
                    )
                  ) {
                    // if role assignment already exists, we are skipping the API call, and showing notification
                    setShowNotification(
                      displayNotification(
                        successMessage,
                        'info',
                        setShowNotification
                      )
                    )
                    setShowEditRRPModal(false)
                  } else {
                    assignRoleAPICall()
                  }
                }}
                style={{ width: '100%' }}
                buttons={
                  <Box direction="row" justify="end" margin={{ top: 'medium' }}>
                    <ButtonGroup
                      buttonList={[
                        {
                          id: 1,
                          label: authzT('cancel'),
                          default: true,
                          testId: 'cancel-btn',
                          onClick: () => setShowEditRRPModal(false)
                        },
                        {
                          id: 2,
                          label: authzT('rrp.save_changes'),
                          primary: true,
                          testId: 'save-btn',
                          type: 'submit',
                          disabled: loading
                        }
                      ]}
                      testId="two-buttons"
                    />
                  </Box>
                }
              >
                <>
                  <RRPInput
                    allScopesRRPId={allScopesRRPId}
                    selectedDetails={selectedRoleDetails}
                    setSelectedDetails={setSelectedRoleDetails}
                    existingRoleAssignments={[selectedRoleAssignment]}
                    customerDetails={customerDetails}
                    inCCSManager={inCCSManager}
                    formErrorMessage={formErrorMessage}
                    setFormErrorMessage={setFormErrorMessage}
                    isEditRRPModal
                    setShowParentModal={setEditModal}
                    setShowAbandonProgressModal={setShowAbandonProgressModal}
                  />
                  {loading && (
                    <Box direction="row" align="center" justify="center">
                      <Loader testId="assign-role-loader" />
                    </Box>
                  )}
                </>
              </CCSForm>
            </>
          }
          onClose={() => setShowEditRRPModal(false)}
        />
      )}
      {showAbandonProgressModal && (
        <AbandonProgressModal
          setShowModal={setShowAbandonProgressModal}
          setShowParentModal={setEditModal}
        />
      )}
    </>
  )
}

EditRRPModal.propTypes = {
  setShowEditRRPModal: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  selectedRoleAssignment: PropTypes.object.isRequired,
  allRoleAssignments: PropTypes.array.isRequired,
  reloadTable: PropTypes.func.isRequired,
  customerDetails: PropTypes.object,
  inCCSManager: PropTypes.bool,
  setShowNotification: PropTypes.func
}

export default EditRRPModal
