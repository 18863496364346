// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import dayjs from 'dayjs'

export const dateRangeMap = {
  '1h': {
    tickCountY: 6,
    tickFormat: 'h:mm A',
    skipInterval: 1,
    actualCount: 24,
    toolTipFormat: 'h:mm A'
  },
  '3h': {
    tickCountY: 6,
    tickFormat: 'h:mm A',
    skipInterval: 1,
    actualCount: 24,
    toolTipFormat: 'h:mm A'
  },
  '24h': {
    tickCountY: 6,
    tickFormat: 'h:mm A',
    skipInterval: 1,
    actualCount: 24,
    toolTipFormat: 'h:mm A'
  },
  '7d': {
    tickCountY: 6,
    tickFormat: 'MM/DD',
    skipInterval: 0,
    actualCount: 7,
    toolTipFormat: 'MMM D, YYYY'
  },
  '30d': {
    tickCountY: 6,
    tickFormat: 'MM/DD',
    skipInterval: 3,
    actualCount: 30,
    toolTipFormat: 'MMM D, YYYY'
  },
  '90d': {
    tickCountY: 6,
    tickFormat: 'MM/DD',
    skipInterval: 10,
    actualCount: 90,
    toolTipFormat: 'MMM D, YYYY'
  }
}

export const getIntervalForTrendChart = (interval) => {
  const intervalMap = {
    'Last Hour': '1h',
    'Last 3 Hours': '3h',
    'Last Day': '24h',
    'Last Week': '7d',
    'Last Month': '30d',
    'Last 3 Months': '90d'
  }

  return intervalMap[interval] || '1h'
}

export const getTime = (state) => {
  const timeToReturn = {
    startTime: '',
    endTime: dayjs().unix()
  }

  const dayjsInstance = dayjs
  if (state.time && state.time.length > 0) {
    switch (state.time[0]) {
      case 'Last Hour': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'h').unix()
        break
      }
      case 'Last 3 Hours': {
        timeToReturn.startTime = dayjsInstance().subtract(3, 'h').unix()
        break
      }
      case 'Last Day': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'd').unix()
        break
      }
      case 'Last Week': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'w').unix()
        break
      }
      case 'Last Month': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'M').unix()
        break
      }
      case 'Last 3 Months': {
        timeToReturn.startTime = dayjsInstance().subtract(3, 'M').unix()
        break
      }
      case 'Last 6 Months': {
        timeToReturn.startTime = dayjsInstance().subtract(6, 'M').unix()
        break
      }
      case 'Last 1 Year': {
        timeToReturn.startTime = dayjsInstance().subtract(365, 'd').unix()
        timeToReturn.endTime = dayjsInstance()
          .subtract(1, 'd')
          .endOf('day')
          .unix()
        break
      }
      case 'Custom Date Range': {
        timeToReturn.endTime = state['Custom Date Range']
          ? dayjsInstance(state['Custom Date Range'][1]).endOf('day').unix()
          : dayjsInstance().endOf('day').unix()
        timeToReturn.startTime = state['Custom Date Range']
          ? dayjsInstance(state['Custom Date Range'][0]).startOf('day').unix()
          : dayjsInstance().subtract(90, 'd').startOf('day').unix()
        break
      }
      default: {
        timeToReturn.startTime = dayjsInstance().subtract({ M: 3 }).unix()
        break
      }
    }
  }

  return timeToReturn
}
