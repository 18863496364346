// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Anchor, Box, Grid, Button, Layer } from 'grommet'
import { Edit } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Card, Notification, Typography, Loader } from '../../../../components'
import { useVisibilityContext } from '../../../../context/visibility-context'
import { visibilityPermissions } from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import Markdown from '../../../../commoncomponents/Markdown'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { isServiceCentric } from '../../../../utils/account-utils'
import { useUPSContext } from '../../../../context/ups-context'

import EditQuickLinkModel from './EditQuickLinkModel'
import { HomeQuickActionsData } from './quick-links-data'

export const QuickLinks = () => {
  const { quicklinks } = useUPSContext()
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  const location = useLocation()
  const [modalOpen, setModalOpen] = useState(false)
  const { hideWhen, rbacPolicies, v2RbacPolicies } = useVisibilityContext()
  const [quickLinksUPSData, setQuickLinksUPSData] = useState([])
  const [updateSuccesfull, setUpdateSuccesfull] = useState(false)
  const [defaultQuickLinks, setdefaultQuickLinks] = useState([])
  const [showLoader, setLoader] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState(null)
  const [notificationTitle, setNotificationTitle] = useState(null)

  useEffect(() => {
    setNotificationTitle(location?.state?.notificationTitle || null)
    const message = location?.state?.notificationDesc ? (
      <Markdown>{location?.state?.notificationDesc}</Markdown>
    ) : null
    setNotificationMessage(message)
    navigate(location.pathname, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  const handlePanelContentClick = (routeTo, hrefTo) => {
    if (routeTo) {
      navigate(routeTo)
    } else if (hrefTo) {
      window.open(hrefTo, '_blank')
    }
  }

  const quickActionPanel = HomeQuickActionsData()
  useEffect(() => {
    const filterRBACHiddenAndCustomLogic = () => {
      // filter rbac here
      const data = quickActionPanel.filter((item) => {
        const { hideFor, rbac } = item
        const filteredLinks = visibilityPermissions({
          hideFor,
          rbac,
          rbacPolicies,
          hideWhen,
          v2RbacPolicies
        })
        return filteredLinks
      })
      const res = data.filter((item) => !item.hidden)
      return res
    }
    setdefaultQuickLinks(filterRBACHiddenAndCustomLogic())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideWhen, rbacPolicies])

  useEffect(() => {
    // GET API call to UPS to get Quicklinks information
    setLoader(true)
    try {
      if (quicklinks) {
        setQuickLinksUPSData(
          defaultQuickLinks.map((item) => {
            const matchingItem = quicklinks.find(
              (link) => link.linkKey === item.linkKey
            )
            return {
              ...item,
              visible: matchingItem.visible
            }
          })
        )
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, modalOpen, defaultQuickLinks, quicklinks])

  return (
    <Box>
      {notificationMessage &&
        displayNotification(
          notificationMessage,
          'info',
          setNotificationMessage,
          notificationTitle
        )}
      {updateSuccesfull && (
        <Notification
          testId="quicklink-success-notification"
          status="normal"
          text={t('dashboard.quick_links_card.update_success')}
          onClose={() => setUpdateSuccesfull(false)}
        />
      )}
      <Grid columns="auto" gap="medium">
        {modalOpen && quickLinksUPSData && (
          <EditQuickLinkModel
            setUpdateSuccesfull={setUpdateSuccesfull}
            onSetOpen={setModalOpen}
            quickActionPanel={quickLinksUPSData}
          />
        )}
        {showLoader && (
          <Layer
            position="center"
            data-testid="edit-quick-link-loader-layer"
            plain
          >
            <Loader testId="loader-spinner" />
          </Layer>
        )}
        <Card
          CustomContent={
            <>
              <Box
                width="100%"
                justify="between"
                direction="row"
                margin={{
                  left: 'small',
                  top: newDashboardFlag ? 'none' : 'medium'
                }}
              >
                <Typography
                  type="heading"
                  level={2}
                  testId="right-panel-quickaction-pretitle"
                >
                  {t('dashboard.quick_links_card.title')}
                </Typography>
                <Button
                  a11yTitle={t(`common:edit`)}
                  icon={<Edit color="brand" />}
                  plain
                  onClick={() => {
                    setModalOpen(true)
                  }}
                  data-testid="edit-quick-links-btn"
                />
              </Box>
              {quickLinksUPSData.some((item) => item.visible) ? (
                quickLinksUPSData.map(
                  (item) =>
                    item.visible && (
                      <Anchor
                        key={item.id}
                        data-testid={item.testId}
                        label={t(item.title)}
                        margin={{ left: 'small', top: 'small' }}
                        onClick={() => {
                          handlePanelContentClick(item.routeTo, item.hrefTo)
                        }}
                      />
                    )
                )
              ) : (
                <Box
                  width="100%"
                  margin={{
                    left: 'small',
                    top: 'xsmall'
                  }}
                >
                  <Typography
                    type="paragraph"
                    size="medium"
                    testId="empty-quicklinks-msg"
                  >
                    {t(
                      'dashboard.quick_links_card.edit_quick_links.empty_quicklink_message'
                    )}
                  </Typography>
                </Box>
              )}
            </>
          }
          testId="quick-actions-card"
        />
      </Grid>
    </Box>
  )
}
