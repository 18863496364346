// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, Markdown, Notification, Paragraph } from 'grommet'
import PropTypes from 'prop-types'
import {
  ChatOption,
  StatusGood,
  CircleAlert,
  StatusWarning
} from 'grommet-icons'
import styled, { ThemeContext } from 'styled-components'

import { Typography } from '../../components'

import ChatBotButtonsContainer from './ChatBotButtonsContainer'
import { useChatContext, ChatActions } from './context/chat-context'

const CaseSuccessMessage = 'Case successfully created.'

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(1, 169, 130, 1);
  animation: dotAnimation
    ${(props) =>
      `${props.sync} ${props.duration}s ${props.delay}s infinite ease-in-out`};
  &:nth-child(2) {
    animation-delay: 200ms;
  }
  &:nth-child(3) {
    animation-delay: 400ms;
  }
`

const DotAnimation = `
  @keyframes dotAnimation {
    0% {
      opacity: 0.3;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-6px);
    }
    100% {
      opacity: 0.3;
      transform: translateY(0);
    }
  }
`

export const MessageLoader = () => {
  return (
    <Box
      background="rgb(255, 255, 255)"
      style={{
        padding: '9px 24px 9px 24px',
        flexDirection: 'row',
        gap: '6px'
      }}
      round="8px"
      width="xsmall"
      height="xxxsmall"
      margin={{ bottom: 'xxsmall' }}
      data-testid="message-loader-container"
    >
      <Dot sync="alternate" duration="1.4" delay="0" />
      <Dot sync="alternate" duration="1.4" delay="1" />
      <Dot sync="alternate" duration="1.4" delay="2" />
      <style>{DotAnimation}</style>
    </Box>
  )
}

const components = {
  ul: ({ children }) => (
    <ul
      data-testid="chatUlElement"
      style={{
        listStyle: 'initial',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '24px',
        wordWrap: 'break-word'
      }}
    >
      {children}
    </ul>
  ),
  ol: ({ children }) => (
    <ol
      data-testid="chatOlElement"
      style={{
        marginTop: '0px',
        marginBottom: '24px',
        fontSize: '18px',
        paddingLeft: '15px',
        fontWeight: 400,
        lineHeight: '24px',
        wordWrap: 'break-word'
      }}
    >
      {children}
    </ol>
  ),
  strong: ({ children }) => (
    <strong data-testid="chatStrongElement" style={{ fontWeight: 550 }}>
      {children}
    </strong>
  ),
  a: ({ href, children, onClick }) => {
    const isExternalLink = href?.startsWith('https')
    const isSupportHubLink = href?.startsWith('/')

    return (
      <a
        href={href}
        target={
          isExternalLink
            ? '_blank'
            : isSupportHubLink
            ? `support-${uuidv4()}`
            : '_self'
        }
        rel={isExternalLink && 'noopener noreferrer'}
        data-testid="chatbot-message-supporthub-link"
        style={{
          cursor: 'pointer',
          boxSizing: 'border-box',
          display: 'inline-block',
          color: 'rgb(51, 51, 51)',
          fontWeight: 500
        }}
        onClick={(e) => {
          if (!(isExternalLink || isSupportHubLink)) {
            e.preventDefault()
            onClick(href)
          }
        }}
      >
        {children}
      </a>
    )
  }
}

export const messageContainsText = (chatResponse) => {
  if (chatResponse?.messages !== undefined) {
    if (
      chatResponse?.messages?.filter((b) => {
        return b.type === 'text'
      })?.length > 0
    )
      return true
  }
  return false
}

export const messageContainsButton = (chatResponse) => {
  if (chatResponse?.messages !== undefined) {
    if (
      chatResponse?.messages?.filter((b) => {
        return b.type === 'button' || b.type === 'button_link'
      })?.length > 0
    )
      return true
  }
  return false
}

export const messageContainsProgressIndicator = (chatResponse) => {
  const messageWithProgressIndicator =
    chatResponse?.messages !== undefined
      ? chatResponse?.messages?.some(
          (message) => message?.type === 'message_loader'
        )
      : false
  return messageWithProgressIndicator
}

export const messageContainsYesButton = (chatResponse) => {
  const includeYesButton = chatResponse?.messages?.some(
    (message) => message?.type === 'button' && message?.value === 'Yes'
  )
  return includeYesButton
}

export const ChatMessageFromBotDisplayText = ({
  text,
  includesThumbsUpButton,
  index,
  textMessage
}) => {
  const { setShowSidePanel, dispatchChatContext } = useChatContext()
  const handleAnchorClick = async (title) => {
    setShowSidePanel(true)
    dispatchChatContext({
      type: ChatActions.OPEN_CHAT,
      payload: { open: false }
    })

    dispatchEvent(
      new CustomEvent('context-help', {
        detail: { topic: `${title}` }
      })
    )
  }

  return (
    <Box
      background="background-front"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      width={
        messageContainsButton &&
        includesThumbsUpButton &&
        index === textMessage?.length - 1
          ? { max: 'fit-content', min: '20rem' }
          : { max: 'fit-content', min: '14rem' }
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start',
        align: 'start',
        gap: '12px',
        alignSelf:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start'
      }}
      round="8px"
      margin={{ bottom: 'xsmall' }}
    >
      <Typography
        type="text"
        size="medium"
        weight="normal"
        data-testid="chatTypographyElement"
      >
        <Markdown
          components={{
            ...components,
            a: ({ href, children }) => (
              <components.a href={href} onClick={handleAnchorClick}>
                {children}
              </components.a>
            )
          }}
          data-testid="chatbot-message-from-bot"
        >
          {text?.value}
        </Markdown>
      </Typography>
    </Box>
  )
}

export const ChatMessageFromBotTriggerWarning = ({ txt }) => {
  return (
    <ThemeContext.Extend
      value={{
        notification: {
          iconContainer: {
            pad: { top: 'xxsmall', right: 'xsmall' }
          }
        }
      }}
    >
      <Box
        flex
        direction="row"
        align="start"
        round="xsmall"
        margin={{ bottom: 'xsmall' }}
        data-testid="chat-message-from-bot-trigger-warning"
      >
        <Notification
          status="warning"
          icon={<StatusWarning color="status-warning" />}
          message={
            <Typography type="text" alignSelf="start">
              {txt.replace(
                '$&#&triggerwarning',
                'Navigating away from this page will close the Virtual Assistant. Click the link to pin the FAQ in the side panel.'
              )}
            </Typography>
          }
        />
      </Box>
    </ThemeContext.Extend>
  )
}

export const ChatMessageFromBotFailureMessage = ({
  failureTxt,
  includesThumbsUpButton,
  index,
  textMessage
}) => {
  const failureCleanedText = failureTxt.replace('$%#&failure', '').trim()
  return (
    <Box
      background="validation-critical"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      width={
        messageContainsButton &&
        includesThumbsUpButton &&
        index === textMessage?.length - 1
          ? { max: 'fit-content', min: '20rem' }
          : { max: 'fit-content', min: '14rem' }
      }
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start',
        align: 'start',
        alignSelf:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start'
      }}
      round="8px"
      margin={{ bottom: 'xsmall' }}
      data-testid="chat-message-from-bot-failures-message"
    >
      <Paragraph margin="none">
        <Box
          as="span"
          direction="row"
          align="center"
          margin={{ right: 'xsmall' }}
          pad={{ bottom: 'xxsmall' }}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            verticalAlign: 'middle'
          }}
        >
          <CircleAlert size="small" color="status-critical" />
        </Box>
        <Markdown
          components={components}
          data-testid="Chat-Message-From-Bot-Failure-Markdown-Message"
          options={{ forceInline: true }}
          style={{
            display: 'inline'
          }}
        >
          {failureCleanedText}
        </Markdown>
      </Paragraph>
    </Box>
  )
}

export const ChatMessageFromBotSuccessMessage = ({
  successTxt,
  includesThumbsUpButton,
  index,
  textMessage
}) => {
  const successCleanedText = successTxt.replace('$%#&success', '').trim()
  return (
    <Box
      background="background-front"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      width={
        messageContainsButton &&
        includesThumbsUpButton &&
        index === textMessage?.length - 1
          ? { max: 'fit-content', min: '20rem' }
          : { max: 'fit-content', min: '14rem' }
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start',
        alignSelf:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start'
      }}
      round="8px"
      margin={{ bottom: 'xsmall' }}
      data-testid="chat-message-from-bot-success-message"
    >
      <Box
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
      >
        <StatusGood size="small" color="brand" />
        <Typography
          type="text"
          size="medium"
          weight="normal"
          data-testid="chatTypographyElementFromBotSuccessMessage"
          margin={{ left: 'xsmall' }}
        >
          {CaseSuccessMessage}
        </Typography>
      </Box>
      <Typography
        type="text"
        size="medium"
        weight="normal"
        data-testid="chatTypographyElementFromBotSuccessMessage"
      >
        <Markdown
          components={components}
          data-testid="Chat-Message-From-Bot-Success-Message"
          options={{ forceInline: true }}
        >
          {successCleanedText}
        </Markdown>
      </Typography>
    </Box>
  )
}

const ChatMessageFromBot = ({ chatResponse, client }) => {
  const formattedTime = chatResponse.timestamp
  const { feedbackFormData, dispatchChatContext } = useChatContext()

  const [textMessage] = useState(
    chatResponse?.messages?.filter((message) => {
      return message?.type === 'text'
    })
  )

  const [buttonsMessage] = useState(
    chatResponse?.messages?.filter((message) =>
      ['button', 'button_link'].includes(message?.type)
    )
  )

  const includesThumbsUpButton = chatResponse?.messages
    ?.filter((b) => {
      return b.type === 'button'
    })
    ?.map((b) => b.value)
    .includes('ThumbsUp')

  const includesThumbsUpOrThunmpsDownButton = chatResponse?.messages?.some(
    (message) =>
      message?.type === 'button' &&
      ['ThumbsUp', 'ThumbsDown'].includes(message?.text)
  )

  useEffect(() => {
    messageContainsProgressIndicator(chatResponse)
  }, [chatResponse])

  return (
    chatResponse?.messages?.length > 0 && (
      <>
        <Box
          width="100%"
          direction="column"
          align="start"
          data-testid="chat3"
          flex="grow"
        >
          <Box
            width="100%"
            direction="row"
            alignSelf="start"
            align="start"
            gap="6px"
            flex="grow"
            pad={{ top: '0', right: '36px', bottom: '0', left: '0' }}
          >
            <Box
              fill="vertical"
              justify="end"
              width="30px"
              style={{ minHeight: '0px', minWidth: '30px' }}
            >
              <Box
                width="1.75rem"
                height="1.75rem"
                round="full"
                background="brand"
                align="center"
                justify="center"
                margin={{ bottom: '5px' }}
                style={{ minHeight: '30px', minWidth: '30px', padding: '7px' }}
              >
                <ChatOption
                  aria-label="Virtual Asistant Avatar"
                  data-testid="chatbot-message-avatar"
                  color="white"
                  size="small"
                />
              </Box>
            </Box>
            <Box style={{ width: 'fit-content' }}>
              {messageContainsText &&
                textMessage?.map((text, index) => {
                  const isTriggerwarningMessage =
                    text?.value?.includes('$&#&triggerwarning')
                  const isSuccessMessage = text?.value?.includes('$%#&success')
                  const isFailureMessage = text?.value?.includes('$%#&failure')
                  const isNewConversation = text?.value?.includes(
                    '&%$new_conversation'
                  )
                  const isFeedbackRequest =
                    text?.value?.includes('&%$call_feedback')
                  if (isNewConversation) {
                    dispatchChatContext({
                      type: ChatActions.CLEAR_CONVERSATION
                    })
                  }
                  if (isTriggerwarningMessage) {
                    return (
                      <ChatMessageFromBotTriggerWarning txt={text?.value} />
                    )
                  }
                  if (isSuccessMessage) {
                    return (
                      <ChatMessageFromBotSuccessMessage
                        successTxt={text?.value}
                        includesThumbsUpButton={includesThumbsUpButton}
                        textMessage={textMessage}
                        index={index}
                        key={text?.value}
                      />
                    )
                  }
                  if (isFailureMessage) {
                    return (
                      <ChatMessageFromBotFailureMessage
                        failureTxt={text?.value}
                        includesThumbsUpButton={includesThumbsUpButton}
                        textMessage={textMessage}
                        index={index}
                        key={text?.value}
                      />
                    )
                  }
                  if (isFeedbackRequest) {
                    dispatchChatContext({
                      type: ChatActions.UPDATE_FEEDBACK_FORM,
                      payload: {
                        ...feedbackFormData,
                        showFeedbackForm: true
                      }
                    })
                  }
                  return (
                    <ChatMessageFromBotDisplayText
                      text={text}
                      includesThumbsUpButton={includesThumbsUpButton}
                      textMessage={textMessage}
                      index={index}
                    />
                  )
                })}
              {messageContainsProgressIndicator(chatResponse) && (
                <Box>
                  <MessageLoader />
                </Box>
              )}
            </Box>
          </Box>

          {includesThumbsUpButton && (
            <Box
              background="background-front"
              pad={{ horizontal: 'medium', vertical: 'small' }}
              width={{ max: 'fit-content', min: '100%' }}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              round="8px"
              margin={{ bottom: 'xsmall' }}
            >
              {includesThumbsUpButton && (
                <Typography
                  type="text"
                  size="18px"
                  weight="normal"
                  margin={{ left: 'auto', right: 'auto' }}
                >
                  <Markdown
                    data-testid="chatbot-message-from-bot"
                    components={components}
                    options={{ forceInline: true }}
                  >
                    {textMessage[textMessage?.length - 1].value}
                  </Markdown>
                </Typography>
              )}
              {includesThumbsUpButton && (
                <Box margin={{ top: '25px', bottom: '0px' }} align="center">
                  <ChatBotButtonsContainer
                    buttons={['ThumbsUp', 'ThumbsDown']}
                    client={client}
                    includesThumbsUpButton
                  />
                </Box>
              )}
            </Box>
          )}
          <Box
            width="auto"
            direction="row"
            justify="flex-start"
            align="center"
            data-testId="chatbot-message-footer"
            gap="xxsmall"
            pad={{ top: '0', right: '0', bottom: 'small', left: '36px' }}
          >
            {!messageContainsProgressIndicator(chatResponse) && (
              <Typography type="text" size="xsmall">
                {chatResponse?.from}
              </Typography>
            )}
            {!messageContainsProgressIndicator(chatResponse) && (
              <Typography type="text" size="xsmall">
                {formattedTime}
              </Typography>
            )}
          </Box>
        </Box>

        {messageContainsButton && buttonsMessage?.length > 0 && (
          <Box
            data-testid="chatmessage-button-container"
            width={{ max: '100%' }}
            direction="column"
            pad={{ top: '0', right: '0px', bottom: '0', left: '0px' }}
            align="end"
            alignSelf="end"
            gap="small"
            flex="grow"
            margin={{ bottom: 'small' }}
          >
            {!includesThumbsUpOrThunmpsDownButton &&
              buttonsMessage?.length > 0 && (
                <ChatBotButtonsContainer
                  buttons={buttonsMessage}
                  messageId={chatResponse?.id}
                  client={client}
                />
              )}
          </Box>
        )}
      </>
    )
  )
}

export default ChatMessageFromBot

ChatMessageFromBot.propTypes = {
  chatResponse: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
}

ChatMessageFromBotTriggerWarning.propTypes = {
  txt: PropTypes.object.isRequired
}

ChatMessageFromBotDisplayText.propTypes = {
  text: PropTypes.object.isRequired,
  includesThumbsUpButton: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  textMessage: PropTypes.array.isRequired
}

ChatMessageFromBotSuccessMessage.propTypes = {
  successTxt: PropTypes.object.isRequired,
  includesThumbsUpButton: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  textMessage: PropTypes.array.isRequired
}

ChatMessageFromBotFailureMessage.propTypes = {
  failureTxt: PropTypes.object.isRequired,
  includesThumbsUpButton: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  textMessage: PropTypes.array.isRequired
}
