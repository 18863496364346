// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Page, PageContent, PageHeader, Anchor, Text } from 'grommet'
import { Previous } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { DataTable } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { getPaginationShowIdx } from '../../../utils/common-utils'

const OnPremWorkspacesContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'manage'])
  const [workspaceData, setWorkspaceData] = useState([])
  const [apiErrorMsg, setApiErrorMsg] = useState('')
  // TODO: Uncomment when backend supports sort property in future
  // const [sortProps, setSortProps] = useState({
  //   property: 'onpremWorkspaceName',
  //   direction: 'asc',
  //   external: true
  // })
  // for pagination
  const itemsPerPage = 20
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)
  const { oidcUser } = useReactOidc()
  const columns = [
    {
      align: 'start',
      header: t('manage:org_workspace_edit.workspace_name'),
      property: 'onPremWorkspaceName',
      render: (datum) => {
        return (
          <Box width="small">
            <Text truncate="tip">{datum?.onPremWorkspaceName}</Text>
          </Box>
        )
      }
    },
    {
      align: 'start',
      header: t('manage:onprem_workspace.workspace_id'),
      property: 'onPremWorkspaceId',
      render: (datum) => {
        return (
          <Box width="small">
            <Text truncate="tip">{datum?.onPremWorkspaceId}</Text>
          </Box>
        )
      }
    },
    {
      align: 'start',
      header: t('manage:onprem_workspace.subscription_key'),
      property: 'subscriptionKey'
    }
  ]
  const handleBackBtnClick = () => {
    navigate('/manage-account')
  }
  // TODO: Uncomment when backend supports sort property in future
  // const sortProp = { onPremWorkspaceName: 'onprem_workspace_name' }

  useEffect(() => {
    get(
      '/ui-doorway/ui/v1/onprem-workspaces',
      {
        // TODO: Uncomment when backend supports sort property in future
        // sort_by: sortProp[sortProps?.property],
        // direction: sortProps?.direction
        limit: itemsPerPage,
        offset: page - 1
      },
      oidcUser.access_token
    ).then(
      (response) => {
        setWorkspaceData(response?.data?.items)
        setTotalItems(response?.data?.total)
      },
      (error) => {
        setApiErrorMsg(error)
      }
    )
  }, [oidcUser.access_token, page])

  return (
    <Page>
      <PageContent>
        <PageHeader
          title={t('manage:onprem_workspace.onprem_workspace_title')}
          subtitle={t('manage:onprem_workspace.onprem_workspace_subtitle')}
          parent={
            <Anchor
              label={t('manage:manage_account')}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              data-testid="go-to-manage-account-btn"
            />
          }
          data-testid="onprem-home-header"
        />
        <Box margin={{ bottom: 'small' }} width="large">
          <DataTable
            pagination={{
              totalItems,
              itemsPerPage,
              page,
              setPage,
              pageIdxInfo
            }}
            grid={{
              columns,
              data: workspaceData,
              onClickRow: (data) => {
                navigate(
                  `/manage-account/on-prem-workspaces/${data?.datum?.id}`
                )
              }
              // TODO: Uncomment when backend supports sort property in future
              // onSort: ({ property, direction, external }) => {
              //   setSortProps({ property, direction, external })
              // }
            }}
            testId="onprem-workspace-table"
          />
        </Box>
      </PageContent>
      {apiErrorMsg && displayApiError(apiErrorMsg, t, setApiErrorMsg)}
    </Page>
  )
}

const OnPremWorkspaces = () => (
  <Layout>
    <OnPremWorkspacesContent />
  </Layout>
)

export default OnPremWorkspaces
