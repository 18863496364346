// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box, PageHeader } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { Previous } from 'grommet-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import {
  Anchor,
  Button,
  DataTable,
  Loader,
  Typography
} from '../../../../components'
import { Layout } from '../../../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { getPaginationShowIdx } from '../../../../utils/common-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import NoViewPermission from '../../../igc-service/commoncomponents/NoViewPermission'
import { useVisibilityContext } from '../../../../context/visibility-context'
import { isMSP } from '../../../../utils/feature-flag-utils'

import AddUserModal from './AddUserModal'
import MSPAddUserModal from './MSPAddUserModal'

const Users = () => {
  const { v2RbacPolicies } = useVisibilityContext()
  const v2UserAccViewAllPerm = v2RbacPolicies?.includes(
    'ccs.accounts.user.view-all'
  )
  const { t } = useTranslation(['common', 'iam', 'auditlogs', 'authz'])
  const location = useLocation()
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [searchVal, setSearchVal] = useState('')

  // for pagination
  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')

  const setNotificationInfo = ({ message, severity, title = '' }) => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const handleBackBtnClick = () => {
    navigate('/manage-account/identity')
  }

  const handleRowClick = (datum) => {
    navigate(`/manage-account/identity/users/${datum?.global_id}`)
  }

  const onCompleteAddUser = (response) => {
    setRefreshCount((prevRefreshCount) => prevRefreshCount + 1)
    setNotificationInfo(response)
  }

  const getUserFullName = (datum) => {
    const firstName = datum?.first_name
    const lastName = datum?.last_name
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || '--'
  }

  const columns = [
    {
      property: 'full_name',
      header: t('iam:users_table_headers.name'),
      primary: true,
      render: (datum) => {
        return (
          <Box direction="row" align="center" gap="small">
            <Typography
              type="text"
              wordBreak="break-all"
              testId="user-display-name"
              emphasis
            >
              {getUserFullName(datum)}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'email',
      header: t('iam:users_table_headers.email')
    },
    {
      property: 'user_status',
      header: t('iam:users_table_headers.verified'),
      render: (datum) => {
        return datum?.user_status === 'VERIFIED'
          ? t('iam:verified_status_yes')
          : t('iam:verified_status_no')
      }
    },
    {
      property: 'last_login',
      header: t('iam:users_table_headers.last_login'),
      render: (datum) => {
        return datum?.last_login
          ? `${dayjs(datum?.last_login).format('MMM DD, YYYY')}, ${dayjs(
              datum?.last_login
            ).format('hh:mm A')}`
          : '--'
      }
    }
  ]

  useEffect(() => {
    if (location?.state?.notificationText) {
      const message = (
        <Typography type="text">
          <Trans>{location?.state?.notificationText}</Trans>
        </Typography>
      )
      setNotificationInfo({
        message,
        severity: 'info',
        title: location?.state?.notificationTitle
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.notificationText])

  useEffect(() => {
    let isCurrent = true
    const request = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      ...(searchVal.trimStart().length > 0 && {
        search_string: searchVal.trimStart()
      }),
      include_unverified: true
    }
    try {
      setLoading(true)
      get(`/ui-doorway/ui/v2/um/users`, request, oidcUser.access_token).then(
        (response) => {
          if (!isCurrent) return
          dayjs.extend(customParseFormat)
          setUsersList(response?.data?.users)
          setTotalItems(response?.data?.pagination?.total_count)
          setLoading(false)
        },
        (error) => {
          if (!isCurrent) return
          setNotificationInfo({
            message: getErrorMessage(error, t),
            severity: 'error'
          })
          setLoading(false)
        }
      )
    } catch (error) {
      setNotificationInfo(getErrorMessage(error, t))
      setLoading(false)
    }
    return () => {
      isCurrent = false
    }
  }, [refreshCount, oidcUser.access_token, page, searchVal, t, itemsPerPage])

  return (
    <Box
      data-testid="workspace-users-page"
      width="xlarge"
      alignSelf="center"
      margin="small"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
    >
      <PageHeader
        title={
          <Typography type="heading" level="1" testId="users-page-title">
            {t('iam:users.Workspace_users_heading')}
          </Typography>
        }
        responsive
        subtitle={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.accounts.user.view-all'
            }}
          >
            <Typography
              testId="users-page-subtitle"
              size="xlarge"
              type="paragraph"
            >
              {t('iam:igc_users.subtitle')}
            </Typography>
          </VisibilityWrapper>
        }
        margin={{ bottom: 'medium' }}
        parent={
          <Anchor
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            testId="users-back-btn"
            href="#"
            margin={{ bottom: 'xsmall' }}
            icon={<Previous />}
            label={t('iam:workspace_identity.title', {
              Workspace: t('common:business_object:wkspc_capitalized')
            })}
          />
        }
      />
      {v2UserAccViewAllPerm ? (
        <DataTable
          pagination={{
            totalItems,
            itemsPerPage,
            setItemsPerPage,
            rowDropDownLabel: t('auditlogs:items_per_page'),
            defaultRowsValue: itemsPerPage,
            page,
            setPage,
            pageIdxInfo,
            rowCountOptions: [20, 50, 100, 200]
          }}
          grid={{
            columns,
            data: usersList,
            onClickRow: (event) => {
              handleRowClick(event?.datum)
            }
          }}
          loading={loading}
          search={{
            onSearchValueChange: (value) => {
              setPage(1)
              setSearchVal(value)
            },
            placeholder: t('common:search'),
            width: 'large'
          }}
          button={
            <Box align="end" width="medium">
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.accounts.platform.customer.edit'
                }}
              >
                <VisibilityWrapper
                  rbac={{
                    permission: 'ccs.authorization.edit'
                  }}
                >
                  <Button
                    secondary
                    label={t('iam:igc_users.add_user_btn')}
                    onClick={() => {
                      setShowAddUserDialog(true)
                    }}
                    testId="data-table-add-user-btn"
                  />
                </VisibilityWrapper>
              </VisibilityWrapper>
            </Box>
          }
          testId="user-data-table"
        />
      ) : (
        <>
          {v2UserAccViewAllPerm === false ? (
            <NoViewPermission />
          ) : (
            <Loader testId="perm-loader" />
          )}
        </>
      )}

      {showAddUserDialog &&
        (isMSP() ? (
          <MSPAddUserModal
            onSetOpen={setShowAddUserDialog}
            setStatus={onCompleteAddUser}
          />
        ) : (
          <AddUserModal
            onSetOpen={setShowAddUserDialog}
            setStatus={onCompleteAddUser}
          />
        ))}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const UsersPage = () => {
  return (
    <Layout>
      <Users align="start" justify="start" />
    </Layout>
  )
}

export default UsersPage
