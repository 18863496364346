// Copyright 2025 Hewlett Packard Enterprise Development LP
import uniqBy from 'lodash/uniqBy'

import servicesData from '../config/services.json'
import { get, post } from '../../../../utils/api-utils'
import { GLCP_FORM, GREENLAKE_SERVICES, SAAS } from '../../constants'

export const fetchServices = (t) => {
  const supportedServices = []
  try {
    if (servicesData?.services?.length) {
      let other = {}
      servicesData.services.forEach((service) => {
        const serviceOption = {
          value: t(service.locale_key),
          name: service.name,
          category: t(service.category_locale_key),
          id: service.id,
          static: service.static,
          slug: service.slug,
          type: service.type,
          show_contact_preferences: service.show_contact_preferences
        }
        if (service.id === 'other') {
          other = serviceOption
        } else {
          supportedServices.push(serviceOption)
        }
      })
      supportedServices.sort((firstService, secondService) =>
        firstService.value.localeCompare(secondService.value)
      )
      supportedServices.push(other)
      return {
        id: servicesData.id,
        locale_key: servicesData.locale_key,
        placeholder_locale_key: servicesData.placeholder_locale_key,
        search_locale_key: servicesData.search_locale_key,
        api_name: servicesData.api_name,
        required: servicesData.required,
        supportedServices: [...supportedServices]
      }
    }
    return { supportedServices: [...supportedServices] }
  } catch {
    return { supportedServices: [...supportedServices] }
  }
}

// Escaping regular expression special characters: [ \ ^ $ . | ? * + ( )
const getEscapedText = (query) => query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

// Create the regular expression with escaped special characters.
const formatSearchExpression = (query) => new RegExp(getEscapedText(query), 'i')

export const onSearch = (query, initialOptions) => {
  const searchRegExp = formatSearchExpression(query)
  return initialOptions.filter((option) => searchRegExp.test(option.value))
}

export const prepareSubmitRequestBody = async (
  formData,
  loggedInUserContact,
  selectedPrimaryContact,
  selectedAlternateContact,
  productInfo,
  workspaceId
) => {
  const subjectDelimiter = ' || '
  const createdBy = {
    email: loggedInUserContact?.email,
    firstName: loggedInUserContact?.firstName,
    lastName: loggedInUserContact?.lastName,
    phone: '',
    mobileNumber: '',
    locale: ''
  }
  const primaryContact = {
    email: selectedPrimaryContact?.email,
    firstName: selectedPrimaryContact?.firstName,
    lastName: selectedPrimaryContact?.lastName,
    phone: formData?.['Primary Contact Phone'],
    mobileNumber: '',
    locale: 'en_US'
  }
  let alternateContact = {}
  if ('email' in selectedAlternateContact) {
    alternateContact = {
      email: selectedAlternateContact?.email,
      firstName: selectedAlternateContact?.firstName,
      lastName: selectedAlternateContact?.lastName,
      phone: formData?.['Alternate Contact Phone'],
      mobileNumber: '',
      locale: 'en_US'
    }
  }
  let subject = `${formData?.issue_title}${subjectDelimiter}${formData?.service?.value}`
  if (formData?.service_category) {
    subject = `${subject}${subjectDelimiter}${formData.service_category?.value}`
  }
  if (formData?.service_sub_category) {
    subject = `${subject}${subjectDelimiter}${formData.service_sub_category?.value}`
    const serviceCategoryItem =
      formData.service_sub_category?.value?.toLowerCase()
    if (formData?.[serviceCategoryItem]) {
      subject = `${subject}${subjectDelimiter}${formData?.[serviceCategoryItem]?.value}`
    }
  }
  let description =
    `${formData?.describe_issue}\n` +
    `Primary Contact FirstName: ${primaryContact?.firstName}\n` +
    `Primary Contact LastName: ${primaryContact?.lastName}\n` +
    `Primary Contact Email: ${primaryContact?.email}\n` +
    `Primary Contact Phone: ${primaryContact?.phone}\n` +
    `Primary Contact Preference: ${formData?.['Primary Contact Preference']}\n` +
    `Alternate Contact FirstName: ${alternateContact?.firstName}\n` +
    `Alternate Contact LastName: ${alternateContact?.lastName}\n` +
    `Alternate Contact Email: ${alternateContact?.email}\n` +
    `Alternate Contact Phone: ${alternateContact?.phone}\n` +
    `Alternate Contact Preference: ${formData?.['Alternate Contact Preference']}\n` +
    `Username: ${formData?.username}\n` +
    `Preferred time zone: ${formData?.protection_store?.value}\n` +
    `Is Protection Store gateway Installed: ${formData?.protection_store_installed?.value}\n` +
    `HPE Account Manager or HPE Sales Name: ${formData?.hpe_sales_name}\n` +
    `HPE Account Manager or HPE Sales Email ID: ${formData?.hpe_sales_email_id}\n` +
    `HPE Sold To Party ID's to be used by API: ${formData?.hpe_sales_party_id}\n` +
    `Region of the API: ${formData?.api_region?.value}\n` +
    `Are you a Customer, Reseller, Distributor or other?: ${formData?.customer_type?.value}\n` +
    `Local Gateway ID: ${formData?.local_gateway_id}\n` +
    `Service name: ${formData?.service_name}\n` +
    `Workspace name: ${formData?.workspace_name}\n` +
    `Opportunity ID: ${formData?.opportunity_id}\n` +
    `Order number ${formData?.order_number}\n` +
    `Invoice ID: ${formData?.invoice_id}\n` +
    `Account name: ${formData?.account_name}\n` +
    `API name: ${formData?.api_name}\n` +
    `Tenant name: ${formData?.tenant_name}\n` +
    `Affected user: ${formData?.affected_user}\n` +
    `Contract identifier: ${formData?.contract_identifier}`

  subject = subject.length > 255 ? subject.substring(0, 255) : subject
  description =
    description.length > 32768 ? description.substring(0, 32768) : description
  let severity
  if (formData?.severity?.value) {
    switch (formData.severity.value) {
      case 'High (widespread impact - business, timelines, etc)':
        severity = '1_CRITICAL'
        break
      case 'Medium (service impacted partially; not down)':
        severity = '2_MEDIUM'
        break
      case 'Low (no impact to business)':
        severity = '3_NORMAL'
        break
      default:
        severity = ''
        break
    }
  }

  const requestBody = {
    subject,
    description,
    troubleshootingStepsTaken: formData?.troubleshooting,
    workspaceId,
    caseType: GREENLAKE_SERVICES,
    serviceName: formData?.service?.value,
    aasType: productInfo?.current?.product_type
      ? productInfo.current.product_type.toUpperCase()
      : SAAS,
    createdBy,
    primaryContact,
    primaryContactPrefContactMethod: formData?.['Primary Contact Preference'],
    alternateContact,
    altContactPrefContactMethod: formData?.['Alternate Contact Preference'],
    severity,
    subscriptionKey: formData?.subscription_key_text,
    orderId: formData?.order_number,
    invoiceId: formData?.invoice_id,
    aasProductNumber: productInfo?.current?.product_number
      ? productInfo.current.product_number
      : '',
    aasProductName: productInfo?.current?.product_name
      ? productInfo.current.product_name
      : '',
    aasProductLine: productInfo?.current?.product_line_code
      ? productInfo.current.product_line_code
      : '',
    deviceSerialNumber: formData?.serial_number,
    deviceProductNumber: '',
    client: GLCP_FORM,
    productNamespace: productInfo?.current?.namespace
      ? productInfo.current.namespace
      : ''
  }
  return requestBody
}

export const submitCase = async (
  access_token,
  formData,
  primaryContact,
  alternateContact,
  caseFormContextData,
  productInfo,
  workspaceId
) => {
  try {
    const { loggedInUserContact } = caseFormContextData
    const requestBody = await prepareSubmitRequestBody(
      formData,
      loggedInUserContact,
      primaryContact,
      alternateContact,
      productInfo,
      workspaceId
    )
    const response = await post(
      `/support-cases/v1alpha1/cases`,
      requestBody,
      access_token
    )
    return response
  } catch {
    return null
  }
}

export const uploadCaseFiles = async (
  access_token,
  metadata,
  reqFile,
  userId
) => {
  try {
    const formData = new FormData()
    formData.append('metadata', JSON.stringify(metadata))
    formData.append('file', reqFile)
    const response = await post(
      `/support-cases/v1alpha1/cases/${userId}/files`,
      formData,
      access_token,
      {
        'Content-Type': 'multipart/form-data'
      }
    )
    return response
  } catch {
    return null
  }
}

export const fetchUsers = async (access_token, search_string) => {
  let usersData = []
  let serverSideSearch = false
  let errorMessage = ''
  try {
    const request = {
      ...(search_string?.trimStart().length && {
        search_string: search_string.trimStart()
      }),
      user_activity_status: 'ACTIVE',
      include_unverified: false
    }
    const response = await get(
      `/ui-doorway/ui/v2/um/users`,
      request,
      access_token
    )
    if (response?.data) {
      const { users, pagination } = response.data
      if (users) {
        const usersResponse = [...response.data.users]
        usersData = usersResponse.map((eachUser) => {
          return {
            name: `${eachUser.first_name} ${eachUser.last_name}`,
            firstName: eachUser.first_name,
            lastName: eachUser.last_name,
            email: eachUser.email,
            value: eachUser.first_name + eachUser.last_name + eachUser.email
          }
        })
      }
      if (
        (pagination?.count_per_page &&
          pagination.total_count !== pagination.count_per_page) ||
        (pagination?.limit && pagination.total_count !== pagination.limit)
      ) {
        serverSideSearch = true
      }
    } else {
      errorMessage = 'support_cases:contact.issue_when_retrieving_list_of_users'
    }
  } catch (error) {
    errorMessage = 'support_cases:contact.issue_when_retrieving_list_of_users'
  }
  return { usersData, serverSideSearch, errorMessage }
}

export const onSort = (initialOptions) => {
  return initialOptions.sort((firstOption, secondOption) =>
    firstOption.name.localeCompare(secondOption.name)
  )
}

export const onSortUnique = (initialOptions) => {
  const uniqueOptions = uniqBy(initialOptions, 'value')
  return uniqueOptions.sort((firstOption, secondOption) =>
    firstOption.name.localeCompare(secondOption.name)
  )
}

export function getEnvName() {
  const envName = window.location.host.split('.')[0]
  const aquilaNames = ['common', 'console']
  return aquilaNames.includes(envName) ? 'aquila' : envName
}
