// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalHeader,
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  Typography,
  CCSForm
} from '../../../components'
import { handleToggle } from '../api-utils'

const DisableIPAccessRuleModal = ({
  setDisableIPAccessRuleModal,
  onSuccess,
  isFromCCSManger = false,
  customerId = ''
}) => {
  const { t } = useTranslation(['authn', 'common'])

  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = () => {
    const enabled = false
    const successMsg = t('ip_access_rules.disabled_succes_msg')
    handleToggle(
      enabled,
      oidcUser.access_token,
      setDisableIPAccessRuleModal,
      setErrorMessage,
      t,
      onSuccess,
      successMsg,
      isFromCCSManger,
      customerId
    )
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="1" testId="modal-title">
              {t('ip_access_rules.disabled_access_rules_title')}
            </Typography>
          }
        />
      }
      content={
        <CCSForm
          errorMessage={errorMessage}
          testId="disable-ip-access-rules-form"
        >
          <>
            <Box gap="medium" margin={{ top: 'xsmall' }}>
              <Typography
                type="text"
                size="large"
                testId="disable-access-rules-desc-1"
              >
                {t('ip_access_rules.disabled_access_desc_1')}
              </Typography>
              <Typography
                type="text"
                size="large"
                testId="disable-access-rules-desc-2"
              >
                {t('ip_access_rules.disabled_access_rules_title')}?
              </Typography>
            </Box>
            <Box margin={{ vertical: 'small' }}>
              <ModalFooter
                right={
                  <ButtonGroup
                    buttonList={[
                      {
                        id: 2,
                        label: t('common:cancel'),
                        default: true,
                        testId: 'cancel-btn',
                        onClick: () => {
                          setDisableIPAccessRuleModal(false)
                        }
                      },
                      {
                        id: 1,
                        label: t('ip_access_rules.disabled_access_rules_title'),
                        primary: true,
                        testId: 'disable-ip-access-rules-btn',
                        onClick: handleSubmit
                      }
                    ]}
                    testId="two-buttons"
                  />
                }
              />
            </Box>
          </>
        </CCSForm>
      }
      onClose={() => {
        setDisableIPAccessRuleModal(false)
      }}
      testId="disable-ip-access-modal"
      width="large"
    />
  )
}

DisableIPAccessRuleModal.propTypes = {
  setDisableIPAccessRuleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isFromCCSManger: PropTypes.bool,
  customerId: PropTypes.string
}

export { DisableIPAccessRuleModal }
