// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, FormField, TextInput } from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { getAllCountries } from '../../../../utils/manage-account-utils'
import { Dropdown, Typography } from '../../../../components'

import { IAPSwitchControllerFormField } from './IAPSwitchControllerForField'
import { BranchToControllerFormField } from './BranchToControllerFormField'
import { ManagedDeviceToControllerFormField } from './ManagedDeviceToControllerFormField'

const ProvisioningRuleFormField = ({
  state,
  dispatch,
  setErrorMessage,
  customerId = undefined,
  inCCSManager = undefined
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const listOfProvisioningType = [
    { label: t('iap_to_airwave'), value: 'iap' },
    { label: t('iap_to_rap'), value: 'iap_to_rap' },
    { label: t('iap_to_cap'), value: 'iap_to_cap' },
    { label: t('iap_to_third_party_server'), value: 'iap_to_thirdparty' },
    { label: t('switch_to_airwave'), value: 'switch_to_amp' },
    { label: t('conductor_to_airwave'), value: 'contrl_to_amp' },
    {
      label: t('branch_to_mobility_conductor'),
      value: 'branch_to_master_controller'
    },
    {
      label: t('managed_device_to_mobility_conductor'),
      value: 'managed_node_to_master_controller'
    }
  ]

  const ProvisioningTypeEnum = {
    iap: t('iap_to_airwave'),
    iap_to_rap: t('iap_to_rap'),
    iap_to_cap: t('iap_to_cap'),
    iap_to_thirdparty: t('iap_to_third_party_server'),
    switch_to_amp: t('switch_to_airwave'),
    contrl_to_amp: t('conductor_to_airwave'),
    branch_to_master_controller: t('branch_to_mobility_conductor'),
    managed_node_to_master_controller: t(
      'managed_device_to_mobility_conductor'
    ),
    iap_to_athena: t('iap_to_central')
  }
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])

  useEffect(() => {
    let isCurrent = true
    getAllCountries(
      t,
      isCurrent,
      setListOfCountries,
      setCountries,
      setErrorMessage
    )
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, t, setErrorMessage])

  return (
    <Box
      margin={{ bottom: 'xxsmall' }}
      direction="column"
      justify="between"
      gap="small"
    >
      <FormField
        data-testid="provisioning-type-form-field"
        label={t('provisioning_type')}
        name="sub_type"
        width="medium"
        required={!state.edit}
      >
        {state.edit ? (
          <Typography
            testId="provisioning-type-input"
            type="text"
            size="medium"
            margin={{ horizontal: 'small', vertical: 'xsmall' }}
          >
            {ProvisioningTypeEnum[state.sub_type] || state.sub_type}
          </Typography>
        ) : (
          <Dropdown
            name="sub_type"
            placeholder={t('select_from_list')}
            options={listOfProvisioningType}
            labelKey="label"
            value={state.sub_type}
            valueKey={{ key: 'value', reduce: true }}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: 'sub_type',
                type: 'CHANGE_FIELD'
              })
            }}
            testId="provisioning-type-input"
          />
        )}
      </FormField>
      {[
        'iap',
        'iap_to_rap',
        'iap_to_cap',
        'iap_to_thirdparty',
        'switch_to_amp',
        'contrl_to_amp'
      ].includes(state.sub_type) && (
        <IAPSwitchControllerFormField
          state={state}
          dispatch={dispatch}
          customerId={customerId}
          inCCSManager={inCCSManager}
        />
      )}
      {state.sub_type === 'branch_to_master_controller' && (
        <BranchToControllerFormField
          state={state}
          dispatch={dispatch}
          customerId={customerId}
          inCCSManager={inCCSManager}
        />
      )}
      {state.sub_type === 'managed_node_to_master_controller' && (
        <ManagedDeviceToControllerFormField
          state={state}
          dispatch={dispatch}
          customerId={customerId}
          inCCSManager={inCCSManager}
        />
      )}
      {[
        'branch_to_master_controller',
        'managed_node_to_master_controller'
      ].includes(state.sub_type) && (
        <FormField
          label={t('country_code')}
          name="country_code"
          required
          data-testid="country-code-form-field"
        >
          <Dropdown
            testId="country-code-form-input"
            name="country_code"
            placeholder={t('select_from_list')}
            options={countries}
            multiple={false}
            labelKey="name"
            value={state.country_code}
            valueKey={{ key: 'code', reduce: true }}
            searchPlaceholder={t('country')}
            emptySearchMessage={t('no_countries_found')}
            onSearch={(searchText) => {
              const regexp = new RegExp(searchText, 'i')
              setCountries(listOfCountries.filter((o) => o.name.match(regexp)))
            }}
            onChange={({ option }) => {
              dispatch({
                value: option.code,
                field: 'country_code',
                type: 'CHANGE_FIELD'
              })
            }}
            onClose={() => setCountries(listOfCountries)}
          />
        </FormField>
      )}
      {state.sub_type === t('iap_to_athena') && (
        <FormField
          data-testid="central-ip-form-field"
          label={t('central_ip')}
          name="value"
          width="medium"
        >
          <TextInput
            data-testid="central-ip-input"
            name="value"
            value={state.value}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: 'value',
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      )}
    </Box>
  )
}

ProvisioningRuleFormField.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

export { ProvisioningRuleFormField }
