// Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useCallback } from 'react'
import { Box } from 'grommet'
import {
  Previous,
  Desktop,
  Cart,
  Group,
  Unlock,
  AppsRounded,
  Folder,
  Contract,
  StatusCritical,
  History,
  ShieldSecurity,
  CircleInformation,
  UserNew,
  UserAdmin,
  ObjectGroup,
  Location,
  DocumentUser
} from 'grommet-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import omit from 'lodash/omit'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import {
  Typography,
  Button,
  Notification,
  Loader,
  ToggleButton,
  Tooltip,
  AvatarInfo
} from '../../../../../components'
import {
  CCSManagerActions,
  useCCSManagerContext
} from '../../../../../context/ccs-manager-context'
import { useVisibilityContext } from '../../../../../context/visibility-context'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import { CreateFolderModal } from '../../../../../commoncomponents/folder-details/CreateFolderModal'
import {
  DevicesDataTable as ActivateDevicesDataTable,
  SubscriptionsDataTable
} from '../../../common-components'
import { getCustomerDetails } from '../customer-apis'
import { AddDevicesModalCommon } from '../../../../../commoncomponents/activate-add-devices/AddDevicesModalCommon'
import { ExportDevicesModal } from '../../../../../commoncomponents/export-devices-datatable/ExportDevicesModal'
import { GenerateSubscriptionModal } from '../../../common-components/subscription-actions'
import IPAccessRules from '../../../../../commoncomponents/ip-access-rules/IPAccessRules'
import {
  DisableIPAccessRuleModal,
  EnableIPAccessRuleModal
} from '../../../../../commoncomponents/ip-access-rules/components'
import { InviteUserModal } from '../../../common-components/user-actions'
import { activateTACRoles } from '../../../utils'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../../utils/common-utils'
import { EditCustomerModal } from '../../../common-components/customer-actions'
import { customRenderer } from '../../../../../commoncomponents/CustomRenderer'
import { ManageAutoSubscriptionModal } from '../../../common-components/auto-subscription/ManageAutoSubscriptionModal'

import BillingAccountWizard from './billing-account-wizard/BillingAccountWizard'
import {
  ApplicationsDataTable,
  OrdersDataTable,
  UsersDataTable,
  FoldersDataTable,
  AliasesDataTable,
  CustomerLogsDataTable,
  TenantsDataTable,
  RolesDataTable,
  RRPDataTable,
  LocationsDataTable,
  BillingAccountDataTable
} from './data-tables'
import { AddAliasModal } from './AddAliasModal'
import { ZTOToggleModal } from './ZTOToggleModal'

const CustomerDetails = () => {
  const navigate = useNavigate()
  const { rbacPolicies } = useVisibilityContext()
  const { t } = useTranslation([
    'manage',
    'device',
    'common',
    'authn',
    'location'
  ])
  const { oidcUser } = useReactOidc()
  const { selectedCustomer, dispatchCCSManagerContext, userCCSRoles } =
    useCCSManagerContext()
  const isActivateTAC = userCCSRoles.some((value) =>
    activateTACRoles.includes(value)
  )

  const applicationsRbac = {
    resource: '/ccs/app-catalog/application',
    permission: 'ccs.app-catalog.view'
  }

  const showApplicationsTab =
    rbacPolicies?.effects?.[applicationsRbac.resource]?.[
      applicationsRbac.permission
    ]
  const { state: location } = useLocation()
  const tabName = location?.tabName

  const [customerData, setCustomerData] = useState(selectedCustomer)
  const [type, setType] = useState(tabName || 'customer-logs')
  const [focus, setFocus] = useState(true)
  const [createFolderModal, setCreateFolderModal] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [showAliasModal, setAliasModal] = useState(false)
  const [ztoToggleVisible, setZtoToggleVisible] = useState(false)
  const [addDevicesModal, setAddDevicesModal] = useState(false)
  const [inviteUserModal, setInviteUserModal] = useState(false)
  const [exportDevicesModal, setExportDevicesModal] = useState(false)
  const [generateSubscriptionModal, setGenerateSubscriptionModal] =
    useState(false)
  const [editCustomerModal, setEditCustomerModal] = useState(false)
  const [showAutoSubscribeModal, setShowAutoSubscribeModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(Object.keys(customerData).length === 1)
  const [showNotification, setShowNotification] = useState(null)
  const [enableIPAccessRuleModal, setEnableIPAccessRuleModal] = useState(false)
  const [disableIPAccessRuleModal, setDisableIPAccessRuleModal] =
    useState(false)
  const [
    ipAccessRulesEnabledInCCSManager,
    setIPAccessRulesEnabledInCCSManager
  ] = useState(false)
  const [
    isAccessRulesDisabledInCCSManager,
    setIsAccessRulesDisabledInCCSManager
  ] = useState(false)
  const [ztoToggleEnabledInCCSManager, setZtoToggleEnabledInCCSManager] =
    useState(false)
  const [ztoToggleDisabledInCCSManager, setZtoToggleDisabledInCCSManager] =
    useState(false)
  const [enableZTOModal, setEnableZTOModal] = useState(false)
  const [disableZTOModal, setDisableZTOModal] = useState(false)
  const [ztoResourceId, setZtoResourceId] = useState('')
  const [enabled, setStatusEnable] = useState(false)
  const [billingAccountWizard, setBillingAccountWizard] = useState(false)
  const impersonatedString = isActivateTAC ? `${t('impersonated')}: ` : ''
  const custAccountLoaded = getCustomerAccount()
  const isTACCustomerSelected =
    customerData.id === custAccountLoaded?.platform_customer_id
  const refreshDataTable = () => {
    setRefreshCount(refreshCount + 1)
  }
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const saCOITenantsLDFlag = LDFlags['glcp-istanbul-sa-coitenants-flag']
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
  const saZTOLDFlag = LDFlags['glcp-sa-zto']
  const mvsaIamUser = LDFlags['glcp-sa-iam-users-tab']

  const handleBackBtnClick = () => {
    navigate('/manage-ccs/customers')
  }
  useEffect(() => {
    setCustomerData(selectedCustomer)
  }, [selectedCustomer])
  const fetchCustomerDetails = useCallback(() => {
    const customerId = customerData.id
    getCustomerDetails(
      oidcUser.access_token,
      t,
      customerId,
      (data) =>
        dispatchCCSManagerContext({
          type: CCSManagerActions.SET_SELECTED_CUSTOMER,
          data
        }),
      setErrorMessage,
      setLoading
    )

    setType('users')
  }, [oidcUser.access_token, t, customerData, dispatchCCSManagerContext])

  useEffect(() => {
    if (Object.keys(customerData).length === 1) {
      setLoading(true)
      fetchCustomerDetails()
    }
  }, [fetchCustomerDetails, customerData])

  const tabsList = [
    {
      logo: <History size="medium" />,
      id: 1,
      title: t('audit_logs'),
      testId: 'customer-logs-tab',
      type: 'customer-logs',
      visible: true,
      rbac: {
        resource: '/ccs/audit-trail',
        permission: 'ccs.audit-trail.view'
      }
    },
    {
      logo: <Group size="medium" />,
      id: 2,
      title: `${t('customer_details.users')}`,
      testId: 'users-tab',
      type: 'users',
      visible: mvsaIamUser ? !isTACCustomerSelected : true,
      rbac: {
        permission: 'ccs.accounts.users.view.all',
        resource: '/ccs/accounts/user'
      }
    },
    {
      logo: <UserAdmin size="medium" />,
      id: 3,
      title: t('customer_roles.roles'),
      testId: 'roles-tab',
      type: 'roles',
      visible:
        (mvsaIamUser && saCustomRoleLDFlag && !isTACCustomerSelected) ||
        (!mvsaIamUser && saCustomRoleLDFlag && isTACCustomerSelected) ||
        (!isTACCustomerSelected &&
          selectedCustomer?.account_type !== 'BASIC_ORGANIZATION'),
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.view'
      }
    },
    {
      logo: <Desktop size="medium" />,
      id: 4,
      testId: 'devices-tab',
      title: `${t('customer_details.devices')}`,
      type: 'devices',
      visible: !isTACCustomerSelected,
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    },
    {
      logo: <Cart size="medium" />,
      id: 5,
      title: `${t('customer_details.orders')}`,
      testId: 'orders-tab',
      type: 'orders',
      visible: !isTACCustomerSelected,
      rbac: {
        permission: 'ccs.activate.view',
        resource: '/ccs/activate/tac'
      }
    },
    {
      logo: <Unlock size="medium" />,
      id: 6,
      title: `${t('customer_details.subscriptions')}`,
      testId: 'subscriptions-tab',
      type: 'subscriptions',
      visible: !isTACCustomerSelected,
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    },
    {
      logo: <AppsRounded size="medium" />,
      id: 7,
      title: `${t('customer_details.applications')}`,
      testId: 'applications-tab',
      type: 'applications',
      visible: !isTACCustomerSelected && showApplicationsTab,
      rbac: {
        resource: '/ccs/app-catalog/application',
        permission: 'ccs.app-catalog.view'
      }
    },
    {
      logo: <Folder size="medium" />,
      id: 8,
      title: `${t('device:folders')}`,
      testId: 'folders-tab',
      type: 'folders',
      visible: !isTACCustomerSelected,
      rbac: {
        resource: '/ccs/activate/tac',
        permission: 'ccs.activate.view'
      }
    },
    {
      logo: <Contract size="medium" />,
      id: 9,
      title: `${t('aliases.title')}`,
      testId: 'aliases-tab',
      type: 'aliases',
      visible: saCOITenantsLDFlag
        ? !isTACCustomerSelected &&
          selectedCustomer?.account_type !== 'TENANT-MSP'
        : !isTACCustomerSelected && selectedCustomer?.account_type !== 'TENANT',
      rbac: {
        permission: 'ccs.activate.view',
        resource: '/ccs/activate/tac'
      }
    },

    {
      logo: <ShieldSecurity size="medium" />,
      id: 10,
      title: t('common:manage_account.ip_access_rules_title'),
      testId: 'ip-access-rules-tab',
      type: 'ip-access-rules',
      visible: !isTACCustomerSelected,
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      }
    },
    {
      logo: <UserNew size="medium" />,
      id: 11,
      title: t('tenants'),
      testId: 'tenants-tab',
      type: 'tenants',
      visible: selectedCustomer?.account_type === 'MSP' || false,
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      }
    },
    {
      logo: <ObjectGroup size="medium" />,
      id: 12,
      title: t('authn:users.resource_restriction_policy'),
      testId: 'rrp-tab',
      type: 'resource-restrictions',
      visible:
        !isTACCustomerSelected &&
        selectedCustomer?.account_type !== 'MSP' &&
        selectedCustomer?.account_type !== 'BASIC_ORGANIZATION',
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.view'
      }
    },
    {
      logo: <DocumentUser size="medium" />,
      id: 13,
      title: t('common:billing_accounts.billing_account'),
      testId: 'billing-accounts-tab',
      type: 'billing-accounts',
      visible: !isTACCustomerSelected,
      rbac: {
        resource: '/ccs/billing-accounts',
        permission: 'ccs.billing-accounts.view'
      },
      hideFor: { feature: 'glcp-billing-accounts' }
    },

    {
      logo: <Location size="medium" />,
      id: 14,
      title: t('location:location_title'),
      testId: 'locations-tab',
      type: 'locations',
      visible: saCOITenantsLDFlag
        ? !isTACCustomerSelected &&
          selectedCustomer?.account_type !== 'TENANT-MSP'
        : !isTACCustomerSelected && selectedCustomer?.account_type !== 'TENANT',
      rbac: {
        resource: '/ccs/location-management',
        permission: 'ccs.location-management.view'
      },
      hideFor: { feature: 'glcp-glasgow-sdi-ccs-manager' }
    }
  ]

  const editCustomerBtn = {
    id: 1,
    secondary: true,
    label: t('edit_customer'),
    testId: 'edit-customer-btn',
    onClick: () => setEditCustomerModal(true),
    visibility: {
      rbac: {
        permission: 'ccs.accounts.platform.customer.edit',
        resource: '/ccs/accounts/platform/customer'
      }
    }
  }

  // To fetch button list based on tab in customer details page
  const getBtnList = () => {
    switch (type) {
      case 'devices':
        return [
          {
            id: 2,
            primary: true,
            label: t('device:add_devices'),
            testId: 'add-device-btn',
            onClick: () => setAddDevicesModal(true),
            visibility: {
              hideFor: {
                feature: 'glcp-tac-show-cak-mac'
              },
              rbac: {
                permission: 'ccs.activate.edit',
                resource: '/ccs/activate/tac'
              }
            }
          },
          {
            id: 3,
            primary: true,
            label: t('device:export_label'),
            testId: 'export-devices-btn',
            onClick: () => setExportDevicesModal(true),
            visibility: {
              rbac: {
                permission: 'ccs.activate.view',
                resource: '/ccs/activate/tac'
              }
            }
          },
          {
            id: 8,
            primary: true,
            label: t('manage_auto_subscribe'),
            testId: 'manage-auto-subscription-btn',
            onClick: () => setShowAutoSubscribeModal(true),
            visibility: {
              hideFor: {
                feature: 'glcp-tac-sm-enhancement'
              },
              rbac: {
                permission: 'ccs.device-management.edit',
                resource: '/ccs/device-management'
              }
            }
          }
        ]
      case 'folders':
        return [
          {
            id: 4,
            primary: true,
            label: t('device:create_new_folder'),
            testId: 'create-folder-btn',
            onClick: () => setCreateFolderModal(true),
            visibility: {
              rbac: {
                permission: 'ccs.activate.edit',
                resource: '/ccs/activate/tac'
              }
            }
          }
        ]
      case 'aliases':
        return [
          {
            id: 5,
            primary: true,
            label: 'Add Alias',
            testId: 'create-folder-btn',
            onClick: () => setAliasModal(true),
            hidden: saCOITenantsLDFlag
              ? customerData?.account_type === 'TENANT-MSP'
              : customerData?.account_type === 'TENANT',
            visibility: {
              rbac: {
                permission: 'ccs.activate.edit',
                resource: '/ccs/activate/tac'
              }
            }
          }
        ]
      case 'users':
        return [
          {
            id: 6,
            primary: true,
            label: t('invite_user'),
            testId: 'invite-user-btn',
            onClick: () => setInviteUserModal(true),
            visibility: {
              rbac: {
                resource: '/ccs/authorization',
                permission: 'ccs.authorization.edit'
              }
            },
            // in TAC roles, only TAC admin has ability to invite
            // other roles like TAC operator, Activate TAC admin and TAC observer should not be able to invite
            // Invite for Activate TAC, TAC observer are restricted by RBAC
            hidden:
              !(
                userCCSRoles.includes('ccs.tac-admin') ||
                userCCSRoles.includes('ccs.sa.sa-admin')
              ) || selectedCustomer?.account_type === 'BASIC_ORGANIZATION'
          }
        ]
      case 'subscriptions':
        return [
          {
            id: 7,
            primary: true,
            label: t('generate_subscriptions'),
            testId: 'generate-subscriptions-btn',
            onClick: () => setGenerateSubscriptionModal(true),
            visibility: {
              rbac: {
                resource: '/ccs/device-management',
                permission: 'ccs.device-management.edit'
              }
            }
          }
        ]
      case 'billing-accounts':
        return [
          {
            id: 8,
            primary: true,
            label: t('manage:billing_accounts.create_billing-btn'),
            testId: 'create-billing-account-btn',
            onClick: () => setBillingAccountWizard(true),
            rbac: {
              resource: '/ccs/billing-accounts',
              permission: 'ccs.billing-accounts.edit'
            }
          }
        ]
      case 'locations':
        return [
          {
            id: 9,
            primary: true,
            label: t('location:create_location'),
            testId: 'create-location-btn',
            onClick: () =>
              navigate('/manage-ccs/customers/locations/create-location', {
                state: { customerId: customerData.id }
              }),
            visibility: {
              hideFor: { feature: 'glcp-glasgow-sdi-ccs-manager' },
              rbac: {
                permission: 'ccs.location-management.edit',
                resource: '/ccs/location-management'
              }
            }
          }
        ]
      default:
        return []
    }
  }

  const renderDataTable = () => {
    switch (type) {
      case 'users':
        return (
          <UsersDataTable
            customerId={customerData.id}
            customerData={customerData}
            refreshCount={refreshCount}
          />
        )
      case 'roles':
        return <RolesDataTable customerId={customerData.id} />
      case 'orders':
        return <OrdersDataTable customerId={customerData.id} />
      case 'subscriptions':
        return (
          <SubscriptionsDataTable
            customerId={customerData.id}
            refreshCount={refreshCount}
          />
        )
      case 'applications':
        return <ApplicationsDataTable customerId={customerData.id} />
      case 'devices':
        return (
          <ActivateDevicesDataTable
            customerData={customerData}
            refreshCount={refreshCount}
            refreshDataTable={refreshDataTable}
          />
        )
      case 'folders':
        return (
          <FoldersDataTable
            customerData={customerData}
            refreshCount={refreshCount}
          />
        )
      case 'aliases':
        return (
          <AliasesDataTable
            customerId={customerData.id}
            refreshCount={refreshCount}
            setZtoToggleEnabledInCCSManager={setZtoToggleEnabledInCCSManager}
            setZtoToggleDisabledInCCSManager={setZtoToggleDisabledInCCSManager}
            setZtoToggleVisible={setZtoToggleVisible}
            setZtoResourceId={setZtoResourceId}
          />
        )
      case 'customer-logs':
        return <CustomerLogsDataTable customerId={customerData.id} />
      case 'ip-access-rules':
        return (
          <IPAccessRules
            customerId={customerData.id}
            isFromCCSManger
            setIPAccessRulesEnabledInCCSManager={
              setIPAccessRulesEnabledInCCSManager
            }
            setIsAccessRulesDisabledInCCSManager={
              setIsAccessRulesDisabledInCCSManager
            }
          />
        )
      case 'tenants':
        return <TenantsDataTable customerId={customerData.id} />
      case 'resource-restrictions':
        return <RRPDataTable customerId={customerData?.id} />
      case 'locations':
        return <LocationsDataTable customerId={customerData?.id} />
      case 'billing-accounts':
        return <BillingAccountDataTable customerId={customerData?.id} />
      default:
        return null
    }
  }

  const getValue = (value) => {
    let fieldValue
    if (value === null || value === undefined) {
      fieldValue = '--'
    } else if (typeof value === 'boolean') {
      if (value) {
        fieldValue = 'True'
      } else {
        fieldValue = 'False'
      }
    } else {
      fieldValue = value
    }
    return fieldValue
  }

  const renderDetailsSection = (data) => {
    return (
      <Box direction="row-responsive">
        <Box alignSelf="start" justify="between" direction="column">
          {Object.entries(data).map((datum) => (
            <Box
              direction="row-responsive"
              align="start"
              justify="start"
              margin={{ top: 'small' }}
              key={datum[0]}
            >
              <Box align="start" width="small">
                <Typography
                  size="small"
                  testId="details-key"
                  type="text"
                  weight="bold"
                >
                  {t(`customer_details.${datum[0]}`, {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                </Typography>
              </Box>
              <Box align="start">
                <Typography
                  size="medium"
                  color="text-strong"
                  testId="details-value"
                  type="text"
                >
                  {getValue(datum[1])}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    )
  }

  const getCustomerDetailsData = () => {
    const addressKeys = [
      'street_address',
      'street_address_2',
      'city',
      'state_or_region',
      'zip'
    ]
    const addressValues = []
    addressKeys.forEach((element) => {
      if (customerData[element]) addressValues.push(customerData[element])
    })
    const data = omit(customerData, [
      ...addressKeys,
      ...[
        'country',
        'name',
        'email',
        'phone_number',
        'description',
        'logo',
        'logo_filename'
      ]
    ])
    const addressData = {
      address: addressValues.join(', ') || '--',
      country: customerData.country
    }
    if (saCOITenantsLDFlag) {
      if (data.account_type === 'TENANT-COI') {
        data.account_type = 'Managed customer workspace - Customer owned'
      } else if (data.account_type === 'TENANT-MSP') {
        data.account_type = 'Managed customer workspace - MSP owned'
      }
    }
    delete data.operational_mode
    return { ...data, ...addressData }
  }

  const getSlicedDetails = (data) => {
    const numKeys = Object.keys(data).length
    const num = Math.ceil(numKeys / 3)
    const resultArray = []
    for (let i = 0; i < numKeys; i += num) {
      resultArray.push(
        Object.fromEntries(Object.entries(data).slice(i, i + num))
      )
    }
    return resultArray.map((value, index) => (
      <Box
        data-testid={`customer-detail-container-${index}`}
        key={index} /* eslint-disable-line react/no-array-index-key */
      >
        {renderDetailsSection(value)}
      </Box>
    ))
  }

  return (
    <Box margin={{ right: 'small' }}>
      <Box direction="row" justify="start" pad="small" wrap>
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={t('customers_tab')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="back-btn"
        />
      </Box>
      <Box margin={{ horizontal: 'xlarge' }}>
        <Box direction="row" justify="between" align="center" wrap>
          {!loading && (
            <Box direction="row" align="center" gap="small">
              <AvatarInfo
                avatarSize="large"
                avatarChar="one"
                primaryInfo={customerData?.name || ''}
                secondaryInfo=""
                testId="account-logo"
                avatarOnly
                {...(customerData?.logo && { avatarSrc: customerData?.logo })}
              />
              <Typography
                level="1"
                type="heading"
                weight="normal"
                testId="customer-name-title"
              >
                {impersonatedString}
                {customerData && customerData.name}
              </Typography>
            </Box>
          )}
          {type !== 'aliases' && type !== 'ip-access-rules' ? (
            <Box direction="row" gap="medium" margin="small">
              {[editCustomerBtn, ...getBtnList()]
                .filter((button) => !button?.hidden)
                .map((button) =>
                  customRenderer(
                    <Button {...omit(button, ['visibility'])} />,
                    button.visibility,
                    button.id
                  )
                )}
            </Box>
          ) : (
            type === 'aliases' && (
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/activate/tac',
                  permission: 'ccs.activate.view'
                }}
              >
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/activate/tac',
                    permission: 'ccs.activate.edit'
                  }}
                >
                  <Box direction="right" gap="small" margin="small">
                    {[editCustomerBtn, ...getBtnList()]
                      .filter((button) => !button?.hidden)
                      .map((button) =>
                        customRenderer(
                          <Button {...omit(button, ['visibility'])} />,
                          button.visibility,
                          button.id
                        )
                      )}
                  </Box>
                </VisibilityWrapper>
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/device-management/zto',
                    permission: 'ccs.device-subscription-zto.view'
                  }}
                  hidden={!saZTOLDFlag}
                >
                  <Tooltip
                    info={
                      <Typography
                        icon={
                          <Box align="left" justify="left">
                            <CircleInformation size="small" />
                          </Box>
                        }
                        size="small"
                        testId="info-text"
                        type="text"
                      >
                        {t('common:manage_account.zto_tooltip')}
                      </Typography>
                    }
                    testId="tooltip"
                  >
                    <Box width="small" round="xsmall" justify="center">
                      {ztoToggleVisible && (
                        <ToggleButton
                          label={t('common:manage_account.zto_title')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setEnableZTOModal(true)
                              setStatusEnable(true)
                            } else {
                              setDisableZTOModal(true)
                              setStatusEnable(false)
                            }
                          }}
                          disabled={ztoToggleDisabledInCCSManager}
                          checked={ztoToggleEnabledInCCSManager}
                          testId="zto-toggle-btn"
                        />
                      )}
                    </Box>
                  </Tooltip>
                </VisibilityWrapper>
              </VisibilityWrapper>
            )
          )}
          {type === 'ip-access-rules' && (
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/accounts/platform/customer',
                permission: 'ccs.accounts.platform.customer.edit'
              }}
            >
              <Tooltip
                info={
                  <Typography
                    icon={
                      <Box align="center" justify="center">
                        <CircleInformation size="small" />
                      </Box>
                    }
                    size="small"
                    testId="info-text"
                    type="text"
                  >
                    {t('authn:ip_access_rules.toggle-info-text')}
                  </Typography>
                }
                testId="tooltip"
              >
                <Box
                  width="medium"
                  round="xxsmall"
                  justify="center"
                  border={{ color: 'border' }}
                >
                  <ToggleButton
                    a11yTitle={t('authn:ip_access_rules.toggle_desc')}
                    label={t('authn:ip_access_rules.title')}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEnableIPAccessRuleModal(true)
                      } else {
                        setDisableIPAccessRuleModal(true)
                      }
                    }}
                    disabled={isAccessRulesDisabledInCCSManager}
                    checked={ipAccessRulesEnabledInCCSManager}
                    testId="ip-access-rules-toggle-btn"
                  />
                </Box>
              </Tooltip>
            </VisibilityWrapper>
          )}
        </Box>
        <Box margin={{ vertical: 'medium', right: 'large' }}>
          {loading ? (
            <Loader testId="loader-spinner" />
          ) : (
            <Box
              direction="row"
              justify="between"
              align="start"
              alignContent="start"
              wrap
            >
              {getSlicedDetails(getCustomerDetailsData())}
            </Box>
          )}
        </Box>
        <Box direction="row" data-testid="tabs-with-icon" wrap>
          {tabsList.map((data) =>
            data.visible ? (
              <VisibilityWrapper
                key={data.id}
                rbac={data?.rbac}
                hideFor={data?.hideFor}
              >
                <Box
                  key={data.id}
                  direction="row"
                  gap="small"
                  round="small"
                  pad="medium"
                  elevation="small"
                  align="center"
                  justify="between"
                  margin={{ bottom: 'medium', right: 'medium' }}
                  onClick={() => {
                    setFocus(true)
                    setType(data.type)
                  }}
                  data-testid={data.testId}
                  {...(data.type === type &&
                    focus && { border: { color: 'focus', size: 'small' } })}
                >
                  <Typography
                    size="medium"
                    testId="tile-subtitle"
                    type="text"
                    weight="bold"
                  >
                    {t(data.title)}
                  </Typography>
                  <Box align="end" background="#EFEFEF" pad="small" round>
                    {data.logo}
                  </Box>
                </Box>
              </VisibilityWrapper>
            ) : null
          )}
        </Box>
        {customerData ? renderDataTable() : null}
      </Box>
      {exportDevicesModal && (
        <ExportDevicesModal
          customerData={customerData}
          onSetOpen={setExportDevicesModal}
        />
      )}
      {createFolderModal && (
        <CreateFolderModal
          onSetOpen={setCreateFolderModal}
          refreshDataTable={refreshDataTable}
          customerId={selectedCustomer.id}
          isCCSManager
        />
      )}
      {showAliasModal && (
        <AddAliasModal
          setAliasModal={setAliasModal}
          customerId={customerData.id}
          refreshDataTable={refreshDataTable}
          setAddAliasNotification={() => {
            setShowNotification(
              displayNotification(
                t('aliases.alias_add_success_msg'),
                'info',
                setShowNotification
              )
            )
          }}
        />
      )}
      {enableZTOModal && (
        <ZTOToggleModal
          setZTOModal={setEnableZTOModal}
          customerId={customerData.id}
          setAddAliasNotification={() => {
            setShowNotification(
              displayNotification(
                t('zto.zto_enable_success_msg'),
                'info',
                setShowNotification,
                setZtoToggleEnabledInCCSManager(true)
              )
            )
          }}
          ztoResourceId={ztoResourceId}
          enabled={enabled}
        />
      )}
      {disableZTOModal && (
        <ZTOToggleModal
          setZTOModal={setDisableZTOModal}
          customerId={customerData.id}
          setAddAliasNotification={() => {
            setShowNotification(
              displayNotification(
                t('zto.zto_disable_success_msg'),
                'info',
                setShowNotification,
                setZtoToggleEnabledInCCSManager(false)
              )
            )
          }}
          ztoResourceId={ztoResourceId}
          enabled={enabled}
        />
      )}
      <AddDevicesModalCommon
        setAddDevicesModal={setAddDevicesModal}
        addDevicesModal={addDevicesModal}
        refreshDataTable={refreshDataTable}
        customerId={selectedCustomer?.id}
        isCCSManager
      />
      {inviteUserModal && (
        <InviteUserModal
          onSetOpen={setInviteUserModal}
          customerId={customerData.id}
          customerUserName={oidcUser?.profile?.email}
          onSuccess={refreshDataTable}
          customerAccountType={customerData.account_type}
        />
      )}
      {generateSubscriptionModal && (
        <GenerateSubscriptionModal
          customerId={customerData.id}
          onSuccess={refreshDataTable}
          setShowGenerateModal={setGenerateSubscriptionModal}
          setShowNotification={setShowNotification}
        />
      )}
      {enableIPAccessRuleModal && (
        <EnableIPAccessRuleModal
          setEnableIPAccessRuleModal={setEnableIPAccessRuleModal}
          onSuccess={(msg) => {
            setShowNotification(
              displayNotification(msg, 'info', setShowNotification)
            )
            setIPAccessRulesEnabledInCCSManager(true)
          }}
          setIPAccessRulesEnabled={setIPAccessRulesEnabledInCCSManager}
          isFromCCSManger
          customerId={customerData.id}
        />
      )}
      {disableIPAccessRuleModal && (
        <DisableIPAccessRuleModal
          setDisableIPAccessRuleModal={setDisableIPAccessRuleModal}
          onSuccess={(msg) => {
            setShowNotification(
              displayNotification(msg, 'info', setShowNotification)
            )
            setIPAccessRulesEnabledInCCSManager(false)
          }}
          setIPAccessRulesEnabled={setIPAccessRulesEnabledInCCSManager}
          isFromCCSManger
          customerId={customerData.id}
        />
      )}
      {editCustomerModal && (
        <EditCustomerModal
          customerData={customerData}
          setShowModal={setEditCustomerModal}
          fetchCustomerDetails={fetchCustomerDetails}
        />
      )}
      {billingAccountWizard && (
        <BillingAccountWizard
          customerId={customerData.id}
          closeWizard={() => {
            setBillingAccountWizard(false)
          }}
        />
      )}
      {showAutoSubscribeModal && (
        <ManageAutoSubscriptionModal
          setShowModal={setShowAutoSubscribeModal}
          customerId={customerData.id}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
      {showNotification}
    </Box>
  )
}

export default CustomerDetails
