// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
const getNewSkipInt = (rdLength, floorIndex) => {
  if (rdLength <= floorIndex) {
    return 0
  }
  let i = 1
  while (rdLength / (i + 1) > floorIndex) {
    i += 1
  }
  return i
}

export const getSkipInterval = (rdLength, defSkipInterval, defActualCount) => {
  if (rdLength === defActualCount) {
    return defSkipInterval
  }
  // 7d & 12m
  if (defSkipInterval === 0) {
    return defSkipInterval
  }
  // 24h
  if (defSkipInterval === 1) {
    if (rdLength <= 12) {
      return 0
    }
    return 1
  }
  // 30d & 90d
  if (rdLength < defActualCount) {
    const floorIndex = Math.floor(defActualCount / (defSkipInterval + 1))
    const newSkipInt = getNewSkipInt(rdLength, floorIndex)
    return newSkipInt
  }
  return defSkipInterval
}
