// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { Box, Grid } from 'grommet'
import { Install, Previous, StatusCritical } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import dayjs from 'dayjs'
import isEqual from 'lodash/isEqual'

import {
  Button,
  Typography,
  DataTable,
  ButtonGroup,
  Notification,
  NoDataInfo
} from '../../../../../../components'
import {
  CCSManagerActions,
  useCCSManagerContext
} from '../../../../../../context/ccs-manager-context'
import { getDeviceDetails } from '../../../../utils'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import { DiscardFolderChangesModal } from '../../../../../../commoncomponents/folder-details/components/DiscardFolderChangesModal'
import { put } from '../../../../../../utils/api-utils'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getApiErrorMessage } from '../../../../../../utils/error-handling-utils'

import ActivateDevicesDetailsSection from './ActivateDevicesDetailsSection'

const ActivateDevicesDetailsPage = () => {
  const { t } = useTranslation(['device', 'common'])
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const deviceData = JSON.parse(sessionStorage.getItem('deviceData'))
  const {
    dispatchCCSManagerContext,
    selectedDeviceCustomerData: customerData
  } = useCCSManagerContext()
  const [deviceDetails, setDeviceDetails] = useState({})
  const { serial_number: serialNo, mac_address: macAddr } = deviceData || ''

  // pagination
  const itemsPerDatatable = 3
  // for device history table
  const [devicesHistoryPage, setDevicesHistoryPage] = useState(1)
  const [devicesHistoryTotalItems, setDevicesHistoryTotalItems] = useState(0)
  const deviceHistoryPageIdxInfo = getPaginationShowIdx(
    devicesHistoryPage,
    devicesHistoryTotalItems,
    itemsPerDatatable,
    t
  )
  // for orders table
  const [ordersPage, setOrdersPage] = useState(1)
  const [ordersTotalItems, setOrdersTotalItems] = useState(0)
  const ordersPageIdxInfo = getPaginationShowIdx(
    ordersPage,
    ordersTotalItems,
    itemsPerDatatable,
    t
  )

  // state to store the API response of device details
  const [editDeviceDetailResponse, setEditDeviceDetailsResponse] = useState({})

  // state to track the changes made in device details
  const [editDeviceDetail, setEditDeviceDetails] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [currentMode, setCurrentMode] = useState('view')
  const [discardModal, setDiscardModal] = useState(false)
  const [goBackOnDiscard, setGoBackOnDiscard] = useState(false)

  const fetchDeviceDetails = useCallback(() => {
    getDeviceDetails(
      oidcUser.access_token,
      t,
      serialNo,
      {
        mac_address: macAddr,
        devices_history_limit: itemsPerDatatable,
        devices_history_page: devicesHistoryPage - 1,
        orders_limit: itemsPerDatatable,
        orders_page: ordersPage - 1,
        ...(customerData?.id && { platform_customer_id: customerData?.id })
      },
      setDeviceDetails,
      setEditDeviceDetailsResponse,
      setDevicesHistoryTotalItems,
      setOrdersTotalItems,
      setErrorMessage
    )
  }, [
    oidcUser.access_token,
    t,
    serialNo,
    macAddr,
    devicesHistoryPage,
    ordersPage,
    customerData?.id
  ])

  useEffect(() => {
    fetchDeviceDetails()
  }, [fetchDeviceDetails])

  useEffect(() => {
    setEditDeviceDetails(editDeviceDetailResponse)
  }, [editDeviceDetailResponse])

  const isDataEdited = () => {
    return !isEqual(editDeviceDetailResponse, editDeviceDetail)
  }

  const handleBackBtnClick = () => {
    if (currentMode === 'view') {
      if (customerData && Object.keys(customerData).length > 1)
        navigate('/manage-ccs/customers/customer-details', {
          state: { tabName: 'devices' }
        })
      else navigate('/manage-ccs/devices')
    } else if (!isDataEdited()) {
      if (customerData && Object.keys(customerData).length > 1)
        navigate('/manage-ccs/customers/customer-details', {
          state: { tabName: 'devices' }
        })
      else navigate('/manage-ccs/devices')
    } else {
      setGoBackOnDiscard(true)
      setDiscardModal(true)
    }
  }

  const handleSaveChanges = () => {
    const url = `/support-assistant/v1alpha1/device`
    const requestBody = {
      device_update_request: {
        mac: deviceDetails?.devices?.[0]?.mac_address,
        device_name: editDeviceDetail?.device_name,
        device_full_name: editDeviceDetail?.full_name,
        device_description: editDeviceDetail?.description,
        folder_id: editDeviceDetail?.folder_id,
        folder_name: editDeviceDetail?.folder_name,
        ...(editDeviceDetailResponse?.mode !== editDeviceDetail?.mode && {
          mode: editDeviceDetail?.mode || ''
        })
      },
      platform_customer_id: customerData?.id
    }
    put(url, requestBody, oidcUser.access_token).then(
      () => {
        fetchDeviceDetails()
        setCurrentMode('view')
      },
      (error) => {
        const backendErrorMessage = getApiErrorMessage(error, t)
        setErrorMessage(
          <Notification
            icon={<StatusCritical color="text-strong" />}
            onClose={() => {
              setErrorMessage(null)
            }}
            testId="status-error-notification"
            text={backendErrorMessage}
            backgroundColor="status-critical"
          />
        )
      }
    )
  }

  const renderDeviceDetails = () => {
    return (
      <ActivateDevicesDetailsSection
        mode={currentMode}
        deviceDetailsData={deviceDetails?.devices?.[0]}
        editableDeviceDetailsData={editDeviceDetail}
        setEditDeviceDetailsData={setEditDeviceDetails}
        customerId={customerData?.id}
      />
    )
  }

  const deviceHistoryColumns = [
    {
      property: 'date',
      type: 'date',
      header: t('date'),
      render: (datum) => (
        <>
          {datum.date
            ? dayjs(parseInt(datum.date, 10)).format('MM/DD/YYYY hh:mm A')
            : '--'}
        </>
      )
    },
    {
      property: 'type',
      type: 'string',
      header: t('type')
    },
    {
      property: 'source_ip',
      type: 'string',
      header: t('source_ip')
    },
    {
      property: 'current_version',
      type: 'string',
      header: t('current_version')
    },
    {
      property: 'mode',
      type: 'string',
      header: t('mode')
    },
    {
      property: 'status',
      type: 'string',
      header: t('status')
    }
  ]

  const orderLinesColumns = [
    {
      property: 'shipped',
      type: 'string',
      header: t('shipped'),
      render: (datum) => (
        <>
          {datum.date
            ? dayjs(parseInt(datum.date, 10)).format('MM/DD/YYYY hh:mm A')
            : '--'}
        </>
      )
    },
    {
      property: 'part_number',
      type: 'string',
      header: t('part')
    },
    {
      property: 'class',
      type: 'string',
      header: t('class_label')
    },
    {
      property: 'bill_to_name',
      type: 'string',
      header: t('soldToOrBillTo')
    },
    {
      property: 'ship_to',
      type: 'string',
      header: t('ship_to')
    },
    {
      property: 'end_user_name',
      type: 'string',
      header: t('end_user')
    },
    {
      property: 'customer_po',
      type: 'string',
      header: t('customer_po')
    },
    {
      property: 'order_no',
      type: 'numeric',
      header: t('order')
    },
    {
      property: 'invoice_no',
      type: 'numeric',
      header: t('invoice')
    },
    {
      property: 'status',
      type: 'string',
      header: t('status')
    }
  ]

  const detailsBody = () => {
    return (
      <Grid
        rows={['full']}
        columns={['1/4', '3/4']}
        areas={[['device_details', 'device_table_details']]}
        direction="row-responsive"
        height={{ min: 'medium', max: 'large' }}
        align="start"
        fill
        margin={{ top: 'medium' }}
      >
        <Box
          gridArea="device_details"
          direction="column"
          margin={{ right: 'large' }}
        >
          {renderDeviceDetails()}
        </Box>
        <Box
          gridArea="device_table_details"
          direction="column"
          margin={{ left: 'large' }}
        >
          <Box margin={{ bottom: 'large' }}>
            <Box margin={{ bottom: 'xsmall' }}>
              <Typography level="3" type="heading" testId="device-history">
                {t('device_history')}
              </Typography>
            </Box>
            <Typography
              size="large"
              type="text"
              testId="device-history-subtitle"
            >
              {t('common:device_history_subtitle')}
            </Typography>
          </Box>
          {deviceDetails?.adi_device_history_data?.device_history?.length ? (
            <DataTable
              grid={{
                data:
                  deviceDetails?.adi_device_history_data?.device_history || [],
                columns: deviceHistoryColumns
              }}
              pagination={{
                totalItems: devicesHistoryTotalItems,
                itemsPerPage: itemsPerDatatable,
                page: devicesHistoryPage,
                setPage: setDevicesHistoryPage,
                pageIdxInfo: deviceHistoryPageIdxInfo
              }}
              testId="device-history-table"
            />
          ) : (
            <NoDataInfo
              subtitle={t('common:no_devices_info')}
              icon={<Install size="large" />}
              testId="no-data-info-without-title-activate-devices"
            />
          )}
          {customerData?.id && (
            <>
              <Box margin={{ vertical: 'large' }}>
                <Box margin={{ bottom: 'xsmall' }}>
                  <Typography level="3" type="heading" testId="order-lines">
                    {t('order_lines')}
                  </Typography>
                </Box>
                <Typography
                  size="large"
                  type="text"
                  testId="device-history-subtitle"
                >
                  {t('order_lines_subtitle')}
                </Typography>
              </Box>
              {deviceDetails?.orders?.aop_sales_order_data?.length ? (
                <DataTable
                  grid={{
                    data: deviceDetails?.orders?.aop_sales_order_data || [],
                    columns: orderLinesColumns
                  }}
                  pagination={{
                    totalItems: ordersTotalItems,
                    itemsPerPage: itemsPerDatatable,
                    page: ordersPage,
                    setPage: setOrdersPage,
                    pageIdxInfo: ordersPageIdxInfo
                  }}
                  testId="order-lines-table"
                />
              ) : (
                <NoDataInfo
                  subtitle={t('order_lines_info')}
                  icon={<Install size="large" />}
                  testId="no-data-info-without-title-orders"
                />
              )}
            </>
          )}
        </Box>
      </Grid>
    )
  }

  return (
    <Box>
      <Box
        direction="row"
        justify="start"
        pad="small"
        margin={{ bottom: 'medium' }}
      >
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={t('devices_tab')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="devices-btn"
        />
      </Box>
      {customerData?.id || deviceDetails?.devices?.[0]?.platform_customer_id ? (
        <Box
          direction="row"
          pad="small"
          margin={{ horizontal: 'large' }}
          justify="between"
          align="center"
        >
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/accounts/platform/customer',
              permission: 'ccs.accounts.platform.customer.view'
            }}
            fallbackComponent={
              <Typography
                type="text"
                testId="device-customer-id"
                margin={{ left: 'medium' }}
              >
                {t('customerId', {
                  count:
                    customerData?.id ||
                    deviceDetails?.devices?.[0]?.platform_customer_id ||
                    '--'
                })}
              </Typography>
            }
          >
            <Button
              default
              label={t('customerId', {
                count:
                  customerData?.id ||
                  deviceDetails?.devices?.[0]?.platform_customer_id
              })}
              testId="customer_id_btn"
              onClick={() => {
                dispatchCCSManagerContext({
                  type: CCSManagerActions.SET_SELECTED_CUSTOMER,
                  data: {
                    id:
                      customerData?.id ||
                      deviceDetails?.devices?.[0]?.platform_customer_id
                  }
                })
                navigate('/manage-ccs/customers/customer-details', {
                  state: { tabName: 'devices' }
                })
              }}
            />
          </VisibilityWrapper>
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.activate.edit',
              resource: '/ccs/activate/tac'
            }}
          >
            <Box gap="medium" align="center">
              {currentMode === 'view' ? (
                <Button
                  secondary
                  label={t('edit')}
                  onClick={() => {
                    setCurrentMode('edit')
                  }}
                  testId="edit-device-details-btn"
                />
              ) : (
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('discard_changes'),
                      secondary: true,
                      onClick: () => {
                        if (isDataEdited()) setDiscardModal(true)
                        else setCurrentMode('view')
                      },
                      testId: 'discard-changes-btn'
                    },
                    {
                      id: 1,
                      label: t('save_changes'),
                      primary: true,
                      testId: 'save-changes-btn',
                      onClick: handleSaveChanges
                    }
                  ]}
                  testId="edit-device-details-buttons"
                />
              )}
            </Box>
          </VisibilityWrapper>
        </Box>
      ) : null}
      <Box pad={{ horizontal: 'xlarge', top: 'medium' }}>
        <Box direction="row-responsive" justify="start">
          {detailsBody()}
        </Box>
      </Box>
      {errorMessage}
      {discardModal && (
        <DiscardFolderChangesModal
          closeModal={() => setDiscardModal(false)}
          onContinue={() => {
            // on discard, setting the state back to the API response
            setEditDeviceDetails(editDeviceDetailResponse)
            setCurrentMode('view')
            setDiscardModal(false)
          }}
          goBack={goBackOnDiscard}
        />
      )}
    </Box>
  )
}

export default ActivateDevicesDetailsPage
