// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import isEqual from 'lodash/isEqual'
import { Box } from 'grommet'

import { getUserName as getUserFullName } from '../../../utils/common-utils'
import { getErrorMessage, put } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import {
  getRoleAssignmentSuccessText,
  allScopesRRPName
} from '../../../utils/manage-account-identity-utils'
import { Typography, AvatarInfo } from '../../../components'
import { isMSP } from '../../../utils/feature-flag-utils'
import { validateForm } from '../../../utils/validation-utils'

// --------------------------AbandonProgressModal--------------------------
export const handleCreatePolicyClick = (navigate) => {
  navigate('/manage-account/identity/scope-groups/create', {
    state: { prevPage: true }
  })
}

// ------------------------AssignRoleConfirmationModal------------------------
export const getUserName = (userData) => {
  return getUserFullName(
    userData?.first_name,
    userData?.last_name,
    userData?.email
  )
}

export const getRequestBody = ({
  selectedRoleDetails,
  existingRoleAssignments,
  action,
  newRoleAssignment
}) => {
  let requestBody = {}
  if (action === 'ADD') {
    requestBody = {
      add: [newRoleAssignment]
    }
  } else {
    const formattedExistingRoles = existingRoleAssignments.map((data) => ({
      role: {
        slug: data?.slug,
        application_id: data?.application_id
      },
      ...(data.access_rules && {
        access_rules: data.access_rules
      }),
      ...(data.resource_restriction_policies && {
        resource_restriction_policies: data.resource_restriction_policies.length
          ? data?.resource_restriction_policies?.map(
              (rrp) => rrp?.resource_restriction_policy_id
            )
          : []
      })
    }))
    if (action === 'SWAP_ROLE') {
      // remove matched role
      const filteredRoleAssignments = formattedExistingRoles.filter(
        (data) =>
          !(
            data?.role?.application_id === selectedRoleDetails.application_id &&
            data?.role?.slug === selectedRoleDetails.role_slug
          )
      )
      requestBody = {
        overwrite: [...filteredRoleAssignments, newRoleAssignment]
      }
    } else if (action === 'MERGE') {
      // action = "MERGE"
      const filteredRoleAssignments = formattedExistingRoles.filter(
        (data) =>
          data.role.application_id !== selectedRoleDetails.application_id
      )
      requestBody = {
        overwrite: [...filteredRoleAssignments, newRoleAssignment]
      }
    } else if (action === 'MERGE_OVERWRITE') {
      // action = "MERGE_OVERWRITE"
      const filteredRoleAssignments = formattedExistingRoles.filter(
        (data) =>
          data.role.application_id === selectedRoleDetails.application_id &&
          data.role.slug !== selectedRoleDetails.role_slug
      )

      const filteredOtherApplicationAssignments = formattedExistingRoles.filter(
        (data) =>
          data.role.application_id !== selectedRoleDetails.application_id
      )

      const filteredAssignments = filteredRoleAssignments
        .map((data) => {
          if (data.access_rules.msp && data.access_rules?.tenants?.length) {
            return {}
          }

          if (data.access_rules.msp && selectedRoleDetails.access_rules.msp) {
            data.access_rules.msp = false
          }
          if (
            data.access_rules?.tenants?.length &&
            selectedRoleDetails.access_rules?.tenants?.length
          ) {
            data.access_rules.tenants = []
          }

          if (!data.access_rules.msp && !data.access_rules?.tenants?.length) {
            return {}
          }

          return data
        })
        .filter((data) => data.access_rules)

      requestBody = {
        overwrite: [
          ...filteredAssignments,
          ...filteredOtherApplicationAssignments,
          newRoleAssignment
        ]
      }
    } else {
      // action = "OVERWRITE"
      // this is for apps with support_one_role_only, remove existing roles
      const filteredRoleAssignments = formattedExistingRoles.filter(
        (data) =>
          data.role.application_id !== selectedRoleDetails.application_id
      )
      requestBody = {
        overwrite: [...filteredRoleAssignments, newRoleAssignment]
      }
    }
  }
  return requestBody
}

export const assignRoleAPICall = ({
  setModal,
  userData,
  selectedRoleDetails,
  existingRoleAssignments,
  reloadUsersTable,
  inCCSManager,
  customerId,
  setShowNotification,
  onSuccessAssign,
  setLoading,
  action,
  newRoleAssignment,
  setErrorMessage,
  t,
  oidcUser
}) => {
  setLoading(true)
  const requestBody = inCCSManager
    ? {
        roles: getRequestBody({
          selectedRoleDetails,
          existingRoleAssignments,
          action,
          newRoleAssignment
        }),
        username: userData.email,
        platform_customer_id: customerId
      }
    : getRequestBody({
        selectedRoleDetails,
        existingRoleAssignments,
        action,
        newRoleAssignment
      })
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/user-roles`
    : `/authorization/ui/v2/customers/users/${userData.email}/roles`

  put(url, requestBody, oidcUser.access_token).then(
    (response) => {
      if (response.status === 200 || response.status === 204) {
        setErrorMessage(null)
        setLoading(false)
        const message = getRoleAssignmentSuccessText(
          selectedRoleDetails.role_name,
          getUserName(userData),
          t
        )
        setShowNotification(
          displayNotification(message, 'info', setShowNotification)
        )
        setModal(false)
        reloadUsersTable()
        onSuccessAssign()
      }
    },
    (error) => {
      setLoading(false)
      const errorMsg = getErrorMessage(error, t)
      setErrorMessage(
        typeof errorMsg === 'object' ? JSON.stringify(errorMsg) : errorMsg
      )
    }
  )
}

export const getPreviousRRPName = ({
  selectedRoleDetails,
  filteredPrevRolesByApp,
  filteredPrevRolesByRoleSlug,
  t
}) => {
  const res = (
    selectedRoleDetails?.support_one_role_only
      ? filteredPrevRolesByApp
      : filteredPrevRolesByRoleSlug
  )
    .filter((data) => data.resource_restriction_policies)
    .map((data) => data.resource_restriction_policies.map((val) => val.name))
    .flat()
  return res.includes(allScopesRRPName)
    ? t('authz:rrp.full_access')
    : res?.map((rrp) => (
        <Typography
          size="medium"
          color="text-strong"
          testId={rrp}
          type="text"
          wordBreak="break-word"
          key={rrp}
        >
          {rrp}
        </Typography>
      ))
}

export const getNewRRPName = (selectedRoleDetails, t) => {
  return selectedRoleDetails?.limit_resource_access
    ? selectedRoleDetails?.resource_restriction_policies?.map((rrp) => (
        <Typography
          size="medium"
          color="text-strong"
          testId={rrp?.name}
          type="text"
          wordBreak="break-word"
          key={rrp?.name}
        >
          {rrp?.name}
        </Typography>
      ))
    : t('authz:rrp.full_access')
}

export const getPreviousAccessRuleName = ({
  selectedRoleDetails,
  filteredPrevRolesByApp,
  filteredPrevRolesByRoleSlug,
  action,
  accessRulesList,
  isTenantWorkspaceGroup
}) => {
  let existingRoleAccessRules = filteredPrevRolesByRoleSlug
    .filter((data) => data.access_rules)
    .map((data) => data.access_rules)
  if (
    selectedRoleDetails?.support_one_role_only &&
    action === 'MERGE_OVERWRITE'
  ) {
    const getAccessRules = (roles, condition) => {
      return roles
        .filter((data) => data?.access_rules && condition(data.access_rules))
        .map((data) => data.access_rules)
    }
    // Determine the condition based on selectedRoleDetails
    let condition
    if (
      selectedRoleDetails?.access_rules?.msp &&
      !selectedRoleDetails?.access_rules?.tenants?.length
    ) {
      condition = (access_rules) => access_rules.msp
    } else if (
      !selectedRoleDetails?.access_rules?.msp &&
      selectedRoleDetails?.access_rules?.tenants?.length
    ) {
      condition = (access_rules) => !access_rules.msp
    } else {
      condition = () => true
    }
    // Get existing role access rules based on the determined condition
    existingRoleAccessRules = getAccessRules(filteredPrevRolesByApp, condition)
    if (existingRoleAccessRules.length === 0) {
      existingRoleAccessRules = filteredPrevRolesByApp?.map(
        (data) => data.access_rules
      )
    }
  } else if (selectedRoleDetails?.support_one_role_only && action === 'MERGE') {
    existingRoleAccessRules = filteredPrevRolesByApp
      .filter((data) => data.access_rules)
      .map((data) => data.access_rules)
  }
  const returnVal =
    existingRoleAccessRules
      .map((accessRule) => {
        let label = accessRulesList.find((val) =>
          isEqual(val.access_rules, accessRule)
        )?.label
        if (isTenantWorkspaceGroup) {
          if (
            !label &&
            accessRule?.tenants &&
            accessRule?.tenants[0] !== 'ALL' &&
            accessRule?.msp
          ) {
            label = accessRulesList[3]?.label
          } else if (
            !label &&
            accessRule?.tenants &&
            accessRule?.tenants[0] !== 'ALL' &&
            !accessRule?.msp
          ) {
            label = accessRulesList[4]?.label
          }
        }
        return label
      })
      .join(', ') || '--'
  return returnVal
}

export const getNewAccessRuleName = (selectedRoleDetails, accessRulesList) => {
  // if support_one_role_only:
  if (selectedRoleDetails?.support_one_role_only) {
    // let hasMSP = false
    // let hasTanent = false
    // filteredPrevRolesByRoleSlug.forEach((item) => {
    //   if (item?.access_rules?.msp) {
    //     hasMSP = true
    //   }
    //   if (item?.access_rules?.tenants?.length) {
    //     hasTanent = true
    //   }
    // })
    let newAccessRule = ''
    // if (hasMSP && hasTanent) {
    //   newAccessRule = accessRulesList.find(
    //     (data) => data.value === selectedRoleDetails.access_rules_index
    //   )?.label
    // } else {
    newAccessRule = accessRulesList.find(
      (data) => data.value === selectedRoleDetails.access_rules_index
    )?.label
    // }
    return newAccessRule
  }
  // if not support_one_role_only
  return accessRulesList.find(
    (data) => data.value === selectedRoleDetails.access_rules_index
  )?.label
}

// for showing data in UI
export const getNewRoleAssignmentUIData = ({
  selectedRoleDetails,
  accessRulesList,
  ccsApplicationId,
  rrpWorkForMSP,
  t
}) => {
  return {
    application: selectedRoleDetails?.application_name,
    role: selectedRoleDetails?.role_name,
    ...(isMSP() && {
      access_rule: getNewAccessRuleName(selectedRoleDetails, accessRulesList)
    }),
    ...(selectedRoleDetails?.application_id !== ccsApplicationId &&
      (!isMSP() || rrpWorkForMSP) && {
        additional_resource_restrictions: getNewRRPName(selectedRoleDetails, t)
      })
  }
}

// for showing data in UI
export const getPreviousRoleAssignmentUIData = ({
  filteredPrevRolesByRoleSlug,
  filteredPrevRolesByApp,
  selectedRoleDetails,
  action,
  accessRulesList,
  isTenantWorkspaceGroup,
  ccsApplicationId,
  existingRoleAssignments,
  rrpWorkForMSP,
  t
}) => {
  let previousRoleAssignment = filteredPrevRolesByRoleSlug?.length
    ? filteredPrevRolesByRoleSlug[0]
    : {
        application_name: filteredPrevRolesByApp?.[0]?.application_name || '--',
        role_name:
          filteredPrevRolesByApp?.map((val) => val?.role_name)?.join(', ') ||
          '--'
      }
  const prevAccessRules = filteredPrevRolesByApp.filter(
    (val) => val.access_rules
  )
  const checkExistingMSPandTenantsAccess = (rules) => {
    return rules.some((rule) => {
      const existingAccessRules = rule?.access_rules
      return (
        existingAccessRules?.msp && existingAccessRules?.tenants?.length > 0
      )
    })
  }
  const isMSPandTenantsAccess =
    checkExistingMSPandTenantsAccess(prevAccessRules)
  if (
    selectedRoleDetails?.support_one_role_only &&
    action === 'MERGE_OVERWRITE' &&
    isMSP()
  ) {
    let roleName = '--'

    const accessRuleType = (() => {
      if (
        selectedRoleDetails?.access_rules?.msp &&
        selectedRoleDetails?.access_rules?.tenants?.length === 0 &&
        !isMSPandTenantsAccess
      ) {
        return 'MSP_ONLY'
      }
      if (
        !selectedRoleDetails?.access_rules?.msp &&
        selectedRoleDetails?.access_rules?.tenants?.length &&
        !isMSPandTenantsAccess
      ) {
        return 'TENANTS_ONLY'
      }
      return 'MSP_AND_TENANTS'
    })()

    switch (accessRuleType) {
      case 'MSP_ONLY':
        roleName =
          filteredPrevRolesByApp
            ?.filter((val) => val?.access_rules?.tenants?.length === 0)
            ?.map((val) => val?.role_name)
            ?.join(', ') || '--'
        break
      case 'TENANTS_ONLY':
        roleName =
          filteredPrevRolesByApp
            ?.filter((val) => val?.access_rules && !val?.access_rules?.msp)
            ?.map((val) => val?.role_name)
            ?.join(', ') || '--'
        break
      case 'MSP_AND_TENANTS':
        roleName =
          prevAccessRules?.length === 2
            ? prevAccessRules.map((val) => val.role_name).join(', ') || '--'
            : prevAccessRules[0]?.role_name || '--'
        break
      default:
        break
    }
    previousRoleAssignment = {
      ...previousRoleAssignment,
      role_name: roleName
    }
  } else if (
    selectedRoleDetails?.support_one_role_only &&
    action === 'MERGE' &&
    isMSP()
  ) {
    previousRoleAssignment = {
      ...previousRoleAssignment,
      role_name:
        filteredPrevRolesByApp?.map((val) => val?.role_name)?.join(', ') || '--'
    }
  }
  return {
    application: previousRoleAssignment?.application_name,
    role: previousRoleAssignment?.role_name,
    ...(isMSP() && {
      access_rule: getPreviousAccessRuleName({
        selectedRoleDetails,
        filteredPrevRolesByApp,
        filteredPrevRolesByRoleSlug,
        action,
        accessRulesList,
        isTenantWorkspaceGroup
      })
    }),
    ...(existingRoleAssignments?.application_id !== ccsApplicationId &&
      selectedRoleDetails?.application_id !== ccsApplicationId &&
      (!isMSP() || rrpWorkForMSP) && {
        additional_resource_restrictions: getPreviousRRPName({
          selectedRoleDetails,
          filteredPrevRolesByApp,
          filteredPrevRolesByRoleSlug,
          t
        })
      })
  }
}

// --------------------------AssignRoleModal--------------------------
export const customerWorkspaceValidation = (formValues, i18nTranslate) => {
  const { msp_role_assignment, workspace_role_assignment } = formValues
  const errorMessage = i18nTranslate(
    i18nTranslate(
      'authz:workspace_group.error_msg_for_invalid_customer_workspace_selection'
    )
  )
  if (msp_role_assignment === 'no' && workspace_role_assignment === 'none') {
    return Promise.reject(new Error(errorMessage))
  }
  return validateForm(formValues, i18nTranslate)
}

export const handleValidation = async (
  formValues,
  i18nTranslate,
  setFormErrorMessage
) => {
  return customerWorkspaceValidation(formValues, i18nTranslate)
    .then(() => {
      setFormErrorMessage('')
    })
    .catch((error) => {
      setFormErrorMessage(error.message)
      throw error
    })
}

export const showRRPFields = ({
  isTenantWorkspaceGroup,
  inCCSManager,
  isMsp,
  accessRulesList,
  selectedDetails,
  ccsApplicationId,
  rrpWorkForMSP
}) => {
  // Check if 'Tenants only' is selected when the tenant-workspace-group flag is disabled
  const isTenantsOnly =
    !isTenantWorkspaceGroup &&
    !inCCSManager &&
    isMsp &&
    (accessRulesList[selectedDetails.access_rules_index] === undefined || // Check for undefined: when there is no user selection
      accessRulesList[selectedDetails.access_rules_index]?.label ===
        'Tenants Only') // Check for 'Tenants Only'

  // Return the visibility of RRP fields
  return (
    selectedDetails.application_id !== ccsApplicationId && // Exclude CCS application
    (!isMsp || rrpWorkForMSP) && // Include MSP-specific logic
    selectedDetails.role_slug && // Ensure role is selected
    ((isTenantWorkspaceGroup &&
      selectedDetails.msp_role_assignment === 'yes') ||
      // Check MSP role assignment for tenant-workspace-group
      (!isTenantWorkspaceGroup && !isTenantsOnly)) // Hide fields if 'Tenants only' is selected
  )
}

export const isRoleAssignmentAlreadyExists = ({
  existingRoleAssignments,
  isMsp,
  selectedDetails,
  ccsApplicationId,
  rrpWorkForMSP
}) => {
  if (rrpWorkForMSP) {
    return existingRoleAssignments.filter(
      (data) =>
        data?.application_id === selectedDetails.application_id &&
        data?.slug === selectedDetails.role_slug &&
        data.access_rules &&
        isEqual(selectedDetails.access_rules, data.access_rules) &&
        // skipping rrp for msp check for HPE GreenLake Platform for assign role confirmation
        isEqual(
          selectedDetails.application_id === ccsApplicationId
            ? []
            : data?.resource_restriction_policies
                ?.map((rrp) => rrp?.resource_restriction_policy_id)
                ?.sort(),
          selectedDetails?.resource_restriction_policies
            ?.map((rrp) => rrp?.id)
            ?.sort()
        )
    )?.length
  }
  if (isMsp) {
    const existingRoleAccessRules = existingRoleAssignments
      .filter(
        (data) =>
          data.application_id === selectedDetails.application_id &&
          data.slug === selectedDetails.role_slug &&
          data.access_rules
      )
      .map((data) => data.access_rules)
    return existingRoleAccessRules.some((val) =>
      isEqual(selectedDetails.access_rules, val)
    )
  }
  if (selectedDetails.application_id === ccsApplicationId) {
    const existingCCSRoles =
      existingRoleAssignments
        ?.filter((data) => data.application_id === ccsApplicationId)
        ?.map((data) => data.slug) || []
    return existingCCSRoles.includes(selectedDetails.role_slug)
  }
  if (
    selectedDetails.application_id &&
    selectedDetails.role_slug &&
    selectedDetails.resource_restriction_policies
  ) {
    return existingRoleAssignments.filter(
      (data) =>
        data?.application_id === selectedDetails.application_id &&
        data?.slug === selectedDetails.role_slug &&
        isEqual(
          data?.resource_restriction_policies
            ?.map((rrp) => rrp?.resource_restriction_policy_id)
            ?.sort(),
          selectedDetails?.resource_restriction_policies
            ?.map((rrp) => rrp?.id)
            ?.sort()
        )
    )?.length
  }
  return false
}

export const getAccessRulesIndex = (selectedDetails) => {
  let access_rules_index
  if (
    selectedDetails.msp_role_assignment === 'yes' &&
    selectedDetails?.workspace_role_assignment === 'ALL'
  ) {
    access_rules_index = 0
  } else if (
    selectedDetails.msp_role_assignment === 'yes' &&
    selectedDetails?.workspace_role_assignment === 'none'
  ) {
    access_rules_index = 1
  } else if (
    selectedDetails.msp_role_assignment === 'no' &&
    selectedDetails?.workspace_role_assignment === 'ALL'
  ) {
    access_rules_index = 2
  } else if (
    selectedDetails.msp_role_assignment === 'yes' &&
    selectedDetails?.workspace_role_assignment === 'specific'
  ) {
    access_rules_index = 3
  } else if (
    selectedDetails.msp_role_assignment === 'no' &&
    selectedDetails?.workspace_role_assignment === 'specific'
  ) {
    access_rules_index = 4
  }
  return access_rules_index
}

// --------------------------UserInput--------------------------
export const dropdownCustomRender = (userData) => {
  return (
    <Box
      direction="row"
      align="center"
      gap="small"
      pad={{ horizontal: 'medium', vertical: 'small' }}
    >
      <AvatarInfo
        avatarChar={userData?.first_name && userData?.last_name ? 'two' : 'one'}
        primaryInfo={userData.full_name}
        secondaryInfo=""
        testId="user-info-avatar-only"
        avatarOnly
      />
      <Box>
        <Typography type="text" testId="username" emphasis>
          {userData.full_name}
        </Typography>
        <Typography type="text" testId="email" size="small">
          {userData?.email}
        </Typography>
      </Box>
    </Box>
  )
}
