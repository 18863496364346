// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { del, getErrorMessage, put } from '../../utils/api-utils'

export const handleDelete = (
  requestBody,
  token,
  setShowModal,
  setErrorMessage,
  t,
  onSuccess,
  successMsg,
  deletingAllRules,
  setInitCount
) => {
  del(`/accounts/ui/v1/ip-access-rule`, requestBody, token).then(
    (response) => {
      if (response.status === 200 || response.status === 204) {
        setErrorMessage('')
        setShowModal(false)
        onSuccess(successMsg)
        if (deletingAllRules) setInitCount(0)
      }
    },
    (error) => {
      const errorMsg = getErrorMessage(error, t)
      setErrorMessage(errorMsg)
    }
  )
}

export const handleToggle = (
  enabled,
  token,
  setShowModal,
  setErrorMessage,
  t,
  onSuccess,
  successMsg,
  isFromCCSManger,
  customerId
) => {
  const url = isFromCCSManger
    ? `/support-assistant/v1alpha1/ip-access-rule/toggle?platform_customer_id=${customerId}&enable=${enabled}`
    : `/accounts/ui/v1/ip-access-rule/toggle?enable=${enabled}`

  put(url, {}, token).then(
    (response) => {
      if (response.status === 200) {
        setErrorMessage('')
        setShowModal(false)
        onSuccess(successMsg)
      }
    },
    (error) => {
      const errorMsg = getErrorMessage(error, t)
      setErrorMessage(errorMsg)
    }
  )
}
