// (C) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import { Box, InfiniteScroll } from 'grommet'
import PropTypes from 'prop-types'

import { getprimaryField } from '../utils'
import { Typography, Loader } from '../../../../components'

const ActionComponent = ({
  content,
  handleActionClick,
  renderHint,
  setOpenDropdown,
  onMoreHandler,
  loadingPagination,
  navigate
}) => {
  const primaryField = getprimaryField(renderHint?.renderingHints)

  return (
    <Box direction="column" gap="medium">
      <InfiniteScroll
        items={content?.results}
        onMore={() => onMoreHandler(content?.pagination, content?.category)}
      >
        {(item, index) => (
          <Box
            pad={{ horizontal: 'xsmall', vertical: 'xsmall' }}
            direction="column"
            align="start"
            alignContent="start"
            focusIndicator={false}
            hoverIndicator
            onClick={() => {
              const path = item?.path
              if (item?.externalRedirection) {
                window.open(path, '_blank')
              } else {
                navigate(path)
                setOpenDropdown(false)
              }
              handleActionClick(item[primaryField], content?.category)
            }}
          >
            <Typography
              type="text"
              testId={`gs-action-title-${index}`}
              emphasis
            >
              {item[primaryField]}
            </Typography>
            {item?.highlight && (
              <Typography
                type="paragraph"
                testId={`gs-action-desc-${index}`}
                maxLines={2}
                emphasis
                fill
              >
                {item?.highlight[Object.keys(item?.highlight)[0]]?.[0]}
              </Typography>
            )}
          </Box>
        )}
      </InfiniteScroll>
      {loadingPagination && (
        <Box align="center">
          <Loader testId="gs-refresh-onMore" />
        </Box>
      )}
    </Box>
  )
}

ActionComponent.propTypes = {
  content: PropTypes.any.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  renderHint: PropTypes.any.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  onMoreHandler: PropTypes.func.isRequired,
  loadingPagination: PropTypes.bool.isRequired,
  navigate: PropTypes.any.isRequired
}

export { ActionComponent }
