import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Loader,
  ModalDialog,
  Typography,
  ButtonGroup
} from '../../../../../components'
import { del, getErrorMessage } from '../../../../../utils/api-utils'

const DeleteFirmwareModal = ({
  id,
  setDeleteFirmwareModal,
  refreshDataTable,
  setDeleteFirmwareMessage,
  setDeleteFirmwareErrorMessage
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  const [loaderStatus, setLoaderStatus] = useState(false)

  const handleSubmit = () => {
    del(
      `/firmware-registry/ui/v1/firmware/${id}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setLoaderStatus(false)
        setDeleteFirmwareModal(false)
        setDeleteFirmwareMessage(t('firmware.deleted'))
        refreshDataTable()
      },
      (apiError) => {
        setLoaderStatus(false)
        const backendErrorMessage = getErrorMessage(apiError, t)
        setDeleteFirmwareErrorMessage(backendErrorMessage)
        setDeleteFirmwareModal(false)
      }
    )
  }
  const handleReset = () => {
    setDeleteFirmwareModal(false)
  }

  return (
    <ModalDialog
      width="medium"
      testId="delete-firmware-modal"
      content={
        <Box gap="small">
          <Typography type="heading" testId="delete-firmware-title">
            {t('firmware.delete_firmware')}
          </Typography>

          <Typography type="text" testId="delete-firmware-desc">
            {t('firmware.delete_firmware_confirmation')}
          </Typography>
          <Box
            direction="row"
            align="center"
            margin="small"
            alignContent="center"
            justify="end"
          >
            {loaderStatus && (
              <Box align="center" justify="end" alignSelf="end">
                <Loader
                  label={t('firmware.deleting_firmware')}
                  testId="loader-spinner"
                  orientation="horizontal"
                />
              </Box>
            )}
            <Box align="end" alignContent="flex-end" justify="end">
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('firmware.cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    type: 'reset',
                    onClick: () => {
                      setDeleteFirmwareModal(false)
                    }
                  },

                  {
                    id: 1,
                    label: t('firmware.delete'),
                    primary: true,
                    disabled: loaderStatus,
                    testId: 'delete-btn',
                    type: 'submit',
                    onClick: () => {
                      setLoaderStatus(true)
                      handleSubmit()
                    }
                  }
                ]}
                testId="two-buttons"
              />
            </Box>
          </Box>
        </Box>
      }
      onClose={handleReset}
    />
  )
}

DeleteFirmwareModal.propTypes = {
  id: PropTypes.string.isRequired,
  setDeleteFirmwareModal: PropTypes.func.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  setDeleteFirmwareMessage: PropTypes.func.isRequired,
  setDeleteFirmwareErrorMessage: PropTypes.func.isRequired
}

export { DeleteFirmwareModal }
