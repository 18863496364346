// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

export const categoryArray = [
  'General',
  'Management and governance',
  'Compute',
  'Networking',
  'Storage',
  'Workloads',
  'Private Cloud'
]

export const VideoTutorialsData = (t) => {
  const i18nCategoryMap = {
    General: t(
      'get_started_videos_tutorials.videos_tutorial_page.categories.general'
    ),
    'Management and governance': t(
      'get_started_videos_tutorials.videos_tutorial_page.categories.management_and_governance'
    ),
    Compute: t(
      'get_started_videos_tutorials.videos_tutorial_page.categories.compute'
    ),
    Networking: t(
      'get_started_videos_tutorials.videos_tutorial_page.categories.networking'
    ),
    Storage: t(
      'get_started_videos_tutorials.videos_tutorial_page.categories.storage'
    ),
    Workloads: t(
      'get_started_videos_tutorials.videos_tutorial_page.categories.workloads'
    ),
    'Private Cloud': t(
      'get_started_videos_tutorials.videos_tutorial_page.categories.private_cloud'
    )
  }

  return i18nCategoryMap
}

export const filterAttributes = (t) => {
  const videoTutorialsData = VideoTutorialsData(t)

  return [
    {
      label: t(
        'get_started_videos_tutorials.videos_tutorial_page.vt_categories'
      ),
      name: 'categories',
      selectionType: 'select-multiple',
      values: [
        {
          valueLabel: videoTutorialsData.General,
          valueName: 'General'
        },
        {
          valueLabel: videoTutorialsData['Management and governance'],
          valueName: 'Management and governance'
        },
        {
          valueLabel: videoTutorialsData.Compute,
          valueName: 'Compute'
        },
        {
          valueLabel: videoTutorialsData.Networking,
          valueName: 'Networking'
        },
        {
          valueLabel: videoTutorialsData.Storage,
          valueName: 'Storage'
        },
        {
          valueLabel: videoTutorialsData.Workloads,
          valueName: 'Workloads'
        },
        {
          valueLabel: videoTutorialsData['Private Cloud'],
          valueName: 'Private Cloud'
        }
      ]
    }
  ]
}
