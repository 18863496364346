// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Box } from 'grommet'
import { Previous } from 'grommet-icons'

import { Button } from '../../../../components'

const LocationDetailsHeader = ({ isCCSManager = false }) => {
  const { t } = useTranslation(['location'])
  const navigate = useNavigate()
  return (
    <Box align="center">
      <Box width="large" direction="row" align="center">
        <Button
          default
          label={t('location:location_title')}
          icon={<Previous />}
          onClick={() => {
            navigate(
              isCCSManager
                ? {
                    pathname: '/manage-ccs/customers/customer-details',

                    tabName: 'locations'
                  }
                : '/manage-account/locations'
            )
          }}
          testId="location-details-page-back-button"
          justify="start"
          margin="none"
          pad="none"
        />
      </Box>
    </Box>
  )
}

LocationDetailsHeader.propTypes = {
  isCCSManager: PropTypes.bool
}

export { LocationDetailsHeader }
