// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { StatusCritical, StatusGood } from 'grommet-icons'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import SideDetailsPanel from '../../../common-components/SideDetailsPanel'
import {
  DataTable,
  ActionButton,
  Notification
} from '../../../../../components'
import { get, getErrorMessage } from '../../../../../utils/api-utils'
import { getPaginationShowIdx } from '../../../../../utils/common-utils'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import EditModal from './EditModal'
import { DeleteFirmwareModal } from './DeleteFirmwareModal'

const FirmwareDataTable = ({ refreshCount }) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [rowId, setRowId] = useState()
  const [showDeleteFirmwareModal, setDeleteFirmwareModal] = useState(false)
  const [firmwareData, setFirmwareData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(false)
  const [showDetailsPanel, setShowDetailsPanel] = useState(false)
  const [detailsPanelTitle, setDetailsPanelTitle] = useState(null)
  const [rowDetails, setRowDetails] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [fileName, setFileName] = useState('')

  // for pagination
  const firmwarePerPage = 10
  const [page, setPage] = useState(1)
  const [totalNoOffirmware, setTotalNoOffirmware] = useState(firmwarePerPage)
  const pageIdxInfo = getPaginationShowIdx(
    page,
    totalNoOffirmware,
    firmwarePerPage,
    t
  )
  const columns = [
    {
      property: 'file_name',
      type: 'string',
      header: t('firmware.file_name'),
      primary: true
    },
    {
      property: 'version',
      type: 'numeric',
      header: t('firmware.version')
    },
    {
      property: 'platform',
      type: 'string',
      header: t('firmware.platform')
    },
    {
      property: 'build',
      type: 'numeric',
      header: t('firmware.build')
    },
    {
      property: 'cbuild',
      type: 'string',
      header: t('firmware.c_build')
    },
    {
      property: '',
      header: '',
      render: (data) => (
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/activate/firmware',
            permission: 'ccs.activate.firmware.update'
          }}
        >
          <ActionButton
            actions={[
              {
                label: t('edit'),
                onClick: () => {
                  setRowDetails(data)
                  setShowEditModal(true)
                },
                visibility: {
                  rbac: {
                    resource: '/ccs/activate/firmware',
                    permission: 'ccs.activate.firmware.update'
                  }
                }
              },
              {
                label: t('delete_label'),
                onClick: () => {
                  setRowId(data.id)
                  setDeleteFirmwareModal(true)
                },
                visibility: {
                  rbac: {
                    resource: '/ccs/activate/firmware',
                    permission: 'ccs.activate.firmware.delete'
                  }
                }
              }
            ]}
            showOneActionAsDropDown
            customRenderer={(actionBtn, visibility, idx) => {
              return (
                !actionBtn.hidden && (
                  <VisibilityWrapper
                    key={idx} // eslint-disable-line react/no-array-index-key
                    rbac={visibility.rbac}
                  >
                    {actionBtn}
                  </VisibilityWrapper>
                )
              )
            }}
            testId="firmware-table-action-btn"
          />
        </VisibilityWrapper>
      )
    }
  ]

  const fetchFirmwares = useCallback(() => {
    get(
      `/firmware-registry/ui/v1/firmware`,
      {
        limit: firmwarePerPage,
        offset: (page - 1) * firmwarePerPage,
        ...(fileName.trim().length && { file_name: fileName.trim() })
      },
      oidcUser.access_token
    ).then(
      (response) => {
        setFirmwareData(response?.data?.data)
        setTotalNoOffirmware(response?.data?.pageInfo?.total_count)
        setErrorMessage(null)
      },
      (error) => {
        setFirmwareData([])
        setErrorMessage(getErrorMessage(error, t))
        console.log(error)
      }
    )
  }, [oidcUser.access_token, t, page, fileName])

  useEffect(() => {
    fetchFirmwares()
  }, [fetchFirmwares, refreshCount])

  const getfirmwareData = (data) => {
    const tabledata = [...data]
    return tabledata.map((datum) => {
      return {
        ...datum,
        cbuild: datum.cbuild ? t('firmware.yes') : t('firmware.no'),
        beta: datum.beta ? t('firmware.yes') : t('firmware.no'),
        is_compliant: datum.is_compliant ? t('firmware.yes') : t('firmware.no'),
        dependent: datum.dependent ? t('firmware.yes') : t('firmware.no')
      }
    })
  }
  const firmwareDetailKeys = {
    file_name: t('firmware.file_name'),
    file_size: t('firmware.file_size'),
    build: t('firmware.build'),
    version: t('firmware.version'),
    oem: t('firmware.oem'),
    checksum: t('firmware.checksum'),
    is_compliant: t('firmware.compliant'),
    cbuild: t('firmware.c_build'),
    platform: t('firmware.platform'),
    track: t('firmware.track'),
    beta: t('firmware.beta'),
    dependent: t('firmware.dependent'),
    created_user_id: t('firmware.created_user_id'),
    device_type: t('firmware.device_type'),
    platform_id: t('firmware.platform_id'),
    major_version: t('firmware.major_version'),
    minor_version: t('firmware.minor_version'),
    created_at: t('firmware.created_at')
  }

  const createRowdata = (datum) => {
    return Object.keys(firmwareDetailKeys).map((key) => {
      if (key === 'created_at') {
        return {
          key: firmwareDetailKeys[key],
          value: dayjs(datum[key]).format('MM/DD/YYYY HH:mm')
        }
      }
      return {
        key: firmwareDetailKeys[key],
        value: datum[key]
      }
    })
  }
  return (
    <>
      <DataTable
        pagination={{
          totalItems: totalNoOffirmware,
          itemsPerPage: firmwarePerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        grid={{
          columns,
          data: getfirmwareData(firmwareData),
          onClickRow: (data) => {
            setDetailsPanelTitle(data.datum.file_name)
            setRowDetails(createRowdata(data.datum))
            setShowDetailsPanel(true)
          }
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setFileName(value)
          },
          placeholder: t('firmware.firmware-search-box-placeholder')
        }}
        summary={{
          entityName: t('firmware.firmware-table-summary')
        }}
        testId="firmware-data-table"
      />
      {showDetailsPanel && (
        <SideDetailsPanel
          title={detailsPanelTitle}
          data={rowDetails}
          onClose={(e) => setShowDetailsPanel(e)}
        />
      )}
      {showEditModal && (
        <EditModal
          data={rowDetails}
          setModalOpen={setShowEditModal}
          onSuccess={() => {
            setSuccessMessage(t('firmware.updated'))
            fetchFirmwares()
            setShowEditModal(false)
          }}
        />
      )}

      {showDeleteFirmwareModal && (
        <DeleteFirmwareModal
          id={rowId}
          setDeleteFirmwareModal={setDeleteFirmwareModal}
          refreshDataTable={() => fetchFirmwares()}
          setDeleteFirmwareMessage={setSuccessMessage}
          setDeleteFirmwareErrorMessage={setErrorMessage}
        />
      )}

      {successMessage && (
        <Notification
          icon={<StatusGood color="text-strong" />}
          onClose={() => setSuccessMessage(false)}
          testId="status-good-notification"
          text={successMessage}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </>
  )
}
FirmwareDataTable.propTypes = {
  refreshCount: PropTypes.number.isRequired
}
export { FirmwareDataTable }
