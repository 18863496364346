// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box, FormField } from 'grommet'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Dropdown } from '../../../components'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { isCoP, isTenant } from '../../../utils/feature-flag-utils'

import { dropdownCustomRender } from './utils'

const UserInput = ({ setUserData, selectedDetails, setSelectedDetails }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn', 'common'])
  const [usersList, setUsersList] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  const getUserFullName = (datum) => {
    const firstName = datum?.first_name
    const lastName = datum?.last_name
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || datum?.email
  }

  // for paginating on scroll
  const itemsPerPage = 50
  const LDFlags = useFlags()
  const [page, setPage] = useState(0)
  const [appendList, setAppendList] = useState(false)
  const [totalItems, setTotalItems] = useState(itemsPerPage)

  useEffect(() => {
    const request = {
      limit: itemsPerPage,
      offset: page * itemsPerPage,
      include_unverified: true,
      // user_status: 'VERIFIED',
      ...(searchVal.trimStart().length > 0 && {
        search_string: searchVal.trimStart()
      }),
      ...(isTenant() && { ignore_msp_users: true })
    }
    get(`/ui-doorway/ui/v2/um/users`, request, oidcUser.access_token).then(
      (resp) => {
        const users =
          isCoP() || LDFlags['glcp-edit-federated-user-role']
            ? resp?.data?.users || []
            : resp?.data?.users?.filter(
                (user) => user.user_type !== 'FEDERATED'
              ) || []
        let userList = []
        if (!isEmpty(users)) {
          userList = users.map((user) => {
            return {
              ...user,
              user_email: user?.email,
              full_name: getUserFullName(user)
            }
          })
        }
        if (appendList) {
          setUsersList((prevState) =>
            uniqBy([...prevState, ...userList], 'user_email')
          )
        } else {
          setUsersList(userList)
        }
        setTotalItems(resp?.data?.pagination?.total_count || itemsPerPage)
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }, [oidcUser.access_token, searchVal, t, page, LDFlags, appendList])

  const handleDebouncedSearchValue = debounce((value) => {
    setAppendList(false)
    setPage(0)
    setSearchVal(value)
  }, 1000)

  return (
    <Box>
      <FormField
        label={t('users.user')}
        name="user_email"
        data-testid="select-user-form-field"
        required
      >
        <Dropdown
          id="user-dropdown"
          testId="user-dropdown"
          name="user_email"
          options={usersList}
          multiple={false}
          value={selectedDetails.user_email}
          valueKey={{
            key: 'user_email',
            reduce: true
          }}
          labelKey="full_name"
          placeholder={t('users.select_a_user')}
          onChange={({ option }) => {
            setUserData(option)
            setSelectedDetails({
              ...selectedDetails,
              user_email: option.user_email
            })
            setAppendList(true)
          }}
          onMore={() => {
            if (
              totalItems > itemsPerPage &&
              page < totalItems / itemsPerPage - 1
            ) {
              setAppendList(true)
              setPage(page + 1)
            }
          }}
          onClose={() => {
            if (searchVal) {
              setAppendList(false)
              setPage(0)
              setSearchVal('')
            }
          }}
          onSearch={(searchText) => {
            handleDebouncedSearchValue(searchText)
          }}
          customRender={(option) => dropdownCustomRender(option)}
          dropHeight="medium"
          searchPlaceholder="Search"
          noBorder
        />
      </FormField>
      {showErrorNotification}
    </Box>
  )
}

UserInput.propTypes = {
  setUserData: PropTypes.func.isRequired,
  selectedDetails: PropTypes.object.isRequired,
  setSelectedDetails: PropTypes.func.isRequired
}

export { UserInput }
