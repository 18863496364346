// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import {
  GuidedTourPlugin,
  GuidedTourStep
} from '@storage/react-guided-tour-plugin'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { GuidedWorkspaceData } from '../GuidedWorkspaceData'

const GuidedTour = ({ tourName, setIsGuidedModalOpen, pathname }) => {
  const { t } = useTranslation(['dashboard'])
  const stopGuidedTour = () => {
    sessionStorage.removeItem('glcpGuidedWorkspace')
    setIsGuidedModalOpen(false)
  }

  const data = GuidedWorkspaceData()
  return (
    <GuidedTourPlugin
      dataTestId={tourName}
      tourName={tourName}
      finishBtnLabel={t('dashboard:guided_workspace.close_btn')}
      onTourSkip={() => stopGuidedTour()}
      onTourFinish={() => {
        stopGuidedTour()
      }}
      steps={data[pathname]}
    >
      {data[pathname]?.map((item) => (
        <GuidedTourStep
          dataTestId="guided-content"
          slot={item?.slot}
          align="start"
        >
          {item?.content}
        </GuidedTourStep>
      ))}
    </GuidedTourPlugin>
  )
}

GuidedTour.propTypes = {
  tourName: PropTypes.string.isRequired,
  setIsGuidedModalOpen: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired
}
export default GuidedTour
