// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import VideoTemplate from '../../templates/VideoTemplate'
import DocumentTemplate from '../../templates/DocumentTemplate'
import ForumTemplate from '../../templates/ForumTemplate'
import NoResults from '../../templates/NoResults'
import { Loader } from '../../../../components'

const documentTemplate = (result, engine) => (
  <DocumentTemplate key={result?.uniqueId} result={result} engine={engine} />
)

const videoTemplate = (result, engine) => (
  <VideoTemplate key={result?.uniqueId} result={result} engine={engine} />
)

const forumTemplate = (result, engine) => (
  <ForumTemplate key={result?.uniqueId} result={result} engine={engine} />
)

const ResultList = ({ controller, templatemanager, engine }) => {
  const [state, setState] = useState(controller?.state)
  const [loading, setLoading] = useState(true)

  const headlessResultTemplatesManager = templatemanager

  useEffect(() => {
    let isMounted = true
    controller?.subscribe(() => {
      if (isMounted) {
        setState(controller?.state)
        document.querySelector('.appLayout')?.scrollTo(0, 0)
      }
    })

    headlessResultTemplatesManager?.registerTemplates(
      {
        fields: [
          'contenttype',
          'kmdocpublicationtitle',
          'hpescuniversaldate',
          'kmdocid',
          'kmdoclocale'
        ],
        conditions: [
          (result) =>
            result?.raw?.contenttype &&
            result?.raw?.contenttype.includes('Documents')
        ],
        content: documentTemplate,
        priority: 0
      },
      {
        fields: [
          'contenttype',
          'kmvideothumbnail',
          'kmvideodescription',
          'hpescuniversaldate',
          'kmvideoid'
        ],
        conditions: [
          (result) =>
            result?.raw?.contenttype &&
            result?.raw?.contenttype.includes('Videos')
        ],
        content: videoTemplate,
        priority: 1
      },
      {
        fields: [
          'contenttype',
          'hpescuniversaldate',
          'limessageissolution',
          'hpesubcategory',
          'limessageauthor'
        ],
        conditions: [
          (result) =>
            result?.raw?.contenttype &&
            (result?.raw?.contenttype.includes('community-forums') ||
              result?.raw?.contenttype.includes('Forums'))
        ],
        content: forumTemplate,
        priority: 2
      }
    )

    setLoading(state?.isLoading)

    return () => {
      isMounted = false
    }
  }, [controller, headlessResultTemplatesManager, engine, state?.isLoading])

  if (loading) {
    return (
      <Box align="center">
        <Loader testId="result-list-loader" />
      </Box>
    )
  }

  if (!state?.hasResults) {
    return <NoResults />
  }

  return (
    <Box>
      {state?.results?.map((result) => {
        const template = headlessResultTemplatesManager?.selectTemplate(result)
        return template ? template(result, engine) : null
      })}
    </Box>
  )
}

ResultList.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired
  }).isRequired,
  templatemanager: PropTypes.object.isRequired,
  engine: PropTypes.object.isRequired
}

export default ResultList
