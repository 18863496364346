// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  FormInput,
  Loader,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'
import { updateV2RBACPolicies } from '../../../../utils/rbac-api-utils'
import { useVisibilityContext } from '../../../../context/visibility-context'
import { useCCSContext } from '../../../../context/ccs-context'

const RemoveGroupModal = ({
  onSetOpen,
  userGroupToRemove,
  setParentNotification,
  refreshParent = () => {}
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const { csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [formValues, setFormValues] = useState({
    groupName: ''
  })

  const closeModal = () => onSetOpen(false)

  const deleteGroup = async () => {
    const { id } = userGroupToRemove
    if (isEmpty(formValues?.groupName?.trim()))
      setErrorMsg(t('common:this_is_required'))
    else if (
      formValues?.groupName?.trim() &&
      formValues?.groupName?.trim() !== t('common:delete_keyword')
    )
      setErrorMsg(t('common:invalid_keyword'))
    else {
      setLoading(true)
      try {
        await del(`/identity/v2alpha1/scim/v2/Groups/${id}`, {})
        closeModal()
        setLoading(false)
        refreshParent()
        // This could affect the current user's permissions hence why we're calling updateV2RBACPolicies
        // updateV2RBACPolicies will not run on local as csrfToken will be null
        updateV2RBACPolicies(dispatchVisibilityContext, csrfToken)
      } catch (error) {
        closeModal()
        setLoading(false)
        setParentNotification(
          getErrorMessage(error, t),
          'error',
          t('iam:organization_groups.remove_group_failed_msg')
        )
      }
    }
  }

  return (
    <ModalDialog
      header={
        loading ? null : (
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="2"
                testId="delete-group-confirm-heading"
              >
                {t('iam:organization_groups.remove_group_modal_title')}
              </Typography>
            }
          />
        )
      }
      content={
        loading ? (
          <Box
            fill
            align="center"
            justify="center"
            pad={{ vertical: 'small', horizontal: 'large' }}
          >
            <Loader
              label={t('common:removing')}
              testId="remove-user-group-loader"
            />
          </Box>
        ) : (
          <Box gap="small" margin={{ top: 'small' }}>
            <Typography type="text" testId="delete-group-warning-msg">
              <Trans>
                {t('iam:organization_groups.delete_group_warning_msg_v2', {
                  groupName: userGroupToRemove?.displayName
                })}
              </Trans>
            </Typography>
            <Typography type="text" testId="delete-group-confirm-msg">
              {t('iam:organization_groups.delete_group_confirm_msg')}
            </Typography>
            <CCSForm
              values={formValues}
              onChange={setFormValues}
              testId="delete-group-modal-form"
              errorMessage=""
              buttons={
                <Box
                  direction="row"
                  justify="end"
                  gap="medium"
                  margin={{ top: 'medium' }}
                >
                  <Button
                    label={t('common:cancel')}
                    onClick={closeModal}
                    disabled={loading}
                    testId="cancel-btn"
                  />
                  <Button
                    label={t('common:delete')}
                    primary
                    onClick={deleteGroup}
                    testId="delete-group-btn"
                    disabled={loading}
                  />
                </Box>
              }
            >
              <FormInput
                required
                name="groupName"
                inputType="text"
                label={t('common:confirm')}
                labelHelper={t('common:type_delete', {
                  delete_keyword: t('common:delete_keyword')
                })}
                testId="delete-group"
                error={errorMsg}
                onChange={() => {
                  setErrorMsg('')
                }}
              />
            </CCSForm>
          </Box>
        )
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      width={loading ? 'small' : 'medium'}
      testId="remove-group-modal-dialog"
    />
  )
}

RemoveGroupModal.propTypes = {
  /**
   * Callback function to close modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * User group to remove
   */
  userGroupToRemove: PropTypes.object.isRequired,

  /**
   * Notification info
   */
  setParentNotification: PropTypes.func.isRequired,

  /**
   * refresh page
   */
  refreshParent: PropTypes.func
}

export default RemoveGroupModal
