// Copyright 2024 Hewlett Packard Enterprise Development LP

import { VisibilityActions } from '../context/visibility-context'

import { get, post, axiosRetryPost, updateCoreData } from './api-utils'
import {
  getOrganizationId,
  getCustomerAccount,
  isGLOP
} from './feature-flag-utils'

export const fetchV2RBACPermissions = async (csrfToken) => {
  if (!csrfToken) return null
  const resp = await get('/authorization/v2alpha1/current-user-permissions')
  return resp.data
}
const fetchV2RBACRootWrkspcPermissions = async (rootWkspId, csrfToken) => {
  if (!csrfToken) return null
  const resp = await get(
    `/authorization/v2alpha1/current-user-permissions?workspace-id=${encodeURIComponent(
      rootWkspId
    )}`
  )
  return resp.data
}
export const permissionsList = [
  // Device-Management
  {
    permission: 'ccs.device-management.view',
    application_resource: '/ccs/device-management'
  },
  {
    permission: 'ccs.device-management.edit',
    application_resource: '/ccs/device-management'
  },
  // Device-Management COP only
  {
    permission: 'ccs.device-profile-management.view',
    application_resource: '/ccs/device-profile-management'
  },
  {
    permission: 'ccs.device-profile-management.edit',
    application_resource: '/ccs/device-profile-management'
  },
  {
    permission: 'ccs.authorization.view', // observer
    application_resource: '/ccs/authorization'
  },
  {
    permission: 'ccs.authorization.edit', // admin, operator
    application_resource: '/ccs/authorization'
  },
  {
    permission: 'ccs.authorization.delete', // admin
    application_resource: '/ccs/authorization'
  },
  // App-Catalog
  {
    permission: 'ccs.app-catalog.view',
    application_resource: '/ccs/app-catalog/application'
  },
  {
    permission: 'ccs.app-catalog.edit',
    application_resource: '/ccs/app-catalog/application'
  },
  {
    permission: 'ccs.app-catalog.delete',
    application_resource: '/ccs/app-catalog/application'
  },
  {
    permission: 'ccs.app-catalog.ccs-admin-op',
    application_resource: '/ccs/app-catalog/application'
  },
  {
    permission: 'ccs.app-catalog.view',
    application_resource: '/ccs/app-catalog/app-instance'
  },
  {
    permission: 'ccs.app-catalog.edit',
    application_resource: '/ccs/app-catalog/app-instance'
  },
  {
    permission: 'ccs.app-catalog.delete',
    application_resource: '/ccs/app-catalog/app-instance'
  },
  {
    permission: 'ccs.app-catalog.ccs-admin-op',
    application_resource: '/ccs/app-catalog/app-instance'
  },
  {
    permission: 'ccs.audit-trail.view',
    application_resource: '/ccs/audit-trail'
  },
  {
    permission: 'ccs.app-provision.view',
    application_resource: '/ccs/app-provision/provisions'
  },
  {
    permission: 'ccs.app-provision.edit',
    application_resource: '/ccs/app-provision/provisions'
  },
  // Account Management
  {
    permission: 'ccs.accounts.platform.customer.view',
    application_resource: '/ccs/accounts/platform/customer'
  },
  {
    permission: 'ccs.accounts.platform.customer.edit',
    application_resource: '/ccs/accounts/platform/customer'
  },
  {
    permission: 'ccs.accounts.platform.customer.delete',
    application_resource: '/ccs/accounts/platform/customer'
  },
  {
    permission: 'ccs.accounts.platform.customer.view',
    application_resource: '/ccs/accounts/platform/customer/toggle-msp'
  },
  {
    permission: 'ccs.accounts.platform.customer.edit',
    application_resource: '/ccs/accounts/platform/customer/toggle-msp'
  },
  {
    permission: 'ccs.accounts.users.view.all',
    application_resource: '/ccs/accounts/user'
  },
  {
    permission: 'ccs.accounts.user.delete',
    application_resource: '/ccs/accounts/user'
  },
  // Authentication - SAML
  {
    permission: 'ccs.accounts.platform.customer.edit',
    application_resource: '/ccs/accounts/platform/customer/saml'
  },
  // GEOm
  {
    permission: 'ccs.geo.view',
    application_resource: '/ccs/geo'
  },
  // Activate
  {
    permission: 'ccs.activate.view',
    application_resource: '/ccs/activate/customer'
  },
  {
    permission: 'ccs.activate.edit',
    application_resource: '/ccs/activate/customer'
  },
  {
    permission: 'ccs.activate.view',
    application_resource: '/ccs/activate/tac'
  },
  {
    permission: 'ccs.activate.edit',
    application_resource: '/ccs/activate/tac'
  },
  // CDS
  {
    permission: 'ccs.consumption-data.view',
    application_resource: '/ccs/consumption-data'
  },
  {
    permission: 'ccs.device-showback-rate.view',
    application_resource: '/ccs/device-management/showback-rate'
  },
  {
    permission: 'ccs.device-showback-rate.manage',
    application_resource: '/ccs/device-management/showback-rate'
  },
  {
    permission: 'ccs.billing-export.manage',
    application_resource: '/ccs/billing-export'
  },
  // Notifications
  {
    permission: 'ccs.notification.edit',
    application_resource: '/ccs/notification'
  },
  // Location Management
  {
    permission: 'ccs.location-management.view',
    application_resource: '/ccs/location-management'
  },
  {
    permission: 'ccs.location-management.edit',
    application_resource: '/ccs/location-management'
  },
  // Billing Accounts
  {
    permission: 'ccs.billing-accounts.view',
    application_resource: '/ccs/billing-accounts'
  },
  {
    permission: 'ccs.billing-accounts.edit',
    application_resource: '/ccs/billing-accounts'
  },
  // Firmware
  {
    permission: 'ccs.activate.firmware.view',
    application_resource: '/ccs/activate/firmware'
  },
  {
    permission: 'ccs.activate.firmware.upload',
    application_resource: '/ccs/activate/firmware'
  },
  {
    permission: 'ccs.activate.firmware.update',
    application_resource: '/ccs/activate/firmware'
  },
  {
    permission: 'ccs.activate.firmware.delete',
    application_resource: '/ccs/activate/firmware'
  },
  // WhatsNew Management
  {
    permission: 'ccs.whats-new.view',
    application_resource: '/ccs/whats-new'
  },
  {
    permission: 'ccs.whats-new.edit',
    application_resource: '/ccs/whats-new'
  },
  {
    permission: 'ccs.whats-new.delete',
    application_resource: '/ccs/whats-new'
  },
  // unified wellness dashboard
  {
    permission: 'ccs.wellness-dashboard.view',
    application_resource: '/ccs/wellness-dashboard'
  },
  {
    permission: 'ccs.user-groups.view',
    application_resource: '/ccs/user-groups'
  },
  {
    permission: 'ccs.user-groups.edit',
    application_resource: '/ccs/user-groups'
  },
  {
    permission: 'ccs.user-groups.delete',
    application_resource: '/ccs/user-groups'
  },
  // Silvercreek (GreenLake OnPrem)
  // Silvercreek - Performance
  {
    permission: 'ccs.system-mgmt.performance.read',
    application_resource: '/ccs/system-mgmt/performance'
  },
  // Silvercreek - Certificates
  {
    permission: 'ccs.system-mgmt.certificates.read',
    application_resource: '/ccs/system-mgmt/certificates'
  },
  {
    permission: 'ccs.system-mgmt.certificates.create',
    application_resource: '/ccs/system-mgmt/certificates'
  },
  {
    permission: 'ccs.system-mgmt.certificates.update',
    application_resource: '/ccs/system-mgmt/certificates'
  },
  {
    permission: 'ccs.system-mgmt.certificates.delete',
    application_resource: '/ccs/system-mgmt/certificates'
  },
  // Silvercreek - Network
  {
    permission: 'ccs.system-mgmt.network.read',
    application_resource: '/ccs/system-mgmt/network'
  },
  {
    permission: 'ccs.system-mgmt.network.update',
    application_resource: '/ccs/system-mgmt/network'
  },
  {
    permission: 'ccs.system-mgmt.network.delete',
    application_resource: '/ccs/system-mgmt/network'
  },
  // Silvercreek - Support Logs
  {
    permission: 'ccs.system-mgmt.support-logs.read',
    application_resource: '/ccs/system-mgmt/support-logs'
  },
  {
    permission: 'ccs.system-mgmt.support-logs.create',
    application_resource: '/ccs/system-mgmt/support-logs'
  },
  {
    permission: 'ccs.system-mgmt.support-logs.delete',
    application_resource: '/ccs/system-mgmt/support-logs'
  },
  // Silvercreek - Backup
  {
    permission: 'ccs.system-mgmt.backup.read',
    application_resource: '/ccs/system-mgmt/backup'
  },
  {
    permission: 'ccs.system-mgmt.backup.create',
    application_resource: '/ccs/system-mgmt/backup'
  },
  // Silvercreek - Version
  {
    permission: 'ccs.system-mgmt.version.read',
    application_resource: '/ccs/system-mgmt/version'
  },
  {
    permission: 'ccs.system-mgmt.version.create',
    application_resource: '/ccs/system-mgmt/version'
  },
  {
    permission: 'ccs.system-mgmt.version.update',
    application_resource: '/ccs/system-mgmt/version'
  },
  {
    permission: 'ccs.system-mgmt.version.delete',
    application_resource: '/ccs/system-mgmt/version'
  },
  // eval - observer
  {
    permission: 'ccs.orders.edit',
    application_resource: '/ccs/orders'
  },
  {
    application_resource: '/ccs/service-catalog/service-provision',
    permission: 'ccs.service-catalog.edit'
  },
  {
    application_resource: '/ccs/service-catalog/service-provision',
    permission: 'ccs.service-catalog.delete'
  },
  {
    permission: 'ccs.reporting.view',
    application_resource: '/ccs/reporting/dashboard'
  },
  {
    permission: 'ccs.reporting.edit',
    application_resource: '/ccs/reporting/dashboard'
  },
  {
    application_resource: '/ccs/service-catalog/service-provision',
    permission: 'ccs.service-catalog.delete'
  },
  // Automations Subscription
  {
    permission: 'ccs.events.subscription.create',
    application_resource: '/ccs/events/subscription'
  },
  {
    permission: 'ccs.events.subscription.read',
    application_resource: '/ccs/events/subscription'
  },
  {
    permission: 'ccs.events.subscription.update',
    application_resource: '/ccs/events/subscription'
  },
  {
    permission: 'ccs.events.subscription.delete',
    application_resource: '/ccs/events/subscription'
  },
  // Automations Webhook
  {
    permission: 'ccs.events.webhook.create',
    application_resource: '/ccs/events/webhook'
  },
  {
    permission: 'ccs.events.webhook.read',
    application_resource: '/ccs/events/webhook'
  },
  {
    permission: 'ccs.events.webhook.update',
    application_resource: '/ccs/events/webhook'
  },
  {
    permission: 'ccs.events.webhook.delete',
    application_resource: '/ccs/events/webhook'
  },
  // SAM Workspace access
  {
    permission: 'ccs.ccs-customer-ws-access.invite',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    permission: 'ccs.aruba-customer-ws-access.invite',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    permission: 'ccs.dscc-customer-ws-access.invite',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    permission: 'ccs.compute-customer-ws-access.invite',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    permission: 'ccs.ccs-customer-ws-access.view',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    permission: 'ccs.aruba-customer-ws-access.view',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    permission: 'ccs.dscc-customer-ws-access.view',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    permission: 'ccs.compute-customer-ws-access.view',
    application_resource: '/ccs/support-customer-ws-access'
  },
  {
    application_resource: '/ccs/device-management/alias',
    permission: 'ccs.device-subscription-alias.edit'
  },
  {
    application_resource: '/ccs/device-management/alias',
    permission: 'ccs.device-subscription-alias.view'
  },
  // ZTO
  {
    permission: 'ccs.device-subscription-zto.view',
    application_resource: '/ccs/device-management/zto'
  },
  {
    permission: 'ccs.device-subscription-zto.edit',
    application_resource: '/ccs/device-management/zto'
  },
  {
    application_resource: '/ccs/activate/global-trade',
    permission: 'ccs.activate.global-trade.view'
  },
  {
    application_resource: '/ccs/activate/global-trade',
    permission: 'ccs.activate.global-trade.edit'
  },
  {
    application_resource: '/ccs/workspace-access-se',
    permission: 'ccs.workspace-access-se.view'
  },
  // Global Search
  {
    application_resource: '/ccs/global-search/administration',
    permission: 'ccs.global-search-administration.view'
  },
  {
    application_resource: '/ccs/global-search/administration',
    permission: 'ccs.global-search-administration.edit'
  },
  {
    application_resource: '/ccs/global-search/administration-curation',
    permission: 'ccs.global-search-curation.view'
  },
  {
    application_resource: '/ccs/global-search/administration-curation',
    permission: 'ccs.global-search-curation.edit'
  }
]
const fetchRBACPermissions = async (token, platformCid, username) => {
  const requestBody = {
    enforcements: permissionsList
  }
  if (window.retryEnforce) {
    const retryConfig = {
      retries: 5,
      retryCondition: (error) => {
        return error.response.status >= 500
      },
      retryDelay: (retryCount) => {
        return retryCount * 1000
      }
    }

    return axiosRetryPost(
      `/authorization/ui/v1/customers/${platformCid}/users/${username}/enforce_batch`,
      requestBody,
      token,
      null,
      null,
      retryConfig
    ).then(
      (resp) => {
        updateCoreData({
          name: 'permissions',
          token,
          data: resp.data
        })
        return resp.data
      },
      (error) => {
        console.log(`retry error`, error)
      }
    )
  }

  return post(
    `/authorization/ui/v1/customers/${platformCid}/users/${username}/enforce_batch`,
    requestBody,
    token
  ).then(
    (resp) => {
      updateCoreData({
        name: 'permissions',
        token,
        data: resp.data
      })
      return resp.data
    },
    (error) => error
  )
}

export const formatRbacPolicies = (policies) => {
  const effects = policies?.effects?.reduce((formatted, policy) => {
    if (formatted[policy.application_resource]) {
      formatted[policy.application_resource][policy.permission] =
        policy.effect === 'ALLOW'
    } else {
      formatted[policy.application_resource] = {
        [policy.permission]: policy.effect === 'ALLOW'
      }
    }
    return formatted
  }, {})
  return { effects }
}

export const updateV2RBACPolicies = (
  dispatchVisibilityContext,
  csrfToken,
  setApiError = () => {}
) => {
  if (!csrfToken) return
  fetchV2RBACPermissions(csrfToken).then(
    (v2RbacPolicies) => {
      if (!v2RbacPolicies) return
      dispatchVisibilityContext({
        type: VisibilityActions.SET_V2_RBAC_POLICIES,
        data: v2RbacPolicies?.items
      })
    },
    (err) => {
      setApiError(err)
    }
  )

  get(`/organizations/v2alpha1/organizations/${getOrganizationId()}`).then(
    (res) => {
      const associatedWorkspaceId = res?.data?.associatedWorkspace?.id
      const associated =
        associatedWorkspaceId !== getCustomerAccount()?.platform_customer_id
      if (associated && !isGLOP()) {
        fetchV2RBACRootWrkspcPermissions(associatedWorkspaceId, csrfToken).then(
          (v2RootPolicies) => {
            dispatchVisibilityContext({
              type: VisibilityActions.SET_V2_ROOT_WKSPC_POLICIES,
              data: v2RootPolicies?.items
            })
          },
          (err) => {
            // TODO: Handle error
            console.log(err)
          }
        )
      }
    },
    (err) => {
      // TODO: Handle error
      console.log(err)
    }
  )
}

export const updateRBACPolicies = async (
  oidcUser,
  custAccount,
  dispatchVisibilityContext,
  setApiError = () => {}
) => {
  return fetchRBACPermissions(
    oidcUser.access_token,
    custAccount.platform_customer_id,
    oidcUser.profile.email
  ).then(
    (rbacPolicies) => {
      dispatchVisibilityContext({
        type: VisibilityActions.SET_RBAC_POLICIES,
        data: formatRbacPolicies(rbacPolicies)
      })
      if (window.NODE_ENV === 'development' && !isGLOP()) {
        updateV2RBACPolicies(dispatchVisibilityContext, true, setApiError)
      }
    },
    (err) => {
      // TODO: Handle error
      console.log(err)
    }
  )
}
