// (C) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types'

import { GetRenderingHints } from './utils'
import {
  QnAComponent,
  DefaultComponent,
  AccordionComponent,
  ActionComponent,
  WorkSpaceComponent
} from './components'

export const RenderDomainComponent = ({
  content,
  renderHints,
  handleListClick,
  handleActionClick,
  setOpenDropdown,
  i18nTranslation,
  onMoreHandler,
  loadingPagination,
  handleSwitchWorkspace,
  navigate
}) => {
  // Get the renderingHints of the hit's category

  const renderHint = GetRenderingHints(content?.category, renderHints)

  if (renderHint?.renderingHints?.displayType === 'SUMMARY') {
    return (
      <QnAComponent
        content={content}
        handleListClick={handleListClick}
        handleActionClick={handleActionClick}
        setOpenDropdown={setOpenDropdown}
        i18nTranslation={i18nTranslation}
        navigate={navigate}
      />
    )
  }
  if (renderHint?.renderingHints?.displayType === 'DEFAULT') {
    return (
      <DefaultComponent
        content={content}
        handleActionClick={handleActionClick}
        renderHint={renderHint}
        setOpenDropdown={setOpenDropdown}
        onMoreHandler={onMoreHandler}
        loadingPagination={loadingPagination}
        navigate={navigate}
      />
    )
  }
  if (renderHint?.renderingHints?.displayType === 'WORKSPACE') {
    return (
      <WorkSpaceComponent
        content={content}
        handleActionClick={handleActionClick}
        renderHint={renderHint}
        setOpenDropdown={setOpenDropdown}
        onMoreHandler={onMoreHandler}
        loadingPagination={loadingPagination}
        handleSwitchWorkspace={handleSwitchWorkspace}
        i18nTranslation={i18nTranslation}
      />
    )
  }
  if (renderHint?.renderingHints?.displayType === 'ACCORDION') {
    return (
      <AccordionComponent
        content={content}
        handleActionClick={handleActionClick}
        renderHint={renderHint}
        setOpenDropdown={setOpenDropdown}
        onMoreHandler={onMoreHandler}
        loadingPagination={loadingPagination}
        navigate={navigate}
      />
    )
  }
  if (renderHint?.renderingHints?.displayType === 'ACTION') {
    return (
      <ActionComponent
        content={content}
        handleActionClick={handleActionClick}
        renderHint={renderHint}
        onMoreHandler={onMoreHandler}
        loadingPagination={loadingPagination}
        setOpenDropdown={setOpenDropdown}
        navigate={navigate}
      />
    )
  }
  return <></>
}
RenderDomainComponent.propTypes = {
  content: PropTypes.any.isRequired,
  renderHints: PropTypes.any.isRequired,
  handleListClick: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired,
  onMoreHandler: PropTypes.func.isRequired,
  loadingPagination: PropTypes.bool.isRequired,
  handleSwitchWorkspace: PropTypes.func.isRequired,
  navigate: PropTypes.any.isRequired
}
