// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box, Accordion, AccordionPanel } from 'grommet'
import { Trash, Down } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../../context/authz-context'
import { Button, Typography } from '../../../../../components'

const SelectedPermissions = ({ application = '', selectedRole = {} }) => {
  const { t } = useTranslation(['authz', 'common'])
  // const [slugNameMap, setSlugNameMap] = useState({})
  const slugNameMap = {}

  // const { oidcUser } = useReactOidc()
  const {
    permissionsSelected,
    dispatchAUTHZContext,
    selectedTreeRolePermissions
  } = useAUTHZContext()
  console.log(application)
  const rolesPagePolicies = selectedRole?.resourcePolicies || []
  const removePermission = (permissionSlug, resMatcher) => {
    if (permissionSlug) {
      const filteredPermission = [
        ...permissionsSelected.filter(
          (removedPermission) => permissionSlug !== removedPermission
        )
      ]
      const filteredTreeRolePermission = [
        ...selectedTreeRolePermissions.filter(
          (rolePermission) => permissionSlug !== rolePermission.matcher
        )
      ]
      dispatchAUTHZContext({
        type: AUTHZActions.PERMISSIONS_SELECTED,
        data: filteredPermission
      })
      dispatchAUTHZContext({
        type: AUTHZActions.SET_TREE_ROLE_PERMISSIONS,
        data: filteredTreeRolePermission
      })

      const resIdx = rolesPagePolicies.findIndex((r) => {
        return r.resource.matcher === resMatcher
      })

      const resObj = rolesPagePolicies[resIdx]
      const newPerm = resObj.permissions.filter(
        (p) => p.slug !== permissionSlug
      )
      resObj.permissions = newPerm
      rolesPagePolicies[resIdx] = resObj
      const removeZeroPermissions = rolesPagePolicies.filter((pol) => {
        return pol.permissions.length !== 0
      })

      selectedRole.resourcePolicies = removeZeroPermissions
      dispatchAUTHZContext({
        type: AUTHZActions.SET_SELECTED_ROLE,
        data: selectedRole
      })
    } else {
      selectedRole.resourcePolicies = []
      dispatchAUTHZContext({
        type: AUTHZActions.SET_SELECTED_ROLE,
        data: selectedRole
      })
      dispatchAUTHZContext({
        type: AUTHZActions.PERMISSIONS_SELECTED,
        data: []
      })
      dispatchAUTHZContext({
        type: AUTHZActions.SET_TREE_ROLE_PERMISSIONS,
        data: []
      })
    }
  }

  return (
    <>
      <Box direction="column" margin={{ top: 'small', bottom: 'small' }}>
        <Box>
          <Box
            flex
            direction="row"
            pad="xsmall"
            wrap
            overflow="auto"
            width="large"
          >
            <Accordion multiple>
              {rolesPagePolicies.map((obj, idx) => {
                return (
                  <AccordionPanel
                    header={
                      <Box
                        align="baseline"
                        justify="center"
                        pad="small"
                        direction="row"
                        width="large"
                      >
                        <Box
                          align="baseline"
                          pad="xsmall"
                          justify="start"
                          direction="row"
                          width="large"
                        >
                          {slugNameMap[obj.resource.matcher] && (
                            <Typography
                              testId="selected-permission"
                              type="heading"
                              level={3}
                            >
                              {slugNameMap[obj.resource.matcher]}
                            </Typography>
                          )}
                          {!slugNameMap[obj.resource.matcher] && (
                            <Typography
                              testId={`selected-permission-${idx}`}
                              type="heading"
                              level={3}
                            >
                              {obj.resource.name}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          align="baseline"
                          justify="end"
                          direction="row"
                          width="large"
                        >
                          <Typography
                            size="small"
                            testId="permissionsCount"
                            type="text"
                            margin="small"
                          >
                            {t('roles.wiz_step3_permissions_count', {
                              permissionsCount: obj.permissions.length
                            })}
                          </Typography>
                          <Down size="small" margin="small" />
                        </Box>
                      </Box>
                    }
                    testId={obj.resource.matcher}
                    key={`${obj.resource.matcher}~${obj.permissions[0]?.slug}-panel`}
                  >
                    <Box align="baseline" direction="column" justify="between">
                      {obj.permissions.map((permission) => {
                        return (
                          <Box
                            align="baseline"
                            direction="row"
                            justify="between"
                            width="large"
                            key={`${obj.resource.matcher}-${permission.slug}`}
                          >
                            <Box
                              align="baseline"
                              pad="small"
                              direction="row"
                              justify="start"
                              width="large"
                            >
                              {slugNameMap[permission.slug] && (
                                <Typography
                                  size="small"
                                  testId="selected-permission"
                                  type="text"
                                >
                                  {slugNameMap[permission.slug]}
                                </Typography>
                              )}
                              {!slugNameMap[permission.slug] && (
                                <Typography
                                  size="small"
                                  testId="selected-permission"
                                  type="text"
                                >
                                  {permission.slug}
                                </Typography>
                              )}
                            </Box>
                            <Button
                              testId={permission.slug}
                              icon={<Trash />}
                              onClick={() =>
                                removePermission(
                                  permission.slug,
                                  obj.resource.matcher
                                )
                              }
                            />
                          </Box>
                        )
                      })}
                    </Box>
                  </AccordionPanel>
                )
              })}
            </Accordion>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SelectedPermissions

SelectedPermissions.propTypes = {
  application: PropTypes.string,
  selectedRole: PropTypes.any
}
