// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { initialize, LDProvider } from 'launchdarkly-react-client-sdk'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import { LDFeatureFlags } from './configs/glcp-ld-feature-flags'
import './i18n'
import { ContextualHelp } from './commoncomponents/contextual-help-layout/ContextualHelp'
import * as serviceWorker from './serviceWorker'
import { get, updateCoreData } from './utils/api-utils'
import { libKeys } from './utils/common-utils'
import { isGLOP, isCoP } from './utils/feature-flag-utils'
import './utils/local-storage-utils'
import {
  getMfeCloudBaseUrl,
  getMfeCloudNewApiUrl,
  getMfeCloudOrgBaseUrl,
  isMfeCloud
} from './utils/settings-utils'
import { sessionChannel } from './utils/account-utils'
import injectScript from './bootstrap'
import { createMfeRedirectHandler } from './utils/redirect-utils'

function startServer() {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_PROXY_MIRAGE
  ) {
    /* eslint-disable global-require */
    const mirageServer = require('./mock-server/mirage-server').default
    const { Server, Response } = require('miragejs')
    /* eslint-enable */
    if (window.Cypress) {
      // Refer - https://miragejs.com/quickstarts/cypress/
      const cyServer = new Server({
        environment: 'test',
        routes() {
          // this passthrough is required for letting this call go through cuz of json consumption
          this.passthrough('/regions.json')

          // TODO: CCS-1384 - update this to be populated based on settings.json
          this.urlPrefix = window.$settings.baseUrl

          const methods = ['get', 'put', 'patch', 'post', 'delete']
          methods.forEach((method) => {
            this[method]('/*', async (schema, request) => {
              const [status, headers, body] = await window.handleFromCypress(
                request
              )
              return new Response(status, headers, body)
            })
          })
          this.passthrough('https://app.launchdarkly.com/**')
          this.passthrough('https://bam.nr-data.net/**')
          this.passthrough(
            'https://hewlettpackardnonproduction2mogyl6nm.org.coveo.com/**'
          )
        }
      })
      cyServer.logging = false
    } else if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_PROXY_MIRAGE
    ) {
      mirageServer()
    }
  }
}

async function renderApp() {
  const ldCID = window.$settings?.ldClientID
    ? window.$settings?.ldClientID
    : window.$settings?.ldKey
  await injectScript(ldCID)

  if (!isGLOP() && !isCoP() && process.env.NODE_ENV !== 'development') {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://www.hpe.com/global/assets/privacy_cookie.js'
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  // TODO: Remove the window.Cypress check for bootstrap after integrate the LD SDK for Cypress instead of mockLD fn
  const LDConfig = {
    clientSideID: ldCID,
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    flags: LDFeatureFlags,
    options: {
      ...(window.Cypress
        ? { streaming: false }
        : { bootstrap: 'localStorage' }),
      ...(process.env.REACT_APP_VERCEL
        ? { streaming: false, baseUrl: '/launch-darkly' }
        : {})
    }
  }

  const ldInit = async () => {
    const ldClient = initialize(
      LDConfig.clientSideID,
      { key: 'anonymous' },
      LDConfig.options
    )
    await ldClient.waitForInitialization(2)
    createMfeRedirectHandler(ldClient.allFlags())
    return ldClient
  }

  const ldClient = !isGLOP() && !isCoP() ? await ldInit() : null

  const hasLD = () => {
    // Enable LD only for Cloud and when REACT_APP_ENABLE_LD is set as true or Cypress is true
    if (window.$settings?.isCoP || window.$settings?.isGLOP) {
      return false
    }
    if (
      process.env.REACT_APP_ENABLE_LD ||
      (window.Cypress && !window.$settings?.isCoP)
    ) {
      return true
    }
    return false
  }
  const GLCPApp = () => {
    return hasLD() ? (
      <LDProvider client={ldClient} {...LDConfig}>
        <App />
      </LDProvider>
    ) : (
      <App />
    )
  }

  const GLCPContextualHelp = () => {
    return hasLD() ? (
      <LDProvider client={ldClient} {...LDConfig}>
        <Suspense fallback={<div>Loading...</div>}>
          <ContextualHelp proxyOverride={process.env.REACT_APP_HELP_PROXY} />
        </Suspense>
      </LDProvider>
    ) : (
      <ContextualHelp proxyOverride={process.env.REACT_APP_HELP_PROXY} />
    )
  }
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(<GLCPApp />)

  const help = ReactDOM.createRoot(document.getElementById('help'))
  help.render(<GLCPContextualHelp />)

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

async function bootstrap() {
  // This is a shim for legacy code in the new GLP/react package to work with this app as well as all others using the new package.
  window.NODE_ENV = process.env.NODE_ENV
  window.REACT_APP_COVEO_MIRAGE = process.env.REACT_APP_COVEO_MIRAGE
  window.REACT_APP_ENABLE_LD = process.env.REACT_APP_ENABLE_LD

  // This uses BrowserChannel browser native API to listen for a post message and redirect all other tabs open on the same domain to the sign-out page.
  sessionChannel.onmessage = () => {
    window.location.assign('/sign-out')
  }
  try {
    const { data: settings } = await get(
      `/settings.json?${process.env.REACT_APP_BUILD_TS}`,
      {},
      undefined,
      true
    )
    if (window.Cypress && window.isCoP) {
      settings.isCoP = true
    }
    if (window.Cypress && window.isGLOP) {
      settings.isGLOP = true
    }
    // TODO: use json validator instead
    if (settings?.authorityURL && settings.client_id) {
      if (!settings.baseUrl) {
        // settings.baseUrl = window.location.origin
        settings.baseUrl = `${window.location.origin}/api`
      }
      if (isMfeCloud) {
        settings.baseUrl = getMfeCloudBaseUrl
        settings.orgBaseUrl = getMfeCloudOrgBaseUrl
        settings.newAPIHost = getMfeCloudNewApiUrl
      }
      updateCoreData({
        name: 'environment',
        data: settings
      })
      window.$settings = settings
      if (!window.$settings?.ldClientID) {
        const keys = await libKeys()
        const nrConfigs = {
          account_id: keys.nrAcctID,
          trust_key: keys.nrTrustKey,
          agent_id: keys.nrAgentID,
          license_key: keys.nrLicKey,
          application_id: keys.nrAppID
        }
        window.$settings.ldKey = keys.ldKey
        window.$settings.aPID = keys.aPID
        window.$settings.aAPIKey = keys.aAPIKey
        window.$nrConfigs = nrConfigs
      }
      Object.freeze(window.$settings)
      startServer()
      renderApp()
    } else {
      throw new Error(`Bad settings.json: ${JSON.stringify(settings)}`)
    }
  } catch (e) {
    console.error(e)
  }
}

bootstrap()
