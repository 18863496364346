// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { checkGotoChooseAccount } from '../../utils/common-utils'
import DeviceManagementRouter from '../device-management/router'
import { isInventoryOwnedWorkspace } from '../../utils/feature-flag-utils'
import { useVisibilityContext } from '../../context/visibility-context'
import { Loader } from '../../components'

import useIsBillingExportAvailable from './billing-export/useIsBillingExportAvailable'
import CopAuthenticationPage from './authentication/cop-authentication/CopAuthentication'
import ManageAccountPage from './manage-account'
import AccountDetailsRouter from './account-details/router'
import AccountTypesRouter from './account-types/router'
import ConfigureAuthenticationRouter from './authentication/configure-auth/router'
import LocalGatewayRouter from './local-gateway/router'
import AuditLogsRouter from './audit-logs/router'
import SsoRouter from './authentication/saml-sso/router'
import ArubaActivateRouter from './aruba-activate/router'
import APIRouter from './api/router'
import PortalCustomizationRouter from './portal-customization/router'
import IdentityRouter from './identity/router'
import AutomationsRouter from './automations/router'
import SubscriptionsRouter from './subscriptions/router'
import { IPAccessRulesRouter } from './ip-access-rules'
import LocationManagementRouter from './location-management/router'
import { ReportingRouter } from './reporting'
import ReportingNotAuthDashboardPage from './reporting/ReportingNotAuth'
import SupportAssistantRouter from './support-access/router'
import { UsageMonitoringRouter } from './usage-monitoring'
import ZeroTouchOnboarding from './zero-touch-onboarding/ZeroTouchOnboarding'
import TagsRouter from './tags/router'
import { OrganizationGovernanceRouter } from './organization-governance/router'
import OnPremWorkspaceRouter from './onprem-workspace/router'
import BillingExportRouter from './billing-export/router'
import AssetManagementRouter from './asset-management/router'

const ManageAccountRouter = () => {
  const { t } = useTranslation(['common'])
  const { rbacPolicies } = useVisibilityContext()
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  const isBillingExportAvailable = useIsBillingExportAvailable()
  return (
    <>
      {rbacPolicies ? (
        <Routes>
          <Route exact path="/" element={<ManageAccountPage />} />
          <Route path="/account-details/*" element={<AccountDetailsRouter />} />
          <Route
            path="/account-type-overview/*"
            element={<AccountTypesRouter />}
          />
          {/* <Route path="/manage-account/authentication" element={
        <AuthenticationRouter />} />
      */}
          <Route
            path="/configure-auth"
            element={<ConfigureAuthenticationRouter />}
          />
          <Route
            path="/identity/*"
            element={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/authorization',
                  permission: 'ccs.authorization.view'
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <IdentityRouter />
              </VisibilityWrapper>
            }
          />
          <Route
            path="/organization/*"
            element={<OrganizationGovernanceRouter />}
          />
          <Route
            path="/local-gateway/*"
            element={
              <VisibilityWrapper
                hideFor={{
                  feature: 'glcp-local-gateway-feature',
                  deployment: ['COP']
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <LocalGatewayRouter />
              </VisibilityWrapper>
            }
          />
          <Route path="/sso/*" element={<SsoRouter />} />
          <Route
            path="/activate/*"
            element={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/activate/customer',
                  permission: 'ccs.activate.view'
                }}
                fallbackComponent={<Navigate to="/not-found" />}
              >
                <ArubaActivateRouter />
              </VisibilityWrapper>
            }
          />
          <Route path="/tags/*" element={<TagsRouter />} />
          <Route path="/api" element={<APIRouter />} />
          <Route
            path="/auditlogs"
            element={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/audit-trail',
                  permission: 'ccs.audit-trail.view'
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <AuditLogsRouter />
              </VisibilityWrapper>
            }
          />
          {isInventoryOwnedWorkspace() ? (
            <Route
              path="/locations/*"
              element={
                <VisibilityWrapper
                  fallbackComponent={<Navigate to="/home" />}
                  hideFor={{
                    deployment: ['COP', 'GLOP']
                  }}
                  rbac={{
                    resource: '/ccs/location-management',
                    permission: 'ccs.location-management.view'
                  }}
                >
                  <LocationManagementRouter />
                </VisibilityWrapper>
              }
            />
          ) : null}
          <Route
            path="/portal-customization"
            element={
              <VisibilityWrapper
                hideFor={{ account: ['TENANT', 'STANDALONE'] }}
                rbac={{
                  resource: '/ccs/accounts/platform/customer',
                  permission: 'ccs.accounts.platform.customer.view'
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <PortalCustomizationRouter />
              </VisibilityWrapper>
            }
          />
          <Route path="/subscriptions/*" element={<SubscriptionsRouter />} />
          <Route path="/ip-access-rules" element={<IPAccessRulesRouter />} />
          <Route path="/authentication" element={<CopAuthenticationPage />} />
          <Route
            path="/support-access"
            element={
              <VisibilityWrapper
                hideFor={{
                  deployment: ['GLOP'],
                  feature: 'glcp-support-access-manager'
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <SupportAssistantRouter />
              </VisibilityWrapper>
            }
          />
          {/* Reroute to devices page when old device-management route is hit  */}
          <Route
            path="/device-management/*"
            element={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/device-management',
                  permission: 'ccs.device-management.view'
                }}
                fallbackComponent={
                  <Navigate
                    to="/home"
                    state={{ errorMessage: t('permission_denied') }}
                  />
                }
              >
                <DeviceManagementRouter />
              </VisibilityWrapper>
            }
          />
          <Route path="/automations/*" element={<AutomationsRouter />} />
          <Route
            path="/usage-monitoring"
            element={
              <VisibilityWrapper
                fallbackComponent={<Navigate to="/home" />}
                hideFor={{
                  deployment: ['COP']
                }}
                rbac={{
                  resource: '/ccs/consumption-data',
                  permission: 'ccs.consumption-data.view'
                }}
              >
                <UsageMonitoringRouter />
              </VisibilityWrapper>
            }
          />
          <Route
            path="/reporting/*"
            element={
              <VisibilityWrapper
                hideFor={{
                  feature: 'glcp-reportfw-dashboard'
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/reporting/dashboard',
                    permission: 'ccs.reporting.view'
                  }}
                  fallbackComponent={<ReportingNotAuthDashboardPage />}
                >
                  <ReportingRouter />
                </VisibilityWrapper>
              </VisibilityWrapper>
            }
          />
          <Route
            path="/zero-touch-onboarding"
            element={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/device-management/zto',
                  permission: 'ccs.device-subscription-zto.edit'
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <ZeroTouchOnboarding />
              </VisibilityWrapper>
            }
          />
          <Route
            path="/on-prem-workspaces/*"
            element={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/device-management',
                  permission: 'ccs.device-management.view'
                }}
                fallbackComponent={<Navigate to="/home" />}
              >
                <OnPremWorkspaceRouter />
              </VisibilityWrapper>
            }
          />
          <Route
            path="/asset-management/*"
            element={<AssetManagementRouter />}
          />
          {isBillingExportAvailable && (
            <Route
              path="/billing-export"
              element={
                <VisibilityWrapper
                  fallbackComponent={<Navigate to="/home" />}
                  rbac={{
                    resource: '/ccs/billing-export',
                    permission: 'ccs.billing-export.manage'
                  }}
                >
                  <BillingExportRouter />
                </VisibilityWrapper>
              }
            />
          )}
          <Route path="/*" element={<Navigate to="/not-found" />} />
        </Routes>
      ) : (
        <Box
          direction="row"
          align="center"
          justify="center"
          margin={{ top: 'large' }}
        >
          <Loader
            orientation="horizontal"
            label={t('common:loading')}
            testId="rbac-loader"
          />
        </Box>
      )}
    </>
  )
}

export default ManageAccountRouter
