// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { get, post } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'

export const actionMoveToFolder = (
  token,
  t,
  requestBody,
  onSetOpen,
  refreshDataTable,
  setErrorMessage
) => {
  const url = `/support-assistant/v1alpha1/device-folder`
  post(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshDataTable()
      }
    },
    (error) => {
      onSetOpen(false)
      setErrorMessage(displayApiError(error, t, setErrorMessage))
      console.error(error)
    }
  )
}

const flattenData = (obj) => {
  return Object.entries(obj).reduce((flattened, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      const flat = Object.entries(value).reduce((f, [k, v]) => {
        f[`${key}_${k}`] = v
        return f
      }, flattened)
      return { ...flattened, ...flat }
    }
    flattened[key] = value
    return flattened
  }, {})
}

export const getDeviceDetails = (
  token,
  t,
  serial,
  request,
  setDeviceDetails,
  setEditDeviceDetailsResponse,
  setDevicesHistoryTotalItems,
  setOrdersTotalItems,
  setErrorMessage
) => {
  const modeEnum = {
    BRANCH: 'BRANCH',
    CC: 'CLOUD_CONTROLLER',
    MC: 'MASTER_CONTROLLER',
    MD: 'MANAGED_DEVICE',
    'MD-VPNC': 'MANAGED_DEVICE_VPNC'
  }
  const url = `/support-assistant/v1alpha1/device/${serial}`
  get(url, request, token).then(
    (response) => {
      if (response) {
        const {
          devices,
          adi_device_history_data: {
            pagination: { total_count: deviceHistoryTotalCount } = {}
          } = {},
          orders: { pagination: { total_count: ordersTotalCount } = {} } = {}
        } = response.data
        const {
          mode,
          inv_name: deviceName,
          inv_full_name: fullName,
          inv_description: description,
          folder: { folder_id: folderId, folder_name: folderName } = {}
        } = devices[0]
        const { data } = response
        data.devices[0] = flattenData(data.devices[0])
        data.devices[0].contact_name =
          data?.devices[0]?.contact_name || data?.devices[0]?.contact_id
        setDeviceDetails(data)
        setEditDeviceDetailsResponse({
          device_name: deviceName,
          full_name: fullName,
          description,
          folder_name: folderName,
          folder_id: folderId,
          mode: mode && modeEnum[mode] ? modeEnum[mode] : mode
        })
        setDevicesHistoryTotalItems(deviceHistoryTotalCount || 0)
        setOrdersTotalItems(ordersTotalCount || 0)
      }
    },
    (error) => {
      setDeviceDetails([])
      setErrorMessage(displayApiError(error, t, setErrorMessage))
      console.error(error)
    }
  )
}

export const getActivateOrderDetails = async (
  orderNumber,
  token,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/order/${orderNumber}`
  return get(url, {}, token).then(
    (resp) => {
      return resp.data
    },
    (err) => {
      setErrorMessage(displayApiError(err, t, setErrorMessage))
      return []
    }
  )
}

export const getLicenseOrderDetails = async (
  searchStr,
  value_type,
  token,
  setErrorMessage,
  t
) => {
  let url = ''

  if (value_type === 'quote') {
    url = `/support-assistant/v1alpha1/orders-detail/${searchStr}`
  } else {
    url = `/support-assistant/v1alpha1/subscription/${searchStr}`
  }
  return get(url, {}, token).then(
    (resp) => {
      return resp.data
    },
    (err) => {
      setErrorMessage(displayApiError(err, t, setErrorMessage))
      return []
    }
  )
}

export const tacRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.tac-observer',
  'ccs.notification-admin',
  'ccs.activate-tac-admin',
  'ccs.sa.activate-admin',
  'ccs.sa.activate-observer',
  'ccs.sa.user-admin',
  'ccs.sa.user-observer',
  'ccs.sa.application-observer',
  'ccs.billing-accounts-admin',
  'ccs.sa.firmware-admin',
  'ccs.sa.firmware-observer',
  'ccs.sa.firmware-operator',
  'ccs.sa.location-operator',
  'ccs.whats-new-admin',
  'ccs.sa.whatsnew-observer',
  'ccs.sa.whatsnew-operator',
  'ccs.sa.workspace-admin',
  'ccs.sa.workspace-observer',
  'ccs.sa.workspace-operator',
  'ccs.sa.sa-admin'
]

export const notificationRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.notification-admin'
]

export const customerRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.sa.workspace-admin',
  'ccs.sa.workspace-operator',
  'ccs.sa.workspace-observer',
  'ccs.sa.sa-admin'
]

export const userRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.sa.user-admin',
  'ccs.sa.user-observer',
  'ccs.sa.sa-admin'
]

export const whatsNewRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.whats-new-admin',
  'ccs.whats-new-operator',
  'ccs.whats-new-observer',
  'ccs.sa.whatsnew-operator',
  'ccs.sa.whatsnew-observer'
]

export const firmwareRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.sa.firmware-admin',
  'ccs.sa.firmware-operator',
  'ccs.sa.firmware-observer'
]

export const applicationRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.sa.application-observer'
]

export const activateRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.sa.activate-admin',
  'ccs.sa.activate-observer'
]

export const locationRoles = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.activate-tac-admin',
  'ccs.tac-observer',
  'ccs.sa.location-operator'
]

export const activateTACRoles = [
  'ccs.activate-tac-admin',
  'ccs.sa.activate-admin',
  'ccs.sa.activate-observer'
]

export const getCustomerStats = (request, token, onSuccess, onError) => {
  const url = '/support-assistant/v1alpha1/stats'
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getCustomers = (request, token, onSuccess, onError) => {
  const url = `/support-assistant/v1alpha1/customers`
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getCustomerRoles = (request, token, onSuccess, onError) => {
  const url = `/support-assistant/v1alpha1/customer-roles`
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getCustomerRolesV2 = (request, token, onSuccess, onError) => {
  const url = `/support-assistant/v1beta1/customer-roles`
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getCustomerRoleDetailsV2 = (
  request,
  token,
  onSuccess,
  onError,
  slug
) => {
  const url = `/support-assistant/v1beta1/customer-roles/${slug}`
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getCustomerRoleAssignedUsersV2 = (
  request,
  token,
  onSuccess,
  onError,
  slug,
  applicationId,
  platformCid
) => {
  const url = `/support-assistant/v1alpha1/customers/${platformCid}/applications/${applicationId}/roles/${slug}/user-assignments`
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getCustomerRolesForHpeGreenlakeSupport = (
  request,
  token,
  platformCid,
  applicationId,
  onSuccess,
  onError
) => {
  const url = `/authorization/ui/v1/customers/${platformCid}/applications/${applicationId}/roles?tag_rules=tac`
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getDevices = (request, token, onSuccess, onError) => {
  const url = `/support-assistant/v1alpha1/activate-devices`
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const getLocations = (request, token, onSuccess, onError) => {
  const url = '/support-assistant/v1alpha1/locations'
  get(url, request, token).then(
    (response) => {
      onSuccess(response)
    },
    (error) => {
      onError(error)
    }
  )
}

export const samRbac = {
  resource: '/ccs/support-customer-ws-access',
  permission: '*'
}

export const getSamPermissionForWorkspaceAccess = (resourcePermissions) => {
  let permissionForWorkspaceAccess = ''
  if (resourcePermissions && Object.keys(resourcePermissions).length) {
    Object.entries(resourcePermissions).some(([permission, value]) => {
      permissionForWorkspaceAccess = permission
      return value ? permissionForWorkspaceAccess : false
    })
  }
  return permissionForWorkspaceAccess
}

export const samRoles = [
  'ccs.sam.customer-workspace-acess-admin',
  'ccs.sam.customer-workspace-acess-observer',
  'aruba.sam.customer-workspace-acess-admin',
  'aruba.sam.customer-workspace-acess-observer',
  'dscc.sam.customer-workspace-acess-admin',
  'dscc.sam.customer-workspace-acess-observer',
  'compute.sam.customer-workspace-acess-admin',
  'compute.sam.customer-workspace-acess-observer',
  'ccs.sam.customer-workspace-access-operator-glp',
  'ccs.sam.customer-workspace-access-operator-aruba',
  'ccs.sam.customer-workspace-access-operator-dscc',
  'ccs.sam.customer-workspace-access-operator-compute'
]

export const rolesToFilter = [
  'ccs.sam.customer-workspace-acess-admin',
  'ccs.sam.customer-workspace-acess-observer',
  'aruba.sam.customer-workspace-acess-admin',
  'aruba.sam.customer-workspace-acess-observer',
  'dscc.sam.customer-workspace-acess-admin',
  'dscc.sam.customer-workspace-acess-observer',
  'compute.sam.customer-workspace-acess-admin',
  'compute.sam.customer-workspace-acess-observer',
  'ccs.sam.customer-workspace-access-operator-glp',
  'ccs.sam.customer-workspace-access-operator-aruba',
  'ccs.sam.customer-workspace-access-operator-dscc',
  'ccs.sam.customer-workspace-access-operator-compute',
  'ccs.sa.firmware-admin',
  'ccs.sa.firmware-operator',
  'ccs.sa.firmware-observer',
  'ccs.sa.whatsnew-operator',
  'ccs.sa.whatsnew-observer',
  'ccs.whats-new-admin'
]

export const isInviteSEAccessAvailableForWorkspace = (samPermissionsObj) => {
  const permissionsForSupportEngineerInvite = [
    'ccs.ccs-customer-ws-access.invite',
    'ccs.aruba-customer-ws-access.invite',
    'ccs.dscc-customer-ws-access.invite',
    'ccs.compute-customer-ws-access.invite'
  ]
  let isAccessAvailableToInviteSupportEngineers = false

  Object.entries(samPermissionsObj).some(([permission, value]) => {
    const condition =
      permissionsForSupportEngineerInvite.includes(permission) && value
    if (condition) {
      isAccessAvailableToInviteSupportEngineers = true
    }
    return condition ? value : false
  })
  return isAccessAvailableToInviteSupportEngineers
}

export const getPermission = (role) => {
  if (
    role.toLowerCase().includes('admin') ||
    role.toLowerCase().includes('operator')
  ) {
    return 'write'
  }
  if (role.toLowerCase().includes('observer')) {
    return 'read'
  }
  return false
}
