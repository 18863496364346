// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { React, useEffect, useState } from 'react'
import { Box, FormField } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Loader, MultiSelectBox, Typography } from '../../../../components'
import { get } from '../../../../utils/api-utils'
import { SCOPE_GROUPS_LIMIT } from '../constants'

const ScopeGroupMultiSelect = ({
  setSelectedScopeGroups,
  scopeError,
  setApiError
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const [loading, setLoading] = useState(true)
  const [scopeGroupOptions, setScopeGroupOptions] = useState([])
  const [totalScopeGroups, setTotalScopeGroups] = useState(0)
  const [offset, setOffset] = useState(0)
  const limit = 30

  const getScopeGroups = () => {
    get(
      `/authorization/v2alpha1/scope-groups?limit=${limit}&offset=${offset}`
    ).then(
      (response) => {
        setTotalScopeGroups(response?.data?.total)
        const scopeGroups = []
        response?.data?.items?.forEach((scopeGroup) => {
          // filtering scopegroup with no scopes
          if (scopeGroup?.scopes?.length) {
            scopeGroups.push({
              label: scopeGroup?.name,
              desc: scopeGroup?.description,
              value: scopeGroup
            })
          }
        })
        setScopeGroupOptions([...scopeGroupOptions, ...scopeGroups])
        setLoading(false)
      },
      (error) => {
        setApiError(error)
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    getScopeGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  const scopeGroupTemplate = (option) => {
    const scopeGroup = option?.value
    return (
      <Box width="small">
        <Typography
          type="text"
          size="medium"
          emphasis
          testId="scope-group-label"
        >
          {option?.label}
        </Typography>
        {scopeGroup?.description && (
          <Typography type="text" size="xsmall" testId="scope-group-desc">
            {scopeGroup?.description}
          </Typography>
        )}
      </Box>
    )
  }

  return loading ? (
    <Box align="center" margin={{ top: 'small', bottom: 'small' }}>
      <Loader testId="scope-group-dropdown-loader" />
    </Box>
  ) : (
    <FormField
      required
      label={t('common:business_object.scope_group_plural_capitalized')}
      help={t('iam:msp.select_scope_groups_desc', {
        roleAssignment: t('common:business_object.role_assignment')
      })}
      error={scopeError?.scopeGroupInstanceError}
      data-testid="scope-group-dropdown"
    >
      <MultiSelectBox
        options={scopeGroupOptions}
        labelKey="label"
        valueKey="value"
        placeholder={t('common:select')}
        limit={SCOPE_GROUPS_LIMIT}
        total={scopeGroupOptions?.length}
        onChange={(selectedScopeGroups) => {
          scopeError?.clearError()
          const scopeGroups = selectedScopeGroups?.map(
            (scopeGroup) => scopeGroup?.value
          )
          setSelectedScopeGroups(scopeGroups)
        }}
        searchPlaceholder={t('common:search')}
        dropHeight="medium"
        disabled={false}
        testId="scopegrp-multi-select"
        onMore={() => {
          if (offset < totalScopeGroups) {
            setOffset(offset + limit)
          }
        }}
      >
        {scopeGroupTemplate}
      </MultiSelectBox>
    </FormField>
  )
}

ScopeGroupMultiSelect.propTypes = {
  setSelectedScopeGroups: PropTypes.func.isRequired,
  scopeError: PropTypes.func.isRequired,
  setApiError: PropTypes.func.isRequired
}

export default ScopeGroupMultiSelect
