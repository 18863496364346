// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Text, Box, Grid, Markdown, Notification } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

import { Typography, Button, Dropdown } from '../../components'
import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'

import {
  userColsDisplayed,
  categoryColumns,
  adminColsDisplayedEdit,
  adminColsDisplayed
} from './constants'
import { getDetails, getDetailsForCategory } from './utils'

const Details = ({
  notificationDetails,
  saveReadStatus,
  readStatus,
  isUserView,
  isApprovalView,
  approvalOptions,
  approveAction,
  setApproveAction,
  setOpenApproveConfirmation,
  setOpenRejectConfirmation,
  isMyRequestView,
  myRequestOptions,
  myRequestAction,
  setMyRequestAction,
  setOpenDeleteConfirmation,
  handleEditNotificationRequestClick,
  publishedActionOptions,
  scheduleActionOptions,
  scheduleAction,
  setScheduleAction,
  setOpenScheduledDeleteConfirmation,
  publishedAction,
  setPublishedAction,
  setOpenPublishedDeleteConfirmation,
  handleEditPublishedNotificationClick,
  isPublished,
  isSchedule
}) => {
  const { t } = useTranslation(['manage'])
  const LDFlags = useFlags()
  const adminColumns = LDFlags['glcp-notifications-feature-edit-istanbul']
    ? adminColsDisplayedEdit
    : adminColsDisplayed

  const userColumns = userColsDisplayed
  const showEditHeader =
    notificationDetails?.updated_version === 0 ||
    notificationDetails?.updated_version === undefined
      ? undefined
      : true

  return (
    <Box
      pad={{ horizontal: 'xlarge', top: 'medium', right: 'xsmall' }}
      width="large"
      justify="between"
      align="start"
      alignSelf="center"
    >
      {isUserView ? (
        <Box direction="row" justify="between" width="large">
          <Typography
            level="3"
            type="heading"
            testId="notification-details-page-title"
          >
            {t('notification.message')}
          </Typography>
          <Button
            secondary
            color="#00E8CF"
            onClick={() => {
              saveReadStatus(!readStatus)
            }}
            label={
              readStatus
                ? t('notification.mark_unread')
                : t('notification.mark_read')
            }
            testId="mark-as-unread"
          />
        </Box>
      ) : (
        <Box direction="row" justify="between" width="large">
          <Typography
            level="3"
            type="heading"
            testId="notification-details-page-title"
          >
            {t('message')}
          </Typography>
          {isApprovalView &&
            notificationDetails?.approval_state !== 'REJECTED' && (
              <Dropdown
                defaultVal="Actions"
                name="actions"
                testId="approval-actions-dropdown"
                // disabled={disabledAction}
                placeholder={t('notification.actions')}
                options={approvalOptions}
                value={approveAction}
                onChangeDropdown={(value) => {
                  setApproveAction(value)
                  if (value === t('notification.approval.approve')) {
                    setOpenApproveConfirmation(true)
                  } else {
                    setOpenRejectConfirmation(true)
                  }
                }}
                size="small"
              />
            )}
          {LDFlags['glcp-notifications-feature-edit-istanbul'] &&
            isPublished &&
            !isSchedule &&
            !(
              notificationDetails?.internal_state >= 2 &&
              notificationDetails?.internal_state <= 8 &&
              notificationDetails?.internal_state !== 5 &&
              notificationDetails?.internal_state !== 0
            ) && (
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.notification.edit',
                  resource: '/ccs/notification'
                }}
              >
                <Dropdown
                  defaultVal="Actions"
                  name="update_actions"
                  testId="update-actions-dropdown"
                  // disabled={disabledAction}
                  placeholder={t('notification.actions')}
                  options={publishedActionOptions}
                  value={publishedAction}
                  onChangeDropdown={(value) => {
                    setPublishedAction(value)
                    if (value === t('delete_label')) {
                      setOpenPublishedDeleteConfirmation(true)
                    } else {
                      handleEditPublishedNotificationClick()
                    }
                  }}
                  size="small"
                />
              </VisibilityWrapper>
            )}
          {isMyRequestView && !isPublished && (
            <Dropdown
              defaultVal="Actions"
              name="actions"
              testId="myrequest-actions-dropdown"
              // disabled={disabledAction}
              placeholder={t('notification.actions')}
              options={myRequestOptions}
              value={myRequestAction}
              onChangeDropdown={(value) => {
                setMyRequestAction(value)
                if (value === t('delete_label')) {
                  setOpenDeleteConfirmation(true)
                } else {
                  // setOpenRejectConfirmation(true)
                  handleEditNotificationRequestClick()
                }
              }}
              size="small"
            />
          )}
          {LDFlags['glcp-notifications-feature-schedule-jhansi'] &&
            isSchedule && (
              <Dropdown
                defaultVal="Actions"
                name="actions"
                testId="schedule-actions-dropdown"
                // disabled={disabledAction}
                placeholder={t('notification.actions')}
                options={scheduleActionOptions}
                value={scheduleAction}
                onChangeDropdown={(value) => {
                  setScheduleAction(value)
                  setOpenScheduledDeleteConfirmation(true)
                }}
                size="small"
              />
            )}
        </Box>
      )}
      <Box pad={{ top: 'small' }} data-testid="notification-summary">
        <Text>{notificationDetails?.summary}</Text>
      </Box>
      <Box
        direction="column"
        pad={{ bottom: 'small', top: 'medium' }}
        margin={{
          bottom:
            notificationDetails?.description &&
            !isEmpty(notificationDetails?.description)
              ? 'medium'
              : 'xsmall'
        }}
      >
        {LDFlags['glcp-notifications-feature-edit-istanbul'] &&
          showEditHeader && (
            <Box
              border={{ side: 'top', color: 'border-weak' }}
              pad={{ bottom: 'small', top: 'small' }}
            >
              <Notification
                global
                status="info"
                message={t('notification.edited_noti_info', {
                  date: dayjs(notificationDetails?.createdAt * 1000).format(
                    'MM/DD/YYYY hh:mm:ss A'
                  )
                })}
                data-testid="banner-notification-test-id"
              />
            </Box>
          )}
        <Box
          border={
            !notificationDetails?.updated_version && {
              side: 'top',
              color: 'border-weak'
            }
          }
          pad={{ bottom: 'small', top: 'small' }}
        >
          <Typography
            level="2"
            size="medium"
            type="heading"
            testId="page-title"
          >
            {t('notification.details')}
          </Typography>
        </Box>
        <Grid
          gap={{ row: 'small', column: 'small' }}
          fill="horizontal"
          columns={['small', 'auto']}
          pad={{ top: 'small' }}
        >
          {isUserView
            ? getDetails(
                t,
                notificationDetails,
                userColumns,
                LDFlags['glcp-notifications-feature-glasgow-admin'],
                LDFlags['glcp-notifications-account-role-target'],
                isUserView,
                LDFlags['glcp-notifications-feature-edit-istanbul']
              )
            : getDetails(
                t,
                notificationDetails,
                adminColumns,
                LDFlags['glcp-notifications-feature-glasgow-admin'],
                LDFlags['glcp-notifications-account-role-target'],
                isUserView,
                LDFlags['glcp-notifications-feature-edit-istanbul']
              )}
        </Grid>
        {LDFlags['glcp-notifications-feature-category-istanbul'] &&
          notificationDetails?.category?.service && (
            <>
              <Box
                border={{ side: 'top', color: 'border-weak' }}
                pad={{ bottom: 'xxsmall', top: 'medium' }}
                margin={{ bottom: 'medium', top: 'medium' }}
              >
                <Typography
                  level="3"
                  size="medium"
                  type="heading"
                  testId="page-title"
                >
                  {t('notification.category')}
                </Typography>
              </Box>
              <Grid
                gap={{ row: 'small', column: 'small' }}
                fill="horizontal"
                columns={['small', 'auto']}
              >
                {getDetailsForCategory(
                  t,
                  notificationDetails?.category,
                  categoryColumns,
                  LDFlags['glcp-notifications-feature-dynamic-app-istanbul']
                )}
              </Grid>
            </>
          )}
      </Box>
      {notificationDetails?.description &&
        !isEmpty(notificationDetails?.description) && (
          <Box
            direction="column"
            pad={{ bottom: 'medium', top: 'medium' }}
            border={{ side: 'top', color: 'border-weak' }}
            fill="horizontal"
          >
            <Typography level="3" type="heading" testId="page-title">
              {t('notification.additional_content')}
            </Typography>
            <Box pad={{ top: 'small' }}>
              <Markdown>{notificationDetails?.description}</Markdown>
            </Box>
          </Box>
        )}
    </Box>
  )
}

Details.prototypes = {
  notificationDetails: PropTypes.object
}

export default Details
