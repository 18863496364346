// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box, Layer, ResponsiveContext } from 'grommet'
import { useRef, useContext, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'

import ChatFeedback from './ChatFeedback'
import { useSessionContext } from './context/session-context'
import { useChatContext } from './context/chat-context'
import Resizable from './Resizable'
import ChatHeader from './ChatHeader'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'
import ChatBotLogo from './ChatBotLogo'

const ChatBotContainer = () => {
  const chatContainerRef = useRef()
  const { client } = useSessionContext()
  const {
    newConversation,
    open,
    chat,
    dispatchChatContext,
    feedbackFormData,
    showSidePanel
  } = useChatContext()
  const [isChatEmpty, setIsChatEmpty] = useState(true)
  const [containerHeight, setContainerHeight] = useState('650px')
  const size = useContext(ResponsiveContext)
  useEffect(() => {
    setIsChatEmpty(!chat || chat.length === 0)
  }, [chat])
  useEffect(() => {
    const handleResize = () => {
      if (!chatContainerRef.current) return

      const windowHeight = window.innerHeight
      const newHeight = Math.min(Math.max(windowHeight - 100, 200), 650)
      setContainerHeight(`${newHeight}px`)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  })

  const isSmallSize = ['small', 'xsmall'].includes(size)
  const sizesAboveMedium = ['medium', 'large', 'xmedium', 'xlarge']
  const isAboveMedium = sizesAboveMedium.includes(size)
  let rightOffset = '2.75rem'

  if (isSmallSize) {
    rightOffset = '0'
  } else if (isAboveMedium && showSidePanel) {
    rightOffset = '480px'
  }
  const getContainerStyles = () => {
    let width
    let height
    let bottom

    if (isSmallSize) {
      width = '100%'
      height = '100%'
      bottom = '0'
    } else {
      width = '384px'
      height = containerHeight
      bottom = '5.4rem'
    }

    return {
      borderRadius: '0.5rem 0.5rem 0 0',
      overflow: 'hidden',
      width,
      height,
      bottom,
      right: `${rightOffset}`,
      position: 'inherit'
    }
  }

  return open ? (
    <ThemeContext.Extend value={{ layer: { zIndex: '111' } }}>
      <Layer
        modal={false}
        background="background-back"
        position="bottom-right"
        margin="small"
        animation={open ? '' : 'fadeOut'}
        ref={chatContainerRef}
        data-testid="chat-bot-container"
        style={getContainerStyles()}
      >
        <Resizable chatContainerRef={chatContainerRef} />
        <ChatHeader
          chatContainerRef={chatContainerRef}
          closeChatBot={dispatchChatContext}
        />
        {feedbackFormData?.showFeedbackForm ? (
          <ChatFeedback />
        ) : (
          <>
            <Box fill direction="column-reverse" flex>
              <ChatBody
                chat={chat}
                client={client}
                newConversation={newConversation}
              />
            </Box>
            <ChatFooter
              client={client}
              closeChatBot={dispatchChatContext}
              isChatEmpty={isChatEmpty}
            />
          </>
        )}
      </Layer>

      <ChatBotLogo
        openChatBot={dispatchChatContext}
        close="CLOSE"
        data-testid="close-button"
      />
    </ThemeContext.Extend>
  ) : (
    <ChatBotLogo openChatBot={dispatchChatContext} data-testid="open-button" />
  )
}

export default ChatBotContainer
