// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box, Markdown, InfiniteScroll } from 'grommet'
import PropTypes from 'prop-types'

import { getprimaryField } from '../utils'
import { Typography, Loader, Button } from '../../../../components'

export const LaunchWorkspaceModel = ({ t }) => {
  return (
    <Box
      elevation="large"
      width="250px"
      height="small"
      pad={{ horizontal: 'small' }}
      round="small"
      style={{ position: 'fixed', zIndex: 110, top: '20%', left: '30%' }}
      alignSelf="center"
      background="background"
      direction="column"
      margin={{ horizontal: 'small' }}
      data-testid="gs-switch-workspace-model"
    >
      <Box direction="column" width="100%" height="100%">
        <Box
          direction="row"
          pad={{ horizontal: 'small', vertical: 'small' }}
          data-testid="loader-modal"
          width="100%"
        >
          <Box
            direction="column"
            data-testid="header"
            pad={{ vertical: 'small' }}
          >
            <Typography
              type="heading"
              level="2"
              weight="bold"
              style={{
                whiteSpace: 'nowrap'
              }}
              testId="header-title"
            >
              {t('common:launching')}
            </Typography>

            <Typography
              type="paragraph"
              size="medium"
              testId="loader-header-subtitle"
            >
              {t('common:loader_dialog_message')}
            </Typography>
          </Box>
        </Box>
        <Box align="center" justify="center" width="100%">
          <Loader />
        </Box>
      </Box>
    </Box>
    // </Box>
  )
}

LaunchWorkspaceModel.propTypes = {
  t: PropTypes.any.isRequired
}

const WorkSpaceComponent = ({
  content,
  handleActionClick,
  renderHint,
  onMoreHandler,
  loadingPagination,
  handleSwitchWorkspace,
  i18nTranslation
}) => {
  const primaryField = getprimaryField(renderHint?.renderingHints)

  return (
    <Box direction="column" gap="small">
      <InfiniteScroll
        onMore={() => onMoreHandler(content?.pagination, content?.category)}
        items={content?.results}
      >
        {(item, index) => (
          <Box
            align="start"
            alignContent="start"
            data-testid={`gs-workspace-${index}`}
            pad={{ horizontal: 'xsmall', vertical: 'xsmall' }}
            hoverIndicator
            focusIndicator={false}
            direction="column"
            onClick={() => {}}
          >
            <Box width="100%" direction="row" justify="between">
              <Box direction="column" justify="between">
                <Typography
                  testId={`gs-workspace-title-${index}`}
                  emphasis
                  type="text"
                >
                  {item[primaryField]}
                </Typography>

                {item?.highlight && (
                  <Typography
                    size="small"
                    type="text"
                    testId={`gs-workspace-desc-${index}`}
                  >
                    <Markdown type="text" testId={`gs-workspace-desc-${index}`}>
                      {item?.highlight[Object.keys(item?.highlight)[0]]?.[0]}
                    </Markdown>
                  </Typography>
                )}
              </Box>
              <Box align="center" gap="small">
                <Button
                  secondary
                  label={i18nTranslation('authn:customer_account.launch')}
                  testId={`gs-launch-btn-${index}`}
                  onClick={() => {
                    handleActionClick(item[primaryField], content?.category)
                    handleSwitchWorkspace(item?.account_info)
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </InfiniteScroll>
      {loadingPagination && (
        <Box align="center">
          <Loader testId="gs-refresh-onMore" />
        </Box>
      )}
    </Box>
  )
}

WorkSpaceComponent.propTypes = {
  content: PropTypes.any.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  renderHint: PropTypes.any.isRequired,
  onMoreHandler: PropTypes.func.isRequired,
  loadingPagination: PropTypes.bool.isRequired,
  handleSwitchWorkspace: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired
}

export { WorkSpaceComponent }
