// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useContext } from 'react'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'

import { Typography } from '../../../components'
import { del, get } from '../../../utils/api-utils'
import { cleanClientStorage } from '../../../utils/client-storage-cleaning-utils'
import useLogger from '../../../hooks/logs/useLogger'
import { sessionChannel } from '../../../utils/account-utils'
import { V2_LOGGER } from '../../../utils/log-util'
import { isV2 } from '../../../utils/feature-flag-utils'

/* 
  This route will be loaded in hidden iFrame(by Ping) during logout 
*/
const EndSession = () => {
  const logger = useLogger()
  const authzV2Enabled = isV2()
  const { userManager } = useContext(AuthenticationContext)
  useEffect(() => {
    /* @TODO : This will change to POST soon - CCS-2311 */
    const callEndSession = async () => {
      // end v1 session
      try {
        await userManager.revokeAccessToken()
        await userManager.removeUser()
        cleanClientStorage()
        // This sends a message to all other open tabs on the same domain to sign out
        sessionChannel.postMessage('sign_out')
        await get(`/authn/v1/session/end-session`, {})
      } catch (err) {
        logger?.log({
          ...V2_LOGGER,
          section: 'end',
          msg: `couldn't end user session`,
          data: err
        })
        // @TODO : Need to discuss with backend team on how to handle error
        console.error(err)
      }

      // end v2 session
      if (authzV2Enabled) {
        logger?.log({
          ...V2_LOGGER,
          section: 'end',
          msg: `ending v2 session`
        })
        try {
          await del(`/internal-sessions/v1alpha1/my-ui-session`)
        } catch (err) {
          logger?.log({
            msg: `error in ending v2 session`,
            type: 'error',
            data: err
          })
          // @TODO how to handle error
          console.log(err)
        }
      }
      sessionStorage.removeItem('orgLaunchInfo')
      sessionStorage.removeItem('NewPostModel')
    }
    callEndSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authzV2Enabled])

  return (
    <Typography type="text" size="xsmall" testId="iframe-load">
      This route will be loaded to hidden iFrame. Not visible to user.
    </Typography>
  )
}

export default EndSession
