// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Anchor, Box, PageHeader } from 'grommet'
import { FormDown, Install, Previous, StatusCritical } from 'grommet-icons'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'

import {
  ActionButton,
  Button,
  Loader,
  Notification,
  Typography,
  ButtonGroup,
  Pagination,
  NoDataInfo
} from '../../components'
import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'
import {
  updateFolderDetails,
  // TODO: Uncomment  below line when Reorder is in place
  // updateRulesReorder
  getRuleList
} from '../../utils/manage-account-utils'
import { useCCSManagerContext } from '../../context/ccs-manager-context'
import { getPaginationShowIdx } from '../../utils/common-utils'

import { AddRuleModal } from './add-rule/AddRuleModal'
import {
  ViewDetails,
  EditDetails,
  EditRules,
  DiscardFolderChangesModal,
  DeleteModal,
  DevicesDataTable
} from './components'

const FolderDetailsPage = ({ inCCSManager = undefined }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation(['device', 'common'])
  const { selectedCustomer: customerData } = useCCSManagerContext()
  const { oidcUser } = useReactOidc()
  const folderDetailParam = JSON.parse(
    sessionStorage.getItem('folderDetailParam')
  )

  // state to store the API response of folder detail, rules
  const [folderDetailsResponse, setFolderDetailsResponse] = useState(null)
  const [rulesListResponse, setRulesListResponse] = useState(null)

  // state to track the changes made in folder detail, rules
  const [folderDetails, setFolderDetails] = useState(null)
  const [rulesList, setRulesList] = useState(null)

  const [rulesLoading, setRulesLoading] = useState(true)

  const [currentMode, setCurrentMode] = useState('view')
  const [discardModal, setDiscardModal] = useState(false)

  const [rulesErrorMsg, setRulesErrorMsg] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [deleteFolderModal, setDeleteFolderModal] = useState(false)
  const [addRuleModal, setAddRuleModal] = useState(false)

  const [editFolderErrorMsg, setEditFolderErrorMsg] = useState(undefined)
  const [editRuleOrderErrorMsg, setEditRuleOrderErrorMsg] = useState(undefined)

  const [goBackonDiscard, setGoBackOnDiscard] = useState(false)

  const [devicesErrorMessage, setDevicesErrorMessage] = useState('')
  // for pagination
  const itemsPerPage = 5
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const isDataEdited = () => {
    return !(
      isEqual(folderDetailsResponse, folderDetails) &&
      isEqual(rulesListResponse, rulesList)
    )
  }

  const handleBackBtnClick = () => {
    if (currentMode === 'view') {
      sessionStorage.removeItem('folderDetailParam')
      if (
        location?.pathname ===
        '/manage-ccs/customers/customer-details/folders/folder-details'
      )
        navigate('/manage-ccs/customers/customer-details', {
          state: { tabName: 'folders' }
        })
      else navigate('/manage-account/activate/folders-list')
    } else if (!isDataEdited()) {
      sessionStorage.removeItem('folderDetailParam')
      if (
        location?.pathname ===
        '/manage-ccs/customers/customer-details/folders/folder-details'
      )
        navigate('/manage-ccs/customers/customer-details', {
          state: { tabName: 'folders' }
        })
      else navigate('/manage-account/activate/folders-list')
    } else {
      setGoBackOnDiscard(true)
      setDiscardModal(true)
    }
  }

  useEffect(() => {
    setFolderDetailsResponse(folderDetailParam)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRulesList = useCallback(() => {
    const param = {
      ...(inCCSManager && { platform_customer_id: customerData?.id }),
      folder_ids: folderDetailParam?.folder_id,
      limit: itemsPerPage,
      page: page - 1
    }
    setRulesLoading(true)
    getRuleList({
      inCCSManager,
      token: oidcUser.access_token,
      t,
      param,
      setErrorMessage: setRulesErrorMsg
    }).then((data) => {
      setRulesListResponse(data?.rules)
      setTotalItems(data?.pagination?.total_count)
      setRulesLoading(false)
    })
  }, [oidcUser.access_token, t, page]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getRulesList()
  }, [getRulesList])

  useEffect(() => {
    setFolderDetails(folderDetailsResponse)
  }, [folderDetailsResponse])

  useEffect(() => {
    setRulesList(rulesListResponse)
  }, [rulesListResponse])

  useEffect(() => {
    // to switch to view mode if edit APIs are successful
    if (editFolderErrorMsg === '' && editRuleOrderErrorMsg === '')
      setCurrentMode('view')
  }, [editFolderErrorMsg, editRuleOrderErrorMsg])

  // To fetch only the user edited field
  function difference() {
    function changes() {
      /* eslint-disable func-names */
      return transform(folderDetails, function (result, value, key) {
        if (!isEqual(value, folderDetailsResponse[key])) {
          result[key] =
            isObject(value) && isObject(folderDetailsResponse[key])
              ? changes()
              : value
        }
      })
      /* eslint-enable */
    }
    return changes()
  }

  const handleSaveChanges = () => {
    const folderDetailChanged = !isEqual(folderDetailsResponse, folderDetails)
    // TODO: Uncomment  below line when Reorder is in place
    // const rulesChanged = !isEqual(rulesListResponse, rulesList)
    if (folderDetailChanged && folderDetails.folder_name === '') {
      setEditFolderErrorMsg(t('folder_name_required_msg'))
    } else if (folderDetailChanged) {
      const requestBody = difference()
      if (requestBody.parent_folder_name) delete requestBody.parent_folder_name
      updateFolderDetails({
        folderID: folderDetailParam.folder_id,
        isCCSManager: inCCSManager,
        customerId: customerData?.id,
        request: requestBody,
        token: oidcUser.access_token,
        t,
        setErrorMessage: setEditFolderErrorMsg,
        onSuccess: () => {
          setEditFolderErrorMsg('')
          setFolderDetailsResponse(folderDetails)
          sessionStorage.setItem(
            'folderDetailParam',
            JSON.stringify(folderDetails)
          )
        }
      })
    } else setEditFolderErrorMsg('')

    // if (rulesChanged) {
    //   updateRulesReorder({
    //     folderId,
    //     rulesData: rulesList,
    //     token: oidcUser.access_token,
    //     t,
    //     setErrorMessage: setEditRuleOrderErrorMsg,
    //     onSuccess: () => {
    //       setEditRuleOrderErrorMsg('')
    //       getRulesList()
    //     }
    //   })
    // } else setEditRuleOrderErrorMsg('')
    // TODO: Uncomment above code and remove below line when Reorder is in place
    setEditRuleOrderErrorMsg('')
  }

  const renderFolderDetails = () => {
    if (currentMode === 'edit')
      return (
        <EditDetails
          folderDetails={folderDetails}
          setFolderDetails={setFolderDetails}
          inCCSManager={inCCSManager}
          customerId={customerData?.id}
        />
      )
    const viewDetailsData = {}
    viewDetailsData[t('name')] = folderDetails?.folder_name || null
    viewDetailsData[t('devices_tab')] = folderDetails?.num_of_devices || null
    viewDetailsData[t('parent_folder_name')] =
      folderDetails?.parent_folder_name || null
    viewDetailsData[t('description')] = folderDetails?.description || null
    viewDetailsData[t('id')] = folderDetails?.folder_id || null
    viewDetailsData[t('uuid')] = folderDetails?.uuid || null
    return <ViewDetails data={viewDetailsData} inCCSManager={inCCSManager} />
  }

  const renderRulesList = () => {
    if (rulesLoading) return <Loader testId="loader" />
    if (rulesErrorMsg !== '')
      return (
        <Notification
          backgroundColor="status-critical"
          testId="critical-inline-notification"
          text={rulesErrorMsg}
          type="inline"
        />
      )
    return rulesList?.length ? (
      <>
        <EditRules
          rulesList={rulesList}
          setRulesList={setRulesList}
          folderData={{
            folder_name: folderDetailsResponse?.folder_name || null,
            folder_id: folderDetailsResponse?.folder_id || null
          }}
          refreshRule={getRulesList}
          inCCSManager={inCCSManager}
          customerId={customerData?.id}
        />
        <Box
          border={{ side: 'bottom', color: 'border-weak' }}
          margin={{ bottom: 'xsmall' }}
        />
        <Pagination
          alignSelf="end"
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          page={page}
          setPage={({ page: nextPage }) => {
            setPage(nextPage)
          }}
          pageIdxInfo={pageIdxInfo}
          testId="edit-folder-detail-pagination"
        />
      </>
    ) : (
      <NoDataInfo
        subtitle={t('no_rules_info')}
        icon={<Install size="large" />}
        testId="no-data-info-without-rules"
      />
    )
  }

  const detailsBody = () => {
    return (
      <>
        <Box direction="column" pad={{ top: 'small', bottom: 'large' }}>
          <Box
            margin={{ bottom: 'small' }}
            border={{ side: 'bottom', color: 'border-weak' }}
          >
            <Box
              direction="row"
              justify="between"
              fill
              margin={{ bottom: 'medium' }}
              wrap
              style={{ rowGap: '12px' }}
            >
              <Typography level="2" type="heading" testId="details-header">
                {t('details')}
              </Typography>

              {currentMode === 'view' ? (
                <VisibilityWrapper
                  rbac={{
                    resource: inCCSManager
                      ? '/ccs/activate/tac'
                      : '/ccs/activate/customer',
                    permission: 'ccs.activate.edit'
                  }}
                >
                  <Button
                    secondary
                    label={t('edit')}
                    onClick={() => {
                      setEditFolderErrorMsg(undefined)
                      setEditRuleOrderErrorMsg(undefined)
                      setCurrentMode('edit')
                    }}
                    testId="edit-folder-btn"
                  />
                </VisibilityWrapper>
              ) : (
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('discard_changes'),
                      secondary: true,
                      onClick: () => {
                        if (isDataEdited()) setDiscardModal(true)
                        else setCurrentMode('view')
                      },
                      testId: 'discard-changes-btn'
                    },
                    {
                      id: 1,
                      label: t('save_changes'),
                      primary: true,
                      testId: 'save-changes-btn',
                      onClick: handleSaveChanges
                    }
                  ]}
                  testId="edit-folder-details-buttons"
                />
              )}
            </Box>
          </Box>
          {renderFolderDetails()}
          {editFolderErrorMsg && editFolderErrorMsg.length > 0 && (
            <Notification
              backgroundColor="status-critical"
              testId="critical-inline-notification"
              text={editFolderErrorMsg}
              type="inline"
            />
          )}
        </Box>

        <Box direction="column">
          <Box direction="row" justify="between">
            <Typography level="2" type="heading" testId="rules-heading">
              {t('rules')}
            </Typography>
            <VisibilityWrapper
              rbac={{
                resource: inCCSManager
                  ? '/ccs/activate/tac'
                  : '/ccs/activate/customer',
                permission: 'ccs.activate.edit'
              }}
            >
              <Button
                secondary
                label={t('add_new_rule')}
                onClick={() => setAddRuleModal(true)}
                testId="add-new-rule-btn"
              />
            </VisibilityWrapper>
          </Box>

          <Box
            direction="row"
            border={{ side: 'bottom', color: 'border-weak' }}
            margin={{ bottom: 'medium' }}
          >
            <Typography
              size="large"
              type="text"
              testId="rules-description"
              margin={{ top: 'xsmall', bottom: 'medium' }}
            >
              {t('rules_description')}
            </Typography>
          </Box>
          {renderRulesList()}
          <Box margin={{ top: 'large' }}>
            <Typography level="2" type="heading" testId="devices-heading">
              {t('devices_tab')}
            </Typography>
            <Box
              direction="row"
              border={{ side: 'bottom', color: 'border-weak' }}
              margin={{ bottom: 'medium', top: 'small' }}
            />
            <DevicesDataTable
              folderId={folderDetailsResponse?.folder_id}
              inCCSManager={inCCSManager}
              setDevicesErrorMessage={setDevicesErrorMessage}
            />
          </Box>
          {devicesErrorMessage && (
            <Notification
              backgroundColor="status-critical"
              icon={<StatusCritical color="text-strong" />}
              onClose={() => setDevicesErrorMessage('')}
              testId="devices-notification"
              text={devicesErrorMessage}
            />
          )}
          {editRuleOrderErrorMsg && editRuleOrderErrorMsg.length > 0 && (
            <Notification
              backgroundColor="status-critical"
              testId="critical-inline-notification"
              text={editRuleOrderErrorMsg}
              type="inline"
            />
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      <Box
        pad={{ horizontal: 'large' }}
        width="large"
        alignSelf="center"
        data-testid="folder-details-page"
      >
        <Box direction="column" pad={{ bottom: 'medium' }}>
          <PageHeader
            title={
              <Typography level="1" type="heading" truncate testId="page-title">
                {folderDetailsResponse?.folder_name || '--'}
              </Typography>
            }
            parent={
              <Anchor
                size="xsmall"
                label={t('folders')}
                icon={<Previous size="small" />}
                onClick={handleBackBtnClick}
                data-testid="folders-back-btn"
              />
            }
            actions={
              <VisibilityWrapper
                rbac={{
                  resource: inCCSManager
                    ? '/ccs/activate/tac'
                    : '/ccs/activate/customer',
                  permission: 'ccs.activate.edit'
                }}
              >
                <Box direction="row" gap="medium" align="center">
                  <ActionButton
                    actions={[
                      {
                        label: t('delete_folder'),
                        onClick: () => setDeleteFolderModal(true)
                      }
                    ]}
                    dropAlign={{
                      left: 'left',
                      top: 'bottom'
                    }}
                    label={t('common:actions')}
                    icon={<FormDown />}
                    reverse
                    testId="multipleactions-action-btn"
                    showOneActionAsDropDown
                  />
                </Box>
              </VisibilityWrapper>
            }
            responsive
          />
        </Box>
        <Box
          direction="column"
          justify="start"
          alignSelf="center"
          width="large"
        >
          {detailsBody()}
        </Box>
      </Box>
      {deleteFolderModal && (
        <DeleteModal
          onSetOpen={setDeleteFolderModal}
          data={{
            folder_name: folderDetailsResponse?.folder_name || null,
            folder_id: folderDetailsResponse?.folder_id || null
          }}
          setErrorMessage={setErrorMessage}
        />
      )}
      {addRuleModal && (
        <AddRuleModal
          onSetOpen={setAddRuleModal}
          refreshRule={getRulesList}
          inCCSManager={inCCSManager}
          customerId={customerData?.id}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical color="text-strong" />}
          onClose={() => setErrorMessage(null)}
          position="top"
          testId="api-notification"
          text={errorMessage}
        />
      )}

      {discardModal && (
        <DiscardFolderChangesModal
          closeModal={() => setDiscardModal(false)}
          onContinue={() => {
            // on discard, setting the state back to the API response
            setFolderDetails(folderDetailsResponse)
            setRulesList(rulesListResponse)

            // resetting the edit error messages
            setEditFolderErrorMsg(undefined)
            setEditRuleOrderErrorMsg(undefined)

            setCurrentMode('view')
            setDiscardModal(false)
          }}
          goBack={goBackonDiscard}
        />
      )}
    </>
  )
}

FolderDetailsPage.propTypes = {
  inCCSManager: PropTypes.bool
}

export default FolderDetailsPage
