// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box, Grid, Video } from 'grommet'
import { Previous } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import debounce from 'lodash/debounce'

import { Typography, Button, FilterButton, Loader } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import ServiceSearch from '../../../dashboard/components/ServiceSearch'

import {
  VideoTutorialsData,
  filterAttributes,
  categoryArray
} from './VideoTutorialsData'

const GetStartedContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const { oidcUser } = useReactOidc()

  const [groupedVideos, setGroupedVideos] = useState({})
  const [filteredVideos, setFilteredVideos] = useState({})
  const [selectedFilters, setSelectedFilters] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [errorNotification, setErrorNotification] = useState(null)
  const [loading, setLoading] = useState(false)

  const URL = '/get-started/v1/blogs'
  const videoTutorialsData = VideoTutorialsData(t)

  const groupVideosByCategory = (videos) => {
    return videos.reduce((acc, video) => {
      const category = video?.serviceCategory
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(video)
      return acc
    }, {})
  }

  // debounce function to update state of the search query
  const debouncedSearchData = debounce((searchStr) => {
    setSearchQuery(searchStr?.toLowerCase().trim())
  }, 500)

  useEffect(() => {
    setLoading(true)
    get(URL, {}, oidcUser?.access_token)
      .then((response) => {
        setGroupedVideos(groupVideosByCategory(response?.data?.blogs))
        setLoading(false)
      })
      .catch((error) => {
        setErrorNotification(displayApiError(error, t, setErrorNotification))
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token])

  useEffect(() => {
    const { categories = [] } = selectedFilters
    let filteredByCategory

    if (categories.length === 0) {
      filteredByCategory = groupedVideos
    } else {
      const selectedCategories = categories.map((filter) => filter.value)
      filteredByCategory = Object.entries(groupedVideos)
        .filter(([category]) => selectedCategories.includes(category))
        .reduce((acc, [category, videos]) => {
          acc[category] = videos
          return acc
        }, {})
    }

    if (searchQuery) {
      const lowerSearchQuery = searchQuery.toLowerCase()
      const filteredBySearch = Object.entries(filteredByCategory).reduce(
        (acc, [category, videos]) => {
          const filteredVideosBySearch = videos?.filter(
            (video) =>
              video?.title?.toLowerCase()?.includes(lowerSearchQuery) ||
              category?.toLowerCase()?.includes(lowerSearchQuery)
          )
          if (filteredVideosBySearch?.length) {
            acc[category] = filteredVideosBySearch
          }
          return acc
        },
        {}
      )
      setFilteredVideos(filteredBySearch)
    } else {
      setFilteredVideos(filteredByCategory)
    }
  }, [searchQuery, selectedFilters, groupedVideos]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackBtnClick = () => {
    navigate('/home/')
  }
  return (
    <>
      {errorNotification}
      {loading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="videos-tutorials-loader" />
        </Box>
      ) : (
        <>
          <Box direction="row" justify="start" pad="small">
            <Button
              default
              label={t('nav_bar.home')}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              testId="home-btn"
            />
          </Box>
          <Box
            data-testid="get-started-page"
            gap="medium"
            margin={{ horizontal: 'xlarge', vertical: 'medium' }}
          >
            <Box direction="column" gap="xsmall">
              <Typography level="1" type="heading" testId="tutorial-page-title">
                {t('get_started_videos_tutorials.videos_tutorial_page.title')}
              </Typography>
              <Typography
                size="large"
                type="text"
                testId="tutorial-page-sub-title"
              >
                {t(
                  'get_started_videos_tutorials.videos_tutorial_page.sub_title'
                )}
              </Typography>
            </Box>
            <Box
              direction="row"
              gap="medium"
              margin={{ top: 'small', bottom: 'small' }}
            >
              <ServiceSearch
                debouncedSearchData={debouncedSearchData}
                placeholder={t('search')}
                testId="video-tutorial-search-box"
              />
              <FilterButton
                title="Filters"
                dialogHeight="100%"
                dialogPosition="right"
                initialSelectedFilters={selectedFilters}
                filterAttributes={filterAttributes(t)}
                onFilterValuesChange={(values) => {
                  setSelectedFilters(values)
                }}
                testId="video-tutorial-filter"
              />
            </Box>
            <Box
              direction="column"
              gap="large"
              justify="start"
              data-testid="getting-started-videos"
            >
              {categoryArray
                .filter(
                  (category) =>
                    filteredVideos[category] &&
                    filteredVideos[category].some((video) => video?.active)
                )
                .map(
                  (category) =>
                    filteredVideos[category] && (
                      <Box key={category} gap="medium">
                        <Typography
                          type="text"
                          data-testid={`${category}-title`}
                          size="xlarge"
                          emphasis
                        >
                          {videoTutorialsData[category] || category}
                        </Typography>
                        <Grid
                          gap="large"
                          columns={{ count: 'fill', size: 'medium' }}
                          align="start"
                        >
                          {filteredVideos[category].map((video) => (
                            <Box
                              key={`video-container-${video?.getStartedID}`}
                              direction="column"
                              gap="small"
                            >
                              <Box>
                                <Video
                                  controls="over"
                                  fit="contain"
                                  autoPlay={false}
                                  alignSelf="start"
                                  data-testId={`video-${video?.getStartedID}`}
                                >
                                  <source
                                    data-testId={`video-source-${video?.getStartedID}`}
                                    src={video?.src}
                                    type="video/mp4"
                                  />
                                </Video>
                              </Box>
                              <Box>
                                <Typography
                                  type="text"
                                  size="medium"
                                  emphasis
                                  testId={`deploy-cloud-title-${video?.getStartedID}`}
                                >
                                  {t(video?.title)}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    )
                )}
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
const VideosTutorials = () => {
  return (
    <Layout>
      <GetStartedContent align="start" justify="start" />
    </Layout>
  )
}

export default VideosTutorials
