// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Page, PageContent, PageHeader } from 'grommet'
import { useState } from 'react'
import { Document } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { Card, Typography } from '../../components'

import DocumentsList from './document-curation/DocumentsList'

const SearchAdministrator = () => {
  const { t } = useTranslation(['manage', 'common'])
  const [currentTab, setCurrentTab] = useState('document-curation')
  const tabsList = [
    {
      id: 1,
      logo: <Document />,
      title: t('global_search_administration.document_curation_tile'),
      key: 'document-curation',
      testId: 'document-curation-tab'
    }
  ]

  return (
    <Page>
      <PageContent>
        <PageHeader
          title={
            <Typography level="1" type="heading" testId="identity-title">
              {t('common:global_search_curation_portal.title')}
            </Typography>
          }
          subtitle={
            <Typography type="text" testId="sa-description">
              {t('global_search_administration.page_subtitle')}
            </Typography>
          }
        />
        <Box fill>
          <Box direction="row" wrap margin={{ bottom: 'small' }}>
            {tabsList.map((data) => (
              <Card
                margin={{ bottom: 'small', right: 'medium' }}
                cardWidth={{ min: 'small', max: 'medium' }}
                key={data.id}
                align="center"
                description={
                  <Typography
                    size="large"
                    testId="tile-title"
                    type="text"
                    weight="bold"
                    wordBreak="break-all"
                  >
                    {data.title}
                  </Typography>
                }
                direction="row-reverse"
                icon={
                  <Box align="end" background="#EFEFEF" pad="small" round>
                    {data.logo}
                  </Box>
                }
                justify="between"
                onClick={() => {
                  setCurrentTab(data.key)
                }}
                testId={data.testId}
                {...(data.key === currentTab && {
                  border: { color: 'focus', size: 'small' }
                })}
              />
            ))}
          </Box>

          {currentTab === 'document-curation' && <DocumentsList />}
        </Box>
      </PageContent>
    </Page>
  )
}

export default SearchAdministrator
