// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import axios from 'axios'

import {
  ButtonGroup,
  CCSForm,
  FileInput,
  Loader,
  ModalDialog,
  Typography
} from '../../../../../components'
import { getErrorMessage, post, get } from '../../../../../utils/api-utils'

const AddFirmwareModal = ({
  setAddFirmwareModal,
  refreshDataTable,
  setAddFirmwareNotification,
  setProgressModal
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [file, setFile] = useState({})
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  const [loaderStatus, setLoaderStatus] = useState(false)

  const handleSubmit = async () => {
    if (file?.name) {
      try {
        const urlResponse = await get(
          `/firmware-registry/ui/v1/firmware/get_signed_url`,
          {},
          oidcUser.access_token
        )
        const url = urlResponse?.data?.url
        const formData = new FormData()
        formData.append('key', `uploads/${file.name}`)
        formData.append('policy', urlResponse?.data?.fields.policy)
        formData.append(
          'x-amz-algorithm',
          urlResponse?.data?.fields['x-amz-algorithm']
        )
        formData.append(
          'x-amz-credential',
          urlResponse?.data?.fields['x-amz-credential']
        )
        formData.append('x-amz-date', urlResponse?.data?.fields['x-amz-date'])
        formData.append(
          'x-amz-signature',
          urlResponse?.data?.fields['x-amz-signature']
        )
        formData.append('file', file)
        if (urlResponse?.status === 200) setLoaderStatus(true)

        const awsResponse = await axios.post(url, formData, {
          'Content-Type': 'multipart/form-data'
        })
        if (awsResponse.status === 204) {
          setLoaderStatus(false)
          post(
            '/firmware-registry/ui/v1/firmware',
            { file_name: file.name },
            oidcUser.access_token
          ).then(
            () => {
              setAddFirmwareModal(false)
              setAddFirmwareNotification(true)
              setProgressModal(true)
              refreshDataTable()
            },
            (apiError) => {
              const backendErrorMessage = apiError?.response?.data?.error
              setErrorMessage(backendErrorMessage)
            }
          )
        }
      } catch (error) {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      } finally {
        setLoaderStatus(false)
      }
    } else {
      setErrorMessage(t('required'))
    }
  }
  const handleReset = () => {
    setAddFirmwareModal(false)
  }

  return (
    <ModalDialog
      width="medium"
      testId="add-firmware-modal"
      content={
        <Box gap="small">
          <Typography type="heading" testId="add-firmware-title">
            {t('firmware.add_firmware')}
          </Typography>
          <CCSForm
            errorMessage={errorMessage}
            validate="blur"
            onSubmit={handleSubmit}
            onReset={handleReset}
            testId="add-firmware-form"
          >
            <>
              <FileInput
                testId="firmware-file-input"
                onChange={(event) => {
                  setFile({})
                  if (event?.target?.files && event?.target?.files?.length) {
                    if (event.target.files[0].size === 0) {
                      setErrorMessage(t('firmware.empty_file'))
                    } else setFile(event.target.files[0])
                  } else {
                    setErrorMessage('')
                    setFile({})
                  }
                }}
              />
              {loaderStatus && (
                <Box
                  height="xxsmall"
                  align="start"
                  justify="center"
                  alignSelf="center"
                  pad={{ top: 'small' }}
                >
                  <Loader
                    label={t('firmware.adding_firmware')}
                    testId="loader-spinner"
                    orientation="horizontal"
                  />
                </Box>
              )}
              <Box margin={{ vertical: 'small' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('firmware.cancel'),
                      default: true,
                      testId: 'cancel-btn',
                      type: 'reset'
                    },

                    {
                      id: 1,
                      label: t('firmware.add'),
                      primary: true,
                      testId: 'add-btn',
                      type: 'submit'
                    }
                  ]}
                  testId="two-buttons"
                />
              </Box>
            </>
          </CCSForm>
        </Box>
      }
      onClose={handleReset}
    />
  )
}

AddFirmwareModal.propTypes = {
  setAddFirmwareModal: PropTypes.func.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  setAddFirmwareNotification: PropTypes.func.isRequired,
  setProgressModal: PropTypes.func.isRequired
}

export { AddFirmwareModal }
