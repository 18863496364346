// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
  Outlet
} from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc, AuthenticationContext } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import lodashGet from 'lodash/get'
import { useFlags } from 'launchdarkly-react-client-sdk'

import useLD from '../hooks/ld/useLD'
import { axiosRetryPost, get, getErrorMessage } from '../utils/api-utils'
import { displayApiError } from '../utils/error-handling-utils'
import { updateUPSData, isPathnameExcluded } from '../utils/ups-utils'
import { COPRedirectionPage, Launchpad } from '../pages/acct-onboarding'
import { ManageAccountRouter } from '../pages/manage-account'
import { SystemRouter } from '../pages/system'
import CCSManagerModule from '../pages/ccs-manager/CCSManagerModule'
import { CCSActions, useCCSContext } from '../context/ccs-context'
import {
  useVisibilityContext,
  VisibilityActions
} from '../context/visibility-context'
import SwitchAccountPage from '../pages/switch-account/switch-account'
import ProfilePage from '../pages/profile/Profile'
import CustomerAccountNewRouter from '../pages/customer-account-new/router'
import CustomerAccountRouter from '../pages/customer-account/router'
// import IGCServiceRouter from '../pages/igc-service/router'
import { useUPSContext } from '../context/ups-context'
import {
  updateTACFlags,
  isCoP,
  isOkta,
  getCustomerAccount,
  getNumCustomerAccounts,
  setCustomerAccount,
  isGLOP,
  isV2,
  //  getOrganizationId,
  hasLP
} from '../utils/feature-flag-utils'
import { CheckResourcePermissions } from '../utils/ccs-manager-utils'
import {
  getQueryStringFromKeys,
  getLocaleMap,
  setDayJSLang,
  useIdleTimeout
} from '../utils/common-utils'
import { ResetPassword } from '../pages/profile/reset-password'
import { NotificationRouter } from '../pages/notifications'
import NotificationLogsRouter from '../pages/notifications/NotificationLogs/router'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import TutorialApplicationRouter from '../pages/tutorial/router'
import LdFlagsApplicationRouter from '../pages/featureflags/router'
import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'
import useLogger from '../hooks/logs/useLogger'
import { WhatsNewRouter } from '../pages/whats-new'
import { SearchAdministrationRouter } from '../pages/search-curation-portal'
import WellnessDashboardRouter from '../pages/unifiedWellness/router'
import ReportsRouter from '../pages/reports/router'
import Error404Page from '../pages/error/Error404Page'
import { ServiceCatalogRouter } from '../dashboard'
import PublishApplicationRouter from '../pages/applications/router'
import {
  getSubscriptionSquid,
  setupV2OnSessionCookieReceived,
  isServiceCentric,
  handleErrorRedirect
} from '../utils/account-utils'
import { SupportPageRouter } from '../pages/support'
import MagicLinkRouter from '../pages/magic-link/router'
import PostOnboardingRouter from '../pages/acct-onboarding/post-router'
// import SearchPageRouter from '../pages/support-search/router'
import { updateRBACPolicies } from '../utils/rbac-api-utils'
// import { SupportFeedbackPageRouter } from '../pages/support-feedback'
// import CasesPageRouter from '../pages/support-cases/router'
import DeviceManagementRouter from '../pages/device-management/router'
import ProfilePreferencesOnlyPage from '../pages/preferences-only/ProfilePreferencesOnly'
import { cleanAccountSessionStorage } from '../utils/client-storage-cleaning-utils'

import experienceMap from './utils'

const PostAuthAppRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname, search: locationQueries, hash: locationHash } = location
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const { login, oidcUser } = useReactOidc()
  const { dispatchCCSContext, csrfToken } = useCCSContext()
  const numOfCustomerAccounts = getNumCustomerAccounts()
  const { dispatchVisibilityContext, hideWhen, rbacPolicies, v2RbacPolicies } =
    useVisibilityContext()
  const { dispatchUPSContext, userName, upsApiCallInProgress } = useUPSContext()
  const { t, i18n } = useTranslation(['common'])
  const [custAccountLoaded, setCustAccountLoaded] = useState({})
  const [apiError, setApiError] = useState(null)
  const queryStr = getQueryStringFromKeys([
    'app',
    'redirect_uri',
    'error-code',
    'status'
  ])
  const [idleTimeout, setIdleTimeout] = useState(null)
  const [customerAccounts, setCustomerAccounts] = useState(null)
  const [custAcctError, setCustAcctError] = useState(null)
  const [refreshLoadAccount, setRefreshLoadAccount] = useState()
  const [isPreferenceFetched, setIsPreferenceFetched] = useState(false)
  const [isCountryFetched, setIsCountryFetched] = useState(false)
  const [skipRbacCall, setSkipRbacCall] = useState(false)
  const [skipV2RbacCall, setSkipV2RbacCall] = useState(false)
  const skipUPSCall = useRef(false)
  const [isUpdateUPSCalled, setIsUpdateUPSCalled] = useState(false)
  const [callListAccountApi, setCallListAccountApi] = useState(false)
  const sessionPlatformCustomerId = JSON.parse(
    sessionStorage.getItem('account')
  )?.platform_customer_id
  const [reset] = useIdleTimeout()
  const LDFlags = useFlags()
  const ld = useLD()
  const logger = useLogger()
  // auto logout from central intermittently redirects to {baseurl}/login?app=xx in that case don't show Notfound page until rbac policies available once rbac policees are available it will route to central again
  const showNotFoundPage =
    location.pathname !== '/' &&
    getCustomerAccount() &&
    (isCoP() ? rbacPolicies !== null : true)
  const pageInfoLog = {
    page: PostAuthAppRouter.name,
    section: 'Initialize',
    type: 'success',
    count: 'Calling:post-auth-app-router'
  }
  logger?.log({
    ...pageInfoLog,
    msg: 'Post Auth App Router loaded successfully!'
  })
  const prefNotificationsStr = '/notifications/email-preferences'

  if (!isEmpty(queryStr) && !sessionStorage.getItem('ccs-cop-ss-cert-api')) {
    sessionStorage.setItem('redirect-query', queryStr)
  }
  useEffect(() => {
    reset()
  }, [idleTimeout]) // eslint-disable-line react-hooks/exhaustive-deps

  // reports
  useEffect(() => {
    const isReportUrl = pathname.includes('/download-report')
    if (isReportUrl) {
      sessionStorage.setItem('report-url', pathname)
      navigate('/reportfw-download')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // reports
  useEffect(() => {
    if (
      window.location.pathname.includes('/launchpad') &&
      !sessionStorage.getItem('redirectToLP')
    ) {
      sessionStorage.setItem('redirectToLP', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hasLP()) {
      if (!sessionStorage.getItem('current-lp-user')) {
        // Original launchpad page should not redirect to /choose-account after launching a site
        navigate('/launchpad')
      } else {
        if (numOfCustomerAccounts === 1) {
          setCustomerAccounts([JSON.parse(sessionStorage.getItem('account'))])
        }
        navigate('/post-onboarding/choose-account')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      (isCoP() || isGLOP()) &&
      !sessionStorage.getItem('ccs-cop-ss-cert-api')
    ) {
      sessionStorage.setItem('ccs-cop-ss-cert-api', true)
      navigate('/accept-certificate')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE: Calling updateRBACPolicies on refresh scenario, but to skip this call
  // skipRbacCall is required because custAccountLoaded is being set on render with
  // the help of sessionStorage data & it is effecting calling the API twice.
  useEffect(() => {
    const sessionAcc = JSON.parse(sessionStorage.getItem('account'))
    const swapMspAcc = JSON.parse(sessionStorage.getItem('swapMspAccount'))
    if (
      isAuthenticated &&
      custAccountLoaded &&
      oidcUser &&
      JSON.stringify(custAccountLoaded) !== '{}' &&
      custAccountLoaded?.platform_customer_id ===
        sessionAcc?.platform_customer_id &&
      (!swapMspAcc ||
        swapMspAcc?.platform_customer_id !==
          sessionAcc?.platform_customer_id) &&
      !skipRbacCall
    ) {
      updateRBACPolicies(
        oidcUser,
        custAccountLoaded,
        dispatchVisibilityContext,
        setApiError
      )
      setSkipRbacCall(false)
    }
  }, [
    isAuthenticated,
    custAccountLoaded,
    dispatchVisibilityContext,
    oidcUser,
    skipRbacCall
  ])
  // UPS update on page refresh on
  // all paths except choose-account and switch-account page
  useEffect(() => {
    if (
      isAuthenticated &&
      custAccountLoaded &&
      oidcUser &&
      JSON.stringify(custAccountLoaded) !== '{}' &&
      custAccountLoaded?.platform_customer_id === sessionPlatformCustomerId &&
      LDFlags['glcp-ups-phase-1'] &&
      !isUpdateUPSCalled &&
      !upsApiCallInProgress &&
      !userName &&
      !skipUPSCall.current &&
      isPathnameExcluded(pathname)
    ) {
      ld.update(custAccountLoaded).then((LDFlagsResolved) => {
        if (LDFlagsResolved) {
          updateUPSData(oidcUser, dispatchUPSContext, LDFlags)
          setIsUpdateUPSCalled(true)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    custAccountLoaded,
    oidcUser,
    pathname,
    sessionPlatformCustomerId,
    skipUPSCall.current,
    LDFlags
  ])

  useEffect(() => {
    if (
      isAuthenticated &&
      custAccountLoaded &&
      oidcUser &&
      JSON.stringify(custAccountLoaded) !== '{}' &&
      custAccountLoaded?.platform_customer_id === sessionPlatformCustomerId &&
      !pathname.includes('/post-onboarding/choose-account') &&
      !pathname.includes('/switch-account') &&
      LDFlags['glcp-support-access-manager'] &&
      rbacPolicies
    ) {
      const isSupportEngineer = CheckResourcePermissions(
        {
          '/ccs/workspace-access-se': ['ccs.workspace-access-se.view']
        },
        rbacPolicies
      )
      dispatchVisibilityContext({
        type: VisibilityActions.SET_IS_SUPPORT_ENGINEER,
        data: isSupportEngineer
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    custAccountLoaded,
    oidcUser,
    sessionPlatformCustomerId,
    LDFlags,
    rbacPolicies
  ])

  if (
    JSON.stringify(custAccountLoaded) === '{}' ||
    custAccountLoaded?.platform_customer_id !==
      JSON.parse(sessionStorage.getItem('account'))?.platform_customer_id
  ) {
    // NOTE: The custAccountLoaded field is used for both, standalone and tenant accounts, when tenant account is launched
    // it is replaced with the tenant account and the standalone account is temporarily stored in swappedMSPAccount.
    const currentAccount = getCustomerAccount()
    setCustAccountLoaded(currentAccount)
    if (isCoP() !== undefined && !isCoP() && !isGLOP()) {
      ld.update(currentAccount)
    }
  }

  const getNextPage = () => {
    if (
      custAccountLoaded.defaulted_country_code ||
      JSON.parse(sessionStorage.getItem('defaultedProfileCountryCode'))
    )
      return '/post-onboarding/choose-account' // return to choose account

    const subscriptionProductSquid = getSubscriptionSquid(LDFlags)
    if (subscriptionProductSquid)
      return `/add-products/${subscriptionProductSquid}` // return subscription magic link

    if (hideWhen?.account === 'TAC') return '/manage-ccs/home' // return tac home page
    return '/home' // return home page
  }

  const { userManager } = useContext(AuthenticationContext)

  useEffect(
    () => {
      const redirectToLogin = async () => {
        // Clean up account session storage and remove user data before login in COP. This acts as a failsafe in case the end-session does not clear session storage.
        // This is required in COP when the user is logged out of Central after a session timeout to prevent getting stuck on the /cop-redirection page after sign-in.
        // Note: Remove this if, in the future, COP enters a sign-in loop scenario.
        if (isCoP()) {
          await userManager.removeUser()
          cleanAccountSessionStorage()
        }
        // Will re-route user to PING ID for authentication
        login()
      }

      if (
        (isCoP() || isGLOP()) &&
        !JSON.parse(sessionStorage.getItem('ccs-cop-login-banner'))
      ) {
        // show login terms and condition modal
        navigate('/accept-certificate/login-banner')
      } else if (oidcUser) {
        // On user authenticated
        setIsAuthenticated(true)
        if (custAccountLoaded !== null && rbacPolicies === null) {
          if (!isCoP())
            updateTACFlags(
              oidcUser,
              dispatchVisibilityContext,
              custAccountLoaded
            )
        } else if (isCoP()) {
          // COP
          if (
            numOfCustomerAccounts !== null &&
            (window.location.pathname === '/' || !isEmpty(queryStr))
          ) {
            if (!isEmpty(queryStr))
              // auto logout from central intermittently redirects to {baseurl}/login?app=xx
              // So after relogin we will have the redirect query and we need to go to central via /cop-redirection
              sessionStorage.setItem('redirect-query', queryStr)
            navigate(`/cop-redirection`)
          }
        } else if (
          window.location.pathname === '/' &&
          custAccountLoaded !== null
        ) {
          // cloud
          // if user has context set and are routing to / then redirect to home.
          // / is not a valid route
          const nextPage = getNextPage()
          navigate(nextPage)
        }
      } else {
        // Will re-route user to PING ID for authentication
        redirectToLogin()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      oidcUser,
      custAccountLoaded,
      rbacPolicies,
      queryStr,
      customerAccounts,
      hideWhen?.account
    ]
  )

  // Handle Refresh to retrieve the CSRF token for PCE Org Workspace. Upong refresh, the csrfToken is lost and needs to be retrieved again. Hence, UI will detect the account type and call the v2 set-scope API to retrieve the CSRF token.
  useEffect(() => {
    const userEmail = oidcUser?.profile?.email
    // process.env.NODE_ENV !== '<replace-me>' --> to prevent set-scope being called in dev environment
    // userEmail --> to prevent set-scope being called when the user is not logged in
    // !queryStr.includes('error-code') --> to prevent set-scope being called during the error flow
    // isV2() --> to prevent set-scope being called for v1 workspace
    // !csrfToken --> to prevent set-scope being called when the csrfToken is already present
    // !v2RbacPolicies --> to makes sure RBAC list is fetched
    if (
      process.env.NODE_ENV !== 'development' &&
      userEmail &&
      !queryStr.includes('error-code') &&
      isV2() &&
      (!csrfToken || !v2RbacPolicies) &&
      !skipV2RbacCall
    ) {
      setupV2OnSessionCookieReceived(
        userEmail,
        custAccountLoaded,
        dispatchCCSContext,
        setApiError,
        dispatchVisibilityContext,
        navigate,
        logger
      )
      setSkipV2RbacCall(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrfToken, oidcUser])

  useEffect(() => {
    if (queryStr?.includes('error-code')) {
      handleErrorRedirect(
        queryStr,
        custAccountLoaded,
        navigate,
        dispatchCCSContext,
        logger
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStr])

  const handlePreferenceFetch = () => {
    if (oidcUser) {
      get(
        '/accounts/ui/v1/user/profile/preferences',
        { login_context: true },
        oidcUser.access_token
      )
        .then(
          (preferencesResponse) => {
            if (
              preferencesResponse?.data?.idle_timeout &&
              preferencesResponse?.data?.idle_timeout > 0
            ) {
              const timeout = preferencesResponse.data.idle_timeout * 1000
              dispatchCCSContext({
                type: CCSActions.SET_IDLE_TIMEOUT,
                data: timeout
              })
              setIdleTimeout(timeout)
            } else {
              // set to default
              dispatchCCSContext({
                type: CCSActions.SET_IDLE_TIMEOUT,
                data: null
              })
              setIdleTimeout(null)
            }

            if (preferencesResponse?.data?.language) {
              const lang = getLocaleMap(preferencesResponse.data.language)
              // This will translate dayJS strings to desired language
              setDayJSLang(preferencesResponse.data.language)
              i18n.changeLanguage(lang)
              // Issue: On login, the language is not getting set in local storage correctly by i18n. So, setting it manually.
              window.localStorage.setItem('i18nextLng', lang)
            }
          },
          (error) => {
            // set to default
            dispatchCCSContext({
              type: CCSActions.SET_IDLE_TIMEOUT,
              data: null
            })
            setIdleTimeout(null)

            // Ignore error if session is not established yet
            if (error && !isEmpty(error.response)) {
              setApiError(error)
            }
          }
        )
        .finally(() => setIsPreferenceFetched(true))
    }
  }

  const handleProfileCountryCodeUpdate = () => {
    get('/accounts/ui/v1/user/profile/country', {}, oidcUser.access_token)
      .then(
        (response) => {
          sessionStorage.setItem(
            'defaultedProfileCountryCode',
            response?.data?.defaulted_country_code || false
          )
        },
        (error) => {
          setApiError(error)
        }
      )
      .finally(() => setIsCountryFetched(true))
  }
  useEffect(() => {
    const isCountryBlocked = new URLSearchParams(location.search).get('blocked')
    const checkCustAccount = isCoP()
      ? (!numOfCustomerAccounts &&
          JSON.parse(sessionStorage.getItem('ccs-cop-ss-cert-api'))) || // added this condition as intermittenly session api gets called twice when redirected to /accept-certificate
        JSON.parse(sessionStorage.getItem('forcePasswordResetForCOP')) // when ever force_password_reset is true from session API we need to make sure session API is getting called again to redirect to password-change page
      : !custAccountLoaded
    let isCurrent = true
    const shouldFetchData =
      oidcUser && checkCustAccount && isCountryFetched && isPreferenceFetched
    // making session api call...
    if (shouldFetchData || (shouldFetchData && isCountryBlocked)) {
      // get per cluster timeout value for session api
      const reqTimeout = LDFlags['glcp-session-api-timeout']
      let args = [
        '/authn/v1/session',
        {
          id_token: oidcUser.id_token
        },
        oidcUser.access_token,
        false,
        { timeout: reqTimeout },
        {
          retries: 1,
          retryCondition: (error) => {
            return (
              (!window.Cypress &&
                error?.code === 'ECONNABORTED' &&
                error?.message?.startsWith('timeout')) ||
              (window.Cypress &&
                error?.response?.data?.message?.startsWith('timeout'))
            )
          }
        }
      ]
      // retires and timeout are not applicable for COP or when turned off for cluster
      if ((isCoP() || !reqTimeout || reqTimeout === 0) && !window.Cypress) {
        args = args.slice(0, 3)
      }
      axiosRetryPost(...args).then(
        (sessionResponse) => {
          if (!isCurrent) return
          setCallListAccountApi(true)
          const accounts = lodashGet(sessionResponse, 'data.accounts')
          const numAccounts = accounts?.length || 0
          sessionStorage?.setItem('numOfCustomerAccounts', numAccounts)
          if (numAccounts === 1 && !sessionStorage?.getItem('orgLaunchInfo')) {
            setCustomerAccount(accounts[0])
            setSkipRbacCall(true)
            skipUPSCall.current = true
          }
          sessionStorage?.setItem(
            'isFederated',
            sessionResponse?.data?.federated
          )
          sessionStorage.setItem('multiple-accounts', accounts?.length > 1)
          setCustomerAccounts(accounts)
          // reports
          const sessionReportUrl = sessionStorage.getItem('report-url')
          const isReportLandingPage = pathname?.includes('/reportfw-download')
          if (
            (sessionReportUrl !== null &&
              sessionReportUrl.includes('download-report')) ||
            isReportLandingPage
          ) {
            if (!isReportLandingPage) {
              navigate('/reportfw-download')
            }
            return
          }
          // NOTE: if the IDP is Okta, load the customer accounts
          if (
            (isOkta() || isGLOP()) &&
            !hasLP() &&
            !isCoP() &&
            !sessionStorage.getItem('orgLaunchInfo')
          ) {
            if (pathname === prefNotificationsStr) {
              sessionStorage.setItem('postLoginRedirectURI', pathname)
            }

            // Ensures that only /support and paths under /support/* will match, excluding patterns like /support-* or /support*.
            if (/^\/support(\/.*)?$/.test(pathname)) {
              let additionalParams = ''
              additionalParams += locationQueries || ''
              additionalParams += locationHash || ''

              sessionStorage.setItem(
                'postLoginRedirectURI',
                pathname + additionalParams
              )
            }
            // if not preference-only page, others should redirect to choose-account
            if (pathname !== '/preferences-only') {
              // is okta inside session call...
              navigate(`/post-onboarding/choose-account`)
            }
          }

          if (isGLOP() && !!sessionResponse?.data?.force_password_reset) {
            navigate('/password-change')
          }
          // if COP, go to /cop-redirection
          if (isCoP()) {
            sessionStorage?.setItem(
              'forcePasswordResetForCOP',
              sessionResponse?.data?.force_password_reset
            )
            if (sessionResponse?.data?.force_password_reset) {
              navigate('/password-change')
            } else {
              navigate('/cop-redirection')
            }
          }
          // route to welcome tour page from Guided Workspace
          if (
            LDFlags['glcp-guided-workspace'] &&
            numAccounts === 0 &&
            !isCoP()
          ) {
            sessionStorage.setItem('glcpGuidedWorkspace', true)
            navigate('/post-onboarding/guided-workspace')
          }
        },
        (error) => {
          logger?.log({
            page: PostAuthAppRouter.name,
            section: 'Session API Call',
            type: 'error',
            count: 'Calling:post-auth-app-router session API',
            msg: error
          })
          if ((isCoP() || isGLOP()) && error?.response?.status === 412) {
            // When user reaches max limit of concurrent session
            // BE will give error response 412
            // Display the error message to user & ask to sign out
            window.location.replace('/error/session')
          } else if (
            isCoP() &&
            error?.response?.status === 403 &&
            error?.response?.data?.reason === 'INACTIVITY_LOCKOUT_TRUE'
          ) {
            // when BE gives activity_lockout_true show this message
            const message = error?.response?.data?.message
            sessionStorage.setItem('session-api-error-message', message)
            window.location.replace('/error/session')
          } else if (
            // error from FE timeout
            !isCoP() &&
            ((!window.Cypress &&
              error?.code === 'ECONNABORTED' &&
              error?.message?.startsWith('timeout')) ||
              (window.Cypress &&
                error?.response?.data?.message?.startsWith('timeout')))
          ) {
            navigate('/error/app-unavailable')
          } else if (!isCoP()) {
            setCustAcctError(getErrorMessage(error, t))
            setCustomerAccounts([])
            sessionStorage?.setItem('numOfCustomerAccounts', 0)
            sessionStorage.removeItem('account')
            navigate(`/post-onboarding/choose-account`)
          }
        }
      )
    }
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oidcUser,
    custAccountLoaded,
    isCountryFetched,
    isPreferenceFetched,
    refreshLoadAccount,
    location.search
  ])

  useEffect(() => {
    handlePreferenceFetch()
    if (oidcUser && !isCoP()) {
      setIsCountryFetched(false)
      // not applicable for COP
      // NOTE: Need this API to determine the activate customers profile country update
      handleProfileCountryCodeUpdate()
    } else {
      setIsCountryFetched(true)
    }
  }, [oidcUser]) // eslint-disable-line react-hooks/exhaustive-deps

  const serviceCentric = isServiceCentric(LDFlags) || isGLOP()
  // Removed ServiceOfferings because we are using ServiceCatalogRouter and PublishApplicationRouter for routing Services and Applications respectively
  const { Home } = experienceMap(serviceCentric)
  // const viewOrganization = getOrganizationId()
  return (
    <>
      {isAuthenticated ? (
        <>
          {apiError && displayApiError(apiError, t, setApiError)}
          <Routes>
            <Route
              exact
              path="/cop-redirection"
              element={<COPRedirectionPage />}
            />
            <Route path="/password-change" element={<ResetPassword />} />
            <Route path="/reportfw-download" element={<ReportsRouter />} />
            <Route exact path="/launchpad" element={<Launchpad />} />
            <Route
              path="/post-onboarding/*"
              element={
                <PostOnboardingRouter
                  customerAccounts={customerAccounts}
                  custAcctError={custAcctError}
                  setRefreshLoadAccount={setRefreshLoadAccount}
                  callListAccountApi={callListAccountApi}
                />
              }
            />
            <Route path="/home/*" element={<Home />} />
            <Route
              path="/system/*"
              element={isGLOP() ? <SystemRouter /> : <Navigate to="/home" />}
            />
            <Route path="/whats-new" element={<WhatsNewRouter />} />
            <Route
              path="/search-curation-portal/*"
              element={<SearchAdministrationRouter />}
            />
            <Route
              path="/services/*"
              element={
                serviceCentric ? (
                  <ServiceCatalogRouter />
                ) : (
                  <PublishApplicationRouter />
                )
              }
            />
            {/**
             * Accounting for service-centric experience changes
             * from "applications" to "services"
             */}
            <Route
              path="/applications/*"
              element={
                serviceCentric ? (
                  <ServiceCatalogRouter />
                ) : (
                  <PublishApplicationRouter />
                )
              }
            />
            <Route
              exact
              path="/tutorial/books"
              element={<TutorialApplicationRouter />}
            />
            <Route
              exact
              path="/ldflags"
              element={<LdFlagsApplicationRouter />}
            />
            <Route path="/switch-account" element={<SwitchAccountPage />} />
            <Route
              path="/customer-account/*"
              element={
                LDFlags['glcp-msp-customer-table'] || isGLOP() ? (
                  <CustomerAccountNewRouter />
                ) : (
                  <CustomerAccountRouter />
                )
              }
            />
            <Route path="/add-products/*" element={<MagicLinkRouter />} />
            <Route
              path="/wellness-dashboard/*"
              element={
                <VisibilityWrapper
                  hideFor={{
                    feature: 'glcp-unified-wellness-dashboard',
                    account: ['TENANT', 'MSP'],
                    deployment: ['COP']
                  }}
                  rbac={{
                    resource: '/ccs/wellness-dashboard',
                    permission: 'ccs.wellness-dashboard.view'
                  }}
                  fallbackComponent={<Navigate to="/home" />}
                >
                  <WellnessDashboardRouter />
                </VisibilityWrapper>
              }
            />
            <Route
              path="/preferences-only"
              element={<ProfilePreferencesOnlyPage />}
            />
            {/* TODO: support/feedback and support/cases need to define routes */}
            <Route exact path="/support/*" element={<SupportPageRouter />} />
            <Route path="/devices/*" element={<DeviceManagementRouter />} />
            <Route path="/notifications/*" element={<NotificationRouter />} />
            <Route path="/manage-account/*" element={<ManageAccountRouter />} />
            <Route
              path="/notification-logs/*"
              element={<NotificationLogsRouter />}
            />
            <Route path="/profile/*" element={<ProfilePage />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/manage-ccs/*" element={<CCSManagerModule />} />
            </Route>
            {showNotFoundPage ? (
              <>
                <Route path="/not-found" element={<Error404Page />} />
                <Route path="/*" element={<Navigate to="/not-found" />} />
              </>
            ) : null}
          </Routes>
        </>
      ) : (
        <LazyLoading />
      )}
    </>
  )
}

const PrivateRoutes = () => {
  const { hideWhen } = useVisibilityContext()
  return hideWhen?.account === 'TAC' ? (
    <Outlet />
  ) : (
    <Navigate to="/manage-ccs/home" />
  )
}

export default PostAuthAppRouter
