// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import lodashGet from 'lodash/get'

import { del, get, getErrorMessage, post, put } from './api-utils'
import { getApiErrorMessage } from './error-handling-utils'

export const getRuleList = ({
  inCCSManager,
  token,
  t,
  param,
  setErrorMessage
} = {}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/rules`
    : `/ui-doorway/ui/v1/activate/rules`

  return get(url, param, token).then(
    (response) => {
      if (setErrorMessage) setErrorMessage('')
      return lodashGet(response, 'data', [])
    },
    (error) => {
      console.error(error)
      if (setErrorMessage) {
        const errorMessage = getApiErrorMessage(error, t)
        setErrorMessage(errorMessage)
      }
      return []
    }
  )
}

export const updateFolderDetails = ({
  folderID,
  isCCSManager,
  customerId,
  request,
  token,
  t,
  setErrorMessage,
  onSuccess
} = {}) => {
  const url = isCCSManager
    ? `/support-assistant/v1alpha1/activate-folder/${folderID}`
    : `/ui-doorway/ui/v1/activate/folders/${folderID}`

  const requestBody = isCCSManager
    ? { ...request, platform_customer_id: customerId }
    : request
  put(url, requestBody, token).then(
    () => {
      onSuccess()
    },
    (error) => {
      const errorMessage = getApiErrorMessage(error, t)
      setErrorMessage(errorMessage)
    }
  )
}

export const actionDeleteFolder = ({
  token,
  t,
  isCCSManager,
  customerId,
  folderID,
  onSetOpen,
  setErrorMessage,
  navigate,
  customerData,
  location
} = {}) => {
  const url = isCCSManager
    ? `/support-assistant/v1alpha1/folder/${folderID}`
    : `/ui-doorway/ui/v1/activate/folders/${folderID}`

  const requestBody = isCCSManager ? { platform_customer_id: customerId } : {}
  del(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        if (
          lodashGet(location, 'pathname') ===
          '/manage-ccs/customers/customer-details/folders/folder-details'
        )
          navigate('/manage-ccs/customers/customer-details', {
            state: { customerData }
          })
        else navigate(-1)
      }
    },
    (error) => {
      onSetOpen(false)
      setErrorMessage(getApiErrorMessage(error, t))
    }
  )
}

export const actionDeleteRule = ({
  token,
  t,
  ruleID,
  onSetOpen,
  refreshRule,
  setErrorMessage,
  isCCSManager,
  customerId
} = {}) => {
  const url = isCCSManager
    ? `/support-assistant/v1alpha1/rule/${ruleID}`
    : `/ui-doorway/ui/v1/activate/rules/${ruleID}`

  const requestBody = isCCSManager ? { platform_customer_id: customerId } : {}
  del(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshRule()
      }
    },
    (error) => {
      onSetOpen(false)
      setErrorMessage(getApiErrorMessage(error, t))
    }
  )
}

export const getFolderList = async ({
  token,
  param,
  t,
  setErrorMessage,
  isCCSManager
}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/user-folders'
    : '/ui-doorway/ui/v1/activate/folders'

  return get(url, param, token).then(
    (response) => {
      return response.data
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}

export const getDeviceList = ({
  token,
  t,
  param,
  setTotalItems,
  setErrorMessage,
  inCCSManager,
  setCountOnFirstLoad,
  countOnFirstLoad,
  reportingActivateExportLdFlag
} = {}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/activate-devices`
    : '/ui-doorway/ui/v1/activate/devices'

  return get(url, param, token).then(
    (response) => {
      if (
        reportingActivateExportLdFlag &&
        countOnFirstLoad !== undefined &&
        !countOnFirstLoad
      ) {
        setCountOnFirstLoad(response?.data?.pagination?.total_count || 0)
      }
      setTotalItems(lodashGet(response, 'data.pagination.total_count', 0))
      return lodashGet(response, 'data.devices', [])
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}

export const getRuleDetails = ({
  param,
  token,
  t,
  setRuleDetails,
  setErrorMessage,
  setLoading,
  inCCSManager,
  customerId
} = {}) => {
  if (setLoading) setLoading(true)
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/folder/${param.folder_id}/rule/${param.rule_name}`
    : `/ui-doorway/ui/v1/activate/folders/${param.folder_id}/rules/${param.rule_name}`

  const request = inCCSManager ? { platform_customer_id: customerId } : {}
  get(url, request, token).then(
    (response) => {
      response.data.rule_type = response.data?.rule_type?.toLowerCase()
      response.data.sub_type = response.data?.sub_type?.toLowerCase()
      setRuleDetails(response.data)
      setErrorMessage('')
      if (setLoading) setLoading(false)
    },
    (error) => {
      const errorMessage = getApiErrorMessage(error, t)
      setErrorMessage(errorMessage)
      if (setLoading) setLoading(false)
    }
  )
}

// THIS COULD BE USED IN FUTURE FOR RULES REORDER
// const updateRulesReorder = ({
//   folderId,
//   rulesData,
//   token,
//   t,
//   setErrorMessage,
//   onSuccess
// } = {}) => {
//   const url = `/ui-doorway/ui/v1/activate/folders/${folderId}/rules`
//   put(url, { rules: rulesData }, token).then(
//     () => {
//       onSuccess()
//     },
//     (error) => {
//       const errorMessage = getApiErrorMessage(error, t)
//       setErrorMessage(errorMessage)
//     }
//   )
// }

export const actionCreateRule = ({
  token,
  t,
  requestBody,
  onSetOpen,
  refreshRule,
  setErrorMessage,
  customerId,
  inCCSManager
}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/rules`
    : '/ui-doorway/ui/v1/activate/rules'

  const updatedRequestBody = inCCSManager
    ? { ...requestBody, platform_customer_id: customerId }
    : requestBody

  post(url, updatedRequestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshRule()
      }
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

export const actionEditRule = ({
  token,
  t,
  ruleID,
  requestBody,
  onSetOpen,
  refreshRule,
  setErrorMessage,
  customerId,
  inCCSManager
}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/activate-rules/${ruleID}`
    : `/ui-doorway/ui/v1/activate/rules/${ruleID}`

  const updatedRequestBody = inCCSManager
    ? { ...requestBody, platform_customer_id: customerId }
    : requestBody
  put(url, updatedRequestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshRule()
      }
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

export const getAllModelsOptions = ({
  token,
  t,
  param,
  setErrorMessage,
  setSelectedRows,
  isCCSManager
} = {}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/device-models'
    : '/ui-doorway/ui/v1/activate/devices/models'

  return get(url, param, token).then(
    (response) => {
      setSelectedRows([])
      return response.data
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}

export const isActivateAdmin = (roleSlugs) => {
  for (let i = 0; i < roleSlugs.length; i += 1) {
    if (
      roleSlugs[i] === 'ccs.account-admin-activate-customer-admin' ||
      roleSlugs[i] === 'ccs.activate-customer-admin'
    )
      return true
  }
  return false
}

export const getAllAccessRulesList = (t, isTenantWorkspaceGroup) => {
  return isTenantWorkspaceGroup
    ? [
        {
          label: t('users.msp_and_all_tenants'),
          value: 0,
          access_rules: {
            msp: true,
            tenants: ['ALL']
          }
        },
        {
          label: t('users.msp_only'),
          value: 1,
          access_rules: {
            msp: true,
            tenants: []
          }
        },
        {
          label: t('users.all_tenants_only'),
          value: 2,
          access_rules: {
            msp: false,
            tenants: ['ALL']
          }
        },
        {
          label: t('users.msp_and_specific_tenants'),
          value: 3,
          access_rules: {
            msp: true,
            tenants: ['specific']
          }
        },
        {
          label: t('users.specific_tenants_only'),
          value: 4,
          access_rules: {
            msp: false,
            tenants: ['specific']
          }
        }
      ]
    : [
        {
          label: t('users.msp_and_tenants'),
          value: 0,
          access_rules: {
            msp: true,
            tenants: ['ALL']
          }
        },
        {
          label: t('users.msp_only'),
          value: 1,
          access_rules: {
            msp: true,
            tenants: []
          }
        },
        {
          label: t('users.tenants_only'),
          value: 2,
          access_rules: {
            msp: false,
            tenants: ['ALL']
          }
        }
      ]
}

export const getAccessRules = (index) => {
  let retAccessRules
  const accessRulesData = [
    {
      msp: true,
      tenants: ['ALL']
    },
    {
      msp: true,
      tenants: []
    },
    {
      msp: false,
      tenants: ['ALL']
    }
  ]
  if (index !== undefined) {
    retAccessRules = accessRulesData[index]
  } else {
    // eslint-disable-next-line
    retAccessRules = accessRulesData[0]
  }
  return retAccessRules
}

export const predefinedRolesPriority = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.tac-observer',
  'ccs.activate-tac-admin',
  'ccs.activate-tac-operator',
  'ccs.account-admin-activate-customer-admin',
  'ccs.activate-customer-admin',
  'ccs.activate-customer-observer',
  'app.admin',
  'ccs.account-admin',
  'ccs.operator',
  'ccs.observer'
]

export const actionCreateFolder = ({
  token,
  t,
  requestBody,
  onSetOpen,
  setErrorMessage,
  refreshDataTable,
  isCCSManager
}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/folder'
    : '/ui-doorway/ui/v1/activate/folders'

  post(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshDataTable()
      }
    },
    (error) => {
      setErrorMessage(getApiErrorMessage(error, t))
    }
  )
}

export const getAllCountries = (
  t,
  isCurrent,
  setListOfCountries,
  setCountries,
  setErrorMessage
) => {
  get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
    (response) => {
      if (!isCurrent) return
      setListOfCountries(response.data.countries)
      setCountries(response.data.countries)
    },
    (error) => {
      const backendErrorMessage = getErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

export const getAllMac = ({ token, param, inCCSManager } = {}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/deviceSummary`
    : `/ui-doorway/ui/v1/activate/devices/mac`

  return get(url, param, token).then(
    (response) => {
      return lodashGet(response, 'data.mac_addresses', [])
    },
    () => {
      return []
    }
  )
}

export const getAllParts = ({ token, param, isCCSManager } = {}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/activate-part'
    : `/ui-doorway/ui/v1/activate/part`

  return get(url, param, token).then(
    (response) => {
      return response.data
    },
    () => {
      return []
    }
  )
}
