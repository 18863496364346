// Copyright 2025 Hewlett Packard Enterprise Development LP
export const SELECT = 'Select'
export const TEXT_AREA = 'TextArea'
export const TEXT_INPUT = 'TextInput'
export const EMAIL_INPUT = 'EmailInput'
export const NOTIFICATION = 'Notification'
export const SUBSCRIPTION_KEY_TEXT = 'subscription_key_text'
export const ERROR_TEXT = 'error'
export const INFO_TEXT = 'info'
export const JSON_TEXT = 'JSON'
export const ASCENDING = 'asc'
export const DESCENDING = 'desc'
export const LINK = 'Link'
export const COMMON_ELEMENTS = 'common-elements'
export const ROOT = 'root'
export const GLCP_FORM = 'GLCP-Form'
export const GREENLAKE_SERVICES = 'GREENLAKE_SERVICES'
export const SAAS = 'SAAS'
