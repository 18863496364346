// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useNavigate } from 'react-router'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../components'

import { handleCreatePolicyClick } from './utils'

const AbandonProgressModal = ({ setShowModal, setShowParentModal }) => {
  const { t } = useTranslation(['authz'])
  const navigate = useNavigate()

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="1" testId="header-title">
              {t('rrp.abandon_progress')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }} gap="medium">
          <Typography type="text" size="large" testId="header-title">
            {t('rrp.abandon_progress_modal_text')}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setShowModal(false)
                    setShowParentModal(true)
                  }
                },
                {
                  id: 1,
                  label: t('rrp.create_policy'),
                  primary: true,
                  testId: 'confirm-abandon-progress',
                  onClick: () => handleCreatePolicyClick(navigate)
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => {
        setShowModal(false)
        setShowParentModal(true)
      }}
      testId="abandon-current-progress-modal"
    />
  )
}

AbandonProgressModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setShowParentModal: PropTypes.func.isRequired
}

export { AbandonProgressModal }
