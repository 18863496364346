// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { Box, Button, Markdown, Menu, TextArea } from 'grommet'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../components'
import { post } from '../../../../utils/api-utils'
import {
  getUpdateRunRequestBody,
  statusMapping,
  getQuestionsObj
} from '../utils'
import { displayNotification } from '../../../../utils/notificiation-utils'

const Questions = ({ t, questions, mode, updateQuestionsData }) => {
  return (
    <Box width="40%">
      <Typography type="heading" level="2">
        {t(
          'global_search_administration.document_curation.question_variations'
        )}
      </Typography>
      {Object.keys(questions).map((query_id) => {
        if (mode === 'VIEW' || mode === 'PREVIEW') {
          return (
            <Box
              border
              margin={{ vertical: 'xsmall' }}
              pad="small"
              round="xsmall"
              key={query_id}
            >
              <Typography type="text">{questions[query_id]}</Typography>
            </Box>
          )
        }
        return (
          <Box margin={{ vertical: 'xsmall' }} data-testid={query_id}>
            <TextArea
              value={questions[query_id]}
              data-testid={`${query_id}-input`}
              onChange={(event) => {
                updateQuestionsData(query_id, event.target.value)
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}

Questions.propTypes = {
  t: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['VIEW', 'EDIT', 'PREVIEW']).isRequired,
  updateQuestionsData: PropTypes.func.isRequired
}

const Summary = ({ t, mode, summaryData, updateSummaryData }) => {
  return (
    <Box width="60%" pad={{ horizontal: 'small' }}>
      <Typography type="heading" level="2">
        {t('global_search_administration.document_curation.summary')}
      </Typography>
      {mode === 'VIEW' || mode === 'PREVIEW' ? (
        <Markdown>{summaryData.summary}</Markdown>
      ) : (
        <Box margin={{ vertical: 'xsmall' }} fill>
          <TextArea
            fill
            data-testid="summary-input"
            value={summaryData.summary}
            onChange={(event) => {
              updateSummaryData(event.target.value)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

Summary.propTypes = {
  t: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['VIEW', 'EDIT', 'PREVIEW']).isRequired,
  summaryData: PropTypes.object.isRequired,
  updateSummaryData: PropTypes.func.isRequired
}

const RunBlock = ({ runDetails, documentDetails }) => {
  const [mode, setMode] = useState('VIEW')
  const { t } = useTranslation(['manage'])
  const [questionsData, setQuestionsData] = useState(
    getQuestionsObj(runDetails)
  )
  const [summaryData, setSummaryData] = useState({
    summary_id: runDetails.summary_id,
    summary: runDetails.summary
  })
  const [disabled, setDisabled] = useState(false)
  const { oidcUser } = useReactOidc()
  const [notification, setNotification] = useState(null)
  const [status, setStatus] = useState(runDetails.status)
  const [updatable, setEditable] = useState(
    Object.hasOwn(runDetails, 'updatable') ? runDetails.updatable : true
  )

  const URL = `/global-search-administration/v1alpha1/curation/review/${runDetails.id}/${documentDetails.doc_id}`
  const handelSave = () => {
    setDisabled(true)
    const requestBody = getUpdateRunRequestBody(
      runDetails,
      documentDetails,
      'In Review',
      questionsData,
      summaryData
    )

    post(URL, requestBody, oidcUser.access_token)
      .then((response) => {
        if (response.status === 202) {
          setNotification(
            displayNotification(
              t(
                'global_search_administration.document_curation.edit_toast_msg'
              ),
              'info',
              () => {
                setNotification(null)
              },
              t(
                'global_search_administration.document_curation.edit_toast_title'
              )
            )
          )
          setMode('VIEW')
          setStatus('IN_REVIEW')
          setDisabled(false)
          setEditable(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setMode('VIEW')
        setDisabled(false)
      })
  }

  const handleEdit = () => {
    setMode('EDIT')
  }

  const handlePreview = () => {
    setMode('PREVIEW')
  }

  const handleApprove = () => {
    const requestBody = getUpdateRunRequestBody(
      runDetails,
      documentDetails,
      'Approved',
      questionsData,
      summaryData
    )

    post(URL, requestBody, oidcUser.access_token)
      .then((response) => {
        if (response.status === 202) {
          setNotification(
            displayNotification(
              t(
                'global_search_administration.document_curation.approve_toast_msg'
              ),
              'info',
              () => {
                setNotification(null)
              },
              t(
                'global_search_administration.document_curation.approve_toast_title'
              )
            )
          )
          setStatus('APPROVED')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Box key={runDetails.id}>
      <Box direction="row" justify="between">
        <Typography type="text" weight="bold">{`Status: ${
          statusMapping(t)[status]
        }`}</Typography>

        <Menu
          data-testid="run-actions"
          border
          label={t('users.actions')}
          dropProps={{
            elevation: 'small'
          }}
          disabled={!updatable}
          items={[
            {
              label: t('edit'),
              'data-testid': 'edit-btn',
              onClick: () => {
                handleEdit()
              }
            },
            ...(status !== 'APPROVED'
              ? [
                  {
                    label: t('notification.approval.approve'),
                    'data-testid': 'approve-btn',
                    onClick: () => {
                      handleApprove()
                    }
                  }
                ]
              : [])
          ]}
        />
      </Box>
      <Box direction="row" style={{ opacity: updatable ? '1' : '0.5' }}>
        <Questions
          t={t}
          mode={mode}
          questions={questionsData}
          updateQuestionsData={(question_id, updated_question) => {
            setQuestionsData({
              ...questionsData,
              [question_id]: updated_question
            })
          }}
        />
        <Summary
          t={t}
          mode={mode}
          summaryData={summaryData}
          updateSummaryData={(updated_summary) => {
            setSummaryData({
              ...summaryData,
              summary: updated_summary
            })
          }}
        />
      </Box>

      {(mode === 'EDIT' || mode === 'PREVIEW') && (
        <Box
          direction="row"
          align="start"
          gap="small"
          margin={{ vertical: 'small' }}
        >
          <Button
            primary
            label={t('save')}
            disabled={disabled}
            data-testid="save-btn"
            onClick={handelSave}
          />
          {mode === 'EDIT' ? (
            <Button
              secondary
              label={t('preview')}
              data-testid="preview-btn"
              onClick={handlePreview}
            />
          ) : (
            <Button
              secondary
              label={t('edit')}
              data-testid="edit-btn"
              onClick={handleEdit}
            />
          )}
          <Button
            label={t('cancel')}
            onClick={() => {
              setQuestionsData(getQuestionsObj(runDetails))
              setSummaryData({
                summary_id: runDetails.summary_id,
                summary: runDetails.summary
              })
              setMode('VIEW')
            }}
          />
        </Box>
      )}

      {notification}
    </Box>
  )
}

RunBlock.propTypes = {
  runDetails: PropTypes.object.isRequired,
  documentDetails: PropTypes.shape({
    doc_id: PropTypes.string,
    doc_version: PropTypes.string,
    doc_link: PropTypes.string
  }).isRequired
}

export default RunBlock
