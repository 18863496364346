// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

import { Button } from 'grommet'
import PropTypes from 'prop-types'

import { isMSP } from '../../../../utils/feature-flag-utils'

const ProvisionButton = ({
  isServiceManager,
  isServiceProvision,
  serviceSlug,
  setShowProvisionModal,
  isTenantOnlySupported,
  t
}) => {
  const renderTenantOnlySupportedLabel =
    isMSP() && isTenantOnlySupported
      ? t('dashboard:service_details.enable')
      : t('dashboard:service_details.provision')

  return (
    <Button
      primary
      data-testid={
        isServiceManager || isServiceProvision
          ? `${serviceSlug}-service-detail-provision-button`
          : `${serviceSlug}-service-detail-setup-button`
      }
      label={renderTenantOnlySupportedLabel}
      onClick={() => setShowProvisionModal(true)}
    />
  )
}

ProvisionButton.propTypes = {
  isServiceManager: PropTypes.bool.isRequired,
  isServiceProvision: PropTypes.bool.isRequired,
  serviceSlug: PropTypes.string.isRequired,
  setShowProvisionModal: PropTypes.func.isRequired,
  isTenantOnlySupported: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

export default ProvisionButton
