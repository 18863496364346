// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useReducer } from 'react'

import actions from './UsageMonitoringActions'

export const initialContext = {
  selectedService: '',
  selectedDateRange: '',
  selectedSubscriptionKey: '',
  loadedSubscriptions: {},
  currentBillingPeriod: null,
  loadedWorkspaces: null,
  selectedWorkspaces: null,
  reportRequest: null
}

export const UsageMonitoringReducer = (state, action) => {
  switch (action.type) {
    case actions.SET_SERVICE: {
      return { ...state, selectedService: action.data }
    }

    case actions.SET_DATE_RANGE: {
      return { ...state, selectedDateRange: action.data }
    }
    case actions.SET_SUBSCRIPTION_KEY: {
      return { ...state, selectedSubscriptionKey: action.data }
    }
    case actions.SET_SUBSCRIPTIONS: {
      return { ...state, loadedSubscriptions: action.data }
    }
    case actions.SET_CURRENT_BILLING_PERIOD: {
      return { ...state, currentBillingPeriod: action.data }
    }
    case actions.SET_LOADED_WORKSPACES: {
      return { ...state, loadedWorkspaces: action.data }
    }
    case actions.SET_SELECTED_WORKSPACES: {
      return { ...state, selectedWorkspaces: action.data }
    }
    case actions.SET_REPORT_REQUEST: {
      return { ...state, reportRequest: action.data }
    }
    default:
      return state
  }
}

const useUsageMonitoringReducer = () => {
  const usageMonitoring = JSON.parse(sessionStorage.getItem('usageMonitoring'))
  if (usageMonitoring !== null) {
    initialContext.usageMonitoring = usageMonitoring
  }
  const [UsageMonitoringState, dispatchUsageMonitoringContext] = useReducer(
    UsageMonitoringReducer,
    initialContext
  )
  return { UsageMonitoringState, dispatchUsageMonitoringContext }
}

export default useUsageMonitoringReducer
