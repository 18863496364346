// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { HighlightUtils } from '@coveo/headless'

const HighlightString = (context, highlight) => {
  return HighlightUtils.highlightString({
    content: context,
    highlights: highlight,
    openingDelimiter: '<b>',
    closingDelimiter: '</b>'
  })
}

export default HighlightString
