// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

import { Anchor, Box, Page, PageContent, PageHeader, Tag } from 'grommet'
import { StatusWarningSmall, Previous, ServerCluster } from 'grommet-icons'
import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams, createSearchParams } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  DataTable,
  Typography,
  NoDataInfo,
  Loader,
  Notification
} from '../../../../../../components'
import { Layout } from '../../../../../../commoncomponents/layout/Layout'
import { get, post } from '../../../../../../utils/api-utils'
import {
  FOUNDATION_BLOCK_STORAGE,
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../../../utils/common-utils'
import {
  getCustomerAccountType,
  isGLOP,
  isMSPTenantCoP
} from '../../../../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  ProgressModal,
  DetachDevices,
  AssignToOnPremWorkSpaceModal,
  NewDetachSubscriptionModal
} from '../../../../../../commoncomponents/device-management'
import { customRenderer } from '../../../../../../commoncomponents/CustomRenderer'
import { displayApiError } from '../../../../../../utils/error-handling-utils'
import { NO_VALUE } from '../../../../../device-management/utils'
import { isServiceCentric } from '../../../../../../utils/account-utils'
import ReviewShowbackRatesDetails from '../../service-subscriptions/components/showbackRate/ReveiwShowbackRatesDetails'
import { ManageSubscriptionTagsModal } from '../../../common-components/ManageSubscriptionTags'
import {
  getOnPremWorkSpaces,
  setCombinedKey
} from '../../../../../../utils/dm-sm-common-utils'
import { displayNotification } from '../../../../../../utils/notificiation-utils'
import { getAssignToOnPremWorkSpacePayloadInDetails } from '../../../utils'
import { SubscriptionDetailsActionButton } from '../../../../../../commoncomponents/device-management/CommonVisibility'
import { RemoveSubscriptionModal } from '../components/RemoveSubscriptionModal'
import { buildDetachData } from '../../../../../device-management/devices-actions-utils'

import DetailsSection from './DetailsSection'

const LicenseDetailsContent = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const [openDetachModal, setOpenDetachModal] = useState(false)
  const [detachProgressModal, setDetachProgressModal] = useState(false)
  const [pullRefresh, setPullRefresh] = useState(true)
  const [supportedDeviceTypes, setSupportedDeviceTypes] = useState([])
  const [isRatesStepAvailable, setIsRatesStepAvailable] = useState(true)
  const [manageRatesModal, setManageRatesModal] = useState(false)
  const [reqData, setReqData] = useState([])

  const handleManageRatesModal = (isOpen) => {
    setManageRatesModal(isOpen)
  }
  const showMspFeature = getCustomerAccountType() === 'MSP'
  const [manageTagsModal, setManageTagsModal] = useState(false)

  const { t } = useTranslation(['device', 'licensing', 'common'])
  const { licenseKey } = useParams()
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [multiLicenseSubscription, setMultiLicenseSubscription] = useState({})
  const [licenseDetails, setLicenseDetails] = useState({
    license: {},
    devices: [],
    expirationStatus: null,
    deviceCount: 0
  })
  const [viewDetails, setViewDetails] = useState()
  const [showLoader, setLoader] = useState(false)
  const [licenseDeviceDetails, setLicenseDeviceDetails] = useState({
    devices: [],
    deviceCount: 0
  })
  const [aasTypeDetail, setAASTypeDetail] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)
  const [refreshTable, setRefreshTable] = useState(false)

  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const attachSubscriptionLD = LDFlags['glcp-dm-attach-subscription-glasgow']
  const newDashboardFlag = isServiceCentric(LDFlags)
  const dmIaaSFlag = LDFlags['glcp-dm-iaas']
  const subcriptionTagsFlag = LDFlags['glcp-subscrption-tags']
  const glopDevicesPreOnBoardingFlag = LDFlags['glcp-dscc-dm-sm']
  const glcpMultiLicenseSupport = LDFlags['glcp-multi-license-support']
  const [onPremWorkSpaceData, setOnPremWorkSpaceData] = useState([])
  const [showOnPremWorkSpaceModal, setOnPremWorkSpaceModal] = useState(false)
  const [successNotification, setSuccessNotification] = useState(null)
  const [showRemoveSubscriptionModal, setRemoveSubscriptionModal] =
    useState(false)
  const [
    showSubscriptionFeedbackNotification,
    setShowSubscriptionFeedbackNotification
  ] = useState(false)
  const [detachSubscriptionData, setDetachSubscriptionData] = useState(null)

  // For Pagination
  const itemsPerPage = 10
  const [page, setPage] = useState(1)
  const [totalSubscriptions, setTotalSubscriptions] = useState(50)
  const [showAddDevicesBtn, setShowAddDevicesBtn] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  useEffect(() => {
    if (glopDevicesPreOnBoardingFlag) {
      getOnPremWorkSpaces(oidcUser.access_token, t, setErrorNotification).then(
        (data) => {
          setOnPremWorkSpaceData(data?.items)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glopDevicesPreOnBoardingFlag])

  const getExpirationStatus = useCallback(
    (timestamp) => {
      if (!timestamp) return t('activation_delayed_warning')
      const expirationDays = Math.ceil(
        (timestamp - new Date().getTime()) / (1000 * 3600 * 24)
      )
      if (expirationDays <= 0)
        return t('subscription_expired_warning', {
          date: dayjs(timestamp).format('MMM D, YYYY')
        })
      if (expirationDays > 0 && expirationDays <= 90)
        return t('subscription_expires_warning', { days: expirationDays })
      return null
    },
    [t]
  )

  const getFormattedDate = (date) => {
    if (date) {
      return dayjs(date).format('MMM D, YYYY')
    }
    return ''
  }

  useEffect(() => {
    const url = `/ui-doorway/ui/v1/license?subscription_key=${licenseKey}`
    post(url, {}, oidcUser.access_token).then(
      (response) => {
        const {
          aas_type: aasType,
          subscription_type: type,
          subscription_key: key,
          subscription_tier_description: tierDescription,
          subscription_tier: tier,
          license_state_type: subscriptionState,
          available_quantity: available,
          contract,
          product_attributes: productAttributes,
          quantity,
          appointments: {
            subscription_start: subscriptionStart,
            subscription_end: subscriptionEnd
          } = {},
          supported_device_types: supportedDeviceTypesData,
          tags,
          on_prem_workspace_name: onPremWorkSpaceName,
          on_prem_workspace_rid,
          resource_id,
          key_type
        } = response.data.subscriptions[0] || ''
        setAASTypeDetail(aasType)
        const details = {
          license: {
            subscription_type: type,
            subscription_key: key,
            tier,
            license_state_type: subscriptionState,
            contractId: contract,
            addOns: productAttributes,
            available,
            total_count: quantity,
            start_date: getFormattedDate(subscriptionStart),
            expiration_date: getFormattedDate(subscriptionEnd),
            onprem_workspace: onPremWorkSpaceName
          },
          expirationStatus: getExpirationStatus(subscriptionEnd)
        }
        setLicenseDetails(details)
        setMultiLicenseSubscription(response?.data?.subscriptions[0])
        setViewDetails({
          ...details,
          license: {
            ...details?.license,
            tier: tierDescription,
            key
          },
          tags,
          on_prem_workspace_rid,
          key_type,
          resource_id
        })
        if (!attachSubscriptionLD)
          setSupportedDeviceTypes(supportedDeviceTypesData)
        if (
          supportedDeviceTypesData?.length > 0 &&
          Math.ceil(
            (subscriptionEnd - new Date().getTime()) / (1000 * 3600 * 24)
          ) > 0
        )
          setShowAddDevicesBtn(true)
        // Hiding Add Devices for IAAS subscriptions as a device cannot be assigned to IAAS subscriptions
        if (aasType === 'IAAS' && dmIaaSFlag) {
          setShowAddDevicesBtn(false)
        }
        // Hiding Add Devices to LTE license as a device cannot be assigned to LTE type license
        if (type === 'UXI_SENSOR_LTE') {
          setShowAddDevicesBtn(false)
        }
      },
      (error) => {
        setErrorMessage(error)
      }
    )
  }, [
    getExpirationStatus,
    licenseKey,
    oidcUser.access_token,
    pullRefresh,
    attachSubscriptionLD,
    dmIaaSFlag,
    refreshTable
  ])

  useEffect(() => {
    setLoader(true)
    get(
      '/ui-doorway/ui/v1/license/detail',
      {
        subscription_key: licenseKey,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage
      },
      oidcUser.access_token
    ).then(
      (response) => {
        let tableData = response.data.subscription_assignments.map(
          ({ device }) => {
            return device
          }
        )
        tableData = tableData.map((val) => ({
          ...val,
          application: `${val.application_name} (${val.application_instance_name})`
        }))
        setLoader(false)
        setTotalSubscriptions(response.data?.pagination?.total_count)
        setLicenseDeviceDetails({
          devices: setCombinedKey(tableData || []),
          deviceCount: response.data.pagination.total_count
        })
      },
      (error) => {
        setLoader(false)
        setErrorMessage(error)
      }
    )
  }, [licenseKey, oidcUser.access_token, page, pullRefresh])

  const handleManageModalOpen = (open) => {
    setManageTagsModal(open)
  }
  const handleClickRow = (selectedRow) => {
    if (selectedRow?.length) {
      const deviceInfo = licenseDeviceDetails?.devices?.filter((value) => {
        return selectedRow?.includes(value.unique_key)
      })

      if (glcpMultiLicenseSupport) {
        setReqData(
          buildDetachData({
            selectedRows: selectedRow,
            viewData: licenseDeviceDetails.devices
          })
        )
      }
      const modifiedDeviceInfo = deviceInfo?.reduce((acc, o) => {
        acc.push({
          serial_number: o.serial_number,
          device_type: o.device_type,
          part_number: o.part_number
        })
        return acc
      }, [])
      setSelectedRows(modifiedDeviceInfo || [])
    } else setSelectedRows([])
  }

  const onCloseDetachProgressModal = () => {
    setDetachProgressModal(false)
    setPullRefresh(!pullRefresh)
    setSelectedRows([])
  }

  const handleBack = () => {
    navigate(
      newDashboardFlag || isGLOP()
        ? '/devices/subscriptions'
        : '/manage-account/subscriptions/device-subscriptions'
    )
  }

  const onClickTag = (item) => {
    navigate(`/manage-account/tags/tag-details/${item?.name}:${item?.value}`, {
      search: 'resourceType=DEVICE_SUBSCRIPTION'
    })
  }

  const handleAddDevices = () => {
    if (!attachSubscriptionLD || !multiLicenseSubscription)
      sessionStorage.setItem(
        'supportedDeviceTypes',
        JSON.stringify(supportedDeviceTypes)
      )
    navigate({
      pathname: newDashboardFlag
        ? `/devices/subscriptions/${licenseKey}/add-devices-to-subscription`
        : `add-devices-to-subscription`,
      search: `${createSearchParams({
        subscription_type: licenseDetails.license.subscription_type,
        resource_id: viewDetails?.resource_id
      })}`
    })
  }
  const handleRemoveSubscriptionModalOpen = (isOpen) => {
    setRemoveSubscriptionModal(isOpen)
  }
  const columns = [
    {
      property: 'unique_key',
      type: 'string',
      header: t('serial_number'),
      primary: true,
      render: (datum) => (
        <Typography size="medium" testId="t13" type="text" weight="bold">
          {datum.serial_number}
        </Typography>
      )
    },
    {
      property: 'device_model',
      type: 'string',
      header: t('model')
    },
    {
      property: 'mac_address',
      type: 'string',
      header: t('mac_address'),
      render: (datum) => {
        return datum?.mac_address || NO_VALUE
      }
    },
    ...(!showMspFeature
      ? [
          {
            property: 'application',
            type: 'string',
            header: t('application')
          }
        ]
      : []),
    ...(showMspFeature
      ? [
          {
            property: 'account_name',
            type: 'string',
            header: t('account', {
              account: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )
            })
          }
        ]
      : [])
  ]

  const buttonList = [
    ...(showAddDevicesBtn
      ? [
          {
            id: 1,
            label: t('add_devices'),
            secondary: true,
            testId: 'add-devices-btn',
            onClick: handleAddDevices
          }
        ]
      : [])
  ]

  const handleDetach = () => {
    setDetachSubscriptionData({
      resource_id: viewDetails?.resource_id,
      key_type: viewDetails?.key_type
    })
    setOpenDetachModal(true)
  }

  const detailsBody = () => {
    return (
      <>
        {openDetachModal &&
          (glcpMultiLicenseSupport ? (
            <NewDetachSubscriptionModal
              onSetOpen={setOpenDetachModal}
              datum={reqData}
              openSuccessModal={() => setDetachProgressModal(true)}
              mode="BULK"
              detachSubscriptionData={detachSubscriptionData}
            />
          ) : (
            <DetachDevices
              onSetOpen={setOpenDetachModal}
              datum={selectedRows}
              openSuccessModal={() => setDetachProgressModal(true)}
              mode="SUBSCRIPTION"
            />
          ))}
        {detachProgressModal && (
          <ProgressModal
            onSetOpen={setDetachProgressModal}
            title={t('detach_devices')}
            onCloseModal={onCloseDetachProgressModal}
          />
        )}
        {!showLoader && (
          <Box direction="column" margin={{ vertical: 'medium' }} gap="large">
            <DetailsSection
              data={viewDetails?.license}
              title={t('details')}
              onPremWorkSpaceResourceId={viewDetails?.on_prem_workspace_rid}
              onPremWorkSpaceDataLength={onPremWorkSpaceData?.length}
            />
            <Box gap="medium">
              <Box
                gap="xsmall"
                border={{ side: 'bottom', color: 'border-weak' }}
              >
                <Box
                  direction="row"
                  align="center"
                  justify="between"
                  margin={{ bottom: 'small' }}
                >
                  <Typography
                    level="3"
                    type="heading"
                    testId="license-details-table-header"
                  >
                    {t('subscribed_devices', {
                      count: licenseDeviceDetails.deviceCount
                    })}
                  </Typography>
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/device-management',
                      permission: 'ccs.device-management.edit'
                    }}
                  >
                    <ButtonGroup buttonList={buttonList} testId="two-buttons" />
                  </VisibilityWrapper>
                </Box>
              </Box>
              {licenseDeviceDetails?.deviceCount ? (
                <DataTable
                  pagination={{
                    totalItems: totalSubscriptions,
                    itemsPerPage,
                    page,
                    setPage
                  }}
                  grid={{
                    data: licenseDeviceDetails.devices,
                    columns
                  }}
                  testId="license-details-table"
                  selection={
                    aasTypeDetail === 'IAAS' && dmIaaSFlag
                      ? undefined
                      : {
                          bulkActions: {
                            actionDropdown: {
                              label: t('common:actions'),
                              visibility: {
                                rbac: {
                                  resource: '/ccs/device-management',
                                  permission: 'ccs.device-management.edit'
                                }
                              }
                            },
                            customRenderer: (element, visibility, index) =>
                              customRenderer(element, visibility, index),
                            actions: [
                              {
                                label: t('detach_devices'),
                                testId: 'detach-devices-btn',
                                onClick: () => handleDetach(),
                                hidden: aasTypeDetail === 'IAAS' && dmIaaSFlag
                              }
                            ]
                          },
                          selectionInfoMessage: t(
                            'common:data_table_warning_msg'
                          ),
                          onSelectionChange: (selectedRow) =>
                            handleClickRow(selectedRow),
                          primaryKey: 'unique_key'
                        }
                  }
                />
              ) : (
                <NoDataInfo
                  subtitle={t('common:no_devices_info')}
                  icon={<ServerCluster size="large" />}
                  testId="no-data-info-without-title-licenses"
                />
              )}
            </Box>
            <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
              {subcriptionTagsFlag && (
                <Box>
                  <Box
                    border={{ side: 'bottom', color: 'border-weak' }}
                    testId="tag-details-header"
                    data-testid="tag-details-header"
                  >
                    <Box margin={{ bottom: 'small' }}>
                      <Typography level="3" type="heading">
                        {t('tags.tags_title')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gap="small"
                    direction="row-responsive"
                    wrap
                    margin={{ bottom: 'large' }}
                    data-testid="tagDetails"
                  >
                    {viewDetails?.tags?.length
                      ? viewDetails?.tags?.map((tag, idx) => {
                          return (
                            <Box
                              margin={{ top: 'xsmall' }}
                              key={`pill-${tag.name}-${tag.value}`}
                            >
                              <Tag
                                name={tag.name}
                                alignSelf="start"
                                value={tag.value}
                                testId={tag.value}
                                data-testid={`${tag.value}-${idx}`}
                                onClick={() => {
                                  onClickTag(tag)
                                }}
                              />
                            </Box>
                          )
                        })
                      : t('tags.no_tags_assigned')}
                  </Box>
                </Box>
              )}
            </VisibilityWrapper>
            <VisibilityWrapper
              hideFor={{
                feature: 'glcp-cds-rate-interface',
                account: ['MSP'],
                deployment: ['COP', 'GLOP']
              }}
              rbac={{
                resource: '/ccs/device-management/showback-rate',
                permission: 'ccs.device-showback-rate.view'
              }}
            >
              <Box
                gap="small"
                direction="row-responsive"
                wrap
                margin={{ bottom: 'large' }}
              >
                <ReviewShowbackRatesDetails
                  manageRatesModal={manageRatesModal}
                  handleManageRatesModal={handleManageRatesModal}
                  setErrorNotification={setErrorMessage}
                  subscriptionKey={licenseKey}
                  setIsRatesStepAvailable={setIsRatesStepAvailable}
                />
              </Box>
            </VisibilityWrapper>
          </Box>
        )}
      </>
    )
  }

  const manageRateButton =
    isRatesStepAvailable === true &&
    !isMSPTenantCoP() &&
    !isGLOP() &&
    LDFlags['glcp-cds-rate-interface']
      ? [
          {
            label: t('showback_rates.manage_rates_action_label'),
            onClick: () => {
              setManageRatesModal(true)
            },
            testId: 'manage-showback-rates-btn',
            visibility: {
              rbac: {
                resource: '/ccs/device-management/showback-rate',
                permission: 'ccs.device-showback-rate.manage'
              }
            }
          }
        ]
      : []
  const headerContent = () => {
    return licenseDetails?.license?.tier === FOUNDATION_BLOCK_STORAGE
      ? t('licensing:block_as_a_service')
      : viewDetails?.license?.subscription_key
  }

  return (
    <Page>
      <PageContent>
        <Box
          pad={newDashboardFlag || isGLOP() ? { horizontal: 'large' } : {}}
          width={newDashboardFlag || isGLOP() ? 'large' : ''}
          alignSelf={newDashboardFlag || isGLOP() ? 'center' : ''}
          data-testid="device-subscription-details-card"
          margin="small"
        >
          <PageHeader
            title={
              newDashboardFlag || isGLOP() ? (
                <Typography
                  level="1"
                  type="heading"
                  testId="header-title-subscription-key"
                  truncate
                >
                  {headerContent()}
                </Typography>
              ) : (
                headerContent()
              )
            }
            parent={
              <Anchor
                size={newDashboardFlag || isGLOP() ? 'xsmall' : ''}
                label={t('device_subscriptions')}
                icon={
                  <Previous
                    size={newDashboardFlag || isGLOP() ? 'small' : ''}
                  />
                }
                onClick={handleBack}
                data-testid="license-keys-btn"
              />
            }
            actions={
              subcriptionTagsFlag ? (
                <SubscriptionDetailsActionButton
                  setManageTagsModal={setManageTagsModal}
                  setOnPremWorkSpaceModal={setOnPremWorkSpaceModal}
                  onPremWorkSpaceData={onPremWorkSpaceData?.length}
                  manageRateButton={manageRateButton}
                  setRemoveSubscriptionModal={setRemoveSubscriptionModal}
                />
              ) : null
            }
            data-testid="device-subscription-details-header"
          />
          {showLoader ? (
            <Box align="center">
              <Loader testId="loader-spinner" />
            </Box>
          ) : (
            ''
          )}
          {licenseDetails?.expirationStatus && (
            <Box
              margin={{ top: 'small' }}
              background={{ color: 'status-warning', opacity: 0.24 }}
              round="xsmall"
              pad="small"
              direction="row"
            >
              <Typography
                type="paragraph"
                testId="warning-card"
                truncate
                size="medium"
                icon={
                  <StatusWarningSmall size="medium" color="status-warning" />
                }
              >
                {licenseDetails?.expirationStatus}
              </Typography>
            </Box>
          )}
          {detailsBody()}
        </Box>
        {manageTagsModal && (
          <ManageSubscriptionTagsModal
            setModalOpen={handleManageModalOpen}
            initialTags={viewDetails?.tags}
            manageTagsData={viewDetails?.license}
            setShowErrorNotification={setErrorNotification}
            selectedSubscriptionsKey={[viewDetails?.license?.subscription_key]}
            onSuccess={() => {
              setRefreshTable(!refreshTable)
            }}
          />
        )}
        {showOnPremWorkSpaceModal && (
          <AssignToOnPremWorkSpaceModal
            setOnPremWorkSpaceModal={setOnPremWorkSpaceModal}
            onPremWorkSpaceData={onPremWorkSpaceData}
            requestPayload={getAssignToOnPremWorkSpacePayloadInDetails(
              licenseDetails?.license?.subscription_key
            )}
            onSuccess={() => {
              setSuccessNotification(
                displayNotification(
                  t('manage:onprem_workspace.device_subscription_notification'),
                  'info',
                  setSuccessNotification
                )
              )
              setOnPremWorkSpaceModal(false)
              setRefreshTable(!refreshTable)
            }}
            selectedRows={[licenseDetails]}
            type="device_subscriptions"
          />
        )}
        {showRemoveSubscriptionModal && (
          <RemoveSubscriptionModal
            setModalOpen={handleRemoveSubscriptionModalOpen}
            removeSubscriptions={[licenseDetails?.license?.subscription_key]}
            onRemoveSuccess={() => {
              setRemoveSubscriptionModal(false)
              setShowSubscriptionFeedbackNotification(true)
              handleBack()
            }}
            modalHeading={t('remove_one_subscription_modal_heading')}
            modalDescription={t('remove_one_subscription_modal_description')}
            modalRemoveBtn={t('remove_subscription')}
          />
        )}
        {showSubscriptionFeedbackNotification && (
          <Notification
            testId="subscription-removal-notification"
            text={t('subscription_removal_notification')}
            status="normal"
            onClose={() => {
              setShowSubscriptionFeedbackNotification(false)
            }}
          />
        )}
        {successNotification}
        {errorNotification}

        {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
      </PageContent>
    </Page>
  )
}

const LicenseDetailsPage = () => {
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  return newDashboardFlag || isGLOP() ? (
    <LicenseDetailsContent align="start" justify="start" />
  ) : (
    <Layout>
      <LicenseDetailsContent align="start" justify="start" />
    </Layout>
  )
}

export default LicenseDetailsPage
