// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { React, useCallback, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Trans, useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { isEmpty } from 'lodash'
import { Anchor, Box, FormField, RadioButtonGroup, Select } from 'grommet'

import {
  Button,
  CCSForm,
  Loader,
  Typography,
  ModalDialog,
  ModalHeader,
  // MultiSelectBox,
  Dropdown,
  Notification,
  ModalFooter
} from '../../../../components'
import { get, post } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { roleKindMapping } from '../../utils'
import { useCCSContext } from '../../../../context/ccs-context'
import {
  getResourceTypeName,
  // filterScopelessResourceTypes,
  getAllInstancesOfResourceTypeSlug,
  isSAMLAuthzUser
} from '../utils'
import {
  USER_SCHEMAS,
  MSP_ALL_CUSTOMER_WORKSPACES_GRN_SUFFIX,
  MSP_WORKSPACE_SCOPES,
  MSP_SCOPE_ASSIGNMENT_TYPE,
  TENANT_WORKSPACE_SCOPES,
  glcpServiceID
} from '../constants'
import { LIFECYCLE_STATE } from '../../../../utils/common-utils'
import { getOrganizationId, isMSP } from '../../../../utils/feature-flag-utils'
import { updateV2RBACPolicies } from '../../../../utils/rbac-api-utils'
import { useVisibilityContext } from '../../../../context/visibility-context'

import Markdown from './Markdown'
import SelectContainer from './SelectContainer'
import { DropdownCustomRender } from './DropdownCustomRender'
import MSPScopeSelection from './MSPScopeSelection'

const MSPAssignRoleSideDrawer = ({
  preSelectedSubject = null,
  preSelectedRole = null,
  setShowAssignRoleModal,
  setStatusNotification = null,
  onAssign = null,
  appId = null,
  roleAssignments = [],
  setActiveTab = () => {}
}) => {
  const { oidcUser } = useReactOidc()
  const { csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const [offset, setOffset] = useState(0)
  const [totalScopeGroups, setTotalScopeGroups] = useState(0)

  const navigate = useNavigate()
  const location = useLocation()
  const isRoleDetailsRoute = location?.pathname?.includes(
    '/manage-account/identity/roles'
  )
  const isNewSubject =
    preSelectedSubject && Object.keys(preSelectedSubject.data)?.length === 0
  // const multiSelectBoxLimit = 10
  const orgId = getOrganizationId()
  const { workSpaceGrn } = useCCSContext()
  const { t } = useTranslation(['common', 'authn', 'iam', 'auditlogs', 'authz'])
  const [loading, setLoading] = useState(false)
  const [roleAssignedLoading, setRoleAssignedLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [searchedText, setSearchedText] = useState('')
  const [oneRoleApp, setOneRoleApp] = useState(false)
  const [hasConflictingOneRoleAppRA, setHasConflictingOneRoleAppRA] =
    useState(false) // For one role applications
  const [serviceName, setServiceName] = useState(null)
  const [isCCSRole, setIsCCSRole] = useState(false) // CCS roles are assigned entire scope
  const [servicesProvisioned, setServicesProvisioned] = useState(null)
  const [showUserGroupOption, setShowUserGroupOption] = useState(false)
  const [rolesOptionsLoading, setRolesOptionsLoading] = useState(false)

  // error states
  const [subjectTypeError, setSubjectTypeError] = useState('')
  const [subjectError, setSubjectError] = useState('')
  const [roleError, setRoleError] = useState('')
  const [scopeError, setScopeError] = useState('')
  const [wkspcGroupScopeError, setWkspcGroupScopeError] = useState('')
  // const [resourceError, setResourceError] = useState('')
  // const [serviceError, setServiceError] = useState('')
  // const [regionError, setRegionError] = useState('')
  // const [resourceTypeError, setResourceTypeError] = useState('')
  // const [resourceInstanceError, setResourceInstanceError] = useState('')
  const [scopeGroupInstanceError, setScopeGroupInstanceError] = useState('')
  const [wkspcGroupInstanceError, setWkspcGroupInstanceError] = useState('')
  const [mspScopeError, setMspScopeError] = useState('')
  const [
    precheckAlreadyExistingRoleError,
    setPrecheckAlreadyExistingRoleError
  ] = useState(false)
  const [alreadyExistingRoleError, setAlreadyExistingRoleError] =
    useState(false)
  const [hasFixedScopePermission, setHasFixedScopePermission] = useState(false)
  const [
    loadingWhileCheckingFixedScopePerm,
    setLoadingWhileCheckingFixedScopePerm
  ] = useState(false)
  const limit = 30
  const initState = {
    roles: [],
    roleOptions: [],
    subject: null,
    subjectOptions: [],
    subjectType: null,
    scope: '',
    customerWorkspaceScope: '',
    service: null,
    serviceOptions: [],
    region: null,
    regionOptions: [],
    resourceType: null,
    resourceTypeOptions: [],
    resource: '',
    resourceInstances: [],
    resourceInstanceOptions: [],
    scopeGroupInstances: null,
    scopeGroupInstanceOptions: [],
    workspaceGroupInstances: null,
    workspaceGroupInstanceOptions: []
  }

  const [state, dispatch] = useReducer((currentState, action) => {
    switch (action?.type) {
      case 'CHANGE_FIELD':
        return { ...currentState, [action?.field]: action?.value }
      case 'INIT_ROLE_STATE':
        return { ...currentState, roles: [] }
      case 'INIT_SUBJECT_STATE':
        return {
          ...currentState,
          subject: null,
          subjectOptions: []
        }
      case 'INIT_SUBJECT_TYPE_STATE':
        return { ...currentState, subjectType: null }
      case 'INIT_SCOPE_STATE':
        return { ...currentState, scope: '' }
      case 'INIT_CUSTOMER_WORKSPACE_SCOPE_STATE':
        return { ...currentState, customerWorkspaceScope: '' }
      // case 'INIT_SERVICE_STATE':
      //   return { ...currentState, service: null }
      // case 'INIT_REGION_STATE':
      //   return { ...currentState, region: null, regionOptions: [] }
      // case 'INIT_RESOURCE_TYPE_STATE':
      //   return {
      //     ...currentState,
      //     resourceType: null,
      //     resourceTypeOptions: []
      //   }
      case 'INIT_RESOURCE_STATE':
        return { ...currentState, resource: null }
      // case 'INIT_RESOURCE_INSTANCE_STATE':
      //   return {
      //     ...currentState,
      //     resourceInstances: [],
      //     resourceInstanceOptions: []
      //   }
      case 'INIT_SCOPE_GROUP_INSTANCES_STATE':
        return {
          ...currentState,
          scopeGroupInstances: null,
          scopeGroupInstanceOptions: []
        }
      case 'INIT_WORKSPACE_GROUP_INSTANCES_STATE':
        return {
          ...currentState,
          workspaceGroupInstances: null,
          workspaceGroupInstanceOptions: []
        }
      default:
        return currentState
    }
  }, initState)

  const getScopeTypeName = (scopeType) => {
    return scopeType?.name || ''
  }

  const getSubjectName = (subject, type) => {
    let name
    // use user's displayName or userName if not found
    if (type === 'USER') name = subject?.displayName || subject?.userName
    else if (type === 'GROUP') name = subject?.displayName
    // else name = subject?.name // APIClient
    return name
  }

  const subjectTypeOptions = [
    { label: t('iam:users.user'), value: 'USER' },
    ...(oneRoleApp || !showUserGroupOption
      ? []
      : [
          {
            label: t('common:business_object.user_group_capitalized'),
            value: 'GROUP'
          }
        ])
    // Commenting out APICLIENT option
    // {
    //   label: t('iam:assign_roles_side_panel.api_client_label'),
    //   value: 'APICLIENT'
    // }
  ]

  const scopeOptions = [
    {
      label: t('iam:assign_roles_side_panel.access_all_workspace_label', {
        workspace: t('common:business_object.wkspc')
      }),
      value: 'ALL_WORKSPACE_OPTION'
    },
    {
      label: t(
        'iam:assign_roles_side_panel.access_to_scope_group_resources_label'
      ),
      value: 'SCOPE_GROUP_OPTION'
    }
    // TODO Hide the Resource option as per GLCP-186839: Hide Specific resource category or instances in Assign role panel
    // ,
    // {
    //   label: t('iam:assign_roles_side_panel.access_one_resource_label'),
    //   value: 'RESOURCE_OPTION'
    // }
  ]

  // const resourceOptions = () => {
  //   if (!state?.resourceType?.value) {
  //     return [
  //       {
  //         label: t('iam:assign_roles_side_panel.select_all_resource_label'),
  //         value: 'ALL_RESOURCES_OPTION'
  //       },
  //       {
  //         label: t('iam:assign_roles_side_panel.select_one_resource_label'),
  //         value: 'SOME_RESOURCES_OPTION'
  //       }
  //     ]
  //   }

  //   const resourceTypeName = getResourceTypeName(state?.resourceType?.value)
  //   return [
  //     {
  //       label: (
  //         <Markdown>
  //           {t(
  //             'iam:assign_roles_side_panel.select_all_resource_with_type_label',
  //             {
  //               resourceType: resourceTypeName
  //             }
  //           )}
  //         </Markdown>
  //       ),
  //       value: 'ALL_RESOURCES_OPTION'
  //     },
  //     {
  //       label: (
  //         <Markdown>
  //           {t(
  //             'iam:assign_roles_side_panel.select_one_resource_with_type_label',
  //             {
  //               resourceType: resourceTypeName
  //             }
  //           )}
  //         </Markdown>
  //       ),
  //       value: 'SOME_RESOURCES_OPTION'
  //     }
  //   ]
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text) => {
      setSearchedText(text)
    }, 500),
    []
  )

  const roleTemplate = (option) => {
    const role = option?.value
    return (
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ horizontal: 'small', vertical: 'small' }}
        fill
      >
        <Box>
          <Typography size="small" type="text">
            {role?.service_name}
          </Typography>
          <Typography type="text" emphasis>
            {option?.label}
          </Typography>
          <Typography size="small" type="text">
            {role?.role_description}
          </Typography>
        </Box>
        <Typography size="small" type="text">
          {roleKindMapping.get(role?.type)}
        </Typography>
      </Box>
    )
  }

  // const resourceInstanceTemplate = (option) => {
  //   const resourceInstance = option?.value
  //   return (
  //     <Box>
  //       <Typography type="text">{option?.label}</Typography>
  //       <Typography type="text">
  //         {t('iam:assign_roles_side_panel.select_resource_instance_option', {
  //           resourceInstance: resourceInstance?.grn.substring(
  //             resourceInstance?.grn.lastIndexOf('/')
  //           )
  //         })}
  //       </Typography>
  //     </Box>
  //   )
  // }

  // const resourceTypeTemplate = (option) => {
  //   const resourceType = option?.value
  //   return (
  //     <Box
  //       justify="between"
  //       pad={{ horizontal: 'small', vertical: 'xsmall' }}
  //       fill
  //     >
  //       <Typography emphasis type="text">
  //         {option?.label}
  //       </Typography>
  //       <Typography size="small" type="text">
  //         {resourceType?.provider_disply_name || resourceType?.provider_name}
  //       </Typography>
  //     </Box>
  //   )
  // }

  const selectScopeTitle = () => {
    const fixedScopeRole =
      hasFixedScopePermission &&
      state?.roles?.length &&
      state?.roles?.length > 0 &&
      state.roles[0]?.value
    return (
      <>
        {hasFixedScopePermission && (
          <Notification
            testId="has-fixed-scope-permission-info-notification"
            type="inline"
            status="info"
            margin={{
              bottom: 'small',
              top: 'small'
            }}
            text={
              <Typography
                type="text"
                size="medium"
                margin={{ bottom: 'xxsmall' }}
              >
                <Markdown
                  components={{
                    a: ({ children }) => (
                      <Anchor
                        label={children}
                        onClick={() => {
                          if (isRoleDetailsRoute) {
                            setShowAssignRoleModal(false)
                            setActiveTab()
                          }
                          navigate(
                            `/manage-account/identity/roles/${encodeURIComponent(
                              fixedScopeRole?.role_grn
                            )}`,
                            {
                              name: fixedScopeRole?.role_display_name,
                              description: fixedScopeRole?.role_description,
                              kind: roleKindMapping.get(fixedScopeRole?.type)
                            }
                          )
                        }}
                      />
                    )
                  }}
                >
                  {t('iam:assign_roles_side_panel.fixed_scope_role_info_msg', {
                    roleName: fixedScopeRole?.role_display_name,
                    role: t('common:business_object.role'),
                    workspace: t('common:business_object.wkspc')
                  })}
                </Markdown>
              </Typography>
            }
          />
        )}
        {t('authz:scope_groups.tab_labels.scope')}
      </>
    )
  }

  const handleDiscardChanges = () => {
    setShowAssignRoleModal(false)
  }

  const checkIfRoleUnassigned = (role, existingRAs) => {
    let result = true
    const roleAssignmentList = preSelectedSubject
      ? roleAssignments
      : existingRAs
    const existingRoleAssignment = roleAssignmentList?.find(
      (ra) => ra?.role_id === role?.role_id || ra?.role_id === role?.id
    )
    if (existingRoleAssignment) {
      setPrecheckAlreadyExistingRoleError(true)
      result = false
    }
    return result
  }

  const fieldsToValidate = () => {
    let result = false
    const isSubjectTypeSelected = state?.subjectType?.value
    setSubjectTypeError(
      !isSubjectTypeSelected
        ? t('iam:assign_roles_side_panel.select_subject_type_error_msg')
        : ''
    )
    if (!isSubjectTypeSelected) return result

    const isSubjectSelected = state?.subject?.value
    // SAML Authz warning is off
    if (!subjectError) {
      setSubjectError(
        !isSubjectSelected
          ? t('iam:assign_roles_side_panel.select_subject_error_msg')
          : ''
      )
      if (!isSubjectSelected) return result
    } else {
      // return false if SAML Authz warning is on
      return result
    }

    const isRoleSelected = state?.roles?.length > 0
    setRoleError(
      !isRoleSelected
        ? t('iam:assign_roles_side_panel.select_role_error_msg', {
            role: t('common:business_object.role')
          })
        : ''
    )
    if (!isRoleSelected) return result

    if (!isMSP()) {
      const isScopeSelected = state?.scope !== ''
      setScopeError(
        !isScopeSelected
          ? t('iam:assign_roles_side_panel.select_scope_error_msg')
          : ''
      )
      if (!isScopeSelected) return result
      if (state?.scope === 'ALL_WORKSPACE_OPTION') {
        result = true
        // } else if (state?.scope === 'RESOURCE_OPTION') {
        //   setServiceError(
        //     !state?.service?.value
        //       ? t('iam:assign_roles_side_panel.select_service_error_msg')
        //       : ''
        //   )
        //   setRegionError(
        //     !state?.region?.value
        //       ? t('iam:assign_roles_side_panel.select_region_error_msg')
        //       : ''
        //   )
        //   setResourceTypeError(
        //     !state?.resourceType?.value
        //       ? t('iam:assign_roles_side_panel.select_resource_type_error_msg')
        //       : ''
        //   )
        //   setResourceError(
        //     !state?.resource
        //       ? t('iam:assign_roles_side_panel.select_resource_error_msg')
        //       : ''
        //   )
        //   setResourceInstanceError(
        //     state?.resourceInstances?.length === 0
        //       ? t(
        //           'iam:assign_roles_side_panel.select_resource_instance_error_msg'
        //         )
        //       : ''
        //   )
        //   const resourcesDataSelected =
        //     state?.service?.value &&
        //     state?.region?.value &&
        //     state?.resourceType?.value &&
        //     state?.resource
        //   if (state?.resource === 'ALL_RESOURCES_OPTION') {
        //     result = resourcesDataSelected
        //   } else if (state?.resource === 'SOME_RESOURCES_OPTION') {
        //     result = resourcesDataSelected && state?.resourceInstances?.length > 0
        //   }
      } else if (state?.scope === 'SCOPE_GROUP_OPTION') {
        const selectedScopeGroupScopeLength =
          state?.scopeGroupInstance?.value?.scopes?.length

        if (!state?.scopeGroupInstance)
          setScopeGroupInstanceError(
            t('iam:assign_roles_side_panel.select_scope_group_error_msg')
          )
        result =
          selectedScopeGroupScopeLength &&
          !!(state?.scopeGroupInstance && state?.scopeGroupInstance?.value)
      }
    } else {
      result = checkIfRoleUnassigned(state?.roles?.[0].value)
      if (result) {
        const isScopeSelected = state?.scope !== ''
        if (!isScopeSelected) {
          setScopeError(
            t('iam:msp.msp_workspace_scope_error', {
              workspace: t('common:business_object.wkspc')
            })
          )
        }
        const isCustomerWkspcScopeSelected =
          state?.customerWorkspaceScope !== ''
        if (!isCustomerWkspcScopeSelected) {
          setWkspcGroupScopeError(
            t('iam:msp.customer_workspace_scope_error', {
              workspace: t('common:business_object.wkspc')
            })
          )
        }
        const isScopeGroupSelected =
          state?.scope === MSP_WORKSPACE_SCOPES?.SPECIFIC_SCOPE_GROUPS &&
          !isEmpty(state?.scopeGroupInstances)
        if (!isScopeGroupSelected) {
          setScopeGroupInstanceError(
            t('iam:assign_roles_side_panel.select_scope_groups_error_msg', {
              scopeGroup: t('common:business_object.scope_group')
            })
          )
        }
        const isWkspcGroupSelected =
          state?.customerWorkspaceScope ===
            TENANT_WORKSPACE_SCOPES?.SPECIFIC_WORKSPACE_GROUPS &&
          !isEmpty(state?.workspaceGroupInstances)
        if (!isWkspcGroupSelected) {
          setWkspcGroupInstanceError(
            t('iam:msp.select_workspace_groups_error', {
              wkspcGroup: t('common:business_object.wkspc_group')
            })
          )
        }
        result =
          isScopeSelected &&
          isCustomerWkspcScopeSelected &&
          (state?.scope !== MSP_WORKSPACE_SCOPES?.SPECIFIC_SCOPE_GROUPS ||
            isScopeGroupSelected) &&
          (state?.customerWorkspaceScope !==
            TENANT_WORKSPACE_SCOPES?.SPECIFIC_WORKSPACE_GROUPS ||
            isWkspcGroupSelected)
        if (result) {
          if (
            state?.customerWorkspaceScope === TENANT_WORKSPACE_SCOPES?.NONE &&
            state?.scope === MSP_WORKSPACE_SCOPES?.NONE
          ) {
            result = false
            setMspScopeError(t('iam:msp.select_scope_validation_msg'))
          }
        }
      }
    }
    return result
  }

  const checkIfOneRoleApp = (provisions) => {
    // Filter
    const oneRoleApplications = provisions?.filter(
      (app) => app?.support_one_role_only
    )
    // Determine if role is one role assignment per app
    oneRoleApplications?.forEach((app) => {
      if (app?.application_id === appId) {
        setOneRoleApp(true)
      }
    })
  }

  const isOneRoleAppAssgConflicted = (
    roleAssignment,
    selectedRoleServiceId,
    selectedRoleId
  ) => {
    return (
      (roleAssignment?.service_id === selectedRoleServiceId ||
        roleAssignment?.custom_role_service_id === selectedRoleServiceId) &&
      roleAssignment?.role_id !== selectedRoleId
    )
  }

  const getOneRoleAppIds = (provisions) => {
    return provisions
      ?.filter((app) => app?.support_one_role_only)
      .map((service) => service?.application_id)
  }

  const roleDetailsOneRoleCheck = (userRAs) => {
    const directRoleAssignments = userRAs?.filter(
      (roleAssignment) => !roleAssignment?.source_Id
    )
    const conflictingOneRoleAppRA = directRoleAssignments?.find(
      (roleAssignment) =>
        isOneRoleAppAssgConflicted(roleAssignment, appId, preSelectedRole?.id)
    )
    if (conflictingOneRoleAppRA) {
      setHasConflictingOneRoleAppRA(true)
      if (
        conflictingOneRoleAppRA?.service_name ||
        conflictingOneRoleAppRA?.custom_role_service_name
      ) {
        setServiceName(
          conflictingOneRoleAppRA?.service_name ||
            conflictingOneRoleAppRA?.custom_role_service_name
        )
      }
    }
  }

  /**
   * This function is used to determine if we display a one role app warning
   * for the quick actions workflow
   */
  const quickActionsOneRoleCheck = (role, provisionedServices, userRAs) => {
    // Check if role is one role application
    const isOneRoleApp = getOneRoleAppIds(provisionedServices)?.includes(
      role?.service_id || role?.custom_role_service_id
    )

    // If one role app, check if user has one role app assigned already
    if (isOneRoleApp) {
      const conflictingOneRoleAppRA = userRAs?.some(
        (ra) =>
          ((ra?.service_id && ra?.service_id === role?.service_id) ||
            (ra?.custom_role_service_id &&
              ra?.custom_role_service_id === role?.custom_role_service_id)) &&
          ra?.role_id !== role?.role_id
      )
      setHasConflictingOneRoleAppRA(conflictingOneRoleAppRA)
      setServiceName(role?.service_name || role?.custom_role_service_name)
    }
  }

  const getServicesProvisioned = async () => {
    let provisionsService = []
    try {
      const response = await get(
        '/ui-doorway/ui/v1/applications/provisions',
        {
          provision_status: 'PROVISIONED'
        },
        oidcUser?.access_token
      )
      provisionsService = response?.data?.provisions
    } catch (error) {
      setApiError(error)
    }
    return provisionsService
  }

  const getRoles = async () => {
    // call get roles API when:
    // - isEmpty(preSelectedRole) => Not from Role details page
    // - !isEmpty(state?.subjectType) =>
    //     When the subject type is selected
    if (isEmpty(preSelectedRole) && !isEmpty(state?.subjectType)) {
      setRolesOptionsLoading(true)
      try {
        const response = await get('/internal-platform-tenant-ui/v2/roles', {
          service: 'all',
          ...(searchedText.trimStart().length > 0 && {
            search: searchedText.trimStart()
          })
        })
        const options = []
        if (response?.data?.roles?.length) {
          let roles = response?.data?.roles
          if (
            preSelectedSubject?.subjectType === 'GROUP' ||
            state?.subjectType?.value === 'GROUP'
          ) {
            const provisions = await getServicesProvisioned()
            setServicesProvisioned(provisions)
            const oneRoleApps = provisions
              ?.filter((service) => service.support_one_role_only)
              ?.map((application) => application.application_id)
            roles = roles?.filter(
              (role) =>
                !oneRoleApps.includes(
                  role?.service_id || role?.custom_role_service_id
                )
            )
          }
          roles?.forEach((role) => {
            options.push({
              label: role.role_display_name,
              value: role
            })
          })
        }
        setRolesOptionsLoading(false)
        dispatch({
          value: options,
          field: 'roleOptions',
          type: 'CHANGE_FIELD'
        })
      } catch (error) {
        setApiError(error)
      }
      setRolesOptionsLoading(false)
    }
  }

  const checkHasFixedScopePermission = async (grn) => {
    setLoadingWhileCheckingFixedScopePerm(true)
    try {
      const response = await get(
        `/internal-platform-tenant-ui/v2/roles?grn=${encodeURIComponent(grn)}`
      )
      if (response?.data && response?.data?.items?.length > 0) {
        const roleData = response?.data?.items[0]
        const fixedScopePermissionResult = roleData?.permissions?.some(
          (permission) => permission.fixedScope === true
        )
        setHasFixedScopePermission(fixedScopePermissionResult)
      } else {
        setHasFixedScopePermission(false)
      }
      setLoadingWhileCheckingFixedScopePerm(false)
    } catch (err) {
      setLoadingWhileCheckingFixedScopePerm(false)
      setHasFixedScopePermission(false)
      setApiError(err)
    }
  }

  const updateScopeGroupOption = (scopeGroupOptions) => {
    dispatch({
      value: scopeGroupOptions,
      field: 'scopeGroupInstanceOptions',
      type: 'CHANGE_FIELD'
    })
  }

  const getScopeGroups = () => {
    get(
      `/authorization/v2alpha1/scope-groups?limit=${limit}&offset=${
        offset || 0
      }`
    ).then(
      (response) => {
        // These lines are for formatting the scope groups to display on the dropdown
        setOffset(offset + limit)
        setTotalScopeGroups(response?.data?.total)
        const scopeGroups = []
        response?.data?.items?.forEach((scopeGroup) => {
          scopeGroups.push({
            label: scopeGroup?.name,
            desc: scopeGroup?.description,
            value: scopeGroup
          })
        })
        updateScopeGroupOption([
          ...state.scopeGroupInstanceOptions,
          ...scopeGroups
        ])
      },
      (error) => {
        setApiError(error)
      }
    )
  }

  const clearScopeErrors = () => {
    setScopeGroupInstanceError('')
    setScopeError('')
    setMspScopeError('')
  }

  const clearCustomerWorkspaceScopeErrors = () => {
    setWkspcGroupScopeError('')
    setWkspcGroupInstanceError('')
    setMspScopeError('')
  }

  const clearScopeSelections = () => {
    clearScopeErrors()
    dispatch({ type: 'INIT_SCOPE_STATE' })
    dispatch({ type: 'INIT_SCOPE_GROUP_INSTANCES_STATE' })
  }

  const clearCustomerWorkspaceScopeSelections = () => {
    clearCustomerWorkspaceScopeErrors()
    dispatch({ type: 'INIT_CUSTOMER_WORKSPACE_SCOPE_STATE' })
    dispatch({ type: 'INIT_WORKSPACE_GROUP_INSTANCES_STATE' })
  }

  const clearRoleRelatedSelections = () => {
    setAlreadyExistingRoleError(false)
    setPrecheckAlreadyExistingRoleError(false)
    clearScopeSelections()
    clearCustomerWorkspaceScopeSelections()
  }

  const handleSubjectTypeChange = async (option) => {
    if (!preSelectedRole) {
      dispatch({ type: 'INIT_ROLE_STATE' })
      setHasFixedScopePermission(false)
      setRoleError('')
    }
    dispatch({ type: 'INIT_SUBJECT_STATE' })
    setSubjectError('')
    if (!isCCSRole) dispatch({ type: 'INIT_SCOPE_STATE' })
    setScopeError('')
    // dispatch({ type: 'INIT_SERVICE_STATE' })
    // setServiceError('')
    // dispatch({ type: 'INIT_REGION_STATE' })
    // setRegionError('')
    // dispatch({ type: 'INIT_RESOURCE_TYPE_STATE' })
    // setResourceTypeError('')
    // dispatch({ type: 'INIT_RESOURCE_STATE' })
    // setResourceError('')
    // dispatch({ type: 'INIT_RESOURCE_INSTANCE_STATE' })
    // setResourceInstanceError('')
    dispatch({ type: 'INIT_SCOPE_GROUP_INSTANCES_STATE' })
    setScopeGroupInstanceError('')
    if (isMSP()) {
      dispatch({ type: 'INIT_WORKSPACE_GROUP_INSTANCES_STATE' })
    }

    let getSubjectURL = '/identity/v2alpha1/scim/v2/Users'
    if (option?.value === 'GROUP') {
      getSubjectURL = '/identity/v2alpha1/scim/v2/Groups'
    }
    // else if (option?.value === 'APICLIENT') {
    //   getSubjectURL = '/internal-identity/v1alpha1/pce-api-clients'
    // }

    try {
      const response = await get(getSubjectURL, {})
      const subjects =
        option?.value === 'APICLIENT'
          ? response?.data?.items
          : response?.data?.Resources

      const options = []
      subjects?.forEach((subject) => {
        options?.push({
          label: getSubjectName(subject, option?.value),
          value: subject
        })
      })

      dispatch({
        value: options,
        field: 'subjectOptions',
        type: 'CHANGE_FIELD'
      })
    } catch (error) {
      setApiError(error)
    }
  }

  const checkOneRoleApp = async (option, provisionedServices, userRAs) => {
    if (preSelectedRole) {
      checkIfOneRoleApp(provisionedServices)
    }

    if (
      !preSelectedRole &&
      !preSelectedSubject &&
      state?.subjectType?.value === 'USER'
    ) {
      quickActionsOneRoleCheck(option[0]?.value, provisionedServices, userRAs)
    }

    if (preSelectedSubject?.subjectType === 'USER') {
      const selectedRoleId = option[0]?.value?.role_id
      const selectedRoleServiceId =
        option[0]?.value?.service_id || option[0]?.value?.custom_role_service_id
      const oneRoleIds = getOneRoleAppIds(provisionedServices)

      if (oneRoleIds?.includes(selectedRoleServiceId)) {
        const assgConflicted = roleAssignments?.some((roleAssignment) =>
          isOneRoleAppAssgConflicted(
            roleAssignment,
            selectedRoleServiceId,
            selectedRoleId
          )
        )
        if (assgConflicted) {
          setHasConflictingOneRoleAppRA(true)
          setServiceName(
            option[0]?.value?.service_name ||
              option[0]?.value?.custom_role_service_name
          )
        }
      }
    }

    if (option[0]?.value?.role_grn && !hasConflictingOneRoleAppRA) {
      await checkHasFixedScopePermission(option[0]?.value?.role_grn)
    }
  }

  const checkSAMLAuthz = (user) => {
    setRoleAssignedLoading(true)
    get(
      `/ui-doorway/ui/v2/um/users?email=${encodeURIComponent(user?.userName)}`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data && response?.data?.users[0]) {
          if (isSAMLAuthzUser(response?.data?.users[0])) {
            setSubjectError(
              t('iam:ccs_attribute.assign_saml_role_warning', {
                user: t('common:business_object.user'),
                roleAssignments: t(
                  'common:business_object.role_assignment_plural'
                )
              })
            )
          }
        }
        setRoleAssignedLoading(false)
      },
      (error) => {
        setApiError(error)
        setRoleAssignedLoading(false)
      }
    )
  }

  const getUserRoleAssignments = async (user) => {
    let userRAs = []
    setRoleAssignedLoading(true)
    try {
      const response = await get(
        `/internal-platform-tenant-ui/v2alpha2/role-assignments?subject=${
          user[USER_SCHEMAS?.EXTENSIONS]?.hpe_principal
        }`
      )
      userRAs = response?.data?.role_assignments
    } catch (error) {
      setApiError(error)
    } finally {
      setRoleAssignedLoading(false)
    }
    return userRAs
  }

  const buildAppOptions = (provisionedServices) => {
    const appOptions = []
    const appIds = []
    const serviceToRegions = {}

    provisionedServices?.forEach((service) => {
      if (!serviceToRegions[service.application_id]) {
        serviceToRegions[service.application_id] = [
          { region_name: service.region_name, region: service.region }
        ]
      } else {
        serviceToRegions[service.application_id].push({
          region_name: service.region_name,
          region: service.region
        })
      }
    })

    provisionedServices?.forEach((service) => {
      if (!appIds.includes(service.application_id)) {
        appIds.push(service.application_id)
        service.regions = serviceToRegions[service.application_id]
        appOptions.push({ label: service.name, value: service })
      }
    })

    return appOptions
  }

  const handleSubjectChange = async (option) => {
    let userRAs
    if (
      (preSelectedRole || oneRoleApp) &&
      state?.subjectType?.value === 'USER'
    ) {
      userRAs = await getUserRoleAssignments(option?.value)
    }
    const result = preSelectedRole
      ? checkIfRoleUnassigned(preSelectedRole, userRAs)
      : true
    if (result) {
      if (state?.subjectType?.value === 'USER') {
        checkSAMLAuthz(option?.value)
      }
      if (oneRoleApp) {
        setHasConflictingOneRoleAppRA(false) // Need to reset for when another user is selected
        roleDetailsOneRoleCheck(userRAs)
      }
    }
  }
  const handleRolesChange = async (option) => {
    let userRAs
    if (
      !preSelectedRole &&
      !preSelectedSubject &&
      state?.subjectType?.value === 'USER'
    ) {
      userRAs = await getUserRoleAssignments(state?.subject?.value)
    }

    if (checkIfRoleUnassigned(option?.[0]?.value, userRAs)) {
      try {
        setRoleAssignedLoading(true)
        setHasConflictingOneRoleAppRA(false)

        const provisionedServices =
          servicesProvisioned !== null
            ? servicesProvisioned
            : await getServicesProvisioned()

        await checkOneRoleApp(option, provisionedServices, userRAs)

        setRoleAssignedLoading(false)

        if (state?.scopeGroupInstanceOptions?.length === 0) {
          const appOptions = buildAppOptions(provisionedServices)
          dispatch({
            value: appOptions,
            field: 'serviceOptions',
            type: 'CHANGE_FIELD'
          })
        }
      } catch (error) {
        setApiError(error)
      }
    }
  }

  // const handleServiceChange = async (option) => {
  //   state.regionOptions.length = 0
  //   const regionOptions = []
  //   option?.value?.regions?.forEach((item) => {
  //     regionOptions.push({
  //       label: item?.region_name,
  //       value: item?.region
  //     })
  //   })

  //   dispatch({
  //     value: regionOptions,
  //     field: 'regionOptions',
  //     type: 'CHANGE_FIELD'
  //   })

  //   state.resourceTypeOptions.length = 0
  //   try {
  //     const response = await get(
  //       '/internal-platform-tenant-ui/v2/resource-types',
  //       {
  //         application_id: option?.value?.application_id
  //       }
  //     )
  //     const resourceTypeOptions = filterScopelessResourceTypes(
  //       response?.data?.resource_types
  //     )
  //     dispatch({
  //       value: resourceTypeOptions,
  //       field: 'resourceTypeOptions',
  //       type: 'CHANGE_FIELD'
  //     })
  //   } catch (error) {
  //     state.resourceTypeOptions.length = 0
  //     setApiError(error)
  //   }
  // }

  // const handleResourceTypeChange = async (option) => {
  //   state.resourceInstanceOptions.length = 0
  //   const allInstancesOfResourceTypeSlug =
  //     getAllInstancesOfResourceTypeSlug(option)
  //   const resourceTypeGrn = `${workSpaceGrn}/regions/${state?.region?.value}/providers/${allInstancesOfResourceTypeSlug}`
  //   try {
  //     const response = await get('/internal-authorization/v2alpha1/resources', {
  //       grn: resourceTypeGrn
  //     })
  //     const resourceInstances = response?.data?.items
  //     const options = []
  //     resourceInstances?.forEach((resIns) => {
  //       options.push({
  //         label: resIns?.displayName,
  //         value: resIns
  //       })
  //     })

  //     dispatch({
  //       value: options,
  //       field: 'resourceInstanceOptions',
  //       type: 'CHANGE_FIELD'
  //     })
  //   } catch (error) {
  //     setApiError(error)
  //     state.resourceInstanceOptions.length = 0
  //   }
  // }

  const handleDispatch = async (option, field) => {
    dispatch({
      value: option,
      field,
      type: 'CHANGE_FIELD'
    })

    if (field === 'subjectType' && option) {
      handleSubjectTypeChange(option)
    }

    if (field === 'scope') {
      if (state?.scopeGroupInstanceOptions?.length === 0) {
        getScopeGroups()
      } else if (scopeGroupInstanceError) {
        // eslint-disable-next-line no-use-before-define
        if (state.scopeGroupInstances)
          handleDispatch(null, 'scopeGroupInstances')
        setScopeGroupInstanceError('')
      }
    }

    if (field === 'roles') {
      await handleRolesChange(option)
    }

    if (field === 'subject') {
      await handleSubjectChange(option)
    }

    // if (field === 'service') {
    //   await handleServiceChange(option)
    // }

    // if (field === 'resourceType') {
    //   await handleResourceTypeChange(option)
    // }
  }

  useEffect(() => {
    let isMounted = true
    if (orgId && orgId !== '' && !preSelectedSubject) {
      setLoading(true)
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (response) => {
          if (!isMounted) return
          if (response?.data?.lifecycleState === LIFECYCLE_STATE.ACTIVE) {
            setShowUserGroupOption(true)
          }
          setLoading(false)
        },
        (error) => {
          setApiError(error)
          setLoading(false)
        }
      )
    }

    return () => {
      isMounted = false
    }
  }, [t, orgId, preSelectedSubject])

  useEffect(() => {
    getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedText, state?.subjectType?.value])

  useEffect(() => {
    if (preSelectedRole) {
      // change role to ui-doorway format; UID should fix post MVP
      const uidRole = {
        role_id: preSelectedRole.id,
        role_name: preSelectedRole.name,
        role_display_name: preSelectedRole.displayName,
        role_description: preSelectedRole.description,
        role_grn: preSelectedRole.grn,
        type: preSelectedRole.type
      }

      handleDispatch(
        [
          {
            label: preSelectedRole.displayName,
            value: uidRole
          }
        ],
        'roles'
      )

      if (preSelectedRole?.grn) {
        checkHasFixedScopePermission(preSelectedRole?.grn)
      }
    }

    if (preSelectedSubject) {
      let subjectTypeLabel
      if (preSelectedSubject?.subjectType === 'USER')
        subjectTypeLabel = t('common:business_object.user_capitalized')
      else if (preSelectedSubject?.subjectType === 'GROUP')
        subjectTypeLabel = t('common:business_object.user_group_capitalized')
      // else subjectTypeLabel = t('iam:assign_roles_side_panel.api_client_label')
      dispatch({
        value: {
          label: subjectTypeLabel,
          value: preSelectedSubject?.subjectType
        },
        field: 'subjectType',
        type: 'CHANGE_FIELD'
      })

      handleDispatch(
        {
          label: getSubjectName(
            preSelectedSubject?.data,
            preSelectedSubject?.subjectType
          ),
          value: preSelectedSubject?.data
        },
        'subject'
      )
    }

    if (appId && appId === glcpServiceID) {
      // in case we are assigning role to roles with ccs permission from roles detail
      handleDispatch('ALL_WORKSPACE_OPTION', 'scope')
      setIsCCSRole(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSubjectText = () => {
    switch (state?.subjectType?.value) {
      case 'GROUP':
        return t('common:business_object.group')
      // case 'APICLIENT':
      //   return t('iam:assign_roles_side_panel.api_client_label')
      default:
        return t('common:business_object.user')
    }
  }
  const getAssignmentNotification = (response) => {
    const totalRoles = state?.roles?.length
    const subjectName = state?.subject?.label
    const PartialSuccessMessage = t(
      'iam:assign_roles_side_panel.partial_success_notification_role_msg'
    )

    const failedNotificationMessage = (
      <Markdown>
        {t('iam:assign_roles_side_panel.failed_notification_role_msg', {
          roleName: state?.roles?.[0].label,
          role: t('common:business_object.role'),
          subjectName,
          isUserText: getSubjectText()
        })}
      </Markdown>
    )
    const successNotificationMessage =
      totalRoles === 1 ? (
        <Typography type="text">
          <Trans>
            {t(
              'iam:assign_roles_side_panel.success_notification_role_singular_msg_v2',
              {
                roleName: state?.roles?.[0].label,
                role: t('common:business_object.role'),
                subjectName,
                isUserText: getSubjectText()
              }
            )}
          </Trans>
        </Typography>
      ) : (
        <Typography type="text">
          <Trans>
            {t(
              'iam:assign_roles_side_panel.success_notification_roles_plural_msg_v2',
              {
                totalRoles,
                roles: t('common:business_object.role_plural'),
                subjectName,
                isUserText: getSubjectText()
              }
            )}
          </Trans>
        </Typography>
      )

    const alreadyExistingRoleCondition =
      response?.status === 206 &&
      response?.data?.failed[0]?.error_response[0]?.details?.httpStatusCode ===
        409 &&
      response?.data?.failed[0]?.error_response[0]?.details?.errorCode ===
        'HPE_GL_AUTHORIZATION_ALREADY_CREATED'

    if (alreadyExistingRoleCondition) {
      setAlreadyExistingRoleError(true)
      return
    }

    let notification = {
      message:
        // eslint-disable-next-line no-nested-ternary
        response?.status === 206 &&
        response?.data?.success &&
        response?.data?.failed
          ? PartialSuccessMessage
          : response?.status === 206 && response?.data?.success
          ? successNotificationMessage
          : failedNotificationMessage,
      severity: response?.data?.success ? 'info' : 'error',
      testId: 'assign-role-status',
      title: response?.data?.success
        ? t('iam:assign_roles_side_panel.assign_role_success_msg', {
            role: t('common:business_object.role_capitalized')
          })
        : t('iam:assign_roles_side_panel.assign_role_failure_msg')
    }
    if (response?.status === 201) {
      notification = {
        ...notification,
        message: successNotificationMessage
      }
    }
    return notification
  }

  /* eslint consistent-return: "off" */
  const handleAssignClick = () => {
    if (fieldsToValidate()) {
      let scopes = []
      if (
        state?.scope === 'ALL_WORKSPACE_OPTION' ||
        state?.scope === MSP_WORKSPACE_SCOPES?.ENTIRE
      ) {
        scopes = isNewSubject
          ? [
              {
                scope: workSpaceGrn,
                scopeType: MSP_SCOPE_ASSIGNMENT_TYPE?.MSP_WORKSPACE
              }
            ]
          : [workSpaceGrn]
      } else if (state?.resource === 'ALL_RESOURCES_OPTION') {
        const allInstancesOfResourceTypeSlug =
          getAllInstancesOfResourceTypeSlug(state?.resourceType)
        const resourceTypeGRN = `${workSpaceGrn}/regions/${state?.region?.value}/providers/${allInstancesOfResourceTypeSlug}`
        scopes = [resourceTypeGRN]
      } else if (state?.scope === 'SCOPE_GROUP_OPTION') {
        scopes = [state?.scopeGroupInstance?.value?.grn] // grn of selected scope group
      } else if (state?.scope === MSP_WORKSPACE_SCOPES?.SPECIFIC_SCOPE_GROUPS) {
        const scopeGroupGrns = state?.scopeGroupInstances?.map((instance) =>
          isNewSubject
            ? {
                scope: instance?.grn,
                scopeName: instance?.name,
                scopeType: MSP_SCOPE_ASSIGNMENT_TYPE?.MSP_SCOPE_GROUP
              }
            : instance?.grn
        )
        scopes = scopeGroupGrns
      } else {
        scopes = state?.resourceInstances?.map((option) => {
          return option?.value?.grn
        })
      }
      if (state?.customerWorkspaceScope === TENANT_WORKSPACE_SCOPES?.ALL) {
        const allTenantsGrn = `${workSpaceGrn}/${MSP_ALL_CUSTOMER_WORKSPACES_GRN_SUFFIX}`
        scopes?.push(
          isNewSubject
            ? {
                scope: allTenantsGrn,
                scopeType: MSP_SCOPE_ASSIGNMENT_TYPE?.CUSTOMER_ALL_CUSTOMERS
              }
            : allTenantsGrn
        )
      } else if (
        state?.customerWorkspaceScope ===
        TENANT_WORKSPACE_SCOPES?.SPECIFIC_WORKSPACE_GROUPS
      ) {
        state?.workspaceGroupInstances?.forEach((instance) => {
          scopes?.push(
            isNewSubject
              ? {
                  scope: instance?.grn,
                  scopeName: instance?.displayName,
                  scopeType: MSP_SCOPE_ASSIGNMENT_TYPE?.CUSTOMER_WORKSPACE_GROUP
                }
              : instance?.grn
          )
        })
      }

      if (isNewSubject) {
        const roles = state?.roles?.map((role) => {
          return role?.value
        })
        onAssign(roles, scopes)
        setShowAssignRoleModal(false)
      } else {
        const role = state?.roles?.[0]?.value
        const postBody = {
          assignments: [
            {
              role_grn: role?.role_grn || '',
              scopes
            }
          ]
        }
        const subjectId = state?.subject?.value?.id
        if (state?.subjectType?.value === 'USER') {
          postBody.user_id = subjectId
        } else if (state?.subjectType?.value === 'GROUP') {
          postBody.group_id = subjectId
        }
        // else {
        //   postBody.client_id = subjectId
        // }
        return new Promise((resolve) => {
          post(
            '/internal-platform-tenant-ui/v2alpha2/role-assignments',
            postBody
          ).then(
            (response) => {
              resolve()
              // This could affect the current user's permissions hence why we're calling updateV2RBACPolicies
              // updateV2RBACPolicies will not run on local as csrfToken will be null
              updateV2RBACPolicies(dispatchVisibilityContext, csrfToken)
              setShowAssignRoleModal(false)
              setStatusNotification(getAssignmentNotification(response))
              onAssign()
            },
            (error) => {
              setShowAssignRoleModal(false)
              setStatusNotification({
                message: error.message,
                severity: 'error',
                testId: 'assign-role-status',
                title: t('iam:assign_roles_side_panel.assign_role_failure_msg')
              })
            }
          )
        })
      }
    }
  }

  const subjectGivenOrSelected = state?.subject?.value || isNewSubject
  const roleGivenOrSelected = state?.roles?.length > 0
  const resourceTypeName = getResourceTypeName(state?.resourceType?.value)
  const scopeTypeName = getScopeTypeName(state?.scopeGroupInstance?.value)
  const loadScopeSelectionCondition =
    !loadingWhileCheckingFixedScopePerm &&
    !hasConflictingOneRoleAppRA &&
    !roleAssignedLoading

  return (
    <>
      {apiError && displayApiError(apiError, t, setApiError)}
      <>
        {loading ? (
          <ModalDialog
            content={
              <Box
                fill
                align="center"
                justify="center"
                direction="row"
                pad={{ vertical: 'small', horizontal: 'large' }}
                background={{ color: 'background-front', opacity: 'strong' }}
              >
                <Loader label="Loading..." testId="assign-roles-loader" />
              </Box>
            }
            onClose={() => setShowAssignRoleModal(false)}
            testId="assign-role-side-drawer-loading-modal"
          />
        ) : (
          <ModalDialog
            header={
              <ModalHeader
                title={
                  <>
                    <Typography
                      level="2"
                      testId="assign-role-title"
                      type="heading"
                    >
                      {t('iam:role_assignment.assign_role', {
                        roles: t('common:business_object.role')
                      })}
                    </Typography>
                    {preSelectedSubject && !isNewSubject ? (
                      <Box width="medium" pad={{ top: 'small' }}>
                        {state?.subjectType?.value === 'GROUP' ? (
                          <>
                            <Typography type="text">
                              <Trans>
                                {t(
                                  'iam:assign_roles_side_panel.subtitle_with_group_v2',
                                  {
                                    users: t(
                                      'common:business_object.user_plural'
                                    ),
                                    subjectName: state?.subject?.label,
                                    group: t('common:business_object.group'),
                                    roles: t(
                                      'common:business_object.role_plural'
                                    )
                                  }
                                )}
                              </Trans>
                            </Typography>
                            <Box margin={{ top: 'medium' }}>
                              <Notification
                                testId="assign-role-side-drawer-notification"
                                type="inline"
                                status="info"
                                text={t(
                                  'iam:assign_roles_side_panel.subtitle_with_group_info'
                                )}
                              />
                            </Box>
                          </>
                        ) : (
                          <Typography type="text">
                            <Trans>
                              {t(
                                'iam:assign_roles_side_panel.subtitle_with_subject_v2',
                                {
                                  subjectName: state?.subject?.label,
                                  roles: t('common:business_object.role_plural')
                                }
                              )}
                            </Trans>
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      <Box pad={{ top: 'small' }}>
                        <Typography type="text" color="light">
                          {state?.subjectType?.value === 'USER'
                            ? t('iam:assign_roles_side_panel.subtitle_user', {
                                role: t('common:business_object.role')
                              })
                            : t('iam:assign_roles_side_panel.subtitle', {
                                role: t('common:business_object.role')
                              })}
                        </Typography>
                      </Box>
                    )}
                  </>
                }
                onClose={() => setShowAssignRoleModal(false)}
              />
            }
            content={
              <>
                {loading ? (
                  <Loader testId="assign-role-side-drawer-loader" />
                ) : (
                  <CCSForm
                    errorMessage=""
                    testId="assign-role-side-drawer-form"
                  >
                    <Box
                      height="100%"
                      pad={{ right: 'small', bottom: 'small' }}
                      data-testid="assign-role-content-box"
                    >
                      <Box pad={{ top: 'small' }}>
                        {/*  subject selection */}
                        {!preSelectedSubject && !isNewSubject ? (
                          <Box>
                            <Box>
                              <SelectContainer
                                title={t(
                                  'iam:assign_roles_side_panel.subject_type_title'
                                )}
                                subtitle={t(
                                  'iam:assign_roles_side_panel.subject_type_subtitle'
                                )}
                              />
                              <FormField
                                required
                                error={subjectTypeError}
                                data-testid="subject-type-select-form"
                              >
                                <Select
                                  placeholder={t(
                                    'iam:users.multi_select_box_placeholder'
                                  )}
                                  options={subjectTypeOptions}
                                  labelKey="label"
                                  valueKey="value"
                                  value={state?.subjectType}
                                  onChange={({ option }) => {
                                    handleDispatch(option, 'subjectType')
                                    if (option) setSubjectTypeError('')
                                    clearRoleRelatedSelections()
                                  }}
                                  required
                                />
                              </FormField>
                            </Box>

                            {state?.subjectType?.value && (
                              <Box>
                                <SelectContainer
                                  title={
                                    state?.subjectType?.value === 'USER'
                                      ? t(
                                          'common:business_object.user_capitalized'
                                        )
                                      : t(
                                          'common:business_object.user_group_capitalized'
                                        )
                                  }
                                />
                                <FormField
                                  required
                                  error={subjectError}
                                  data-testid="subject-select-form"
                                >
                                  <Select
                                    placeholder={t(
                                      'iam:users.multi_select_box_placeholder'
                                    )}
                                    options={state?.subjectOptions}
                                    labelKey="label"
                                    valueKey="value"
                                    value={state?.subject}
                                    dropHeight="medium"
                                    onChange={({ option }) => {
                                      // reset subject error upon new selection
                                      setSubjectError('')
                                      clearRoleRelatedSelections()
                                      handleDispatch(option, 'subject')
                                    }}
                                    required
                                  />
                                </FormField>
                              </Box>
                            )}
                          </Box>
                        ) : null}
                        {!subjectError &&
                          ((!preSelectedRole && subjectGivenOrSelected) ||
                          isNewSubject ? (
                            <Box>
                              <SelectContainer
                                title={t(
                                  'common:business_object.role_capitalized'
                                )}
                              />
                              <FormField
                                required
                                error={roleError}
                                data-testid="roles-select-form"
                                name="role-to-duplicate"
                              >
                                <Dropdown
                                  placeholder={t(
                                    'iam:users.multi_select_box_placeholder'
                                  )}
                                  value={state?.roles}
                                  labelKey="label"
                                  valueKey="value"
                                  dropHeight="medium"
                                  options={state?.roleOptions}
                                  searchPlaceholder={t('search')}
                                  onChangeDropdown={(selectedRoles) => {
                                    handleDispatch('', 'scope')
                                    setIsCCSRole(false)
                                    setHasConflictingOneRoleAppRA(false)
                                    if (
                                      selectedRoles?.value?.service_id ===
                                        glcpServiceID ||
                                      selectedRoles?.value
                                        ?.custom_role_service_id ===
                                        glcpServiceID
                                    ) {
                                      // checking if role is ccs role or that role has ccs permission
                                      handleDispatch(
                                        'ALL_WORKSPACE_OPTION',
                                        'scope'
                                      )
                                      setIsCCSRole(true)
                                    }
                                    const roles = [selectedRoles]
                                    clearRoleRelatedSelections(false)
                                    handleDispatch(roles, 'roles')
                                    if (selectedRoles) setRoleError('')
                                  }}
                                  onSearch={(searchText) => {
                                    handleDebouncedSearchValue(searchText)
                                  }}
                                  emptySearchMessage={
                                    rolesOptionsLoading ? (
                                      <Loader testId="roles-options-loader" />
                                    ) : (
                                      t('iam:roles_error.no_matches_msg')
                                    )
                                  }
                                  customRender={(role) => roleTemplate(role)}
                                  testId="roles-multi-select"
                                />
                              </FormField>
                            </Box>
                          ) : null)}
                        {!subjectError && loadScopeSelectionCondition && (
                          <>
                            {/* Standard enterprise scope selection */}
                            {subjectGivenOrSelected &&
                            roleGivenOrSelected &&
                            !isCCSRole &&
                            !isMSP() ? (
                              <Box pad={{ top: 'medium' }}>
                                <SelectContainer
                                  heading={t(
                                    'iam:assign_roles_side_panel.specify_role_scope_title',
                                    {
                                      role: t('common:business_object.role')
                                    }
                                  )}
                                  title={selectScopeTitle()}
                                />
                                <FormField
                                  required
                                  error={scopeError}
                                  data-testid="scope-radio-btn-form"
                                >
                                  <RadioButtonGroup
                                    name="scope"
                                    options={scopeOptions}
                                    value={state?.scope}
                                    labelKey="label"
                                    valueKey="value"
                                    onChange={(event) => {
                                      const { value } = event.target
                                      handleDispatch(value, 'scope')
                                      clearRoleRelatedSelections()
                                      if (event.target.value) setScopeError('')
                                    }}
                                  />
                                </FormField>
                              </Box>
                            ) : null}
                            {/** MSP scope selection */}
                            {subjectGivenOrSelected &&
                            roleGivenOrSelected &&
                            !precheckAlreadyExistingRoleError &&
                            isMSP() ? (
                              <>
                                <MSPScopeSelection
                                  handleSelection={handleDispatch}
                                  scopeError={{
                                    scopeError,
                                    scopeGroupInstanceError,
                                    clearError: clearScopeErrors
                                  }}
                                  wkspcGroupScopeError={{
                                    wkspcGroupScopeError,
                                    wkspcGroupInstanceError,
                                    clearError:
                                      clearCustomerWorkspaceScopeErrors
                                  }}
                                  setApiError={setApiError}
                                  isCCSRole={isCCSRole}
                                />
                                {mspScopeError && (
                                  <Box pad={{ top: 'small' }}>
                                    <Notification
                                      type="inline"
                                      message={mspScopeError}
                                      status="critical"
                                      testId="no-scope-selection-warning"
                                    />
                                  </Box>
                                )}
                              </>
                            ) : null}

                            {/* resource category selection */}
                            {roleGivenOrSelected && (
                              <Box width="medium">
                                {state?.scope === 'ALL_WORKSPACE_OPTION' && (
                                  <Typography
                                    type="text"
                                    testId="role-scoped-to-all-workspace-text"
                                  >
                                    {t(
                                      'iam:assign_roles_side_panel.select_all_resource_msg',
                                      {
                                        workspace: t(
                                          'common:business_object.wkspc'
                                        )
                                      }
                                    )}
                                  </Typography>
                                )}
                              </Box>
                            )}
                            {/* {roleGivenOrSelected &&
                              (state?.scope === 'RESOURCE_OPTION' ? (
                                <Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'auditlogs:audit_logs.service_manager'
                                      )}
                                      subtitle={t(
                                        'iam:assign_roles_side_panel.select_service_subtitle'
                                      )}
                                      value={state?.service?.value}
                                    />

                                    <FormField
                                      required
                                      error={serviceError}
                                      data-testid="service-select-form"
                                    >
                                      <Select
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        dropProps={{
                                          width: 'small'
                                        }}
                                        options={state?.serviceOptions}
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.service}
                                        onChange={({ option }) => {
                                          handleDispatch(option, 'service')
                                          if (option) setServiceError('')
                                        }}
                                        required
                                      />
                                    </FormField>
                                  </Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'iam:assign_roles_side_panel.select_region_title'
                                      )}
                                      subtitle={t(
                                        'iam:assign_roles_side_panel.select_region_subtitle'
                                      )}
                                    />
                                    <FormField
                                      required
                                      error={regionError}
                                      data-testid="region-select-form"
                                    >
                                      <Select
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        options={state?.regionOptions}
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.region}
                                        dropHeight="medium"
                                        onChange={({ option }) => {
                                          handleDispatch(option, 'region')
                                          if (option) setRegionError('')
                                        }}
                                        required
                                      />
                                    </FormField>
                                  </Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'iam:assign_roles_side_panel.select_resource_type_title'
                                      )}
                                      subtitle={t(
                                        'iam:assign_roles_side_panel.select_resource_type_subtitle'
                                      )}
                                    />
                                    <FormField
                                      required
                                      error={resourceTypeError}
                                      data-testid="resourceType-select-form"
                                    >
                                      <Dropdown
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        options={state?.resourceTypeOptions}
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.resourceType}
                                        onChange={({ option }) => {
                                          handleDispatch(option, 'resourceType')
                                          if (option) setResourceTypeError('')
                                        }}
                                        customRender={(resourceType) =>
                                          resourceTypeTemplate(resourceType)
                                        }
                                        testId="resourceType-dropdown"
                                        required
                                      />
                                    </FormField>
                                  </Box>
                                  {state?.resourceType && (
                                    <Box>
                                      <SelectContainer
                                        title={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                      />
                                      <FormField
                                        required
                                        error={resourceError}
                                        data-testid="resource-radio-btn-form"
                                      >
                                        <RadioButtonGroup
                                          name="resource-radio-btn-group"
                                          id="resource-radio-btn-group"
                                          value={state?.resource}
                                          options={resourceOptions()}
                                          labelKey="label"
                                          valueKey="value"
                                          onChange={(event) => {
                                            handleDispatch(
                                              event.target.value,
                                              'resource'
                                            )
                                            handleDispatch(
                                              [],
                                              'resourceInstances'
                                            )
                                            if (event.target.value)
                                              setResourceError('')
                                          }}
                                        />
                                      </FormField>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box width="medium">
                                  {state?.scope === 'ALL_WORKSPACE_OPTION' && (
                                    <Typography
                                      type="text"
                                      testId="role-scoped-to-all-workspace-text"
                                    >
                                      {t(
                                        'iam:assign_roles_side_panel.select_all_resource_msg',
                                        {
                                          workspace: t(
                                            'common:business_object.wkspc'
                                          )
                                        }
                                      )}
                                    </Typography>
                                  )}
                                </Box>
                              ))} */}
                            {/* resource instance selection */}
                            {roleGivenOrSelected &&
                              state?.scope === 'RESOURCE_OPTION' &&
                              (state?.resource === `SOME_RESOURCES_OPTION` ? (
                                <Box>
                                  {/* <SelectContainer
                                    title={t(
                                      'iam:assign_roles_side_panel.select_resource_instance_title'
                                    )}
                                    subtitle={t(
                                      'iam:assign_roles_side_panel.select_resource_instance_subtitle',
                                      {
                                        role: t('common:business_object.role')
                                      }
                                    )}
                                  />
                                  <FormField
                                    required
                                    error={resourceInstanceError}
                                    data-testid="resource-instance-multi-select-form"
                                  >
                                    <MultiSelectBox
                                      limit={multiSelectBoxLimit}
                                      placeholder={t(
                                        'iam:users.multi_select_box_placeholder'
                                      )}
                                      labelKey="label"
                                      valueKey="value"
                                      options={state.resourceInstanceOptions}
                                      dropHeight="medium"
                                      help={
                                        <Box
                                          data-testid="multiselect-help-description"
                                          direction="row"
                                          justify="between"
                                          flex={false}
                                          pad={{
                                            horizontal: 'xsmall',
                                            bottom: 'xsmall'
                                          }}
                                        >
                                          <Text>
                                            {t('iam:users.select_up_to_msg', {
                                              limit: multiSelectBoxLimit
                                            })}
                                          </Text>
                                        </Box>
                                      }
                                      onChange={(selectedArr) => {
                                        handleDispatch(
                                          selectedArr,
                                          'resourceInstances'
                                        )
                                        if (selectedArr)
                                          setResourceInstanceError('')
                                      }}
                                      testId="resource-instance-multi-select"
                                    >
                                      {resourceInstanceTemplate}
                                    </MultiSelectBox>
                                  </FormField> */}
                                </Box>
                              ) : (
                                state?.resource === `ALL_RESOURCES_OPTION` && (
                                  <Box width="medium">
                                    <Typography type="text">
                                      <Markdown>
                                        {t(
                                          'iam:assign_roles_side_panel.select_scope_with_all_resource_msg',
                                          {
                                            resourceType: resourceTypeName,
                                            region: state?.region?.label,
                                            service: state?.service?.label
                                          }
                                        )}
                                      </Markdown>
                                    </Typography>
                                  </Box>
                                )
                              ))}

                            {/* selection summary text */}
                            {roleGivenOrSelected &&
                              state?.scope === 'RESOURCE_OPTION' &&
                              state?.resource === `SOME_RESOURCES_OPTION` &&
                              state?.resourceInstances?.length > 0 &&
                              (state?.resourceInstances?.length === 1 ? (
                                <Box width="medium">
                                  <Typography type="text">
                                    <Markdown>
                                      {t(
                                        'iam:assign_roles_side_panel.select_one_scope_msg',
                                        {
                                          resourceType: resourceTypeName
                                        }
                                      )}
                                    </Markdown>
                                  </Typography>
                                </Box>
                              ) : (
                                <Box width="medium">
                                  <Typography type="text">
                                    <Markdown>
                                      {t(
                                        'iam:assign_roles_side_panel.select_specific_scope_msg',
                                        {
                                          totalSelected:
                                            state?.resourceInstances?.length,
                                          resourceType: resourceTypeName,
                                          region: state?.region?.label,
                                          service: state?.service?.label
                                        }
                                      )}
                                    </Markdown>
                                  </Typography>
                                </Box>
                              ))}

                            {roleGivenOrSelected &&
                              (state?.scope === 'SCOPE_GROUP_OPTION' ? (
                                <Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'iam:assign_roles_side_panel.select_scope_group_title'
                                      )}
                                      value={state?.service?.value}
                                    />
                                    <FormField
                                      required
                                      error={scopeGroupInstanceError}
                                      data-testid="scope-group-select-form"
                                    >
                                      <Dropdown
                                        id="scope-group-dropdown"
                                        testId="scope-group-dropdown"
                                        name="scope_group_instance"
                                        multiple={false}
                                        options={
                                          state?.scopeGroupInstanceOptions
                                        }
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.scopeGroupInstance}
                                        dropAlign={{ bottom: 'top' }} // Dropdown values comes on top the Select box
                                        required
                                        onMore={() => {
                                          if (offset < totalScopeGroups) {
                                            getScopeGroups()
                                          }
                                        }}
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        onChange={({ option }) => {
                                          handleDispatch(
                                            option,
                                            'scopeGroupInstance'
                                          )
                                          const selectedScopeGroupScopeLength =
                                            option?.value?.scopes?.length
                                          if (!selectedScopeGroupScopeLength) {
                                            setScopeGroupInstanceError(
                                              t(
                                                'iam:assign_roles_side_panel.select_scopegroup_with_no_scope_error_msg'
                                              )
                                            )
                                          } else if (option) {
                                            setScopeGroupInstanceError('')
                                          }
                                          setAlreadyExistingRoleError(false)
                                        }}
                                        customRender={(option) => (
                                          <DropdownCustomRender
                                            name={option.label}
                                            description={option.desc || ''}
                                          />
                                        )}
                                      />
                                    </FormField>
                                  </Box>
                                </Box>
                              ) : null)}
                            {!scopeGroupInstanceError &&
                              !isMSP() &&
                              state?.scopeGroupInstance &&
                              state?.scope !== 'ALL_WORKSPACE_OPTION' && (
                                <Box width="medium">
                                  <Typography type="text">
                                    <Markdown>
                                      {t(
                                        'iam:assign_roles_side_panel.select_a_scope_msg',
                                        {
                                          scopeType: scopeTypeName
                                        }
                                      )}
                                    </Markdown>
                                  </Typography>
                                </Box>
                              )}
                          </>
                        )}
                        {(roleAssignedLoading ||
                          loadingWhileCheckingFixedScopePerm) && (
                          <Box alignSelf="center" pad={{ top: 'small' }}>
                            <Loader testId="role-assigned-loader" />
                          </Box>
                        )}
                        {hasConflictingOneRoleAppRA &&
                          !roleAssignedLoading &&
                          !loadingWhileCheckingFixedScopePerm && (
                            <Box pad={{ top: 'small' }}>
                              <Notification
                                type="inline"
                                text={
                                  <Markdown>
                                    {t(
                                      preSelectedSubject?.subjectType === 'USER'
                                        ? 'iam:assign_roles_side_panel.one_role_assigned'
                                        : 'iam:assign_roles_side_panel.role_assigned_on_subject',
                                      {
                                        serviceName
                                      }
                                    )}
                                  </Markdown>
                                }
                                status="critical"
                                testId="one-role-app-warning"
                              />
                            </Box>
                          )}
                        {(alreadyExistingRoleError ||
                          precheckAlreadyExistingRoleError) && (
                          <Box pad={{ top: 'small' }}>
                            <Notification
                              type="inline"
                              text={
                                <Markdown>
                                  {!precheckAlreadyExistingRoleError
                                    ? t(
                                        'authz:409_error_messages.role_assignment_already_created_error'
                                      )
                                    : t(
                                        'iam:role_assignment.multi_scopes_role_assignment_already_created_error',
                                        {
                                          roleAssignment: t(
                                            'common:business_object.role_assignment'
                                          ),
                                          role: t('common:business_object.role')
                                        }
                                      )}
                                </Markdown>
                              }
                              status="critical"
                              testId="already-existing-role-warning"
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </CCSForm>
                )}
              </>
            }
            footer={
              <ModalFooter
                left={
                  <Box direction="column" gap="medium">
                    <Notification
                      status="info"
                      text={t(
                        'iam:assign_roles_side_panel.role_assignment_in_progress_msg',
                        {
                          roleAssignment: t(
                            'common:business_object.role_assignment_capitalized'
                          )
                        }
                      )}
                      type="inline"
                      testId="ra-change-warning"
                    />
                    <Box direction="row" gap="medium">
                      <Button
                        primary
                        label={t('common:assign')}
                        type="submit"
                        onClick={() => {
                          if (!hasConflictingOneRoleAppRA) {
                            handleAssignClick()
                          }
                        }}
                        testId="btn-assign"
                      />
                      <Button
                        label={t('common:cancel')}
                        type="reset"
                        onClick={handleDiscardChanges}
                        testId="btn-cancel"
                      />
                    </Box>
                  </Box>
                }
              />
            }
            onClose={() => setShowAssignRoleModal(false)}
            position="right"
            height="100%"
            width={{ min: 'full', max: 'medium' }}
            overflow="hidden"
            testId="assign-role-side-drawer"
          />
        )}
      </>
    </>
  )
}

MSPAssignRoleSideDrawer.propTypes = {
  /**
   * Pre-selected subject contains a 'string' subject type and a subject.
   */
  preSelectedSubject: PropTypes.object,

  /**
   * preselected role object
   */
  preSelectedRole: PropTypes.object,

  /**
   * Callback to indicate whether the modal should be opened or closed.
   */
  setShowAssignRoleModal: PropTypes.func.isRequired,

  /**
   * Callback to set the success or error status back to caller.
   */
  setStatusNotification: PropTypes.func,

  /**
   * Callback to pass role and scope array back to the caller when the MSPAssignRoleSideDrawer is called from another Modal or do do refresh
   */
  onAssign: PropTypes.func,

  /**
   * Application id of the role - needed to determine if one role application
   */
  appId: PropTypes.string,

  /**
   * role assigned to user
   */
  roleAssignments: PropTypes.array,

  /**
   * set active tab
   */
  setActiveTab: PropTypes.func
}

export default MSPAssignRoleSideDrawer
