// Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

export const CaseCreationContext = React.createContext()

export const CaseCreationContextProvider = ({ children }) => {
  const {
    oidcUser: { profile }
  } = useReactOidc()
  const loggedInUserContact = {
    name: `${profile.given_name} ${profile.family_name}`,
    firstName: profile.given_name,
    lastName: profile.family_name,
    email: profile.email,
    value: profile.given_name + profile.family_name + profile.email
  }
  const [contacts, setContacts] = useState([])
  const [selectedPrimaryContact, setSelectedPrimaryContact] =
    useState(loggedInUserContact)
  const [selectedAlternateContact, setSelectedAlternateContact] = useState({})
  const [serverSearchEnabled, setServerSearchEnabled] = useState(false)
  const [contactSearchFailed, setContactSearchFailed] = useState(false)
  const caseForm = {
    loggedInUserContact,
    contacts,
    setContacts,
    selectedPrimaryContact,
    setSelectedPrimaryContact,
    selectedAlternateContact,
    setSelectedAlternateContact,
    serverSearchEnabled,
    setServerSearchEnabled,
    contactSearchFailed,
    setContactSearchFailed
  }
  return (
    <CaseCreationContext.Provider value={caseForm}>
      {children}
    </CaseCreationContext.Provider>
  )
}
CaseCreationContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}
