// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

import {
  BACKUP_AND_RECOVERY,
  BLOCK_STORAGE,
  CENTRAL,
  CENTRAL_INTERNAL,
  DATASVS,
  // commented for GLCP-189423: removal for Data Fabric tile under workloads
  // DATA_FABRIC,
  DATA_OPS_MANAGER,
  DISASTER_RECOVERY,
  FILE_STORAGE,
  GLC,
  // commented for GLCP-200445: removal for HPC tile
  // HPC,
  HPECC,
  INFOSIGHT,
  // commented for GLCP-200445: removal for ML_OPS tile
  // ML_OPS,
  MSP,
  OPSRAMP,
  PRIVATE_CLOUD_BUSINESS,
  PRIVATE_CLOUD_ENTERPRISE,
  STANDALONE,
  SD,
  WELLNESS,
  UXI,
  CATEGORY,
  COM_PREPRODUCTION,
  PLATFORM_ANALYTICS,
  EDGE_CONNECT,
  MLDX_MS,
  PCAI,
  HCIPOC,
  HOC,
  PCSM,
  CONSUMPTION_ANALYTICS,
  SCDEVI,
  TENANT,
  SCAAS,
  ZCH,
  VCF
} from '../constants'
import { isGLOP, isStagingCluster } from '../../utils/feature-flag-utils'

import { Service } from './Service'

const {
  MANAGEMENT_AND_GOVERNANCE,
  COMPUTE,
  NETWORKING,
  PRIVATE_CLOUD: PRIVATE_CLOUD_CATEGORY,
  STORAGE,
  WORKLOADS,
  AI_ML_ANALYTICS
} = CATEGORY

const getStorageServiceManagerSlug = () => {
  if (isStagingCluster()) return SCDEVI
  return DATASVS
}

const getPCEworkspaceTypes = () => (isGLOP() ? [TENANT] : [STANDALONE])

const getHybridConsoleName = () =>
  isGLOP()
    ? 'Hybrid Operations Console - Disconnected'
    : 'Hybrid Operations Console'

const getDataServicesName = () =>
  isGLOP() ? 'Data Services Cloud Console' : 'Data Services'

const getLDServices = (LDFlags) => {
  const services =
    LDFlags['glcp-services-definition']?.services?.length > 0
      ? LDFlags['glcp-services-definition']?.services
      : [
          {
            name: 'Compute Ops Management',
            category: COMPUTE,
            serviceManagerSlug: HPECC,
            serviceSlug: HPECC,
            documentationUrl: 'https://www.hpe.com/info/com-gsg',
            quoteUrl:
              'https://buy.hpe.com/us/en/enterprise-solutions/greenlake-solutions/greenlake-for-compute/compute-ops-management/hpe-greenlake-for-compute-ops-management/p/1014368370',
            workspaceTypes: [STANDALONE, MSP]
          },
          {
            name: 'Call Home',
            category: COMPUTE,
            serviceManagerSlug: ZCH,
            serviceSlug: ZCH,
            documentationUrl: '',
            workspaceTypes: [STANDALONE],
            contactSalesUrl: ''
          },
          {
            name: 'Compute Ops Management Pre-Production',
            category: COMPUTE,
            serviceManagerSlug: COM_PREPRODUCTION,
            serviceSlug: COM_PREPRODUCTION
          },
          {
            name: 'Machine Learning Development Environment Software',
            category: AI_ML_ANALYTICS,
            serviceManagerSlug: MLDX_MS,
            serviceSlug: MLDX_MS,
            documentationUrl: 'https://mldes.ext.hpe.com/docs/index.html',
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html',
            workspaceTypes: [STANDALONE],
            quoteUrl:
              'https://buy.hpe.com/us/en/software/cray-software/cray-software/cray-software/hpe-machine-learning-development-environment-5%E2%80%91year-subscription-e%E2%80%91rtu/p/r9y52aae'
          },
          {
            name: 'Supercomputing as a Service',
            category: AI_ML_ANALYTICS,
            serviceManagerSlug: SCAAS,
            serviceSlug: SCAAS,
            documentationUrl: '',
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html',
            workspaceTypes: [STANDALONE],
            quoteUrl: ''
          },
          {
            name: 'HPE Aruba Networking Central',
            category: NETWORKING,
            serviceManagerSlug: CENTRAL,
            serviceSlug: CENTRAL,
            documentationUrl:
              'https://www.arubanetworks.com/techdocs/central/latest/content/home.htm',
            workspaceTypes: [STANDALONE, MSP]
          },
          {
            name: 'HPE Aruba Networking Central Internal',
            category: NETWORKING,
            serviceManagerSlug: CENTRAL_INTERNAL,
            serviceSlug: CENTRAL_INTERNAL,
            documentationUrl:
              'https://www.arubanetworks.com/techdocs/central/latest/content/home.htm',
            workspaceTypes: [STANDALONE, MSP]
          },
          {
            name: getDataServicesName(),
            category: STORAGE,
            serviceManagerSlug: DATASVS,
            serviceSlug: DATASVS
          },
          {
            name: 'HPE GreenLake Flex Solutions',
            category: WORKLOADS,
            serviceManagerSlug: GLC,
            serviceSlug: GLC,
            testDriveUrl: '',
            documentationUrl:
              'https://www.hpe.com/us/en/greenlake/services.html'
          },
          // commented for GLCP-200445: removal for ML Ops tile
          // {
          //  name: 'ML Ops',
          //  category: WORKLOADS,
          //  serviceManagerSlug: GLC,
          //  serviceSlug: ML_OPS,
          //  testDriveUrl: ''
          // },
          {
            name: 'Private Cloud Enterprise',
            category: PRIVATE_CLOUD_CATEGORY,
            /**
             * For users with PCE account type, PCE should show up as a service manager (provisionable/launchable)
             * For users with standalone account type, PCE should show up a service under GLC (non-provisionable/launchable)
             * This logic will be moved to back end, projected by the end of Helsinki PI
             */
            serviceManagerSlug: isGLOP() ? PRIVATE_CLOUD_ENTERPRISE : GLC,
            serviceSlug: PRIVATE_CLOUD_ENTERPRISE,
            documentationUrl:
              'https://www.hpe.com/info/HPE-GreenLake-Private-Cloud-Enterprise',
            workspaceTypes: getPCEworkspaceTypes()
          },
          // commented for GLCP-200445: removal for HPC tile
          // {
          //   name: 'High Performance Computing',
          //   category: WORKLOADS,
          //   serviceManagerSlug: GLC,
          //   serviceSlug: HPC,
          //   testDriveUrl: ''
          // },
          // commented for GLCP-189423: removal for Data Fabric tile under workloads
          // {
          //   name: 'Data Fabric',
          //   category: WORKLOADS,
          //   serviceManagerSlug: GLC,
          //   serviceSlug: DATA_FABRIC,
          //   testDriveUrl: ''
          // },
          {
            name: 'Backup and Recovery',
            category: STORAGE,
            serviceManagerSlug: getStorageServiceManagerSlug(),
            featuresSupported: ['DEEP_LINKING'],
            serviceSlug: BACKUP_AND_RECOVERY,
            documentationUrl:
              'https://support.hpe.com/hpesc/public/docDisplay?docLocale=en_US&docId=a50004269enw',
            quoteUrl:
              'https://buy.hpe.com/us/en/storage/storage-cloud/storage-saas/data-services/hpe-greenlake-for-backup-recovery/p/1014420488'
          },
          {
            name: 'Block Storage',
            category: STORAGE,
            serviceManagerSlug: getStorageServiceManagerSlug(),
            featuresSupported: ['DEEP_LINKING'],
            serviceSlug: BLOCK_STORAGE
          },
          {
            name: 'Data Ops Manager',
            category: STORAGE,
            serviceManagerSlug: getStorageServiceManagerSlug(),
            featuresSupported: ['DEEP_LINKING'],
            serviceSlug: DATA_OPS_MANAGER
          },
          {
            name: 'File Storage',
            category: STORAGE,
            serviceManagerSlug: getStorageServiceManagerSlug(),
            featuresSupported: ['DEEP_LINKING'],
            serviceSlug: FILE_STORAGE
          },
          {
            name: 'Disaster Recovery',
            category: STORAGE,
            serviceManagerSlug: getStorageServiceManagerSlug(),
            featuresSupported: ['DEEP_LINKING'],
            serviceSlug: DISASTER_RECOVERY,
            quoteUrl:
              'https://buy.hpe.com/us/en/as-a-service/private-hybrid-cloud-saas/data-protection-saas/data-protection/hpe-greenlake-for-disaster-recovery/p/1014691269',
            documentationUrl:
              'https://support.hpe.com/hpesc/public/docDisplay?docId=a50004305enw&docLocale=en_US'
          },
          {
            name: 'Private Cloud Business Edition',
            category: PRIVATE_CLOUD_CATEGORY,
            serviceManagerSlug: getStorageServiceManagerSlug(),
            featuresSupported: ['DEEP_LINKING'],
            serviceSlug: PRIVATE_CLOUD_BUSINESS
          },
          {
            name: 'Wellness Dashboard',
            category: MANAGEMENT_AND_GOVERNANCE,
            serviceSlug: WELLNESS,
            documentationUrl:
              'https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us',
            workspaceTypes: [STANDALONE]
          },
          {
            name: 'HPE Sustainability Insight Center',
            category: MANAGEMENT_AND_GOVERNANCE,
            serviceManagerSlug: SD,
            serviceSlug: SD,
            documentationUrl:
              'https://www.hpe.com/greenlake/HPE-Sustainability-Insight-Center',
            regions: ['us-west'], // defaulting to us-west for now - needs validation
            featuresSupported: ['HIDE_VIEW_ASSIGNED_DEVICES_BUTTON']
          },
          {
            name: 'HPE InfoSight',
            category: MANAGEMENT_AND_GOVERNANCE,
            serviceSlug: INFOSIGHT,
            regions: ['us-west'] // defaulting to us-west for now - needs validation
          },
          {
            name: 'OpsRamp',
            category: MANAGEMENT_AND_GOVERNANCE,
            serviceSlug: OPSRAMP,
            serviceManagerSlug: LDFlags['glcp-ops-ramp'] ? OPSRAMP : undefined,
            contactSalesUrl:
              'https://www.opsramp.com/about-opsramp/contact-us/',
            documentationUrl: 'https://docs.opsramp.com/',
            bypassStaticRedirectUrl: LDFlags['glcp-ops-ramp'],
            regions: ['us-west'] // defaulting to us-west for now - needs validation
          },
          {
            name: 'User Experience Insight',
            category: NETWORKING,
            serviceManagerSlug: UXI,
            serviceSlug: UXI,
            documentationUrl: 'https://help.capenetworks.com/en/',
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html'
          },
          {
            name: 'EdgeConnect Orchestrator',
            category: NETWORKING,
            serviceSlug: EDGE_CONNECT,
            serviceManagerSlug: EDGE_CONNECT,
            documentationUrl: 'https://www.arubanetworks.com/techdocs/sdwan/',
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html',
            workspaceTypes: [STANDALONE]
          },
          {
            name: 'Platform Analytics',
            category: MANAGEMENT_AND_GOVERNANCE,
            serviceSlug: PLATFORM_ANALYTICS,
            serviceManagerSlug: PLATFORM_ANALYTICS,
            documentationUrl: 'https://superset.apache.org/docs/intro'
          },
          {
            name: 'Private Cloud AI',
            category: PRIVATE_CLOUD_CATEGORY,
            serviceSlug: PCAI,
            serviceManagerSlug: DATASVS,
            featuresSupported: ['DEEP_LINKING']
          },
          {
            name: 'VMware Cloud Foundation, Managed for you',
            category: WORKLOADS,
            serviceSlug: VCF,
            serviceManagerSlug: isStagingCluster() ? HCIPOC : DATASVS,
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html',
            featuresSupported: ['DEEP_LINKING'],
            workspaceTypes: [STANDALONE]
          },
          {
            name: 'HCIPOC',
            category: MANAGEMENT_AND_GOVERNANCE,
            serviceSlug: HCIPOC,
            serviceManagerSlug: HCIPOC,
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html',
            workspaceTypes: [STANDALONE]
          },
          {
            name: getHybridConsoleName(),
            category: PRIVATE_CLOUD_CATEGORY,
            serviceSlug: HOC,
            serviceManagerSlug: HOC,
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html',
            workspaceTypes: [STANDALONE, MSP]
          },
          {
            name: 'Private Cloud Service Manager',
            category: PRIVATE_CLOUD_CATEGORY,
            serviceSlug: PCSM,
            serviceManagerSlug: PCSM,
            contactSalesUrl: 'https://www.hpe.com/us/en/contact-hpe.html',
            workspaceTypes: [STANDALONE, MSP]
          },
          {
            name: 'Consumption Analytics',
            category: MANAGEMENT_AND_GOVERNANCE,
            serviceSlug: CONSUMPTION_ANALYTICS,
            serviceManagerSlug: CONSUMPTION_ANALYTICS,
            documentationUrl:
              'https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us',
            featuresSupported: ['HIDE_VIEW_ASSIGNED_DEVICES_BUTTON']
          },
          {
            name: 'SCINT',
            category: STORAGE,
            serviceManagerSlug: SCDEVI,
            serviceSlug: SCDEVI
          }
        ]
  return services
}

const getServiceDefinitions = (LDFlags) => {
  const services = getLDServices(LDFlags)
  return services.map((serviceDefinition) => new Service(serviceDefinition))
}

export default getServiceDefinitions
