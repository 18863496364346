// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box, Grid, Accordion, AccordionPanel, Grommet } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import { Typography } from '../../components'

import { getDetails, getHistoryDetails } from './utils'
import {
  adminColsDisplayed,
  adminColsDisplayedEdit,
  userHistoryColsDisplayed,
  historyColumns
} from './constants'

const UpdateHistoryDetails = ({
  historyDetails,
  isUserView,
  updateHistory,
  originalNoti
}) => {
  const { t } = useTranslation(['manage'])

  const LDFlags = useFlags()

  const adminColumns = LDFlags['glcp-notifications-feature-edit-istanbul']
    ? adminColsDisplayedEdit
    : adminColsDisplayed

  const userColumns = userHistoryColsDisplayed

  const customAccordionTheme = {
    accordion: {
      border: undefined,
      icons: {
        color: 'black'
      }
    }
  }

  return (
    <Box
      pad={{ horizontal: 'xlarge', top: 'xsmall', right: 'xsmall' }}
      width="large"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box
        pad={{ top: 'small' }}
        border={{ side: 'top', color: 'border-weak', size: 'small' }}
        width="large"
        margin={{ bottom: 'medium' }}
      >
        <Typography level="2" type="heading" weight="bold" testId="page-title">
          {t('notification.history')}
        </Typography>
      </Box>
      {historyDetails?.history?.length > 0 ? (
        <>
          <Box
            fill
            direction="column"
            pad={{ bottom: 'xsmall', top: 'xsmall' }}
            width="large"
          >
            {updateHistory?.map((historyData) => {
              return (
                <Box
                  margin={{ bottom: 'medium' }}
                  justify="between"
                  direction="row"
                >
                  <Box>
                    <Typography
                      size="large"
                      type="text"
                      testId="page-title"
                      margin={{ bottom: 'xsmall' }}
                    >
                      {!isUserView
                        ? t('notification.updated_by', {
                            owner: historyData?.updatedBy
                          })
                        : t('notification.updated')}
                    </Typography>

                    <Grommet theme={customAccordionTheme}>
                      <Accordion animate multiple>
                        <AccordionPanel
                          label={t('notification.showMore')}
                          border={undefined}
                          key={historyData?.historyID}
                          id={historyData?.historyID}
                        >
                          <Box>
                            <Grid
                              gap={{ row: 'small', column: 'small' }}
                              fill="horizontal"
                              columns={['small', 'auto']}
                              pad={{ top: 'small' }}
                            >
                              {getHistoryDetails(
                                t,
                                historyData?.updated_params || [],
                                historyColumns,
                                LDFlags[
                                  'glcp-notifications-feature-glasgow-admin'
                                ],
                                isUserView
                              )}
                            </Grid>
                          </Box>
                        </AccordionPanel>
                      </Accordion>
                    </Grommet>
                  </Box>
                  <Typography
                    size="medium"
                    type="text"
                    testId="page-title"
                    margin={{ bottom: 'xsmall', top: 'small' }}
                  >
                    {`${dayjs(historyData?.updated_at * 1000).format(
                      'MM/DD/YYYY hh:mm:ss A'
                    )}`}
                  </Typography>
                </Box>
              )
            })}
          </Box>

          <Box
            fill
            direction="row"
            pad={{ bottom: 'small', top: 'xsmall' }}
            margin={{ bottom: 'medium' }}
            width="large"
            justify="between"
          >
            <Box>
              <Typography
                size="large"
                type="text"
                testId="page-title"
                margin={{ bottom: 'xsmall' }}
              >
                {!isUserView
                  ? t('notification.created_by', {
                      owner: originalNoti?.notification_details?.createdBy
                    })
                  : t('notification.created')}
              </Typography>
              <Grommet theme={customAccordionTheme}>
                <Accordion>
                  <AccordionPanel
                    key={originalNoti?.historyID}
                    label={t('notification.showMore')}
                    data-testid="create-noti-history-display"
                    id={originalNoti?.historyID}
                  >
                    <Box height="large">
                      <Grid
                        gap={{ row: 'small', column: 'small' }}
                        fill="horizontal"
                        columns={['small', 'auto']}
                        pad={{ top: 'small' }}
                      >
                        {isUserView
                          ? getDetails(
                              t,
                              originalNoti?.notification_details || [],
                              userColumns,
                              LDFlags[
                                'glcp-notifications-feature-glasgow-admin'
                              ],
                              LDFlags['glcp-notifications-account-role-target'],
                              isUserView
                            )
                          : getDetails(
                              t,
                              originalNoti?.notification_details || [],
                              adminColumns,
                              LDFlags[
                                'glcp-notifications-feature-glasgow-admin'
                              ],
                              LDFlags['glcp-notifications-account-role-target'],
                              isUserView
                            )}
                      </Grid>
                    </Box>
                  </AccordionPanel>
                </Accordion>
              </Grommet>
            </Box>
            <Typography
              size="medium"
              type="text"
              testId="page-title"
              margin={{ bottom: 'xsmall', top: 'small' }}
            >
              {`${dayjs(
                originalNoti?.notification_details?.createdAt * 1000
              ).format('MM/DD/YYYY hh:mm:ss A')}`}
            </Typography>
          </Box>
        </>
      ) : (
        <Typography
          level="2"
          type="text"
          margin={{ top: 'small' }}
          testId="page-title"
        >
          --
        </Typography>
      )}
    </Box>
  )
}

UpdateHistoryDetails.prototypes = {
  notificationDetails: PropTypes.object
}

export default UpdateHistoryDetails
