// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect } from 'react'
import { Box } from 'grommet'
import { ChatOption, DocumentText, Video } from 'grommet-icons'
import PropTypes from 'prop-types'
import { buildInteractiveInstantResult } from '@coveo/headless'

import { Typography } from '../../../../../components'

import SuggestionContainer from './SuggestionContainer'

const TopHits = ({ result, index, engine, t }) => {
  const interactiveResult = buildInteractiveInstantResult(engine, {
    options: { result }
  })
  const contenttype = result?.raw?.contenttype

  useEffect(
    () => () => interactiveResult.cancelPendingSelect(),
    [interactiveResult]
  )

  const truncanateText = (text, maxLength) => {
    if (!text) return ''
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
  }

  return (
    <SuggestionContainer>
      <Box onClick={() => interactiveResult.select()}>
        <Box direction="column" gap="small">
          {index === 0 && (
            <Typography type="text" size="xsmall">
              {t('top_hits')}
            </Typography>
          )}
          <Box direction="row" gap="small">
            <Box pad={{ top: 'xsmall', right: 'small' }}>
              {contenttype === 'Documents' && <DocumentText />}
              {contenttype === 'community-forums' && <ChatOption />}
              {contenttype === 'Videos' && <Video />}
            </Box>

            <Box>
              <Typography type="text" weight={500}>
                {result.title}
              </Typography>
              <Typography type="text">
                {contenttype === 'Videos'
                  ? truncanateText(result?.raw?.kmvideodescription, 125)
                  : truncanateText(result.excerpt, 125)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </SuggestionContainer>
  )
}

TopHits.propTypes = {
  result: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  engine: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default TopHits
