// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useRef } from 'react'
import { Box, Image, Carousel, Grommet, Markdown } from 'grommet'
import PropTypes from 'prop-types'
import { StatusGoodSmall } from 'grommet-icons'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { deepMerge } from 'grommet/utils'
import { hpe } from 'grommet-theme-hpe'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ModalHeader,
  Anchor,
  Button
} from '../../../components'
import { put } from '../../../utils/api-utils'
import {
  WHATS_NEW_ALLOWED_IMAGE_TYPES,
  WHATS_NEW_ALLOWED_VIDEO_TYPE
} from '../../../utils/validation-utils'
import postBackground from '../../../images/BackgroundGradientBrand.png'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import VideoComponent from './VideoComponent'
import DisableAnnouncement from './DisableAnnouncement'

const panelTheme = deepMerge(hpe, {
  carousel: {
    icons: {
      current: StatusGoodSmall,
      width: '3px'
    }
  },
  icon: {
    size: {
      medium: '10px'
    }
  }
})

const PostModel = ({ Posts, closeModel }) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()

  const [activeSlide, setActiveSlide] = useState(0)
  const [showDisableModal, setShowDisableModal] = useState(false)
  const currentVideoPlaying = useRef()
  const LDFlags = useFlags()

  const handlePostRead = ({ Isclose }) => {
    sessionStorage.setItem('NewPostModel', true)
    const postRequestBody = { lastVisitedBlogId: Posts[0].blogID }

    put(
      '/whatsnew/v1/dismiss/dashboard/blogs',
      postRequestBody,
      oidcUser.access_token,
      {
        'x-request-id': uuidv4()
      }
    ).then(() => {
      if (!Isclose) {
        navigate('/whats-new')
      }
    })
  }

  return (
    <VisibilityWrapper hideFor={{ feature: 'glcp-whatsnew-istanbul' }}>
      <Box>
        <ModalDialog
          width="large"
          height="large"
          testId="whats-new-post-model"
          position="center"
          onClose={() => {}}
          background={{
            image: `url(${postBackground})`,
            size: '100% 50%',
            color: 'white',
            position: 'top center'
          }}
          header={
            <ModalHeader
              onClose={() => {
                handlePostRead({ Isclose: true })
                closeModel()
              }}
            />
          }
          content={
            <>
              <Box
                pad={{ horizontal: 'small', top: 'xsmall' }}
                height="100%"
                width="100%"
              >
                <Box
                  pad={{ horizontal: 'small' }}
                  gap="small"
                  data-testid="whats-new-model-box"
                  direction="row"
                  width="100%"
                  height="100%"
                >
                  <Box
                    height="100%"
                    justify="end"
                    width={{ min: 'xxsmall' }}
                    pad={{ bottom: 'small' }}
                  >
                    {activeSlide !== 0 && (
                      <Button
                        label={t('back')}
                        testId="back-btn"
                        alignSelf="center"
                        onClick={() => {
                          setActiveSlide(activeSlide - 1)
                        }}
                      />
                    )}
                  </Box>
                  <Box pad={{ top: 'xsmall' }} width="100%" height="100%">
                    <Grommet
                      data-testid="theme"
                      theme={panelTheme}
                      background="unset"
                      style={{ height: '100%', width: '100%' }}
                    >
                      <Box align="center" justify="end" fill>
                        <Carousel
                          fill
                          controls={
                            Posts.length - 1 === 0 ? false : 'selectors'
                          }
                          activeChild={activeSlide}
                          onChild={setActiveSlide}
                          margin={{ bottom: 'small', top: 'medium' }}
                        >
                          {Posts.map((item, index) => (
                            <Box
                              direction="column"
                              key={item}
                              pad={{
                                horizontal: 'small',
                                top: 'small',
                                bottom: 'xsmall'
                              }}
                              align="end"
                              fill
                            >
                              <Box
                                key={item}
                                data-testid={`whats-new-media-blog-${index}`}
                                justify="end"
                                fill
                              >
                                {WHATS_NEW_ALLOWED_IMAGE_TYPES.includes(
                                  item?.mediaType
                                ) && (
                                  <Box data-testid="image-div" justify="end">
                                    <Image
                                      fill="vertically"
                                      alignSelf="end"
                                      src={item?.media}
                                      key={item}
                                    />
                                  </Box>
                                )}
                                {WHATS_NEW_ALLOWED_VIDEO_TYPE.includes(
                                  item?.mediaType
                                ) && (
                                  <Box data-testid="video-div" justify="end">
                                    <VideoComponent
                                      item={item}
                                      currentVideoPlaying={currentVideoPlaying}
                                      activeSlide={activeSlide}
                                    />
                                  </Box>
                                )}
                              </Box>
                              <Box
                                align="center"
                                gap="medium"
                                fill
                                data-testid={`whats-new-content-blog-${index}`}
                              >
                                <Box pad={{ top: 'medium' }}>
                                  <Typography
                                    level="2"
                                    type="heading"
                                    textAlign="center"
                                    key={item}
                                    testId={`whats-new-title-${index}`}
                                  >
                                    {item?.title}
                                  </Typography>
                                </Box>
                                <Box pad={{ bottom: 'medium' }} align="center">
                                  <Typography
                                    type="text"
                                    size="medium"
                                    testId={`whats-new-summary-${index}`}
                                    key={item}
                                    textAlign="left"
                                  >
                                    {LDFlags['glcp-whats-new-rich-text'] ? (
                                      <Markdown>{item?.summary}</Markdown>
                                    ) : (
                                      item?.summary
                                    )}
                                  </Typography>
                                  <Anchor
                                    key={item}
                                    testId={`whats-new-learn-more-${index}`}
                                    label={t(
                                      'whats_new.create_post_form.learn_more'
                                    )}
                                    onClick={() =>
                                      handlePostRead({ Isclose: false })
                                    }
                                  />
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Carousel>
                      </Box>
                    </Grommet>
                  </Box>

                  <Box
                    height="100%"
                    justify="end"
                    width={{ min: 'xxsmall' }}
                    pad={{ bottom: 'small' }}
                  >
                    {Posts.length - 1 !== 0 && (
                      <Button
                        primary
                        label={
                          activeSlide !== Posts.length - 1
                            ? t('notification.next_btn_label')
                            : t('done')
                        }
                        testId="next-done-btn"
                        alignSelf="center"
                        onClick={() => {
                          setActiveSlide(activeSlide + 1)

                          if (activeSlide === Posts.length - 1) {
                            handlePostRead({ Isclose: true })
                            closeModel()
                          }
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          }
          footer={
            <Box
              pad={{ horizontal: 'large', top: 'small' }}
              background="white"
              align="center"
              alignContent="baseline"
              border={{ side: 'top', color: 'border-weak' }}
            >
              <Anchor
                label={t('whats_new.create_post_form.dont_show_message')}
                color="text-weak"
                size="xsmall"
                testId="whats-new-dont-show-msg"
                onClick={() => {
                  setShowDisableModal(true)
                }}
              />
            </Box>
          }
        />
      </Box>
      {showDisableModal && (
        <DisableAnnouncement
          closeAnnouncementModal={() => {
            setShowDisableModal(false)
          }}
          closeModel={() => closeModel()}
          lastVisitedBlogId={Posts[0].blogID}
        />
      )}
    </VisibilityWrapper>
  )
}
PostModel.propTypes = {
  Posts: PropTypes.array.isRequired,
  closeModel: PropTypes.func.isRequired
}

export { PostModel }
