// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, RadioButtonGroup } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ButtonGroup
} from '../../components'
import { ProgressModal } from '../device-management'

import { AddDeviceByCloudModalCommon } from './AddDeviceByCloudModalCommon'

const AddDevicesModalCommon = ({
  setAddDevicesModal,
  addDevicesModal,
  refreshDataTable = () => {},
  isCCSManager = false,
  customerId = undefined,
  isActivateCustomer = false
}) => {
  const { t } = useTranslation(['device'])
  const addingMethods = [
    {
      value: 'cak',
      label: t('cloud_activation_and_mac_address')
    }
  ]
  const [addingMethod, setAddingMethod] = useState('cak')
  const [addDeviceInputModal, setAddDeviceInputModal] = useState(false)
  const [showProgressModal, setProgressModal] = useState(false)

  const handleContinue = () => {
    setAddDevicesModal(false)
    setAddDeviceInputModal(true)
  }

  const openSuccessModal = () => {
    setProgressModal(true)
  }

  const refreshModal = () => {
    setAddingMethod('cak')
  }

  const renderModal = () => {
    switch (addingMethod) {
      case 'cak':
        return (
          <AddDeviceByCloudModalCommon
            setModal={setAddDeviceInputModal}
            openSuccessModal={openSuccessModal}
            refreshModal={refreshModal}
            isCCSManager={isCCSManager}
            customerId={customerId}
            isActivateCustomer={isActivateCustomer}
          />
        )
      default:
        return null
    }
  }
  const handleCancel = () => {
    setAddDevicesModal(false)
    refreshModal()
  }

  return (
    <Box>
      {addDevicesModal && (
        <ModalDialog
          width="medium"
          testId="add-devices-modal-dialog"
          content={
            <Box style={{ overflow: 'auto' }}>
              <Box gap="xsmall">
                <Typography type="heading" testId="add-devices-title">
                  {t('add_devices')}
                </Typography>
                <Typography
                  type="paragraph"
                  size="small"
                  testId="add-devices-subtitle"
                >
                  {t('add_devices_subtitle')}
                </Typography>
              </Box>
              <Box
                margin={{ top: 'medium' }}
                border={{ side: 'all', color: 'border-weak' }}
                round="xsmall"
              >
                <RadioButtonGroup
                  name="add-device-radio-option"
                  options={addingMethods}
                  value={addingMethod}
                  onChange={(event) => setAddingMethod(event.target.value)}
                  data-testid="add-device-radio-option"
                />
              </Box>
            </Box>
          }
          footer={
            <ModalFooter
              right={
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('cancel'),
                      default: true,
                      testId: 'cancel-btn',
                      onClick: handleCancel
                    },
                    {
                      id: 1,
                      label: t('continue'),
                      primary: true,
                      testId: 'continue-btn',
                      onClick: handleContinue
                    }
                  ]}
                  testId="two-buttons"
                />
              }
            />
          }
          onClose={() => setAddDevicesModal(false)}
        />
      )}
      {addDeviceInputModal && renderModal()}
      {showProgressModal && (
        <ProgressModal
          onSetOpen={() => setProgressModal(true)}
          title={t('devices_added')}
          onCloseModal={() => {
            setProgressModal(false)
            refreshDataTable()
          }}
        />
      )}
    </Box>
  )
}

AddDevicesModalCommon.propTypes = {
  setAddDevicesModal: PropTypes.func.isRequired,
  addDevicesModal: PropTypes.bool.isRequired,
  refreshDataTable: PropTypes.func,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string,
  isActivateCustomer: PropTypes.bool
}

export { AddDevicesModalCommon }
