// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Grid, ResponsiveContext } from 'grommet'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Card, Typography } from '../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { RecommendedCardData } from './recommended-card-data'

export const Recommended = () => {
  const { t } = useTranslation(['common', 'manage'])
  const size = useContext(ResponsiveContext)
  const navigate = useNavigate()

  const grid = {
    columns: {
      medium: ['auto', 'auto']
    }
  }
  const recommendations = RecommendedCardData()

  const handleNonNativeCardClick = (routeTo, hrefTo) => {
    if (routeTo) {
      navigate(routeTo)
    } else if (hrefTo) {
      window.open(hrefTo, '_blank')
    }
  }

  return (
    <Box>
      <VisibilityWrapper hideFor={{ deployment: ['COP'] }}>
        <Typography
          type="heading"
          level="2"
          margin={{ bottom: 'medium', top: 'none' }}
          testId="recommended-header"
        >
          {t('recommended_for_you')}
        </Typography>
        <Grid columns={grid.columns[size] || 'auto'} gap="medium">
          {recommendations &&
            recommendations.map(
              (item) =>
                !item.hidden && (
                  <VisibilityWrapper
                    key={item.id}
                    hideFor={item.hideFor}
                    rbac={item.rbac}
                  >
                    <Card
                      key={item.id}
                      action={
                        <Button
                          color={item.actionBtnColor}
                          label={t(item.actionBtnLbl)}
                          primary={Boolean(item.actionBtnColor)}
                          secondary={!item.actionBtnColor}
                          icon={item.actionBtnIcon}
                          reverse
                          size="medium"
                          type="button"
                          testId={`${item.testId}-btn`}
                          onClick={() =>
                            handleNonNativeCardClick(item.routeTo, item.hrefTo)
                          }
                          target={item.target}
                        />
                      }
                      foregroundColor={item.foreground}
                      background={item.background}
                      border={item.border}
                      elevation={item.elevation}
                      cardWidth="100%"
                      description={t(item.description)}
                      icon={item.icon}
                      testId={item.testId}
                      pretitle={t(item.pretitle)}
                      title={
                        <Typography
                          emphasis
                          type="heading"
                          level="3"
                          testId={`${item.testId}-title`}
                        >
                          {t(item.title)}
                        </Typography>
                      }
                      subtitle={item.subtitle}
                    />
                  </VisibilityWrapper>
                )
            )}
        </Grid>
      </VisibilityWrapper>
    </Box>
  )
}
