// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, FormField, TextInput } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  CCSForm,
  ModalDialog,
  ModalFooter,
  Typography
} from '../../components'
import {
  validateMACAddress,
  validateCloudActivationKey
} from '../../utils/validation-utils'
import { post } from '../../utils/api-utils'
import { formatMAC } from '../../utils/dm-sm-common-utils'
import { getApiErrorMessage } from '../../utils/error-handling-utils'

const AddDeviceByCloudModalCommon = ({
  setModal,
  openSuccessModal,
  refreshModal,
  isCCSManager = false,
  customerId = undefined,
  isActivateCustomer = false
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [cloudActivationKey, setCloudActivationKey] = useState('')
  const [macAddress, setMacAddress] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const addToDeviceList = () => {
    const requestBody = {
      devices: [
        {
          cloud_activation_key: cloudActivationKey,
          mac_address: macAddress,
          app_category: 'NETWORK'
        }
      ],
      ...(isCCSManager && { platform_customer_id: customerId })
    }
    let url = ''
    if (isCCSManager) {
      url = '/support-assistant/v1alpha1/devices'
    } else if (isActivateCustomer) {
      url = '/ui-doorway/ui/v1/activate/devices'
    } else {
      url = '/ui-doorway/ui/v1/devices'
    }
    post(url, requestBody, oidcUser.access_token).then(
      () => {
        setModal(false)
        openSuccessModal()
        refreshModal()
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }

  const handleAddButtonClick = () => {
    const cloudActivationKeyValidation =
      validateCloudActivationKey(cloudActivationKey)
    const macAddressValidation = validateMACAddress(macAddress)
    if (cloudActivationKeyValidation && macAddressValidation) {
      setErrorMessage('')
      addToDeviceList()
    } else if (!cloudActivationKeyValidation && !macAddressValidation)
      setErrorMessage(t('invalid_cloud_activation_key_and_mac_add'))
    else if (cloudActivationKeyValidation)
      setErrorMessage(t('invalid_mac_address'))
    else setErrorMessage(t('invalid_cloud_activation_key'))
  }

  const formatAndSetMAC = (curr, key) => {
    setMacAddress(formatMAC(macAddress, curr, key))
  }

  return (
    <ModalDialog
      content={
        <Box>
          <Typography
            size="medium"
            type="text"
            testId="add-device-by-cloud-description-1"
          >
            {t('add_devices')}
          </Typography>
          <Typography
            level="1"
            type="heading"
            testId="add-device-by-cloud-title"
          >
            {t('cloud_activation_key_and_mac_address')}
          </Typography>
          <Typography
            size="medium"
            type="text"
            testId="add-device-by-cloud-description-2"
            margin={{ top: 'xsmall' }}
          >
            {t('cloud_activation_key_and_mac_address_desc')}
          </Typography>

          <CCSForm
            errorMessage={errorMessage}
            testId="add-device-by-cloud-form"
          >
            <Box
              margin={{ top: 'medium', bottom: 'xxsmall' }}
              direction="column"
              justify="between"
              gap="small"
            >
              <FormField
                data-testid="cloud-activation-key-form-field"
                label={t('cloud_activation_key')}
                name="cloud_activation_key"
                width="medium"
              >
                <TextInput
                  data-testid="cloud-activation-key-input"
                  name="cloud_activation_key"
                  placeholder="12345678910"
                  value={cloudActivationKey}
                  onChange={(event) =>
                    setCloudActivationKey(event.target.value.trim())
                  }
                />
              </FormField>
              <FormField
                data-testid="mac-address-form-field"
                label={t('mac_address')}
                name="mac_address"
                width="medium"
              >
                <TextInput
                  data-testid="mac-address-input"
                  name="mac_address"
                  placeholder="00:00:00:00:00:00"
                  value={macAddress}
                  onChange={(event) =>
                    formatAndSetMAC(
                      event.target.value.trim(),
                      event.nativeEvent.data
                    )
                  }
                />
              </FormField>
            </Box>
          </CCSForm>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setModal(false)
                    refreshModal()
                  }
                },
                {
                  id: 1,
                  label: t('add'),
                  primary: true,
                  testId: 'add-button',
                  onClick: handleAddButtonClick
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      testId="add-device-by-cloud-modal-dialog"
      onClose={() => setModal(false)}
      width="medium"
    />
  )
}

AddDeviceByCloudModalCommon.propTypes = {
  setModal: PropTypes.func.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  refreshModal: PropTypes.func.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string,
  isActivateCustomer: PropTypes.bool
}

export { AddDeviceByCloudModalCommon }
