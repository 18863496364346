// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'

export const getRoles = (roles, t) => {
  return roles.map((role, idx) => {
    role.type = role.predefined
      ? t('authz:roles.build_in')
      : t('authz:roles.custom')
    role.last_updated = role.updated_at
      ? dayjs().to(new Date(role.updated_at * 1000))
      : dayjs().to(new Date(role.created_at * 1000))
    role.created_at = dayjs().to(new Date(role.created_at * 1000))
    role.role_id = idx + 1
    role.application = role.application ? role.application : '' // should not be null
    return role
  })
}

const getCustomerRolesUrl = (
  customRoleFeatureFlag,
  saIAMRolesLDFlag,
  platformCid,
  applicationId
) => {
  let url = ''
  if (customRoleFeatureFlag) {
    if (saIAMRolesLDFlag) {
      url = `/support-assistant/v1beta1/customer-roles?tag_rules=tac&platform_customer_id=${platformCid}`
    } else {
      url = `/authorization/ui/v1/customers/${platformCid}/applications/${applicationId}/roles?tag_rules=tac&platform_cid=${platformCid}`
    }
  } else {
    url = `/ui-doorway/ui/v1/um/customers/roles`
  }
  return url
}

function useRolesGet(
  accessToken,
  t,
  rolesToDelete,
  itemsPerPage,
  page,
  debouncedSearchValue,
  setTotalCount,
  setShowNotification,
  setLoading,
  customRoleFeatureFlag,
  saIAMRolesLDFlag,
  platformCid,
  applicationId,
  sortBy,
  sortDirection
  // filterOptions
) {
  const [roles, setRoles] = useState(undefined)

  useEffect(() => {
    let isCurrent = true
    const url = getCustomerRolesUrl(
      customRoleFeatureFlag,
      saIAMRolesLDFlag,
      platformCid,
      applicationId
    )
    get(
      url,
      {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        search_string: debouncedSearchValue,
        orderBy: sortBy,
        direction: sortDirection
      },
      accessToken
    ).then(
      (response) => {
        if (!isCurrent) return
        const updatedRoles = getRoles(response.data.roles, t)
        setRoles(updatedRoles)
        setTotalCount(response.data?.pagination?.total_count)
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )

    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    debouncedSearchValue,
    page,
    rolesToDelete,
    itemsPerPage,
    setTotalCount,
    sortBy,
    sortDirection
  ])

  return [roles, setRoles]
}

export default useRolesGet
