// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'
import React from 'react'

import DocumentCuration from './document-curation/DocumentCuration'
import SearchAdministrationPage from './searchCuration'

const SearchAdministrationRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<SearchAdministrationPage />} />
      <Route
        exact
        path="/document-curation/:docId"
        element={<DocumentCuration />}
      />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export default SearchAdministrationRouter
