// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { ChatActions, useChatContext } from '../chat-context'
import chatService, { SESSION_EXPIRED } from '../../service/ChatService'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import {
  messageContainsProgressIndicator,
  messageContainsYesButton
} from '../../ChatMessageFromBot'

const SessionContext = createContext()

export const SessionContextProvider = ({ children }) => {
  const { oidcUser } = useReactOidc()
  const accessToken = oidcUser?.access_token
  const userEmail = oidcUser?.profile?.email

  const {
    currentPoll,
    sessionId,
    dispatchChatContext,
    setDisableInput,
    newConversation
  } = useChatContext()
  const [startPolling, setStartPolling] = useState(false)
  const [checkNextMessage, setCheckNextMessage] = useState(false)
  const isTabActive = useRef(true)
  const sessionIdRef = useRef(null)
  const accessTokenRef = useRef(accessToken)
  const [workspaceId, setWorkspaceId] = useState(
    getCustomerAccount()?.platform_customer_id
  )
  const attemptForWorkspaceUpdate = 0

  useEffect(() => {
    const handleVisibilityChange = () => {
      isTabActive.current = !document.hidden
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const displayErrorMessage = useCallback(() => {
    dispatchChatContext({
      type: ChatActions.ERROR_CONNECTING_CHAT
    })
  }, [dispatchChatContext])

  const client = useMemo(
    () => ({
      send: async (message) => {
        setDisableInput(true)
        const response = await chatService.sendUtterance(
          accessToken,
          sessionId,
          message
        )
        if (response?.id) {
          setStartPolling(true)
        } else if (response === SESSION_EXPIRED?.message) {
          dispatchChatContext({
            type: ChatActions.CLEAR_STATE
          })
        } else {
          displayErrorMessage()
        }
      }
    }),
    [
      accessToken,
      sessionId,
      dispatchChatContext,
      setDisableInput,
      displayErrorMessage
    ]
  )

  const incrementCurrentPoll = useCallback(() => {
    dispatchChatContext({
      type: ChatActions.UPDATE_CURRENT_POLL
    })
  }, [dispatchChatContext])

  const updateChatState = useCallback(
    (chatState) => {
      chatState.forEach((chat) => {
        dispatchChatContext({
          type: ChatActions.UPDATE_CHAT,
          payload: {
            from: 'Virtual Assistant',
            ...chat
          }
        })
      })
      if (messageContainsProgressIndicator(chatState[0])) {
        setCheckNextMessage(true)
        setDisableInput(true)
        setStartPolling(true)
      } else if (messageContainsYesButton(chatState[0])) {
        setDisableInput(true)
      } else {
        setDisableInput(false)
      }
    },
    [dispatchChatContext, setDisableInput]
  )

  const updateWorkspaceId = () => {
    const newWorkspaceId = getCustomerAccount()?.platform_customer_id
    setWorkspaceId(newWorkspaceId)
  }

  useEffect(() => {
    updateWorkspaceId()
    const pollingResponse = async () => {
      const response = await chatService.getResponses(
        accessToken,
        sessionId,
        10,
        currentPoll
      )
      if (response?.items) {
        dispatchChatContext({
          type: ChatActions?.REMOVE_LAST_MESSAGE
        })
        setStartPolling(false)
        incrementCurrentPoll()
        if (response?.items?.length > 0) {
          if (checkNextMessage) {
            dispatchChatContext({
              type: ChatActions?.REMOVE_LAST_MESSAGE
            })
            setCheckNextMessage(false)
          }
          updateChatState(response?.items)
        }
      } else {
        setStartPolling(false)
        dispatchChatContext({
          type: ChatActions?.RESET_CURRENT_POLL
        })
        displayErrorMessage()
      }
    }
    if (sessionId && startPolling) {
      pollingResponse()
    }
  }, [
    sessionId,
    accessToken,
    startPolling,
    currentPoll,
    dispatchChatContext,
    incrementCurrentPoll,
    updateChatState,
    checkNextMessage,
    setDisableInput,
    displayErrorMessage
  ])

  useEffect(() => {
    const sendInitialMessage = async () => {
      const initialMessage = newConversation
        ? 'TriggerNewConversation'
        : 'Hello'
      return chatService.sendUtterance(
        accessToken,
        sessionIdRef.current,
        initialMessage
      )
    }

    dispatchChatContext({
      type: ChatActions?.ADD_MESSAGE_LOADER
    })

    const initializeSession = async () => {
      const sessionResponse = await chatService.createSession(
        accessToken,
        userEmail,
        workspaceId
      )
      if (sessionResponse?.sessionId) {
        sessionIdRef.current = sessionResponse?.sessionId
        dispatchChatContext({
          type: ChatActions.UPDATE_SESSION_ID,
          payload: sessionResponse?.sessionId
        })
        if (sessionIdRef?.current) {
          const responseFromInitialMessageUtterance = await sendInitialMessage()
          if (responseFromInitialMessageUtterance) {
            setDisableInput(true)
            setStartPolling(true)
          }
        }
      } else {
        displayErrorMessage()
      }
    }

    if (
      (sessionId === null || sessionId === undefined) &&
      isTabActive.current
    ) {
      initializeSession()
    }
  }, [
    accessToken,
    userEmail,
    workspaceId,
    sessionId,
    dispatchChatContext,
    setDisableInput,
    displayErrorMessage,
    newConversation
  ])

  useEffect(() => {
    accessTokenRef.current = accessToken
    sessionIdRef.current = sessionId
  }, [accessToken, sessionId])

  useEffect(() => {
    const workspaceNameUpdated = async () => {
      if (workspaceId) {
        try {
          await chatService.updateWorkspace(
            accessTokenRef.current,
            sessionIdRef.current,
            workspaceId,
            attemptForWorkspaceUpdate
          )
        } catch (error) {
          console.warn('Error fetching data for workspace:', error)
        }
      }
    }
    workspaceNameUpdated()
  }, [workspaceId])

  return (
    <SessionContext.Provider value={useMemo(() => ({ client }), [client])}>
      {children}
    </SessionContext.Provider>
  )
}

export const useSessionContext = () => {
  return useContext(SessionContext)
}

SessionContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
