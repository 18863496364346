// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useState, useEffect, useRef } from 'react'
import { Box, TextArea } from 'grommet'
import PropTypes from 'prop-types'
import { Send, CircleAlert } from 'grommet-icons'
import styled from 'styled-components'
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber
} from 'libphonenumber-js'

import { Typography } from '../../components'

import { ChatActions, useChatContext } from './context/chat-context'

const StyledTextArea = styled(TextArea)`
  all: unset;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::placeholder {
    pointer-events: none;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`
const StyledBox = styled(Box)`
  background-color: ${(props) =>
    props.messageLength > 250
      ? `${props.theme.global.colors['status-critical'].light}1f`
      : `${props.theme.global.colors['background-front'].light}`};
`

const ChatFooter = ({ client, isChatEmpty }) => {
  const [message, setMessage] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('+')
  const [isPhoneNumberWrong, setIsPhoneNumberWrong] = useState(false)
  const [showScrollbar, setShowScrollbar] = useState(false)
  const [numberOfLines, setNumberOfLines] = useState(0)
  const [prevMessageLength, setPrevMessageLength] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const [over, setOver] = useState(false)
  const CHAR_MAX_LIMIT = 250
  const PHONE_MAX_LIMIT = 30
  const PHONE_ERRORMESSAGE = `Enter a valid phone number (including the country code)`

  const {
    sessionId,
    dispatchChatContext,
    disableInput,
    textAreaHeight,
    setTextAreaHeight
  } = useChatContext()

  const textareaRef = useRef()
  const isPhoneButtonSelected = JSON.parse(
    localStorage.getItem('chatPhoneButtonSelected') || false
  )

  useEffect(() => {
    const savedMessage = sessionStorage.getItem('chatInputMessage') || ''
    const savedPhoneNumber =
      sessionStorage.getItem('chatInputPhoneNumber') || '+'
    if (savedMessage || savedPhoneNumber) {
      setMessage(savedMessage)
      setPhoneNumber(savedPhoneNumber)
    }
  }, [])

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('chatPhoneButtonSelected'))) {
      textareaRef.current?.focus()
    }
    sessionStorage.setItem('chatInputMessage', message)
    sessionStorage.setItem('chatInputPhoneNumber', phoneNumber)
  })

  const sendMessage = () => {
    const time = `${new Date().getHours()}:${new Date().getMinutes()}`
    const createPayload = (text) => ({
      from: 'You',
      text,
      time
    })
    const handleChatDispatch = (text) => {
      const payload = createPayload(text)
      if (sessionId) {
        dispatchChatContext({ type: ChatActions.UPDATE_CHAT, payload })
        dispatchChatContext({ type: ChatActions.ADD_MESSAGE_LOADER })
        dispatchChatContext({
          type: ChatActions.SYNC_USER_INPUT_TO_BUTTONS,
          payload
        })
        client.send(text)
      } else {
        dispatchChatContext({ type: ChatActions.ERROR_CONNECTING_CHAT })
      }
    }
    if (message.trim()) {
      handleChatDispatch(message)
      setMessage('')
      sessionStorage.removeItem('chatInputMessage')
    }
    if (isPhoneButtonSelected && phoneNumber !== '+') {
      if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
        setIsPhoneNumberWrong(true)
        return
      }

      handleChatDispatch(phoneNumber)
      setPhoneNumber('+')
      setIsPhoneNumberWrong(false)
      sessionStorage.removeItem('chatInputPhoneNumber')
      localStorage.setItem('chatPhoneButtonSelected', false)
    }
  }

  const handleUSPhoneNumber = (cleanedInputValue) => {
    const phonenumberPart = cleanedInputValue.replace('+1', '')
    if (phonenumberPart.length === 0) {
      cleanedInputValue = `+1`
    } else if (phonenumberPart.length <= 3) {
      cleanedInputValue = `+1 (${phonenumberPart}`
    } else if (phonenumberPart.length <= 6) {
      cleanedInputValue = `+1 (${phonenumberPart.slice(
        0,
        3
      )}) ${phonenumberPart.slice(3)}`
    } else {
      cleanedInputValue = `+1 (${phonenumberPart.slice(
        0,
        3
      )}) ${phonenumberPart.slice(3, 6)}-${phonenumberPart.slice(6, 30)}`
    }
    setPhoneNumber(cleanedInputValue)
  }

  const handleOtherCountryPhoneNumber = (cleanedInputValue) => {
    const phoneNumberFormat = parsePhoneNumberFromString(cleanedInputValue)
    if (phoneNumberFormat) {
      cleanedInputValue = phoneNumberFormat.formatInternational()
    }
    setPhoneNumber(cleanedInputValue)
  }

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target?.value || ''
    setIsPhoneNumberWrong(false)

    if (inputValue.length <= PHONE_MAX_LIMIT) {
      let cleanedInputValue = inputValue.replace(/[^0-9+]/g, '')

      if (cleanedInputValue.startsWith('+')) {
        cleanedInputValue = `+${cleanedInputValue.slice(1).replace(/\+/g, '')}`
      } else {
        cleanedInputValue = `+${cleanedInputValue.replace(/\+/g, '')}`
      }

      if (cleanedInputValue.startsWith('+1')) {
        handleUSPhoneNumber(cleanedInputValue)
      } else {
        handleOtherCountryPhoneNumber(cleanedInputValue)
      }
    }
  }

  const handleTextAreaChange = (e) => {
    setMessage(e.target.value)
  }

  const handleMouseDown = () => {
    setIsResizing(true)
  }

  const handleMouseUp = () => {
    setIsResizing(false)
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isResizing) {
        const textArea = textareaRef.current
        const newHeight = Math.max(24, textArea.clientHeight - event.movementY)
        const maxHeight = 300
        textArea.style.height = `${Math.min(newHeight, maxHeight)}px`
        setTextAreaHeight(textArea.style.height)
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, setTextAreaHeight])

  useEffect(() => {
    const textArea = textareaRef.current
    const currentMessageLength = message?.length

    const calculatedNumberOfLines = Math.floor(textArea.scrollHeight / 24)

    if (currentMessageLength < prevMessageLength) {
      const newBoxHeight = Math.max(24, textArea.clientHeight - 24)
      textArea.style.height = `${newBoxHeight}px`
    }
    if (message?.trim() === '') {
      setTextAreaHeight(24)
      setNumberOfLines(0)
      setShowScrollbar(false)
    } else {
      if (calculatedNumberOfLines > 1) {
        textArea.style.height = `${Math.min(textArea.scrollHeight, 150)}px`
        setTextAreaHeight(textArea.style.height)
        setNumberOfLines(calculatedNumberOfLines)
        setShowScrollbar(calculatedNumberOfLines > 4)
      }
      setPrevMessageLength(currentMessageLength)
    }
  }, [message, prevMessageLength, setTextAreaHeight, numberOfLines])

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      message?.length <= CHAR_MAX_LIMIT
    ) {
      event.preventDefault()
      sendMessage()
    }
  }

  const handleOnChange = (event) => {
    if (!isPhoneButtonSelected) {
      handleTextAreaChange(event)
    } else {
      handlePhoneNumberChange(event)
    }
  }

  const handleErrorBackground = () => {
    return !isPhoneNumberWrong || !isPhoneButtonSelected ? '' : '#FC61613D'
  }

  const getPlaceholder = () => {
    return !isPhoneButtonSelected ? 'Type your issue...' : ''
  }

  const getValue = () => {
    return !isPhoneButtonSelected ? message : phoneNumber || '+'
  }

  const getOverflowY = () => {
    return showScrollbar ? 'scroll' : 'hidden'
  }

  const getCursorStyle = () => {
    return isChatEmpty || disableInput ? 'not-allowed' : 'text'
  }

  const count = message?.length
  const overLimit = count - CHAR_MAX_LIMIT
  const setDownBackground = () => {
    if (disableInput || overLimit > 0 || isChatEmpty) {
      return '#01A98284'
    }
    if (over) {
      return '#007159'
    }
    return 'rgb(1, 169, 130)'
  }
  return (
    <Box
      data-testid="MouseDownUp"
      direction="column"
      pad={{ top: 'xsmall' }}
      background="background-back"
      style={{ position: 'relative' }}
    >
      <Box
        data-testid="chat-footer"
        direction="row"
        align="center"
        pad={{ horizontal: 'small', top: 'small', bottom: 'xsmall' }}
        justify="start"
        background="background-back"
        gap="0px"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          bottom: '0px',
          position: 'relative',
          width: '100%'
        }}
      >
        <StyledBox
          messageLength={message?.length}
          flex="grow"
          direction="row"
          align="initial"
          round="6px"
          pad={{ right: 'small', left: 'small', top: '6px', bottom: '6px' }}
          border={{ color: 'lightgray' }}
          style={{
            minHeight: '24px',
            height: 'auto',
            minWidth: '294px',
            background: handleErrorBackground()
          }}
        >
          <StyledTextArea
            ref={textareaRef}
            placeholder={getPlaceholder()}
            plain
            focusIndicator={false}
            resize={false}
            value={getValue()}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            disabled={disableInput || isChatEmpty}
            data-testid="textarea"
            style={{
              fontSize: '18px',
              width: '100%',
              minHeight: 'inherit',
              minWidth: 'inherit',
              fontWeight: '400',
              display: 'inline-block',
              verticalAlign: 'middle',
              wordWrap: 'break-word',
              lineHeight: '24px',
              height: `${textAreaHeight}px`,
              overflowY: getOverflowY(),
              padding: '0px',
              alignContent: 'center',
              cursor: getCursorStyle()
            }}
          />
        </StyledBox>
        <Box
          data-testid="chat-send-icon"
          flex="shrink"
          pad="9px"
          background="brand"
          round="full"
          align="center"
          justify="center"
          margin={{ left: '6px' }}
          width="auto"
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
          style={{
            minWidth: '36px',
            minHeight: '36px',
            zIndex: '1',
            background: setDownBackground()
          }}
        >
          <Send
            size="18px"
            id="chat-send-ico"
            data-testid="send-icon"
            onClick={sendMessage}
            style={{
              cursor:
                isChatEmpty || disableInput || overLimit > 0
                  ? 'not-allowed'
                  : 'pointer',
              pointerEvents:
                isChatEmpty || disableInput || overLimit > 0 ? 'none' : 'auto'
            }}
            aria-label="send your message"
          />
        </Box>
      </Box>
      <Box
        direction="column"
        pad={{ horizontal: 'small' }}
        align="center"
        justify="center"
        style={{ cursor: 'default' }}
      >
        {count > CHAR_MAX_LIMIT / 2 && (
          <>
            <Box
              direction="column"
              pad={{ horizontal: 'xxsmall', bottom: 'small' }}
              data-testid="characterCount"
              align="center"
              justify="center"
              style={{ marginRight: 'auto' }}
            >
              <Typography
                margin={{ right: '400px' }}
                type="text"
                size="xsmall"
                color={250 - count >= 0 ? 'text-weak' : 'status-critical'}
                style={{
                  lineHeight: '10px',
                  whiteSpace: 'nowrap'
                }}
              >
                {250 - count < 0
                  ? `${overLimit} characters over limit`
                  : `${250 - count} characters left`}
              </Typography>
            </Box>
          </>
        )}
        {!isPhoneNumberWrong || !isPhoneButtonSelected ? (
          ''
        ) : (
          <Box
            as="span"
            direction="row"
            align="center"
            margin={{ right: 'auto' }}
            pad={{ bottom: 'xxsmall' }}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              verticalAlign: 'middle'
            }}
          >
            <CircleAlert size="small" color="text" />
            <Typography
              type="text"
              size="xsmall"
              color="text"
              data-testid="clear-chat-span"
              margin={{ left: 'xsmall' }}
            >
              {`${PHONE_ERRORMESSAGE}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ChatFooter

ChatFooter.propTypes = {
  isChatEmpty: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
}
