// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'

import { Typography } from '../../components'

const GuidedWorkspaceData = () => {
  const { t } = useTranslation(['dashboard'])
  const size = useContext(ResponsiveContext)

  const content = (text = '') => {
    return (
      <Typography type="paragraph" size="small">
        {text}
      </Typography>
    )
  }
  const data = {
    '/home/get-started': [],
    '/manage-account/identity/users': [
      {
        slot: 'users-card',
        target: 'invite-users-btn',
        align: 'bottomRight',
        header: t('dashboard:guided_workspace.manage_users_card_title'),
        content: content(t('dashboard:guided_workspace.manage_users_card_text'))
      }
    ],
    '/services/service-catalog': [
      {
        slot: 'services-card',
        target: 'service-centric-menulist-service-catalog-link',
        align: size === 'xlarge' ? 'right' : 'bottom',
        header: t('dashboard:guided_workspace.services_card_title'),
        content: content(t('dashboard:guided_workspace.services_card_text'))
      }
    ],
    '/services/service-subscriptions': [
      {
        slot: 'service-subs-card',
        target: 'no-data-info-service-subscriptions',
        align: size === 'xlarge' ? 'left' : 'bottom',
        header: t('dashboard:guided_workspace.service_subs_card_title'),
        content: content(t('dashboard:guided_workspace.service_subs_card_text'))
      }
    ],
    '/devices/subscriptions-list': [
      {
        slot: 'subscriptions-card',
        target: 'no-data-info-licenses',
        align: ['xlarge', 'large'].includes(size) ? 'left' : 'bottom',
        header: t('dashboard:guided_workspace.device_subs_card_title'),
        content: content(t('dashboard:guided_workspace.device_subs_card_text'))
      }
    ],
    '/devices/inventory-list': [
      {
        slot: 'devices-card',
        target: 'no-data-info-devices',
        align: ['xlarge', 'large'].includes(size) ? 'left' : 'bottom',
        header: t('dashboard:guided_workspace.add_devices_card_title'),
        content: content(t('dashboard:guided_workspace.add_devices_card_text'))
      }
    ]
  }

  return data
}

export { GuidedWorkspaceData }
