// Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { CircleAlert } from 'grommet-icons'

import { Typography, Anchor } from '../../../../components'

const CaseErrorPage = ({ errorData }) => {
  const { t } = useTranslation(['support_hub'])
  const errorTitle = errorData?.title
    ? errorData.title
    : 'support_cases:create_case.submit_case_full_page_error'
  const errorMessage = errorData?.message
    ? errorData.message
    : 'support_cases:common.try_again'

  return (
    <Box gap="medium" align="center" margin={{ bottom: 'large' }}>
      <CircleAlert size="xlarge" />
      <Typography
        size="medium"
        level="1"
        type="heading"
        textAlign="center"
        data-testid="error-title"
      >
        {t(errorTitle)}
      </Typography>
      <Typography type="text" weight="normal" data-testid="error-message">
        <Trans i18nKey={errorMessage} t={t}>
          <Anchor
            href="/support"
            testId="case_confirm_support"
            label={t('support_hub:support_title')}
          />
        </Trans>
      </Typography>
    </Box>
  )
}

CaseErrorPage.propTypes = {
  errorData: PropTypes.object.isRequired
}

export default CaseErrorPage
