// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { Hpe } from 'grommet-icons'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { debounce } from 'lodash'

/* eslint-enable */
import {
  DataTable,
  // FilterButton,
  Typography,
  Logo,
  ActionButton,
  Loader
} from '../../components'
import { AUTHZActions, useAUTHZContext } from '../../context/authz-context'
import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'
import {
  isCoP,
  getCustomerAccount,
  isGLOP
} from '../../utils/feature-flag-utils'
import ArubaImage from '../../images/aruba.png'
import pcaasImage from '../../images/pce-hoc.jpg'
import { isServiceCentric } from '../../utils/account-utils'

import useRolesGet from './commoncomponents/userolesget'
import RoleDetailsModal from './role-details-modal/RoleDetailsModal'

const RolesDataTable = React.memo(
  ({
    rolesToDelete,
    setRolesToDelete,
    setDeleteDlgOpen,
    inCCSManager = false
  }) => {
    // const [filterOptions, setFilterOptions] = useState({})
    const LDFlags = useFlags()
    const newDashboardFlag = isServiceCentric(LDFlags)
    const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
    const saIAMRolesLDFlag = LDFlags['glcp-london-sa-support-roles-flag']
    const { t } = useTranslation(['authz, common', 'manage'])
    dayjs.extend(relativeTime)

    // State for search status (whether there is a pending API request)
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
    const itemsPerPage = 10
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const { dispatchAUTHZContext, openDetails, roleAppMap } = useAUTHZContext()
    const { oidcUser } = useReactOidc()
    const [showNotification, setShowNotification] = useState(null)
    const [loading, setLoading] = useState(true)
    const custAccountLoaded = getCustomerAccount()
    const platformCid = custAccountLoaded?.platform_customer_id || ''
    const [sortBy, setSortBy] = useState('name')
    const [sortDirection, setSortDirection] = useState('asc')

    const ccsApplicationId = '00000000-0000-0000-0000-000000000000'
    const [roles] = useRolesGet(
      oidcUser.access_token,
      t,
      rolesToDelete,
      itemsPerPage,
      page,
      debouncedSearchTerm,
      setTotalCount,
      setShowNotification,
      setLoading,
      inCCSManager && saCustomRoleLDFlag,
      inCCSManager && saIAMRolesLDFlag,
      platformCid,
      inCCSManager && saCustomRoleLDFlag ? ccsApplicationId : null,
      sortBy,
      sortDirection
    )
    useEffect(() => {
      const roleToAppForContext = new Map()

      if (roles) {
        roles.forEach((role) => {
          roleToAppForContext.set(role.application_id, {
            duplicateFlag: false,
            provAppName:
              role?.application_id === ccsApplicationId
                ? t('common:platform_application_brand_name')
                : role?.application_name,
            appLogo: role.application_logo,
            roleList: roleToAppForContext.get(role.application_id)
              ? [
                  ...roleToAppForContext.get(role.application_id).roleList,
                  {
                    ...role,
                    resourcePolicies: role.resource_policies
                  }
                ]
              : [
                  {
                    ...role,
                    resourcePolicies: role.resource_policies
                  }
                ]
          })
        })
      }
      dispatchAUTHZContext({
        type: AUTHZActions.SET_ROLE_APP_MAP_DATA,
        data: roleToAppForContext
      })
    }, [roles, rolesToDelete, dispatchAUTHZContext, t])

    const navigateToDetail = (role) => {
      navigate(
        `/${
          inCCSManager && saCustomRoleLDFlag ? 'manage-ccs' : 'manage-account'
        }/identity/roles/roleviewedit/${role.application_id}/${role.slug}`
      )
    }
    const handleClickRow = (e) => {
      dispatchAUTHZContext({
        type: AUTHZActions.SET_SELECTED_ROLE_DETAILS,
        data: e.datum
      })
    }

    // function to handle sorting by name
    const sortRoles = () => {
      const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
      setSortBy('name')
      setSortDirection(newSortDirection)
    }

    // function to handle sorting by description
    const sortRolesByDescription = () => {
      const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
      setSortBy('description')
      setSortDirection(newSortDirection)
    }

    const onSort = ({ property }) => {
      // property indicates which column is clicked
      // direction indicates the desired sort direction
      if (property === 'role_id') {
        sortRoles()
      } else if (property === 'description') {
        sortRolesByDescription()
      }
    }

    const handleDebouncedSearchValue = debounce((value) => {
      setDebouncedSearchTerm(value)
    }, 500)

    const loadApplicationImage = (data) => {
      let imageType
      if (isCoP()) {
        imageType = ArubaImage
      } else if (isGLOP()) {
        imageType = pcaasImage
      } else {
        imageType = data.application_logo
      }
      return imageType
    }

    const columns = [
      {
        property: 'role_id',
        type: 'string',
        header: t('authz:name'),
        sortable: true,
        render: (datum) => datum.name,
        primary: true
      },
      {
        property: 'description',
        type: 'string',
        header: t('authz:description'),
        sortable: true
      },
      {
        property: 'type',
        type: 'string',
        header: t('authz:roles.role'),
        sortable: false
      },
      ...(inCCSManager && saCustomRoleLDFlag && !saIAMRolesLDFlag
        ? []
        : [
            {
              property: 'users',
              type: 'numeric',
              header: t('authz:roles.assigned_users'),
              render: (datum) => (
                <Typography size="medium" type="text">
                  {datum.total_users > 0
                    ? `${datum.total_users} ${
                        datum.total_users > 1
                          ? t('authz:roles.user_length_plural')
                          : t('authz:roles.user_length_singular')
                      }`
                    : '--'}
                </Typography>
              ),
              sortable: false
            }
          ]),
      {
        property: 'application_name',
        type: 'string',
        header: newDashboardFlag
          ? t('authz:service_manager')
          : t('authz:application'),
        render: (datum) => (
          <>
            <Box align="center" gap="small" direction="row">
              {datum.application_id === ccsApplicationId ? (
                <Hpe color="brand" size="small" />
              ) : (
                <Logo
                  size="xxsmall"
                  url={loadApplicationImage(datum)}
                  testId="application-logo"
                />
              )}
              <Typography size="medium" type="text">
                {datum?.application_name}
              </Typography>
            </Box>
          </>
        ),
        sortable: false
      },
      {
        property: 'actions',
        header: '',
        render: (datum) => (
          <>
            <ActionButton
              showOneActionAsDropDown
              actions={
                datum.predefined
                  ? [
                      {
                        label: t('authz:view_details'),
                        onClick: () => {
                          navigateToDetail(datum)
                        }
                      }
                    ]
                  : [
                      {
                        label: t('authz:view_details'),
                        onClick: () => {
                          navigateToDetail(datum)
                        }
                      },
                      {
                        label: t('authz:roles.duplicate'),
                        visibility: {
                          hideFor: {
                            account: ['TENANT']
                          },
                          rbac: {
                            resource: '/ccs/authorization',
                            permission: 'ccs.authorization.edit'
                          }
                        },
                        hidden: datum?.tags?.readonly,
                        onClick: () => {
                          dispatchAUTHZContext({
                            type: AUTHZActions.SET_CREATE_ROLE_DATA,
                            data: {
                              name: datum.name,
                              createOption: 'duplicate_existing',
                              application: datum.application_id
                            }
                          })
                          dispatchAUTHZContext({
                            type: AUTHZActions.SET_SELECTED_ROLE,
                            data: {
                              ...datum,
                              application: datum.application_id,
                              resourcePolicies: datum.resource_policies
                            }
                          })
                          const permissionsSelected = []
                          const res = datum.resource_policies
                          for (let j = 0; j < res.length; j += 1) {
                            for (
                              let i = 0;
                              i < res[j].permissions.length;
                              i += 1
                            ) {
                              permissionsSelected.push(
                                `${res[j].resource.matcher}~${res[j].permissions[i].slug}`
                              )
                            }
                          }
                          dispatchAUTHZContext({
                            type: AUTHZActions.PERMISSIONS_SELECTED,
                            data: permissionsSelected
                          })
                          roleAppMap.set(datum.application_id, {
                            ...roleAppMap.get(datum.application_id),
                            duplicateFlag: true
                          })
                          navigate(
                            `/${
                              inCCSManager && saCustomRoleLDFlag
                                ? 'manage-ccs'
                                : 'manage-account'
                            }/identity/roles/create/${datum.application_id}/${
                              datum.slug
                            }`
                          )
                        }
                      },
                      {
                        label: t('manage:delete_label'),
                        visibility: {
                          hideFor: {
                            account: ['TENANT']
                          },
                          rbac: {
                            resource: '/ccs/authorization',
                            permission: 'ccs.authorization.delete'
                          }
                        },
                        hidden: datum?.tags?.readonly,
                        onClick: () => {
                          setRolesToDelete([datum])
                          setDeleteDlgOpen(true)
                        }
                      }
                    ]
              }
              testId={`roles-data-table-action-buttons-${datum.role_id}`}
              customRenderer={(actionBtn, visibility) => {
                return (
                  !actionBtn.hidden && (
                    <VisibilityWrapper
                      key={actionBtn.key}
                      rbac={visibility?.rbac}
                      hideFor={visibility?.hideFor}
                    >
                      {actionBtn}
                    </VisibilityWrapper>
                  )
                )
              }}
            />
          </>
        )
      }
    ]

    return loading ? (
      <Loader testId="role-data-table-loader" orientation="horizontal" />
    ) : (
      <>
        {showNotification !== null && showNotification}
        <Box
          pad={{ horizontal: 'xxsmall', top: 'small' }}
          data-testid="roles-data-table"
        >
          <DataTable
            pagination={{
              totalItems: totalCount,
              itemsPerPage,
              page,
              setPage
            }}
            onSort={onSort}
            sort={{
              property: sortBy,
              direction: sortDirection
            }}
            grid={{
              columns,
              data: roles,
              onClickRow: (e) => handleClickRow(e)
            }}
            summary={{
              entityName: t('authz:roles.roles_bracket')
            }}
            {...(inCCSManager && saCustomRoleLDFlag && !saIAMRolesLDFlag
              ? {}
              : {
                  search: {
                    onSearchValueChange: (value) => {
                      if (value.trim() !== '') {
                        handleDebouncedSearchValue(value)
                      } else {
                        setDebouncedSearchTerm('')
                      }
                      setPage(1)
                    },
                    placeholder: t('authz:roles.search_roles'),
                    width: 'medium'
                  }
                })}
            testId="roles-table"
          />
        </Box>
        {openDetails && (
          <RoleDetailsModal
            inCCSManager={inCCSManager}
            onClose={() =>
              dispatchAUTHZContext({
                type: AUTHZActions.CLEAR_SELECTED_ROLE_DETAILS
              })
            }
          />
        )}
      </>
    )
  }
)

export default RolesDataTable

RolesDataTable.propTypes = {
  rolesToDelete: PropTypes.array.isRequired,
  setRolesToDelete: PropTypes.func.isRequired,
  setDeleteDlgOpen: PropTypes.func.isRequired,
  inCCSManager: PropTypes.bool
}
