// Copyright 2025 Hewlett Packard Enterprise Development LP
import { useEffect, useState, useCallback, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { FormField, Select, TextArea, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import { ShareRounded } from 'grommet-icons'

import {
  SELECT,
  TEXT_AREA,
  TEXT_INPUT,
  NOTIFICATION,
  SUBSCRIPTION_KEY_TEXT,
  ERROR_TEXT,
  INFO_TEXT,
  JSON_TEXT,
  LINK,
  EMAIL_INPUT,
  ROOT
} from '../../constants'
import {
  getTargetNodeFromJSON,
  prepareCommonFields,
  prepareElement,
  getNextFromElements,
  clearFormElementsTillSelection
} from '../utils/CaseFormElementUtils'
import { Button } from '../../../../components'
import { Notification } from '../../../../components/notification/Notification'
import { onSearch as filterOptions } from '../utils/FormUtil'
import { validateEmail } from '../../../../utils/validation-utils'

const CaseFormElements = ({ serviceId, caseFormCallBack = () => {} }) => {
  const [formElements, setFormElements] = useState({})
  const selectedServiceJSON = useRef({})
  const subscriptionKeyData = useRef({})
  const isSubKeyNextEleRendered = useRef(false)
  const selectedElementOptions = useRef({})
  const requiredElementsFilledStatus = useRef({})
  const isRequiredElementsFilled = useRef(false)
  const { t } = useTranslation(['support_cases'])

  const prepareSubscriptionElement = useCallback(
    (subscriptionJson, subscriptionElementId, parentId) => {
      const subscriptionElement = prepareElement(
        subscriptionJson,
        subscriptionElementId,
        requiredElementsFilledStatus,
        t
      )
      subscriptionElement.max_length = 100
      subscriptionElement.threshold = 0.75
      subscriptionElement.parent_id = parentId
      subscriptionKeyData.current = subscriptionElement
      return subscriptionElement
    },
    [t]
  )

  const getSubscriptionDetails = useCallback(
    (jsonData, key, elements) => {
      if (jsonData.subscription) {
        const subscriptionElement = prepareSubscriptionElement(
          jsonData.subscription,
          `${key}-subscription`,
          ROOT
        )
        elements[subscriptionElement.id] = subscriptionElement
        isSubKeyNextEleRendered.current = !subscriptionElement.is_required
      }
    },
    [prepareSubscriptionElement]
  )

  const prepareDynamicElements = useCallback(
    (jsonData, key, elements) => {
      if (
        !jsonData.subscription ||
        (jsonData.subscription && !jsonData.subscription?.is_required)
      ) {
        if (jsonData.type !== JSON_TEXT) {
          const element = prepareElement(
            jsonData,
            key,
            requiredElementsFilledStatus,
            t
          )
          elements[element.id] = element
        }
        prepareCommonFields(
          jsonData,
          key,
          elements,
          requiredElementsFilledStatus,
          t
        )
      }
    },
    [t]
  )

  const resetDynamicForm = useCallback(() => {
    setFormElements({})
    selectedServiceJSON.current = {}
    subscriptionKeyData.current = {}
    isSubKeyNextEleRendered.current = false
    selectedElementOptions.current = {}
    requiredElementsFilledStatus.current = {}
    isRequiredElementsFilled.current = false
  }, [])

  useEffect(() => {
    resetDynamicForm()
    import(`..//config/${serviceId}.json`)
      .then((serviceJson) => {
        const elements = {}
        const jsonEscapeKeys = ['default']
        Object.entries(serviceJson).forEach(([key, value]) => {
          if (!jsonEscapeKeys.includes(key) && value) {
            getSubscriptionDetails(value, key, elements)
            prepareDynamicElements(value, key, elements)
            caseFormCallBack({
              data: {
                renderSubField: true,
                productInfo: value?.product_info
                  ? {
                      // Override all the values while loading a new service.
                      product_number: value.product_info.product_number,
                      product_name: value.product_info.product_name,
                      product_line_code: value.product_info.product_line_code,
                      product_type: value.product_info.product_type,
                      namespace: value.product_info.namespace
                    }
                  : {
                      // Reset the values while loading a new service.
                      product_number: '',
                      product_name: '',
                      product_line_code: '',
                      product_type: '',
                      namespace: ''
                    }
              }
            })
          }
        })
        setFormElements(elements)
        selectedServiceJSON.current = serviceJson
      })
      .catch(() => {
        caseFormCallBack({
          error: {
            showError: true,
            message: t('support_cases:create_case.backend_failure_message'),
            showFormElements: false
          }
        })
      })
  }, [
    serviceId,
    caseFormCallBack,
    prepareSubscriptionElement,
    getSubscriptionDetails,
    prepareDynamicElements,
    resetDynamicForm,
    t
  ])

  const getTargetNodePath = (option, target) => {
    let targetNodePath
    let isRenderNextElements = false
    if (
      !isSubKeyNextEleRendered.current &&
      target.name === SUBSCRIPTION_KEY_TEXT
    ) {
      targetNodePath = subscriptionKeyData.current.parent_id
      isSubKeyNextEleRendered.current = true
    } else if (
      isSubKeyNextEleRendered.current &&
      target.name === SUBSCRIPTION_KEY_TEXT
    ) {
      isRenderNextElements = true
    } else if (option?.id) {
      targetNodePath = option.id
    }
    return { isRenderNextElements, targetNodePath }
  }

  const getElementsFromJson = (targetNodePathId, selectedOption, target) => {
    const escSubKeyEleIds = [SUBSCRIPTION_KEY_TEXT]
    const selectedJson = getTargetNodeFromJSON(
      targetNodePathId,
      selectedServiceJSON.current
    )
    if (selectedJson) {
      const elements = clearFormElementsTillSelection(
        formElements,
        requiredElementsFilledStatus,
        target
      )
      let isNotRenderedNextElements = false
      if (
        !escSubKeyEleIds.includes(target.name) &&
        selectedJson?.subscription
      ) {
        const subscriptionElement = prepareSubscriptionElement(
          selectedJson.subscription,
          `${targetNodePathId}-subscription`,
          selectedOption?.id
        )
        elements[subscriptionElement.id] = subscriptionElement
        if (selectedJson?.subscription?.is_required) {
          setFormElements(elements)
          isSubKeyNextEleRendered.current = false
          isNotRenderedNextElements = true
          requiredElementsFilledStatus.current[subscriptionElement.name] = false
        } else {
          isSubKeyNextEleRendered.current = true
        }
      }
      if (!isNotRenderedNextElements) {
        if (selectedJson?.product_info) {
          caseFormCallBack({
            data: {
              productInfo: selectedJson.product_info
            }
          })
        }
        const updatedElements = getNextFromElements(
          selectedJson,
          targetNodePathId,
          elements,
          requiredElementsFilledStatus,
          t
        )
        setFormElements(updatedElements)
      }
    }
  }

  const fireCallBackEvent = (value, elementName) => {
    if (elementName in requiredElementsFilledStatus.current) {
      requiredElementsFilledStatus.current[elementName] = true
    }
    const isRequiredElementsPopulated = Object.values(
      requiredElementsFilledStatus.current
    ).every((everyElement) => everyElement)

    const eventData = {}
    if (value?.hide_button_panel) {
      eventData.toggleButtonPanel = false
      eventData.renderContact = false
    } else {
      if (
        document.querySelectorAll("[data-testid='case-form-button-group']")
          ?.length === 0
      ) {
        eventData.toggleButtonPanel = true
      }
      if (isRequiredElementsPopulated) {
        eventData.renderContact = true
        isRequiredElementsFilled.current = true
      } else if (
        !isRequiredElementsPopulated &&
        isRequiredElementsFilled.current
      ) {
        isRequiredElementsFilled.current = false
        eventData.renderContact = false
      }
    }
    if (Object.keys(eventData).length !== 0) {
      caseFormCallBack({
        data: eventData
      })
    }
  }
  const handleChange = ({ value, target }) => {
    if (target?.value !== value?.value) {
      const subKeyChangeResponse = getTargetNodePath(value, target)
      if (
        !subKeyChangeResponse.isRenderNextElements &&
        subKeyChangeResponse.targetNodePath
      ) {
        getElementsFromJson(subKeyChangeResponse.targetNodePath, value, target)
      }
      fireCallBackEvent(value, target.name)
    }
  }

  const handleValidate = (value, element) => {
    let validationInfo = {}
    if (
      element?.max_length &&
      element?.threshold &&
      value?.length / element?.max_length > element?.threshold
    ) {
      validationInfo.status =
        element.max_length - value.length >= 0 ? INFO_TEXT : ERROR_TEXT
      validationInfo.message =
        element.max_length - value.length >= 0
          ? t('support_cases:common.characters_left', {
              char_count: element.max_length - value.length
            })
          : t('support_cases:common.characters_over_limit', {
              char_count: value.length - element.max_length
            })
    }

    if (Object.keys(validationInfo).length === 0) {
      validationInfo = undefined
    }
    return validationInfo
  }

  const onSearch = (query, elementId) => {
    if (!selectedElementOptions.current[elementId]) {
      selectedElementOptions.current[elementId] = {
        ...formElements[elementId]
      }
    }
    const allOptions = [...selectedElementOptions.current[elementId].options]
    formElements[elementId].options = [...filterOptions(query, allOptions)]
    setFormElements({ ...formElements })
  }

  const onClose = (elementId) => {
    if (selectedElementOptions.current[elementId]) {
      formElements[elementId].options = [
        ...selectedElementOptions.current[elementId].options
      ]
      setFormElements({ ...formElements })
    }
  }

  return (
    <>
      {Object.keys(formElements).length > 0 &&
        Object.values(formElements).map((element) => {
          switch (element.type) {
            case SELECT:
              return (
                <Fragment key={element.id}>
                  <FormField
                    htmlFor={element.id}
                    name={element.name}
                    label={element.label}
                    required={element.is_required}
                  >
                    <Select
                      data-id={element.id}
                      id={element.id}
                      name={element.name}
                      placeholder={element.placeholder}
                      options={element.options}
                      labelKey="label"
                      valueKey="value"
                      onChange={(option) => handleChange(option)}
                      onClose={() => onClose(element.id)}
                      onSearch={
                        element.search_enabled
                          ? (query) => onSearch(query, element.id)
                          : null
                      }
                      disabledKey={(option) => {
                        return (
                          option.label ===
                          t(
                            'support_cases:create_case.subscription_key_notification'
                          )
                        )
                      }}
                    />
                  </FormField>
                </Fragment>
              )
            case TEXT_AREA:
              return (
                <Fragment key={element.id}>
                  <FormField
                    name={element.name}
                    required={element.is_required}
                    validate={(value) => handleValidate(value, element)}
                    data-testid={element.id}
                    label={element.label}
                    validateOn="change"
                  >
                    <TextArea
                      htmlFor={element.id}
                      name={element.name}
                      data-id={element.id}
                      id={element.id}
                      testId={element.id}
                      placeholder={element.placeholder}
                      onChange={(e) => handleChange(e)}
                    />
                  </FormField>
                </Fragment>
              )
            case TEXT_INPUT:
              return (
                <Fragment key={element.id}>
                  <FormField
                    name={element.name}
                    required={element.is_required}
                    validate={(value) => handleValidate(value, element)}
                    data-testid={element.id}
                    label={element.label}
                    validateOn="change"
                  >
                    <TextInput
                      htmlFor={element.id}
                      name={element.name}
                      inputType="text"
                      data-id={element.id}
                      id={element.id}
                      testId={element.id}
                      placeholder={element.placeholder}
                      onChange={(e) => handleChange(e)}
                    />
                  </FormField>
                </Fragment>
              )
            case EMAIL_INPUT:
              return (
                <Fragment key={element.id}>
                  <FormField
                    name={element.name}
                    required={element.is_required}
                    validate={(value) =>
                      validateEmail(
                        value,
                        t('support_cases:common.invalid_email')
                      )
                    }
                    data-testid={element.id}
                    label={element.label}
                    validateOn="change"
                  >
                    <TextInput
                      htmlFor={element.id}
                      name={element.name}
                      inputType="text"
                      data-id={element.id}
                      id={element.id}
                      testId={element.id}
                      placeholder={element.placeholder}
                    />
                  </FormField>
                </Fragment>
              )
            case NOTIFICATION:
              return (
                <Fragment key={element.id}>
                  <Notification
                    status={element.status}
                    message={element.message}
                    type={element.notification_type}
                  />
                </Fragment>
              )
            case LINK:
              return (
                <Fragment key={element.id}>
                  <Button
                    margin={{ top: 'xsmall' }}
                    primary={element.is_primary}
                    label={element.label}
                    icon={<ShareRounded />}
                    reverse={element.is_reverse}
                    href={element.url}
                    target={element.target}
                    testId={element.name}
                    style={{ width: 'fit-content' }}
                  />
                </Fragment>
              )
            default:
              return <Fragment key={element.id} />
          }
        })}
    </>
  )
}

CaseFormElements.propTypes = {
  serviceId: PropTypes.string.isRequired,
  caseFormCallBack: PropTypes.func
}

export default CaseFormElements
