// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, Wizard } from '../../../../../../components'
import { patch, post } from '../../../../../../utils/api-utils'
import { ProgressModal } from '../../../../../../commoncomponents/device-management'
import {
  AUDIT_LOG_CATEGORY,
  formatApplySubscriptionError
} from '../../../../../../utils/common-utils'
import { Layout } from '../../../../../../commoncomponents/layout/Layout'

import SelectUnlicensedDevices, {
  validateDeviceSelectionFields
} from './steps/SelectUnlicensedDevices'

const AddDevicesToLicenses = () => {
  const { t } = useTranslation(['device', 'common'])
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const {
    'glcp-dm-attach-subscription-glasgow': attachSubscriptionLD,
    'glcp-multi-license-support': glcpMultiLicenseSupport
  } = LDFlags
  const { licenseKey } = useParams()
  const searchParams = new URLSearchParams(window.location.search)
  const licenseType = searchParams?.get('subscription_type')
  const resourceId = searchParams?.get('resource_id')
  const { oidcUser } = useReactOidc()
  const [progressModal, setProgressModal] = useState(false)

  const removeSessionItem = () => {
    if (!attachSubscriptionLD) sessionStorage.removeItem('supportedDeviceTypes')
  }

  const handleFinish = (formValues, setFormError) => {
    if (glcpMultiLicenseSupport) {
      const requestBody = formValues.devices.map((value) => ({
        device_type: value.device_type,
        part_number: value.part_number,
        serial_number: value.serial_number,
        device_resource_id: value.resource_id,
        subscription_resource_ids: [
          ...(value.subscriptions.map((val) => val.resource_id) || []),
          resourceId
        ]
      }))
      patch(
        '/ui-doorway/ui/v1/license/devices',
        requestBody,
        oidcUser.access_token
      ).then(
        () => {
          removeSessionItem()
          setProgressModal(true)
        },
        (error) => {
          const backendErrorMessage = formatApplySubscriptionError(error, t)
          setFormError(backendErrorMessage)
        }
      )
    } else {
      const requestBody = formValues.devices.map((value) => ({
        ...value,
        subscription_key: licenseKey
      }))
      post(
        '/ui-doorway/ui/v1/license/devices',
        requestBody,
        oidcUser.access_token
      ).then(
        () => {
          removeSessionItem()
          setProgressModal(true)
        },
        (error) => {
          const backendErrorMessage = formatApplySubscriptionError(error, t)
          setFormError(backendErrorMessage)
        }
      )
    }
  }
  return (
    <Layout bannerOptions="no-banner">
      <Box>
        <Wizard
          actionOnExit={() => {
            removeSessionItem()
            navigate(-1)
          }}
          actionOnSubmit={handleFinish}
          formDefaultValues={{
            serials: []
          }}
          steps={[
            {
              childComponents: <SelectUnlicensedDevices />,
              description: (
                <Box direction="row" gap="xsmall">
                  <Typography size="large" testId="basic" type="text">
                    <Trans i18nKey="select_unsubscribed_devices_subtitle" t={t}>
                      <strong>{{ subscriptionType: licenseType }}</strong>
                    </Trans>
                  </Typography>
                </Box>
              ),
              title: t('select_unsubscribed_devices'),
              validateForm: (formValues) =>
                validateDeviceSelectionFields(
                  formValues,
                  t,
                  licenseKey,
                  oidcUser
                )
            }
          ]}
          testId="apply-licenses-from-license-detail-page"
          title={t('add_devices')}
        />
        {progressModal && (
          <ProgressModal
            onSetOpen={setProgressModal}
            title={t('apply_subscriptions')}
            onCloseModal={() =>
              navigate(
                `/manage-account/subscriptions/device-subscriptions/${licenseKey}`
              )
            }
            auditLogCategory={AUDIT_LOG_CATEGORY.SUBSCRIPTION_MANAGEMENT}
          />
        )}
      </Box>
    </Layout>
  )
}

export default AddDevicesToLicenses
