// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Box, Grid, ResponsiveContext, PageHeader } from 'grommet'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation } from 'react-router-dom'

import { FilterButton, Loader, Typography, Dropdown } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { useAxiosAuth } from '../../../dashboard/hooks'
import SideMenu from '../../../dashboard/components/SideMenu'
import ServiceSearch from '../../../dashboard/components/ServiceSearch'
import { displayApiError } from '../../../utils/error-handling-utils'
import { isServiceEnabled } from '../../../dashboard/utils'
import { GuidedTour } from '../../guided-workspace'
import { isGuidedWorkspace } from '../../../utils/feature-flag-utils'

import NewServiceCatalogCard from './NewServiceCatalogCard'

const ServiceCatalog = () => {
  const location = useLocation()
  const [filteredServiceCatalogData, setFilteredServiceCatalogData] = useState(
    []
  )
  const LDFlags = useFlags()
  const [selectedFilters, setSelectedFilters] = useState({})
  const [regionOptions, setRegionOptions] = useState([])
  const { t } = useTranslation([
    'dashboard',
    'services_catalog',
    'services',
    'authn'
  ])
  const ALL_REGION = t('common.all_regions')
  const [selectedRegion, setSelectedRegion] = useState(ALL_REGION)
  const [isLoaded, setIsLoaded] = useState(false)
  const [serviceCatalogData, setServiceCatalogData] = useState([])
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const size = useContext(ResponsiveContext)
  const isScreenSmall = size === 'small' || size === 'xsmall'
  const axios = useAxiosAuth()
  const responsiveColumns = {
    small: { count: 1, size: 'full' },
    medium: { count: 2, size: '1/2' },
    large: { count: 3, size: '1/3' },
    xlarge: { count: 4, size: '1/4' }
  }

  const toolbarDirection = ['xsmall', 'small', 'medium'].includes(size)
    ? 'column'
    : 'row'

  const [searchQuery, setSearchQuery] = useState('')

  const extractAvailableRegions = (data) =>
    new Set(
      data
        .flatMap(({ services }) => services)
        .flatMap(({ available_regions }) => available_regions)
        .map(({ name }) => name)
    )

  useEffect(() => {
    setIsLoaded(false)

    axios
      .get('/service-catalog/v1alpha1/service-catalog')
      .then(({ data: { items } }) => {
        setServiceCatalogData(items || [])
        setRegionOptions([ALL_REGION, ...extractAvailableRegions(items || [])])
      })
      .catch((error) =>
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      )
      .finally(() => setIsLoaded(true))
  }, [axios, ALL_REGION, setServiceCatalogData, t])

  useEffect(() => {
    setFilteredServiceCatalogData(
      serviceCatalogData.filter((serviceCategory) => serviceCategory)
    )
  }, [serviceCatalogData])

  // Service Category names
  const serviceCategories = useMemo(() => {
    return serviceCatalogData.map(({ category }) => ({
      valueLabel: t(`dashboard:common.category.${category}`),
      valueName: category
    }))
  }, [t, serviceCatalogData])

  // debounce function to update state of the search query
  const debouncedSearchData = debounce((searchStr) => {
    setSearchQuery(searchStr?.toLowerCase().trim())
  }, 500)

  useEffect(() => {
    // filter by category
    const filteredData = serviceCatalogData
      .map((category) => ({ ...category }))
      .filter(
        ({ category }) =>
          !selectedFilters.category ||
          selectedFilters.category.includes(category)
      )

    // filter by region
    filteredData.forEach((category) => {
      category.services = category.services.filter(
        ({ available_regions }) =>
          selectedRegion === ALL_REGION ||
          available_regions
            .map(({ name: regionName }) => regionName)
            .includes(selectedRegion)
      )
    })

    // filter by keyword search
    filteredData.forEach((category) => {
      category.services = category.services.filter(
        ({ service_offer: { name, short_description, slug } }) =>
          !searchQuery ||
          name?.toLowerCase().includes(searchQuery) ||
          t(`services:${slug}.short_description`, short_description)
            ?.toLowerCase()
            .includes(searchQuery)
      )
    })

    // filter by service LD flag
    filteredData.forEach((category) => {
      category.services = category.services.filter(
        ({ service_offer: { slug } }) => isServiceEnabled(slug, LDFlags)
      )
    })

    // filter by Eval
    filteredData.forEach((category) => {
      category.services = category.services.filter(
        ({ service_offer: { eval_url } }) =>
          !selectedFilters.evaluation || eval_url
      )
    })

    setFilteredServiceCatalogData(filteredData)
  }, [
    t,
    serviceCatalogData,
    searchQuery,
    selectedRegion,
    selectedFilters,
    LDFlags,
    ALL_REGION
  ])

  const serviceCount = filteredServiceCatalogData.flatMap(
    (category) => category.services
  ).length

  const isGuidedWorkspaceEnabled = LDFlags['glcp-guided-workspace']

  const [isGuidedModalOpen, setIsGuidedModalOpen] = useState(
    isGuidedWorkspace()
  )

  // fix categories list
  return (
    <Layout>
      {showErrorNotification}
      {isGuidedModalOpen && isGuidedWorkspaceEnabled && (
        <GuidedTour
          pathname={location.pathname}
          tourName="catalog-guided"
          setIsGuidedModalOpen={setIsGuidedModalOpen}
        />
      )}
      <Box pad={{ horizontal: 'xlarge', vertical: 'medium' }}>
        <Box margin={{ bottom: 'large' }}>
          <PageHeader
            title={t('common.services')}
            subtitle={t('common.services_subtitle')}
            testId="service-centric-catalog-pageheader"
          />
        </Box>
        <Grid
          rows={['auto']}
          columns={size === 'xlarge' ? ['small', 'flex'] : ['full']}
          gap="large"
        >
          <SideMenu active="catalog" />
          <Box gap="large">
            <Box
              direction={isScreenSmall ? 'column' : 'row'}
              justify={isScreenSmall ? 'center' : 'between'}
              align={isScreenSmall ? 'start' : 'center'}
              gap="large"
            >
              <Box>
                <Typography level={2} type="heading">
                  {t('service_catalog.title')}
                </Typography>
                <Typography type="paragraph">
                  {t('service_catalog.subtitle')}
                </Typography>
              </Box>
              <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
                <Box>
                  <Dropdown
                    options={regionOptions}
                    defaultVal={ALL_REGION}
                    onChangeDropdown={(e) => {
                      setSelectedRegion(e)
                    }}
                    testId="service-centric-catalog-region-dropdown"
                  />
                </Box>
              </VisibilityWrapper>
            </Box>
            <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
              <Box direction="column">
                <Box
                  direction={toolbarDirection || 'row'}
                  gap="medium"
                  margin={{ bottom: 'small' }}
                >
                  <ServiceSearch
                    debouncedSearchData={debouncedSearchData}
                    placeholder={t('service_catalog.search_placeholder')}
                    testId="service-catalog-search-box"
                  />
                  <Box width="100%">
                    <FilterButton
                      dialogHeight="100%"
                      title={t('authn:filter')}
                      dialogPosition="right"
                      initialSelectedFilters={selectedFilters}
                      filterAttributes={
                        LDFlags['glcp-evaluation-service'] === true
                          ? [
                              {
                                label: t('service_catalog.service_categories'),
                                name: 'category',
                                values: serviceCategories,
                                height: 'auto'
                              },
                              {
                                label: t('service_catalog.evaluations'),
                                name: 'evaluation',
                                values: [
                                  {
                                    valueLabel: t(
                                      'service_catalog.evaluation_available'
                                    ),
                                    valueName: 'Evaluation Available'
                                  }
                                ]
                              }
                            ]
                          : [
                              {
                                label: t('service_catalog.service_categories'),
                                name: 'category',
                                values: serviceCategories,
                                height: 'auto'
                              }
                            ]
                      }
                      onFilterValuesChange={(values) => {
                        setSelectedFilters(values)
                      }}
                      testId="service-catalog-filter"
                    />
                  </Box>
                </Box>
                {serviceCount > 0 && (
                  <Box>
                    <Typography type="text" testId="service-catalog-count">
                      {`${serviceCount} ${t('service_catalog.total_services')}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </VisibilityWrapper>
            <Box>
              {filteredServiceCatalogData && isLoaded ? (
                filteredServiceCatalogData.map(
                  ({ category, services }, index) => {
                    // Determine if this is the last category to style
                    // appropriately.
                    const lastCategory =
                      index ===
                      Object.keys(filteredServiceCatalogData).length - 1
                        ? null
                        : {
                            color: 'border-weak',
                            opacity: 'weak',
                            size: 'xsmall',
                            side: 'bottom'
                          }

                    return services?.length !== 0 ? (
                      <Box key={category}>
                        <Typography type="heading" level={3}>
                          {t(`dashboard:common.category.${category}`)}
                        </Typography>
                        <Box
                          margin={{ bottom: 'large' }}
                          pad={{ bottom: 'medium' }}
                          border={lastCategory}
                        >
                          <Box
                            columns={{
                              count: 'fill',
                              size: ['15%', 'small', 'flex']
                            }}
                            gap="large"
                            pad={{ vertical: 'medium' }}
                          >
                            <Grid
                              columns={responsiveColumns[size]}
                              gap="large"
                              pad={{ vertical: 'medium' }}
                            >
                              {services.map(({ service_offer }) => {
                                return (
                                  <NewServiceCatalogCard
                                    key={service_offer.id}
                                    serviceOffer={service_offer}
                                    t={t}
                                  />
                                )
                              })}
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    ) : null
                  }
                )
              ) : (
                <Box align="center" justify="center" alignSelf="center">
                  <Loader testId="loader-spinner" />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Layout>
  )
}
export default ServiceCatalog
