// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  Loader,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../components'
import EmailTextInput from '../commoncomponents/add-user/EmailTextInput'
import { getErrorMessage, post } from '../../../utils/api-utils'
import AddToGroup from '../commoncomponents/add-user/AddToGroup'
import { displayNotification } from '../../../utils/notificiation-utils'
import Markdown from '../../../commoncomponents/Markdown'
import { validateSAMLAuthzDomain } from '../../manage-account/utils'

const AddUserModal = ({
  onSetOpen,
  refreshParent = () => {},
  setParentNotification = () => {}
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const { oidcUser } = useReactOidc()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formValues, setFormValues] = useState({
    email: ''
  })
  const [selectedGroups, setSelectedGroups] = useState([])
  const [emailError, setEmailError] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')

  const setNotificationInfo = (message, severity) => {
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const closeModal = () => {
    onSetOpen(false)
  }

  const handleAddUser = () => {
    const addUser = async () => {
      setIsSubmitting(true)
      const groupIds = selectedGroups?.map((group) => group?.id)
      await post('/internal-platform-tenant-ui/v2/users', {
        user_name: formValues?.email?.trim(),
        group_ids: groupIds,
        assignments: {
          roles_grn: [],
          scopes: []
        },
        displayName: formValues?.email?.trim()
      }).then(
        (response) => {
          setIsSubmitting(false)
          closeModal()
          refreshParent()
          if (response?.data?.failed) {
            // Partial success scenario
            const groupsAddedSuccesfully =
              groupIds?.length - response?.data?.failed?.length
            setParentNotification(
              <Markdown>
                {t(`iam:igc_users.add_user_notification_partial_success_msg`, {
                  groupsAddedSuccesfully,
                  groupTotal: groupIds?.length
                })}
              </Markdown>,
              'warning',
              t('iam:igc_users.add_user_notification_success_title')
            )
          } else {
            // Success scenario
            setParentNotification(
              <Typography type="text">
                <Trans>
                  {t('iam:igc_users.add_user_notification_success_msg_v2', {
                    userName: formValues?.email
                  })}
                </Trans>
              </Typography>,
              'info',
              t('iam:igc_users.add_user_notification_success_title')
            )
          }
        },
        (error) => {
          setIsSubmitting(false)
          closeModal()
          setParentNotification(getErrorMessage(error, t), 'error')
        }
      )
    }
    if (isEmpty(formValues?.email?.trim())) {
      setEmailError(t('common:required'))
    } else {
      setIsSubmitting(true)
      validateSAMLAuthzDomain(
        formValues?.email,
        oidcUser,
        t,
        setIsSubmitting,
        setNotificationInfo,
        () =>
          setEmailError(
            t('iam:ccs_attribute.add_saml_user_to_org_workspace_warning', {
              user: t('common:business_object.user'),
              domain: t('common:business_object.domain'),
              organization: t('common:business_object.organization_lowercase'),
              workspaces: t('common:business_object.wkspc_plural')
            })
          ),
        addUser
      )
    }
  }

  return (
    <>
      <ModalDialog
        position="center"
        width="100%"
        height="100%"
        pad="small"
        header={
          <Box alignSelf="center" width="large">
            <ModalHeader
              title={
                <Box direction="column">
                  <Typography
                    type="heading"
                    level="2"
                    testId="add-user-modal-title"
                  >
                    {t('iam:igc_users.add_user_title')}
                  </Typography>
                  <Typography type="paragraph" testId="add-user-modal-subtitle">
                    {t('iam:igc_users.add_user_subtitle')}
                  </Typography>
                </Box>
              }
              onClose={closeModal}
            />
          </Box>
        }
        content={
          <Box direction="column" alignSelf="center" width="large">
            <CCSForm
              value={formValues}
              onChange={setFormValues}
              validate="blur"
              errorMessage=""
              testId="add-user-form"
            >
              <Box direction="column">
                <Box margin={{ top: 'medium', bottom: 'medium' }}>
                  <EmailTextInput
                    isSubmitting={isSubmitting}
                    errorMsg={emailError}
                    setErrorMsg={setEmailError}
                    setNotificationInfo={setNotificationInfo}
                  />
                </Box>
                <Box margin={{ top: 'medium', bottom: 'medium' }}>
                  <AddToGroup
                    selectedGroups={selectedGroups}
                    setSelectedGroups={setSelectedGroups}
                    setNotificationInfo={setNotificationInfo}
                  />
                </Box>
              </Box>
            </CCSForm>
            <Box direction="row" gap="small">
              <Button
                primary
                type="submit"
                onClick={handleAddUser}
                label={t('common:add')}
                testId="add-user-add-btn"
              />
              <Button
                label={t('common:cancel')}
                onClick={closeModal}
                testId="add-user-cancel-btn"
              />
            </Box>
            {isSubmitting && (
              <Loader
                modal
                testId="add-user-loader"
                size="xxsmall"
                modalTitle={t('iam:users.loader_title')}
                modalSubTitle={t('iam:users.loader_subtitle')}
              />
            )}
          </Box>
        }
        onClose={closeModal}
        testId="add-user-dialog"
      />
      {notifMsg && displayNotification(notifMsg, notifSeverity, setNotifMsg)}
    </>
  )
}

AddUserModal.propTypes = {
  /**
   * Callback to show add user modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * Used to refresh parent upon adding user
   */
  refreshParent: PropTypes.func,

  /**
   * Set notification
   */
  setParentNotification: PropTypes.func
}

export default AddUserModal
