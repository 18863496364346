// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Buffer } from 'buffer'

import React from 'react'
import lodashGet from 'lodash/get'
import find from 'lodash/find'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'
import { Storage, Servers, Beacon, Switch, Gateway, Cube } from 'grommet-icons'
import round from 'lodash/round'
import gt from 'lodash/gt'
import CryptoJS from 'crypto-js'

import { useCCSContext } from '../context/ccs-context'

import { getApiErrorMessage } from './error-handling-utils'
import { get } from './api-utils'
import { isGLOP, getCustomerAccount } from './feature-flag-utils'

/**
 * place all common util methods/ functions here
 */
/**
 * renameKeys
 * @param {*} keysMap - equivalent obj with new keys map
 * @param {*} obj - original object that need to be changed
 */
export const renameKeys = (keysMap, obj = {}) => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...(keysMap[key] && { [keysMap[key]]: obj[key] })
    }),
    {}
  )
}

/**
 * get query strings with keys
 */
/**
 * getQueryStringFromKeys
 * @param keys - ['app', 'url']
 * return strings: app=1234&url=google.com
 */
export const getQueryStringFromKeys = (keys) => {
  let output = ''
  const params = new URL(document.location).searchParams
  keys.forEach((key) => {
    const value = params.get(key)
    if (value && value !== '') {
      output = output === '' ? `${key}=${value}` : `${output}&${key}=${value}`
    }
  })
  return output
}

/**
 * Compose Pagination 'showing start-end of total' in i18n
 */
/**
 * getPaginationShowIdx
 * @param page: current pagination page
 * @param totalItems: total counts
 * @param itemsPerPage: items per page
 * return i18n string: Showing {start}-{end} of {total}
 */
export const getPaginationShowIdx = (page, totalItems, itemsPerPage, t) => {
  const startRow = page > 1 ? (page - 1) * itemsPerPage + 1 : 1
  const endRow =
    startRow + itemsPerPage - 1 < totalItems
      ? startRow + itemsPerPage - 1
      : totalItems

  const returnTrans = t('common:showing_pagination_info', {
    start: startRow,
    end: endRow,
    total: totalItems
  })
  return returnTrans
}
/**
 * getSupportedLocales
 * @returns supported languages
 */
export const getSupportedLocales = () => {
  return [
    { name: 'Chinese', locale: 'zh-CN', code: 'cn' },
    { name: 'English', locale: 'en-US', code: 'en' },
    { name: 'French', locale: 'fr-FR', code: 'fr' },
    { name: 'German', locale: 'de-DE', code: 'de' },
    { name: 'Japanese', locale: 'ja-JP', code: 'jp' },
    { name: 'Korean', locale: 'ko-KR', code: 'ko' },
    { name: 'Portuguese', locale: 'pt-BR', code: 'br' },
    { name: 'Russian', locale: 'ru-RU', code: 'ru' },
    { name: 'Spanish', locale: 'es-ES', code: 'es' },
    { name: 'Italian', locale: 'it-IT', code: 'it' }
  ]
}
/**
 * getLocaleMap
 * @param {*} userLangCode
 * @returns the language code in 'en-US' format of the equivalent 'en'
 */
export const getLocaleMap = (userLangCode) => {
  // For now, use this structure for managing the languages. We need the name for the display, the locale
  // for setting language in i18n (as defined in i18n.js and the public/locales folder), and the code for
  // backend API. This will likely changes when CCS-2778 is ready.
  const langFound = find(getSupportedLocales(), ['code', userLangCode])
  return (langFound && langFound.locale) || 'en-US'
}

/**
 * set the language for dayjs timestamp conversion
 */
/**
 * setDayJSLang
 * @param {*} userLangCode - language code chosen by the user, e.g., de, es, etc.
 */
export const setDayJSLang = (userLangCode) => {
  // Here we use the user's preferred language to obtain the language code, e.g., 'es' for Spanish, and store
  // it in dayJSLang. Then the setDayJSLang function uses that code to call the dayjs.locale(dayJSLang) which
  // in turn converts all dayjs strings to the chosen language.
  let dayJSLang = userLangCode
  dayjs.extend(localizedFormat)
  if (userLangCode === 'cn') dayJSLang = 'zh-cn'
  if (userLangCode === 'br') dayJSLang = 'pt-br'
  if (userLangCode === 'jp') dayJSLang = 'ja'
  /* eslint-disable global-require */
  require(`dayjs/locale/${dayJSLang}.js`) // eslint-disable-line import/no-dynamic-require
  /* eslint-enable */
  dayjs.locale(dayJSLang)
}

export const useIdleTimeout = () => {
  const navigate = useNavigate()
  const { idleTimeout } = useCCSContext()
  const timeout = idleTimeout || 5 * 60 * 100
  const { start, reset, pause } = useIdleTimer({
    timeout,
    startOnMount: false,
    onIdle: () => {
      sessionStorage.setItem('timedout', true)
      navigate('/sign-out')
    },
    debounce: 500,
    crossTab: true,
    syncTimers: 200
  })

  start()
  return [reset, pause]
}

/**
 * convert file to Base64 format
 */
/**
 * fileToBase64
 * @param {file} file binary
 */
export function fileToBase64(file) {
  if (!file) return ''
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function onload() {
      resolve(reader.result)
    }
    reader.onerror = function onerror(error) {
      reject(error)
    }
  })
}

export function fileToBinaryString(file) {
  if (!file) return ''
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = function onload() {
      resolve(reader.result)
    }
    reader.onerror = function onerror(error) {
      reject(error)
    }
  })
}

export const FOUNDATION_BLOCK_STORAGE = 'FOUNDATION_BLOCK_STORAGE'

export const deviceTypeIcon = {
  STORAGE: <Storage size="medium" />,
  DHCI_STORAGE: <Storage size="medium" />,
  COMPUTE: <Servers size="medium" />,
  DHCI_COMPUTE: <Servers size="medium" />,
  AP: <Beacon size="medium" />,
  GATEWAY: <Gateway size="medium" />,
  SD_WAN_GW: <Gateway size="medium" />,
  SWITCH: <Switch size="medium" />,
  NW_THIRD_PARTY: <Cube size="medium" />,
  PCE: <Servers size="medium" />,
  SENSOR: <Beacon size="medium" />,
  BRIDGE: <Beacon size="medium" />
}

export const displayDeviceTypeEnum = {
  COMPUTE: 'COMPUTE',
  STORAGE: 'STORAGE',
  DHCI_COMPUTE: 'DHCI_COMPUTE',
  DHCI_STORAGE: 'DHCI_STORAGE',
  AP: 'AP',
  SWITCH: 'SWITCH',
  GATEWAY: 'GATEWAY',
  NW_THIRD_PARTY: 'NW_THIRD_PARTY',
  SD_WAN_GW: 'SD_WAN_GW',
  PCE: 'PCE',
  SENSOR: 'SENSOR',
  BRIDGE: 'BRIDGE'
}

export const getContactValue = (firstName, lastName, email, t, contactType) => {
  if (contactType === 'NONGLP') {
    return `${email} (${t('common:email_outside_platform')})`
  }
  if (firstName && lastName) return `${firstName} ${lastName} (${email})`
  if (firstName || lastName) return `${firstName || lastName} (${email})`
  return email
}

export const getUserName = (firstName, lastName, email) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  return firstName || lastName || email
}

export const formatApplySubscriptionError = (error, t) => {
  let backendError = getApiErrorMessage(error, t)
  if (
    isArray(backendError) &&
    backendError?.length &&
    backendError[0]?.status
  ) {
    const group = groupBy(backendError, 'status')
    backendError = Object.keys(group)
      ?.map((msg) => {
        const fields = group[msg]
          ?.map((obj) => {
            return obj?.subscription_key
          })
          ?.join(', ')
        return `${msg}: ${fields}`
      })
      ?.join(' / ')
  }
  return backendError?.toString()
}

export const isCoP = () => {
  const showCOPFeature = lodashGet(window, '$settings.isCoP')
  return showCOPFeature
}

export const checkGotoChooseAccount = (navigate, location) => {
  const { pathname } = location
  const custAccount = JSON.parse(sessionStorage.getItem('account'))
  if (
    !isCoP() &&
    !custAccount &&
    pathname !== '/post-onboarding/choose-account' &&
    pathname !== '/post-onboarding/set-up-account' &&
    pathname !== '/preferences-only' &&
    !pathname?.startsWith('/post-onboarding/choose-country')
  ) {
    navigate('/post-onboarding/choose-account')
    return true
  }
  if (isCoP() && !custAccount) {
    navigate('/')
    document.location.reload()
    return true
  }
  return false
}

// COP: to set the session storage if it is redirecting to GLCP from central
export const setFromCentralSessionStorage = () => {
  const fromCentralPage =
    sessionStorage.getItem('cop-central-url')?.replace('login', '') ===
      document.referrer ||
    document?.referrer?.includes(sessionStorage.getItem('cop-central-url'))

  if (isCoP() && sessionStorage.getItem('from-central') === null)
    sessionStorage.setItem('from-central', fromCentralPage)
}

export const isFederatedAccount = () => {
  return sessionStorage?.getItem('isFederated') === 'true'
}
export const deviceTypeOptionsDropdown = (t, LDFlags) => {
  return [
    { label: t('access_points'), value: 'AP' },
    { label: t('switches'), value: 'SWITCH' },
    { label: t('gateways'), value: 'GATEWAY' },
    { label: t('bridge'), value: 'BRIDGE' },
    ...(LDFlags['glcp-dm-silver-peak']
      ? [{ label: t('sd_wan_gateways'), value: 'SD_WAN_GW' }]
      : []),
    { label: t('servers'), value: 'COMPUTE' },
    { label: t('storage'), value: 'STORAGE' },
    ...(!isCoP() && LDFlags['glcp-dm-uxi']
      ? [{ label: t('sensors'), value: 'SENSOR' }]
      : [])
  ]
}

// To display label in auto subscribe dropdown option
export const getDeviceTypeEnum = (deviceType, t) => {
  const deviceTypeEnum = {
    AP: t('access_points'),
    SWITCH: t('switches'),
    GATEWAY: isCoP() ? t('controllers') : t('gateways'),
    COMPUTE: t('compute'),
    DHCI_COMPUTE: t('dhci_compute'),
    DHCI_STORAGE: t('dhci_storage'),
    STORAGE: t('storage'),
    SD_WAN_GW: t('sd_wan_gateways'),
    SENSOR: t('sensors'),
    BRIDGE: t('bridge')
  }
  return deviceTypeEnum[deviceType] || deviceType
}

export const WKSPC_PLURAL = 'wkspc_plural'
export const WKSPC_CAPITALIZED = 'wkspc_capitalized'
export const WKSPC_PLURAL_CAPITALIZED = 'wkspc_plural_capitalized'
export const WKSPC = 'wkspc'

export const getWorkspaceString = (ldFlag, t, type) => {
  let workspaceString = ''
  switch (type) {
    case WKSPC_PLURAL:
      workspaceString =
        (ldFlag && !isCoP()) || isGLOP()
          ? t('common:business_object.wkspc_plural')
          : t('common:business_object.workspace_plural')
      break
    case WKSPC_CAPITALIZED:
      workspaceString =
        (ldFlag && !isCoP()) || isGLOP()
          ? t('common:business_object.wkspc_capitalized')
          : t('common:business_object.workspace_capitalized')
      break
    case WKSPC_PLURAL_CAPITALIZED:
      workspaceString =
        (ldFlag && !isCoP()) || isGLOP()
          ? t('common:business_object.wkspc_plural_capitalized')
          : t('common:business_object.workspace_plural_capitalized')
      break
    case WKSPC:
      workspaceString =
        (ldFlag && !isCoP()) || isGLOP()
          ? t('common:business_object.wkspc')
          : t('common:business_object.workspace')
      break
    default:
      workspaceString = ''
      break
  }
  return workspaceString
}

export const getArticleString = (ldFlag, t) => {
  return ldFlag && !isCoP() ? t('common:article.a') : t('common:article.an')
}

export const RESPONSIVE_PROPS = {
  xsmall: {
    content: {
      gap: 'medium',
      direction: 'column'
    },
    container: {
      pad: {
        horizontal: 'medium',
        top: 'medium'
      }
    }
  },
  small: {
    content: {
      gap: 'medium',
      direction: 'column'
    },
    container: {
      pad: {
        horizontal: 'medium',
        top: 'medium'
      }
    }
  },
  medium: {
    content: {
      gap: 'medium',
      direction: 'column'
    },
    container: {
      pad: {
        horizontal: 'medium',
        top: 'medium'
      }
    }
  },
  large: {
    content: {
      gap: 'large',
      direction: 'row'
    },
    container: {
      pad: {
        horizontal: 'large',
        top: 'medium'
      }
    }
  },
  xlarge: {
    content: {
      gap: 'xlarge',
      direction: 'row'
    },
    container: {
      pad: {
        horizontal: 'large',
        top: 'medium'
      }
    }
  }
}

// CARD_COLUMNS used in Grid with Card component
// ex: <Grid columns={cardColumns} >
// cardColumns is dynamic retrieved through ResponsiveContext
export const CARD_COLUMNS = {
  xsmall: { count: 1, size: 'small' },
  small: { count: 2, size: 'small' },
  medium: { count: 2, size: 'small' },
  large: { count: 'fit', size: 'xsmall' },
  xlarge: { count: 'fit', size: 'small' }
}

export const getServiceDetailsPath = (serviceName) => {
  return serviceName ? serviceName.toLowerCase().split(' ').join('-') : ''
}

export const navigateToServicePageViaSubscription = async ({
  navigate,
  appId,
  appName,
  appSlug,
  LDFlags,
  oidcUser
}) => {
  let detailsPath = ''
  if (!LDFlags['glcp-service-centric-experience-phase-1']) {
    navigate(`/applications/installed-apps/${appId}/service-subs`)
  } else {
    if (LDFlags['glcp-service-registry']) {
      const apiResp = await get(
        '/service-catalog/v1alpha1/service-offers',
        { application_id: appId },
        oidcUser.access_token
      )
      const serviceId = apiResp?.data?.items?.find(
        (val) => val.slug === appSlug
      )?.id
      detailsPath = serviceId
    } else {
      detailsPath = getServiceDetailsPath(appName)
    }
    if (detailsPath) {
      const serviceDetailsPath = `/services/service-catalog/${detailsPath}/service-subs`
      navigate(serviceDetailsPath)
    }
  }
}

/**
 * Calculates the SHA256 checksum of a file.
 * @param {File} file - The File object for which to calculate the checksum.
 * @param {Object} cancelFlag - A ref object with a `current` property indicating whether the calculation should be canceled.
 * @param {function} progressCallback - A callback function that receives the progress percentage as its parameter.
 * @returns {Promise<string>} A Promise that resolves with the calculated checksum or rejects if the calculation is canceled or encounters an error.
 */
export const calculateChecksum = (file, cancelFlag, progressCallback) => {
  const chunkSize = 20 * 1024 * 1024 // 2 MB chunks
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    let currentChunk = 0
    const totalChunks = Math.ceil(file.size / chunkSize)

    // Initialize the SHA-256 hasher
    const hasher = CryptoJS.algo.SHA256.create()

    const loadNextChunk = () => {
      if (cancelFlag?.current) {
        reject(new Error('Calculation cancelled'))
        return
      }

      const start = currentChunk * chunkSize
      const end = Math.min(start + chunkSize, file.size)
      const blob = file.slice(start, end)
      fileReader.readAsArrayBuffer(blob)
    }

    fileReader.onload = (e) => {
      const chunk = new Uint8Array(e.target.result)
      hasher.update(CryptoJS.lib.WordArray.create(chunk))

      currentChunk += 1

      // Calculate progress percentage
      if (progressCallback) {
        const progress = round((currentChunk * 100) / totalChunks, 2)
        progressCallback(progress)
      }

      if (currentChunk < totalChunks) {
        loadNextChunk()
      } else {
        // Finalize the hash and resolve the promise
        const finalHash = hasher.finalize()
        const calculatedChecksum = finalHash.toString(CryptoJS.enc.Hex)
        resolve(calculatedChecksum)
      }
    }

    fileReader.onerror = (error) => {
      reject(error)
    }

    loadNextChunk()
  })
}

/**
 * Compare the versions.
 * @param {String} recommendedVersion - The recommendedVersion exp: 1.1.0.
 * @param {String} currentVersion - The currentVersion exp: 1.0.0.
 * @returns {Boolean} return true if recommended version greater than current version else return false .
 */
export const isGreaterVersion = (recommendedVersion, currentVersion) => {
  return gt(recommendedVersion, currentVersion)
}

export const AUDIT_LOG_CATEGORY = {
  SUBSCRIPTION_MANAGEMENT: 'Subscription Management',
  DEVICE_MANAGEMENT: 'Device Management',
  AUTHORIZATION: 'Authorization'
}

export const currentLanguagePreference = () => {
  return localStorage.getItem('i18nextLng') || 'en-US'
}

export const getDeviceTypeLabel = (t, type) => {
  const deviceTypeMap = {
    AP: t('access-point'),
    SWITCH: t('switch'),
    GATEWAY: isCoP() ? t('controller') : t('gateway'),
    PCE: t('private_cloud_enterprise'),
    SD_WAN_GW: t('sd_wan_gateway'),
    SENSOR: t('sensor'),
    NW_THIRD_PARTY: t('third_party'),
    COMPUTE: t('server'),
    DHCI_COMPUTE: t('server'),
    STORAGE: t('storage'),
    DHCI_STORAGE: t('storage'),
    BRIDGE: t('bridge')
  }

  return deviceTypeMap[type] || type
}

/**
 * Returns a string value for the given city and state combination.
 * @param {String} city - The city name
 * @param {String} stateRegion - The state or region name
 * @returns {String}
 * - when both city and state are populated, returns city and state into a comma separated string. exp: "Chicago, Illinois"
 * - when only city is populated - returns a string of the city. exp: "Chicago"
 * - when only state is populated - returns a string of the state. exp: "Illinois"
 */
export const joinCityState = (city = '', stateRegion = '') => {
  let citystate = null
  if (city && stateRegion) citystate = `${city}, ${stateRegion}`
  else if (city) citystate = `${city}`
  else citystate = `${stateRegion}`
  return citystate
}

/**
 * Gets the country name for the given country code.
 * @param {String} code - The country code. exp: "IE"
 * @param {List} countryList - The list of countries to compare against the given country code.
 * @returns {String} - The country name assciated with the given country code. exp: "Ireland"
 */
export const getCountryName = (code, countryList) => {
  const foundCountry = countryList?.find((country) => country?.code === code)
  return foundCountry?.name ? foundCountry.name : code
}

/**
 * Gets the country code for the given country name.
 * @param {String} name - The country name. exp: Ireland
 * @param {List} countryList - The list of countries to compare against the given country code
 * @returns {String} - The country code assciated with the given country name. exp: "IE"
 */
export const getCountryCode = (name, countryList) => {
  const foundCode = countryList?.find((country) => country?.name === name)
  return foundCode?.code ? foundCode.code : ''
}

/**
 * Updates the GLCP Page Header with the given workspace name.
 * @param {String} workspaceName - The workspace name
 */
export const updateWorkspaceNameInHeader = (workspaceName) => {
  const header = document.querySelector('greenlake-header')
  if (header) {
    header.workspace = workspaceName
  }
}

/**
 * Creates a new string from the given string but with any and all hyphen characters (i.e "-") removed and returns this new string.
 * @param {String} str - the given string. exp: "aaaaaaaa-bbbb-cccc-eeee-ffffffffffff"
 * @returns {String} - the given string with any and all hyphens removed. "aaaaaaaabbbbcccceeeeffffffffffff"
 */
export const removeAllHyphensFromString = (str) => {
  return str?.replace(/-/g, '')
}

/**
 * Compares whether two given strings are equal, ignoring any hyphens.
 * This is particularly useful when comparing Globally Unique Identifiers (GUIDs).
 * @param {String} str1 - the given string. exp: "aaaaaaaa-bbbb-cccc-eeee-ffffffffffff" or "aaaaaaaabbbbcccceeeeffffffffffff"
 * @param {String} str2 - the given string. exp: "aaaaaaaa-bbbb-cccc-eeee-ffffffffffff" or "aaaaaaaabbbbcccceeeeffffffffffff"
 * @returns
 * - true, if the given strings (ignoring any hyphens) are equal.
 * - false, if the given strings (ignoring any hyphens) are not equal.
 */
export const areGuidsEqual = (str1, str2) => {
  const strOne = removeAllHyphensFromString(str1)
  const strTwo = removeAllHyphensFromString(str2)
  return strOne === strTwo
}

export const isCurrentlyLoggedInWorkspace = (workspaceId) => {
  return areGuidsEqual(workspaceId, getCustomerAccount()?.platform_customer_id)
}

/**
 * Returns the keys required to connect LaunchDarkly, Amplitude and NewRelic
 * @returns ldkey, aPID, aAPIKey
 */
export const decryptSettingsKeys = async (settingsObj) => {
  const splitKeys = async (settings) => {
    const keyBlob = settings?.skbfbsfivldaanr
    return keyBlob.split('h22eg15m')
  }

  const decryptData = async (encryptedData, key, iv) => {
    const keyBuffer = Buffer.from(key, 'base64')
    const ivBuffer = Buffer.from(iv, 'base64')

    const cryptoKey = await window.crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-CBC' },
      false,
      ['decrypt']
    )

    const decrypted = await window.crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: ivBuffer },
      cryptoKey,
      Buffer.from(encryptedData, 'base64')
    )

    return new TextDecoder('utf-8').decode(decrypted)
  }

  const encKeysArr = await splitKeys(settingsObj)
  const [
    key,
    iv,
    LDKey,
    APID,
    AAPIKey,
    NRAcctID,
    NRTrustKey,
    NRAgentID,
    NRLicKey,
    NRAppID
  ] = encKeysArr

  const keyBuffer = Buffer.from(key, 'hex')
  const ivBuffer = Buffer.from(iv, 'hex')

  const decryptKey = async (keyToDecrypt) => {
    return decryptData(keyToDecrypt, keyBuffer, ivBuffer).then((dKey) => dKey)
  }
  const keys = {
    ldKey: LDKey.length > 0 ? await decryptKey(LDKey) : undefined,
    aPID: APID.length > 0 ? await decryptKey(APID) : undefined,
    aAPIKey: AAPIKey.length > 0 ? await decryptKey(AAPIKey) : undefined,
    nrAcctID: NRAcctID.length > 0 ? await decryptKey(NRAcctID) : undefined,
    nrTrustKey:
      NRTrustKey.length > 0 ? await decryptKey(NRTrustKey) : undefined,
    nrAgentID: NRAgentID.length > 0 ? await decryptKey(NRAgentID) : undefined,
    nrLicKey: NRLicKey.length > 0 ? await decryptKey(NRLicKey) : undefined,
    nrAppID: NRAppID.length > 0 ? await decryptKey(NRAppID) : undefined
  }
  return keys
}

export const libKeys = async () => {
  const decryptedKeys = await decryptSettingsKeys(window.$settings)
  return decryptedKeys
}

export const LIFECYCLE_STATE = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING'
})

// Function to hash string
export const hashString = (str) => {
  const hasher = CryptoJS.algo.SHA256.create()
  const chunk = new Uint8Array(str)
  hasher.update(CryptoJS.lib.WordArray.create(chunk))
  const finalHash = hasher.finalize()
  return finalHash.toString(CryptoJS.enc.Hex)
}
