// Copyright 2025 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect, useCallback } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Typography,
  Button,
  DataTable,
  ActionButton
} from '../../../../components'
import { get } from '../../../../utils/api-utils'
import { getPaginationShowIdx } from '../../../../utils/common-utils'
import { AddBooksModal, DeleteBookModal } from '../components'

const BookInventoryPage = () => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['tutorial'])
  const [bookList, setBookList] = useState([])
  const [addBookModalOpen, setBookModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [deleteBooksModalOpen, setDeleteBooksModalOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [mode, setMode] = useState('add')

  const columns = [
    {
      property: 'book_year',
      header: t('book_year')
    },
    {
      property: 'book_title',
      header: t('book_title')
    },
    {
      property: 'total_pages',
      header: t('total_pages')
    },
    {
      property: 'rating',
      header: t('book_rating')
    },
    {
      property: 'actions',
      header: '',
      render: (item) => {
        return (
          <ActionButton
            showOneActionAsDropDown
            testId="edit-book-button"
            actions={[
              {
                label: t('edit'),
                onClick: () => {
                  setSelectedRow(item)
                  setMode('edit')
                  setBookModalOpen(true)
                }
              }
            ]}
          />
        )
      }
    }
  ]
  const handleClickRow = () => {
    if (selectedRows?.length) {
      // Create array of book ids
      const selectedBookList = bookList?.filter((value) => {
        return selectedRows?.includes(value.id)
      })
      setSelectedRows(selectedBookList || [])
    } else setSelectedRows([])
    setDeleteBooksModalOpen(true)
  }
  // pagination
  const [totalBooks, setTotalBooks] = useState(50)
  const itemsPerPage = 5
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalBooks, itemsPerPage, t)
  const pagination = {
    totalItems: totalBooks,
    itemsPerPage,
    page,
    setPage,
    pageIdxInfo
  }
  // search
  const [searchTerm, setSearchTerm] = useState('')

  const fetchBookList = useCallback(() => {
    let url = `/book-catalog/ui/v1/book?limit=${itemsPerPage}&offset=${
      (page - 1) * itemsPerPage
    }`
    if (searchTerm.length > 2) url = `${url}&search_string=${searchTerm}`

    get(`${url}`, {}, oidcUser.access_token).then(
      (resp) => {
        setBookList(resp.data.items)
        setTotalBooks(resp.data.pagination.total_count)
      },
      () => {
        setBookList([])
      }
    )
  }, [oidcUser.access_token, page, searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchBookList()
  }, [fetchBookList])

  const onSubmitSuccess = () => {
    fetchBookList()
  }
  return (
    <Box width="100%" margin={{ right: 'large' }}>
      {addBookModalOpen && (
        <AddBooksModal
          title={mode === 'add' ? t('add_book') : t('edit_book')}
          subtitle={mode === 'add' ? t('add_new_books') : t('edit_new_books')}
          setBookModalOpen={setBookModalOpen}
          onSubmitSuccess={onSubmitSuccess}
          selectedRow={selectedRow}
          mode={mode}
        />
      )}
      {deleteBooksModalOpen && (
        <DeleteBookModal
          onSetOpen={setDeleteBooksModalOpen}
          onSetClose={fetchBookList}
          selectedBooks={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      <Box gap="xsmall" pad={{ bottom: 'medium' }}>
        <Box direction="row" justify="between">
          <Typography
            level="2"
            type="heading"
            weight="normal"
            testId="book-inventory-page-title"
          >
            {t('book_inventory')}
          </Typography>
          <Button
            label={t('add_book')}
            primary
            onClick={() => {
              setMode('add')
              setBookModalOpen(true)
            }}
            testId="add-books-modal-btn"
          />
        </Box>
        <Typography
          type="paragraph"
          size="medium"
          testId="book-inventory-page-subtitle"
          border={{ side: 'bottom', color: '#CCCCCC' }}
        >
          {t('book_inventory_subtitle')}
        </Typography>
      </Box>
      <Box margin={{ top: 'medium' }}>
        <DataTable
          pagination={pagination}
          grid={{
            columns,
            data: bookList,
            pad: { body: { horizontal: 'small', vertical: 'small' } }
          }}
          search={{
            onSearchValueChange: (val) => {
              setSearchTerm(val)
              setPage(1)
            },
            placeholder: t('search_placeholder')
          }}
          summary={{
            entityName: t('selected_book_text')
          }}
          testId="book-datatable"
          selection={{
            bulkActions: (
              <Box direction="row" gap="medium">
                {selectedRows.length > 0 && (
                  <Button
                    label="Delete"
                    secondary
                    testId="delete-users-btn"
                    onClick={() => {
                      handleClickRow()
                    }}
                  />
                )}
              </Box>
            ),
            onSelectionChange: (rows) => setSelectedRows(rows),
            primaryKey: 'id'
          }}
        />
      </Box>
    </Box>
  )
}

export { BookInventoryPage }
