// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Markdown } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Typography,
  Loader,
  ModalDialog,
  CCSForm,
  ButtonGroup,
  FormInput
} from '../../../../../components'
import { get, getErrorMessage } from '../../../../../utils/api-utils'

const DeleteSSOModal = ({
  onSetOpen,
  onConfirmDelete,
  domainName,
  recoveryEmail,
  errorMessage,
  setErrorMessage,
  submitDisabled
}) => {
  const { t } = useTranslation(['authn'])
  const { oidcUser } = useReactOidc()
  const [deleteRecoveryEmail, setDeleteRecoveryEmail] = useState(null)
  const [loading, setLoading] = useState(false)

  const validateConfirmationMessage = (inputMessage) => {
    if (inputMessage !== 'DELETE') {
      return t('saml_sso.invalid_keyword')
    }
    return true
  }

  useEffect(() => {
    const getRecoveryEmail = async () => {
      try {
        setLoading(true)
        const response = await get(
          `/authn/v1/saml/config/${domainName}`,
          {},
          oidcUser?.access_token
        )
        setDeleteRecoveryEmail(response?.data?.recovery_user?.recovery_email)
        setLoading(false)
      } catch (error) {
        setErrorMessage(getErrorMessage(error, t))
        setLoading(false)
      }
    }
    if (!recoveryEmail) {
      getRecoveryEmail()
    }
  }, [oidcUser?.access_token, t, recoveryEmail, setErrorMessage, domainName])

  return (
    <>
      {loading ? (
        <Box direction="row" align="center" justify="center" pad="medium">
          <Loader testId="delete-modal-loader" />
        </Box>
      ) : (
        <ModalDialog
          width="large"
          content={
            <CCSForm
              errorMessage={errorMessage}
              onSubmit={onConfirmDelete}
              testId="delete-sso-form"
            >
              <>
                <Box>
                  <Typography
                    type="heading"
                    level={2}
                    testId="delete-sso-title"
                  >
                    {t('saml_sso.delete_connection_header')}
                  </Typography>
                  <Typography
                    type="text"
                    size="medium"
                    testId="delete-sso-connection-message"
                  >
                    <Markdown data-testid="delete-sso-subtitle">
                      {t('saml_sso.delete_sso_connection_msg', {
                        domainName
                      })}
                    </Markdown>
                    <ul>
                      <li>{t('saml_sso.delete_sso_connection_point_1')}</li>
                      <li>{t('saml_sso.delete_sso_connection_point_2')}</li>
                      {recoveryEmail || deleteRecoveryEmail ? (
                        <li>
                          <Markdown>
                            {t('saml_sso.delete_sso_connection_point_3', {
                              recoveryUser: recoveryEmail || deleteRecoveryEmail
                            })}
                          </Markdown>
                        </li>
                      ) : null}
                      <li>{t('saml_sso.delete_sso_connection_point_4')}</li>
                    </ul>
                    <Typography
                      type="text"
                      testId="delete-sso-connection-confirmation"
                    >
                      {t('saml_sso.delete_sso_connection_confirmation')}
                    </Typography>
                    <FormInput
                      required
                      name="confirmation_message"
                      inputType="text"
                      label={t(
                        'manage_account.saml_sso.static_auth_modal.confirmation_label'
                      )}
                      validate={validateConfirmationMessage}
                      testId="delete-sso-confirmation-field"
                    />
                  </Typography>
                </Box>
                <Box margin={{ vertical: 'small' }}>
                  <ButtonGroup
                    buttonList={[
                      {
                        id: 1,
                        label: t('saml_sso.cancel'),
                        default: true,
                        testId: 'cancel-modal-btn',
                        onClick: () => {
                          onSetOpen(false)
                        }
                      },
                      {
                        id: 2,
                        label: t('users.delete'),
                        primary: true,
                        type: 'submit',
                        disabled: submitDisabled,
                        testId: 'delete-submit-btn'
                      }
                    ]}
                    testId="delete-sso-action-buttons"
                  />
                </Box>
              </>
            </CCSForm>
          }
          onClose={() => onSetOpen(false)}
          testId="delete-sso-confirmation-modal"
        />
      )}
    </>
  )
}

DeleteSSOModal.propTypes = {
  /**
   * Function to display modal
   */
  onSetOpen: PropTypes.func.isRequired,
  /**
   * Function on delete confirmation
   */
  onConfirmDelete: PropTypes.func.isRequired,
  /**
   * Value of the domain's name
   */
  domainName: PropTypes.string.isRequired,
  /**
   * Value of the recovery email
   */
  recoveryEmail: PropTypes.string,
  /**
   * Value of the error message
   */
  errorMessage: PropTypes.string.isRequired,
  /**
   * Function to set error message
   */
  setErrorMessage: PropTypes.func.isRequired,
  /**
   * Bool to disable submit
   */
  submitDisabled: PropTypes.bool.isRequired
}

DeleteSSOModal.defaultProps = {
  recoveryEmail: null
}

export default DeleteSSOModal
