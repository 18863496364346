// Copyright 2025 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box, Nav } from 'grommet'
import { Previous } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useNavigate, useLocation } from 'react-router-dom'

import { Layout } from '../../commoncomponents/layout/Layout'
import { AvatarInfo, Button, Typography } from '../../components'
import { isCoP, isGLOP } from '../../utils/feature-flag-utils'
import { isFederatedAccount } from '../../utils/common-utils'

import ProfileRouter from './router'

const Profile = () => {
  const { oidcUser } = useReactOidc()
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const user = {
    name: oidcUser.profile.given_name || oidcUser.profile.email,
    email: oidcUser.profile.email
  }

  const handleBackBtnClick = () => {
    navigate('/post-onboarding/choose-account')
  }

  const leftMenuOptions = [
    {
      id: 1,
      label: 'Profile Details',
      routeTo: pathname.includes(':accountlist')
        ? '/profile/details:accountlist'
        : '/profile/details',
      testId: 'profile-details-link'
    },
    {
      id: 2,
      label: 'Security',
      routeTo: pathname.includes(':accountlist')
        ? '/profile/security:accountlist'
        : '/profile/security',
      testId: 'profile-security-link',
      isHidden: isCoP()
    },
    {
      id: 3,
      label: 'Password',
      routeTo: pathname.includes(':accountlist')
        ? '/profile/password:accountlist'
        : '/profile/password',
      testId: 'profile-password-link',
      isHidden: isFederatedAccount()
    },
    {
      id: 4,
      label: 'Preferences',
      routeTo: pathname.includes(':accountlist')
        ? '/profile/preferences:accountlist'
        : '/profile/preferences',
      testId: 'profile-preferences-link'
      // },
      // {
      //   id: 5,
      //   label: 'Notifications',
      //   routeTo: '/profile/notifications',
      //   testId: 'profile-notifications-link'
      // },
      // {
      //   id: 6,
      //   label: 'Sessions',
      //   routeTo: '/profile/sessions',
      //   testId: 'profile-sessions-link'
    }
  ]

  return (
    <Box data-testid="profile-page">
      {pathname.includes(':accountlist') && (
        <Box direction="row" justify="start" pad="small">
          <Button
            default
            margin={{ vertical: 'xsmall', horizontal: 'small' }}
            label="Account Selection"
            icon={<Previous />}
            onClick={handleBackBtnClick}
            testId="account-selection-btn"
          />
        </Box>
      )}
      <Box gap="xsmall" margin={{ horizontal: 'xlarge', top: 'medium' }}>
        <Box direction="row" gap="medium">
          <AvatarInfo
            avatarOnly
            avatarSize="large"
            avatarChar={
              user.name && user.name.split(' ').length > 1 ? 'two' : 'one'
            }
            primaryInfo={user.name}
            secondaryInfo={user.email}
            testId="user-info-no-avatar"
          />
          <Box>
            <Typography
              level="1"
              type="heading"
              weight="normal"
              testId="page_title"
            >
              My Profile
            </Typography>
            <Typography
              size="large"
              type="text"
              weight="normal"
              testId="page_description"
            >
              Manage your HPE GreenLake user profile.
            </Typography>
          </Box>
        </Box>
        <Box direction="row" gap="xlarge" margin={{ top: 'large' }}>
          <Nav direction="column" background="white" gap="small">
            {leftMenuOptions.map(
              (item) =>
                !item.isHidden && (
                  <Button
                    key={item.id}
                    onClick={() => navigate(item.routeTo)}
                    plain
                    active={pathname === item.routeTo}
                    testId={item.testId}
                  >
                    <Box
                      pad={{ vertical: 'xsmall', left: 'small' }}
                      width="small"
                    >
                      <Typography
                        size={pathname === item.routeTo ? 'xsmall' : 'medium'}
                        type={pathname === item.routeTo ? 'heading' : 'text'}
                        level={pathname === item.routeTo ? 4 : undefined}
                        testId="details_description"
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  </Button>
                )
            )}
          </Nav>
          <Box fill>
            <ProfileRouter user={user} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const ProfilePage = () => {
  const { pathname } = useLocation()
  return pathname.includes(':accountlist') ||
    (isGLOP() &&
      JSON.parse(sessionStorage.getItem('account'))?.platform_customer_id ===
        undefined) ? (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      <Profile />
    </Layout>
  ) : (
    <Layout>
      <Profile />
    </Layout>
  )
}

export default ProfilePage
