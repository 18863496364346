// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react'
import { Anchor, Box, Page, PageContent, PageHeader } from 'grommet'
import { useNavigate, useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { Previous, Organization, Group } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, Card } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import Markdown from '../../../../commoncomponents/Markdown'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../context/authz-context'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'
import { AssignRoleModal } from '../../../../commoncomponents/identity/assign-roles-modal/AssignRoleModal'
import InviteUser from '../../../../commoncomponents/identity/users/InviteUser'
import { displayNotification } from '../../../../utils/notificiation-utils'

const Identity = () => {
  const { t } = useTranslation(['authz', 'common'])
  const location = useLocation()
  const navigate = useNavigate()
  const [inviteUserModal, setInviteUserModal] = useState(false)
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [showNotification, setShowNotification] = useState(null)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const mvsaIamUser = LDFlags['glcp-sa-iam-users-tab']
  const [successMessage, setSuccessMessage] = useState(null)
  const [successTitle, setSuccessTitle] = useState(null)

  useEffect(() => {
    setSuccessTitle(location?.state?.notificationTitle || null)
    const message = location?.state?.notificationDesc ? (
      <Markdown>{location?.state?.notificationDesc}</Markdown>
    ) : null
    setSuccessMessage(message)
    if (
      location?.state?.notificationTitle ||
      location?.state?.notificationDesc
    ) {
      navigate(location.pathname, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  // Allow HPE theme styling to spread onto Typography instance
  const {
    pageHeader: { subtitle }
  } = useContext(ThemeContext)

  const { dispatchAUTHZContext } = useAUTHZContext()
  // clear all the scopes contexts
  useEffect(() => {
    dispatchAUTHZContext({
      type: AUTHZActions.CLEAR_SCOPE_MODAL_DATA
    })
    dispatchAUTHZContext({
      type: AUTHZActions.CLEAR_PREDEFINED_FILTERS
    })
    dispatchAUTHZContext({
      type: AUTHZActions.CLEAR_SCOPE_RESOURCE_INSTANCES
    })
    dispatchAUTHZContext({
      type: AUTHZActions.CLEAR_SCOPE_DATA_MAP
    })
  }, [dispatchAUTHZContext])

  const handleAccountCardClick = (currentItem) => {
    const mainRouter = '/manage-ccs/identity/'
    const routeTo = `${mainRouter}${currentItem.routeTo}`
    navigate(routeTo)
  }
  const handleBackBtnClick = () => {
    navigate('/manage-ccs/home')
  }

  const manageIdentityList = [
    {
      id: 1,
      icon: <Organization size="medium" />,
      title: 'roles_permissions_title',
      description: 'roles_desc',
      routeTo: 'roles',
      testId: 'roles',
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.view'
      }
    },
    ...(mvsaIamUser
      ? [
          {
            id: 2,
            icon: <Group size="medium" />,
            title: 'users_title',
            description: 'users_subtitle',
            routeTo: 'users',
            testId: 'users',
            rbac: {
              permission: 'ccs.accounts.users.view.all',
              resource: '/ccs/accounts/user'
            }
          }
        ]
      : [])
  ]

  return (
    <Page>
      <PageContent>
        {successMessage &&
          displayNotification(
            successMessage,
            'info',
            setSuccessMessage,
            successTitle
          )}
        <InviteUser
          inviteUserOpen={inviteUserModal}
          onSetOpen={setInviteUserModal}
          path="/manage-account/identity"
        />
        <Box data-testid="identity-page">
          <PageHeader
            title={
              <Typography level="1" type="heading" testId="identity-title">
                {t('identity_access')}
              </Typography>
            }
            subtitle={
              <Typography
                type="text"
                testId="identity-description"
                {...subtitle}
              >
                {t('id_access_desc')}
              </Typography>
            }
            parent={
              <Anchor
                label={
                  mvsaIamUser
                    ? t('common:nav_bar.home', {
                        account: getWorkspaceString(
                          showWorkspaceString,
                          t,
                          WKSPC_CAPITALIZED
                        )
                      })
                    : t('common:button.manage_account', {
                        account: getWorkspaceString(
                          showWorkspaceString,
                          t,
                          WKSPC_CAPITALIZED
                        )
                      })
                }
                icon={<Previous />}
                onClick={handleBackBtnClick}
                data-testid="manage-account-btn"
              />
            }
          />
          <Box data-testid="identity-access" gap="large" margin="medium">
            <Box>
              <Typography
                type="heading"
                level="2"
                testId="identity_manage_section_title"
              >
                {t('manage')}
              </Typography>
              <Box direction="row" wrap>
                {manageIdentityList &&
                  manageIdentityList.map((item) =>
                    !item.isHidden ? (
                      <VisibilityWrapper
                        key={item.id}
                        hideFor={item.hideFor}
                        rbac={item.rbac}
                      >
                        <Card
                          key={item.id}
                          cardWidth="medium"
                          testId={item.testId}
                          margin={{ top: 'medium', right: 'medium' }}
                          height="small"
                          onClick={() => handleAccountCardClick(item)}
                          CustomContent={
                            <Box gap="small" flex={false}>
                              {item.icon}
                              <Box
                                data-testid={`${item.testId}-text-info`}
                                gap="xsmall"
                              >
                                <Typography
                                  type="heading"
                                  level="3"
                                  testId={`${item.testId}-title`}
                                >
                                  {t(item.title)}
                                </Typography>
                                <Typography
                                  type="text"
                                  testId={`${item.testId}-summary`}
                                >
                                  {t(item.description)}
                                </Typography>
                              </Box>
                            </Box>
                          }
                        />
                      </VisibilityWrapper>
                    ) : null
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
        {showAssignRoleModal && (
          <AssignRoleModal
            setModal={setShowAssignRoleModal}
            setShowNotification={setShowNotification}
          />
        )}
        {showNotification}
      </PageContent>
    </Page>
  )
}

const IdentityPage = () => {
  return <Identity />
}

export default IdentityPage
