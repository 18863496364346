// Copyright 2025 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'

import TutorialHome from './TutorialHome'

const TutorialApplicationRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  console.log('TutorialApplicationRouter enter')
  return (
    <Routes>
      <Route exact path="/" element={<TutorialHome />} />
    </Routes>
  )
}

export default TutorialApplicationRouter
