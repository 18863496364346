// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Anchor, Box, Grid, ResponsiveContext } from 'grommet'
import { ShareRounded } from 'grommet-icons'
import {
  buildSearchBox,
  buildResultList,
  buildFacet,
  buildDateFacet,
  buildFacetManager,
  buildPager,
  buildQuerySummary,
  buildResultTemplatesManager,
  buildDidYouMean,
  loadSearchAnalyticsActions,
  loadSearchActions,
  buildRecentResultsList,
  buildResultsPerPage,
  buildBreadcrumbManager,
  buildRecentQueriesList,
  buildInstantResults
} from '@coveo/headless'
import { Trans, useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ResultList from '../ResultList/ResultList'
import SearchPagination from '../SearchPagination/SearchPagination'
import Facet from '../FacetComponents/Facet/Facet'
import DateFacet, { customDateRanges } from '../FacetComponents/DateFacet'
import FacetManager from '../FacetComponents/FacetManager'
import { Ruler, Typography } from '../../../../components'
import { QuerySummary } from '../QuerySummary'
import QueryCorrection from '../QueryCorrection'
import { urlManagerController } from '../../utils/BindUrlManager'
import RecentlyViewed from '../RecentlyViewed/RecentlyViewed'
import { LINKS, getLink } from '../../../support/utils/links-util'
import ResultsPerPage from '../ResultsPerPage'
import BreadcrumbManager from '../BreadcrumbManager'
import SearchBoxRenderer from '../StandaloneSearchBox/SearchBoxRenderer'

const SearchPage = ({ engine }) => {
  const { t } = useTranslation(['support_hub_search', 'common'])
  const size = useContext(ResponsiveContext)
  const [hasResults, setHasResults] = useState(false)
  const [maxPages, setMaxPages] = useState(false)
  const LDFlags = useFlags()
  const facetFlag = LDFlags['glcp-support-search-data-filter']
  const recentlyViewedFlag = LDFlags['glcp-support-search-recently-viewed']
  const breadcrumbFlag = LDFlags['glcp-support-search-breadcrumbs']

  const handleHasResultsChange = (hasResult) => {
    setHasResults(hasResult)
  }

  const handleMaxPagesChange = (maxPage) => {
    setMaxPages(maxPage > 1)
  }

  useEffect(() => {
    const { logInterfaceLoad } = loadSearchAnalyticsActions(engine)
    const { executeSearch } = loadSearchActions(engine)
    urlManagerController(engine)
    engine.dispatch(executeSearch(logInterfaceLoad()))
  }, [engine])

  const options = {
    numberOfSuggestions: 5,
    clearFilters: false,
    enableQuerySyntax: true
  }

  const searchBoxController = buildSearchBox(engine, { options })
  const recentQueriesListController = buildRecentQueriesList(engine, {
    options: { maxLength: 5 }
  })
  const instantResultsController = buildInstantResults(engine, {
    options: { maxResultsPerQuery: 5, searchBoxId: 'instantResults' }
  })
  const resultListController = buildResultList(engine)
  const resultListManager = buildResultTemplatesManager(engine)
  const resultsPagination = buildPager(engine)
  const breadcrumbManagerController = buildBreadcrumbManager(engine)
  const contenttypeFacetController = buildFacet(engine, {
    options: {
      field: 'contenttype',
      facetId: 'contenttype',
      numberOfValues: 5
    }
  })
  const cloudServicesFacetController = buildFacet(engine, {
    options: {
      field: 'kmtargetl5namelist',
      facetId: 'kmtargetl5namelist',
      numberOfValues: 100
    }
  })
  const documenttypeFacetControler = buildFacet(engine, {
    options: {
      field: 'kmdoctypedetails',
      facetId: 'kmdoctypedetails',
      numberOfValues: 100
    }
  })
  const dateFacetController = buildDateFacet(engine, {
    options: {
      field: 'hpescuniversaldate', // Update this field in sorting condition in BreadcrumbManager.js if it changes in the future
      generateAutomaticRanges: false,
      currentValues: customDateRanges,
      sortCriteria: 'descending',
      facetId: 'dataFacetId'
    }
  })

  const facetManagerController = buildFacetManager(engine)
  const querySummaryController = buildQuerySummary(engine)
  const queryCorrectionController = buildDidYouMean(engine)
  queryCorrectionController.updateQueryCorrectionMode('legacy')
  const recentResultListController = buildRecentResultsList(engine)
  const resultsPerPageOptions = [10, 25, 50, 100]
  const resultPerPageController = buildResultsPerPage(engine, {
    initialState: { numberOfResults: resultsPerPageOptions[1] }
  })

  const columns = {
    xsmall: ['auto'],
    small: ['auto'],
    medium: ['auto'],
    large: ['auto'],
    xlarge: ['medium', 'auto']
  }

  const rows = {
    xsmall: ['auto', 'auto', 'auto', 'auto'],
    small: ['auto', 'auto', 'auto', 'auto'],
    medium: ['auto', 'auto', 'auto', 'auto'],
    large: ['auto', 'auto', 'auto', 'auto'],
    xlarge: ['auto', 'auto', 'auto']
  }

  const areas = {
    xsmall: [
      { name: 'header', start: [0, 0], end: [0, 0] },
      { name: 'nav', start: [0, 1], end: [0, 1] },
      { name: 'main', start: [0, 2], end: [0, 2] },
      { name: 'footer', start: [0, 3], end: [0, 3] }
    ],
    small: [
      { name: 'header', start: [0, 0], end: [1, 0] },
      { name: 'nav', start: [0, 1], end: [1, 1] },
      { name: 'main', start: [0, 2], end: [1, 2] },
      { name: 'footer', start: [0, 3], end: [1, 3] }
    ],
    medium: [
      { name: 'header', start: [0, 0], end: [1, 0] },
      { name: 'nav', start: [0, 1], end: [1, 1] },
      { name: 'main', start: [0, 2], end: [1, 2] },
      { name: 'footer', start: [0, 3], end: [1, 3] }
    ],
    large: [
      { name: 'header', start: [0, 0], end: [1, 0] },
      { name: 'nav', start: [0, 1], end: [1, 1] },
      { name: 'main', start: [0, 2], end: [1, 2] },
      { name: 'footer', start: [0, 3], end: [1, 3] }
    ],
    xlarge: [
      { name: 'header', start: [1, 0], end: [1, 0] },
      { name: 'nav', start: [0, 1], end: [0, 1] },
      { name: 'main', start: [1, 1], end: [1, 1] },
      { name: 'footer', start: [1, 2], end: [1, 2] }
    ]
  }

  return (
    <Grid
      rows={rows[size]}
      columns={columns[size]}
      gap="large"
      areas={areas[size]}
    >
      <Box
        pad={{ top: 'medium' }}
        gridArea="header"
        direction={size === 'xsmall' || size === 'small' ? 'column' : 'row'}
        gap="medium"
        align="center"
      >
        <SearchBoxRenderer
          controller={searchBoxController}
          recentQueriesListController={recentQueriesListController}
          instantResultsController={instantResultsController}
          engine={engine}
        />
        {!recentlyViewedFlag && (
          <RecentlyViewed size={size} controller={recentResultListController} />
        )}
      </Box>
      <Box gridArea="nav">
        <FacetManager controller={facetManagerController} size={size}>
          <Facet
            title={t('cloud_service')}
            controller={cloudServicesFacetController}
            facetFlag={facetFlag}
          />
          <Facet
            title={t('document_type')}
            controller={documenttypeFacetControler}
            facetFlag={facetFlag}
          />
          <Facet
            title={t('content_type')}
            controller={contenttypeFacetController}
            facetFlag={facetFlag}
          />

          <DateFacet
            title={t('last_updated')}
            controller={dateFacetController}
            facetFlag={facetFlag}
          />
        </FacetManager>
      </Box>
      <Box gridArea="main">
        <QuerySummary
          controller={querySummaryController}
          onHasResultsChange={handleHasResultsChange}
        />
        {breadcrumbFlag && (
          <BreadcrumbManager controller={breadcrumbManagerController} />
        )}
        <Ruler
          background="border-strong"
          margin={{ top: 'xsmall', bottom: 'none' }}
        />
        <QueryCorrection controller={queryCorrectionController} />
        <Box>
          <ResultList
            controller={resultListController}
            templatemanager={resultListManager}
            engine={engine}
            size={size}
          />
        </Box>
      </Box>

      <Box
        gridArea="footer"
        gap="small"
        height="small"
        margin={{ bottom: 'medium' }}
      >
        <Box align="center">
          <Typography type="text" width="100%" textAlign="center">
            <Trans
              i18nKey="support_hub_search:footer_message"
              t={t}
              components={{
                1: (
                  <Anchor
                    style={{ textDecoration: 'underline' }}
                    gap="xxsmall"
                    icon={<ShareRounded />}
                    label={t('hpe_communities')}
                    href={getLink(LINKS.HPE_COMMUNITIES)}
                    target="_blank"
                    reverse
                  />
                ),
                2: (
                  <Anchor
                    style={{ textDecoration: 'underline' }}
                    gap="xxsmall"
                    label={t('provide_feedback')}
                    href={getLink(LINKS.FEEDBACK_SEARCH)}
                    icon={<ShareRounded />}
                    target="FEEDBACK_SEARCH"
                    reverse
                  />
                )
              }}
            />
          </Typography>
        </Box>

        <Ruler background="border-strong" />
        {hasResults && (
          <Box
            direction={size === 'xsmall' ? 'column' : 'row'}
            justify="between"
          >
            {maxPages && (
              <ResultsPerPage
                controller={resultPerPageController}
                options={resultsPerPageOptions}
              />
            )}

            <SearchPagination
              controller={resultsPagination}
              onMaxPagesChange={handleMaxPagesChange}
            />
          </Box>
        )}
      </Box>
    </Grid>
  )
}

SearchPage.propTypes = {
  engine: PropTypes.object.isRequired
}

export default SearchPage
