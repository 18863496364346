// Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledBoxLeftTop = styled(Box)`
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  cursor: nw-resize;
`
const StyledBoxRightTop = styled(Box)`
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  cursor: sw-resize;
`

const StyledBoxLeftBottom = styled(Box)`
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  cursor: sw-resize;
`
const StyledBoxRightBottom = styled(Box)`
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: transparent;
  cursor: se-resize;
`

const StyledBoxLeft = styled(Box)`
  width: 0.2rem;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  cursor: col-resize;
`
const StyledBoxRight = styled(Box)`
  width: 0.2rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  cursor: col-resize;
`

const StyledBoxTop = styled(Box)`
  width: 100%;
  height: 0.2rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  cursor: row-resize;
`

const StyledBoxBottom = styled(Box)`
  width: 100%;
  height: 0.2rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  cursor: pointer;
`

const Resizable = ({ chatContainerRef = undefined }) => {
  let actual_width
  let actual_left
  let actual_mouse_x
  let actual_x
  let actual_height
  let actual_top
  let actual_mouse_y
  const minimumHeight = 300
  const minimumWidth = 384

  const getChatContainerDimension = (dimension) => {
    return parseFloat(
      getComputedStyle(chatContainerRef.current, null)
        .getPropertyValue(dimension)
        .replace('px', '')
    )
  }

  const cacheAllActualValues = (e) => {
    actual_width = getChatContainerDimension('width')
    actual_left = getChatContainerDimension('left')
    actual_x = chatContainerRef.current.getBoundingClientRect().left
    actual_mouse_x = e.clientX
    actual_height = getChatContainerDimension('height')
    actual_top = getChatContainerDimension('top')
    actual_mouse_y = e.clientY
  }

  const onLeftResize = (e) => {
    e.preventDefault()
    const newWidth = actual_width - (e.clientX - actual_mouse_x)
    const newLeft = actual_left + (e.clientX - actual_mouse_x)
    if (newWidth > minimumWidth && newLeft >= 0) {
      chatContainerRef.current.style.width = `${
        actual_width - (e.clientX - actual_mouse_x)
      }px`
      chatContainerRef.current.style.left = `${newLeft}px`
    }
  }

  const onRightResize = (e) => {
    e.preventDefault()
    const newWidth = e.clientX - actual_x
    if (newWidth > minimumWidth && e.clientX <= window.innerWidth) {
      chatContainerRef.current.style.width = `${e.clientX - actual_x}px`
    }
  }

  const onTopResize = (e) => {
    e.preventDefault()
    const newHeight = actual_height - (e.clientY - actual_mouse_y)
    const newTop = actual_top + (e.clientY - actual_mouse_y)
    if (newHeight > minimumHeight && newTop >= 0) {
      chatContainerRef.current.style.height = `${
        actual_height - (e.clientY - actual_mouse_y)
      }px`
      chatContainerRef.current.style.top = `${newTop}px`
    }
  }

  const onBottomResize = (e) => {
    e.preventDefault()
    const newWidth = actual_width + (e.clientX - actual_mouse_x)
    const newHeight = actual_height + (e.clientY - actual_mouse_y)
    if (newHeight > minimumHeight && newWidth > minimumWidth) {
      chatContainerRef.current.style.height = `${
        actual_height + (e.clientY - actual_mouse_y)
      }px`
      chatContainerRef.current.style.marginBottom = `${
        actual_mouse_y - e.clientY
      }px`
    }
  }

  const onStopResize = (e) => {
    e.preventDefault()
    window.removeEventListener('mousemove', onLeftResize)
    window.removeEventListener('mousemove', onRightResize)
    window.removeEventListener('mousemove', onTopResize)
    window.removeEventListener('mousemove', onBottomResize)
  }

  const startLeftResizer = (e) => {
    e.preventDefault()
    actual_width = getChatContainerDimension('width')
    actual_left = getChatContainerDimension('left')
    actual_mouse_x = e.clientX
    window.addEventListener('mousemove', onLeftResize)
    window.addEventListener('mouseup', onStopResize)
  }

  const startRightResizer = (e) => {
    e.preventDefault()
    actual_width = getChatContainerDimension('width')
    actual_x = chatContainerRef.current.getBoundingClientRect().left
    window.addEventListener('mousemove', onRightResize)
    window.addEventListener('mouseup', onStopResize)
  }

  const startTopResizer = (e) => {
    e.preventDefault()
    actual_height = getChatContainerDimension('height')
    actual_top = getChatContainerDimension('top')
    actual_mouse_y = e.clientY
    window.addEventListener('mousemove', onTopResize)
    window.addEventListener('mouseup', onStopResize)
  }

  const onTopLeftResize = (e) => {
    e.preventDefault()
    cacheAllActualValues(e)
    window.addEventListener('mousemove', onLeftResize)
    window.addEventListener('mousemove', onTopResize)
    window.addEventListener('mouseup', onStopResize)
  }

  const onBottomRightResize = (e) => {
    e.preventDefault()
    actual_width = getChatContainerDimension('width')
    actual_height = getChatContainerDimension('height')
    actual_x = chatContainerRef.current.getBoundingClientRect().left
    actual_mouse_x = e.clientX
    actual_mouse_y = e.clientY
    if (
      actual_width + (e.clientX - actual_mouse_x) > minimumWidth &&
      actual_height + (e.clientY - actual_mouse_y) > minimumHeight
    ) {
      chatContainerRef.current.style.width = `${
        actual_width + (e.clientX - actual_mouse_x)
      }px`
      chatContainerRef.current.style.height = `${
        actual_height + (e.clientY - actual_mouse_y)
      }px`
    }
    window.addEventListener('mousemove', onBottomResize)
    window.addEventListener('mousemove', onRightResize)
    window.addEventListener('mouseup', onStopResize)
  }

  const onTopRightResize = (e) => {
    e.preventDefault()
    actual_height = getChatContainerDimension('height')
    actual_top = getChatContainerDimension('top')
    actual_mouse_y = e.clientY
    actual_width = getChatContainerDimension('width')
    actual_x = chatContainerRef.current.getBoundingClientRect().left
    actual_mouse_x = e.clientX
    window.addEventListener('mousemove', onRightResize)
    window.addEventListener('mousemove', onTopResize)
    window.addEventListener('mouseup', onStopResize)
  }

  const onBottomLeftResize = (e) => {
    e.preventDefault()
    cacheAllActualValues(e)
    window.addEventListener('mousemove', onLeftResize)
    window.addEventListener('mousemove', onBottomResize)
    window.addEventListener('mouseup', onStopResize)
  }

  return (
    <>
      <StyledBoxLeftTop
        onMouseDown={onTopLeftResize}
        data-testid="left-top-resizer"
      />
      <StyledBoxRightTop
        onMouseDown={onTopRightResize}
        data-testid="on-Top-Right-Resize"
      />
      <StyledBoxLeftBottom
        onMouseDown={onBottomLeftResize}
        data-testid="on-Bottom-Left-Resize"
      />
      <StyledBoxRightBottom
        onMouseDown={onBottomRightResize}
        data-testid="right-bottom-resizer"
      />
      <StyledBoxLeft
        onMouseDown={startLeftResizer}
        data-testid="start-Left-Resizer"
      />
      <StyledBoxRight
        onMouseDown={startRightResizer}
        data-testid="start-Right-Resizer"
      />
      <StyledBoxTop
        onMouseDown={startTopResizer}
        data-testid="start-Top-Resizer"
      />
      <StyledBoxBottom data-testid="start-Bottom-Resizer" />
    </>
  )
}

Resizable.propTypes = {
  chatContainerRef: PropTypes.shape({
    current: PropTypes.object
  })
}

export default Resizable
