// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box, Heading, PageHeader } from 'grommet'
import { FormDown, Previous } from 'grommet-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { pick } from 'lodash'
import dayjs from 'dayjs'

import {
  ActionButton,
  Anchor,
  Loader,
  Tabs,
  Typography
} from '../../../../components'
import { get } from '../../../../utils/api-utils'
import { Layout } from '../../../../commoncomponents/layout/Layout'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { renameKeys } from '../../../../utils/common-utils'
import {
  showActionDropdown,
  getGLCPAppDetails,
  roleKindMapping
} from '../../utils'
import AssignRoleSideDrawer from '../common-components/AssignRoleSideDrawer'
import MSPAssignRoleSideDrawer from '../common-components/MSPAssignRoleSideDrawer'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { useVisibilityContext } from '../../../../context/visibility-context'
import NoViewPermission from '../../../igc-service/commoncomponents/NoViewPermission'
import { isMSP } from '../../../../utils/feature-flag-utils'

import { DeleteRoleConfirmationModal } from './components/DeleteRoleConfirmationModal'
import { DeleteRoleWarningModal } from './components/DeleteRoleWarningModal'
import { PermissionsTab, AssignmentsTab, DetailsTab } from './tabs'
import CreateRoleWizard from './components/create-role-wizard/CreateRoleWizard'

const RoleDetailsContent = () => {
  const { oidcUser } = useReactOidc()
  const location = useLocation()
  /**
   * We need to decode then encode again when we make the API call or it will fail for some reason.
   */
  let { grn } = useParams()
  grn = decodeURIComponent(grn)
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'iam', 'authz'])
  const { rbacPolicies, v2RbacPolicies } = useVisibilityContext()
  const v2CSSAuthView = v2RbacPolicies?.includes('ccs.authorization.view')

  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showEditDetailPanel, setShowEditDetailPanel] = useState(false)
  const [roleDetails, setRoleDetails] = useState({})
  const [role, setRole] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)
  const [openCreateRoleWizard, setOpenCreateRoleWizard] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [statusNotification, setStatusNotification] = useState(false)
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [showPermissionDialog, setShowPermissionDialog] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [applicationId, setApplicationId] = useState('')

  const getManagedBy = useCallback(
    async (resouceProviderResp, roleType) => {
      let managedBy = ''
      if (resouceProviderResp?.data) {
        const appId = resouceProviderResp?.data?.items[0]?.applicationId
        if (appId) {
          setApplicationId(appId)

          /**
           * If the application id is 00000000-0000-0000-0000-000000000000, we set the managedBy (the service name) to be 'HPE GreenLake Platform'
           * The reason why we do this is because the API for getting the service name does not handle this application id.
           * Therefore we handle it on the UI and hardcode it
           *  */
          const glcpInfo = getGLCPAppDetails()
          if (appId !== glcpInfo?.glcpApplicationId) {
            try {
              const applicationResponse = await get(
                `/app-catalog/ui/v1/applications/${appId}`,
                {},
                oidcUser?.access_token
              )
              managedBy = applicationResponse?.data
                ? applicationResponse?.data?.name
                : ''
            } catch (error) {
              setApiError(error)
            }
          } else {
            managedBy = glcpInfo?.glcpApplicationName
          }
        }
      }
      return roleType === 'CUSTOM' ? '' : managedBy
    },
    [oidcUser?.access_token]
  )

  useEffect(() => {
    setLoading(false)
    const retrieveRoleData = async () => {
      try {
        const response = await get(
          `/authorization/v2alpha1/roles?grn=${encodeURIComponent(grn)}`,
          {}
        )
        if (response?.data && response?.data?.items) {
          const roleData = response?.data?.items.find(
            (item) => item?.grn === grn
          )

          const roleDetailsKeys = {
            id: t('iam:roles_details.role_id'),
            name: 'name',
            displayName: t('iam:roles_details.role_name', {
              role: t('common:business_object.role_capitalized')
            }),
            description: t('authz:workspace_group.description'),
            kind: t('iam:roles_details.kind'),
            grn: t('iam:roles_details.grn_title'),
            managedBy: t('iam:roles_details.managedBy'),
            type: t('authz:roles.type'),
            permissions: 'permissions',
            refId: t('iam:roles_details.ref_id'),
            createdAt: t('iam:roles_details.created_at'),
            updatedAt: t('iam:roles_details.updated_at')
          }

          const roleDetailsData = {
            id: roleData?.id,
            name: roleData?.name,
            displayName: roleData?.displayName,
            description: roleData?.description,
            kind: roleData?.kind,
            grn,
            managedBy: '',
            type: roleKindMapping.get(roleData?.kind),
            permissions: roleData?.permissions,
            refId: roleData?.linkedRoleRef,
            createdAt: dayjs(roleData?.createdAt).format('MMMM DD, YYYY'),
            updatedAt: dayjs(roleData?.updatedAt).format('MMMM DD, YYYY')
          }

          let getFormattedObject = (keys, obj) => {
            const filtered = pick(obj, Object?.keys(keys))
            return renameKeys(keys, filtered)
          }
          setRole(roleData)
          setRoleDetails(getFormattedObject(roleDetailsKeys, roleDetailsData))

          /**
           * Get service name using managedBy. The reason we set the role data before this is in case this code fails, we can still set role data
           */
          const providerName =
            roleData?.kind === 'CUSTOM' && roleData?.permissions?.length
              ? roleData?.permissions[0]?.split('.')[0]
              : roleData?.managedBy // In case of custom roles we get manageBy as "", thats why we are setting provider name roleData?.permissions[0]?.split('.')[0]
          const resouceProviderResp = providerName
            ? await get(
                `/authorization/v2alpha1/resource-providers?name=${providerName}`,
                {}
              )
            : null

          roleDetailsData.managedBy = await getManagedBy(
            resouceProviderResp,
            roleData?.kind
          )

          getFormattedObject = (keys, obj) => {
            const filtered = pick(obj, Object?.keys(keys))
            return renameKeys(keys, filtered)
          }
          setRoleDetails(getFormattedObject(roleDetailsKeys, roleDetailsData))
          setLoading(false)
        }
      } catch (error) {
        setApiError(error)
        setLoading(false)
      }
    }
    retrieveRoleData()
  }, [oidcUser?.access_token, grn, showEditDetailPanel, t, getManagedBy])

  const handleBackBtnClick = () => {
    navigate(`/manage-account/identity/roles`)
  }

  const handleTabClick = (i) => {
    setActiveIndex(i)
  }

  const openWarningModal = () => {
    setShowWarningModal(true)
  }

  const closeWarningModal = () => {
    setShowWarningModal(false)
  }

  const openDeleteModal = () => {
    setShowDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleAssignRolesClick = () => {
    setShowAssignRoleModal(true)
  }

  const getRoleActions = (roleKind) => {
    let actions = null
    actions = [
      {
        label: t('iam:roles_permission.add_permission_title'),
        onClick: () => {
          handleTabClick(0)
          setShowPermissionDialog(true)
        },
        visibility: {
          rbac: {
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.edit'
          }
        }
      },
      {
        label: t('iam:role_assignment.assign_role', {
          roles: t('common:business_object.role')
        }),
        onClick: () => {
          handleTabClick(1)
          handleAssignRolesClick()
        },
        visibility: {
          rbac: {
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.edit'
          }
        }
      },
      {
        label: t('iam:roles_details.role_edit'),
        onClick: () => {
          handleTabClick(2)
          setShowEditDetailPanel(true)
        },
        visibility: {
          rbac: {
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.edit'
          }
        }
      },
      // Note - Commented because it might come in MS2
      // {
      //   label: t('iam:roles_details.role_duplicate'),
      //   onClick: () => {
      //     setOpenCreateRoleWizard(true)
      //   },
      //   visibility: {
      //     rbac: {
      //       resource: '/ccs/authorization',
      //       permission: 'ccs.authorization.edit'
      //     }
      //   }
      // },
      {
        label: t('common:delete', {
          role: t('common:business_object.role')
        }),
        onClick: async () => {
          get(
            `/internal-platform-tenant-ui/v2/role-assignments?role-grn=${encodeURIComponent(
              grn
            )}`,
            {}
          ).then(
            (response) => {
              if (response?.data?.role_assignments?.length !== 0)
                openWarningModal()
              else openDeleteModal()
            },
            (error) => {
              setApiError(error)
            }
          )
        },
        visibility: {
          rbac: {
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.delete'
          }
        }
      }
    ]
    // actions[1] should be the assign role action
    return roleKind === 'CUSTOM' ? actions : [actions[1]]
  }

  return loading ? (
    <Box direction="row" align="center" justify="center">
      <Loader testId="role-details-loader" />
    </Box>
  ) : (
    <Box
      data-testid="role-details"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      {apiError && displayApiError(apiError, t, setApiError)}
      <PageHeader
        title={
          <Box direction="row" gap="small">
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.authorization.view'
              }}
            >
              <Heading level="1" weight="normal" margin="none">
                {role?.displayName || location?.state?.name}
              </Heading>
            </VisibilityWrapper>
          </Box>
        }
        subtitle={
          <Box margin={{ top: 'xsmall' }}>
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.authorization.view'
              }}
            >
              {!loading && (
                <Typography
                  type="text"
                  testId="role-description"
                  size="xlarge"
                  weight="lighter"
                >
                  {role?.description || ' '}
                </Typography>
              )}
            </VisibilityWrapper>
          </Box>
        }
        parent={
          <Anchor
            label={t('common:business_object.role_plural_capitalized')}
            icon={<Previous />}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            testId="roles-back-btn"
          />
        }
        actions={
          // TODO: Integrate custom role type with actions
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.authorization.view'
            }}
          >
            {showActionDropdown(rbacPolicies, getRoleActions(role?.kind)) && (
              <ActionButton
                showOneActionAsDropDown
                label={t('common:actions')}
                icon={<FormDown />}
                reverse
                actions={getRoleActions(role?.kind)}
                testId="role-details-action-btn"
                customRenderer={(actionBtn, visibility, idx) => {
                  return (
                    <VisibilityWrapper
                      key={idx} // eslint-disable-line react/no-array-index-key
                      rbac={visibility?.rbac}
                    >
                      {actionBtn}
                    </VisibilityWrapper>
                  )
                }}
              />
            )}
          </VisibilityWrapper>
        }
      />
      {showAssignRoleModal &&
        (isMSP() ? (
          <MSPAssignRoleSideDrawer
            preSelectedRole={role}
            appId={applicationId}
            setShowAssignRoleModal={setShowAssignRoleModal}
            setStatusNotification={setStatusNotification}
            onAssign={() => {
              setRefreshCount((prevRefreshCount) => prevRefreshCount + 1)
            }}
            setActiveTab={() => handleTabClick(0)}
          />
        ) : (
          <AssignRoleSideDrawer
            preSelectedRole={role}
            appId={applicationId}
            setShowAssignRoleModal={setShowAssignRoleModal}
            setStatusNotification={setStatusNotification}
            onAssign={() => {
              setRefreshCount((prevRefreshCount) => prevRefreshCount + 1)
            }}
            setActiveTab={() => handleTabClick(0)}
          />
        ))}
      {statusNotification &&
        displayNotification(
          statusNotification.message,
          statusNotification.severity,
          setStatusNotification,
          statusNotification.title
        )}
      {showWarningModal && (
        <DeleteRoleWarningModal closeWarningModal={closeWarningModal} />
      )}
      {showDeleteModal && (
        <DeleteRoleConfirmationModal
          closeDeleteModal={closeDeleteModal}
          role={role}
          setApiError={setApiError}
        />
      )}

      {openCreateRoleWizard && (
        <CreateRoleWizard
          isSetOpen={setOpenCreateRoleWizard}
          onSuccess={(message) => {
            navigate(`/manage-account/identity/roles`, {
              state: {
                message
              }
            })
          }}
          roleToDuplicate={role}
        />
      )}
      {v2CSSAuthView ? (
        <Box margin={{ top: 'medium' }}>
          <Tabs
            alignControls="start"
            justify="start"
            activeIndex={activeIndex}
            onActive={setActiveIndex}
            tabsList={[
              {
                content: (
                  <PermissionsTab
                    showPermissionDialog={showPermissionDialog}
                    setShowPermissionDialog={setShowPermissionDialog}
                    setStatusNotification={setStatusNotification}
                  />
                ),
                id: 1,
                label: t('iam:roles_permission.permission_title'),
                testId: 'persmissions-tab'
              },
              {
                content: (
                  <AssignmentsTab
                    roleName={role?.displayName}
                    refreshCount={refreshCount}
                    role={role}
                    appId={applicationId}
                    setActiveTab={() => handleTabClick(0)}
                  />
                ),
                id: 2,
                label: t('authz:assignments.role_assignments_plural'),
                testId: 'assignments-tab'
              },
              {
                content: (
                  <DetailsTab
                    roleDetails={roleDetails}
                    showEditDetailPanel={showEditDetailPanel}
                    setShowEditDetailPanel={setShowEditDetailPanel}
                  />
                ),
                id: 3,
                label: t('iam:roles_details.details'),
                testId: 'details-tab'
              }
            ]}
            testId="role-details-tabs"
          />
        </Box>
      ) : (
        <>
          {v2CSSAuthView === false ? (
            <NoViewPermission />
          ) : (
            <Loader testId="perm-loader" />
          )}
        </>
      )}
    </Box>
  )
}

const RoleDetails = () => {
  return (
    <Layout>
      <RoleDetailsContent align="start" justify="start" />
    </Layout>
  )
}

export { RoleDetails }
