// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { Trash } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Anchor, DataTable } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { roleAssignmentEnum, subjectTypesEnum } from '../constants'
import { getScopeInfo } from '../utils'

const RoleAssignmentContent = ({
  handleOnDeleteBtnClick,
  retrievedData,
  subjectType
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['iam', 'common'])

  const handleRowClickForRASource = (datum) => {
    navigate(`/manage-account/identity/groups/${datum?.source_id}`)
  }
  const handleRowClickForRARole = (datum) => {
    navigate(
      `/manage-account/identity/roles/${encodeURIComponent(datum?.role_grn)}`
    )
  }
  return (
    <>
      <DataTable
        grid={{
          columns: [
            {
              header: t('common:business_object.role_capitalized'),
              property: 'meta.role',
              size: 'small',
              sortable: false,
              render: (datum) => {
                return (
                  <Anchor
                    href="#"
                    label={datum?.role_name}
                    testId="users-role-assignment-anchor"
                    onClick={(event) => {
                      event.preventDefault()
                      handleRowClickForRARole(datum)
                    }}
                  />
                )
              }
            },
            {
              header: t('iam:roles_table_headers.service'),
              property: 'meta.service',
              sortable: false,
              render: (datum) => {
                return datum?.service_name || datum?.custom_role_service_name
              }
            },
            {
              header: t('iam:role_assignment.scope_capitalized'),
              property: 'meta.scope',
              sortable: false,
              render: (datum) => {
                return getScopeInfo(datum, navigate, t)
              }
            },
            ...(subjectType === subjectTypesEnum?.USER
              ? [
                  {
                    header: t('iam:role_assignment.source_assignment_title'),
                    property: 'meta.source',
                    sortable: false,
                    render: (datum) => {
                      if (
                        datum?.source === roleAssignmentEnum?.DIRECT ||
                        datum?.source === roleAssignmentEnum?.SSO
                      ) {
                        return datum?.source
                      }
                      return (
                        <Anchor
                          href="#"
                          label={datum?.source}
                          testId="users-role-assignment-anchor"
                          onClick={() => handleRowClickForRASource(datum)}
                        />
                      )
                    }
                  }
                ]
              : []),
            {
              header: '',
              property: t('iam:users.delete'),
              sortable: false,
              render: (datum) => {
                if (datum?.source === 'Direct' || datum?.source === undefined) {
                  return (
                    <VisibilityWrapper
                      rbac={{
                        resource: '/ccs/authorization',
                        permission: 'ccs.authorization.edit'
                      }}
                    >
                      <Trash
                        onClick={() => handleOnDeleteBtnClick(datum)}
                        style={{ cursor: 'pointer' }}
                        data-testid="role-assignments-table-data-delete"
                      />
                    </VisibilityWrapper>
                  )
                }
                return null
              }
            },
            {
              property: 'id',
              primary: true,
              render: () => <></>
            }
          ],
          data: retrievedData
        }}
        testId="role-assignments-data-table"
      />
    </>
  )
}

RoleAssignmentContent.propTypes = {
  handleOnDeleteBtnClick: PropTypes.func.isRequired,
  retrievedData: PropTypes.array.isRequired,
  subjectType: PropTypes.string.isRequired
}

export default RoleAssignmentContent
