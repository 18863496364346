// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

export const analyticsConfig = () => {
  const storedLdConfigFromSession = JSON.parse(
    sessionStorage?.getItem('glp-analytics-config')
  )
  return {
    ...storedLdConfigFromSession,
    plugins: {
      amplitude: {
        projectId: window.$settings?.aaConfig?.project_id
          ? window.$settings?.aaConfig?.project_id
          : window.$settings?.aPID,
        apiKey: window.$settings?.aaConfig?.api_key
          ? window.$settings?.aaConfig?.api_key
          : window.$settings?.aAPIKey
      }
    }
  }
}
