// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, FormField, TextInput } from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { getAllParts } from '../../../../utils/manage-account-utils'
import { validateIPRange } from '../../../../utils/validation-utils'
import { Dropdown, Typography } from '../../../../components'

const MoveToFolderFormField = ({ state, dispatch, inCCSManager = false }) => {
  const { t } = useTranslation(['device'])
  const { oidcUser } = useReactOidc()
  const [listOfParts, setListOfParts] = useState([])
  const listOfMoveType = [
    { label: t('device_category'), value: 'category' },
    { label: t('part_number'), value: 'part' },
    { label: t('po_number'), value: 'custpo' },
    { label: t('billing_info'), value: 'billto' },
    { label: t('external_ip_address_range'), value: 'externalip' }
  ]
  const listOfCategory = [
    { label: t('ap'), value: 'ap' },
    { label: t('iap'), value: 'iap' },
    { label: t('rap'), value: 'rap' },
    { label: t('controller'), value: 'controller' },
    { label: t('switch'), value: 'switch' }
  ]
  // for paginating on scroll
  const [page, setPage] = useState(0)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    const param = {
      limit: 50,
      page
    }
    getAllParts({
      token: oidcUser.access_token,
      param,
      isCCSManager: inCCSManager
    }).then((data) => {
      const parentPart = data?.parts?.map((value) => value.parent_part)
      if (parentPart)
        setListOfParts((prevState) => [...prevState, ...parentPart])
      setTotalPage(data?.total_pages)
    })
  }, [oidcUser.access_token, page, inCCSManager])

  return (
    <Box
      margin={{ bottom: 'xxsmall' }}
      direction="column"
      justify="between"
      gap="small"
    >
      <FormField
        data-testid="move-by-form-field"
        label={t('move_by')}
        name="sub_type"
        width="medium"
        required={!state.edit}
      >
        {state.edit ? (
          <Typography
            testId="move-by-input"
            type="text"
            size="medium"
            margin={{ horizontal: 'small', vertical: 'xsmall' }}
          >
            {t(`list_of_move_type_option.${state.sub_type}`)}
          </Typography>
        ) : (
          <Dropdown
            name="sub_type"
            placeholder={t('select_from_list')}
            options={listOfMoveType}
            value={state.sub_type}
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: 'sub_type',
                type: 'CHANGE_FIELD'
              })
            }}
            testId="move-by-input"
          />
        )}
      </FormField>

      {state.sub_type === 'category' && (
        <FormField
          data-testid="category-form-field"
          label={t('category')}
          name="value"
          width="medium"
        >
          <Dropdown
            name="value"
            placeholder={t('select_from_list')}
            options={listOfCategory}
            value={state.value}
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: 'value',
                type: 'CHANGE_FIELD'
              })
            }}
            testId="category-input"
          />
        </FormField>
      )}

      {state.sub_type === 'part' && (
        <FormField
          data-testid="family-form-field"
          label={t('family')}
          name="value"
          width="medium"
        >
          <Dropdown
            name="value"
            placeholder={t('select_from_list')}
            options={listOfParts}
            labelKey="part_number"
            valueKey={{ key: 'part_number', reduce: true }}
            value={state.value}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: 'value',
                type: 'CHANGE_FIELD'
              })
            }}
            testId="family-input"
            onMore={() => {
              if (page < totalPage) setPage(page + 1)
            }}
          />
        </FormField>
      )}

      {['custpo', 'billto', 'externalip'].includes(state.sub_type) && (
        <FormField
          data-testid="contains-form-field"
          label={
            state.sub_type === 'externalip' ? t('ip_range') : t('contains')
          }
          name="value"
          width="medium"
          required={state.sub_type === 'externalip'}
          {...(state.sub_type === 'externalip' && {
            validate: () => validateIPRange(state.value)
          })}
        >
          <TextInput
            data-testid="contains-input"
            name="value"
            value={state.value}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: 'value',
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      )}
    </Box>
  )
}

MoveToFolderFormField.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  inCCSManager: PropTypes.bool
}
export { MoveToFolderFormField }
