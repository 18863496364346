// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import {
  buildStandaloneSearchBox,
  buildRecentQueriesList,
  buildInstantResults
} from '@coveo/headless'
import { Box, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Loader } from '../../../../components'
import { searchEngine } from '../../common/Engine'

import SearchBoxRenderer from './SearchBoxRenderer'

const StandaloneSearchBox = () => {
  const { oidcUser } = useReactOidc()
  const [engine, setEngine] = useState(null)

  const [controller, setController] = useState(null)
  const [recentQueryController, setRecentQueryController] = useState(null)
  const [instantSearchController, setInstantSearchController] = useState(null)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation('support_hub_search')

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        const searchInstance = await searchEngine(oidcUser?.access_token)
        if (isMounted) {
          setEngine(searchInstance)
          setLoading(false)
        }
      } catch (e) {
        setError(true)
        setLoading(false)
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [oidcUser?.access_token])

  useEffect(() => {
    const options = {
      numberOfSuggestions: 5,
      redirectionUrl: '/support/search'
    }
    // Check if engine exists before creating the controller
    if (engine) {
      const newController = buildStandaloneSearchBox(engine, { options })
      setController(newController)
      const newControllerR = buildRecentQueriesList(engine, {
        options: { maxLength: 5 }
      })
      setRecentQueryController(newControllerR)
      const newInstantController = buildInstantResults(engine, {
        options: { maxResultsPerQuery: 5, searchBoxId: 'instantResults' }
      })
      setInstantSearchController(newInstantController)
    }
  }, [engine])

  if (loading) {
    return (
      <Box align="center">
        <Loader testId="standalone-searchbox-loader" />
      </Box>
    )
  }

  if (error) {
    return <TextInput placeholder={t('error_unable_to_search')} disabled />
  }

  return controller ? (
    <SearchBoxRenderer
      controller={controller}
      recentQueriesListController={recentQueryController}
      instantResultsController={instantSearchController}
      engine={engine}
    />
  ) : null
}

export default StandaloneSearchBox
