// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import { Box, FormField } from 'grommet'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  Loader,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Notification,
  MultiSelectBox,
  Typography
} from '../../../components'
import {
  get,
  getBaseUrl,
  getErrorMessage,
  patch
} from '../../../utils/api-utils'
import { GROUP_SCHEMAS } from '../groups/constants'
import Markdown from '../../../commoncomponents/Markdown'
import { updateV2RBACPolicies } from '../../../utils/rbac-api-utils'
import { useCCSContext } from '../../../context/ccs-context'
import { useVisibilityContext } from '../../../context/visibility-context'
import { getDomainFromEmail } from '../../manage-account/utils'

import { debounceSearch } from './utils'

const AssignUsersToGroupModal = ({
  onSetOpen,
  onSubmit,
  setNotificationInfo = () => {},
  group
}) => {
  const { csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common', 'iam'])
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [samlLoading, setSAMLLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [defaultOptions, setDefaultOptions] = useState()
  const [samlAuthzUsers, setSAMLAuthzUsers] = useState([])
  const [dropdownOpened, setDropdownOpened] = useState(false)

  const closeModal = () => {
    onSetOpen(false)
  }

  const validateSelectUsers = () => {
    if (selectedUsers?.length === 0) {
      setErrorMessage(t('common:this_is_required'))
      return false
    }
    return true
  }

  const template = (option) => (
    <Box pad={{ top: 'xsmall' }}>
      <Typography size="small" type="paragraph">
        {option?.displayName || option?.userName}
      </Typography>
      {option?.displayName && (
        <Typography size="xsmall" type="paragraph">
          {option?.userName}
        </Typography>
      )}
    </Box>
  )

  const samlAuthzWarning = (
    <Box width={{ min: 'full', max: 'medium' }}>
      {samlLoading ? (
        <Loader testId="check-saml-authz-users-loader" />
      ) : (
        <Notification
          testId="saml-authz-selected-warning"
          type="inline"
          status="warning"
          text={t('iam:ccs_attribute.add_saml_users_to_group_warning', {
            numSAMLAuthzUsers: samlAuthzUsers?.length || 0,
            totalSelectedUsers: selectedUsers?.length,
            users: t('common:business_object.user_plural'),
            domains: t('common:business_object.domain'),
            userGroups: t('common:business_object.user_group_plural'),
            roleAssignments: t('common:business_object.role_assignment_plural')
          })}
        />
      )}
    </Box>
  )

  useEffect(() => {
    let isMounted = true
    const getUsersAssignedToGroup = async () => {
      let usersAssignedToGroup
      await get(
        `/identity/v2alpha1/scim/v2/extensions/Groups/${group?.id}/users`
      ).then(
        (response) => {
          if (!isMounted) return
          usersAssignedToGroup = response?.data?.Resources
        },
        (error) => {
          if (!isMounted) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
        }
      )
      return usersAssignedToGroup
    }

    const getUsers = async () => {
      const searchValueTrimmed = searchValue?.trim()
      const searchQuery =
        searchValueTrimmed?.length > 0
          ? `?filter=${encodeURIComponent(
              `displayName sw "${searchValueTrimmed}" or userName sw "${searchValueTrimmed}"`
            )}`
          : ''
      setLoading(true)
      setUsers([])
      await get(`/identity/v2alpha1/scim/v2/Users${searchQuery}`).then(
        async (response) => {
          let usersToDisplay = []
          if (response?.data && response?.data?.Resources) {
            usersToDisplay = response?.data?.Resources
          }
          if (group?.id) {
            const usersAssignedToGroup = await getUsersAssignedToGroup()
            const usersAssignedToGroupIds = usersAssignedToGroup?.map(
              (user) => user?.id
            )
            usersToDisplay = usersToDisplay?.filter(
              (user) => !usersAssignedToGroupIds?.includes(user?.id)
            )
          }
          if (!isMounted) return
          if (isEmpty(searchValue?.trim())) {
            setDefaultOptions(usersToDisplay)
          }
          setUsers(usersToDisplay)
          setLoading(false)
        },
        (error) => {
          if (!isMounted) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    getUsers()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const onSelectionChanged = (newSelectedUsers) => {
    // filter for new selections that not in current selected users
    // since we only need to send GET API to the new selection(s)
    const urls = newSelectedUsers
      ?.filter(
        (newSelectedUser) =>
          !selectedUsers
            ?.map((currentSelectedUser) => currentSelectedUser?.id)
            ?.includes(newSelectedUser?.id)
      )
      ?.map((user) => {
        const domain = getDomainFromEmail(user?.userName)
        return {
          id: user?.id,
          url: `/ui-doorway/ui/v1/saml/claims?domains=${encodeURIComponent(
            domain
          )}`
        }
      })

    // filter for SAML authz entry that in the new selection
    // since the previous SAML authz entries might be removed from the selections
    const newSAMLAuthzUsers = samlAuthzUsers?.filter((samlAuthzUser) =>
      newSelectedUsers
        ?.map((newSelectedUser) => newSelectedUser?.id)
        .includes(samlAuthzUser)
    )
    setSAMLLoading(true)
    setSelectedUsers(newSelectedUsers)
    const promises = urls?.map((url) =>
      get(url?.url, {}, oidcUser.access_token)
    )
    if (!isEmpty(promises)) {
      // send all GET SAML requests concurrently
      Promise.all(promises)
        .then((results) => {
          if (results && results?.length === promises?.length) {
            results?.forEach((res, i) => {
              if (res?.data?.sso_mode) {
                newSAMLAuthzUsers.push(urls[i]?.id)
              }
            })
          }
          setSAMLAuthzUsers(newSAMLAuthzUsers)
          setSAMLLoading(false)
        })
        .catch((error) => {
          setSAMLLoading(false)
          setNotificationInfo(getErrorMessage(error, t), 'error')
        })
    } else {
      setSAMLLoading(false)
      setSAMLAuthzUsers(newSAMLAuthzUsers)
    }
  }

  const updateGroupUsers = async () => {
    setLoading(true)
    const nonSAMLAuthzSelectedUsers =
      samlAuthzUsers?.length > 0
        ? selectedUsers?.filter((s) => !samlAuthzUsers?.includes(s?.id))
        : selectedUsers
    const value = nonSAMLAuthzSelectedUsers?.map((u) => ({
      display: u?.displayName,
      $ref: `${getBaseUrl()}/identity/v2alpha1/scim/v2/Users/${u?.id}`,
      value: u?.id
    }))
    if (nonSAMLAuthzSelectedUsers?.length > 0) {
      try {
        await patch(`/identity/v2alpha1/scim/v2/Groups/${group?.id}`, {
          schemas: [GROUP_SCHEMAS.PATCH_OP_SCHEMA],
          Operations: [
            {
              op: 'add',
              value,
              path: 'members'
            }
          ]
        })
        setNotificationInfo(
          nonSAMLAuthzSelectedUsers?.length === 1 ? (
            <Markdown>
              {t('iam:organization_groups.user_added_message', {
                userName: `${
                  nonSAMLAuthzSelectedUsers[0]?.displayName ||
                  nonSAMLAuthzSelectedUsers[0]?.userName
                }`,
                groupName: `${group?.displayName}`
              })}
            </Markdown>
          ) : (
            <Markdown>
              {t('iam:organization_groups.users_added_message', {
                usercount: nonSAMLAuthzSelectedUsers?.length,
                groupName: `${group?.displayName}`
              })}
            </Markdown>
          ),
          'info',
          nonSAMLAuthzSelectedUsers?.length === 1
            ? t('iam:organization_groups.user_added_title')
            : t('iam:organization_groups.users_added_title')
        )
        // This could affect the current user's permissions hence why we're calling updateV2RBACPolicies
        // updateV2RBACPolicies will not run on local as csrfToken will be null
        updateV2RBACPolicies(dispatchVisibilityContext, csrfToken)
        onSubmit()
      } catch (error) {
        setNotificationInfo(getErrorMessage(error, t), 'error')
      } finally {
        setLoading(false)
        closeModal()
      }
    } else {
      closeModal()
    }
  }

  return (
    <ModalDialog
      height="100%"
      overflow="hidden"
      position="right"
      header={
        <ModalHeader
          title={
            <Box direction="column">
              <Typography
                type="heading"
                level="2"
                margin={{ bottom: 'small' }}
                emphasis
              >
                {t('iam:organization_groups.add_users_to_group_title')}
              </Typography>
              <Box width="medium">
                <Markdown>
                  {t('iam:organization_groups.add_users_to_group_desc', {
                    groupName: group?.displayName
                  })}
                </Markdown>
              </Box>
            </Box>
          }
          onClose={closeModal}
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Box
            justify={dropdownOpened ? 'between' : 'start'}
            gap="small"
            height="large"
          >
            <CCSForm errorMessage="" testId="assign-users-to-group-form">
              <FormField
                label={t('iam:users.select_user_label')}
                required
                error={errorMessage}
              >
                <MultiSelectBox
                  options={users}
                  labelKey={(user) =>
                    isEmpty(user?.displayName)
                      ? user?.userName
                      : user?.displayName
                  }
                  valueKey="id"
                  placeholder={t('common:select')}
                  onChange={(user) => {
                    onSelectionChanged(user)
                    setErrorMessage('')
                  }}
                  onClose={() => {
                    setUsers(defaultOptions)
                    setDropdownOpened(false)
                  }}
                  onOpen={() => setDropdownOpened(true)}
                  onSearch={(searchText) =>
                    debounceSearch(searchText, setSearchValue)
                  }
                  searchPlaceholder={t('common:search')}
                  emptySearchMessage={
                    loading
                      ? t('common:searching')
                      : t('common:search_no_matches')
                  }
                  dropHeight="medium"
                  disabled={loading}
                  testId="assign-users-to-group-multiselect"
                >
                  {template}
                </MultiSelectBox>
              </FormField>
            </CCSForm>
            {samlAuthzUsers?.length > 0 && samlAuthzWarning}
          </Box>
          <Box align="center" margin={{ top: 'small' }}>
            {loading && <Loader testId="assign-users-to-group-loader" />}
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          left={
            <Box direction="row" gap="small">
              <Button
                primary
                label={t('common:add')}
                onClick={() => {
                  if (validateSelectUsers()) {
                    updateGroupUsers()
                  }
                }}
                disabled={loading}
                testId="assign-users-to-group-add-btn"
              />
              <Button
                default
                label={t('common:cancel')}
                onClick={closeModal}
                testId="assign-users-to-group-cancel-btn"
              />
            </Box>
          }
        />
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      testId="assign-users-to-group-modal"
    />
  )
}

AssignUsersToGroupModal.propTypes = {
  /**
   * Flag to display modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * onSubmit handler
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Set notification info
   */
  setNotificationInfo: PropTypes.func,

  /**
   * The group to add users
   */
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired
  }).isRequired
}

export default AssignUsersToGroupModal
