// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useReducer } from 'react'

import CCSManagerActions from './CCSManagerActions'

export const initialContext = {
  selectedCustomer: null,
  selectedUser: null,
  userCCSRoles: null,
  selectedDeviceCustomerData: null
}

export const CCSManagerReducer = (state, action) => {
  switch (action.type) {
    case CCSManagerActions.SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.data
      }
    case CCSManagerActions.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.data
      }
    case CCSManagerActions.SET_SELECTED_DEVICE_CUSTOMER_DATA:
      return {
        ...state,
        selectedDeviceCustomerData: action.data
      }
    case CCSManagerActions.SET_USER_CCS_ROLES:
      return { ...state, userCCSRoles: action.data }
    default:
      return state
  }
}

const useCCSManagerReducer = () => {
  const [CCSManagerState, dispatchCCSManagerContext] = useReducer(
    CCSManagerReducer,
    initialContext
  )
  return { CCSManagerState, dispatchCCSManagerContext }
}

export default useCCSManagerReducer
