// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Card } from 'grommet'
import PropTypes from 'prop-types'
import {
  CircleInformation,
  StatusCriticalSmall,
  StatusUnknownSmall,
  StatusWarningSmall
} from 'grommet-icons'

import { Typography } from '../../../components'

const Widget = ({ text1, text2, text3, cnt1, cnt2, cnt3, display = '' }) => {
  return (
    <Box direction="row" elevation="none">
      <Card
        pad={{ top: 'small', left: 'small' }}
        width="140px"
        key="0"
        elevation="none"
      >
        <Box direction="row" justify-content="evenly" margin="xsmall">
          <Typography
            icon={<StatusCriticalSmall color="status-critical" size="12px" />}
            size="medium"
            type="text"
          >
            {text1}
          </Typography>
        </Box>
        <Typography size="xxlarge" type="text" margin={{ left: 'xsmall' }}>
          <Box direction="row" align="center" justify="center">
            {cnt1}
            <Typography
              style={{ color: 'black', width: '55px', wordWrap: 'break-word' }}
              width="small"
              weight="400"
              size="xsmall"
              type="text"
              margin="xsmall"
            >
              {display}
            </Typography>
          </Box>
        </Typography>
      </Card>
      <Card
        pad={{ top: 'small', left: 'small' }}
        width="140px"
        key="1"
        elevation="none"
      >
        <Box direction="row" justify-content="evenly" margin="xsmall">
          <Typography
            icon={<StatusWarningSmall color="status-warning" size="12px" />}
            type="text"
          >
            {text2}
          </Typography>
        </Box>
        <Typography size="xxlarge" type="text" margin={{ left: 'xsmall' }}>
          <Box direction="row" align="center" justify="center">
            {cnt2}
            <Typography
              style={{ color: 'black', width: '55px', wordWrap: 'break-word' }}
              width="small"
              weight="400"
              size="xsmall"
              type="text"
              margin="xsmall"
            >
              {display}
            </Typography>
          </Box>
        </Typography>
      </Card>
      <Card
        pad={{ top: 'small', left: 'small' }}
        width="140px"
        key="2"
        elevation="none"
      >
        <Box direction="row" justify-content="evenly" margin="xsmall">
          {text3 === 'Unknown' && (
            <Typography
              icon={<StatusUnknownSmall color="status-unknown" size="12px" />}
              size="medium"
              type="text"
            >
              {text3}
            </Typography>
          )}
          {text3 === 'Informational' && (
            <Typography
              icon={<CircleInformation size="12px" />}
              size="medium"
              type="text"
            >
              {text3}
            </Typography>
          )}
        </Box>
        <Typography size="xxlarge" type="text" margin={{ left: 'xsmall' }}>
          <Box direction="row" align="center" justify="center">
            {cnt3}
            <Typography
              style={{ color: 'black', width: '55px', wordWrap: 'break-word' }}
              width="small"
              weight="400"
              size="xsmall"
              type="text"
              margin="xsmall"
            >
              {display}
            </Typography>
          </Box>
        </Typography>
      </Card>
    </Box>
  )
}

Widget.propTypes = {
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  text3: PropTypes.string.isRequired,
  cnt1: PropTypes.number.isRequired,
  cnt2: PropTypes.number.isRequired,
  cnt3: PropTypes.number.isRequired,
  display: PropTypes.string
}

export { Widget }
