// Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  ASCENDING,
  COMMON_ELEMENTS,
  DESCENDING,
  JSON_TEXT,
  ROOT,
  SELECT,
  SUBSCRIPTION_KEY_TEXT
} from '../../constants'
import commonElements from '../config/common-elements.json'

import { getEnvName } from './FormUtil'

const prepareOptions = (optionObject, parentKey, t) => {
  const optionArray = []
  const isOptionArray = optionObject instanceof Array
  if (optionObject instanceof Object) {
    Object.entries(optionObject).forEach(([optKey, optValue]) => {
      optionArray.push({
        id: isOptionArray
          ? optValue.label_key
          : `${parentKey}-options-${optKey}`,
        label: t(optValue.label_key),
        value: optValue.value,
        hide_button_panel: optValue.hide_button_panel
          ? optValue.hide_button_panel
          : false
      })
    })
  }
  return optionArray
}

const sortAsc = (options) => {
  return options.sort((firstOption, secondOption) =>
    firstOption.value.localeCompare(secondOption.value)
  )
}

const sortDesc = (options) => {
  return options
    .sort((firstOption, secondOption) =>
      firstOption.value.localeCompare(secondOption.value)
    )
    .reverse()
}

const sortOptions = (sortOrder, options) => {
  switch (sortOrder) {
    case ASCENDING:
      return sortAsc(options)
    case DESCENDING:
      return sortDesc(options)
    default:
      return options
  }
}

export const prepareElement = (
  elementObject,
  elementId,
  requiredElementsFilledStatus,
  t
) => {
  const element = {
    id: elementId,
    name: elementObject?.name,
    label: t(elementObject?.label_key),
    is_required: elementObject?.is_required,
    type: elementObject?.type,
    value: '',
    status: elementObject?.status,
    message: t(elementObject?.message),
    notification_type: elementObject?.notification_type,
    placeholder: t(elementObject?.placeholder_key),
    max_length: elementObject?.max_length,
    threshold: elementObject?.threshold,
    exclude_custom_validation: elementObject?.exclude_custom_validation,
    is_primary: elementObject?.is_primary,
    is_reverse: elementObject?.is_reverse,
    url:
      elementObject && elementObject[`${getEnvName()}_url`]
        ? elementObject[`${getEnvName()}_url`]
        : elementObject.hoku_url,
    target: elementObject?.target
  }
  if (element.is_required) {
    requiredElementsFilledStatus.current[element.name] = false
  }
  if (elementObject.type === SELECT && elementObject?.options) {
    const allOptions = prepareOptions(elementObject.options, elementId, t)
    const skipSortOptions = []
    allOptions.forEach((option, index) => {
      if (
        option.label === t('support_cases:common.not_listed') ||
        option.label === t('support_cases:common.other')
      ) {
        skipSortOptions.push(option)
        allOptions.splice(index, 1)
      }
    })
    element.options = [
      ...sortOptions(elementObject.sort_order, allOptions),
      ...skipSortOptions
    ]
    element.search_enabled = element.options.length > 10
  }
  return element
}

export const prepareCommonFields = (
  jsonData,
  elementOptId,
  elements,
  requiredElementsFilledStatus,
  t
) => {
  if (jsonData.type === JSON_TEXT && jsonData.name === COMMON_ELEMENTS) {
    Object.entries(commonElements).forEach(([elementKey, elementValue]) => {
      const element = prepareElement(
        elementValue,
        `${elementOptId}-${elementKey}`,
        requiredElementsFilledStatus,
        t
      )
      elements[element.id] = element
    })
  }
}

export const getTargetNodeFromJSON = (targetNodePathId, serviceJson) => {
  let targetNodeJson
  if (targetNodePathId && targetNodePathId === ROOT) {
    targetNodeJson = serviceJson
  } else {
    let nodes = []
    if (targetNodePathId) {
      nodes = targetNodePathId.split('-')
    }
    if (nodes.length) {
      nodes.forEach((node) => {
        serviceJson = serviceJson[node]
      })
      targetNodeJson = serviceJson
    }
  }
  return targetNodeJson
}

/**
 * Accepts the JSON node as an input and prepares the form elements for child nodes
 */
export const getNextFromElements = (
  elementJson,
  targetNodePathId,
  elements,
  requiredElementsFilledStatus,
  t
) => {
  const escSubKeyEleIds = [SUBSCRIPTION_KEY_TEXT]
  Object.entries(elementJson).forEach(([key, currentValue]) => {
    const parentNodeId =
      targetNodePathId && targetNodePathId !== ROOT
        ? `${targetNodePathId}-${key}`
        : key
    if (
      currentValue?.type !== JSON_TEXT &&
      !escSubKeyEleIds.includes(currentValue?.name)
    ) {
      const element = prepareElement(
        currentValue,
        parentNodeId,
        requiredElementsFilledStatus,
        t
      )
      elements[element.id] = element
    }
    prepareCommonFields(
      currentValue,
      parentNodeId,
      elements,
      requiredElementsFilledStatus,
      t
    )
  })
  return elements
}

/**
 * Assume that the case form is fully rendered.
 * If any of the top progressive required dropdown is changed
 * then, the elements below will be removed from the array
 */
export const clearFormElementsTillSelection = (
  formElements,
  requiredElementsFilledStatus,
  target
) => {
  let elements = {}
  const updateRequiredElementsFilledStatus = {}
  let isKeyFound = false
  Object.entries(formElements).forEach(([key, selectedValue]) => {
    if (!isKeyFound) {
      elements = { ...elements, [key]: selectedValue }
      if (selectedValue.is_required) {
        updateRequiredElementsFilledStatus[selectedValue.name] =
          requiredElementsFilledStatus.current[selectedValue.name]
      }
      if (key === target?.dataset.id) {
        isKeyFound = true
      }
    }
  })
  requiredElementsFilledStatus.current = updateRequiredElementsFilledStatus
  return elements
}
