// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Anchor, Text } from 'grommet'
import { PropTypes } from 'prop-types'
import { buildInteractiveResult } from '@coveo/headless'

import HighlightString from '../../templates/HighLightString'

import { buildClickUri } from './clickUriUtils'
// Component for rendering a clickable link to a result item
const ListItemLink = ({ result, engine }) => {
  const interactiveResult = buildInteractiveResult(engine, {
    options: { result }
  })

  const clickUri = buildClickUri(result)

  const handleClick = (event) => {
    // Check if it's a left click (button 0), middle click (button 1),
    // or opened in a new tab (with Ctrl or Cmd key)
    if (
      event.button === 0 ||
      event.button === 1 ||
      event.ctrlKey ||
      event.metaKey
    ) {
      interactiveResult.select()
    }
  }

  return (
    <Anchor
      data-testid="result-link-id"
      target="_blank"
      href={clickUri}
      onClick={handleClick}
      onAuxClick={handleClick}
      key={result.uniqueId}
      label={
        <Text
          dangerouslySetInnerHTML={{
            __html: HighlightString(result.title, result.titleHighlights)
          }}
        />
      }
    />
  )
}

ListItemLink.propTypes = {
  result: PropTypes.object.isRequired,
  engine: PropTypes.object.isRequired
}

export default ListItemLink
