// Copyright 2025 Hewlett Packard Enterprise Development LP
/* Please add any localStorage item keys we want to reserve when sign-out to the constant localStorageWhitelistWithoutPII.
The item which was not included in localStorageWhitelistWithoutPII will be removed after user sign-out. */
export const localStorageWhitelistWithoutPII = Object.freeze({
  OIDC_LOGS: 'oidcLogs',
  GLCP_LOGS: 'glcpLogs',
  LAST_REQ_ERROR: 'last-request-error',
  DASHBOARD_LAST_ACCESSED: 'glcp-recent-services',
  DASHBOARD_GET_STARTED: 'glcpGetStarted',
  RECENT_SERVICES_VIEW: 'recentServicesView',
  FIRST_TIME_WELLNESS_USER: 'first-time-wellness-user',
  COVEO_ANALYTICS: '__coveo.analytics.history',
  COVEO_VISITOR_ID: 'visitorId',
  RECENT_RESULTS: 'recentResults',
  LATEST_RECENT_QUERIES: 'latestRecentQueries',
  COVEO_STANDALONE_SEARCH_BOX: 'coveo_standalone_search_box_data'
})

/* If a localStorage item was designed for temporary use, but expected to be removed when user sign-out.
Please add the item key into the localStorageWhitelistWithPII. */
export const localStorageWhitelistWithPII = Object.freeze({
  I18N_BOO: 'i18next.translate.boo',
  CHAT_STATE: 'chatState',
  CHAT_PHONE_BUTTON_SELECTED: 'chatPhoneButtonSelected'
})

const invalidLocalStorageItemKeyError = (key) => {
  return `The localStorage key ${key} is not presented in the localStorageWhitelistWithoutPII or localStorageWhitelistWithPII. Please ensure you have not set any PII for your local storage item directly, then update the localStorageWhitelistWithoutPII or localStorageWhitelistWithPII in local-storage-utils.js with your key.`
}

const localStorageSetItem = localStorage.setItem

localStorage.setItem = (key, value) => {
  if (
    !Object.values({
      ...localStorageWhitelistWithoutPII,
      ...localStorageWhitelistWithPII
    }).includes(key) &&
    window.NODE_ENV === 'development'
  ) {
    console.warn(invalidLocalStorageItemKeyError(key))
  }
  localStorageSetItem.call(localStorage, key, value)
}
