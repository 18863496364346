// Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Anchor,
  Box,
  Form,
  FormField,
  FileInput,
  Text,
  NameValueList,
  NameValuePair
} from 'grommet'
import { CircleAlert } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { v4 as uuidv4 } from 'uuid'

import {
  Notification,
  Button,
  Typography,
  ModalDialog,
  ModalHeader
} from '../../../../components'
import { FILE_EXTENSION_CONSTANTS } from '../../FileExtensionConstants'
import { uploadCaseFiles } from '../utils/FormUtil'

const FileUpload = ({
  caseNumber,
  externalCaseId,
  workspaceId,
  productNamespace
}) => {
  const { t } = useTranslation(['support_cases'])
  const MAX_FILE_SIZE = 2147483648 // 2 GB
  const [numFiles, setNumFiles] = useState(0)
  const [busy, setBusy] = useState(false)
  const [fileRecords, setFileRecords] = useState([])
  const [fileRecordsMetaData, setFileRecordsMetaData] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [visible, setVisible] = useState()
  const onClose = () => setVisible(undefined)
  const [showBtn, setShowBtn] = useState(false)
  const [showSupportedFileFormats, setShowSupportedFileFormats] =
    useState(false)
  const [formValue, setFormValue] = useState({})
  const fileInputRef = useRef()
  const [aggregateThresholdVal, setAggregateThresholdVal] = useState(10)
  const { oidcUser } = useReactOidc()
  const userId = useMemo(() => uuidv4(), [])
  const fileTypes = [
    {
      type: t('support_cases:file_upload.log_files'),
      fileValue: [
        {
          subFileType: t('support_cases:common.compute'),
          value:
            '.log, .syslog, .eventlog, .dmesg, .accesslog, .errorlog, .authlog'
        },
        {
          subFileType: t('support_cases:common.storage'),
          value: '.clog, .slog, .fslog, .dblog, .replog, .ltt'
        },
        {
          subFileType: t('support_cases:common.networking'),
          value: '.pcap, .syslog, .netlog, .authlog, .vpnlog'
        },
        {
          subFileType: t('support_cases:file_upload.software'),
          value:
            '.log, .etl, .trc, .debuglog, .errorlog, .auditlog, .installlog, .updatelog, .eventlog, .evtx, .evt'
        },
        {
          subFileType: t('support_cases:common.other'),
          value: '.ahs, .msglog, .errlog, .statlog'
        }
      ]
    },
    {
      type: t('support_cases:file_upload.other_files'),
      fileValue: [
        {
          subFileType: t('support_cases:file_upload.misc'),
          value:
            '.csv, .zip, .xz, .tar, .tgz, .cab, .7z, .gz, .gzip, .rar, .tbz2, .z[num] (.z1 to z9), .out, .bz2, .md5, .checksum, .service, .target, .blg, .dmp, .mdmp, .sdmp, .iml, .ini, .cfg, .conf, .nfo, .ltd, .hpasm, .messages, .secure, .iso, .s7z, .ace, .info, .bin, .msg, .eml'
        },
        {
          subFileType: t('support_cases:file_upload.office'),
          value: '.xls, .xlsx, .doc, .docx, .pdf, .ppt, .pptx, .txt'
        },
        {
          subFileType: t('support_cases:file_upload.image'),
          value: '.png, .jpg, .jpeg, .jpe, .jfif, .bmp, .gif, .tif'
        },
        {
          subFileType: t('support_cases:file_upload.video'),
          value: '.mp4, .mpeg, .mpg, .ilo, .mov, .wmv, .avi'
        },
        {
          subFileType: t('support_cases:file_upload.web_page'),
          value: '.html, .htm, .xml'
        }
      ]
    }
  ]

  const isFileFormatValid = (fileRec) => {
    let extension = ''
    if (fileRec?.name?.includes('.')) {
      extension = fileRec.name.split('.').pop().toLowerCase()
      if (!FILE_EXTENSION_CONSTANTS.includes(extension)) return true
    } else {
      return true
    }
    return false
  }
  const isFileSizeValid = (fileRec) => {
    return fileRec.size > MAX_FILE_SIZE
  }
  const populateErrorMessage = useCallback(
    (sizeError, typeError) => {
      if (sizeError && typeError) {
        setErrorMsg(
          t(
            'support_cases:file_upload.unsupported_file_format_max_size_error_msg'
          )
        )
        // eslint-disable-next-line no-else-return
      } else if (sizeError) {
        setErrorMsg(
          t('support_cases:file_upload.unsupported_max_size_error_msg')
        )
      } else if (typeError) {
        setErrorMsg(
          t('support_cases:file_upload.unsupported_file_formats_error_msg')
        )
      } else {
        setShowBtn(true)
        setErrorMsg('')
      }
    },
    [t]
  )
  const validateFileInput = useCallback(() => {
    const fileRecArray =
      fileRecords && numFiles > 0 ? Array.from(fileRecords) : undefined
    let typeError = false
    let sizeError = false
    if (fileRecArray) {
      setSuccess(false)
      setShowBtn(false)
      fileRecArray.forEach((fileRec) => {
        if (typeof fileRec === 'object') {
          typeError = typeError || isFileFormatValid(fileRec)
          sizeError = sizeError || isFileSizeValid(fileRec)
        }
      })
      populateErrorMessage(sizeError, typeError)
    } else {
      setShowBtn(false)
      setErrorMsg('')
    }
  }, [fileRecords, numFiles, populateErrorMessage])

  const handleFiles = (files, event) => {
    setAggregateThresholdVal(files.length)
    if (files.length > 10) {
      setShowBtn(false)
      setErrorMsg(t('support_cases:file_upload.max_10_files'))
    } else {
      setNumFiles(files.length)
      setFileRecords([...files])
      const filesData = event.target.files
      setFileRecordsMetaData([...filesData])
    }
  }

  const callUploadFilesApi = useCallback(
    async (reqFile) => {
      const metadata = {
        externalCaseId,
        productNamespace,
        workspaceId
      }
      const fileResponse = await uploadCaseFiles(
        oidcUser.access_token,
        metadata,
        reqFile,
        userId
      )
      return {
        isSuccess: fileResponse?.status === 201 && fileResponse?.data,
        fileName: reqFile.name
      }
    },
    [
      oidcUser.access_token,
      externalCaseId,
      workspaceId,
      productNamespace,
      userId
    ]
  )

  const processFiles = async (rawFiles) => {
    const overAllFiles = []
    const successFiles = []
    const failedFiles = []
    await rawFiles.reduce(async (promise, file) => {
      await promise
      overAllFiles.push(file.name)
      const rawFilesResponse = await callUploadFilesApi(file)
      if (rawFilesResponse?.isSuccess) {
        successFiles.push(rawFilesResponse.fileName)
      } else {
        failedFiles.push(rawFilesResponse.fileName)
      }
    }, Promise.resolve())
    return { overAllFiles, successFiles, failedFiles }
  }

  const handleSubmit = async () => {
    if (numFiles > 0) {
      setBusy(true)
      const finalResponse = await processFiles(fileRecordsMetaData)
      if (finalResponse?.overAllFiles) {
        setFileRecords([])
        setNumFiles(0)
        fileInputRef.current.value = null
        setFormValue({})
        setVisible(true)
        setShowBtn(true)
        setBusy(false)
        setSuccess(true)
      }
    }
  }

  const onLayerClose = () => {
    setShowSupportedFileFormats(false)
  }

  useEffect(() => {
    validateFileInput()
  }, [fileRecords, validateFileInput, t])
  return (
    <Box direction="row-responsive" align="center" margin={{ top: 'small' }}>
      <Box>
        {visible && (
          <Notification
            testId="file-upload-notification-id"
            toast
            status="normal"
            text={
              t('support_cases:file_upload.file_upload_successfull_msg') +
              caseNumber
            }
            onClose={onClose}
            margin={{ top: 'large' }}
          />
        )}
      </Box>
      <Box margin={{ right: 'medium' }}>
        <Form
          validate="blur"
          messages={{
            required: t('support_cases:common.this_is_required_field')
          }}
          value={formValue}
          onSubmit={handleSubmit}
          onChange={(fileData) => {
            setFormValue(fileData)
          }}
        >
          <Typography
            type="text"
            weight="normal"
            size="small"
            data-testid="file-upload-label"
          >
            {t('support_cases:file_upload.file_upload_label')}
          </Typography>
          <Box width="medium">
            <FormField
              width="large"
              name="file-upload-form-field"
              htmlFor="file-input"
              info={
                <Box direction="row" gap="xsmall">
                  <Typography
                    weight="normal"
                    size="xsmall"
                    type="text"
                    data-testid="file-upload-info-msg"
                  >
                    {t('support_cases:file_upload.max_file_size_limit')}
                  </Typography>
                  <Typography
                    weight="normal"
                    size="xsmall"
                    type="text"
                    data-testid="file-upload-supported-file-formats"
                  >
                    <Anchor
                      weight="normal"
                      data-testid="file-upload-supported-extentions"
                      onClick={() => {
                        setShowSupportedFileFormats(true)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setShowSupportedFileFormats(true)
                        }
                      }}
                      tabIndex="0"
                      label={t(
                        'support_cases:file_upload.supported_file_formats'
                      )}
                    />
                  </Typography>
                </Box>
              }
              data-testid="file-upload-form-field"
              validateOn="change"
              error={errorMsg}
            >
              <FileInput
                testId="file-input"
                data-testid="fileInput"
                id="file-input"
                name="file-input"
                ref={fileInputRef}
                multiple={{
                  aggregateThreshold: aggregateThresholdVal
                }}
                messages={{
                  dropPromptMultiple: t(
                    'support_cases:file_upload.file_upload_placeholder'
                  ),
                  browse:
                    numFiles > 0
                      ? t('support_cases:file_upload.select_more_files')
                      : t('support_cases:file_upload.select_file')
                }}
                onChange={(event, { files }) => handleFiles(files, event)}
                renderFile={(file) => {
                  let extension = ''
                  if (file?.name?.includes('.')) {
                    extension = file.name.split('.').pop().toLowerCase()
                  }
                  const showAlert =
                    !FILE_EXTENSION_CONSTANTS.includes(extension) ||
                    file.size > MAX_FILE_SIZE
                  return (
                    <Box
                      direction="row"
                      gap="xsmall"
                      align="center"
                      margin="small"
                    >
                      {showAlert && <CircleAlert />}
                      <Text weight={500} truncate>
                        {file.name}
                      </Text>
                    </Box>
                  )
                }}
              />
            </FormField>
          </Box>
          {showBtn && (
            <Box direction="row" gap="small" margin={{ top: 'small' }}>
              <Button
                testId="file-upload-button"
                primary
                busy={busy}
                success={success}
                type="submit"
                label={t('support_cases:file_upload.upload_button')}
              />
            </Box>
          )}
        </Form>
      </Box>
      {showSupportedFileFormats && (
        <ModalDialog
          position="right"
          height="100%"
          modal={false}
          header={
            <ModalHeader
              title={
                <Typography type="heading" level="2" testId="header-title">
                  {t('support_cases:file_upload.supported_file_formats_label')}
                </Typography>
              }
              onClose={onLayerClose}
            />
          }
          content={
            <Box gap="xxsmall" width="medium" flex={false}>
              {Object.values(fileTypes).map((element) => (
                <Box key={element.type}>
                  <Typography
                    level="3"
                    type="heading"
                    margin={{ bottom: 'small' }}
                    data-testid="file-upload-dialog-label"
                  >
                    {element.type}
                  </Typography>
                  <NameValueList
                    layout="column"
                    gap="small"
                    nameProps={{ width: 'xsmall' }}
                  >
                    {Object.values(element.fileValue).map((subElement) => (
                      <NameValuePair
                        key={subElement.subFileType}
                        name={
                          <Typography
                            type="text"
                            size="medium"
                            weight="500"
                            wordBreak="break-word"
                          >
                            {subElement.subFileType}
                          </Typography>
                        }
                      >
                        {subElement.value}
                      </NameValuePair>
                    ))}
                  </NameValueList>
                </Box>
              ))}
            </Box>
          }
          onClose={onLayerClose}
          onClickOutside={onLayerClose}
          testId="file-upload-dialog"
        />
      )}
    </Box>
  )
}
FileUpload.propTypes = {
  caseNumber: PropTypes.number.isRequired,
  externalCaseId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
  productNamespace: PropTypes.string.isRequired
}
export default FileUpload
