// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import useLogger from '../logs/useLogger'
import { hashString } from '../../utils/common-utils'

export default function useLD() {
  const ldClient = useLDClient()
  const { oidcUser } = useReactOidc()
  const logger = useLogger()

  const ld = {
    update: (account) => {
      if (window.REACT_APP_ENABLE_LD) {
        const LDPromise = new Promise((resolve) => {
          const pageInfoLog = {
            page: 'Account Selection',
            section: 'Load',
            type: 'success'
          }
          const newContext = {
            kind: 'multi',
            user: {
              key: account?.platform_customer_id,
              email: hashString(oidcUser?.profile?.email) // hashed email
            },
            org: {
              key: account?.platform_customer_id,
              name: account?.company_name,
              domain: oidcUser?.profile?.email.split('@')[1] // Parse the domain from the email
            }
          }
          ldClient?.identify(newContext, null, (err, flags) => {
            logger?.log({
              ...pageInfoLog,
              msg: `LD Context has been updated for customer ID => ${account?.platform_customer_id}`
            })
            resolve(flags)
          })

          ldClient?.on('error', (error) => {
            logger?.log({
              ...pageInfoLog,
              ...{ type: 'error' },
              msg: `LD Error handler => ${error}`
            })
            resolve('failed')
          })
        })
        return LDPromise
      }
      return Promise.resolve()
    }
  }
  return ld
}
