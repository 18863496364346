// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, Image } from 'grommet'
import { HelpOption } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  MenuList,
  Typography,
  Dropdown,
  Ruler,
  Button,
  GLCPHelpMenu
} from '../../../components'
import { getCustomerAccount, isCoP } from '../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../visibility-wrapper/VisibilityWrapper'
import { useVisibilityContext } from '../../../context/visibility-context'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

import moreResourcesImage from './more-resources.png'

const allAppsList = [
  {
    key: 1,
    label: 'HPE GreenLake Developer Portal',
    value: 'hpe_gl_dev_portal',
    hideFor: {
      account: ['MSP', 'TENANT'],
      deployment: ['COP']
    }
  },
  { key: 2, label: 'HPE GreenLake', value: 'hpe_green_lake' },
  {
    key: 3,
    label: 'HPE GreenLake Central',
    value: 'hpe_green_lake_central',
    hideFor: { account: ['MSP', 'TENANT'], deployment: ['COP'] }
  },
  {
    key: 4,
    label: 'Data Services',
    value: 'data_services',
    hideFor: { account: ['MSP', 'TENANT'], deployment: ['COP'] }
  },
  {
    key: 5,
    label: 'Compute Ops Management',
    value: 'compute_ops_management',
    hideFor: { account: ['MSP', 'TENANT'], deployment: ['COP'] }
  },
  { key: 6, label: 'HPE Aruba Networking Central', value: 'aruba_central' }
]

const feedbackURLs = {
  hpe_gl_dev_portal: {
    general_feedback: '', // Put in the link once gina comes back with it
    feature_request: '' // Same for this one
  },
  hpe_green_lake: {
    general_feedback:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf',
    feature_request:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=fr'
  },
  hpe_green_lake_central: {
    general_feedback:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=cs&tof=gf',
    feature_request:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=cs&tof=fr'
  },
  data_services: {
    general_feedback:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=dscc&tof=gf',
    feature_request:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=dscc&tof=fr'
  },
  compute_ops_management: {
    general_feedback:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=cpcc&tof=gf',
    feature_request:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=cpcc&tof=fr'
  },
  aruba_central: {
    general_feedback:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=acc&tof=gf',
    feature_request:
      'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=acc&tof=fr'
  }
}

const HelpNavContent = () => {
  const { isSupportEngineer, hideWhen } = useVisibilityContext()
  const { t } = useTranslation(['common', 'support_access_manager'])
  const feedbackTypesList = [
    {
      label: t('help_nav.feedback_dropdown.general_feedback'),
      value: 'general_feedback'
    },
    {
      label: t('help_nav.feedback_dropdown.feature_request'),
      value: 'feature_request'
    }
  ]
  const defaultApp = 'hpe_green_lake'
  const [selectedApp, setSelectedApp] = useState(defaultApp)
  const [selectedFeedbackType, setSelectedFeedbackType] = useState('')
  const handleChangeDrop = (app) => {
    setSelectedApp(app)
  }
  const handleFeedbackTypesChange = (type) => {
    setSelectedFeedbackType(type)
  }
  const handleFeedbackContinue = () => {
    window.open(feedbackURLs[selectedApp][selectedFeedbackType])
  }
  const menuListNavStyle = {
    padding: 'none',
    gap: 'none'
  }
  const legalImgUrl = moreResourcesImage
  const HelpMenuList = ({ menuListFor }) => {
    const navigate = useNavigate()
    const LDFlags = useFlags()
    const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

    const custAccountLoaded = getCustomerAccount()

    const handleMenuItemClick = (item) => {
      if (item.linkTo.includes('http')) {
        window.open(item.linkTo, '_blank', 'noopener')
      } else {
        navigate(item.linkTo)
      }
    }
    switch (menuListFor) {
      case 'HELP_GLCS':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t('help_nav.help_dropdown.getting_started'),
                linkTo:
                  'https://www.hpe.com/h22228/video-gallery/us/en/57e8086e-b6d7-4d29-a617-9e48a5cd052e/greenlake---guided-tour-/video/',
                testId: 'getting-started-help-dropdown'
              },
              {
                id: 2,
                label: t('help_nav.help_dropdown.user_guide'),
                linkTo: 'https://www.hpe.com/support/greenlake-userguide-en',
                testId: 'user-guide-help-dropdown'
              },
              {
                id: 3,
                label: t('help_nav.help_dropdown.about'),
                linkTo: 'https://www.hpe.com/us/en/greenlake.html',
                testId: 'about-help-dropdown'
              },
              {
                id: 4,
                label: t('help_nav.help_dropdown.service_status'),
                linkTo: 'https://status.greenlake-hpe.com',
                testId: 'service-status-help-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-help-dropdown"
          />
        )
      case 'HELP_DS':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t('help_nav.help_dropdown.api_documentation'),
                linkTo: 'https://console-us1.data.cloud.hpe.com/doc/api/v1/',
                testId: 'api-documentation-help-dropdown'
              },
              {
                id: 2,
                label: t('help_nav.help_dropdown.user_guide'),
                linkTo:
                  'https://hpe-tcaas.zoominsoftware.io/bundle/DSCC_QuickStart/raw/resource/enus/DSCC_QuickStart.pdf',
                testId: 'user-guide-help-dropdown'
              },
              {
                id: 3,
                label: showWorkspaceString
                  ? t('help_nav.help_dropdown.how_to_create_workspace')
                  : t('help_nav.help_dropdown.how_to_create_company_acc2'),
                linkTo:
                  'https://common.cloud.hpe.com/static/media/Part1-Creating-and-Orginization.cda6ed735f55791d3b97.mp4',
                testId: 'create-organization-help-dropdown'
              },
              {
                id: 4,
                label: t('help_nav.help_dropdown.how_to_add_dscc_app'),
                linkTo:
                  'https://common.cloud.hpe.com/static/media/Part2-Adding-an-Application.89fcb78d29f3cc693356.mp4',
                testId: 'add-application-help-dropdown'
              },
              {
                id: 5,
                label: t('help_nav.help_dropdown.how_to_add_app_permissions'),
                linkTo:
                  'https://www.hpe.com/h22228/video-gallery/us/en/1084793c-b2f0-49e2-9d68-d33347b98c43/video/',
                testId: 'create-assignment-scopes-help-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-help-dropdown"
          />
        )
      case 'HELP_COM':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t('help_nav.help_dropdown.user_guide'),
                linkTo: 'https://www.hpe.com/info/com-gsg',
                testId: 'user-guide-help-dropdown'
              },
              {
                id: 2,
                label: t(
                  'help_nav.help_dropdown.compute_ops_management_community'
                ),
                linkTo: 'https://www.hpe.com/forum/computeopsmanager',
                testId: 'compute-ops-management-service-help-dropdown'
              },
              {
                id: 3,
                label: t('help_nav.help_dropdown.basics_of_compute'),
                linkTo:
                  'https://support.hpe.com/hpesc/public/videoDisplay?videoId=vpsg00005048en_us',
                testId: 'user-guide-help-dropdown'
              },
              {
                id: 4,
                label: t('help_nav.help_dropdown.how_to_update_firmware'),
                linkTo:
                  'https://support.hpe.com/hpesc/public/videoDisplay?videoId=vpsg00005049en_us',
                testId: 'compute-ops-management-service-help-dropdown'
              },
              {
                id: 5,
                label: t('help_nav.help_dropdown.onboarding_1_overview'),
                linkTo:
                  'https://support.hpe.com/hpesc/public/videoDisplay?videoId=vpsg00005087en_us',
                testId: 'compute-ops-management-overview-help-dropdown'
              },
              {
                id: 6,
                label: t('help_nav.help_dropdown.onboarding_2_platform'),
                linkTo:
                  'https://support.hpe.com/hpesc/public/videoDisplay?videoId=vpsg00005088en_us',
                testId: 'compute-ops-management-platform-help-dropdown'
              },
              {
                id: 7,
                label: t('help_nav.help_dropdown.onboarding_3_servers'),
                linkTo:
                  'https://support.hpe.com/hpesc/public/videoDisplay?videoId=vpsg00005089en_us',
                testId: 'compute-ops-management-servers-help-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-help-dropdown"
          />
        )
      case 'HELP_AC':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t('help_nav.help_dropdown.online_help'),
                linkTo:
                  'https://www.arubanetworks.com/techdocs/central/latest/content/home.htm',

                testId: 'online-help-dropdown'
              },
              {
                id: 2,
                label: t('help_nav.help_dropdown.aruba_airhead_community'),
                linkTo: 'https://community.arubanetworks.com/',
                testId: 'aruba-airhead-community-help-dropdown'
              },
              {
                id: 3,
                label: t('help_nav.help_dropdown.online_rma'),
                linkTo: 'https://asp.arubanetworks.com/rma',
                testId: 'online-rma-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-help-dropdown"
          />
        )
      case 'SUPPORT_DEVPORTAL':
        return (
          <>
            <MenuList
              navStyles={menuListNavStyle}
              menuData={[
                {
                  id: 1,
                  label: t('help_nav.help_dropdown.create_new_case'),
                  linkTo:
                    'https://h41390.www4.hpe.com/support/index.html?form=developerportal',
                  testId: 'billing-dropdown'
                },
                {
                  id: 2,
                  label: t('help_nav.help_dropdown.view_cases'),
                  linkTo: 'https://support.hpe.com/connect/s/viewcases',
                  testId: 'account-user-onboarding-select-dropdown'
                }
              ]}
              onClickMenuItem={handleMenuItemClick}
              testId="glcp-support-dropdown"
            />
          </>
        )
      case 'SUPPORT_GLCP':
        return (
          <>
            <MenuList
              navStyles={menuListNavStyle}
              menuData={[
                {
                  id: 1,
                  label: t(
                    'help_nav.help_dropdown.billing_metering_subscription'
                  ),
                  linkTo:
                    'https://h41390.www4.hpe.com/support/index.html?form=osqbm',
                  testId: 'billing-dropdown'
                },
                {
                  id: 2,
                  label: t('help_nav.help_dropdown.acc_user_onboard', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  }),
                  linkTo:
                    'https://h41390.www4.hpe.com/support/index.html?form=glsupport ',
                  testId: 'account-user-onboarding-select-dropdown'
                },
                {
                  id: 3,
                  label: t('help_nav.help_dropdown.view_cases'),
                  linkTo: 'https://support.hpe.com/connect/s/viewcases',
                  testId: 'account-user-onboarding-select-dropdown'
                },
                ...(!isSupportEngineer && hideWhen?.account !== 'TAC'
                  ? [
                      {
                        id: 4,
                        label: t(
                          'support_access_manager:manage_support_access_title'
                        ),
                        linkTo: '/manage-account/support-access',
                        testId: 'desc-manage-sam-select-dropdown'
                      }
                    ]
                  : [])
              ]}
              onClickMenuItem={handleMenuItemClick}
              testId="glcp-support-dropdown"
            />
          </>
        )
      case 'SUPPORT_GLCS':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t(
                  'help_nav.help_dropdown.billing_metering_subscription'
                ),
                linkTo:
                  'https://h41390.www4.hpe.com/support/index.html?form=osqbm',
                testId: 'billing-dropdown'
              },
              {
                id: 2,
                label: t('help_nav.help_dropdown.create_new_case'),
                linkTo:
                  'https://client.greenlake.hpe.com/core/my-support-cases',
                testId: 'create-new-case-select-dropdown'
              },
              {
                id: 3,
                label: t('help_nav.help_dropdown.view_cases'),
                linkTo:
                  'https://client.greenlake.hpe.com/core/my-support-cases',
                testId: 'view-cases-select-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-support-dropdown"
          />
        )
      case 'SUPPORT_DS':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t(
                  'help_nav.help_dropdown.billing_metering_subscription'
                ),
                linkTo:
                  'https://h41390.www4.hpe.com/support/index.html?form=osqbm',
                testId: 'billing-dropdown'
              },
              {
                id: 2,
                label: t('help_nav.help_dropdown.create_case'),
                linkTo:
                  'https://h41390.www4.hpe.com/support/index.html?form=dssupport',
                testId: 'nimble-storage-select-dropdown'
              },
              {
                id: 3,
                label: t('help_nav.help_dropdown.view_data_service_cases'),
                linkTo: 'https://support.hpe.com/connect/s/viewcases',
                testId: 'primera-storage-select-dropdown'
              },
              {
                id: 4,
                label: t('help_nav.help_dropdown.view_hpe_alletra_6000_cases'),
                linkTo: 'https://infosight.hpe.com/app/login',
                testId: 'backup-recovery-select-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-support-dropdown"
          />
        )
      case 'SUPPORT_COM':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t(
                  'help_nav.help_dropdown.billing_metering_subscription'
                ),
                linkTo:
                  'https://h41390.www4.hpe.com/support/index.html?form=osqbm',
                testId: 'billing-dropdown'
              },
              {
                id: 2,
                label: t('help_nav.help_dropdown.create_case'),
                linkTo:
                  'https://h41390.www4.hpe.com/support/index.html?form=computesupport',
                testId: 'create-compute-case-select-dropdown'
              },
              {
                id: 3,
                label: t('help_nav.help_dropdown.view_cases'),
                linkTo: 'https://support.hpe.com/connect/s/viewcases',
                testId: 'view-compute-cases-select-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-support-dropdown"
          />
        )
      case 'SUPPORT_AS':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              // TODO: Enable this once Aruba Central wants
              // {
              //   id: 1,
              //   label: t(
              //     'help_nav.help_dropdown.billing_metering_subscription'
              //   ),
              //   linkTo:
              //     'https://h41390.www4.hpe.com/support/index.html?form=osqbm',
              //   testId: 'billing-dropdown'
              // },
              {
                id: 2,
                label: t('help_nav.help_dropdown.create_new_case'),
                linkTo: 'https://asp.arubanetworks.com/',
                testId: 'create-new-case-select-dropdown'
              },
              {
                id: 3,
                label: t('help_nav.help_dropdown.view_cases'),
                linkTo: 'https://asp.arubanetworks.com/',
                testId: 'view-cases-select-dropdown'
              }
            ]}
            onClickMenuItem={handleMenuItemClick}
            testId="glcp-support-dropdown"
          />
        )
      case 'LEGAL_RESOURCES':
        return (
          <MenuList
            navStyles={menuListNavStyle}
            menuData={[
              {
                id: 1,
                label: t('help_nav.legal_dropdown.privacy_policy'),
                linkTo: 'https://www.hpe.com/us/en/legal/privacy.html',
                testId: 'privacy-policy-legal-drop'
              },
              ...(!isCoP()
                ? [
                    {
                      id: 2,
                      label: t('help_nav.legal_dropdown.cookies'),
                      linkTo:
                        'https://www.hpe.com/us/en/legal/privacy.html#datacollection',
                      testId: 'cookies-legal-drop'
                    }
                  ]
                : []),
              {
                id: 3,
                label: t('help_nav.legal_dropdown.terms_of_use'),
                linkTo:
                  'https://www.hpe.com/us/en/about/legal/terms-of-use.html',
                testId: 'terms-legal-drop'
              },
              ...(!isCoP()
                ? [
                    {
                      id: 4,
                      label: t(
                        'help_nav.legal_dropdown.do_not_sell_personal_info'
                      ),
                      linkTo:
                        'https://www.hpe.com/us/en/privacy/personal-information.html',
                      testId: 'privacy-legal-drop'
                    }
                  ]
                : [])
            ]}
            multiple={false}
            onClickMenuItem={handleMenuItemClick}
            testId="legal-resources-options"
          />
        )
      default:
        return (
          <>
            <MenuList
              navStyles={menuListNavStyle}
              menuData={[
                {
                  id: 1,
                  label: t('help_nav.help_dropdown.getting_started'),
                  linkTo: '/home/get-started',
                  isHidden: () => !custAccountLoaded || isCoP(),
                  testId: 'getting-started-help-dropdown'
                },
                {
                  id: 2,
                  label: t('help_nav.help_dropdown.documentation'),
                  linkTo: isCoP()
                    ? 'https://www.hpe.com/greenlake/GL-onprem-user-guide-en'
                    : 'https://www.hpe.com/greenlake/GLCplatform-user-guide-en',
                  testId: 'documentation-help-dropdown'
                }
              ]}
              onClickMenuItem={handleMenuItemClick}
              testId="glcp-help-dropdown"
            />
          </>
        )
    }
  }
  HelpMenuList.propTypes = {
    menuListFor: PropTypes.string.isRequired
  }

  const helpDrop = {
    hpe_green_lake: <HelpMenuList menuListFor="default" />,
    hpe_green_lake_central: <HelpMenuList menuListFor="HELP_GLCS" />,
    data_services: <HelpMenuList menuListFor="HELP_DS" />,
    compute_ops_management: <HelpMenuList menuListFor="HELP_COM" />,
    aruba_central: <HelpMenuList menuListFor="HELP_AC" />
  }
  const selectDrop = {
    hpe_gl_dev_portal: <HelpMenuList menuListFor="SUPPORT_DEVPORTAL" />,
    hpe_green_lake: <HelpMenuList menuListFor="SUPPORT_GLCP" />,
    hpe_green_lake_central: <HelpMenuList menuListFor="SUPPORT_GLCS" />,
    data_services: <HelpMenuList menuListFor="SUPPORT_DS" />,
    compute_ops_management: <HelpMenuList menuListFor="SUPPORT_COM" />,
    aruba_central: <HelpMenuList menuListFor="SUPPORT_AS" />
  }

  const renderHelpMenu = ({ key, label, value, hideFor }) => {
    return (
      <VisibilityWrapper key={key} hideFor={hideFor}>
        <Box align="start" gap="none" elevation="none" background="none">
          <Box
            background={selectedApp === value ? '#17EBA0' : 'none'}
            width="100%"
            pad="xsmall"
          >
            <Typography
              type="text"
              size="medium"
              emphasis
              color={selectedApp === value ? '#000000' : '#444444'}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      </VisibilityWrapper>
    )
  }

  const HelpMenuData = [
    {
      id: 1,
      label: t('help_nav.help_dropdown.help'),
      content: (
        <Box key={1}>
          <Box pad={{ horizontal: 'medium', top: 'medium' }} gap="small">
            <Typography size="xsmall" type="text">
              {t('help_nav.help_dropdown.help')}
            </Typography>
            <Dropdown
              options={allAppsList}
              defaultVal={selectedApp}
              multiple={false}
              id="help-drop-down"
              testId="help-drop-down"
              placeholder={t('help_nav.dropdown_placeholder')}
              onChangeDropdown={(option) => {
                handleChangeDrop(option)
              }}
              customRender={renderHelpMenu}
            />
          </Box>
          {selectedApp ? (
            <>
              <Box pad={{ horizontal: 'xsmall', top: 'small' }}>
                {helpDrop[selectedApp]}
              </Box>
              <Ruler background="border-weak" />
              <Box pad={{ horizontal: 'small' }} gap="small">
                <Box pad={{ horizontal: 'small' }}>
                  <Typography size="xsmall" type="text">
                    {t('help_nav.help_dropdown.support')}
                  </Typography>
                </Box>
                {selectDrop[selectedApp]}
              </Box>
            </>
          ) : (
            <Box pad={{ horizontal: 'medium' }}>
              <Typography type="paragraph">
                {t('help_nav.help_dropdown.select_description_preselection')}
              </Typography>
            </Box>
          )}
        </Box>
      ),
      isHidden: false
    },
    {
      id: 2,
      label: t('help_nav.feedback_dropdown.feedback'),
      content: (
        <Box key={2} flex>
          <Box pad="medium" gap="xsmall" flex>
            <Typography size="xsmall" type="text" color="text">
              {t('help_nav.feedback_dropdown.give_feedback')}
            </Typography>
            <FormField
              htmlFor="feedback-drop-down"
              label={t('help_nav.feedback_dropdown.which_product')}
            >
              <Dropdown
                options={allAppsList}
                defaultVal={selectedApp}
                multiple={false}
                id="feedback-drop-down"
                testId="feedback-drop-down"
                placeholder={t('help_nav.dropdown_placeholder')}
                onChangeDropdown={(option) => handleChangeDrop(option)}
                customRender={renderHelpMenu}
                plain
              />
            </FormField>
            <FormField
              htmlFor="feedback-types-drop-down"
              label={t('help_nav.feedback_dropdown.what_feedback')}
            >
              <Dropdown
                options={feedbackTypesList}
                defaultVal={selectedFeedbackType}
                multiple={false}
                id="feedback-types-drop-down"
                testId="feedback-types-drop-down"
                placeholder={t('help_nav.dropdown_placeholder')}
                onChangeDropdown={(option) => handleFeedbackTypesChange(option)}
                plain
              />
            </FormField>
          </Box>
          <Box
            border={{ side: 'top', color: 'border-weak' }}
            pad={{ horizontal: 'medium', vertical: 'small' }}
          >
            <Button
              primary
              label={t('continue')}
              disabled={!selectedApp || !selectedFeedbackType}
              onClick={handleFeedbackContinue}
              testId="feedback-continue-btn"
            />
          </Box>
        </Box>
      ),
      isHidden: isCoP()
    },
    {
      id: 3,
      label: t('help_nav.legal_dropdown.legal'),
      content: (
        <Box key={3}>
          <VisibilityWrapper hideFor={{ deployment: 'COP' }}>
            <Box
              pad={{ top: 'small', bottom: 'medium', horizontal: 'medium' }}
              border={{ side: 'bottom', color: 'border-weak' }}
              gap="small"
            >
              <Box
                align="center"
                justify="center"
                overflow="hidden"
                round="xsmall"
              >
                <Image
                  src={legalImgUrl}
                  alt="Two information technology professionals having a discussion with HPE server racks in the background."
                  fit="cover"
                />
              </Box>

              <Typography type="text" emphasis>
                {t('help_nav.legal_dropdown.learn_more')}
              </Typography>
              <Typography size="small" type="text" background="text-light">
                {t('help_nav.legal_dropdown.desc')}
              </Typography>
              <Button
                secondary
                label={t('help_nav.legal_dropdown.subscribe_now')}
                onClick={() =>
                  window.open(
                    'https://www.hpe.com/us/en/newsroom/subscribe.html',
                    '',
                    'noopener'
                  )
                }
                testId="subscribe-now-btn"
              />
            </Box>
          </VisibilityWrapper>
          <Box pad={{ horizontal: 'small', vertical: 'medium' }} gap="small">
            <Box pad={{ horizontal: 'small' }}>
              <Typography size="xsmall" type="text">
                {t('help_nav.legal_dropdown.resources')}
              </Typography>
            </Box>
            <HelpMenuList menuListFor="LEGAL_RESOURCES" />
          </Box>
        </Box>
      ),
      isHidden: false
    }
  ]
  return <GLCPHelpMenu helpNavContent={HelpMenuData} testId="help-menu" />
}

const HelpMenu = {
  id: 1,
  label: 'Help menu',
  icon: (
    <Box
      data-testid="btn-help"
      round
      height="36px"
      width="36px"
      align="center"
      justify="center"
    >
      <HelpOption color="text-strong" />
    </Box>
  ),
  content: <HelpNavContent />
}

export { HelpMenu }
