// (C) Copyright 2025 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react'
// eslint-disable-next-line import/no-unresolved
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { ServerCluster } from 'grommet-icons'
import { useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import uniqBy from 'lodash/uniqBy'

import {
  DataTable,
  FilterButton,
  Typography,
  NoDataInfo
} from '../../../../../../../components'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { get, post } from '../../../../../../../utils/api-utils'
import { validateForm } from '../../../../../../../utils/validation-utils'
import { getFormattedFilterValues } from '../../../../../../device-management/utils'
import { setCombinedKey } from '../../../../../../../utils/dm-sm-common-utils'

export const validateFields = (formValues, i18nTranslate) => {
  const requiredString = i18nTranslate('required')
  const { devices } = formValues
  const formValid = devices?.length > 0
  return {
    serials: formValid ? '' : requiredString,
    isValid: formValid
  }
}

export const validateDeviceSelectionFields = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const SelectUnlicensedDevices = () => {
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const {
    'glcp-dm-attach-subscription-glasgow': attachSubscriptionLD,
    'glcp-multi-license-support': glcpMultiLicenseSupport
  } = LDFlags
  const { t } = useTranslation(['device', 'common'])
  const { licenseKey } = useParams()
  const [unlicensedDevices, setUnlicensedDevices] = useState([])
  const { formValues, setFormValues } = useContext(WizardContext)
  const [applications, setApplications] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filterOptions, setFilterOptions] = useState({})
  const itemsPerPage = 50
  const [page, setPage] = useState(1)
  const [totalDevices, setTotalDevices] = useState(50)
  const [initPageFlag, setInitPageFlag] = useState(0)

  useEffect(() => {
    let isCurrent = true
    get(`/ui-doorway/ui/v1/applications`, {}, oidcUser.access_token).then(
      (response) => {
        let appFilterOptions = uniqBy(
          response?.data?.applications,
          'application_customer_id'
        )
        appFilterOptions = appFilterOptions.map((value) => {
          return {
            valueLabel: `${value.application_name} (${value.ccs_region})`,
            valueName: value.application_customer_id
          }
        })
        if (!isCurrent) return
        setApplications(appFilterOptions)
      },
      (error) => {
        console.log(error)
      }
    )
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token])

  useEffect(() => {
    const supportedDeviceTypes = JSON.parse(
      sessionStorage.getItem('supportedDeviceTypes')
    )
    const url = `/ui-doorway/ui/v1/license/devices/search?limit=${itemsPerPage}&offset=${
      (page - 1) * itemsPerPage
    }`
    const modifiedFilterOptions = getFormattedFilterValues(filterOptions)
    post(
      url,
      {
        device_license_type: 'NOT_LICENSED',
        ...(attachSubscriptionLD || glcpMultiLicenseSupport
          ? { subscription_key: licenseKey }
          : { device_type: supportedDeviceTypes?.toString() || [] }),
        ...(searchTerm.length && { device_pattern: searchTerm }),
        ...(Object.keys(modifiedFilterOptions)?.length && modifiedFilterOptions)
      },
      oidcUser.access_token
    ).then(
      (response) => {
        const tableData = setCombinedKey(response?.data?.devices || [])
        setUnlicensedDevices(tableData)
        setTotalDevices(response?.data?.pagination?.total_count)
        if (tableData?.length) setInitPageFlag((count) => count + 1)
      },
      (error) => {
        setUnlicensedDevices([])
        console.log(error)
      }
    )
  }, [
    oidcUser.access_token,
    searchTerm,
    filterOptions,
    page,
    licenseKey,
    attachSubscriptionLD,
    glcpMultiLicenseSupport
  ])

  const filterValues = applications?.length
    ? [
        {
          label: t('application'),
          name: 'application_customer_ids',
          values: applications
        }
      ]
    : []

  const columns = [
    {
      property: 'serial_number',
      type: 'string',
      header: t('serial_number'),
      primary: true,
      render: (datum) => (
        <Typography size="medium" testId="t13" type="text" weight="bold">
          {datum.serial_number}
        </Typography>
      )
    },
    {
      property: 'device_model',
      type: 'string',
      header: t('model')
    },
    {
      property: 'application',
      type: 'string',
      header: t('application')
    }
  ]

  const handleClickRow = (rows) => {
    if (rows?.length) {
      const deviceInfo = unlicensedDevices?.filter((value) => {
        return rows?.includes(value.serial_number)
      })
      const modifiedDeviceInfo = deviceInfo?.reduce((acc, o) => {
        acc.push({
          serial_number: o.serial_number,
          device_type: o.device_type,
          part_number: o.part_number,
          resource_id: o.resource_id,
          subscriptions: o.subscriptions
        })
        return acc
      }, [])
      setFormValues({ ...formValues, devices: modifiedDeviceInfo || [] })
    } else setFormValues({ ...formValues, devices: [] })
  }

  return (
    <Box width="large" margin={{ bottom: 'medium' }}>
      {!initPageFlag ? (
        <Box margin={{ top: 'large' }}>
          <NoDataInfo
            icon={<ServerCluster color="text" size="large" />}
            subtitle={t('inventory_init_desc1')}
            testId="no-data-info-without-title-licenses"
          />
        </Box>
      ) : (
        <DataTable
          pagination={{
            totalItems: totalDevices,
            itemsPerPage,
            page,
            setPage
          }}
          filterButton={
            <FilterButton
              filterAttributes={filterValues}
              onFilterValuesChange={(filters) => {
                setFilterOptions(filters)
                setPage(1)
              }}
              testId="filter-search-btn"
            />
          }
          grid={{
            columns,
            data: unlicensedDevices
          }}
          search={{
            onSearchValueChange: (val) => {
              setSearchTerm(val)
              setPage(1)
            },
            placeholder: t('search_unsubscribed_devices'),
            width: 'xxlarge'
          }}
          summary={{
            entityName: t('devices_tab')
          }}
          selection={{
            onSelectionChange: (rows) => handleClickRow(rows),
            primaryKey: 'serial_number'
          }}
          testId="unlicensed-devices-table"
        />
      )}
    </Box>
  )
}

export default SelectUnlicensedDevices
