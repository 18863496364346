// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useParams } from 'react-router-dom'
import {
  Anchor,
  Box,
  Data,
  DataSearch,
  DataSummary,
  DataTable,
  Pagination,
  Toolbar
} from 'grommet'
import uniqBy from 'lodash/uniqBy'
import { CircleInformation, ShareRounded } from 'grommet-icons'

import { get } from '../../../../utils/api-utils'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  Button,
  Loader,
  NoDataInfo,
  Notification
} from '../../../../components'
import { GREENLAKE_SUPPORT_URL } from '../../../../utils/error-utils'
import { setCombinedKey } from '../../../../utils/dm-sm-common-utils'

const defaultView = {
  search: '',
  step: 20,
  page: 1
}

const AddDevices = () => {
  const { t } = useTranslation(['device', 'licensing', 'common'])
  const { oidcUser } = useReactOidc()
  const { formValues, setFormValues } = useContext(WizardContext)
  const { productSquid } = useParams()

  const [errorMessage, setErrorMessage] = useState('')

  // Total item in the inventory irrespective of search, filter & pagination
  const [total, setTotal] = useState(0)
  // It holds data & data count in the current page
  const [result, setResult] = useState({ data: [], filteredTotal: 0 })
  // It holds search, filter, page & limit/step value
  const [view, setView] = useState(defaultView)
  // To display loader when BE api is in progress to show No data component
  const [loading, setLoading] = useState(true)
  // To show no data component
  const [hideNoData, setHideNoData] = useState(false)

  // useEffect to fetch subscriptions from squid for addition
  useEffect(() => {
    get(
      '/ui-doorway/ui/v1/devices',
      {
        squid: productSquid,
        ...(view.search?.length > 0 && { search_string: view.search }),
        limit: view.step,
        offset: ((view.page || 1) - 1) * view.step
      },
      oidcUser.access_token
    )
      .then(
        (response) => {
          const modifiedResponse = setCombinedKey(response?.data?.devices || [])
          const totalCount = response?.data?.pagination?.total_count
          // Stores current list diplayed in table and total count in the current search & filter state
          setResult({
            data: modifiedResponse,
            filteredTotal: totalCount
          })

          // It keeps count of max list among previous total count & filtered total count
          setTotal((prevTotal) => Math.max(prevTotal, totalCount))
          if (totalCount) setHideNoData(true)
        },
        (error) => {
          // If API error out when user perform search or sort, display error
          // Else no data component will suffix as it contains contact support link
          if (total) setErrorMessage(error)
        }
      )
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, view])

  // columns to be dispalyed in datatable
  const columns = [
    {
      property: 'serial_number',
      header: t('serial_number_sentence_case')
    },
    {
      property: 'device_model',
      header: t('model')
    },
    {
      property: 'device_type',
      header: t('device_type_sentence_case')
    },
    {
      property: 'part_number',
      header: t('product_id')
    }
  ]

  // To handle checkbox selection
  const handleSelectionChange = (selectedData) => {
    const newlySelectedRowsDetails =
      result?.data?.filter((value) =>
        selectedData?.includes(value?.unique_key)
      ) || []

    const existingSelectedRowsDetails =
      formValues?.selectedDevicesList?.filter((value) =>
        selectedData?.includes(value?.unique_key)
      ) || []

    const selectedRowsDetails = uniqBy(
      [...newlySelectedRowsDetails, ...existingSelectedRowsDetails],
      'unique_key'
    )
    setFormValues({
      ...formValues,
      selectedDevicesList: selectedRowsDetails,
      claimedAllDevices: selectedRowsDetails?.length === total
    })
  }

  const getDeviceContent = () => {
    if (!hideNoData)
      return (
        <Box margin={{ bottom: 'medium' }}>
          <NoDataInfo
            icon={<CircleInformation color="text" size="large" />}
            title={t('licensing:add_products.no_available_devices')}
            titleHeadingLevel={2}
            subtitle={t('licensing:add_products.no_available_devices_desc')}
            action={
              <Button
                label={t('common:contact_support')}
                secondary
                testId="contact-support-btn"
                href={GREENLAKE_SUPPORT_URL}
                target="_blank"
                icon={<ShareRounded />}
                reverse
              />
            }
            testId="no-devices-info"
          />
        </Box>
      )
    return (
      <>
        <Data
          data={result?.data}
          total={total}
          filteredTotal={result?.filteredTotal}
          defaultView={defaultView}
          view={view}
          onView={setView}
          messages={{
            dataSummary: {
              items: t('devices_lowercase'),
              itemsSingle: t('device_lowercase')
            }
          }}
        >
          <Toolbar direction="column">
            <Toolbar>
              <DataSearch
                data-testid="search-field"
                placeholder={t(
                  'licensing:add_products.search_serial_or_product_id'
                )}
                width="medium"
              />
            </Toolbar>
            <Toolbar>
              <DataSummary data-testid="table-summary" />
            </Toolbar>
          </Toolbar>
          <DataTable
            columns={columns}
            select={
              formValues?.selectedDevicesList?.map(
                ({ unique_key: key }) => key
              ) || []
            }
            onSelect={handleSelectionChange}
            primaryKey="unique_key"
            data-testid="devices-datatable"
          />
          <Pagination
            summary
            border="top"
            pad={{ vertical: 'xsmall' }}
            stepOptions={[20, 40, 60, 100]}
          />
        </Data>
        <Box margin={{ top: 'large' }}>
          <Notification
            status="info"
            testId="add-devices-info-banner-notification"
            text={
              <Trans
                t={t}
                i18nKey="licensing:add_products.add_devices_info_message"
              >
                <Anchor
                  style={{ whiteSpace: 'wrap' }}
                  weight="normal"
                  href={GREENLAKE_SUPPORT_URL}
                  target="_blank"
                />
              </Trans>
            }
            type="inline"
          />
        </Box>
      </>
    )
  }

  return (
    <Box
      direction="column"
      justify="between"
      margin={{ bottom: 'small', top: 'medium' }}
    >
      {loading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="devices-loader" />
        </Box>
      ) : (
        getDeviceContent()
      )}

      {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
    </Box>
  )
}

export { AddDevices }
