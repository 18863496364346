// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { Box, ResponsiveContext } from 'grommet'
import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ChatOption, Close } from 'grommet-icons'
import styled from 'styled-components'

import { ChatActions, useChatContext } from './context/chat-context'

const HoverButton = styled(Box)`
  z-index: 2; /* Adjust this value based on your layout */

  &:hover [data-testid='chat-bot-logo'] {
    background-image: linear-gradient(
      to bottom left,
      rgba(0, 133, 103, 1),
      rgba(1, 169, 130, 0.7),
      rgba(0, 133, 103, 1)
    );
  }
  &:focus {
    outline: none;
  }
`

const ChatBotLogo = ({ openChatBot, close = false }) => {
  const size = useContext(ResponsiveContext)
  const {
    dispatchChatContext,
    feedbackFormData,
    lastActiveFeedback,
    setLastActiveAsFeedback
  } = useChatContext()
  const { showFeedbackForm } = feedbackFormData

  const onClearChatBot = async () => {
    sessionStorage.removeItem('chatInputMessage')

    openChatBot({ type: ChatActions.CLEAR_STATE, payload: { open: false } })
  }
  const { showSidePanel, setShowSidePanel } = useChatContext()

  const onOpenChatBot = () => {
    const updateFeedbackForm = (show) => {
      dispatchChatContext({
        type: ChatActions.UPDATE_FEEDBACK_FORM,
        payload: { ...feedbackFormData, showFeedbackForm: show }
      })
    }

    if (close) {
      updateFeedbackForm(true)
      if (showFeedbackForm) {
        updateFeedbackForm(false)
        onClearChatBot()
        setLastActiveAsFeedback(false)
      } else {
        setLastActiveAsFeedback(true)
      }
    } else {
      updateFeedbackForm(false)
      if (lastActiveFeedback) {
        if (!showFeedbackForm) {
          updateFeedbackForm(true)
          openChatBot({ type: ChatActions.OPEN_CHAT, payload: { open: true } })
        }
      } else {
        openChatBot({ type: ChatActions.OPEN_CHAT, payload: { open: true } })
      }
    }
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const sidePanel = document.querySelector(
        '[data-testid="contextual-help"]'
      )
      setShowSidePanel(true)
      if (!sidePanel) {
        setShowSidePanel(false)
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  })

  const isAboveMedium =
    size === 'medium' ||
    size === 'large' ||
    size === 'xmedium' ||
    size === 'xlarge'

  let rightOffset = '10px'
  let bottomOffset
  const isSmallSize = size === 'small' || size === 'xsmall'

  if (isSmallSize) {
    rightOffset = '0'
    bottomOffset = '0'
  } else if (isAboveMedium && showSidePanel) {
    rightOffset = '450px'
  }

  bottomOffset = size === 'xsmall' || size === 'small' ? '0' : '10px'

  return (
    <HoverButton
      style={{
        position: 'fixed',
        bottom: `${bottomOffset}`,
        right: `${rightOffset}`
      }}
    >
      <Box
        margin="medium"
        width="3rem"
        height="3rem"
        style={{
          cursor: 'pointer',
          position:
            size === 'xsmall' || size === 'small' ? 'fixed' : 'undefined',
          right: `${rightOffset}`,
          bottom: `${bottomOffset}`
        }}
        round="full"
        background="#01A982"
        align="center"
        data-testid="chat-bot-logo"
        justify="center"
        onClick={onOpenChatBot}
      >
        {close ? (
          <Close
            color="white"
            size="small"
            data-testid="chatbot-close-icon"
            aria-label="close the virtual assistant"
          />
        ) : (
          <ChatOption
            color="white"
            size="20px"
            data-testid="chatbot-open-icon"
            aria-label="open the virtual assistant"
          />
        )}
      </Box>
    </HoverButton>
  )
}

ChatBotLogo.propTypes = {
  openChatBot: PropTypes.func.isRequired,
  close: PropTypes.bool
}

export default ChatBotLogo
