// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { FormField, RadioButtonGroup, Box, ThemeContext } from 'grommet'
import { CircleInformation } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  CCSForm,
  FormInput,
  FormTextArea,
  Typography,
  Tooltip
} from '../../../components'
import {
  addAccessRuleDynamicFields,
  formatRuleData,
  handleConfirmation
} from '../utils'
import { getWorkspaceString, WKSPC_PLURAL } from '../../../utils/common-utils'

const AccessRuleModal = ({
  mode,
  ruleData = undefined,
  setOpen,
  onSuccess
}) => {
  const { t } = useTranslation(['authn'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')
  const protocolVersions = ['IPV4', 'IPV6']
  const ipFormats = [
    { label: t('ip_access_rules.single_ip_address'), value: 'SINGLE_IP' },
    { label: t('ip_access_rules.ip_subnet_mask'), value: 'IP_SUBNET_MASK' },
    { label: t('ip_access_rules.ip_range'), value: 'IP_RANGE' }
  ]
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const theme = React.useContext(ThemeContext)

  const initialData =
    mode === 'add'
      ? {
          protocolVersion: protocolVersions[0],
          ipFormat: ipFormats[0].value,
          desc: ''
        }
      : formatRuleData(ruleData)

  const [data, dispatch] = useReducer((curr, action) => {
    switch (action.field) {
      // the below case can be uncommented when we have IPV6 into picture
      // case 'protocolVersion':
      //   return {
      //     desc: curr.desc,
      //     ipFormat: curr.ipFormat,
      //     protocolVersion: action.value,
      //     ...(curr.ip_access_rule_id && {
      //       ip_access_rule_id: curr.ip_access_rule_id
      //     })
      //   }
      case 'ipFormat':
        return {
          desc: curr.desc,
          protocolVersion: curr.protocolVersion,
          ipFormat: action.value,
          ...(curr.ip_access_rule_id && {
            ip_access_rule_id: curr.ip_access_rule_id
          })
        }
      default:
        return { ...curr, [action.field]: action.value }
    }
  }, initialData)

  return (
    <ModalDialog
      testId="add-access-rule-modal"
      onClose={() => setOpen(false)}
      header={
        <ModalHeader
          title={
            <Typography
              level={2}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              testId="add-header-title"
              type="heading"
            >
              {mode === 'add'
                ? t('ip_access_rules.add_access_rule')
                : t('ip_access_rules.edit_access_rule')}
            </Typography>
          }
          subtitle={
            mode === 'add'
              ? t('ip_access_rules.add_access_rule_subtitle', {
                  accounts: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_PLURAL
                  )
                })
              : undefined
          }
        />
      }
      content={
        <Box margin={{ top: 'large' }}>
          <CCSForm
            errorMessage={errorMessage}
            testId="add-access-rule-form"
            onSubmit={() =>
              handleConfirmation(
                t,
                onSuccess,
                setErrorMessage,
                data,
                mode,
                setOpen,
                oidcUser
              )
            }
            onReset={() => setOpen(false)}
            validate="blur"
          >
            <>
              <Box margin={{ bottom: 'small' }}>
                {/* INFO: Commenting the option to add IPv6 addresses as IPv6 are not supported as of now
                <FormField
                  required
                  label={t('ip_access_rules.protocol_version')}
                  name="protocol_version"
                  width="medium"
                  testId="protocol-version-dropdown"
                >
                  <Dropdown
                    name="protocol_version"
                    options={protocolVersions}
                    defaultVal={protocolVersions[0]}
                    value={data.protocolVersion}
                    onChange={({ option }) => {
                      dispatch({
                        value: option,
                        field: 'protocolVersion'
                      })
                    }}
                    testId="protocol-version-dropdown"
                  />
                </FormField> */}
                <FormField
                  label={t('ip_access_rules.ipv4_format')}
                  name="ip_format"
                  width="medium"
                >
                  <RadioButtonGroup
                    name="ip-format"
                    options={ipFormats}
                    value={data.ipFormat}
                    onChange={(event) =>
                      dispatch({ value: event.target.value, field: 'ipFormat' })
                    }
                    data-testid="ipv4-format"
                  />
                </FormField>
                {addAccessRuleDynamicFields(t)[data.ipFormat].map((field) => (
                  <FormInput
                    key={field.name}
                    name={field.name}
                    inputType="text"
                    label={
                      <Typography
                        reverse
                        type="text"
                        icon={
                          field.example && (
                            <Tooltip
                              plain
                              info={
                                <Box
                                  fill
                                  background="background-back"
                                  pad="small"
                                  elevation="xlarge"
                                  width="small"
                                >
                                  <Typography type="text" weight="bold">
                                    {t('ip_access_rules.example')}:
                                  </Typography>
                                  <Typography type="text">
                                    {field.example[data.protocolVersion]}
                                  </Typography>
                                </Box>
                              }
                              testId="tooltip"
                            >
                              <Box pad={{ top: 'xsmall' }}>
                                <CircleInformation size="small" />
                              </Box>
                            </Tooltip>
                          )
                        }
                        {...theme.formField.label}
                        testId={`${field.name}-label`}
                      >
                        {field.label}*
                      </Typography>
                    }
                    placeholder={field.placeholder[data.protocolVersion]}
                    width="medium"
                    value={data[field.name] || ''}
                    validate={(value) =>
                      field.validate[data.protocolVersion](value)
                    }
                    onChange={(e) =>
                      dispatch({
                        value: e.target.value,
                        field: field.name
                      })
                    }
                    testId={`${field.name}-form-field`}
                  />
                ))}
                <FormTextArea
                  name="description"
                  label={t('ip_access_rules.description')}
                  placeholder={t('ip_access_rules.add_access_rule_desc')}
                  value={data.desc}
                  width="medium"
                  required
                  onChange={(e) =>
                    dispatch({
                      value: e.target.value,
                      field: 'desc'
                    })
                  }
                  testId="description-form-field"
                />
              </Box>
              <ModalFooter
                right={
                  <ButtonGroup
                    buttonList={[
                      {
                        id: 2,
                        label: t('cancel', { ns: 'common' }),
                        default: true,
                        type: 'reset',
                        testId: 'cancel-btn'
                      },
                      {
                        id: 1,
                        label: t(mode === 'add' ? 'add' : 'save', {
                          ns: 'common'
                        }),
                        primary: true,
                        type: 'submit',
                        testId: 'add-btn'
                      }
                    ]}
                    testId="button-group"
                  />
                }
              />
            </>
          </CCSForm>
        </Box>
      }
    />
  )
}

AccessRuleModal.propTypes = {
  mode: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  ruleData: PropTypes.object,
  onSuccess: PropTypes.func.isRequired
}

export default AccessRuleModal
