// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormField, RadioButtonGroup } from 'grommet'
import { useTranslation } from 'react-i18next'

import { MSP_WORKSPACE_SCOPES, TENANT_WORKSPACE_SCOPES } from '../constants'

import ScopeGroupMultiSelect from './ScopeGroupMultiSelect'
import WorkspaceGroupMultiselect from './WorkspaceGroupMultiselect'

const MSPScopeSelection = ({
  handleSelection,
  scopeError,
  wkspcGroupScopeError,
  setApiError,
  isCCSRole = false
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const [showScopeGroupDropdown, setShowScopeGroupDropdown] = useState(false)
  const [showWkspGroupDropdown, setShowWkspGroupDropdown] = useState(false)

  const mspWkspScopeOptions = [
    {
      label: t('iam:msp.workspace_scope_none'),
      value: MSP_WORKSPACE_SCOPES?.NONE
    },
    {
      label: t('iam:msp.msp_workspace_scope_entire', {
        workspace: t('common:business_object.wkspc')
      }),
      value: MSP_WORKSPACE_SCOPES?.ENTIRE
    },
    ...(!isCCSRole
      ? [
          {
            label: t('iam:msp.msp_workspace_scope_specific'),
            value: MSP_WORKSPACE_SCOPES?.SPECIFIC_SCOPE_GROUPS
          }
        ]
      : [])
  ]

  const customerWkspScopeOptions = [
    {
      label: t('iam:msp.workspace_scope_none'),
      value: TENANT_WORKSPACE_SCOPES?.NONE
    },
    {
      label: t('iam:msp.msp_all_customers', {
        workspaces: t('common:business_object.wkspc_plural')
      }),
      value: TENANT_WORKSPACE_SCOPES?.ALL
    },
    {
      label: t('iam:msp.msp_specific_wkspc_groups', {
        wkspcGroups: t('common:business_object.wkspc_group_plural')
      }),
      value: TENANT_WORKSPACE_SCOPES?.SPECIFIC_WORKSPACE_GROUPS
    }
  ]

  return (
    <>
      <FormField
        required
        label={t('iam:msp.msp_workspace_scope', {
          workspace: t('common:business_object.wkspc')
        })}
        error={scopeError?.scopeError}
        data-testid="msp-wksp-scope-radio-btns"
      >
        <RadioButtonGroup
          name="msp-workspace-scope"
          options={mspWkspScopeOptions}
          labelKey="label"
          valueKey="value"
          onChange={(event) => {
            scopeError?.clearError()
            handleSelection(event?.value, 'scope')
            if (event?.value === MSP_WORKSPACE_SCOPES?.SPECIFIC_SCOPE_GROUPS) {
              setShowScopeGroupDropdown(true)
            } else {
              setShowScopeGroupDropdown(false)
            }
          }}
        />
      </FormField>
      {showScopeGroupDropdown && (
        <ScopeGroupMultiSelect
          setSelectedScopeGroups={(scopeGroups) => {
            handleSelection(scopeGroups, 'scopeGroupInstances')
          }}
          scopeError={scopeError}
          setApiError={setApiError}
        />
      )}
      <FormField
        required
        label={t('iam:msp.customer_workspace_scope', {
          workspace: t('common:business_object.wkspc')
        })}
        error={wkspcGroupScopeError?.wkspcGroupScopeError}
        data-testid="customer-wksp-scope-radio-btns"
      >
        <RadioButtonGroup
          name="customer-workspace-scope"
          options={customerWkspScopeOptions}
          labelKey="label"
          valueKey="value"
          onChange={(event) => {
            wkspcGroupScopeError?.clearError()
            handleSelection(event?.value, 'customerWorkspaceScope')
            if (
              event?.value ===
              TENANT_WORKSPACE_SCOPES?.SPECIFIC_WORKSPACE_GROUPS
            ) {
              setShowWkspGroupDropdown(true)
            } else {
              setShowWkspGroupDropdown(false)
            }
          }}
        />
      </FormField>
      {showWkspGroupDropdown && (
        <WorkspaceGroupMultiselect
          setSelectedWorkspaceGroups={(workspaceGroups) => {
            handleSelection(workspaceGroups, 'workspaceGroupInstances')
          }}
          wkspcGroupScopeError={wkspcGroupScopeError}
          setApiError={setApiError}
        />
      )}
    </>
  )
}

MSPScopeSelection.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  scopeError: PropTypes.string.isRequired,
  wkspcGroupScopeError: PropTypes.string.isRequired,
  setApiError: PropTypes.func.isRequired,
  isCCSRole: PropTypes.bool.isRequired
}

export default MSPScopeSelection
