// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import {
  Box,
  Grid,
  Notification as GrommetNotification,
  NameValueList,
  NameValuePair,
  Text
} from 'grommet'
import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Trans, useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  ButtonGroup,
  Notification,
  Loader
} from '../../../components'
import { isMSP } from '../../../utils/feature-flag-utils'
import { getCCSAppDetails } from '../../../utils/ccs-manager-utils'
import { getRoleUpdateAction } from '../../../utils/manage-account-identity-utils'

import {
  getUserName,
  assignRoleAPICall,
  getNewRoleAssignmentUIData,
  getPreviousRoleAssignmentUIData
} from './utils'

const AssignRoleConfirmationModal = ({
  setModal,
  userData,
  selectedRoleDetails,
  existingRoleAssignments,
  reloadUsersTable = () => {},
  accessRulesList,
  inCCSManager = false,
  customerId = undefined,
  setShowNotification = () => {},
  onSuccessAssign = () => {}
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn', 'authz', 'common'])
  const { ccsApplicationId } = getCCSAppDetails()
  const LDFlags = useFlags()
  const isTenantWorkspaceGroup = LDFlags['tenant-workspace-group'] && isMSP
  const rrpWorkForMSP = LDFlags['glcp-msp-rrp'] && isMSP
  const [errorMessage, setErrorMessage] = useState(null)
  const [action, setAction] = useState('') // ADD, OVERWRITE, SWAP_ROLE, MERGE, MERGE_OVERWRITE
  const [loading, setLoading] = useState(false)

  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)

  const filteredPrevRolesByApp = existingRoleAssignments.filter(
    (data) => data.application_id === selectedRoleDetails?.application_id
  )

  const filteredPrevRolesByRoleSlug = filteredPrevRolesByApp.filter(
    (data) => data.slug === selectedRoleDetails?.role_slug
  )

  // set role update action - ADD, OVERWRITE, SWAP_ROLE, MERGE, MERGE_OVERWRITE
  useEffect(() => {
    if (action === '')
      setAction(
        getRoleUpdateAction(
          filteredPrevRolesByApp,
          filteredPrevRolesByRoleSlug,
          selectedRoleDetails,
          ccsApplicationId,
          inCCSManager
        )
      )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // formatting the new role assignment for request body
  const newRoleAssignment = {
    role: {
      slug: selectedRoleDetails.role_slug,
      application_id: selectedRoleDetails.application_id
    },
    ...(selectedRoleDetails.access_rules && {
      access_rules: selectedRoleDetails.access_rules
    }),
    ...(selectedRoleDetails.application_id !== ccsApplicationId &&
      (!isMSP() || rrpWorkForMSP) && {
        resource_restriction_policies:
          selectedRoleDetails.resource_restriction_policies
            ?.map((rrp) => rrp?.id)
            .filter((rrp) => !['', null, undefined].includes(rrp)) || []
      })
  }

  // for showing data in UI

  // for rendering the columns in confirmation modal
  const renderData = ({ heading, data }) => (
    <Box gap="medium">
      <Typography
        type="heading"
        level="3"
        testId={heading?.toLowerCase()?.split(' ')?.join('-')}
      >
        {heading}
      </Typography>

      <NameValueList>
        {Object.entries(data).map((datum) => (
          <NameValuePair
            key={datum[0]}
            name={
              <Typography
                testId={datum[0]}
                type="text"
                wordBreak="break-word"
                {...name}
              >
                {t(`users.${datum[0]}`)}
              </Typography>
            }
          >
            <Typography
              testId={`${datum[0]}-value`}
              type="text"
              wordBreak="break-word"
            >
              <>{datum[1] ? datum[1] : '--'}</>
            </Typography>
          </NameValuePair>
        ))}
      </NameValueList>
    </Box>
  )

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box gap="xsmall">
              <Typography type="heading" level="1" testId="header-title">
                <Trans
                  i18nKey="users.assign_role_to_username"
                  t={t}
                  values={{
                    userName: getUserName(userData)
                  }}
                />
              </Typography>
              <Typography type="text" size="large" testId="header-title">
                {t('users.assign_role_confirmation_modal_subtitle')}
              </Typography>
            </Box>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }} gap="medium">
          {selectedRoleDetails?.support_one_role_only &&
            (isMSP() ? (
              <GrommetNotification
                status="info"
                message={
                  <Trans
                    i18nKey="users.msp_support_one_role_only_msg"
                    t={t}
                    values={{
                      applicationName: selectedRoleDetails?.application_name
                    }}
                    // Grommet text component is needed otherwise the Box
                    // from Typography component forces a line break
                    components={[<Text color="text-strong" weight={500} />]}
                  />
                }
              />
            ) : (
              <GrommetNotification
                status="info"
                message={
                  <Trans
                    i18nKey="users.non_msp_support_one_role_only_msg"
                    t={t}
                    values={{
                      applicationName: selectedRoleDetails?.application_name
                    }}
                    components={[<Text color="text-strong" weight={500} />]}
                  />
                }
              />
            ))}

          {action === '' ? (
            <Loader testId="loader" />
          ) : (
            <Grid
              columns={['1/2', '1/2']}
              areas={[['left', 'right']]}
              rows={['fill']}
            >
              <Box gridArea="left" pad={{ right: 'small' }} data-testid="left">
                {action === 'ADD'
                  ? renderData({
                      heading: t('users.confirm_role'),
                      data: getNewRoleAssignmentUIData({
                        selectedRoleDetails,
                        accessRulesList,
                        ccsApplicationId,
                        rrpWorkForMSP,
                        t
                      })
                    })
                  : renderData({
                      heading: t('users.currently_assigned'),
                      data: getPreviousRoleAssignmentUIData({
                        filteredPrevRolesByRoleSlug,
                        filteredPrevRolesByApp,
                        selectedRoleDetails,
                        action,
                        accessRulesList,
                        isTenantWorkspaceGroup,
                        ccsApplicationId,
                        existingRoleAssignments,
                        rrpWorkForMSP,
                        t
                      })
                    })}
              </Box>
              {action !== 'ADD' && (
                <Box
                  gridArea="right"
                  pad={{ left: 'small' }}
                  data-testid="right"
                >
                  {renderData({
                    heading: t('users.overwrite_access_with'),
                    data: getNewRoleAssignmentUIData({
                      selectedRoleDetails,
                      accessRulesList,
                      ccsApplicationId,
                      rrpWorkForMSP,
                      t
                    })
                  })}
                </Box>
              )}
            </Grid>
          )}
          {errorMessage && (
            <Notification
              backgroundColor="status-critical"
              onClose={() => {
                setErrorMessage(null)
              }}
              testId="assign-role-error-notification"
              text={errorMessage}
              type="inline"
            />
          )}
          {loading && (
            <Box direction="row" align="center" justify="center">
              <Loader testId="assign-role-loader" />
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('users.cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => setModal(false)
                },
                {
                  id: 1,
                  label: t('users.change_role_assignment_label'),
                  primary: true,
                  testId: 'confirm-assignment-role-btn',
                  onClick: () =>
                    assignRoleAPICall({
                      setModal,
                      userData,
                      selectedRoleDetails,
                      existingRoleAssignments,
                      reloadUsersTable,
                      inCCSManager,
                      customerId,
                      setShowNotification,
                      onSuccessAssign,
                      setLoading,
                      action,
                      newRoleAssignment,
                      setErrorMessage,
                      t,
                      oidcUser
                    }),
                  disabled: loading
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => setModal(false)}
      testId="assign-role-confirmation-modal"
      width="large"
    />
  )
}

AssignRoleConfirmationModal.propTypes = {
  userData: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  selectedRoleDetails: PropTypes.object.isRequired,
  reloadUsersTable: PropTypes.func,
  existingRoleAssignments: PropTypes.array.isRequired,
  accessRulesList: PropTypes.array.isRequired,
  inCCSManager: PropTypes.bool,
  customerId: PropTypes.string,
  setShowNotification: PropTypes.func,
  onSuccessAssign: PropTypes.func
}

export { AssignRoleConfirmationModal }
