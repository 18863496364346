// Copyright 2024 Hewlett Packard Enterprise Development LP
import { isEmpty } from 'lodash'

import { get, getErrorMessage } from '../../utils/api-utils'
import { getApiErrorMessage } from '../../utils/error-handling-utils'
import { getCCSAppDetails } from '../../utils/ccs-manager-utils'

const getFolder = async ({ token, folderId, t, setErrorMessage }) => {
  const url = `/ui-doorway/ui/v1/activate/folders/${folderId}`
  return get(url, {}, token).then(
    (response) => {
      return response
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}

const getCurrentCCSRoles = (roles) => {
  const { ccsApplicationId } = getCCSAppDetails()
  return roles
    .filter((role) => role.application_id === ccsApplicationId)
    .map((role) => role.slug)
}

export const roleKindMapping = new Map([
  ['LINKED', 'Linked'],
  ['CUSTOM', 'Custom'],
  ['PREDEFINED', 'Built-in'],
  ['MANAGED', 'Managed']
])

export const showActionDropdown = (policies, actionList) => {
  return actionList.some(
    (actions) =>
      policies?.effects?.[actions?.visibility?.rbac?.resource]?.[
        actions?.visibility?.rbac?.permission
      ]
  )
}

export const showV2ActionDropdown = (policies, actionList) => {
  return actionList?.some((action) =>
    policies?.includes(`${action?.visibility?.rbac?.permission}`)
  )
}

export const showContent = (policies, rbac) => {
  return policies?.effects?.[rbac?.resource]?.[rbac?.permission]
}

export const getGLCPAppDetails = () => {
  return {
    glcpApplicationId: '00000000-0000-0000-0000-000000000000',
    glcpApplicationName: 'HPE GreenLake Platform'
  }
}

export const getUserName = (data) => {
  if (data?.subject_type === 'user') {
    if (data?.subject_name !== '')
      return `${data?.subject_name} (${data?.subject_email})`
    return data?.subject_email
  }
  return data?.subject_name
}

const updateStatusName = (name) => {
  switch (name) {
    case 'pending':
      return 'Pending'

    case 'warning':
      return 'Warning'

    case 'disabled':
      return 'Disabled'

    case 'active':
      return 'Active'

    case 'error':
      return 'Critical'

    default:
      return name || ''
  }
}

const getDomainFromEmail = (email) =>
  email?.trim()?.split('@')?.pop()?.toLowerCase()

const validateSAMLAuthzDomain = (
  email,
  oidcUser,
  t,
  setLoading,
  onAPIError,
  onSAMLAuthz = () => {},
  onNonSAMLAuthz
) => {
  const domain = getDomainFromEmail(email)
  if (!isEmpty(domain)) {
    setLoading(true)
    get(
      `/ui-doorway/ui/v1/saml/claims?domains=${encodeURIComponent(domain)}`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.sso_mode) {
          onSAMLAuthz()
        } else if (onNonSAMLAuthz) {
          onNonSAMLAuthz()
        }
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        onAPIError(getErrorMessage(error, t), 'error')
      }
    )
  }
}

export {
  getFolder,
  getCurrentCCSRoles,
  updateStatusName,
  validateSAMLAuthzDomain,
  getDomainFromEmail
}
