// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useReducer } from 'react'

import CCSActions from './CCSActions'

export const initialContext = {
  isIpRestricted: false,
  workSpaceGrn: null,
  csrfToken: null,
  uploadVersionPkgPercent: undefined,
  analytics: undefined
}

export const CCSReducer = (state, action) => {
  switch (action.type) {
    case CCSActions.SET_IS_IP_RESTRICTED:
      return { ...state, isIpRestricted: action.data }
    case CCSActions.SET_IDLE_TIMEOUT:
      return { ...state, idleTimeout: action.data }
    case CCSActions.SET_WORKSPACE_GRN:
      return { ...state, workSpaceGrn: action.data }
    case CCSActions.SET_CSRF_TOKEN:
      return { ...state, csrfToken: action.data }
    case CCSActions.SET_UPLOAD_VERSION_PERCENTAGE:
      return { ...state, uploadVersionPkgPercent: action.data }
    case CCSActions.SET_AMPLITUDE_ANALYTICS:
      return {
        ...state,
        analytics: {
          ...state?.analytics,
          [action?.data?.key]: action?.data?.value
        }
      }
    default:
      return state
  }
}

const useCCSReducer = () => {
  const [CCSState, dispatchCCSContext] = useReducer(CCSReducer, initialContext)
  return { CCSState, dispatchCCSContext }
}

export default useCCSReducer
