// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useEffect, useRef, useState, useCallback } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { FormDown, Previous, ContactInfo } from 'grommet-icons'
import { Box, PageHeader } from 'grommet'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import {
  Anchor,
  ActionButton,
  Loader,
  Typography,
  NoDataInfo,
  Button
} from '../../../../components'
import { Layout } from '../../../../commoncomponents/layout/Layout'
import Markdown from '../common-components/Markdown'
import { get, getErrorMessage, post } from '../../../../utils/api-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayNotification } from '../../../../utils/notificiation-utils'
import AssignRoleSideDrawer from '../common-components/AssignRoleSideDrawer'
import MSPAssignRoleSideDrawer from '../common-components/MSPAssignRoleSideDrawer'
import { subjectTypesEnum, glcpServiceID, userStatus } from '../constants'
import DeleteRoleAssignmentConfirmation from '../common-components/DeleteRoleAssignmentConfirmation'
import { getCustomerAccount, isMSP } from '../../../../utils/feature-flag-utils'
import { UserDetailDetailsSection } from '../../../igc-service/users/components/UserDetailDetailsSection'
import NoViewPermission from '../../../igc-service/commoncomponents/NoViewPermission'
import { getApiErrorMessage } from '../../../../utils/error-handling-utils'
import { isSAMLAuthzUser } from '../utils'
import { useVisibilityContext } from '../../../../context/visibility-context'

import RemoveUserModal from './RemoveUserModal'
import RoleAssignmentContent from './RoleAssignmentTableContent'
import MSPRoleAssignmentContent from './MSPRoleAssignmentTableContent'

const UserDetailsContent = () => {
  const { v2RbacPolicies } = useVisibilityContext()
  const v2UserAccViewAllPerm = v2RbacPolicies?.includes(
    'ccs.accounts.user.view-all'
  )
  const { t } = useTranslation(['common', 'iam'])
  const custWorkspace = getCustomerAccount()
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const { userId } = useParams()
  const location = useLocation()
  const userEmail = location?.state?.userEmail
  const [headerLoading, setHeaderLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const ref = useRef(false)
  const [userDetailsData, setUserDetailsData] = useState({})
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState(false)
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [errorNotification, setErrorNotification] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [retrievedRAData, setRetrievedRAData] = useState([])
  const [retrievedUser, setRetrievedUser] = useState({})
  const [isSAMLAuthz, setSAMLAuthz] = useState(false)

  // Assign Role Side Drawer
  const [refreshCount, setRefreshCount] = useState(0)

  // Delete
  const [showRemoveRADialog, setShowRemoveRADialog] = useState(false)
  const [retrievedRA, setRetrievedRA] = useState([])
  const [select, setSelect] = useState([])

  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const setStatusNotification = (notification) => {
    setNotificationInfo(
      notification?.message,
      notification?.severity,
      notification?.title
    )
  }

  const handleAssignRolesClick = () => {
    setNotifMsg(false)
    setShowAssignRoleModal(true)
  }

  const handleOnDeleteRABtnClick = useCallback((datum) => {
    setShowRemoveRADialog(true)
    setRetrievedRA(datum)
    setSelect(datum)
    setNotifMsg(false)
  }, [])

  const inviteEmailNotificationMsg = (
    <Typography type="text">
      <Trans>
        {t('iam:users.invitation_sent_msg_v2', {
          userEmail
        })}
      </Trans>
    </Typography>
  )

  const handleEmailInvite = () => {
    post(
      `/ui-doorway/ui/v1/um/resend-invite`,
      {
        usernames: [userEmail]
      },
      oidcUser.access_token
    ).then(
      () => {
        setStatusNotification({
          message: inviteEmailNotificationMsg,
          severity: 'info',
          testId: 'send-invite-status',
          title: t('iam:users.info_invitation_sent')
        })
      },
      (error) => {
        setNotificationInfo(getErrorMessage(error, t), 'error')
      }
    )
  }

  const actionBtns = [
    {
      label: t('common:send_invite'),
      testId: 'send-invite-btn',
      onClick: handleEmailInvite,
      visibility: { rbac: null },
      disabled: loading
    },
    {
      label: t('common:assign_role'),
      testId: 'add-to-role-assignment-btn',
      hidden: loading || isSAMLAuthz,
      visibility: {
        rbac: {
          permission: ['ccs.authorization.edit']
        }
      },
      onClick: () => {
        handleAssignRolesClick()
      }
    },
    {
      label: t('iam:igc_users.remove_from_workspace_title'),
      visibility: {
        rbac: {
          permission: [
            'ccs.accounts.platform.customer.edit',
            'ccs.authorization.edit'
          ]
        }
      },
      testId: 'remove-user-btn',
      onClick: () => {
        setShowRemoveUserDialog(true)
      }
    }
  ]

  useEffect(() => {
    ref.current = true

    const handleRoleAssignment = async (userHpePrincipal) => {
      try {
        setIsLoading(true)
        const url = isMSP()
          ? '/internal-platform-tenant-ui/v2alpha2/role-assignments'
          : '/internal-platform-tenant-ui/v2/role-assignments'
        const response = await get(`${url}?subject=${userHpePrincipal}`, {})
        if (response?.data?.role_assignments) {
          setRetrievedRAData(response?.data?.role_assignments)
        }
        setIsLoading(false)
      } catch (error) {
        setErrorNotification(
          displayNotification(
            getApiErrorMessage(error, t),
            'error',
            setErrorNotification
          )
        )
        setIsLoading(false)
      }
    }

    const getUserDetailsData = async () => {
      try {
        const userData = await get(
          `/ui-doorway/ui/v2/um/users?global_id=${userId}`,
          {},
          oidcUser.access_token
        )
        const response = userData?.data?.users[0]
        const result = {
          id: userId,
          userName: response?.email,
          displayName:
            response?.first_name &&
            response?.last_name &&
            response?.first_name?.concat(` ${response?.last_name}`),
          givenName: response?.first_name,
          familyName: response?.last_name,
          verified: response?.user_status === userStatus?.VERIFIED,
          userType: response?.user_type,
          ssoMode: response?.sso_mode,
          lastLogin: response?.last_login,
          created: response?.created_at
        }
        setRetrievedUser(result)
        setSAMLAuthz(isSAMLAuthzUser(result))

        result.workspaceId = custWorkspace?.platform_customer_id
        result.workspaceName = custWorkspace?.company_name
        setUserDetailsData(result)
        setHeaderLoading(false)
        setLoading(false)
        await handleRoleAssignment(response?.hpe_principal ?? `user:${userId}`)
      } catch (error) {
        const result = {
          id: userId,
          workspaceId: custWorkspace?.platform_customer_id,
          workspaceName: custWorkspace?.company_name
        }
        setUserDetailsData(result)
        setNotificationInfo(getErrorMessage(error, t), 'error')
        setHeaderLoading(false)
        setLoading(false)
      }
    }
    getUserDetailsData()

    return () => {
      ref.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, oidcUser.access_token, t, refreshCount])

  const roleName = retrievedRA?.role_name

  const roleDeleteConfirmationMsgs = () => {
    return (
      <>
        <Typography type="text">
          <Trans>
            {t(
              'iam:role_assignment.remove_workspace_role_assignment_confirmation_msg_v3',
              {
                roleName,
                roleAssignment: t('common:business_object.role_assignment'),
                userName:
                  userDetailsData?.displayName || userDetailsData?.userName
              }
            )}
          </Trans>
        </Typography>
        <Typography type="text" margin={{ top: 'small' }}>
          {t(
            'iam:role_assignment.remove_workspace_role_assignment_confirmation_msg_1_v2',
            {
              roleAssignment: t('common:business_object.role_assignment'),
              user: t('common:business_object.user')
            }
          )}
        </Typography>
      </>
    )
  }

  const lastRoleDeleteConfirmationMsgs = () => {
    return (
      <>
        <Typography type="text">
          <Trans>
            {t(
              'iam:role_assignment.remove_workspace_last_role_assignment_confirmation_msg_v3',
              {
                roleName,
                roleAssignment: t('common:business_object.role_assignment'),
                workspace: t('common:business_object.wkspc'),
                wkspName: userDetailsData?.workspaceName,
                userName:
                  userDetailsData?.displayName || userDetailsData?.userName
              }
            )}
          </Trans>
        </Typography>
        <Box margin={{ top: 'small' }} gap="small">
          <Typography type="text">
            {t(
              'iam:role_assignment.remove_workspace_last_role_assignment_confirmation_msg_1_v2',
              {
                roleAssignment: t('common:business_object.role_assignment'),
                user: t('common:business_object.user'),
                workspace: t('common:business_object.wkspc')
              }
            )}
          </Typography>
          <Typography type="text">
            {t(
              'iam:role_assignment.remove_workspace_last_role_assignment_confirmation_msg_2_v2',
              {
                user: t('common:business_object.user'),
                workspace: t('common:business_object.wkspc')
              }
            )}
          </Typography>
          <Typography type="text">
            {t(
              'iam:role_assignment.remove_workspace_last_role_assignment_confirmation_msg_3_v2',
              {
                roleAssignment: t('common:business_object.role_assignment'),
                user: t('common:business_object.user')
              }
            )}
          </Typography>
        </Box>
      </>
    )
  }

  const handleRARemoved = () => {
    if (retrievedRAData?.length === 1) {
      // last role assignment
      navigate('/manage-account/identity/users', {
        state: {
          notificationText: t('iam:role_assignment.removed_msg_v3', {
            roleName: retrievedRA?.role_name,
            roleAssignment: t('common:business_object.role_assignment'),
            userName: userDetailsData?.displayName || userDetailsData?.userName
          }),
          notificationTitle: t('iam:role_assignment.removed_title_v2', {
            roleAssignment: t(
              'common:business_object.role_assignment_capitalized'
            )
          })
        }
      })
    } else {
      setNotificationInfo(
        <Markdown>
          {t('iam:roles_details.remove_assignment_message', {
            roleName,
            subjectType: t('common:business_object.user'),
            subjectName:
              userDetailsData?.displayName || userDetailsData?.userName
          })}
        </Markdown>,
        'info',
        t('iam:role_assignment.removed_title_v2', {
          roleAssignment: t(
            'common:business_object.role_assignment_capitalized'
          )
        })
      )
      setRefreshCount((prevRefreshCount) => prevRefreshCount + 1)
    }
  }

  return (
    <Box
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      {errorNotification}
      <PageHeader
        title={
          headerLoading ? (
            <Loader testId="user-details-header-loader" />
          ) : (
            <Typography
              type="heading"
              level="1"
              testId="user-details-header-title"
            >
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.accounts.user.view-all'
                }}
              >
                {userDetailsData?.displayName || userDetailsData?.userName}
              </VisibilityWrapper>
            </Typography>
          )
        }
        subtitle={
          !headerLoading &&
          !isEmpty(userDetailsData?.displayName) && (
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.accounts.user.view-all'
              }}
            >
              {userDetailsData?.userName}
            </VisibilityWrapper>
          )
        }
        parent={
          <Anchor
            testId="user-details-page-back-btn"
            onClick={(event) => {
              event?.preventDefault()
              navigate('/manage-account/identity/users')
            }}
            icon={<Previous />}
            href="#"
            label={t('iam:igc_workspace_users.title')}
          />
        }
        actions={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.accounts.user.view-all'
            }}
          >
            <ActionButton
              testId="user-details-action-btn"
              reverse // Puts icon at the end instead of in front of label
              showOneActionAsDropDown
              icon={<FormDown />}
              actions={actionBtns}
              label={t('common:actions')}
              customRenderer={(actionBtn, visibility, idx) => {
                let action = actionBtn
                visibility?.rbac?.permission?.forEach((perm) => {
                  action = (
                    <VisibilityWrapper
                      key={idx}
                      rbac={{
                        permission: perm
                      }}
                    >
                      {action}
                    </VisibilityWrapper>
                  )
                })
                return action
              }}
            />
          </VisibilityWrapper>
        }
      />
      {v2UserAccViewAllPerm ? (
        <>
          <UserDetailDetailsSection
            userDetailsData={userDetailsData}
            parentLoading={loading}
            isWorkspaceUsersFlow
          />
          {/** Role assignment section */}
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.authorization.view'
            }}
          >
            <Box
              direction="row"
              justify="between"
              margin={{ vertical: 'large' }}
              fill
            >
              <Typography
                type="heading"
                level="3"
                testId="users-role-assignments-title"
              >
                {t('iam:role_assignment.title', {
                  role: t('common:business_object.role_capitalized')
                })}
              </Typography>
              {!isSAMLAuthz && retrievedRAData.length ? (
                <VisibilityWrapper
                  rbac={{
                    permission: 'ccs.authorization.edit'
                  }}
                >
                  <Button
                    testId="add-to-role-assignment-btn"
                    onClick={() => handleAssignRolesClick()}
                    label={t('iam:role_assignment.assign_role', {
                      roles: t('common:business_object.role_plural')
                    })}
                    secondary
                  />
                </VisibilityWrapper>
              ) : null}
            </Box>
            {(() => {
              if (isLoading) {
                return <Loader testId="role-assignment-loader" />
              }
              // eslint-disable-next-line no-nested-ternary
              return retrievedRAData.length ? (
                isMSP() ? (
                  <MSPRoleAssignmentContent
                    handleOnDeleteBtnClick={handleOnDeleteRABtnClick}
                    retrievedData={retrievedRAData}
                    subjectType={subjectTypesEnum.USER}
                  />
                ) : (
                  <RoleAssignmentContent
                    handleOnDeleteBtnClick={handleOnDeleteRABtnClick}
                    retrievedData={retrievedRAData}
                    subjectType={subjectTypesEnum.USER}
                  />
                )
              ) : (
                <Box margin={{ top: 'medium' }}>
                  <NoDataInfo
                    action={
                      !isSAMLAuthz && (
                        <VisibilityWrapper
                          rbac={{
                            permission: 'ccs.authorization.edit'
                          }}
                        >
                          <Button
                            testId="add-to-role-assignment-btn"
                            onClick={() => handleAssignRolesClick()}
                            label={t('iam:role_assignment.assign_role', {
                              roles: t('common:business_object.role_plural')
                            })}
                            secondary
                          />
                        </VisibilityWrapper>
                      )
                    }
                    icon={<ContactInfo size="xlarge" />}
                    subtitle={t(
                      'iam:role_assignment.get_started_assign_role_to_user',
                      {
                        role: t('common:business_object.role_plural')
                      }
                    )}
                    title={t('iam:role_assignment.no_role_assignment_title', {
                      role: t('common:business_object.role_plural')
                    })}
                    testId="no-data-info"
                  />
                </Box>
              )
            })()}
          </VisibilityWrapper>
        </>
      ) : (
        <>
          {v2UserAccViewAllPerm === false ? (
            <NoViewPermission />
          ) : (
            <Loader testId="perm-loader" />
          )}
        </>
      )}

      {showAssignRoleModal &&
        (isMSP() ? (
          <MSPAssignRoleSideDrawer
            preSelectedSubject={{
              data: retrievedUser,
              subjectType: 'USER'
            }}
            setShowAssignRoleModal={setShowAssignRoleModal}
            setStatusNotification={(notification) => {
              setNotificationInfo(
                notification?.message,
                notification?.severity,
                notification?.title
              )
            }}
            onAssign={() => {
              setRefreshCount((prevRefreshCount) => prevRefreshCount + 1)
            }}
            roleAssignments={retrievedRAData}
          />
        ) : (
          <AssignRoleSideDrawer
            preSelectedSubject={{
              data: retrievedUser,
              subjectType: 'USER'
            }}
            setShowAssignRoleModal={setShowAssignRoleModal}
            setStatusNotification={(notification) => {
              setNotificationInfo(
                notification?.message,
                notification?.severity,
                notification?.title
              )
            }}
            onAssign={() => {
              setRefreshCount((prevRefreshCount) => prevRefreshCount + 1)
            }}
            roleAssignments={retrievedRAData}
          />
        ))}
      {showRemoveUserDialog && (
        <RemoveUserModal
          onSetOpen={setShowRemoveUserDialog}
          userToRemove={userDetailsData}
          retrievedData={retrievedRAData}
          setNotificationInfo={setNotificationInfo}
        />
      )}
      {showRemoveRADialog && (
        <DeleteRoleAssignmentConfirmation
          setConfirm={setShowRemoveRADialog}
          setNotificationInfo={setNotificationInfo}
          select={select}
          retrievedRA={retrievedRA}
          onRemoved={handleRARemoved}
          title={
            retrievedRA?.service_id === glcpServiceID
              ? t('iam:role_assignment.remove_role_assignment_title_v2', {
                  roleAssignment: t('common:business_object.role_assignment')
                })
              : t(
                  'iam:role_assignment.remove_role_assignment_secondary_title_v2',
                  {
                    roleAssignment: t('common:business_object.role_assignment')
                  }
                )
          }
          subtitle={
            retrievedRAData?.length > 1
              ? roleDeleteConfirmationMsgs()
              : retrievedRAData?.length === 1 &&
                lastRoleDeleteConfirmationMsgs()
          }
        />
      )}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const UserDetailsPage = () => {
  return (
    <Layout>
      <UserDetailsContent />
    </Layout>
  )
}

export default UserDetailsPage
