// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, FormField, TextArea, TextInput } from 'grommet'
import React, { useState, useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'
import debounce from 'lodash/debounce'

import {
  ButtonGroup,
  CCSForm,
  Dropdown,
  ModalDialog,
  Typography,
  ModalHeader
} from '../../components'
import { validateFieldWithEmptySpaces } from '../../utils/validation-utils'
import {
  actionCreateFolder,
  getFolderList
} from '../../utils/manage-account-utils'

const CreateFolderModal = ({
  onSetOpen,
  refreshDataTable,
  customerId = undefined,
  isCCSManager = undefined
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')
  const [listOfFolders, setListOfFolders] = useState([])
  const [appendList, setAppendList] = useState(false)
  const [searchedText, setSearchedText] = useState('')

  // for paginating on scroll
  const itemsPerPage = 50
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((value) => {
      setSearchedText(value)
      setAppendList(false)
      setPage(0)
    }, 500),
    []
  )

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      folder_name: '',
      parent_folder_id: '',
      description: ''
    }
  )

  useEffect(() => {
    const param = {
      limit: itemsPerPage,
      page,
      ...(isCCSManager && { platform_customer_id: customerId }),
      ...(searchedText ? { search_name: searchedText } : '')
    }
    getFolderList({
      token: oidcUser.access_token,
      param,
      t,
      setErrorMessage,
      isCCSManager
    }).then((data) => {
      const foldersResponse = data?.folders
      if (foldersResponse) {
        if (appendList) {
          let modifiedResponse = [...listOfFolders, ...foldersResponse]
          modifiedResponse = uniqBy(
            orderBy(
              modifiedResponse,
              [(folder) => folder.folder_name?.toLowerCase()],
              ['asc']
            ),
            'folder_id'
          )
          setListOfFolders(modifiedResponse)
        } else {
          setListOfFolders(foldersResponse)
        }
      }
      setTotalItems(data?.pagination?.total_count || itemsPerPage)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t, customerId, isCCSManager, page, searchedText])

  const requestBody = isCCSManager
    ? { ...state, platform_customer_id: customerId }
    : state
  const createFolder = () => {
    actionCreateFolder({
      token: oidcUser.access_token,
      t,
      requestBody,
      onSetOpen,
      setErrorMessage,
      refreshDataTable,
      isCCSManager
    })
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          subtitle={t('create_new_folder_desc')}
          title={
            <Typography level="1" type="heading" testId="create-folder-title">
              {t('create_new_folder')}
            </Typography>
          }
        />
      }
      content={
        <Box>
          <CCSForm
            onSubmit={() => createFolder()}
            onReset={() => onSetOpen(false)}
            errorMessage={errorMessage}
            testId="create-folder-form"
            validate="blur"
          >
            <Box
              margin={{ top: 'medium', bottom: 'xxsmall' }}
              direction="column"
              justify="between"
              gap="small"
            >
              <FormField
                data-testid="folder-name-form-field"
                label={t('folder_name')}
                name="folder_name"
                width="medium"
                required
                validate={() =>
                  validateFieldWithEmptySpaces(
                    state.folder_name,
                    t('invalid_name_format')
                  )
                }
              >
                <TextInput
                  data-testid="folder-name-input"
                  name="folder_name"
                  placeholder={t('folder_name')}
                  value={state.folder_name}
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'folder_name',
                      type: 'CHANGE_FIELD'
                    })
                  }
                />
              </FormField>
              <FormField
                data-testid="parent-name-form-field"
                label={t('parent')}
                name="parent_folder_name"
                width="medium"
                required
              >
                <Dropdown
                  name="parent_folder_name"
                  placeholder={t('select_parent_folder')}
                  searchPlaceholder={t('search')}
                  options={listOfFolders}
                  labelKey="folder_name"
                  valueKey={{ key: 'folder_id', reduce: true }}
                  value={state.parent_folder_id ? state.parent_folder_id : ''}
                  disabled={!listOfFolders.length}
                  onSearch={(searchText) => {
                    handleDebouncedSearchValue(searchText)
                  }}
                  onChange={({ option }) => {
                    dispatch({
                      value: option.folder_id,
                      field: 'parent_folder_id',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  testId="parent-name-input"
                  onMore={() => {
                    if (
                      totalItems > itemsPerPage &&
                      page < totalItems / itemsPerPage - 1
                    ) {
                      setPage(page + 1)
                      setAppendList(true)
                    }
                  }}
                  onClose={() => {
                    if (searchedText) {
                      setListOfFolders([])
                      setAppendList(false)
                      setPage(0)
                      setSearchedText('')
                    }
                  }}
                />
              </FormField>
              <FormField
                data-testid="description-form-field"
                label={t('description')}
                name="description"
                width="medium"
              >
                <TextArea
                  name="description"
                  placeholder={t('enter_description')}
                  data-testid="description-input"
                  value={state.description}
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'description',
                      type: 'CHANGE_FIELD'
                    })
                  }
                />
              </FormField>
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    type: 'reset'
                  },
                  {
                    id: 1,
                    label: t('create'),
                    primary: true,
                    type: 'submit',
                    testId: 'create-btn'
                  }
                ]}
                testId="two-buttons"
              />
            </Box>
          </CCSForm>
        </Box>
      }
      testId="create-folder-modal-dialog"
      onClose={() => onSetOpen(false)}
      width="medium"
    />
  )
}

CreateFolderModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  isCCSManager: PropTypes.bool
}

export { CreateFolderModal }
