// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Box, CheckBox } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  FormInput,
  Loader,
  Notification,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../../components'
import Markdown from '../../../../commoncomponents/Markdown'
import { del, getErrorMessage } from '../../../../utils/api-utils'
import { isCurrentlyLoggedInWorkspace } from '../../../../utils/common-utils'

import CheckPrerequisiteModal from './CheckPrerequisiteModal'
import CheckOrgGovernanceDeletePrereqModel from './CheckOrgGovernanceDeletePrereqModel'

const RemoveAccountModal = ({
  onSetOpen,
  accountToRemove,
  orgName,
  setNotificationInfo,
  isOrgDelete,
  isRootOrgWorkspace
}) => {
  const { t } = useTranslation(['iam', 'common'])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)
  const [confirmDeleteText, setConfirmDeleteText] = useState('')
  const [checkingPrePrequisite, setCheckingPrePrequisite] = useState(true)
  const [prePrequisiteChecked, setPrePrequisiteChecked] = useState(false)
  const isCurrentWkspc = isCurrentlyLoggedInWorkspace(
    accountToRemove?.platform_customer_id
  )
  const [errorMsg, setErrorMsg] = useState('')

  const closeModal = () => {
    onSetOpen(false)
  }

  useEffect(() => {
    if (isOrgDelete && !isRootOrgWorkspace) {
      setPrePrequisiteChecked(true)
    }
  }, [isOrgDelete, isRootOrgWorkspace])

  const removeAccount = async () => {
    const confirmText = confirmDeleteText?.trim()
    if (isEmpty(confirmText)) setErrorMsg(t('common:this_is_required'))
    else if (confirmText && confirmText !== t('common:delete_keyword'))
      setErrorMsg(t('common:invalid_keyword'))
    else {
      setLoading(true)
      const sendEmailQuery = sendEmail ? `?send-email=${sendEmail}` : ''
      try {
        await del(
          `/organizations/v2alpha1/workspaces/${encodeURIComponent(
            accountToRemove?.platform_customer_id
          )}${sendEmailQuery}`
        )
        if (isOrgDelete && !isCurrentWkspc) {
          navigate('/manage-account/organization/workspaces', {
            state: {
              notificationText: t('iam:delete_workspace.success_toast_v2', {
                workspace: t('common:business_object.wkspc'),
                workspaceName: accountToRemove?.company_name
              })
            }
          })
        } else {
          navigate('/sign-out')
        }
      } catch (error) {
        if (
          error?.response?.data?.httpStatusCode === 409 &&
          error?.response?.data?.errorCode === 'HPE_GL_ERROR_CONFLICT'
        ) {
          setNotificationInfo(
            t('iam:delete_workspace.org_wkspc_delete_error_msg', {
              workspace: t('common:business_object.wkspc'),
              workspaceName: accountToRemove?.company_name,
              devices: t('common:business_object.device_plural'),
              deviceSubs: t(
                'common:business_object.device_subscription_plural'
              ),
              serviceSubs: t(
                'common:business_object.service_subscription_plural'
              ),
              services: t('common:business_object.service_plural')
            }),
            'error'
          )
        } else {
          setNotificationInfo(getErrorMessage(error, t), 'error')
        }
      } finally {
        closeModal()
        setLoading(false)
      }
    }
  }

  const getOrgGovernanceDeleteDescription = (account) => {
    if (!isCurrentWkspc) {
      return t(
        'iam:delete_workspace.description_organization_not_current_wkspc_v2',
        {
          workspaceName: account?.company_name,
          workspace: t('common:business_object.wkspc'),
          orgName
        }
      )
    }
    return t('iam:delete_workspace.description_organization', {
      workspaceName: account?.company_name,
      workspace: t('common:business_object.wkspc'),
      orgName
    })
  }

  const getModalContent = (account) => {
    let content
    if (loading) {
      content = (
        <Box pad={{ vertical: 'small' }} justify="center" align="center" fill>
          <Loader
            testId="remove-account-loader"
            label={t('common:removing')}
            orientation="horizontal"
          />
        </Box>
      )
    } else {
      content = (
        <Box width="medium" margin={{ top: 'medium' }}>
          <Typography type="text" data-testid="delete-workspace-description">
            <Trans>
              {isEmpty(orgName)
                ? t('iam:delete_workspace.description_v2', {
                    workspaceName: account?.company_name,
                    workspace: t('common:business_object.wkspc')
                  })
                : getOrgGovernanceDeleteDescription(account)}
            </Trans>
          </Typography>
          {isOrgDelete && isRootOrgWorkspace && (
            <Notification
              margin={{ top: 'small' }}
              type="inline"
              text={
                <Markdown>
                  {t(
                    'iam:delete_workspace.delete_last_wkspce_in_organization',
                    {
                      workspace: t('common:business_object.wkspc'),
                      orgName
                    }
                  )}
                </Markdown>
              }
              status="critical"
              testId="delete-root-wkspc-warning"
            />
          )}
          <CCSForm
            testId="delete-account-modal-form"
            errorMessage=""
            buttons={
              <Box
                direction="row"
                justify="end"
                gap="medium"
                margin={{ top: 'medium' }}
              >
                <Button
                  label={t('common:cancel')}
                  onClick={closeModal}
                  disabled={loading}
                  testId="cancel-btn"
                />
                <Button
                  label={t('iam:delete_workspace.title', {
                    workspace: t('common:business_object.wkspc')
                  })}
                  primary
                  onClick={removeAccount}
                  testId="delete-account-btn"
                  disabled={loading}
                />
              </Box>
            }
          >
            <>
              <Box margin={{ vertical: 'medium' }}>
                <CheckBox
                  pad="none"
                  data-testid="notify-delete-checkbox"
                  checked={sendEmail}
                  disabled={loading}
                  label={t('iam:delete_workspace.send_notify', {
                    workspace: t('common:business_object.wkspc')
                  })}
                  onChange={(event) => {
                    setSendEmail(event?.target?.checked)
                  }}
                />
              </Box>
              <FormInput
                required
                name="inputText"
                inputType="text"
                label={t('common:confirmation')}
                labelHelper={
                  <Typography type="text" size="xsmall">
                    <Trans size="xsmall">
                      {isOrgDelete && isRootOrgWorkspace
                        ? t('iam:delete_workspace:root_wkspc_type_confirm_v2', {
                            workspace: t('common:business_object.wkspc'),
                            workspaceName: account?.company_name,
                            orgName,
                            deleteKeyword: t('common:delete_keyword')
                          })
                        : t('iam:delete_workspace:type_confirm_v2', {
                            workspace: t('common:business_object.wkspc'),
                            workspaceName: account?.company_name,
                            deleteKeyword: t('common:delete_keyword')
                          })}
                    </Trans>
                  </Typography>
                }
                testId="delete-account"
                error={errorMsg}
                value={confirmDeleteText}
                onChange={(event) => {
                  setConfirmDeleteText(event.target.value)
                  setErrorMsg('')
                }}
              />
            </>
          </CCSForm>
        </Box>
      )
    }
    return content
  }

  return (
    <>
      {checkingPrePrequisite && !isOrgDelete && (
        <CheckPrerequisiteModal
          onValidateSuccess={() => setPrePrequisiteChecked(true)}
          onValidateFail={() => {
            setCheckingPrePrequisite(false)
            closeModal()
          }}
          workspaceName={accountToRemove?.company_name}
          orgName={orgName}
          setNotificationInfo={setNotificationInfo}
        />
      )}
      {checkingPrePrequisite && isOrgDelete && isRootOrgWorkspace && (
        <CheckOrgGovernanceDeletePrereqModel
          onValidateSuccess={() => setPrePrequisiteChecked(true)}
          onValidateFail={() => {
            setCheckingPrePrequisite(false)
            closeModal()
          }}
          workspaceName={accountToRemove?.company_name}
          orgName={orgName}
          setNotificationInfo={setNotificationInfo}
        />
      )}
      {prePrequisiteChecked && (
        <ModalDialog
          header={
            <ModalHeader
              title={
                <Typography
                  type="heading"
                  level="2"
                  testId="delete-wkspc-heading"
                >
                  {t('iam:delete_workspace.title', {
                    workspace: t('common:business_object.wkspc')
                  })}
                </Typography>
              }
            />
          }
          content={getModalContent(accountToRemove)}
          onClose={closeModal}
          onClickOutside={closeModal}
          testId="remove-account-modal"
        />
      )}
    </>
  )
}

RemoveAccountModal.propTypes = {
  /**
   * Callback function to close modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * Account to remove
   */
  accountToRemove: PropTypes.object.isRequired,

  /**
   * The name of organization associated with the workspace
   */
  orgName: PropTypes.string,

  /**
   * set notification info
   */
  setNotificationInfo: PropTypes.func.isRequired,

  /**
   * This boolean property used to if this DELETE action is org.
   */
  isOrgDelete: PropTypes.bool,

  /**
   * This boolean property used to signal if the workspace is the Root Workspace of the Organization.
   */
  isRootOrgWorkspace: PropTypes.bool
}

RemoveAccountModal.defaultProps = {
  orgName: '',
  isOrgDelete: false,
  isRootOrgWorkspace: false
}
export default RemoveAccountModal
