// Copyright 2025 Hewlett Packard Enterprise Development LP
import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CheckBox, Form, FormField, Header } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import {
  Notification,
  ButtonGroup,
  Typography,
  Anchor,
  ModalDialog
} from '../../../../components'
import LazyLoading from '../../../../commoncomponents/LazyLoading/LazyLoading'
import { CaseCreationContext } from '../context/CaseCreationContext'
import { submitCase } from '../utils/FormUtil'

import ServiceElement from './ServiceElement'
import Contact from './Contact'
import CaseFormElements from './CaseFormElements'

const CaseForm = ({ caseCreationCallback = () => {} }) => {
  const { t } = useTranslation(['support_cases'])
  const custAccountLoaded = getCustomerAccount()
  const workspaceName = custAccountLoaded?.company_name || ''
  const workspaceId = custAccountLoaded?.platform_customer_id || ''
  const [service, setService] = useState({})
  const [busy, setBusy] = useState(false)
  const [showCaseFormElements, setShowCaseFormElements] = useState(false)
  const [showButtonPanel, setShowButtonPanel] = useState(false)
  const [errorInfo, setErrorInfo] = useState({})
  const [renderContact, setRenderContact] = useState(false)
  const [showConfirmCheckBox, setShowConfirmCheckBox] = useState(false)
  const [isDisableCaseForm, setIsDisableCaseForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [urlParams, setUrlParams] = useState({})
  const [showInlineSubmitError, setShowInlineSubmitError] = useState(false)
  const caseFormContextData = useContext(CaseCreationContext)
  const { oidcUser } = useReactOidc()
  const productInfo = useRef({})
  const {
    loggedInUserContact,
    selectedPrimaryContact,
    setSelectedPrimaryContact,
    selectedAlternateContact,
    setSelectedAlternateContact
  } = caseFormContextData

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    setUrlParams(params)
    setShowButtonPanel(true)
  }, [])

  const submitCaseForm = useCallback(
    async (formData) => {
      const calloutResponse = await submitCase(
        oidcUser.access_token,
        formData,
        selectedPrimaryContact,
        selectedAlternateContact,
        caseFormContextData,
        productInfo,
        workspaceId
      )
      if (calloutResponse?.status === 201 && calloutResponse?.data) {
        caseCreationCallback({
          data: {
            caseNumber: calloutResponse.data.externalCaseNumber,
            externalCaseId: calloutResponse.data.externalCaseId,
            workspaceId,
            productNamespace: productInfo?.current?.namespace
              ? productInfo.current.namespace
              : ''
          }
        })
      } else if (!showInlineSubmitError) {
        // failure use-case: when the submit failed on the first try.
        setShowInlineSubmitError(true)
        setIsDisableCaseForm(false)
      } else {
        // failure use-case: when the submit failed on the second time(re-try).
        caseCreationCallback({
          error: {
            title: 'support_cases:create_case.submit_case_full_page_error',
            message: 'support_cases:common.try_again'
          }
        })
      }
      setBusy(false)
    },
    [
      caseCreationCallback,
      selectedPrimaryContact,
      selectedAlternateContact,
      caseFormContextData,
      oidcUser,
      showInlineSubmitError,
      workspaceId
    ]
  )

  const onSubmit = useCallback(
    ({ value }) => {
      setBusy(true)
      if (value) {
        setIsDisableCaseForm(true)
        submitCaseForm(value)
      }
    },
    [submitCaseForm]
  )

  const onReset = useCallback(() => {
    setService({})
    setErrorInfo({})
    setShowCaseFormElements(false)
    setRenderContact(false)
    setShowConfirmCheckBox(false)
    setSelectedPrimaryContact(loggedInUserContact)
    setSelectedAlternateContact({})
    setShowInlineSubmitError(false)
    productInfo.current = {}
  }, [
    loggedInUserContact,
    setSelectedPrimaryContact,
    setSelectedAlternateContact
  ])

  const renderContactAndConfirmCheckBox = useCallback((data) => {
    if ('renderContact' in data) {
      if (data.renderContact) {
        setRenderContact(data.renderContact)
      } else {
        setShowConfirmCheckBox(false)
        setRenderContact(false)
        setShowInlineSubmitError(false)
      }
    }
    if ('showConfirmCheckbox' in data) {
      setShowConfirmCheckBox(data.showConfirmCheckbox)
    }
  }, [])

  const handleCallbackError = useCallback((error) => {
    setErrorInfo(error)
    if (!error.showFormElements) {
      setShowCaseFormElements(error.showFormElements)
    }
  }, [])

  const handleCallback = useCallback(
    ({ error, data }) => {
      if (error) {
        handleCallbackError(error)
      } else if (data) {
        // Check if errorInfo is not empty
        if (Object.keys(errorInfo).length !== 0) {
          setErrorInfo({})
        }
        if (data.selectedService) {
          if (urlParams?.service?.toUpperCase() !== data.selectedService.slug) {
            setUrlParams({})
            window.history.replaceState({}, '', window.location.pathname)
          }
          setService(data.selectedService)
          setShowButtonPanel(data.selectedService.type !== 'Link')
          setShowCaseFormElements(true)
          setIsLoading(data.selectedService.type !== 'Link')
        }
        if (data.renderSubField) {
          setIsLoading(false)
        }
        if ('toggleButtonPanel' in data) {
          setShowButtonPanel(data.toggleButtonPanel)
        }
        if (data.productInfo) {
          productInfo.current = {
            ...productInfo.current,
            ...data.productInfo
          }
        }
        renderContactAndConfirmCheckBox(data)
      }
    },
    [
      errorInfo,
      handleCallbackError,
      renderContactAndConfirmCheckBox,
      urlParams?.service
    ]
  )

  return (
    <Box
      gap="medium"
      margin={{ horizontal: 'auto' }}
      pad={{ horizontal: 'auto' }}
    >
      <Header pad={{ top: 'large' }}>
        <Typography level="1" type="heading" testId="case-form-heading">
          {t('support_cases:common.hpe_greenlake_support')}
        </Typography>
      </Header>

      <Notification
        status="info"
        type="inline"
        testId="status-workspace-info-notification"
        text={
          <>
            {t('support_cases:create_case.workspace_notification', {
              workspace: workspaceName
            })}{' '}
            <Anchor
              href="/switch-account"
              label={t('support_cases:create_case.change_workspace')}
              testId="anchor-workspace-info-notification"
            />
          </>
        }
      />
      <Form
        messages={{
          required: t('support_cases:common.this_is_required_field')
        }}
        onSubmit={onSubmit}
        onReset={onReset}
      >
        <Box margin={{ bottom: 'xlarge' }}>
          <ServiceElement
            service={service}
            urlParams={urlParams}
            caseFormCallBack={handleCallback}
          />
          {showCaseFormElements && (
            <CaseFormElements
              serviceId={service.id}
              caseFormCallBack={handleCallback}
            />
          )}
          {isLoading && <LazyLoading />}
          {errorInfo?.showError && (
            <Box margin={{ top: 'xsmall' }}>
              <Notification
                icon={<CircleInformation />}
                status="critical"
                text={errorInfo.message}
                type="inline"
                testId="case-form-error-notification"
              />
            </Box>
          )}
          {renderContact && (
            <Contact
              showContactPreference={service.show_contact_preferences}
              caseFormCallBack={handleCallback}
            />
          )}
          {showConfirmCheckBox && (
            <Box margin={{ top: 'large' }}>
              <FormField name="case-submit-confirm-check" required>
                <CheckBox
                  pad="none"
                  id="case-submit-confirm-check"
                  data-testid="case-submit-confirm-check"
                  name="case-submit-confirm-check"
                  label={t(
                    'support_cases:create_case.case_submit_confirm_check'
                  )}
                />
              </FormField>
            </Box>
          )}
          {showInlineSubmitError && (
            <Box gap="small" pad={{ vertical: 'medium' }}>
              <Notification
                status="critical"
                type="inline"
                icon={<CircleInformation height="medium" />}
                testId="submit-api-error-notification"
                text={
                  <Typography
                    weight="normal"
                    size="medium"
                    type="text"
                    data-testid="submit-api-error-notification-msg"
                  >
                    {t('support_cases:create_case.submit_case_inline_error')}
                  </Typography>
                }
              />
            </Box>
          )}
          {showButtonPanel && (
            <Box
              direction="row"
              gap="small"
              margin={{
                top: showConfirmCheckBox ? 'small' : 'large'
              }}
            >
              <ButtonGroup
                buttonList={[
                  {
                    label: t('support_cases:common.submit'),
                    primary: true,
                    disabled: errorInfo?.showError || isLoading,
                    testId: 'case-form-submit',
                    type: 'submit',
                    busy
                  },
                  {
                    label: t('support_cases:common.clear'),
                    testId: 'case-form-reset',
                    type: 'reset',
                    disabled: isLoading
                  }
                ]}
                testId="case-form-button-group"
              />
            </Box>
          )}
        </Box>
      </Form>
      {isDisableCaseForm && <ModalDialog plain="false" />}
    </Box>
  )
}

CaseForm.propTypes = {
  caseCreationCallback: PropTypes.func
}

export default CaseForm
