// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { post, getErrorMessage } from '../../../../../utils/api-utils'

export const validateReviewCreateForm = async ({
  formValues,
  token,
  t,
  platformCustomerId,
  selectedRole,
  customRoleFeatureFlag
}) => {
  const {
    roleDetails: { name, description }
  } = formValues

  if (
    !selectedRole.resourcePolicies ||
    selectedRole.resourcePolicies.length === 0
  ) {
    return Promise.reject(new Error('Please add permissions for the role'))
  }

  const requestBody = {
    name: name ? name.trim() : '',
    ...(customRoleFeatureFlag
      ? {
          tags: {
            tac: true
          }
        }
      : {}),
    description: description ? description.trim() : '',
    resource_policies: selectedRole.resourcePolicies
  }

  if (!platformCustomerId) return null

  const url = `/authorization/ui/v1/customers/${platformCustomerId}/applications/${
    selectedRole?.application_id
  }/roles${customRoleFeatureFlag ? '?tag_rules=tac' : ''}`

  try {
    const resp = await post(url, requestBody, token)
    if (resp && resp.status === 200) {
      return Promise.resolve({ message: 'success' })
    }
  } catch (err) {
    console.error(err)
    // show error message below
    const backendErrorMessage = getErrorMessage(err, t)
    return Promise.reject(new Error(backendErrorMessage))
  }
  return null
}
