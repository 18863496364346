// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, SelectMultiple, FileInput, Select } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import isEmpty from 'lodash/isEmpty'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import axios from 'axios'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import {
  FormInput,
  Notification,
  ModalDialog,
  Typography,
  ButtonGroup,
  ToggleButton,
  FormTextArea
} from '../../../../../../../../components'
import { validateForm } from '../../../../../../../../utils/validation-utils'
import { get, getErrorMessage } from '../../../../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { validateNewDetails, validateSelectedFile } from './utils'
import ReferenceLink from './ReferenceLink'

export const validateNewDetailsPost = (formValues, i18nTranslate, LDFlags) => {
  const errorObj = validateNewDetails(formValues, i18nTranslate, LDFlags)
  if (!isEmpty(errorObj)) {
    return Promise.reject(
      new Error(
        i18nTranslate('whats_new.create_post_form.missing_required_fields')
      )
    )
  }
  if (formValues.uploading) {
    return Promise.reject(
      new Error(
        i18nTranslate('whats_new.create_post_form.file_uploading_message')
      )
    )
  }

  return validateForm(formValues, i18nTranslate)
}

const NewDetails = () => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [awsFilePath, setAwsFilePath] = useState('')

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [formError, setFormError] = useState({})

  const [apiErrorMessage, setApiErrorMessage] = useState(null)
  const [services, setServices] = useState([])

  const [listOfServices, setListOfServices] = useState([])
  const [publicationLocation, setPublicationLocation] = useState([])
  const [uploading, setUploading] = useState(false)
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const [numFiles, setNumFiles] = useState(
    formValues?.selectedFile?.length || 0
  )
  const [summaryFlag, setSummaryFlag] = useState(
    formValues?.summaryFlag || false
  )
  const LDFlags = useFlags()

  const shortDescMaxLength = 2000
  const titleMaxLength = 100
  const summaryMaxLength = 440

  useEffect(() => {
    get(
      '/whatsnew/v1/blog/service/category',
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data) {
          setListOfServices(response.data.items)
          setServices(response.data.items)
        }
      },
      (error) => {
        setApiErrorMessage(getErrorMessage(error, t))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  useEffect(() => {
    get(
      `/whatsnew/v1/blog/service/category?q=publicationLocation`,
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data) {
          setPublicationLocation(response.data.items)
        }
      },
      (error) => {
        setApiErrorMessage(getErrorMessage(error, t))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  const onDelete = (onConfirm) => {
    if (formValues?.media) {
      get(
        `/whatsnew/v1/blog/medialink?op=delete&path=${formValues?.media}`,
        {},
        oidcUser.access_token,
        false,
        {},
        {
          'X-Account-ID': platformCustomerId
        }
      ).then(
        (response) => {
          if (response?.status === 204) {
            setNumFiles(0)
            setFormValues({
              ...formValues,
              fileName: '',
              selectedFile: [],
              media: '',
              mediaType: ''
            })
            setAwsFilePath('')
            onConfirm()
          }
        },
        (error) => {
          setApiErrorMessage(getErrorMessage(error, t))
        }
      )
    } else {
      setNumFiles(0)
      setFormValues({
        ...formValues,
        fileName: '',
        selectedFile: [],
        media: ''
      })
      setFormError((formError.uploadFile = []))
      onConfirm()
    }
  }

  const handleCancel = (onConfirm) => {
    if (!isEmpty(formValues?.selectedFile)) {
      onDelete(onConfirm)
    }
  }

  const uploadMediaToAWS = (operation, filePath, file) => {
    get(
      `/whatsnew/v1/blog/medialink?op=${operation}&path=${filePath}`,
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data) {
          const url = response?.data?.signedURL
          axios
            .put(url, file, {
              headers: {
                'Content-Type': file.type
              }
            })
            .then(
              (awsResponse) => {
                if (awsResponse.status === 200) {
                  setAwsFilePath(response.data?.path)
                  setFormValues((currentFormValues) => ({
                    ...currentFormValues,
                    media: response.data?.path,
                    fileName: file.name,
                    mediaType: file.type,
                    selectedFile: [file],
                    uploading: false
                  }))
                }
                setUploading(false)
              },
              (error) => {
                setApiErrorMessage(getErrorMessage(error, t))
                setUploading(false)
                setFormValues((currentFormValues) => ({
                  ...currentFormValues,
                  uploading: false
                }))
              }
            )
        }
      },
      (error) => {
        setApiErrorMessage(getErrorMessage(error, t))
      }
    )
  }

  useEffect(() => {
    if (attemptedAdvance) {
      const errorObj = validateNewDetails(formValues, t, LDFlags)
      setFormError(errorObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t])

  const handleFileUpload = (files) => {
    const errorObj = {}
    setFormError(errorObj)
    const errorFile = validateSelectedFile(files[0], t)
    if (errorFile) {
      errorObj.uploadFile = errorFile
      setFormError(errorObj)
    }

    if (isEmpty(errorObj)) {
      if (files.length > 0) {
        setNumFiles(files.length)
        setFormValues({
          ...formValues,
          fileName: files[0].name,
          selectedFile: files,
          uploading: true
        })
        setUploading(true)
        if (awsFilePath === '') {
          uploadMediaToAWS('post', files[0].name, files[0])
        } else {
          const filePath = awsFilePath.substring(
            0,
            awsFilePath.lastIndexOf('/') + 1
          )
          uploadMediaToAWS('replace', filePath + files[0].name, files[0])
        }
      }
    }
  }

  return (
    <Box justify="between" align="start" margin={{ bottom: 'medium' }}>
      <Box direction="column" gap="small" width="100%">
        <Box width="100%">
          <FormInput
            testId="post-title"
            label={t('whats_new.create_post_form.title_field_label')}
            inputType="text"
            value={formValues.title || ''}
            placeholder={t('whats_new.create_post_form.title_placeholder')}
            error={formError.title}
            maxLength={titleMaxLength}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                title: event.target.value
              })
            }}
            required
          />
          <Box alignSelf="end">
            <Typography type="text" size="xsmall" color="text-weak">
              {formValues.title.length}/{titleMaxLength}
            </Typography>
          </Box>
        </Box>

        <FormField
          width="100%"
          label={t('whats_new.create_post_form.service_catalog')}
          name="serviceCategory"
          data-testid="service-category"
          error={formError.serviceCategory}
        >
          <SelectMultiple
            data-testid="service-category-field"
            value={formValues.serviceCategory}
            placeholder={t('whats_new.create_post_form.service_placeholder')}
            options={services}
            onSearch={(searchText) => {
              const regexp = new RegExp(searchText, 'i')
              setServices(listOfServices.filter((o) => o.match(regexp)))
            }}
            onClose={() => setServices(listOfServices)}
            onChange={({ value }) => {
              setFormValues({
                ...formValues,
                serviceCategory: value
              })
            }}
          />
        </FormField>
        <>
          <FormTextArea
            width="100%"
            testId="short-description-input"
            label={t('whats_new.create_post_form.full_description')}
            inputType="text"
            name="shortDescription"
            rows="10"
            maxLength={shortDescMaxLength}
            error={formError.shortDescription}
            value={formValues.shortDescription || ''}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                shortDescription: event.target.value
              })
            }}
            required
          />
          <Box alignSelf="end">
            <Typography
              type="text"
              testId="short-description-count"
              size="xsmall"
              color="text-weak"
            >
              {formValues.shortDescription.length}/{shortDescMaxLength}
            </Typography>
          </Box>
        </>
        <FormField
          label={t('whats_new.create_post_form.upload_media')}
          help={
            <Box direction="column">
              <Typography type="text" testId="upload-media-desc" size="xsmall">
                {t('whats_new.create_post_form.upload_media_desc')}
              </Typography>
              <Typography type="text" testId="upload-media-help" size="xsmall">
                {t('whats_new.create_post_form.upload_media_help')}
              </Typography>
            </Box>
          }
          name="upload-file"
          htmlFor="upload-file"
          error={formError.uploadFile}
          data-testid="upload-file-title"
        >
          <FileInput
            data-testid="upload-file-input"
            multiple={false}
            disabled={uploading}
            value={formValues.selectedFile}
            onChange={(event, { files }) => {
              if (event?.target?.files && event?.target?.files?.length) {
                setNumFiles(files.length)
                setFormValues({
                  ...formValues,
                  fileName: files[0].name,
                  selectedFile: files
                })
                handleFileUpload(files)
              }
            }}
            confirmRemove={({ onConfirm, onCancel }) => (
              <>
                {formValues?.media ? (
                  <ModalDialog
                    testId="delete-file-modal"
                    onClose={() => {
                      onCancel()
                    }}
                    header={
                      <Typography
                        type="heading"
                        testId="delete-file-title"
                        level="1"
                      >
                        {t('whats_new.create_post_form.remove_file')}
                      </Typography>
                    }
                    content={
                      <Box margin={{ vertical: 'medium' }}>
                        <Typography
                          type="text"
                          size="large"
                          testId="delete-file-desc"
                        >
                          {t('whats_new.create_post_form.remove_file_desc')}
                        </Typography>
                      </Box>
                    }
                    footer={
                      <ButtonGroup
                        buttonList={[
                          {
                            id: 2,
                            label: t('whats_new.cancel_btn'),
                            secondary: true,
                            testId: 'cancel-delete-file-btn',
                            onClick: onCancel,
                            type: 'reset'
                          },
                          {
                            id: 1,
                            label: t(
                              'whats_new.create_post_form.remove_file_btn'
                            ),
                            primary: true,
                            type: 'submit',
                            onClick: () => {
                              onDelete(onConfirm)
                            },
                            testId: 'delete-file-btn'
                          }
                        ]}
                        testId="two-buttons"
                      />
                    }
                    position="center"
                    width="medium"
                  />
                ) : (
                  handleCancel(onConfirm)
                )}
              </>
            )}
            messages={{
              browse:
                numFiles > 0
                  ? t('whats_new.create_post_form.replace_file')
                  : t('whats_new.create_post_form.select_file'),
              dropPrompt: t('whats_new.create_post_form.drag_drop')
            }}
          />
        </FormField>
        {uploading && (
          <Typography type="text" size="xsmall" testId="uploading-media-label">
            {t('whats_new.create_post_form.file_uploading')}
          </Typography>
        )}
        <FormField
          width="100%"
          label={t('whats_new.publication_location')}
          name="publicationLocation"
          data-testid="publication-location-title"
          help={
            <Typography
              type="text"
              testId="publication-location-desc"
              size="xsmall"
            >
              {t('whats_new.publication_desc')}
            </Typography>
          }
        >
          <Select
            data-testid="publication-location-field"
            value={formValues.publicationLocation || ''}
            placeholder={t('whats_new.create_post_form.service_placeholder')}
            options={publicationLocation}
            clear
            onChange={({ value }) => {
              setFormValues({
                ...formValues,
                publicationLocation: value
              })
            }}
            dropAlign={{ top: 'top', left: 'left' }}
          />
        </FormField>
        <Box>
          <Typography type="heading" testId="reference-link-title" level="4">
            {t('whats_new.create_post_form.reference_link_title')}
          </Typography>
          <Typography type="text" testId="upload-media-desc" size="xsmall">
            {t('whats_new.create_post_form.reference_link_desc')}
          </Typography>

          <ReferenceLink
            formValues={formValues}
            setFormValues={setFormValues}
            formError={formError}
          />

          <VisibilityWrapper hideFor={{ feature: 'glcp-whatsnew-istanbul' }}>
            <Box direction="row" align="center">
              <ToggleButton
                reverse
                checked={summaryFlag}
                onChange={(event) => {
                  setSummaryFlag(event.target.checked)
                  setFormValues({
                    ...formValues,
                    summaryFlag: event.target.checked
                  })
                }}
                testId="announcement-toggle-btn"
              />
              <Typography type="text" testId="summary-toggle-btn-label">
                {t('whats_new.create_post_form.summary_toggle_btn')}
              </Typography>
            </Box>
            {summaryFlag && (
              <>
                <FormTextArea
                  width="100%"
                  testId="summary-input"
                  label={t('whats_new.create_post_form.summary_title')}
                  inputType="text"
                  name="summary"
                  rows="3"
                  maxLength={summaryMaxLength}
                  error={formError.summary}
                  value={formValues.summary || ''}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      summary: event.target.value
                    })
                  }}
                  required
                />
                <Box alignSelf="end">
                  <Typography
                    type="text"
                    testId="summary-count"
                    size="xsmall"
                    color="text-weak"
                  >
                    {formValues.summary?.length || 0}/{summaryMaxLength}
                  </Typography>
                </Box>
              </>
            )}
          </VisibilityWrapper>
        </Box>
      </Box>

      {apiErrorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setApiErrorMessage(null)}
          testId="critical-inline-notification"
          text={apiErrorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

export default NewDetails
