// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { Box, Page, PageContent } from 'grommet'
import { StatusGood, Previous } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, Notification, Typography } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { ProgressModal } from '../../../../commoncomponents/device-management'

import { AddFirmwareModal, DataTable } from './components'

const FirmWarePage = () => {
  const LDFlags = useFlags()
  const mvSvsToSaHomeLD = LDFlags['glcp-sa-mv-svcs']
  const [refreshCount, setRefreshCount] = useState(0)
  const [showAddFirmwareModal, setAddFirmwareModal] = useState(false)
  const [showAddFirmwareNotification, setAddFirmwareNotification] =
    useState(false)
  const { t } = useTranslation(['manage'])
  const [showProgressModal, setProgressModal] = useState(false)
  const navigate = useNavigate()

  const refreshDataTable = () => {
    setRefreshCount(refreshCount + 1)
  }
  const handleBackBtnClick = () => {
    navigate('/manage-ccs/home')
  }
  const renderContent = () => (
    <Box gap="medium" pad="xsmall" width="xxlarge">
      <Box direction="row" justify="between">
        <Box direction="column">
          {mvSvsToSaHomeLD && (
            <Box direction="row" justify="start">
              <Button
                default
                label={t('common:nav_bar.home')}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                testId="manage-account-btn"
              />
            </Box>
          )}

          <Box>
            <Typography
              level="1"
              type="heading"
              weight="normal"
              testId="firmware-page-title"
            >
              {t('firmware.title')}
            </Typography>
          </Box>
          <Box margin={{ top: 'xsmall' }}>
            <Typography
              type="paragraph"
              size="xlarge"
              weight="400"
              testId="firmware-page-subtitle"
            >
              {t('firmware.subTitle')}
            </Typography>
          </Box>
        </Box>
        <Box margin={{ top: 'large' }}>
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/activate/firmware',
              permission: 'ccs.activate.firmware.upload'
            }}
          >
            <Button
              label={t('firmware.add_firmware')}
              testId="add-firmware"
              primary
              onClick={() => {
                setAddFirmwareModal(true)
              }}
            />
          </VisibilityWrapper>
        </Box>
      </Box>
      <DataTable refreshCount={refreshCount} />
      {showAddFirmwareModal && (
        <AddFirmwareModal
          setAddFirmwareModal={setAddFirmwareModal}
          refreshDataTable={refreshDataTable}
          setAddFirmwareNotification={setAddFirmwareNotification}
          setProgressModal={setProgressModal}
        />
      )}
      {showAddFirmwareNotification && (
        <Notification
          icon={<StatusGood color="text-strong" />}
          onClose={() => setAddFirmwareNotification(false)}
          testId="status-good-notification"
          text={t('firmware.add_success_msg')}
        />
      )}
      {showProgressModal && (
        <ProgressModal
          onSetOpen={setProgressModal}
          title={t('firmware.firmware_added')}
          subtitle={t('firmware.firmware_msg')}
          onCloseModal={() => {
            setProgressModal(false)
          }}
          hideAuditLogBtn
        />
      )}
    </Box>
  )
  return mvSvsToSaHomeLD ? (
    <Page>
      <PageContent>{renderContent()}</PageContent>
    </Page>
  ) : (
    renderContent()
  )
}

export default FirmWarePage
