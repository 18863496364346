// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'
import { ShareRounded } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Typography,
  DataTable,
  AvatarInfo,
  Loader
} from '../../components'
import { get } from '../../utils/api-utils'
import { getCustomerAccount } from '../../utils/feature-flag-utils'
import { displayApiError } from '../../utils/error-handling-utils'

const AssignedUsers = ({ role, showSearch = false, inCCSManager = false }) => {
  const { t } = useTranslation(['authz'])
  const itemsPerPage = 10
  const [usersList, setUsersList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [showNotification, setShowNotification] = useState(null)
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
  const saIAMRolesLDFlag = LDFlags['glcp-london-sa-support-roles-flag']

  const custAccountLoaded = getCustomerAccount()
  const platformCid = custAccountLoaded?.platform_customer_id || ''

  const { oidcUser } = useReactOidc()
  const { slug, application_id: appId } = role || {}

  const getUserFullName = (user) => {
    const firstName = user?.first_name
    const lastName = user?.last_name
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || user?.user_name
  }
  useEffect(() => {
    const url =
      inCCSManager && saIAMRolesLDFlag
        ? `/support-assistant/v1alpha1/customers/${platformCid}/applications/${appId}/roles/${slug}/user-assignments`
        : `/authorization/ui/v2/customers/${platformCid}/applications/${appId}/roles/${slug}/user_assignments`
    if (slug && appId && platformCid) {
      get(
        url,
        {
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage,
          user_id: debouncedSearchValue
        },
        oidcUser.access_token
      )
        .then((response) => {
          const users = response?.data?.users
          users.forEach((user) => {
            user.user_full_name = getUserFullName(user)
          })
          setUsersList(users)
          setTotalCount(
            response?.data?.total_users ||
              response.data?.pagination?.total_count
          )
          setLoading(false)
          setShowNotification(null)
        })
        .catch((error) => {
          setShowNotification(displayApiError(error, t))
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearchValue])

  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('name'),
      render: (datum) => (
        <Box direction="column" gap="xsmall">
          <AvatarInfo
            avatarChar="one"
            primaryInfo={datum?.user_full_name}
            secondaryInfo={datum?.email || datum?.user_id}
            testId="user-info-avatar-only"
          />
        </Box>
      )
    },
    ...(inCCSManager && saCustomRoleLDFlag
      ? []
      : [
          {
            property: '',
            header: '',
            render: (datum) => (
              <Button
                testId="view-assigned-user-details-button"
                alignSelf="end"
                aria-label={`${t('view_details')}, ${datum.user_full_name}`}
                icon={<ShareRounded />}
                onClick={() => {
                  navigate(`/manage-account/identity/users/${datum.user_id}`)
                }}
              />
            )
          }
        ])
  ]

  return (
    <>
      {showNotification}
      <Box
        gap="medium"
        direction="row"
        justify="between"
        pad={{ bottom: 'medium' }}
        margin={{ bottom: 'small', top: 'large' }}
        border={showSearch ? { side: 'bottom', color: 'border-weak' } : null}
      >
        <Typography
          level="2"
          type="heading"
          testId="dynamic-view-edit-form-title"
        >
          <>
            {t('roles.assigned_users')} ({totalCount ?? usersList.length})
          </>
        </Typography>
      </Box>
      <Box
        direction="column"
        justify="center"
        gap="small"
        pad={{ bottom: 'medium' }}
        margin={{ bottom: 'small' }}
      >
        {loading ? (
          <Loader testId="assigned-user-loader" />
        ) : (
          <DataTable
            pagination={{
              totalItems: totalCount,
              itemsPerPage,
              page,
              setPage,
              numberMiddlePages: 1
            }}
            grid={{
              columns,
              data: usersList,
              pad: { body: { horizontal: 'xsmall', vertical: 'small' } }
            }}
            summary={{
              entityName: t('users_title')
            }}
            search={{
              onSearchValueChange: (value) => {
                setDebouncedSearchValue(value.trim())
                setPage(1)
              },
              placeholder: t('search_by_user_name'),
              width: 'medium'
            }}
            testId="user-assignments-table"
          />
        )}
      </Box>
    </>
  )
}

AssignedUsers.propTypes = {
  role: PropTypes.object.isRequired,
  showSearch: PropTypes.bool,
  inCCSManager: PropTypes.bool
}

export default AssignedUsers
