// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useCallback, useEffect, useState } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'
import { isEmpty, cloneDeep } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Loader, Wizard } from '../../../components'
import { AUTHZActions, useAUTHZContext } from '../../../context/authz-context'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'

import ReviewCreate from './wizard-steps/step3/ReviewCreate'
import AddPermissions from './wizard-steps/step2/AddPermissions'
import RoleDetails from './wizard-steps/step1/RoleDetails'
import { validateReviewCreateForm } from './wizard-steps/step3/utils'
import { validateAddPermissionsForm } from './wizard-steps/step2/utils'
import {
  validateRoleDetailsForm,
  defaultRoleDetails
} from './wizard-steps/step1/utils'

const CreateRolesWizard = ({ inCCSManager = false }) => {
  const navigate = useNavigate()
  const { applicationId, slug } = useParams()
  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
  const { dispatchAUTHZContext, roleAppMap } = useAUTHZContext()
  const { t } = useTranslation(['authz', 'common'])
  const { oidcUser } = useReactOidc()
  const [role, setRole] = useState({
    application_id: applicationId,
    application_name: roleAppMap?.get(applicationId)?.provAppName
  })
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(true)

  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''

  const getRole = useCallback(() => {
    setLoading(true)
    get(
      `/ui-doorway/ui/v1/um/customers/roles/${slug}`,
      { application_id: applicationId },
      oidcUser.access_token
    ).then(
      (response) => {
        const { data } = response
        setRole({
          ...data,
          resourcePolicies: cloneDeep(data?.resource_policies),
          application_id: applicationId,
          duplicateFlag: true
        })
        const permissionsSelected = []
        const res = data?.resource_policies
        if (!isEmpty(res)) {
          for (let j = 0; j < res.length; j += 1) {
            for (let i = 0; i < res[j].permissions.length; i += 1) {
              permissionsSelected.push(
                `${res[j].resource.matcher}~${res[j].permissions[i].slug}`
              )
            }
          }
        }
        dispatchAUTHZContext({
          type: AUTHZActions.PERMISSIONS_SELECTED,
          data: permissionsSelected
        })
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, applicationId, slug])

  const getApplication = useCallback(() => {
    setLoading(true)
    const url =
      inCCSManager && saCustomRoleLDFlag
        ? `/support-assistant/v1alpha1/customer-provisions`
        : `/ui-doorway/ui/v1/applications/provisions`

    get(
      url,
      {
        provision_status: 'PROVISIONED',
        application_id: applicationId,
        ...(inCCSManager && saCustomRoleLDFlag
          ? { platform_customer_id: platformCustomerId }
          : null)
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (!isEmpty(response?.data?.provisions)) {
          const data = response?.data?.provisions[0]
          setRole({
            application_name: data?.name,
            application_logo: data?.logo,
            application_id: applicationId,
            duplicateFlag: false
          })
        }
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, applicationId])

  useEffect(() => {
    if (applicationId && slug) {
      getRole()
    } else if (applicationId) {
      getApplication()
    }
  }, [applicationId, slug, getRole, getApplication])

  return loading ? (
    <Box align="center" justify="center" pad={{ top: 'large' }}>
      <Loader
        label={t('loading')}
        orientation="horizontal"
        testId="rrp-details-loader"
      />
    </Box>
  ) : (
    <>
      {showNotification !== null && showNotification}
      <Wizard
        title={t('roles.create_role')}
        formDefaultValues={{
          ...defaultRoleDetails
        }}
        actionOnExit={() =>
          navigate(
            `/${
              inCCSManager && saCustomRoleLDFlag
                ? 'manage-ccs'
                : 'manage-account'
            }/identity/roles`
          )
        }
        actionOnSubmit={(formValues) => {
          navigate(
            `/${
              inCCSManager && saCustomRoleLDFlag
                ? 'manage-ccs'
                : 'manage-account'
            }/identity/roles`,
            {
              state: {
                notificationTitle: t('roles.role_create_notification_title'),
                notificationMessage: t('roles.role_create_notification_msg', {
                  roleName: formValues?.roleDetails?.name
                })
              }
            }
          )
        }}
        cancelStrings={{
          continueLabel: t('rrp.wiz_continue'),
          exitLabel: t('rrp.wiz_exit'),
          heading: t('rrp.wiz_exit_warning'),
          text: t('roles.wiz_leave_modal')
        }}
        steps={[
          {
            childComponents: <RoleDetails selectedAppRole={role} />,
            description: t('roles.wiz_step1_desc'),
            title: t('roles.wiz_step1_title'),
            validateForm: (formValues) => validateRoleDetailsForm(formValues)
          },
          {
            childComponents: (
              <AddPermissions
                selectedRole={role}
                setRole={setRole}
                inCCSManager={inCCSManager}
              />
            ),
            description: t('roles.wiz_step2_desc'),
            title: t('roles.wiz_step2_title', {
              appName: role?.application_name
            }),
            validateForm: () => validateAddPermissionsForm(role, t)
          },
          {
            childComponents: <ReviewCreate selectedRole={role} />,
            description: t('roles.wiz_step3_desc'),
            title: t('rrp.wiz_step4_title'),
            validateForm: (formValues) =>
              validateReviewCreateForm({
                formValues,
                token: oidcUser.access_token,
                t,
                platformCustomerId,
                selectedRole: role,
                customRoleFeatureFlag: saCustomRoleLDFlag && inCCSManager
              })
          }
        ]}
        testId="create-role-wizard"
      />
    </>
  )
}

CreateRolesWizard.propTypes = {
  inCCSManager: PropTypes.bool
}

export default CreateRolesWizard
