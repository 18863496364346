// Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { CaseCreationContext } from '../context/CaseCreationContext'
import LazyLoading from '../../../../commoncomponents/LazyLoading/LazyLoading'
import { Typography } from '../../../../components'

import ContactElements from './ContactElements'

const Contact = ({ showContactPreference, caseFormCallBack = () => {} }) => {
  const [contactsLoading, setContactsLoading] = useState(true)
  const { t } = useTranslation(['support_cases'])
  const CaseFormContextData = useContext(CaseCreationContext)
  const { contacts } = CaseFormContextData
  const handleContactLoading = useCallback((data) => {
    setContactsLoading(data)
  }, [])
  return (
    <>
      {contactsLoading && <LazyLoading />}
      {!contactsLoading && (
        <Typography
          level="2"
          type="heading"
          margin={{ bottom: 'medium', top: 'large' }}
        >
          {t('support_cases:create_case.final_details_msg')}
        </Typography>
      )}
      <ContactElements
        contactConfig={{
          contactTitle: t('support_cases:contact.primary_contact'),
          toolTipRequired: false,
          contactType: 'Primary',
          contactPreferenceVisible: showContactPreference
        }}
        caseFormCallBack={caseFormCallBack}
        showContactLoading={handleContactLoading}
      />
      {contacts.length > 1 && (
        <Box margin={{ top: 'medium' }}>
          <ContactElements
            contactConfig={{
              contactTitle: t('support_cases:contact.alternate_contact'),
              toolTipRequired: true,
              contactType: 'Alternate',
              contactPreferenceVisible: showContactPreference
            }}
            caseFormCallBack={caseFormCallBack}
          />
        </Box>
      )}
    </>
  )
}
Contact.propTypes = {
  showContactPreference: PropTypes.bool.isRequired,
  caseFormCallBack: PropTypes.func
}

export default Contact
