// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Tag, Video, Image, Layer, Anchor, Markdown } from 'grommet'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { FormClose, Expand, Contract } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, Button } from '../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  WHATS_NEW_ALLOWED_IMAGE_TYPES,
  WHATS_NEW_ALLOWED_VIDEO_TYPE
} from '../../../utils/validation-utils'
import { convertUTCToLocalTime } from '../../ccs-manager/pages/whats-new/utils'

const PostDetail = ({ item, currentVideoPlaying = null }) => {
  const { t } = useTranslation(['common'])
  const [enlargedMedia, setEnlargedMedia] = useState(false)
  const awsFilePath = item?.media || ''
  const contentType = item?.mediaType || ''

  const videoRef = useRef()
  const LDFlags = useFlags()

  useEffect(() => {
    const videoElement = videoRef.current
    const videoPlayHandler = (event) => {
      if (
        currentVideoPlaying?.current &&
        currentVideoPlaying?.current?.id !== event.target.id
      ) {
        currentVideoPlaying.current.pause()
      }
      currentVideoPlaying.current = videoElement
    }
    if (WHATS_NEW_ALLOWED_VIDEO_TYPE.includes(contentType) && videoElement) {
      videoElement?.addEventListener('play', videoPlayHandler)
    }
  })

  const MediaComponent = () => {
    if (WHATS_NEW_ALLOWED_IMAGE_TYPES.includes(contentType)) {
      return (
        <Box
          hoverIndicator={{
            elevation: 'medium'
          }}
          onClick={() => setEnlargedMedia(!enlargedMedia)}
        >
          <Image
            fit="contain"
            src={awsFilePath}
            data-testid={`image-${item.blogID}`}
          />
        </Box>
      )
    }
    if (WHATS_NEW_ALLOWED_VIDEO_TYPE.includes(contentType)) {
      return (
        <Video
          ref={videoRef}
          id={`video-${item.blogID}`}
          data-testid={`video-${item.blogID}`}
          fit="contain"
          src={awsFilePath}
          controls={{
            items: [
              {
                icon: enlargedMedia ? <Contract /> : <Expand />,
                onClick: () => setEnlargedMedia(!enlargedMedia)
              },
              'volume'
            ]
          }}
        />
      )
    }
    return <></>
  }

  const RenderMedia = () => {
    return (
      <>
        {enlargedMedia ? (
          <Layer
            modal
            position="center"
            responsive
            background="black"
            margin="medium"
            data-testid={`enlarged-media-${item.blogID}`}
            onEsc={() => setEnlargedMedia(false)}
          >
            <Box width="auto" height="auto">
              <Box direction="row-reverse" pad="xsmall" width="100%">
                <Button
                  icon={<FormClose color="white" />}
                  onClick={() => setEnlargedMedia(false)}
                  plain
                  data-testid="close-button"
                />
              </Box>
              <Box
                data-testid={`media-${item.blogID}`}
                alignSelf="center"
                width="xxlarge"
                margin={{ horizontal: 'medium', bottom: 'medium' }}
              >
                <MediaComponent />
              </Box>
            </Box>
          </Layer>
        ) : (
          <MediaComponent />
        )}
      </>
    )
  }

  return (
    <VisibilityWrapper
      hideFor={{ feature: 'glcp-whats-new', deployment: ['COP'] }}
    >
      <Box pad="small" gap="small" key={item.blogID}>
        <Typography
          color="text-weak"
          size="small"
          type="text"
          testId={`whatsnew-customer-datetime-${item.blogID}`}
        >
          {convertUTCToLocalTime(item?.publishDate)}
        </Typography>
        <Box>
          <Typography
            level="2"
            type="heading"
            testId={`whatsnew-customer-title-${item.blogID}`}
          >
            {item?.title}
          </Typography>
        </Box>
        <Box>
          <Typography
            type="text"
            size="medium"
            testId={`whatsnew-short-description-${item.blogID}`}
          >
            {LDFlags['glcp-whats-new-rich-text'] ? (
              <Markdown>{item?.shortDescription}</Markdown>
            ) : (
              item?.shortDescription
            )}
          </Typography>
        </Box>
        <Box data-testid={`media-box-${item.blogID}`}>
          <RenderMedia />
        </Box>
        {!isEmpty(item.referenceLinks) && (
          <Box width="auto" direction="column">
            <Typography
              type="heading"
              testId={`reference-link-title-${item.blogID}`}
              level="3"
            >
              {t('whats_new.reference_link_title')}
            </Typography>
            <Box
              direction="column"
              data-testid={`reference-links-${item.blogID}`}
            >
              {item.referenceLinks.map((element, index) => (
                <Anchor
                  label={element?.label}
                  href={element?.link}
                  target="_blank"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                />
              ))}
            </Box>
          </Box>
        )}
        {item.serviceCategory?.length ? (
          <Box
            width="auto"
            direction="row-responsive"
            pad={{ vertical: 'small' }}
            gap="small"
          >
            {item.serviceCategory.map((serviceCategory) => (
              <Tag
                name={serviceCategory}
                size="small"
                data-testid={`service-category-${serviceCategory}-${item.blogID}`}
              />
            ))}
          </Box>
        ) : (
          <></>
        )}
        <Box border="bottom" pad="small" />
      </Box>
    </VisibilityWrapper>
  )
}

PostDetail.propTypes = {
  item: PropTypes.object.isRequired,
  currentVideoPlaying: PropTypes.object
}

export { PostDetail }
