// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box, Tag, Button } from 'grommet'
import { useTranslation } from 'react-i18next'

import { getDaysBetweenDates } from '../utils/date-utils'

const BreadcrumbManager = ({ controller }) => {
  const [state, setState] = useState(controller.state)
  const { t } = useTranslation('support_hub_search')
  const dateFormat = 'YYYY/MM/DD@HH:mm:ss'

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => setState(controller.state))
    return () => unsubscribe()
  }, [controller])

  // Displays nothing if state does not have breadcrumbs
  if (!state?.hasBreadcrumbs) {
    return null
  }

  // Method for formatting date facet values
  const formatValue = (dateStr) => {
    return `Last ${getDaysBetweenDates(dateStr)} days`
  }

  // Function to format localized dates
  const getLocalizedDateLabel = (startValue) => {
    const daysBetween = getDaysBetweenDates(startValue, dateFormat)
    return t('last_no_days', { days: daysBetween })
  }

  // Facet value translation function
  const getTranslatedValue = (value) => {
    // Try to translate the value using multiple translation keys
    return (
      t(`contenttype.${value}`, '') || // Check contenttype translations
      t(`kmdoctypedetails.${value}`, '') || // Check kmdoctypedetails translations
      t(`kmtargetl5namelist.${value}`, value) // Check kmtargetl5namelist translations
    )
  }

  const allBreadcrumbs = [
    ...state.facetBreadcrumbs.flatMap((facet) =>
      facet.values.map((breadcrumb) => {
        const translatedValue = getTranslatedValue(breadcrumb.value.value)
        return {
          key: breadcrumb.value.value,
          value: translatedValue,
          deselect: breadcrumb.deselect,
          field: facet.field
        }
      })
    ),
    ...state.dateFacetBreadcrumbs.flatMap((facet) =>
      facet.values.map((breadcrumb) => {
        const key = formatValue(breadcrumb.value.start)
        const localizedLabel = getLocalizedDateLabel(breadcrumb.value.start)

        return {
          key,
          value: localizedLabel,
          deselect: breadcrumb.deselect,
          field: facet.field
        }
      })
    )
  ]

  // Extracts the number of days from the string and converts it to a number
  const getDaysFromString = (value) => {
    const match = value.match(/\d+/)
    return match ? parseInt(match[0], 10) : 0
  }

  // Compares breadcrumbs/filter values for sorting
  const compareBreadcrumbs = (a, b) => {
    // Checks if both values belong to the Last Updated facet and sorts them by days in ascending order
    if (a.field === 'hpescuniversaldate' && b.field === 'hpescuniversaldate') {
      const daysA = getDaysFromString(a.value)
      const daysB = getDaysFromString(b.value)
      return daysA - daysB
    }
    // Default alphabetical sorting for other values
    return a.value.localeCompare(b.value)
  }

  allBreadcrumbs.sort(compareBreadcrumbs)
  const sortedBreadcrumbs = allBreadcrumbs

  // The UX team requested that we modify Tag components
  const StyledTag = styled(Tag)`
    padding: xxsmall xsmall small;
    padding-right: xsmall;
    border-radius: large;
    border: 1px solid var(--light-border-weak, rgba(0, 0, 0, 0.12));
    background: var(--Light-Background, #fff);

    span {
      color: var(--Light-Text, #555); /* Text color */
      font-size: medium; /* Font size */
      font-style: normal; /* Font style */
      font-weight: 600; /* Font weight */
      line-height: large; /* Line height */
    }
  `
  return (
    <Box data-testid="breadcrumb-manager" gap="xxsmall">
      <Box alignSelf="start" gap="xxsmall">
        <Box
          direction="row"
          wrap
          gap="small"
          alignContent="start"
          justifyContent="start"
        >
          {sortedBreadcrumbs.map((breadcrumb) => (
            <StyledTag
              width="fit-content"
              key={breadcrumb.key}
              value={breadcrumb.value}
              onRemove={breadcrumb.deselect}
              margin={{ vertical: 'xsmall' }}
              data-testid="breadcrumb-filter"
            />
          ))}
          <Box margin={{ top: 'xsmall' }}>
            <Button
              data-testid="clear-all-filters-btn"
              label={t('clear_all')}
              onClick={() => controller.deselectAll()}
              style={{
                order: 1,
                width: 'fit-content',
                padding: 'xsmall small',
                fontSize: 'medium',
                lineHeight: 'medium',
                color: 'var(--Light-Text, #555)'
              }} // Position at the end Clear all button
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

BreadcrumbManager.propTypes = {
  controller: PropTypes.object.isRequired
}

export default BreadcrumbManager
