// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { React } from 'react'
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'
import { AUTHZContextProvider } from '../../context/authz-context'

import HomePage from './home'
import GetStartedRouter from './get-started/router'
import { VideosTutorials } from './get-started-videos-tutorials'

const HomeRouterContent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route path="/get-started" element={<GetStartedRouter />} />
      <Route path="/videos-tutorials" element={<VideosTutorials />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

const HomeRouter = () => {
  return (
    <AUTHZContextProvider>
      <HomeRouterContent />
    </AUTHZContextProvider>
  )
}

export default HomeRouter
