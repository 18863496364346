// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Loader, MultiSelectBox } from '../../../../components'
import { get } from '../../../../utils/api-utils'
// import { debounceSearch } from '../../../igc-service/commoncomponents/utils'
import { WKSPC_GROUPS_LIMIT } from '../constants'

const WorkspaceGroupMultiselect = ({
  setSelectedWorkspaceGroups = () => {},
  wkspcGroupScopeError = {},
  setApiError = () => {}
}) => {
  const { t } = useTranslation(['common', 'iam'])

  const limit = 200
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(true)
  const [workspaceGroupOptions, setWorkspaceGroupOptions] = useState([])
  const [totalWorkspaceGroup, setTotalWorkspaceGroup] = useState(0)
  // const [searchValue, setSearchValue] = useState('')

  const getWorkspaceGroups = () => {
    // const searchValueTrimmed = searchValue?.trim()
    // const searchQuery =
    //   searchValue?.trim()?.length > 0
    //     ? `?filter=${encodeURIComponent(searchValueTrimmed)}`
    //     : ''
    get(`/tenants-management/v1alpha1/groups`, {
      limit,
      offset
    }).then(
      (response) => {
        setTotalWorkspaceGroup(response?.data?.total)
        const newWorkspaceGroups = []

        // Format data to display in multiselect component
        response?.data?.items?.forEach((wg) => {
          newWorkspaceGroups?.push({
            label: wg?.displayName,
            value: wg
          })
        })

        setWorkspaceGroupOptions([
          ...workspaceGroupOptions,
          ...newWorkspaceGroups
        ])
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setApiError(error, t)
      }
    )
  }

  useEffect(() => {
    getWorkspaceGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  return loading ? (
    <Box align="center" margin={{ top: 'small', bottom: 'small' }}>
      <Loader testId="wkspc-group-dropdown-loader" />
    </Box>
  ) : (
    <FormField
      label={t('common:business_object.wkspc_group_plural_capitalized')}
      help={t('iam:msp.select_workspace_groups_desc', {
        workspaces: t('common:business_object.wkspc_plural'),
        role: t('common:business_object.role')
      })}
      error={wkspcGroupScopeError?.wkspcGroupInstanceError}
      data-testid="workspace-group-dropdown"
    >
      <MultiSelectBox
        options={workspaceGroupOptions}
        labelKey="label"
        valueKey="value"
        placeholder={t('common:select')}
        limit={WKSPC_GROUPS_LIMIT}
        total={totalWorkspaceGroup}
        onChange={(selectedWkspcGroups) => {
          wkspcGroupScopeError?.clearError()
          const wkspcGroups = selectedWkspcGroups?.map((wg) => wg?.value)
          setSelectedWorkspaceGroups(wkspcGroups)
        }}
        onMore={() => {
          if (offset < totalWorkspaceGroup) {
            setOffset((prev) => prev + limit)
          }
        }}
        // onSearch={(searchText) => {
        //   setOffset(0)
        //   setWorkspaceGroupOptions([])
        //   debounceSearch(searchText, setSearchValue, setOffset)
        // }}
        // searchPlaceholder={t('common:search')}
        // emptySearchMessage={t('common:search_no_matches')}
        dropHeight="medium"
        disabled={false}
        testId="assign-workspace-groups-to-user-multiselect"
      />
    </FormField>
  )
}

WorkspaceGroupMultiselect.propTypes = {
  /**
   * Set selected workspace groups
   */
  setSelectedWorkspaceGroups: PropTypes.func.isRequired,

  /**
   * Validation error if no workspace group instance selected
   */
  wkspcGroupScopeError: PropTypes.object,

  /**
   * Set api error
   */
  setApiError: PropTypes.func.isRequired
}

export default WorkspaceGroupMultiselect
