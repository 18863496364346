// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Form,
  Box,
  Button,
  TextArea,
  CheckBox,
  Text,
  Notification,
  Anchor,
  Paragraph,
  StarRating
} from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { StatusGood, CircleAlert, FormPrevious } from 'grommet-icons'
import styled from 'styled-components'

import { NoDataInfo, Typography } from '../../components'
import { sendFormData } from '../support-feedback/utils'

import { ChatActions, useChatContext } from './context/chat-context'

const StyledBox = styled(Box)`
  background-color: ${(props) =>
    props.feedbackLength > 2000 || props.feedbackErrorFlag
      ? `${props.theme.global.colors['status-critical'].light}1f`
      : `${props.theme.global.colors['background-front'].light}`};
`
const StyledRatingContainer = styled.div`
  [role='radiogroup'] {
    outline: none;
    border: none;
    width: 180px;
    margin: 0px;
    &:focus-visible {
      outline: none;
      border: none;
    }

    label {
      outline: none;
      border: none;
      padding: 6px;
      margin: 0px;
      justify-content: center;
      min-width: 24px;
      pointer-events: none;
      background-color: transparent;

      &:focus-visible {
        outline: none;
        border: none;
      }

      div {
        outline: none;
        border: none;
        padding: 0px;

        &:focus-visible {
          outline: none;
          border: none;
        }
      }
    }

    input {
      outline: none;
      border: none;

      &:focus-visible {
        outline: none;
        border: none;
      }
    }

    svg {
      height: 20px;
      width: 20px;
      outline: none;
      border: none;
      pointer-events: auto;
      fill: none;
      stroke-width: 2;
      &:focus {
        stroke-width: 2;
        filter: drop-shadow(0px 0px 1px #00e8cf);
      }

      path {
        height: 20px;
        width: 20px;
        outline: none;
        border: none;
        &:hover {
          stroke-width: 2;
          background-color: transparent;
          fill: rgba(1, 169, 130, 1);
          stroke: rgba(1, 169, 130, 1);
        }
      }
    }

    &[data-selected='1'] label:nth-of-type(-n + 1) svg,
    &[data-selected='2'] label:nth-of-type(-n + 2) svg,
    &[data-selected='3'] label:nth-of-type(-n + 3) svg,
    &[data-selected='4'] label:nth-of-type(-n + 4) svg,
    &[data-selected='5'] label:nth-of-type(-n + 5) svg {
      stroke-width: 2;
      fill: rgba(1, 169, 130, 1);
      stroke: rgba(1, 169, 130, 1);
    }
    &[data-selected='1'] label:nth-of-type(-n + 1) svg:hover,
    &[data-selected='2'] label:nth-of-type(-n + 2) svg:hover,
    &[data-selected='3'] label:nth-of-type(-n + 3) svg:hover,
    &[data-selected='4'] label:nth-of-type(-n + 4) svg:hover,
    &[data-selected='5'] label:nth-of-type(-n + 5) svg:hover {
      stroke-width: 2;
      background-color: transparent;
      fill: rgba(0, 133, 103, 1);
      stroke: rgba(0, 133, 103, 1);
    }

    &[data-selected='1'] label:nth-of-type(-n + 1) path,
    &[data-selected='2'] label:nth-of-type(-n + 2) path,
    &[data-selected='3'] label:nth-of-type(-n + 3) path,
    &[data-selected='4'] label:nth-of-type(-n + 4) path,
    &[data-selected='5'] label:nth-of-type(-n + 5) path {
      stroke-width: 2;
      fill: rgba(1, 169, 130, 1);
      stroke: rgba(1, 169, 130, 1);
    }
    &[data-selected='1'] label:nth-of-type(-n + 1) path:hover,
    &[data-selected='2'] label:nth-of-type(-n + 2) path:hover,
    &[data-selected='3'] label:nth-of-type(-n + 3) path:hover,
    &[data-selected='4'] label:nth-of-type(-n + 4) path:hover,
    &[data-selected='5'] label:nth-of-type(-n + 5) path:hover {
      stroke-width: 2;
      background-color: transparent;
      fill: rgba(0, 133, 103, 1);
      stroke: rgba(0, 133, 103, 1);
    }
  }
`

const StyledErrorRatingContainer = styled.div`
  [role='radiogroup'] {
    outline: none;
    border: none;
    width: 180px;
    margin: 0px;

    &:focus-visible {
      outline: none;
      border: none;
    }

    label {
      outline: none;
      padding: 6px;
      border: none;
      margin: 0px;
      justify-content: center;
      min-width: 24px;
      pointer-events: none;
      background-color: transparent;

      &:focus-visible {
        outline: none;
        border: none;
      }

      div {
        outline: none;
        border: none;
        padding: 0px;

        &:focus-visible {
          outline: none;
          border: none;
        }
      }
    }

    input {
      outline: none;
      border: none;

      &:focus-visible {
        outline: none;
        border: none;
      }
    }

    svg {
      height: 20px;
      width: 20px;
      outline: none;
      border: none;
      pointer-events: auto;
      &:focus {
        stroke-width: 2;
        filter: drop-shadow(0px 0px 1px #00e8cf);
      }
      stroke-width: 2;
      stroke: rgba(0, 0, 0, 0.72);
    }

    path {
      stroke-width: 2;
      fill: rgba(254, 217, 217, 1);
      stroke: rgba(0, 0, 0, 0.72);
    }
  }
`
const CustomScrollBox = styled(Box)`
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 4px;
  ${({ isFirefox }) => isFirefox && { 'scrollbar-width': 'thin' }};

  ::-webkit-scrollbar {
    width: 8px;
    height: 116px;
    color: #cccccc;
  }
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 90px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`
const FeedbackForm = () => {
  const { i18n } = useTranslation(['support_hub_feedback'])
  const { oidcUser } = useReactOidc()
  const idempotentKey = useMemo(() => uuidv4(), [])
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  )
  const skipSubmit = searchParams.get('skipSubmit')

  const [isLoading, setIsLoading] = useState(false)
  const [fails, setFails] = useState(0)

  const { feedbackFormData, dispatchChatContext, setLastActiveAsFeedback } =
    useChatContext()
  const {
    rating,
    feedback,
    consent,
    isSubmitted,
    isErrorNotification,
    isErrorScreen,
    ratingError,
    feedbackError
  } = feedbackFormData

  const validateFeedback = (text) => /^[a-zA-Z0-9\s.,?!'"-]*$/.test(text)
  const count = feedback.length
  const CHAR_MAX_LIMIT = 2000
  const overLimit = count - CHAR_MAX_LIMIT

  const sendData = async (data) => {
    setIsLoading(true)
    const formData = new FormData()
    const metadata = {
      skipSubmit: skipSubmit !== 'false',
      idempotentKey,
      topic: data.topic,
      rating: data.rating,
      details: data.details ? data.details : ' ',
      contact: data.contact ? 1 : 2,
      url: window.location.href,
      userLanguage: i18n.language
    }
    formData.append('metadata', JSON.stringify(metadata))

    return sendFormData(formData, oidcUser?.access_token)
  }

  const submitForm = (data) => {
    if (isLoading) {
      return
    }
    sendData(data)
      .then(() => {
        dispatchChatContext({
          type: ChatActions.UPDATE_FEEDBACK_FORM,
          payload: { ...feedbackFormData, isSubmitted: true }
        })
      })
      .catch(() => {
        const nextFails = fails + 1
        setFails(nextFails)
        if (nextFails === 1) {
          dispatchChatContext({
            type: ChatActions.UPDATE_FEEDBACK_FORM,
            payload: {
              ...feedbackFormData,
              isErrorNotification: true
            }
          })
        }
        if (nextFails === 2) {
          dispatchChatContext({
            type: ChatActions.UPDATE_FEEDBACK_FORM,
            payload: {
              ...feedbackFormData,
              isErrorScreen: true
            }
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  const submitFeedback = () => {
    let isValid = true

    if (!rating) {
      isValid = false
      dispatchChatContext({
        type: ChatActions.UPDATE_FEEDBACK_FORM,
        payload: {
          ...feedbackFormData,
          ratingError: true
        }
      })
    }

    if (feedback && !validateFeedback(feedback)) {
      isValid = false
      dispatchChatContext({
        type: ChatActions.UPDATE_FEEDBACK_FORM,
        payload: {
          ...feedbackFormData,
          feedbackError: true
        }
      })
    }

    if (isValid) {
      submitForm({ topic: 3, rating, details: feedback, contact: consent })
    }
  }

  const navigate = useNavigate()

  const supportLinkClickHandler = (e) => {
    e.preventDefault()
    navigate('/support')
    window.open(e.target.href, '_blank')
  }

  const onClearChatBot = async () => {
    sessionStorage.removeItem('chatInputMessage')
    dispatchChatContext({ type: ChatActions.CLEAR_STATE })
    dispatchChatContext({
      type: ChatActions.OPEN_CHAT,
      payload: { open: false }
    })
    setLastActiveAsFeedback(false)
  }

  const handleRatingChange = (value) => {
    dispatchChatContext({
      type: ChatActions.UPDATE_FEEDBACK_FORM,
      payload: { ...feedbackFormData, rating: value }
    })
  }

  const handleFeedbackChange = (value) => {
    dispatchChatContext({
      type: ChatActions.UPDATE_FEEDBACK_FORM,
      payload: { ...feedbackFormData, feedback: value }
    })
    if (feedbackError && validateFeedback(value)) {
      dispatchChatContext({
        type: ChatActions.UPDATE_FEEDBACK_FORM,
        payload: { ...feedbackFormData, feedbackError: false }
      })
    }
  }

  const handleConsentChange = (value) => {
    dispatchChatContext({
      type: ChatActions.UPDATE_FEEDBACK_FORM,
      payload: { ...feedbackFormData, consent: value }
    })
  }
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  return (
    <CustomScrollBox align="center" width="full" isFirefox={isFirefox}>
      {!isSubmitted && !isErrorScreen && (
        <>
          <Box
            display="flex"
            direction="column"
            justify="center"
            align="center"
            width={{ min: 'medium', max: 'full' }}
            height={{ min: '595px', max: 'full' }}
            pad={{ left: 'medium', right: 'medium', bottom: 'medium' }}
          >
            <Box
              align="start"
              direction="row"
              gap="medium"
              width={{ min: '0px', max: 'full' }}
              height="xxsmall"
              pad={{ top: 'small' }}
              style={{ width: '-webkit-fill-available' }}
            >
              <Button
                width="168px"
                height="24px"
                label="Back to conversation"
                icon={
                  <FormPrevious
                    color="brand"
                    data-testid="chatbot-feedback-back-conversation"
                  />
                }
                onClick={() => {
                  dispatchChatContext({
                    type: ChatActions.UPDATE_FEEDBACK_FORM,
                    payload: { ...feedbackFormData, showFeedbackForm: false }
                  })

                  dispatchChatContext({
                    type: ChatActions.REMOVE_FEEDBACK_REQUEST
                  })
                }}
                style={{
                  fontSize: '18px',
                  fontWeight: '400',
                  paddingLeft: 0
                }}
              />
            </Box>
            <Box
              direction="column"
              justify="center"
              align="center"
              gap="medium"
              height="450px"
              alignSelf="stretch"
              flex="1 0 0"
            >
              <Form
                style={{ outline: 'none', border: 'none', maxHeight: 'full' }}
                onSubmit={(event) => {
                  event.preventDefault()
                  submitFeedback()
                }}
              >
                <Box
                  style={{ outline: 'none', border: 'none' }}
                  data-testid="chatbot-feedback-title"
                >
                  <Text
                    style={{
                      height: '40px',
                      fontSize: '20px',
                      lineHeight: '20px',
                      fontWeight: '500',
                      color: 'black'
                    }}
                  >
                    Before you go, how satisfied are you with my ability to
                    assist you today?
                  </Text>
                </Box>
                <Box
                  direction="row"
                  justify="center"
                  gap="small"
                  margin={{ top: 'medium', bottom: 'medium' }}
                  style={{
                    height: '36px',
                    outline: 'none',
                    border: 'none'
                  }}
                >
                  {ratingError && !rating ? (
                    <Box alignSelf="center" style={{ height: '56px' }}>
                      <StyledErrorRatingContainer
                        style={{ height: '36px', width: '180px' }}
                      >
                        <StarRating
                          name="rating"
                          data-testid="chatbot-feedback-rating"
                          direction="row"
                          value={rating}
                          onChange={(event) =>
                            handleRatingChange(parseInt(event.target.value, 10))
                          }
                          style={{ height: '36px' }}
                        />
                      </StyledErrorRatingContainer>
                      <Box>
                        <Typography
                          data-testid="chatbot-feedback-rating-error"
                          type="text"
                          size="small"
                          color="text"
                          alignContent="center"
                          style={{
                            lineHeight: '16px',
                            whiteSpace: 'nowrap',
                            fontSize: '14px',
                            fontWeight: '400',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 'xsmall'
                          }}
                          margin={{ top: 'xsmall' }}
                        >
                          <CircleAlert size="12px" />
                          This is a required field.
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <StyledRatingContainer
                      style={{
                        outline: 'none',
                        border: 'none',
                        width: '180px'
                      }}
                    >
                      <StarRating
                        name="rating"
                        data-selected={rating}
                        data-testid="chatbot-feedback-rating"
                        direction="row"
                        value={rating}
                        onChange={(event) =>
                          handleRatingChange(parseInt(event.target.value, 10))
                        }
                        style={{ height: '36px' }}
                      />
                    </StyledRatingContainer>
                  )}
                </Box>

                <StyledBox
                  feedbackLength={feedback.length}
                  feedbackErrorFlag={feedbackError}
                  style={{
                    minHeight: '60px',
                    maxHeight: '80px',
                    maxWidth: 'full',
                    alignSelf: 'stretch',
                    flex: '1 0 0',
                    color: 'text-xweak'
                  }}
                >
                  <TextArea
                    data-testid="chatbot-feedback-textarea"
                    direction="row"
                    align="initial"
                    pad={{
                      right: 'small',
                      left: 'small',
                      top: 'xsmall',
                      bottom: 'xsmall'
                    }}
                    placeholder="Provide additional feedback (optional)"
                    value={feedback}
                    onChange={(event) =>
                      handleFeedbackChange(event.target.value)
                    }
                    style={{
                      minHeight: '60px',
                      maxHeight: '80px',
                      maxWidth: 'full',
                      resize: 'vertical',
                      fontSize: '18px',
                      fontWeight: '400',
                      color: 'text-xweak'
                    }}
                  />
                </StyledBox>

                {count > 1600 && (
                  <Typography
                    type="text"
                    size="small"
                    color="text-weak"
                    style={{
                      lineHeight: '16px',
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 'xsmall'
                    }}
                    margin={{ top: 'xsmall' }}
                  >
                    {2000 - count < 0 ? (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 'xsmall'
                        }}
                      >
                        <CircleAlert color="text-weak" size="12px" />
                        {overLimit} characters over limit
                      </span>
                    ) : (
                      `${2000 - count} characters left`
                    )}
                  </Typography>
                )}

                {feedbackError && (
                  <Typography
                    data-testid="chatbot-feedback-textarea-error-message"
                    type="text"
                    size="small"
                    color="text"
                    style={{
                      lineHeight: '16px',
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 'xsmall'
                    }}
                    margin={{ top: 'xsmall' }}
                  >
                    <CircleAlert size="12px" />
                    This field supports plain text only.
                  </Typography>
                )}

                <Box margin={{ vertical: 'medium' }} style={{ height: '48px' }}>
                  <CheckBox
                    id="contact"
                    name="contact"
                    pad="none"
                    label="HPE may use the email associated with this account to discuss my feedback"
                    checked={consent}
                    onChange={(event) =>
                      handleConsentChange(event.target.checked)
                    }
                  />
                </Box>

                {isErrorNotification && (
                  <Box margin={{ bottom: 'medium' }}>
                    <Notification
                      status="critical"
                      icon={<CircleAlert />}
                      message="There was an error submitting your request, please try again."
                    />
                  </Box>
                )}

                <Box direction="row" gap="small">
                  <Button
                    type="submit"
                    primary
                    label="Submit"
                    data-testid="chatbot-feedback-submit"
                  />
                  <Button
                    type="button"
                    data-testid="chatbot-feedback-nothanks"
                    label="No thanks"
                    onClick={onClearChatBot}
                  />
                </Box>
              </Form>
            </Box>
            <Box
              align="center"
              width={{ min: '336px', max: '586px' }}
              height="80px"
              color="text"
              style={{
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '16px',
                fontSize: '14px',
                fontWeight: '400'
              }}
            >
              HPE is conducting this questionnaire via our trusted research
              partner, Qualtrics, Inc. and their associated qualtrics.com
              services. Your personal data will be used only as necessary to
              manage responses to this questionnaire and will be processed in
              accordance with&nbsp;
              <Anchor
                href="https://www.hpe.com/us/en/legal/privacy.html"
                target="_blank"
                fontWeight="500"
              >
                HPE Privacy Statement
              </Anchor>
              .
            </Box>
          </Box>
        </>
      )}

      {isSubmitted && !isErrorScreen && (
        <Box
          display="flex"
          direction="column"
          justify="center"
          align="center"
          width={{ min: 'medium' }}
          height={{ min: '602px' }}
          pad={{ left: 'medium', right: 'medium', bottom: 'medium' }}
        >
          <Box
            direction="column"
            align="center"
            gap="medium"
            alignContent="center"
            width="336px"
            height="126px"
          >
            <NoDataInfo
              title="Thank you for your feedback!"
              titleHeadingLevel={2}
              action={
                <Anchor
                  alignSelf="center"
                  label="Visit Support Hub"
                  href="/support"
                  onClick={supportLinkClickHandler}
                  data-testid="chatbot-feedback-support-link"
                />
              }
              icon={<StatusGood size="30px" />}
              testId="success-info"
            />
          </Box>
        </Box>
      )}

      {isErrorScreen && (
        <Box
          display="flex"
          direction="column"
          justify="center"
          align="center"
          width={{ min: 'medium' }}
          height={{ min: '602px' }}
          pad={{ left: 'medium', right: 'medium', bottom: 'medium' }}
        >
          <Box
            direction="column"
            align="center"
            gap="medium"
            alignContent="center"
            width="336px"
            height="174px"
          >
            <NoDataInfo
              title="We are unable to process your request at this time"
              titleHeadingLevel={2}
              action={
                <Paragraph margin="none">
                  Please try again later, or visit&nbsp;
                  <Anchor
                    href="/support"
                    onClick={supportLinkClickHandler}
                    data-testid="support-link"
                  >
                    Support Hub
                  </Anchor>
                  &nbsp;for more options.
                </Paragraph>
              }
              icon={<CircleAlert size="30px" />}
              testId="error-info"
            />
          </Box>
        </Box>
      )}
    </CustomScrollBox>
  )
}

export default FeedbackForm
