// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Trans } from 'react-i18next'
import { Text } from 'grommet'

import { isMSP } from './feature-flag-utils'

export const allScopesRRPName = 'AllScopes' // TODO: AllScopes is the suggested name, we need to change the string if the name of RRP is changed

export const getRoleAssignmentSuccessText = (roleName, userName, t) => {
  return (
    // Text wrapper necessary for emphasized text to appear inline
    // with default text
    <Text>
      <Trans
        i18nKey="authz:assignments.role_assignment_success_notification_text"
        t={t}
        values={{
          roleName,
          userName
        }}
        components={[<Text weight={500} color="text-strong" />]}
      />
    </Text>
  )
}

// this will return updateAction Type, based on existingRole and selectedRole for assignRoleModal
export const getRoleUpdateAction = (
  filteredPrevRolesByApp,
  filteredPrevRolesByRoleSlug,
  selectedRoleDetails,
  ccsApplicationId,
  inCCSmanager
) => {
  // MSP case
  if (isMSP()) {
    let hasSameRole = false
    let hasMSP = false
    let hasTanent = false
    let hasMSPForDiffRole = false
    let hasTanentForDiffRole = false
    filteredPrevRolesByApp.forEach((item) => {
      if (item?.role_name === selectedRoleDetails?.role_name) {
        hasSameRole = true
        if (item?.access_rules?.msp) {
          hasMSP = true
        }
        if (item?.access_rules?.tenants?.length) {
          hasTanent = true
        }
      } else {
        if (item?.access_rules?.msp) {
          hasMSPForDiffRole = true
        }
        if (item?.access_rules?.tenants?.length) {
          hasTanentForDiffRole = true
        }
      }
    })
    let action = 'ADD'
    if (selectedRoleDetails.support_one_role_only) {
      if (
        !isMSP() ||
        (hasTanent && hasMSP) ||
        (hasMSP &&
          selectedRoleDetails?.access_rules?.msp &&
          hasTanent &&
          selectedRoleDetails?.access_rules?.tenants?.length) ||
        (hasMSP && selectedRoleDetails?.access_rules?.msp)
      ) {
        action = 'OVERWRITE'
      } else if (
        hasSameRole &&
        ((hasMSP && selectedRoleDetails?.access_rules?.tenants?.length) ||
          (hasTanent && selectedRoleDetails?.access_rules?.msp))
      ) {
        action = 'MERGE'
      } else if (
        !hasSameRole &&
        ((hasMSPForDiffRole && selectedRoleDetails?.access_rules?.msp) ||
          (hasTanentForDiffRole &&
            selectedRoleDetails?.access_rules?.tenants?.length))
      ) {
        action = 'MERGE_OVERWRITE'
      }
    } else if (selectedRoleDetails.application_id === ccsApplicationId) {
      // ccs appid - MSP
      if (isMSP()) {
        if (filteredPrevRolesByRoleSlug.length) action = 'SWAP_ROLE'
      }
    } else if (filteredPrevRolesByRoleSlug.length) action = 'SWAP_ROLE'
    return action
  }
  // non-MSP case
  if (selectedRoleDetails.support_one_role_only) {
    if (filteredPrevRolesByApp.length) return 'OVERWRITE'
    return 'ADD'
  }
  if (
    !inCCSmanager &&
    selectedRoleDetails.application_id === ccsApplicationId
  ) {
    return 'ADD'
  }
  if (filteredPrevRolesByRoleSlug.length) return 'SWAP_ROLE' // RRP got updated
  return 'ADD'
}

export const mspDefaultRRPId = '11111111-1111-1111-1111-111111111111'

export const nonScopedDefaultRRPId = '00000000-0000-0000-0000-000000000002'

export const getEditRRPSuccessText = (appName, t) => {
  return (
    <Trans
      i18nKey="authz:rrp.edit_rrp_success_notification_text"
      t={t}
      values={{
        appName
      }}
      components={[<strong />]}
    />
  )
}
