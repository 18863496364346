// Copyright 2025 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box, Menu } from 'grommet'
import { ChapterAdd, Chat, ShareRounded, MoreVertical } from 'grommet-icons'
import { ThemeContext } from 'styled-components'

import { Typography } from '../../components'

import { useSessionContext } from './context/session-context'
import { ChatActions, useChatContext } from './context/chat-context'

const CustomMenu = () => {
  const { client } = useSessionContext()
  const { dispatchChatContext, sessionId, feedbackFormData } = useChatContext()

  const sendUserMessage = (msg) => {
    const time = new Date()
    if (msg.length > 0 && msg.trim() !== '') {
      const payload = {
        from: 'You',
        text: msg,
        time: `${time.getHours()}:${time.getMinutes()}`
      }
      if (sessionId) {
        dispatchChatContext({ type: ChatActions.UPDATE_CHAT, payload })
        dispatchChatContext({ type: ChatActions.ADD_MESSAGE_LOADER })
        dispatchChatContext({
          type: ChatActions.SYNC_USER_INPUT_TO_BUTTONS,
          payload
        })
        dispatchChatContext({
          type: ChatActions.REMOVE_FEEDBACK_REQUEST
        })
        client.send(msg)
      } else dispatchChatContext({ type: ChatActions.ERROR_CONNECTING_CHAT })

      sessionStorage.removeItem('chatInputMessage')
    }
  }

  return (
    <ThemeContext.Extend
      value={{
        menu: {
          group: {
            separator: {
              pad: { vertical: 'xsmall' },
              color: 'border-weak'
            }
          },
          item: {
            gap: 'small',
            pad: { horizontal: 'small', vertical: 'xsmall', right: 'xsmall' },
            justify: 'left'
          },
          extend: `
            [role="menu"] {
              overflow: visible ;
              width : 192px ;
            }
            [role="menuitem"]{
              background:transparent;
              &:hover{
              background:rgba(0,0,0,0.04);
              }
            }  
          `
        }
      }}
    >
      <Menu
        icon={
          <MoreVertical
            aria-label={undefined}
            color="text"
            data-testid="menu-toggle"
          />
        }
        dropProps={{
          style: { zIndex: 112, overflow: 'visible' }
        }}
        items={[
          [
            {
              label: (
                <Box align="start" data-testid="menu-create-case">
                  <Typography type="text">Create case</Typography>
                </Box>
              ),
              icon: <ChapterAdd aria-label={undefined} size="small" />,
              onClick: () => {
                dispatchChatContext({
                  type: ChatActions.UPDATE_FEEDBACK_FORM,
                  payload: { ...feedbackFormData, showFeedbackForm: false }
                })
                sendUserMessage('Create case')
              }
            },

            {
              label: (
                <Box align="start" data-testid="menu-new-conversation">
                  <Typography type="text">New conversation</Typography>
                </Box>
              ),
              icon: <Chat aria-label={undefined} size="small" />,
              onClick: () => {
                dispatchChatContext({
                  type: ChatActions.UPDATE_FEEDBACK_FORM,
                  payload: {
                    showFeedbackForm: false,
                    rating: '',
                    feedback: '',
                    consent: false,
                    isSubmitted: false,
                    isErrorNotification: false,
                    isErrorScreen: false,
                    ratingError: false,
                    feedbackError: false
                  }
                })
                sendUserMessage('New conversation')
              }
            }
          ],
          [
            {
              gap: 'xsmall',
              label: (
                <Box align="start" data-testid="menu-user-guide">
                  <Typography type="text" size="xsmall" weight="Medium">
                    User Guide
                  </Typography>
                </Box>
              ),
              icon: <ShareRounded aria-label={undefined} size="small" />,
              reverse: true,

              onClick: () => {
                window.open(
                  'https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us',
                  '_blank',
                  'noopener'
                )
              }
            },
            {
              margin: { bottom: 'xsmall' },
              gap: 'xsmall',
              label: (
                <Box align="start" data-testid="menu-privacy-statement">
                  <Typography type="text" size="xsmall" weight="Medium">
                    HPE Privacy Statement
                  </Typography>
                </Box>
              ),
              icon: <ShareRounded aria-label={undefined} size="small" />,
              reverse: true,

              onClick: () => {
                window.open(
                  'https://www.hpe.com/us/en/legal/privacy.html#privacystatement',
                  '_blank',
                  'noopener'
                )
              }
            }
          ]
        ]}
      />
    </ThemeContext.Extend>
  )
}

export default CustomMenu
