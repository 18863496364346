// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormDown, Previous } from 'grommet-icons'
import { Box, PageHeader } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import { Anchor, ActionButton, Loader, Typography } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'

import GroupDetailDetailsSection from './components/GroupDetailDetailsSection'
import GroupDetailsUsers from './components/GroupDetailsUsers'
import GroupDetailsRoleAssignments from './components/GroupDetailsRoleAssignments'
import { GROUP_SCHEMAS } from './constants'
import RemoveGroupModal from './components/RemoveGroupModal'

const GroupDetailsContent = ({ isWorkspace = false }) => {
  const { t } = useTranslation(['common', 'iam'])
  const { groupId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const v2GroupReadPerm = v2RbacPolicies?.includes('identity.user-groups.read')
  const ccsAuthView = v2RbacPolicies?.includes('ccs.authorization.view')
  const v2UserReadPerm = v2RbacPolicies?.includes('identity.users.read')
  const v2AssociateGroupReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.user-groups.read'
  )
  const ccsAssociateAuthView = v2RbacRootWkspcPolicies?.includes(
    'ccs.authorization.view'
  )
  const v2AssociateUserReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.users.read'
  )
  const [loading, setLoading] = useState(true)
  const [groupDetailsData, setGroupDetailsData] = useState({})
  const [showRemoveGroupModal, setShowRemoveGroupModal] = useState(false)
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const [isAssociated, setIsAssociated] = useState(false)

  const path = isWorkspace
    ? 'manage-account/identity'
    : 'manage-account/organization'

  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const actionBtns = [
    {
      label: t('common:delete'),
      testId: 'remove-group-btn',
      onClick: () => {
        setShowRemoveGroupModal(true)
      }
    }
  ]

  useEffect(() => {
    if (location?.state?.displayName) {
      const msg = (
        <Typography type="text">
          <Trans>
            {t('iam:organization_groups.create_group_notif_msg_v2', {
              groupName: location?.state?.displayName
            })}
          </Trans>
        </Typography>
      )
      setNotificationInfo(
        msg,
        'info',
        t('iam:organization_groups.create_group_notif_title')
      )
    }
  }, [location?.state?.displayName, t])
  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let isCurrent = true
    const getGroupDetailsData = async () => {
      try {
        const groupData = await get(
          `/identity/v2alpha1/scim/v2/Groups/${groupId}`
        )
        if (!isCurrent) return
        if (groupData?.data) {
          setGroupDetailsData(groupData?.data)
        }
      } catch (error) {
        setNotificationInfo(getErrorMessage(error, t), 'error')
      } finally {
        if (isCurrent) {
          setLoading(false)
        }
      }
    }
    getGroupDetailsData()

    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId])

  return (
    <Box
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        title={
          loading ? (
            <Loader testId="group-details-header-loader" />
          ) : (
            ((!isAssociated && (v2GroupReadPerm || ccsAuthView)) ||
              (isAssociated &&
                (v2AssociateGroupReadPerm || ccsAssociateAuthView))) && (
              <Typography
                type="heading"
                level="1"
                testId="group-details-header-title"
              >
                {groupDetailsData?.displayName || '--'}
              </Typography>
            )
          )
        }
        subtitle={
          loading ||
          !(!isAssociated && (v2GroupReadPerm || ccsAuthView)) ||
          !(isAssociated && (v2AssociateGroupReadPerm || ccsAssociateAuthView))
            ? null
            : groupDetailsData[GROUP_SCHEMAS?.PARTIAL]?.groupDescription
        }
        parent={
          <Anchor
            label={t('iam:organization_groups.title')}
            href="#"
            icon={<Previous />}
            onClick={(event) => {
              event?.preventDefault()
              navigate(`/${path}/groups`)
            }}
            testId="group-details-page-back-btn"
          />
        }
        actions={
          !isWorkspace &&
          ((!isAssociated &&
            (v2GroupReadPerm || ccsAuthView) &&
            v2RbacPolicies?.includes('identity.user-groups.delete')) ||
            (isAssociated &&
              (v2AssociateGroupReadPerm || ccsAssociateAuthView) &&
              v2RbacRootWkspcPolicies?.includes(
                'identity.user-groups.delete'
              ))) && (
            <ActionButton
              label={t('common:actions')}
              actions={actionBtns}
              icon={<FormDown />}
              reverse // Puts icon at the end instead of in front of label
              showOneActionAsDropDown
              testId="group-details-action-btn"
            />
          )
        }
      />
      {!loading && (
        <>
          {(!isAssociated && (v2GroupReadPerm || ccsAuthView)) ||
          (isAssociated &&
            (v2AssociateGroupReadPerm || ccsAssociateAuthView)) ? (
            <>
              <GroupDetailDetailsSection
                groupDetailsData={groupDetailsData}
                parentLoading={loading}
              />
              {((!isAssociated && (v2UserReadPerm || ccsAuthView)) ||
                (isAssociated &&
                  (v2AssociateUserReadPerm || ccsAssociateAuthView))) && (
                <GroupDetailsUsers
                  groupDetailsData={groupDetailsData}
                  setNotificationInfo={setNotificationInfo}
                  parentLoading={loading}
                  isWorkspace={isWorkspace}
                  isAssociated={isAssociated}
                />
              )}
              {isWorkspace &&
                ((isAssociated &&
                  v2RbacRootWkspcPolicies?.includes(
                    'ccs.authorization.view'
                  )) ||
                  (!isAssociated &&
                    v2RbacPolicies?.includes('ccs.authorization.view'))) && (
                  <GroupDetailsRoleAssignments
                    groupDetailsData={groupDetailsData}
                    setNotificationInfo={setNotificationInfo}
                    parentLoading={loading}
                  />
                )}
            </>
          ) : (
            <NoViewPermission />
          )}
        </>
      )}

      {showRemoveGroupModal && (
        <RemoveGroupModal
          loading={loading}
          onSetOpen={setShowRemoveGroupModal}
          userGroupToRemove={groupDetailsData}
          setParentNotification={setNotificationInfo}
          refreshParent={() => {
            navigate('/manage-account/organization/groups', {
              state: {
                deleteGroupName: groupDetailsData?.displayName
              }
            })
          }}
        />
      )}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const GroupDetails = ({ isWorkspace = false }) => {
  return (
    <Layout>
      <GroupDetailsContent isWorkspace={isWorkspace} />
    </Layout>
  )
}

GroupDetailsContent.propTypes = {
  /**
   * True if called from v2 workspace, false if called from IGC pages
   */
  isWorkspace: PropTypes.bool
}

GroupDetails.propTypes = {
  /**
   * True if called from v2 workspace, false if called from IGC pages
   */
  isWorkspace: PropTypes.bool
}

export default GroupDetails
