// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, FormField, RadioButtonGroup, SelectMultiple } from 'grommet'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'
import debounce from 'lodash/debounce'

import {
  Button,
  CCSForm,
  Dropdown,
  FormInput,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../components'
import { useAUTHZContext } from '../../../context/authz-context'
import { get, getErrorMessage, post } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  getCustomerAccount,
  getCustomerAccountType,
  isGLOP,
  isMSP
} from '../../../utils/feature-flag-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { validateEmail } from '../../../utils/validation-utils'
import {
  getAccessRules,
  isActivateAdmin,
  getAllAccessRulesList
} from '../../../utils/manage-account-utils'
import { getCCSAppDetails } from '../../../utils/ccs-manager-utils'
import useFetchUserRoles from '../../../hooks/ui-doorway/useFetchUserRoles'
import Markdown from '../../Markdown'

const InviteUserModal = ({
  onSuccess = () => {},
  inCCSManager = false,
  onSetOpen,
  setShowNotification = () => {}
}) => {
  // TODO CCS-2031: Allow for searching of users and maintaining a list of multiple emails
  const [formValues, setFormValues] = useState({
    email: '',
    role: '',
    contact_info: ''
  })
  const [rolesList, setRolesList] = useState([])
  const [allRolesList, setAllRolesList] = useState([])
  const [searchString, setSearchString] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorOccurred, setErrorOccurred] = useState('')
  const { t: authnT } = useTranslation(['authn', 'common'])
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const { oidcUser } = useReactOidc()
  const ccsApplicationId = getCCSAppDetails()?.ccsApplicationId

  const accountType = getCustomerAccountType()

  const { userCCSRoles } = useAUTHZContext() // used for determining if the user is activate admin
  useFetchUserRoles()

  const [containGLC, setContainGLC] = useState(null)
  const LDFlags = useFlags()
  const [workspaceGroups, setWorkspaceGroups] = useState([])
  const [workspaceSearchString, setWorkspaceSearchString] = useState('')
  const custAccountLoaded = getCustomerAccount()
  const platformCid = custAccountLoaded?.platform_customer_id
  const itemsPerPage = 50
  const selectionLimit = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const isMSPUserInviteOrGlop =
    (LDFlags['glcp-msp-user-invite'] || isGLOP()) && isMSP()
  const isTenantWorkspaceGroup = LDFlags['tenant-workspace-group']

  const mspWorkspaceRoleOptions = [
    {
      value: 'none',
      label: authnT('authz:rrp.none')
    },
    {
      value: 'all',
      label: authnT('notifications.all_label')
    },
    {
      value: 'specific',
      label: authnT('users.specific_workspace_groups')
    }
  ]

  const accessRulesList = getAllAccessRulesList(authnT, isTenantWorkspaceGroup)

  useEffect(() => {
    if (userCCSRoles && platformCid && ccsApplicationId) {
      const param = {
        limit: itemsPerPage,
        ...(searchString?.trim()?.length && { name: searchString?.trim() }),
        offset: page === 1 ? 0 : offset,
        ...(isActivateAdmin(userCCSRoles) && { tag_rules: 'activate' })
      }
      get(
        `/authorization/ui/v1/customers/${platformCid}/applications/${ccsApplicationId}/roles`,
        param,
        oidcUser.access_token
      ).then(
        (response) => {
          const { data } = response
          let roles = []
          if (data?.roles) {
            roles = data?.roles || []
          }
          setTotalItems(data?.pagination?.total_count || itemsPerPage)
          setOffset(data?.pagination?.offset || 0)
          const rolesObj = roles.map((role, index) => {
            return {
              label: role.name,
              name: role.name,
              value: index,
              description: role.description,
              applicationId: role.application_id,
              slug: role.slug
            }
          })
          if (page !== 1) {
            setRolesList((prevRoles) => [...prevRoles, ...rolesObj])
            setAllRolesList((prevRoles) => [...prevRoles, ...rolesObj])
          } else {
            setRolesList(rolesObj)
            setAllRolesList(rolesObj)
          }
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, authnT, setShowErrorNotification)
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oidcUser.access_token,
    userCCSRoles,
    ccsApplicationId,
    authnT,
    page,
    searchString,
    platformCid
  ])

  const setAccessRules = () => {
    let accessRuleValue
    if (isMSPUserInviteOrGlop && isTenantWorkspaceGroup) {
      accessRuleValue = {
        msp: true,
        tenants: formValues?.access_rules?.tenants
      }
    } else if (accountType === 'MSP') {
      accessRuleValue =
        isMSPUserInviteOrGlop && !isTenantWorkspaceGroup
          ? formValues?.access_rules
          : getAccessRules(0)
    } else {
      accessRuleValue = null
    }
    return accessRuleValue
  }

  useEffect(() => {
    if (containGLC === null) {
      // check is current workspace contains provisioned GLC app
      get(
        '/ui-doorway/ui/v1/applications/provisions?provision_status=PROVISIONED',
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          if (!response || !response?.data?.provisions) {
            setContainGLC(false)
            return
          }
          const { provisions } = response?.data || []
          if (provisions.length > 0) {
            const provision = provisions.filter((item) => item.slug === 'GLC')
            if (provision.length === 1) {
              setContainGLC(true)
            } else {
              setContainGLC(false)
            }
          } else {
            setContainGLC(false)
          }
        },
        (error) => {
          setContainGLC(false)
          setShowErrorNotification(
            displayApiError(error, authnT, setShowErrorNotification)
          )
        }
      )
    }
    if (isMSPUserInviteOrGlop && isTenantWorkspaceGroup) {
      get(
        '/groups-management/v1alpha1/groups',
        {
          ...(workspaceSearchString?.trim()?.length && {
            filter: workspaceSearchString?.trim()
          }),
          'group-type': 'Workspace'
        },
        oidcUser?.access_token,
        false,
        {},
        { 'X-workspaceID': platformCid }
      ).then(
        (response) => {
          setWorkspaceGroups(response?.data?.items ?? [])
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, authnT, setShowErrorNotification)
          )
        }
      )
    }
  }, [
    oidcUser?.access_token,
    containGLC,
    authnT,
    isMSPUserInviteOrGlop,
    isTenantWorkspaceGroup,
    platformCid,
    workspaceSearchString
  ])

  const assignRole = () => {
    const { email, role, contact_info } = formValues
    const selectedRole = rolesList.find(
      (roleName) => roleName.slug === role.slug
    )
    const requestBody = {
      roles: [
        {
          role: {
            application_id: selectedRole.applicationId,
            slug: selectedRole.slug
          },
          access_rules: setAccessRules()
        }
      ],
      user_names: [email],
      ...(contact_info.length && {
        contact_information: contact_info
      })
      // send platform_customer_id and customer_username fileds for ccs-manager API
    }
    setIsSubmitting(true)
    let url = `/ui-doorway/ui/v1/um/invite-user`
    if (LDFlags['glcp-glc-migration'] && containGLC) {
      url = '/organizations/v1alpha1/users/invite-user'
    }
    post(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200) {
          onSetOpen(false)
          onSuccess()
          setShowNotification(
            displayNotification(
              <Markdown>
                {authnT('users.user_invite_notification_msg', {
                  userName: requestBody?.user_names[0]
                })}
              </Markdown>,
              'info',
              setShowNotification,
              authnT('users.user_invite_notification_title')
            )
          )
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, authnT)
        setErrorOccurred(backendErrorMessage)
        setIsSubmitting(false)
      }
    )
  }

  const handleDebouncedSearchValue = debounce((value) => {
    setSearchString(value)
    setPage(1)
  }, 1000)

  const handleDebouncedWorkspaceSearchValue = debounce((value) => {
    setWorkspaceSearchString(value)
  }, 500)

  const renderOption = ({ label, description, value }) => (
    <Box direction="column" width="small">
      <Box
        direction="row"
        pad={{ top: 'small', left: 'small', right: 'small' }}
        gap="small"
        background="none"
      >
        <Typography type="text" weight="bold" testId={`role-id-${value}`}>
          {label}
        </Typography>
      </Box>
      <Box
        direction="row"
        pad={{ bottom: 'small', left: 'small', right: 'small' }}
        gap="small"
      >
        <Typography type="paragraph">{description}</Typography>
      </Box>
    </Box>
  )

  return (
    <>
      {showErrorNotification}
      <ModalDialog
        onClickOutside={() => onSetOpen(false)}
        header={
          <ModalHeader
            title={
              <Typography type="heading" level="1" testId="invite-title">
                {authnT('users.inviteUserTitle')}
              </Typography>
            }
          />
        }
        content={
          <Box gap="medium" margin={{ top: 'xsmall' }}>
            <Typography type="text" size="large" testId="invite-subtitle">
              {!isMSPUserInviteOrGlop
                ? authnT('users.inviteUserSubtitle')
                : authnT('users.msp_invite_user_subtitle')}
            </Typography>
            <Box gap="xsmall">
              <CCSForm
                value={formValues}
                onChange={setFormValues}
                errorMessage={errorOccurred}
                testId="invite-user-form"
                validate="submit"
                onSubmit={assignRole}
                buttons={
                  <Box direction="row" justify="end" gap="medium">
                    <Button
                      default
                      label={authnT('users.cancel')}
                      onClick={() => onSetOpen(false)}
                      testId="cancel-invite-btn"
                    />
                    <Button
                      primary
                      type="submit"
                      disabled={isSubmitting}
                      label={authnT('users.sendInvite')}
                      testId="send-invite-btn"
                    />
                  </Box>
                }
              >
                <Box
                  gap={!isMSPUserInviteOrGlop ? 'medium' : 'xsmall'}
                  margin={{ bottom: 'medium' }}
                >
                  <Box>
                    <FormInput
                      inputType="text"
                      name="email"
                      label={authnT('users.emailFormLabel')}
                      help=""
                      required
                      validate={(value) => {
                        const msg = validateEmail(value)
                        return msg
                      }}
                      testId="email-form-field"
                      placeholder={authnT('users.emailPlaceholder')}
                    />
                    <Typography
                      type="paragraph"
                      size="small"
                      testId="invite-note"
                    >
                      {authnT('users.emailInfo')}
                    </Typography>
                  </Box>

                  <FormField
                    label={authnT('users.roleFormLabel')}
                    name="role_slug"
                    data-testid="role-form-field"
                    validate={(value) => {
                      if (value?.length) {
                        return true
                      }
                      return authnT('users.please_selecr_a_valid_role')
                    }}
                  >
                    {rolesList && (
                      <Dropdown
                        id="roles-dropdown"
                        data-testid="roles-dropdown"
                        name="role_slug"
                        options={rolesList}
                        multiple={false}
                        labelKey="name"
                        value={formValues.role_slug}
                        valueKey={{ key: 'slug', reduce: true }}
                        placeholder={authnT('users.select_role')}
                        onChange={({ option }) => {
                          setFormValues({
                            ...formValues,
                            role: {
                              slug: option.slug
                            },
                            role_slug: option.slug,
                            role_name: option.name,
                            predefined: option.predefined,
                            access_rules: null,
                            access_rules_index: undefined,
                            ...(formValues.limit_resource_access && {
                              resource_restriction_policies: []
                            })
                          })
                        }}
                        onMore={() => {
                          if (!inCCSManager && totalItems > offset) {
                            setPage(page + 1)
                          }
                        }}
                        onSearch={(searchText) => {
                          if (inCCSManager) {
                            setRolesList(
                              allRolesList.filter((o) =>
                                o.name.includes(searchText)
                              )
                            )
                          } else {
                            handleDebouncedSearchValue(searchText)
                          }
                        }}
                        onClose={() => {
                          setSearchString('')
                        }}
                        customRender={(option) => renderOption(option)}
                        noBorder
                      />
                    )}
                  </FormField>

                  {isMSPUserInviteOrGlop && isTenantWorkspaceGroup && (
                    <FormField
                      label={authnT('users.role_assignment_label')}
                      name="workspace_role_assignment"
                      validate={(value) => {
                        return value?.length
                          ? true
                          : authnT('saml_sso.required')
                      }}
                      data-testid="msp-workspace-role-form-field"
                    >
                      <RadioButtonGroup
                        name="workspace_role_assignment"
                        options={mspWorkspaceRoleOptions}
                        value={formValues?.workspace_role_assignment}
                        onChange={(event) => {
                          let tenants = event?.target?.value
                          if (event?.target?.value === 'all') {
                            tenants = ['ALL']
                          } else if (event?.target?.value === 'none') {
                            tenants = []
                          }
                          setFormValues({
                            ...formValues,
                            access_rules: {
                              ...formValues?.access_rules,
                              tenants
                            },
                            workspace_role_assignment:
                              event?.target?.value || null
                          })
                        }}
                        data-testid="msp-workspace-role-radiobutton"
                      />
                    </FormField>
                  )}
                  {isMSPUserInviteOrGlop && !isTenantWorkspaceGroup && (
                    <FormField
                      label={authnT('ip_access_rules.access_rule')}
                      name="access_rules_index"
                      required
                      data-testid="access-rules-form-field"
                    >
                      <Dropdown
                        id="access-rules-dropdown"
                        name="access_rules_index"
                        placeholder={authnT('users.select_access_rule')}
                        labelKey="label"
                        valueKey={{ key: 'value', reduce: true }}
                        value={formValues?.access_rules_index}
                        options={accessRulesList}
                        multiple={false}
                        onChange={({ option }) => {
                          setFormValues({
                            ...formValues,
                            access_rules: option?.access_rules,
                            access_rules_index: option?.value
                          })
                        }}
                        noBorder
                        data-testid="access-rules-dropdown"
                      />
                    </FormField>
                  )}
                  {formValues?.workspace_role_assignment === 'specific' &&
                    isTenantWorkspaceGroup && (
                      <FormField
                        label={authnT('users.msp_invite_user_workspace_groups')}
                        name="workspace_groups"
                        validate={(value) => {
                          return value?.length
                            ? true
                            : authnT('saml_sso.required')
                        }}
                        data-testid="select-workspace-groups-form-field"
                      >
                        <SelectMultiple
                          id="workspace_groups"
                          name="workspace_groups"
                          limit={selectionLimit}
                          options={workspaceGroups}
                          valueKey="id"
                          labelKey="displayName"
                          value={formValues?.workspace_groups}
                          onChange={({ value: nextValue }) => {
                            setFormValues({
                              ...formValues,
                              workspace_groups: nextValue,
                              access_rules: {
                                ...formValues?.access_rules,
                                tenants: nextValue?.map((res) => res?.id)
                              }
                            })
                          }}
                          placeholder={authnT('iam:users.select_up_to_msg', {
                            limit: selectionLimit
                          })}
                          searchPlaceholder={authnT('common:search')}
                          onSearch={(searchText) => {
                            handleDebouncedWorkspaceSearchValue(searchText)
                          }}
                          onClose={() => {
                            setWorkspaceSearchString('')
                          }}
                          noBorder
                          dropHeight="medium"
                          data-testid="workspace-groups-dropdown"
                        />
                      </FormField>
                    )}
                  <Box>
                    <FormInput
                      inputType="text"
                      label={authnT('users.contact_info_label')}
                      labelHelper={
                        !isMSPUserInviteOrGlop
                          ? authnT('users.contact_info_label_subtext')
                          : authnT('users.msp_contact_info_subtext')
                      }
                      name="contact_info"
                      testId="contact-info-form-input"
                      validate={(value) => validateEmail(value)}
                      placeholder={authnT('users.emailPlaceholder')}
                    />
                    <Typography
                      type="text"
                      size="small"
                      testId="contact-info-note"
                    >
                      {!isMSPUserInviteOrGlop
                        ? authnT('users.contact_info_form_info_message')
                        : ''}
                    </Typography>
                  </Box>
                </Box>
              </CCSForm>
            </Box>
          </Box>
        }
        onClose={() => onSetOpen()}
        testId="invite-user-modal"
        width="medium"
        height="100%"
        position="right"
      />
    </>
  )
}

InviteUserModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  inCCSManager: PropTypes.bool,
  setShowNotification: PropTypes.func
}

export default InviteUserModal
