// (C) Copyright 2025 Hewlett Packard Enterprise Development LP
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo
} from 'react'
import PropTypes from 'prop-types'

import useChatReducer from './ChatReducer'
import ChatActions from './ChatActions'

const ChatContext = createContext()

export const ChatContextProvider = ({ children }) => {
  const stateAndDispatch = useChatReducer()
  const [disableInput, setDisableInput] = useState(false)
  const [lastActiveFeedback, setLastActiveAsFeedback] = useState(false)
  const [textAreaHeight, setTextAreaHeight] = useState(25)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const { ChatState, dispatchChatContext } = stateAndDispatch
  const { selectedButtons, feedbackFormData } = ChatState

  const updateSelectedButton = useCallback(
    (messageId, buttonValue) => {
      dispatchChatContext({
        type: ChatActions.UPDATE_SELECTED_BUTTONS,
        payload: { messageId, buttonValue }
      })
    },
    [dispatchChatContext]
  )
  const contextValue = useMemo(
    () => ({
      ...stateAndDispatch,
      disableInput,
      selectedButtons,
      updateSelectedButton,
      setDisableInput,
      textAreaHeight,
      setTextAreaHeight,
      lastActiveFeedback,
      setLastActiveAsFeedback,
      showSidePanel,
      setShowSidePanel,
      feedbackFormData: feedbackFormData || {
        showFeedbackForm: false,
        rating: '',
        feedback: '',
        consent: false,
        isSubmitted: false,
        isErrorNotification: false,
        isErrorScreen: false,
        ratingError: false,
        feedbackError: false
      }
    }),
    [
      stateAndDispatch,
      disableInput,
      selectedButtons,
      updateSelectedButton,
      setDisableInput,
      textAreaHeight,
      setTextAreaHeight,
      lastActiveFeedback,
      setLastActiveAsFeedback,
      feedbackFormData,
      showSidePanel,
      setShowSidePanel
    ]
  )
  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  )
}

ChatContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useChatContext = () => {
  const {
    ChatState,
    dispatchChatContext,
    selectedButtons,
    updateSelectedButton,
    disableInput,
    setDisableInput,
    textAreaHeight,
    setTextAreaHeight,
    lastActiveFeedback,
    setLastActiveAsFeedback,
    feedbackFormData,
    showSidePanel,
    setShowSidePanel
  } = useContext(ChatContext) || {}
  return {
    ...ChatState,
    dispatchChatContext,
    selectedButtons,
    updateSelectedButton,
    disableInput,
    setDisableInput,
    textAreaHeight,
    setTextAreaHeight,
    lastActiveFeedback,
    setLastActiveAsFeedback,
    feedbackFormData,
    showSidePanel,
    setShowSidePanel
  }
}
